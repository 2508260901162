<div>
    <div class="modal-custom"
         *ngIf="status != 'success' && status != 'error'">
        <div class="modal-header px-4">
            <span class="modal-title font-size-16">
                <i class="icon-edit icon-squre-custon mr-2"></i>
                Edit Team
            </span>
            <button type="button"
                    class="close"
                    aria-label="Close"
                    (click)="resetAndCloseModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body p-4">
            <div class="d-flex flex-column">
                <div class="mb-1">
                    <span class="pb-3 mb-1 text-muted font-weight-500">Team Name</span>
                    <p>{{team.name}}</p>
                </div>

                <div class="mb-1">
                    <span class="pb-2 mb-1 text-muted font-weight-500">Can this team sign transactions?</span>
                    <p>{{team.type === TeamSigningType.SIGNING ? 'Yes' : 'No'}}</p>
                </div>

                <div class="mb-1 mt-1">
                    <div class="d-flex mb-2">
                        <span class="text-muted font-weight-500">Select Members</span>
                        <div class="d-inline float-right" style="margin-left: auto;"> 
                            <span class="text-muted font-weight-500" href="#">{{ selectedMembers?.length > 0 ? selectedMembers?.length + ' Selected': ''}}</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <ng-multiselect-dropdown 
                        [settings]="searchDropdownSettings" 
                        name="Initiator" placeholder="Select Members" 
                        [data]="filteredMembers" 
                        [(ngModel)]="selectedMembers"
                        (onSelect)="onMemberSelect($event)"
                        (onDeSelect)="onMemberDeSelect($event)" >
                            <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected" class="d-flex ms-2">
                                <div class="d-flex" style="padding:4px 10px !important;" [style]="isSelected?'display:none !important':''">
                                    <div class="d-flex align-items-center box_Circle_Qr p-1">
                                        <img class="img-fluid for-light rounded-circle user-image " [src]="getMembers[id]?.phptoURL" alt="">
                                    </div>
                                    <div class="details d-flex flex-column ml-1">
                                        <div class="text-left p-0 mb-auto mt-auto">
                                            <div #name class="m-0 truncate_Name drop_name">{{ getMembers[id]?.displayName}}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                                <div class="d-flex">
                                    <div class="d-flex align-items-center box_Circle_Qr">
                                        <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2" [src]="getMembers[id]?.phptoURL" alt="">
                                    </div>
                                    <div class="details d-flex align-items-center">
                                        <div class="col text-left p-0">
                                            <div #name class="m-0 truncate_Name" [ngbTooltip]="option">{{ option }}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-multiselect-dropdown>
                        <!-- <div *ngIf="isControlInvalidAndTouched('selectedMembers')"
                             class="text-danger mt-1">
                            Please select a minimum of one member.
                        </div> -->
                    </div>
                </div>

                <div class="wallet-warn" *ngIf="erroredWallets.length">
                    <img src="/assets/images/exclamation.svg" />
                    <div>
                        <span>The current minimum requirement for this wallet is inadequate.</span>
                        <ul>
                            <li *ngFor="let eWallet of erroredWallets">{{eWallet.name}} : {{eWallet.count}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <ng-container>
            <div class="modal-footer border-0">
                <div class="wallets-count" *ngIf="team.walletCount">
                    <p>Team changes will be synchronised across all associated</p>
                    <span>{{team.walletCount}} wallets.</span>
                </div>
                <button type="submit"
                        (click)="!isLoading && submitTeam()"
                        [disabled]="isLoading || erroredWallets.length || !selectedMembers.length || !hasUpdateMembers"
                        class="btn btn-primary w-100">Edit Team</button>
            </div>
        </ng-container>
    </div>
</div>