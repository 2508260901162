import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
} from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { WalletserviceService } from "../../services/walletservice.service";
import { Wallet } from "../../entities/wallet";
import {
  tokenType,
  TransactionRequest,
} from "../../entities/TransactionRequest";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ComposeTransaction } from "../../helpers/trezor/transactionComposer";
import { LoggerService } from "../../../shared/services/logger.service";
import Swal from "sweetalert2";
import { HttpService } from "../../services/http.service";
import { AuthServiceJWT } from "../../services/auth.service";
import { getIcon, getName } from "../../helpers/coins";
import { UAParser } from "ua-parser-js";
import { environment } from "src/environments/environment";
import { allowNumber, allowNumberDecimal } from "../../helpers/inputNumber";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { SegmentService } from "ngx-segment-analytics";
import { ErrorPrompt } from "../custom-prompt/custom-prompt.service";
import { sortWallets } from "./utils";
import { PolicyService } from "../../services/policy.service";

@Component({
  selector: "app-refill",
  templateUrl: "./refill.component.html",
  styleUrls: ["./refill.component.scss"],
})
export class RefillComponent implements OnInit {
  page = 1;
  pageSize = 10;
  sum: any;
  wallets: Wallet[];
  _wallets: any = [];
  cooldown: string;
  pendingtxs: TransactionRequest[] = [];
  step1 = false;
  current_transaction_count: number;
  total_transactions: number;
  current_filter_transaction_count: number;
  composeTransaction: ComposeTransaction;
  txRequest: TransactionRequest;
  selectedSend: Wallet;
  Wallet_Name: string;
  balanceUSD: number;
  id: number;
  wallet_id: number;
  currentBalance: number;
  condition: string;
  to_Wallet_Name: string;
  amount: number;
  coin: string;
  chain: string;
  balance: number;
  signers_PhotoURL: string;
  frequency: string;
  recurringTime: any;
  balanceTrigger: any;
  percentage_of_progressbar: string;
  isLoader = false;
  device: string;
  browser: string;
  state = false;
  assets: any;
  selectedAsset: any;
  selectedAddress: any;
  setNumberRefill: number;
  interval_unit: string = "hours";
  setRefillInterval: number;
  setRefillAmount: number;
  setBalanceLower: number;
  createRefillStep: number = 1;
  status: string = "step-1";
  isLoading: boolean = false;
  filterType: string = "all";
  refillSearch: string;
  selectedWallet: any;
  userType: any;
  pushId: any;
  pushCoin: any;
  loading: boolean = true;
  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild("Scheduled_refill") approveallScheduleTransactionsDiv: ElementRef;
  @Input() refillWalletId: any;
  @Input() refillCard: string;
  @Input() component_for: string;
  @Input() walletData: any;
  @Input() walletAllData: any;

  constructor(
    private data: DataService,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private modalService: NgbModal,
    private logger: LoggerService,
    public authService: AuthServiceJWT,
    private router: Router,
    public segment: SegmentService,
    public policyService: PolicyService
  ) {
    this.browser = new UAParser().getBrowser().name;
    // this.walletService.changeViewWallet(true);

    let user = this.authService.getUser;
    this.signers_PhotoURL = user.photoURL.toString();
    this.data.pendingTxRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((txs: TransactionRequest[]) => {
        if (!txs) return;
        this.pendingtxs = txs;
        for (let i = 0; i < this.pendingtxs.length; i++) {
          this.pendingtxs[i]["walletinfor"] = this.walletService.getWallet(
            this.pendingtxs[i].walletid +
              "_" +
              this.pendingtxs[i].asset.toLowerCase()
          );
        }
        if (txs) {
          this.state = true;
          this.filterWallets();
        }
      });
    this.data.getUserProfile
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (user_detail) => {
        if (!user_detail) return;
        this.userType = user_detail["organizations"][0].type;
      });
    this.device = "ledger";
  }

  filterWallets = () => {
    this.wallets = this.walletService.getAllWallets();
    if (!this.refillWalletId) {
      this.wallets = this.wallets.filter((ele) => ele.isArchived != 1);
    }
    this.data.refillTxs
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((refillTxs) => {
        const refillWallets = [];
        refillTxs.forEach((refillTx) => {
          let wallet = this.walletService.getWallet(
            refillTx.walletid + "_" + refillTx.asset.toLowerCase()
          );
          if (wallet) {
            wallet["scheduledBatch"] = refillTx;
            wallet.walletKeys = refillTx.walletKeys;
            refillWallets.push(wallet);
          }

          this.wallets = refillWallets;
          // this.wallets = this.wallets.filter(s => {
          //   if (s['scheduledBatch']) {
          //     return s.coin == s['scheduledBatch'].asset;
          //   }
          // });
          // this.wallets = this.wallets.map((wallet) => {
          //   wallet['sortValue'] = wallet['scheduledBatch']['remainingTxs'] + this.filterScheduleTransactions(wallet['scheduledBatch'].walletid).length * 1000;
          //   return wallet;
          // });
          // this.wallets.sort((a, b) => parseFloat(b['sortValue']) - parseFloat(a['sortValue']));
          this._wallets = this.wallets;
          this._wallets.forEach((item: any) => {
            item["ScheduleTransactions"] = this.filterScheduleTransactions(
              item["scheduledBatch"].walletid
            );
          });
          this.refillSingleWallet();
          // code for refill signers
          this._wallets.filter((obj) => {
            obj.walletKeys.filter((signer) => {
              signer["isSigned"] = this.checkSigned(obj, signer.userid);
            });
          });
          sortWallets(this._wallets);
        });
        this.loading = false;
        console.info("refill data", this._wallets);
      });
  };

  checkSigned(walletObj, userId) {
    let refillTx = this.pendingtxs.filter(
      (ele) => ele.walletid == walletObj.id && ele["scheduledBatch"]
    );

    if (refillTx.length) {
      return refillTx.every((element) => {
        if (
          element.signs.filter((e) => e.approved && e["userid"] == userId)
            .length
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.refillSingleWallet();
    if (this.component_for == "create_refill") {
      this.selectedWallet = this.walletAllData.filter(
        (ele) => ele.id == this.walletData.id
      )[0];
      if (!this.walletData.policy) {
        this.selectedWallet.policy =
          await this.policyService.getWalletPolicySync(this.walletData.id);
      }

      this.assets = this.walletAllData.filter(
        (ele) =>
          ele.id == this.walletData.id && ele.tokenType != tokenType.nftToken
      );
    }
  }

  selectAssets(asset) {
    this.selectedAsset = asset;
  }

  selectAddress(address) {
    this.selectedAddress = address;
  }

  selectRole(unit) {
    this.interval_unit = unit;
  }

  filterScheduleTransactions(wid) {
    this.pendingtxs.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    this.pendingtxs.sort((a, b) => b["noceidentifier"] - a["noceidentifier"]); // Changed
    return this.pendingtxs.filter((object) => {
      let status = this.getStatusText(object);
      return (
        object["type"] == 2 && status == "Approve" && object["walletid"] == wid
      );
    });
  }

  //Filter Schedule transactions for getting total count of all schedule transactions
  filterForTotalScheduleTransactions(wid) {
    this.pendingtxs.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    this.pendingtxs.sort((a, b) => b["noceidentifier"] - a["noceidentifier"]); // Changed
    return this.pendingtxs.filter((object) => {
      // let status = this.getStatusText(object)
      return object["type"] == 2 && object["walletid"] == wid;
    });
  }

  //Filter schedule transactions to get count of current transactions that has been approved by signer
  filterForCurrentScheduleTransactions(wid) {
    this.pendingtxs.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    this.pendingtxs.sort((a, b) => b["noceidentifier"] - a["noceidentifier"]); // Changed
    return this.pendingtxs.filter((object) => {
      let status = this.getStatusText(object);
      return object["type"] == 2 && status == "" && object["walletid"] == wid;
    });
  }

  getStatusText(txData: TransactionRequest) {
    let wallet = this.walletService.getWallet(
      txData.walletid + "_" + txData.asset?.toLowerCase()
    );
    if (wallet) {
      let m = Number.parseInt(wallet.config.split("of")[0]);
      if (txData.signs.filter((ele) => ele.approved == true).length >= m) {
        return "Execute";
      }
    }

    for (let i = 0; i < txData.signs.length; i++) {
      if (txData.signs[i].isMine && txData.signs[i].approved) return "";
    }

    return "Approve";
  }

  async onApproveClick(wid) {
    //get all scheduled transactiosn only
    var getScheduleTransactions = this.filterScheduleTransactions(wid);
    //open popup only when there is transactions
    if (getScheduleTransactions.length > 0) {
      try {
        this.open(this.approveallScheduleTransactionsDiv);

        //Total Number of scheduled transactions
        this.total_transactions =
          this.filterForTotalScheduleTransactions(wid).length;

        //Getting transactions that has been Signed by signer
        this.current_transaction_count =
          this.filterForCurrentScheduleTransactions(wid).length;

        //Decrease one count of transaction as transaction has been signed
        this.current_filter_transaction_count =
          this.filterScheduleTransactions(wid).length - 1;
        // this.current_filter_transaction_count = 0;

        // Load transaction Details
        await this.LoadTransactionDetailsInPopup(wid);

        //Initialize trezore
      } catch (err) {
        this.logger.error(err, err);
      }
    }
  } //end

  checkPending(id) {
    if (this.walletData.pending_txs.length > 0) {
      Swal.fire({
        icon: "info",
        text: "Please clear pending transactions in this wallet before creating refill schedule",
        showConfirmButton: false,
      });
      return;
    }
    this.open(id);
  }

  open(refill: any) {
    this.step1 = true;
    // this.modalService.open(refill, { windowClass: 'modal-custom-background', centered: true });
    this.modalService
      .open(refill, { windowClass: "modal-custom-background", centered: true })
      .result.then(
        (result) => {
          this.resetSendModel();
        },
        (reason) => {
          this.resetSendModel();
        }
      );
  }

  pushOpen(pushrefillwallets: any) {
    this.modalService.open(pushrefillwallets, { centered: true });
  }

  refillPushBtn(Wallets_id, coin) {
    this.httpService.refillPush(Wallets_id, coin).subscribe((data) => {
      if (data.success == true) {
        Swal.fire({
          title: "Success!",
          text: "Awesome! Your refill transaction is pushed to process urgent refill.",
          icon: "success",
          confirmButtonText: "Cool",
        });
        this.modalService.dismissAll();
      } else {
        ErrorPrompt.fire({
          icon: "error",
          title: "Oops...",
          text: `${data.message}`,
        });
        this.modalService.dismissAll();
      }
    });
  }

  async LoadTransactionDetailsInPopup(wid) {
    try {
      var getScheduleTransactions = this.filterScheduleTransactions(wid);
      console.info(getScheduleTransactions);
      if (getScheduleTransactions.length > 0) {
        this.txRequest =
          getScheduleTransactions[this.current_filter_transaction_count];

        if (
          getScheduleTransactions[this.current_filter_transaction_count]
            .walletinfor
        ) {
          try {
            this.device = getScheduleTransactions[
              this.current_filter_transaction_count
            ].walletinfor.walletKeys.filter((key) => key.ismine)[0].provider;
          } catch (err) {
            this.device = "trezor";
          }
          this.wallet_id = wid;
          this.id =
            getScheduleTransactions[this.current_filter_transaction_count].id;
          this.selectedSend =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].walletinfor;
          this.Wallet_Name =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].walletinfor.name;
          this.balanceUSD =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].walletinfor.balanceUSD;
          this.to_Wallet_Name =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].addressLabel;
          this.condition =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].destinationAddress;
          this.balance =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].walletinfor.balance;
          this.coin =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].asset;
          this.chain =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].chain;
          this.amount =
            getScheduleTransactions[
              this.current_filter_transaction_count
            ].scheduledBatch.amount;
          if (
            getScheduleTransactions[this.current_filter_transaction_count]
              .scheduledBatch
          ) {
            this.currentBalance =
              getScheduleTransactions[
                this.current_filter_transaction_count
              ].scheduledBatch.currentbalance;
            this.frequency =
              getScheduleTransactions[
                this.current_filter_transaction_count
              ].scheduledBatch.transactioncount;
            this.recurringTime =
              getScheduleTransactions[
                this.current_filter_transaction_count
              ].scheduledBatch.recurringtime;
            this.balanceTrigger =
              getScheduleTransactions[
                this.current_filter_transaction_count
              ].scheduledBatch.balancetrigger;
          } else {
            this.logger.error(
              "No schedule batch found for wallet id" +
                getScheduleTransactions[this.current_filter_transaction_count]
                  .walletinfor.id
            );
            ErrorPrompt.fire({
              icon: "error",
              title: "Failed to load transaction!",
              text: "Failed to load transaction!",
              showConfirmButton: false,
            });
            return;
          }
          console.info(this.selectedSend);
          this.composeTransaction = new ComposeTransaction(
            { ...this.selectedSend, tag: "refill" },
            this.data,
            this.httpService,
            this.logger,
            this.segment,
            this.authService,
            this.walletService
          );

          let progress_bar_percentage = Math.round(
            (this.current_transaction_count * 100) / this.total_transactions
          );
          if (progress_bar_percentage < 10) {
            let percentage = Math.round(10 * progress_bar_percentage) / 10;
            this.percentage_of_progressbar = percentage + "%";
          } else {
            let percentage = Math.round(100 * progress_bar_percentage) / 100;
            this.percentage_of_progressbar = percentage + "%";
          }
        }
      }
    } catch (err) {
      this.logger.error(err, err);
    }
  } //end

  async Sign(wid) {
    this.isLoader = true;
    console.info(this.txRequest);
    let signResponse = await this.composeTransaction.sendTransaction(
      { address: this.condition, value: this.amount },
      [],
      this.txRequest
    );
    this.modalService.dismissAll();
    this.isLoader = false;
    for (let i = 0; i < signResponse.length; i++) {
      if (signResponse[i].error) {
        ErrorPrompt.fire({
          // position: 'top-end',
          icon: "error",
          title: "Failed to sign transaction!",
          text: this.walletService.setCustomErrorMsg(signResponse[i].error),
          showConfirmButton: false,
          // timer: 1500
        });
        return;
      }
    }
    //increase transaction count as the transaction signed successfully
    this.current_transaction_count += 1;

    //Decrease the count to get final remaining transactions
    this.current_filter_transaction_count -= 1;
    // this.current_filter_transaction_count += 1;

    //check for all transactions has been signed and call sign() method till all transactions are Signed.
    if (this.current_transaction_count != this.total_transactions) {
      //Load details of transaction
      await this.LoadTransactionDetailsInPopup(wid);
      //open popup after loading details
      this.open(this.approveallScheduleTransactionsDiv);

      //refresh pending tx
      this.refreshPendingTx();
      //call sign method for other remaining transactions in queue
      await this.Sign(wid);
    } else {
      this.refreshPendingTx();
      Swal.fire({
        title: "Success!",
        text: "All Transactions has been signed !!",
        icon: "success",
        confirmButtonText: "Cool",
      });
    }
  }

  refillSingleWallet() {
    if (this.refillCard == "refill_card") {
      this._wallets = this._wallets.filter(
        (obj) => obj.id == this.refillWalletId
      );
    }
  }

  // pagination = (length) => {
  //   let from = ((this.page - 1) * this.pageSize) + 1;
  //   let to = this.page * this.pageSize < length ? this.page * this.pageSize : length;
  //   return `${from} to ${to}`;
  // }

  nextCreateRefill() {
    this.createRefillStep = 2;
  }
  resetSendModel() {
    this.createRefillStep = 1;
    this.setNumberRefill = null;
    this.setRefillInterval = null;
    this.setRefillAmount = null;
    this.setBalanceLower = null;
    if (this.component_for != "create_refill") this.selectedWallet = null;
    this.selectedAsset = null;
    this.selectedAddress = null;
  }
  // createRefillSchedule ()
  //  {
  //    this.status = 'success';
  //  }

  async createRefillSchedule(
    address,
    walletId,
    transactionCount,
    recurringTime,
    amount,
    balanceTrigger,
    asset,
    timeIntervalUnit
  ) {
    this.isLoading = true;
    let timeInHours;
    if (timeIntervalUnit.toLowerCase() == "days") {
      timeInHours = recurringTime * 24;
    } else {
      timeInHours = recurringTime;
    }

    try {
      let bodyObj = {
        address: address,
        walletId: walletId,
        amount: amount,
        recurringTime: timeInHours,
        transactionCount: transactionCount,
        balanceTrigger: balanceTrigger,
        asset: asset,
      };

      const response = await this.httpService
        .createRefillSchedule(bodyObj)
        .toPromise();

      console.log(response);
      if (response.success === true) {
        this.status = "success";
        this.isLoading = false;
      } else {
        this.modalService.dismissAll();
        this.isLoading = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to create refill schedule",
          text: response.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.isLoading = false;
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to add staking",
        text: e.error.message,
        showConfirmButton: false,
      });
      console.log(e);
    }
  }

  // omit_special_char(event)
  // {
  //   var k;

  //   if(this.setRefillInterval < 0 || this.setNumberRefill < 0 || this.setRefillAmount < 0 || this.setBalanceLower < 0 )
  //   {
  //     this.setRefillInterval = null;
  //     this.setNumberRefill = null;
  //     this.setRefillAmount = null;
  //     this.setBalanceLower = null;
  //   }
  //   k = event.charCode;  //  k = event.keyCode;  (Both can be used)
  //   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 46);
  // }

  allowNumberDecimal(event) {
    if (
      this.setRefillInterval < 0 ||
      this.setNumberRefill < 0 ||
      this.setRefillAmount < 0 ||
      this.setBalanceLower < 0
    ) {
      this.setRefillInterval = null;
      this.setNumberRefill = null;
      this.setRefillAmount = null;
      this.setBalanceLower = null;
    }
    return allowNumberDecimal(event);
  }
  allowNumber(event) {
    if (
      this.setRefillInterval < 0 ||
      this.setNumberRefill < 0 ||
      this.setRefillAmount < 0 ||
      this.setBalanceLower < 0
    ) {
      this.setRefillInterval = null;
      this.setNumberRefill = null;
      this.setRefillAmount = null;
      this.setBalanceLower = null;
    }
    return allowNumber(event);
  }

  async refreshPendingTx() {
    let txRequests = await this.httpService
      .getAllPendingWalletTransactions()
      .toPromise();
    this.data.changePendingTxRequest(txRequests);
  }

  refillFilter(type) {
    this.filterType = type;
    this.refillSearch = "";
  }

  checkForRefillWallet(wallets) {
    return wallets.filter(
      (ele) =>
        environment.refillWallet_support_chain.includes(ele.chain) &&
        ele.version == 1 &&
        !ele.scheduledBatch &&
        (ele.subtype != "hot" ||
          ele.subtype != "hot_pipeline" ||
          ele.subtype != "deposit") &&
        this.userType == 2 &&
        ele.isArchived != 1
    );
  }

  selectWallet(data) {
    this.selectedWallet = data;
    if (!this.selectedWallet.policy) {
      this.policyService
        .getWalletPolicySync(this.selectedWallet.id)
        .then((policy) => {
          this.selectedWallet = policy;
        });
    }
    this.assets = this.walletAllData.filter(
      (ele) => ele.id == data.id && ele.tokenType != tokenType.nftToken
    );
  }

  pushData(id, coin) {
    this.pushId = id;
    this.pushCoin = coin;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  walletDetailClick(id) {
    this.router.navigateByUrl("/wallets/" + id + "/" + "overview");
  }
}
