(function (global, factory) {
  if (typeof define === 'function' && define.amd) {
    define(function () {
      return factory(global, global.document);
    });
  } else if (typeof module !== 'undefined' && module.exports) {
    module.exports = factory(global, global.document);
  } else {
    global.normalize = factory(global, global.document);
  }
})(typeof window !== 'undefined' ? window : this, function (window, document) {
  var charmap = require('./charmap.json');
  var regex = null;
  var current_charmap;
  var old_charmap;
  function normalize(str, custom_charmap) {
    old_charmap = current_charmap;
    current_charmap = custom_charmap || charmap;
    regex = regex && old_charmap === current_charmap ? regex : buildRegExp(current_charmap);
    return str.replace(regex, function (charToReplace) {
      return current_charmap[charToReplace.charCodeAt(0)] || charToReplace;
    });
  }
  function buildRegExp(charmap) {
    return new RegExp('[' + Object.keys(charmap).map(function (code) {
      return String.fromCharCode(code);
    }).join(' ') + ']', 'g');
  }
  return normalize;
});