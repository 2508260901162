<div class="container-fluid center">
  <div class="row w-100" *ngIf="loading">
    <div class="col-2">
      <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="col-2">
      <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="col-3 row">
      <div class="col-4">
        <ngx-skeleton-loader></ngx-skeleton-loader>
      </div>
      <div class="col-4">
        <ngx-skeleton-loader></ngx-skeleton-loader>
      </div>
      <div class="col-4">
        <ngx-skeleton-loader></ngx-skeleton-loader>
      </div>
    </div>
    <div class="col-2" style="margin-left: auto;">
      <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
  </div>
  <div class="card card-margin mt-3" *ngIf="loading">
    <div class="table-responsive styled-table">
      <table class="table">
        <thead>
          <tr>
            <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col" ><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col" style="width: 120px;"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col" style="width: 350px;"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
            <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of [].constructor(policylist_component_for == 'wallet_details'?4:10); let i = index">
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
            <td class="text-center">
              <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader count="5" appearance="circle"></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
            <td>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-xl-12 p-0" *ngIf="policylist_component_for == 'wallet_details'?false:true && listPolicyData.length">
      <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item">
          <a class="text-muted font-weight-bolder" routerLink='/settings'>Settings</a>
        </li>
        <li class="breadcrumb-item active">
          <span class="text-muted font-weight-bolder">Policy Management</span>
        </li>
      </ol>
    </div>
    <div class="d-flex justify-content-between align-items-center header-title-margin pb-3 col-12">
      <div class="d-flex align-items-center">
        <h6 class="d-inline mb-0 mr-5 text-muted">Policy Rules</h6>
        <div class="search-round mr-3" *ngIf="listPolicyData.length">
          <i class="icon-search" aria-hidden="true"></i>
            <input class="form-control" [(ngModel)]="policySearch" (keyup)="search(policySearch)"
              placeholder="Search" type="text">
        </div>
        <ul class="d-flex flex-wrap" *ngIf="policylist_component_for == 'wallet_details'?false:true && listPolicyData.length">
          <li>
            <span class="tag cursor-pointer mr-2" [ngClass]="{ 'active': filterPolicyType === 'All'}"
              (click)="policyTypeFilter('All')">All | {{ listPolicyData.length }}</span>
          </li>
          <li>
            <span class="tag cursor-pointer mr-2" [ngClass]="{ 'active': filterPolicyType === 'address'}"
              (click)="policyTypeFilter('address')">Whitelist | {{ filterPolicy('address') }}</span>
          </li>
          <li>
            <span class="tag cursor-pointer mr-2" [ngClass]="{ 'active': filterPolicyType === 'velocity'}"
              (click)="policyTypeFilter('velocity')">Spending Limit | {{ filterPolicy('velocity') }}</span>
          </li>
          <li>
            <span class="tag cursor-pointer" [ngClass]="{ 'active': filterPolicyType === 'threshold'}"
              (click)="policyTypeFilter('threshold')">Transaction Limit | {{ filterPolicy('threshold') }}</span>
          </li>
        </ul>
      </div>
      <div class="d-flex">
        <button class="btn btn-outline-primary btn-md mr-2" (click)="downloadCSV()"
          *ngIf="policylist_component_for == 'wallet_details'?false:true && listPolicyData.length"><i class="icon-download mr-1"></i>
          Download</button>
        <div class="ml-2" *ngIf="policylist_component_for == 'wallet_details'?true:false">
          <app-add-policy [addpolicy_component_for]="addpolicy_component_for"
            [walletDetailWalletId]="walletDetailWalletId"></app-add-policy>
        </div>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="!loading && listPolicyData.length">
      <div class="card m-t-10" [ngClass]="{'card-shadow-border':policylist_component_for === 'wallet_details'}">
        <div class="table-responsive styled-table table-new">
          <table class="table policies-list-table">
            <thead>
              <tr>
                <th class="pl-4" *ngIf="policylist_component_for == 'wallet_details'?false:true">Wallet</th>
                <th *ngIf="policylist_component_for == 'wallet_details'?false:true">Asset</th>
                <th [ngClass]="{'pl-4':policylist_component_for == 'wallet_details'}">Type</th>
                <th>Details</th>
                <th>Initiators</th>
                <th>Approvers</th>
                <th class="text-center">Status</th>
                <th class="text-center time-header">Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="policies-list-table">
              <tr
                *ngFor="let policy of listPolicyData | policyListSearch: policySearch | policyTypeFilter:filterPolicyType | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ;let i=index">
                <td class="pl-4" *ngIf="policylist_component_for == 'wallet_details'?false:true">
                  <div>
                    <span class="d-block" placement="top" ngbTooltip="{{ policy.walletname }}">{{
                      policy.walletname }}</span>
                    <span class="network text-dark {{ policy.chain.toLowerCase() }}">
                      {{ policy.coinfullname }}
                    </span>
                  </div>
                </td>
                <td *ngIf="policylist_component_for == 'wallet_details'?false:true">
                  <div class="d-flex align-items-center">
                    <img class="align-self-center mr-1 img-fluid img-round-border mr-2"
                      [src]="policy.coin | getIcon">
                    <span>{{policy.coin}}</span>
                  </div>
                </td>

                <td class="light-grey-text" [ngClass]="{'pl-4':policylist_component_for == 'wallet_details'}">
                  <div>
                    <span>{{policy.policytypecustom}}</span>
                    <span *ngIf="policy.subtype.toLowerCase() == 'enable_app'">Whitelist</span>
                    <span *ngIf="policy.subtype.toLowerCase() == 'stakingenabled'">Enable Staking</span>
                  </div>
                </td>
                <td>
                  <div
                    *ngIf="policy.subtype.toLowerCase() == 'address' || policy.subtype.toLowerCase() == 'stakingenabled'">
                    <span class="d-block">{{policy.name}}</span>
                    <app-copy-address [address]="policy.condition"></app-copy-address>
                    <span *ngIf="policy.memo">Memo/Tag - {{policy.memo}}</span>
                  </div>
                  <div *ngIf="policy.subtype.toLowerCase() == 'velocity'">
                    <span class="d-block">{{policy.condition}}</span>
                    <span class="d-block light-grey-text">${{policy.amount | number : '1.2-2'}}</span>
                  </div>
                  <div *ngIf="policy.subtype.toLowerCase() == 'threshold'">
                    <span class="d-block">Per Transaction</span>
                    <span class="d-block light-grey-text">${{policy.amount | number : '1.2-2'}}</span>
                  </div>
                  <div *ngIf="policy.subtype.toLowerCase() == 'enable_app'">
                    <span class="d-block">{{policy.name}}</span>
                    <span class="d-block light-grey-text">Enable Apps</span>
                  </div>
                </td>
                <td>
                  <ng-container *ngIf="policy?.approvers && policy?.approvers.length">
                    <app-signer-user-list [signerUserData]="[policy?.approvers[0]]" [uniqId]="i"
                      [component_for]="'policylistuser'">
                    </app-signer-user-list>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="policy?.approvers && policy?.approvers.length">
                    <app-signer-user-list [signerUserData]="policy?.approvers" [uniqId]="i"
                      [component_for]="'policylistuser'">
                    </app-signer-user-list>
                  </ng-container>
                </td>
                <td class="text-center">
                  <div *ngIf="policy.isArchived === 0" class="enable_dot d-inline-block"></div>
                  <div *ngIf="policy.isArchived === 1" class="archived_dot d-inline-block"></div>
                </td>
                <td>
                  <div class="d-flex justify-content-center">
                    <div class="text-left">
                      <div>
                        {{ policy.created_at | dateWithZone }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div>
                    <span *ngIf="orgRole != 2" class="btn view-only-btn p-0 ml-2" data-original-title="View only"
                      title="View only" type="submit">View only</span>
                    <a
                      *ngIf="(policy.subtype.toLowerCase() == 'velocity' || policy.subtype.toLowerCase() == 'threshold')  && orgRole == 2">
                      <app-add-policy [component_for]="component_for" [changePolicyData]="policy"></app-add-policy>
                    </a>
                    <a
                      *ngIf="(policy.subtype.toLowerCase() == 'address' || policy.subtype.toLowerCase() == 'velocity' || policy.subtype.toLowerCase() == 'threshold') && orgRole == 2">
                      <button appDisableClick class="btn btn-link sign-btn text-red p-0 ml-2"
                        data-original-title="Remove Policy" title="Remove Policy" type="submit"
                        (click)="openRemovePolicyPrompt(policy)">
                        Remove
                      </button>
                    </a>
                  </div>
                </td>
              </tr>
              <tr
                *ngIf="listPolicyData.length == 0 || (listPolicyData | policyListSearch: policySearch | policyTypeFilter:filterPolicyType).length == 0">
                <td colspan="8" class="text-center">No data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="(listPolicyData | policyListSearch: policySearch | policyTypeFilter:filterPolicyType).length > 0" class="mt-3 mb-3">
        <div class="d-flex justify-content-between">
          <span>Showing <span>{{(listPolicyData | policyListSearch: policySearch |
              policyTypeFilter:filterPolicyType).length | paginationFromTo : page : pageSize}}</span> of
            {{(listPolicyData | policyListSearch: policySearch | policyTypeFilter:filterPolicyType).length}}
            entries</span>
          <div class="pagination">
            <ngb-pagination
              [collectionSize]="(listPolicyData | policyListSearch: policySearch | policyTypeFilter:filterPolicyType).length"
              [(page)]="page" [pageSize]="pageSize" [maxSize]="3" [boundaryLinks]="true" [ellipses]="false">
              <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left" placement="top"
                  ngbTooltip="First"></i><i class="icon-arrow-left" placement="top"
                  ngbTooltip="First"></i></ng-template>
              <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left" placement="top"
                  ngbTooltip="Previous"></i></ng-template>
              <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right" placement="top"
                  ngbTooltip="Next"></i></ng-template>
              <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right" placement="top"
                  ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!loading && !listPolicyData.length" class="card d-flex align-items-center p-4 card-shadow-border">
  <img src="../../../assets/images/placeholder/policy.png" width="125" class="mb-4">
  <h4>No policy yet</h4>
  <p class="mb-0">No policy yet, create policy now and secure your wallets.</p>
</div>