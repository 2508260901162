import { Component, Input, OnInit } from "@angular/core";
import { DataService } from "../../services/data.service";
import { AuthServiceJWT } from "../../services/auth.service";
import { HttpService } from "../../services/http.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WalletserviceService } from "../../services/walletservice.service";
import Swal from "sweetalert2";
import { Subject } from "rxjs";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MembersService } from "../../services/members.service";
import { ConstantService } from "../../services/constant.service";
import {
  CUSTODY_TYPE,
  orgType,
  orgUserRequestStatus,
} from "../../entities/members";
import { SegmentService } from 'ngx-segment-analytics';
import { ErrorPrompt } from "../custom-prompt/custom-prompt.service";
@Component({
  selector: "add-team",
  templateUrl: "./add-team.component.html",
  styleUrls: ["./add-team.component.scss"],
})
export class AddTeam implements OnInit {
  total_members;
  dropdownSettingsInitiator: IDropdownSettings = {};
  dropdownSettingsSigner: IDropdownSettings = {};
  dropdownTeamType: IDropdownSettings = {};
  getmem: any;
  minInt = [];
  minSig = [];
  members: any;
  _membersInitiator = [];
  _membersSigner = [];
  selected_asset_value: string;
  added_member = false;
  total_members_limit = false;
  enable_preivew = false;
  user: any;
  createWalletOption = false;
  selected_Asset = false;
  shield: boolean = true;
  fixed_shield = true;
  focusTotInit = false;
  focusToType = false;
  focusTotSign = false;
  teamName: string;
  minimumInitiator = [];
  minimumSigner = [];

  TeamSigningType = '2';
  TeamSigningSubType = '1';

  rangeNode = [
    {
      key: 1,
    },
    {
      key: 2,
    },
    {
      key: 3,
    },
    {
      key: 4,
    },
    {
      key: 5,
    },
    {
      key: 6,
    },
    {
      key: 7,
    },
    {
      key: 8,
    },
    {
      key: 9,
    },
    {
      key: 10,
    },
  ];
  // walletType = [
  //   // {
  //   //   name: 'MultiSig',
  //   //   value: 'multisig_shield'
  //   // },
  //   {
  //     name: "MPC",
  //     value: "mpc",
  //   },
  // ];
  // selected_type: any = {};
  minimum_initiator_selected = 1;
  minimum_signer_selected = 1;
  orgType = orgType;
  custodyType = CUSTODY_TYPE;
  @Input() component_for: string;
  @Input() orgProfile: any;

  private onDestroy$: Subject<void> = new Subject<void>();
  isLoading: boolean = false;
  status: string;

  constructor(
    private org: ConstantService,
    private walletService: WalletserviceService,
    private memberservice: MembersService,
    private data: DataService,
    public authService: AuthServiceJWT,
    private httpService: HttpService,
    private modalService: NgbModal,
    private segment: SegmentService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.init();
  }

  async init() {
    let profile = await this.data.getUserProfile.getValue();
    this.getMember(profile.organizations[0].id);
  }

  async getMember(ordId) {
    let members = await this.httpService.getOrgMembers(ordId);
    this.members = members.members;
    this.members = this.members.filter((e) => e.userType == 1);
  }

  ngOnInit(): void {
    console.log("log org data here", this.orgProfile);

    this.getmem = [];
    this.memberservice.resetMember();
    this.dropdownSettingsInitiator = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      enableCheckAll: false,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: "",
    };
    this.dropdownSettingsSigner = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      closeDropDownOnSelection: false,
      enableCheckAll: false,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: "",
      // limitSelection:1
    };
    this.dropdownTeamType = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      closeDropDownOnSelection: false,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
  }

  addTeam(addTeamModal: any) {
    this.modalService.dismissAll();
    this.status = "";
    this._membersInitiator = [];
    this._membersSigner = [];
    this.minimum_initiator_selected = 1;
    this.minimum_signer_selected = 1;
    this.teamName = "";
    // this.selected_type = {};
    this.minInt = [];
    this.minSig = [];
    this.getmem = [];
    this.memberservice.resetMember();
    // this.selected_type.name = "MPC";
    this._membersInitiator = this.members
      ?.filter((ele) => ele.status === orgUserRequestStatus.active)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
    this._membersSigner = this.members
      ?.filter((ele) => {
        return ele?.providers?.find((e) => e == "mobile_mpc");
      })
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
    this.modalService
      .open(addTeamModal, {
        windowClass: "add-team modal-custom-background",
        centered: true,
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  get getMembers() {
    return this.members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  onMemberSelect(memberOption: any, type) {
    const member = this.getMembers[memberOption.id];

    const isPresentInt = this.minInt.find((data) => data.email == member.email);
    const isPresentSig = this.minSig.find((data) => data.email == member.email);
    if (type === "initiator") {
      if (!isPresentInt || !isPresentSig) {
        this.minInt.push(member);
        this._membersSigner = this._membersSigner
          ?.filter((ele) => ele.email !== member.email)
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.minInitiatorRequired();
      }
    } else if (type === "signer") {
      if (!isPresentSig || !isPresentInt) {
        this.minSig.push(member);
        this._membersInitiator = this._membersInitiator
          ?.filter((ele) => ele.email !== member.email)
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.minSignerRequired();
      }
    }
  }

  onMemberDeSelect(memberOption: any, type) {
    const member: any = this.getMembers[memberOption.id];

    if (type === "initiator") {
      let provider = [];
      if (member?.providers) provider = Object?.values(member?.providers);
      this.minInt = this.minInt.filter((ele) => ele.email !== member.email);
      if (provider?.includes("mobile_mpc")) {
        this._membersSigner = this._membersSigner
          .concat([member])
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      }
      this.minInitiatorRequired();
      if (
        this.minInt.length != 0 &&
        this.minInt.length < this.minimum_initiator_selected
      )
        this.minimum_initiator_selected = this.minInt.length;
    } else if (type === "signer") {
      this.minSig = this.minSig.filter((ele) => ele.email !== member.email);
      if (member?.status === orgUserRequestStatus.active) {
        this._membersInitiator = this._membersInitiator
          .concat([member])
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      }
      this.minSignerRequired();
      if (
        this.minSig.length != 0 &&
        this.minSig.length < this.minimum_signer_selected
      )
        this.minimum_signer_selected = this.minSig.length;
    }
  }

  // onDropDownBtnClick(data) {
  //   // this._membersInitiator = this.members.filter(ele => ele.canBeInitiator == true)
  //   this._membersInitiator = this.members?.filter(
  //     (ele) => ele.status === orgUserRequestStatus.active
  //   );
  //   this.selected_type = data;
  //   if (data.value == "multisig_shield") {
  //     this._membersSigner = this.members
  //       .filter((ele) => {
  //         return ele?.providers?.find((e) => {
  //           if (e == "trezor" || e == "ledger") {
  //             return e;
  //           }
  //         });
  //       })
  //       .sort((a, b) => a.displayName.localeCompare(b.displayName));
  //   } else if (data.value == "mpc") {
  //     this._membersSigner = this.members
  //       ?.filter((ele) => {
  //         return ele?.providers?.find((e) => e == "mobile_mpc");
  //       })
  //       .sort((a, b) => a.displayName.localeCompare(b.displayName));
  //   }
  // }

  numberList() {
    return this.rangeNode;
  }

  minSignerRequired() {
    this.minimumSigner = [];
    for (let x = 1; x <= this.minSig.length; x++) {
      this.minimumSigner.push(x);
    }
  }

  minInitiatorRequired() {
    this.minimumInitiator = [];
    for (let x = 1; x <= this.minInt.length; x++) {
      this.minimumInitiator.push(x);
    }
  }

  async addTeamBtn() {
    this.isLoading = true;

    // if (!this.teamName || !this.minInt.length || !this.minSig.length) {
    //   return
    // }

    const body = {
      name: this.teamName,
      initiator_config:
        this.minimum_initiator_selected + "of" + this.minInt.length,
      signer_config: this.minSig.length
        ? this.minimum_signer_selected + "of" + this.minSig.length
        : null,
      type: "mpc",
      initiator_ids: this.minInt.map((ele) => {
        return ele.id;
      }),
      signer_ids: this.minSig.map((ele) => {
        return ele.id;
      }),
    };
    try {
      const response = await this.httpService.add_team(body).toPromise();
      this.segment.track("team-created", body).catch((err)=>{});

      if (response) {
        this.isLoading = false;
        this.status = "success";
      } else {
        this.modalService.dismissAll();
        this.isLoading = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to create team",
          text: response.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.isLoading = false;
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to add team",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }

  async addTeamBtn2() {
    this.isLoading = true;

    const body = {
      "name" : this.teamName,
      "type" : parseInt(this.TeamSigningType), 
      "members": this.minInt.map((ele) => {
        return ele.id;
      }) // TODO: Change this once role filter is clarified
    };
    if (this.TeamSigningType == '2') body['subType'] = parseInt(this.TeamSigningSubType);
    try {
      const response = await this.httpService.add_team_v2(body).toPromise();
      if (response) {
        this.isLoading = false;
        this.status = "success";
      } else {
        this.modalService.dismissAll();
        this.isLoading = false;
        Swal.fire({
          icon: "error",
          title: "Failed to create team",
          text: response.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.isLoading = false;
      this.modalService.dismissAll();
      Swal.fire({
        icon: "error",
        title: "Failed to add team",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }
}
