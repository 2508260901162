import { Component, Input, OnInit } from '@angular/core';
import BN from "bignumber.js";

@Component({
  selector: 'app-frozen-pending-balance',
  templateUrl: './frozen-pending-balance.component.html',
  styleUrls: ['./frozen-pending-balance.component.scss']
})
export class FrozenPendingBalanceComponent implements OnInit {
  @Input() data: any;
  @Input() component_for: string;
  constructor() { }

  ngOnInit(): void {
  }

  sumofwallets(wallet) {
    let total = new BN(0);
    if (wallet.assets && wallet.assets.length > 0)
      for (let i = 0; i < wallet.assets.length; i++) {
        total = total.plus(new BN(wallet.assets[i].balanceUSD));
      }
    return total
  }

}
