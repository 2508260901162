'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
var GlobalTypes;
(function (GlobalTypes) {
  GlobalTypes[GlobalTypes['UNSIGNED_TX'] = 0] = 'UNSIGNED_TX';
  GlobalTypes[GlobalTypes['GLOBAL_XPUB'] = 1] = 'GLOBAL_XPUB';
})(GlobalTypes = exports.GlobalTypes || (exports.GlobalTypes = {}));
exports.GLOBAL_TYPE_NAMES = ['unsignedTx', 'globalXpub'];
var InputTypes;
(function (InputTypes) {
  InputTypes[InputTypes['NON_WITNESS_UTXO'] = 0] = 'NON_WITNESS_UTXO';
  InputTypes[InputTypes['WITNESS_UTXO'] = 1] = 'WITNESS_UTXO';
  InputTypes[InputTypes['PARTIAL_SIG'] = 2] = 'PARTIAL_SIG';
  InputTypes[InputTypes['SIGHASH_TYPE'] = 3] = 'SIGHASH_TYPE';
  InputTypes[InputTypes['REDEEM_SCRIPT'] = 4] = 'REDEEM_SCRIPT';
  InputTypes[InputTypes['WITNESS_SCRIPT'] = 5] = 'WITNESS_SCRIPT';
  InputTypes[InputTypes['BIP32_DERIVATION'] = 6] = 'BIP32_DERIVATION';
  InputTypes[InputTypes['FINAL_SCRIPTSIG'] = 7] = 'FINAL_SCRIPTSIG';
  InputTypes[InputTypes['FINAL_SCRIPTWITNESS'] = 8] = 'FINAL_SCRIPTWITNESS';
  InputTypes[InputTypes['POR_COMMITMENT'] = 9] = 'POR_COMMITMENT';
})(InputTypes = exports.InputTypes || (exports.InputTypes = {}));
exports.INPUT_TYPE_NAMES = ['nonWitnessUtxo', 'witnessUtxo', 'partialSig', 'sighashType', 'redeemScript', 'witnessScript', 'bip32Derivation', 'finalScriptSig', 'finalScriptWitness', 'porCommitment'];
var OutputTypes;
(function (OutputTypes) {
  OutputTypes[OutputTypes['REDEEM_SCRIPT'] = 0] = 'REDEEM_SCRIPT';
  OutputTypes[OutputTypes['WITNESS_SCRIPT'] = 1] = 'WITNESS_SCRIPT';
  OutputTypes[OutputTypes['BIP32_DERIVATION'] = 2] = 'BIP32_DERIVATION';
})(OutputTypes = exports.OutputTypes || (exports.OutputTypes = {}));
exports.OUTPUT_TYPE_NAMES = ['redeemScript', 'witnessScript', 'bip32Derivation'];