import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../services/http.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { TagifyService } from 'ngx-tagify';
import { SegmentService } from 'ngx-segment-analytics';
import  { ErrorPrompt, SuccessPrompt }  from '../custom-prompt/custom-prompt.service';
import { SuccessPromptPayload } from '../../constants/promptMessages';

enum UserRole {
  member = 1,
  admin = 2,
}

@Component({
  selector: 'app-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss']
})
export class InviteMembersComponent implements OnInit {
  tags = [];
  organization: any;
  org_id: any;
  invite_member = false;
  success = false;
  isOwner:number;
  memberType = [];
  selected_type: any = {};
  inviteBodyData = {
    name: "",
    email: "",
    role: "",
  };
  ifValidEmail: boolean = false;
  @Input() invite_component_for: string;
  @Output() inviteMembersData = new EventEmitter<any>();
  constructor(private modalService: NgbModal, private data: DataService, private httpService: HttpService, private router: Router, private tagifyService: TagifyService, private segment: SegmentService) {
    this.data.getUserProfile.subscribe(async user_detail => {
      if (!user_detail || !user_detail['organizations'].length) return;
      this.org_id = user_detail['organizations'][0].id;
      this.isOwner = user_detail['organizations'][0].isowner;
      if(this.isOwner == 1){
        this.memberType= [
          {
            name: "Member",
            value: "1",
          },
          {
            name: "Admin",
            value: "2",
          },
        ]
      }else{
        this.memberType= [
          {
            name: "Member",
            value: "1",
          },
        ]
      }
      
      if(!this.selected_type){
        this.selected_type = this.memberType[0];
        this.inviteBodyData.role = this.memberType[0].value;
      }
    });
  }

  public settings = {
    placeholder: "Enter email address",
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  };

  selectRole(role, email) {
    this.tags.map(ele => {
      if (ele.value === email) {
        let objIndex = this.tags.findIndex((obj => obj.value === email));
        this.tags[objIndex]["role"] = role;
      }
    });
  }

  emailInput() {
    for (var i = 0; i < this.tags.length; i++) {
      if (!this.tags[i].role) {
        this.tags[i]["role"] = "member";
      }
    }
  }

  removeTag(tag_id) {
    for (var i = 0; i < this.tags.length; i++) {
      if (this.tags[i].value === tag_id) {
        this.tags.splice(i, 1);
      }
    }
    this.tagifyService.get('example').removeTags([tag_id]);
  }

  onDropDownBtnClick(data) {
    this.selected_type = data;
    this.inviteBodyData.role = this.selected_type.value;
  }

  async inviteMembers() {
    this.invite_member = true;
    let body = {
      org: {
        members: [this.inviteBodyData],
        orgid: this.org_id,
      },
    };
      try {
        let response = await this.httpService.inviteMembersOrg(body).toPromise();
          console.log("response", response);
          if (response.success === true) {
            this.segment.track("invitation-sent", body).catch((err)=>{});
            this.invite_member = false;
            this.success = true;
            SuccessPrompt.fire(SuccessPromptPayload.INVITE_MEMBER,() => {
              // Additional actions after modal closed
              this.modalService.dismissAll();
            });
            this.inviteMembersData.emit(true);
          } else {
            this.modalService.dismissAll();
            ErrorPrompt.fire({
              icon: "error",
              title: "Failed to invite member to your organisation",
              text: response.message,
              showConfirmButton: false,
            })
          }
      } catch (e) {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to invite member to your organisation",
          text: e.error.message,
          showConfirmButton: false,
        });
        console.error(e);
      }
  }


  open(content) {
    this.invite_member = true;
    this.inviteBodyData = {
      name: "",
      email: "",
      role: this.memberType[0].value,
    };

    this.tags = [];
    this.success = false;
    this.selected_type = this.memberType[0];
    this.modalService.open(content, { windowClass: 'invite-members modal-custom-background', centered: true });
  }

  emailValidator(): boolean {
    this.ifValidEmail = this.settings.pattern.test(this.inviteBodyData.email);
    return this.ifValidEmail;
  }
  
  validateInput(){
    this.inviteBodyData.name = this.inviteBodyData.name ? this.inviteBodyData.name.replace(/^\s+|\s+$/g, "").replace(/[^a-zA-Z ]/g, "") : "";
  }
  
  ngOnInit(): void {}
}