import { Component, OnInit } from '@angular/core';
import { WalletserviceService } from '../../services/walletservice.service';
import { Wallet } from '../../entities/wallet';
import { DataService } from '../../services/data.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hot-wallet',
  templateUrl: './hot-wallet.component.html',
  styleUrls: ['./hot-wallet.component.scss']
})
export class HotWalletComponent implements OnInit {

  wallets: Wallet[];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private data: DataService,
    private walletService: WalletserviceService,
  ) {

    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallet => {
      this.wallets = this.walletService.getAllWallets().filter(s => {
        if ((s['subtype'] == "hot" || s['subtype'] == "deposit" || s['subtype'] == "hot_pipeline") && s['isArchived'] != 1) {
          return s;
        }
      }).sort((a, b) => b['balanceUSD'] - a['balanceUSD']);
    });
  }

  ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
