var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import { ethers } from "ethers";
import axios from "axios";
import SHA224 from "crypto-js/sha224";
import { getEnv } from "@ledgerhq/live-env";
import EIP712CAL from "@ledgerhq/cryptoassets/data/eip712";
import EIP712CALV2 from "@ledgerhq/cryptoassets/data/eip712_v2";
// As defined in [spec](https://eips.ethereum.org/EIPS/eip-712), the properties below are all required.
export function isEIP712Message(message) {
  return !!message && typeof message === "object" && "types" in message && "primaryType" in message && "domain" in message && "message" in message;
}
export const sortObjectAlphabetically = obj => {
  const keys = Object.keys(obj).sort();
  return keys.reduce((acc, curr) => {
    const value = (() => {
      if (Array.isArray(obj[curr])) {
        return obj[curr].map(field => sortObjectAlphabetically(field));
      }
      return obj[curr];
    })();
    acc[curr] = value;
    return acc;
  }, {});
};
export const getSchemaHashForMessage = message => {
  const {
    types
  } = message;
  const sortedTypes = sortObjectAlphabetically(types);
  return SHA224(JSON.stringify(sortedTypes).replace(" ", "")).toString();
};
/**
 * Tries to find the proper filters for a given EIP712 message
 * in the CAL
 *
 * @param {EIP712Message} message
 * @returns {MessageFilters | undefined}
 */
export const getFiltersForMessage = (message, shouldUseV1Filters, calServiceURL) => __awaiter(void 0, void 0, void 0, function* () {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const schemaHash = getSchemaHashForMessage(message);
  const verifyingContract = ((_b = (_a = message.domain) === null || _a === void 0 ? void 0 : _a.verifyingContract) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || ethers.constants.AddressZero;
  try {
    if (calServiceURL) {
      const {
        data
      } = yield axios.get(`${calServiceURL}/v1/dapps`, {
        params: {
          output: "eip712_signatures",
          eip712_signatures_version: shouldUseV1Filters ? "v1" : "v2",
          chain_id: (_c = message.domain) === null || _c === void 0 ? void 0 : _c.chainId,
          contracts: verifyingContract
        }
      });
      const filters = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data[0]) === null || _d === void 0 ? void 0 : _d.eip712_signatures) === null || _e === void 0 ? void 0 : _e[verifyingContract]) === null || _f === void 0 ? void 0 : _f[schemaHash];
      if (!filters) {
        // Fallback to catch
        throw new Error("Fallback to static file");
      }
      return filters;
    }
    // Fallback to catch
    throw new Error("Fallback to static file");
  } catch (e) {
    const messageId = `${(_h = (_g = message.domain) === null || _g === void 0 ? void 0 : _g.chainId) !== null && _h !== void 0 ? _h : 0}:${verifyingContract}:${schemaHash}`;
    if (shouldUseV1Filters) {
      return EIP712CAL[messageId];
    }
    return EIP712CALV2[messageId];
  }
});
/**
 * Get the value at a specific path of an object and return it as a string or as an array of string
 * Used recursively by getValueFromPath
 *
 * @see getValueFromPath
 */
const getValue = (path, value) => {
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return value.map(v => getValue(path, v)).flat();
    }
    /* istanbul ignore if : unecessary test of a throw */
    if (!(path in value)) {
      throw new Error(`Could not find key ${path} in ${JSON.stringify(value)} `);
    }
    const result = value[path];
    return typeof result === "object" ? result : result.toString();
  }
  return value.toString();
};
/**
 * Using a path as a string, returns the value(s) of a json key without worrying about depth or arrays
 * (e.g: 'to.wallets.[]' => ["0x123", "0x456"])
 */
export const getValueFromPath = (path, eip721Message) => {
  const splittedPath = path.split(".");
  const {
    message
  } = eip721Message;
  let value = message;
  for (let i = 0; i <= splittedPath.length - 1; i++) {
    const subPath = splittedPath[i];
    const isLastElement = i >= splittedPath.length - 1;
    if (subPath === "[]" && !isLastElement) continue;
    value = getValue(subPath, value);
  }
  /* istanbul ignore if : unecessary test of a throw */
  if (value === message) {
    throw new Error("getValueFromPath returned the whole original message");
  }
  return value;
};
/**
 * Gets the fields visible on the nano for a specific EIP712 message
 */
export const getEIP712FieldsDisplayedOnNano = (messageData_1, ...args_1) => __awaiter(void 0, [messageData_1, ...args_1], void 0, function* (messageData, calServiceURL = getEnv("CAL_SERVICE_URL")) {
  if (!isEIP712Message(messageData)) {
    return null;
  }
  const _j = messageData.types,
    {
      EIP712Domain
    } = _j,
    otherTypes = __rest(_j, ["EIP712Domain"]);
  const displayedInfos = [];
  const filters = yield getFiltersForMessage(messageData, false, calServiceURL);
  if (!filters) {
    const {
      types
    } = messageData;
    const domainFields = types["EIP712Domain"].map(({
      name
    }) => name);
    if (domainFields.includes("name") && messageData.domain.name) {
      displayedInfos.push({
        label: "name",
        value: messageData.domain.name
      });
    }
    if (domainFields.includes("version") && messageData.domain.version) {
      displayedInfos.push({
        label: "version",
        value: messageData.domain.version
      });
    }
    if (domainFields.includes("chainId") && messageData.domain.chainId) {
      displayedInfos.push({
        label: "chainId",
        value: messageData.domain.chainId.toString()
      });
    }
    if (domainFields.includes("verifyingContract") && messageData.domain.verifyingContract) {
      displayedInfos.push({
        label: "verifyingContract",
        value: messageData.domain.verifyingContract.toString()
      });
    }
    if (domainFields.includes("salt") && messageData.domain.salt) {
      displayedInfos.push({
        label: "salt",
        value: messageData.domain.salt.toString()
      });
    }
    displayedInfos.push({
      label: "Message hash",
      value: ethers.utils._TypedDataEncoder.hashStruct(messageData.primaryType, otherTypes, messageData.message)
    });
    return displayedInfos;
  }
  const {
    contractName,
    fields
  } = filters;
  if (contractName && contractName.label) {
    displayedInfos.push({
      label: "Contract",
      value: contractName.label
    });
  }
  for (const field of fields) {
    displayedInfos.push({
      label: field.label,
      value: getValueFromPath(field.path, messageData)
    });
  }
  return displayedInfos;
});
