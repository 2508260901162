<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div *ngIf="wallets && wallets.length == 0" class="card d-flex align-items-center p-4 card-shadow-border">
                <img src="../../../assets/images/placeholder/transaction.png" width="125" class="mb-4">
                <h6>No wallets yet</h6>
                <p class="mb-0">You currently do not have any wallets.</p>
            </div>
            <div class="card" *ngIf="wallets && wallets.length > 0">
                <div class="card-body p-3">
                    <div class="card-border mb-3 card input-bk">
                        <div class="d-flex align-items-center justify-content-between p-3">
                            <div class="d-flex align-items-center">
                                <div class="font-size-16 mr-3 holding_icon img-round-border">

                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3732_1280)">
                                            <path
                                                    d="M14.9721 16.4737C14.624 16.4737 14.3418 16.7559 14.3418 17.104V18.6798H1.73526V1.66104H14.3418V1.9762C14.3418 2.3243 14.624 2.60653 14.9721 2.60653C15.3202 2.60653 15.6024 2.3243 15.6024 1.9762V1.03072C15.6024 0.682621 15.3202 0.400391 14.9721 0.400391H1.10494C0.75684 0.400391 0.474609 0.682621 0.474609 1.03072V19.3102C0.474609 19.6583 0.75684 19.9405 1.10494 19.9405H14.9721C15.3202 19.9405 15.6024 19.6583 15.6024 19.3102V17.104C15.6024 16.7559 15.3202 16.4737 14.9721 16.4737Z"
                                                    fill="#2BB673"/>
                                            <path
                                                    d="M3.62618 7.01871H5.83232C6.18041 7.01871 6.46264 6.73648 6.46264 6.38838C6.46264 6.04029 6.18041 5.75806 5.83232 5.75806H3.62618C3.27808 5.75806 2.99585 6.04029 2.99585 6.38838C2.99585 6.73648 3.27808 7.01871 3.62618 7.01871Z"
                                                    fill="#2BB673"/>
                                            <path
                                                    d="M3.62618 9.54019H4.57166C4.91976 9.54019 5.20199 9.25796 5.20199 8.90987C5.20199 8.56177 4.91976 8.27954 4.57166 8.27954H3.62618C3.27808 8.27954 2.99585 8.56177 2.99585 8.90987C2.99585 9.25796 3.27808 9.54019 3.62618 9.54019Z"
                                                    fill="#2BB673"/>
                                            <path
                                                    d="M3.62618 12.0614H5.20199C5.55008 12.0614 5.83232 11.7792 5.83232 11.4311C5.83232 11.083 5.55008 10.8008 5.20199 10.8008H3.62618C3.27808 10.8008 2.99585 11.083 2.99585 11.4311C2.99585 11.7792 3.27808 12.0614 3.62618 12.0614Z"
                                                    fill="#2BB673"/>
                                            <path
                                                    d="M3.62618 14.5827H7.40813C7.75622 14.5827 8.03845 14.3004 8.03845 13.9523C8.03845 13.6043 7.75622 13.322 7.40813 13.322H3.62618C3.27808 13.322 2.99585 13.6043 2.99585 13.9523C2.99585 14.3004 3.27808 14.5827 3.62618 14.5827Z"
                                                    fill="#2BB673"/>
                                            <g clip-path="url(#clip1_3732_1280)">
                                                <path
                                                        d="M19.0372 11.8414V13.3505C19.0372 14.3516 18.2939 15.1734 17.3885 15.1734H10.3525C9.4471 15.1734 8.70386 14.3516 8.70386 13.3505V6.99036C8.70386 5.98927 9.4471 5.16748 10.3525 5.16748H17.3885C18.2939 5.16748 19.0372 5.98927 19.0372 6.99036V8.5443"
                                                        stroke="#2BB673" stroke-miterlimit="10"/>
                                                <path
                                                        d="M18.2128 11.9509H16.9336C16.1047 11.9509 15.4246 11.1989 15.4246 10.2825V10.0484C15.4246 9.13195 16.1047 8.37988 16.9336 8.37988H18.2128C19.0417 8.37988 19.7218 9.13195 19.7218 10.0484V10.2825C19.7218 11.2039 19.0417 11.9509 18.2128 11.9509Z"
                                                        stroke="#2BB673" stroke-miterlimit="10"/>
                                                <path
                                                        d="M17.204 10.681C17.4602 10.681 17.6679 10.4513 17.6679 10.168C17.6679 9.88471 17.4602 9.65503 17.204 9.65503C16.9477 9.65503 16.74 9.88471 16.74 10.168C16.74 10.4513 16.9477 10.681 17.204 10.681Z"
                                                        stroke="#2BB673" stroke-width="0.3" stroke-miterlimit="10"/>
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3732_1280">
                                                <rect width="20.1704" height="20.1704" fill="white"
                                                      transform="translate(0.00195312 0.0852051)"/>
                                            </clipPath>
                                            <clipPath id="clip1_3732_1280">
                                                <rect width="11.9189" height="11.002" fill="white"
                                                      transform="translate(8.25342 4.66943)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <div>
                                    <p class="m-0 font-weight-bold">Holding Statement</p>
                                    <p class="m-0 text-dark">
                                        Holding statement allows you to download the report of current balance of assets
                                        in all wallets
                                    </p>
                                </div>
                            </div>
                            <div [ngClass]="{'disabled': disableCSV}" class=" mx-1"
                                    style="margin-left: auto !important; padding-right: 6px !important;" ngbDropdown
                                    [autoClose]="'outside'"
                                    #myDropDownloads="ngbDropdown">
                                    <div class="d-flex btn-with-hover btn btn-round m-auto h-39 pl-0 pr-0">
                                        <button 
                                            (blur)="focusTotInitDownload=false" class="btn btn-outline-primary btn-round pr-2" 
                                            [ngClass]="{'disabled': disableCSV}" [disabled]="disableCSV"
                                            (click)="myDropDownloads.open();focusTotInitDownload=!focusTotInitDownload"
                                    >
                                        Download
                                        <img src="../../../assets/images/down_arrow.svg"
                                             (click)="focusTotInitDownload ? myDropDownloads.close() : myDropDownloads.open()"
                                             class="arrow-svg ml-2 cursor-pointer"
                                             [ngClass]="{'rotate-icon': focusTotInitDownload}">
                                    </button>
                                </div>
                                <ul ngbDropdownMenu class="whitelistscroll transform-download py-0"
                                    style="max-height: 12rem !important; overflow: auto;">
                                    <div class="d-flex align-items-center p-0"
                                         style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                        <label class="m-0">
                                            <li ngbDropdownItem class="p-0 pt-2 pb-2  pl-3 text-wrap cursor-pointer li-width"
                                                (click)="downloadHoldingPdf(downloadPdfTemplate);myDropDownloads.close()">
                                                <div class="d-flex align-items-center ">
                                                    <div class="text-left text-margin">
                                                        <div class="text-dark ">
                                                            <img src="../../../assets/images/pdf.png" class="mr-2">
                                                            Download PDF
                                                        </div>
                                                    </div>
                                                    <div class="ml-auto"></div>
                                                </div>
                                            </li>
                                            <li ngbDropdownItem class="p-0 pt-2 pb-2  pl-3 text-wrap cursor-pointer li-width"
                                                (click)="holdingStatement(downloadCSVTemplate);myDropDownloads.close()">
                                                <div class="d-flex align-items-center ">
                                                    <div class="text-left text-margin">
                                                        <div class="text-dark ">
                                                            <img src="../../../assets/images/csv.png" class="mr-2">
                                                            Download CSV
                                                        </div>
                                                    </div>
                                                    <div class="ml-auto"></div>
                                                </div>
                                            </li>
                                        </label>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-border mb-3 card input-bk">
                        <div class="d-flex align-items-center justify-content-between p-3">
                            <div class="d-flex align-items-center">
                                <div class="font-size-16 mr-3 account_icon img-round-border">

                                    <img class="img-fluid" src="../../../../assets/images/accountIcon.svg"/>
                                </div>
                                <div>
                                    <p class="m-0 font-weight-bold">Account Statement</p>
                                    <p class="m-0 text-dark">
                                        Account statement allows you to download the monthly transaction reports of all
                                        wallets
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div [ngClass]="{'disabled': newOrg}" class=" mx-1"
                                     style="margin-left: auto !important; padding-right: 6px !important;" ngbDropdown
                                     [autoClose]="'outside'"
                                     #myDropInitiator="ngbDropdown">
                                    <div class="d-flex btn-with-hover btn btn-round m-auto h-39 pl-0 pr-0">
                                        <input autocomplete="off" class="form-control search-custom test input-hover"
                                               id="dropdownManual2" ngbDropdownAnchor
                                               (focus)="!newOrg&&myDropInitiator.open(); !newOrg&&focusTotInit=true"
                                               (blur)="!newOrg&&focusTotInit=false" type="text"
                                               [value]="accountFilterDates.length ? accountFilterDates.length > 1 ? accountFilterDates.length + ' Months selected' : accountFilterDates[0].date : 'Select month'"
                                               (keydown)="$event.preventDefault()" [ngClass]="{'disabled': newOrg}"/>

                                        <svg class="arrow-svg" *ngIf="!focusTotInit"
                                             (click)="!newOrg&&myDropInitiator.open()" width="15"
                                             height="8" viewBox="0 0 15 8" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M14.5009 0.231167C14.2634 0.0624024 13.9578 -0.018849 13.6508 0.00511375C13.3439 0.0290765 13.0603 0.156311 12.862 0.3591L7.93651 5.43752L3.01091 0.359184C2.81318 0.155599 2.52944 0.0278505 2.2221 0.00404118C1.91476 -0.0197681 1.609 0.0623121 1.37209 0.232225C1.13517 0.402138 0.986511 0.645966 0.958804 0.910069C0.931097 1.17417 1.02661 1.43692 1.22434 1.6405L7.04322 7.64077C7.15238 7.75325 7.28898 7.84375 7.44334 7.90585C7.5977 7.96796 7.76606 8.00015 7.93651 8.00015C8.10695 8.00015 8.27531 7.96796 8.42968 7.90585C8.58404 7.84375 8.72063 7.75325 8.82979 7.64077L14.6487 1.64042C14.8463 1.43663 14.9418 1.17375 14.9141 0.909527C14.8864 0.645302 14.7378 0.401323 14.5009 0.231167Z"
                                                    fill="#A7ADB7"/>
                                        </svg>
                                        <svg class="arrow-svg" *ngIf="focusTotInit"
                                             (click)="!newOrg&&myDropInitiator.close()" width="15" height="8"
                                             viewBox="0 0 15 8" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M1.37199 7.76899C1.60956 7.93775 1.91512 8.019 2.22209 7.99504C2.52907 7.97108 2.81258 7.84384 3.01088 7.64105L7.93642 2.56264L12.862 7.64097C13.0597 7.84455 13.3435 7.9723 13.6508 7.99611C13.9582 8.01992 14.2639 7.93784 14.5008 7.76793C14.7378 7.59801 14.8864 7.35419 14.9141 7.09008C14.9418 6.82598 14.8463 6.56324 14.6486 6.35965L8.8297 0.359385C8.72054 0.246898 8.58395 0.156401 8.42958 0.0942984C8.27522 0.0321956 8.10686 5.72205e-06 7.93642 5.72205e-06C7.76597 5.72205e-06 7.59761 0.0321956 7.44325 0.0942984C7.28889 0.156401 7.15229 0.246898 7.04313 0.359385L1.22425 6.35974C1.02659 6.56353 0.931129 6.8264 0.958829 7.09063C0.986527 7.35485 1.13512 7.59883 1.37199 7.76899Z"
                                                    fill="#A7ADB7"/>
                                        </svg>
                                    </div>
                                    <ul ngbDropdownMenu class="whitelistscroll py-0"
                                        style="max-height: 12rem !important; overflow: auto;">
                                        <div ngbDropdownItem class="d-flex align-items-center p-0 pl-3"
                                             *ngFor="let data of customCalenderAccount;let i = index;"
                                             style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                            <input type="checkbox" class="dropdown-content brd_Box p-0 mr-2"
                                                   (change)="handleDateFilter($event, data, ReportTypeEnum.ACCOUNT)"
                                                   id="date{{i}}">
                                            <label for="date{{i}}" class="m-0">
                                                <li class="p-0 pt-2 pb-2 text-wrap">
                                                    <div class="d-flex align-items-center ">
                                                        <div class="text-left text-margin">
                                                            <div class="text-dark ">
                                                                {{ data?.date }}
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto"></div>
                                                    </div>
                                                </li>
                                            </label>
                                        </div>
                                    </ul>
                                </div>
                                <div
                                        [ngClass]="{'disabled': newOrg || !accountFilterDates.length}"
                                        class=" mx-1"
                                        style="margin-left: auto !important; padding-right: 6px !important;" ngbDropdown
                                        [autoClose]="'outside'"
                                        #myDropDownloadsAcc="ngbDropdown">
                                    <div class="d-flex btn-with-hover btn btn-round m-auto h-39 pl-0 pr-0">
                                        <button
                                                (blur)="focusTotInitDownloadAcc=false"
                                                class="btn btn-outline-primary btn-round pr-2"
                                                [ngClass]="{'disabled': newOrg || !accountFilterDates.length}"
                                                [disabled]="newOrg || !accountFilterDates.length"
                                                (click)="myDropDownloadsAcc.open();focusTotInitDownloadAcc=!focusTotInitDownloadAcc"
                                        >
                                            Download
                                            <img src="../../../assets/images/down_arrow.svg"
                                                 (click)="focusTotInitDownloadAcc ? myDropDownloadsAcc.close() : myDropDownloadsAcc.open()"
                                                 class="arrow-svg ml-2 cursor-pointer"
                                                 [ngClass]="{'rotate-icon': focusTotInitDownloadAcc}">
                                        </button>
                                    </div>
                                    <ul ngbDropdownMenu class="whitelistscroll transform-download py-0"
                                        style="max-height: 12rem !important; overflow: auto;">
                                        <div class="d-flex align-items-center p-0"
                                             style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                            <label class="m-0">
                                                <li ngbDropdownItem class="p-0 pt-2 pb-2 pl-3 text-wrap cursor-pointer li-width"
                                                    (click)="!loadingAcc[0]&&downloadMultipleReports(ReportTypeEnum.ACCOUNT, ReportDownloadTypeEnum.PDF);myDropDownloadsAcc.close()">
                                                    <div class="d-flex align-items-center ">
                                                        <div class="text-left text-margin">
                                                            <div class="text-dark ">
                                                                <img src="../../../assets/images/pdf.png" class="mr-2">
                                                                Download PDF
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto"></div>
                                                    </div>
                                                </li>
                                                <li ngbDropdownItem class="p-0 pt-2 pb-2 pl-3 text-wrap cursor-pointer li-width"
                                                    (click)="!loadingAcc[0]&&downloadMultipleReports(ReportTypeEnum.ACCOUNT, ReportDownloadTypeEnum.CSV);myDropDownloadsAcc.close()">
                                                    <div class="d-flex align-items-center ">
                                                        <div class="text-left text-margin">
                                                            <div class="text-dark ">
                                                                <img src="../../../assets/images/csv.png" class="mr-2">
                                                                Download CSV
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto"></div>
                                                    </div>
                                                </li>
                                            </label>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ng-container *ngIf="false">
            <div class="card" *ngIf="wallets && wallets.length > 0">
                <div class="card-body p-3" *ngIf="!isCustody">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="d-flex align-items-center">
                            <h2 class="m-0">
                                Compliance
                            </h2>
                        </div>
                    </div>
                    <div class="card-border mb-3 card input-bk">
                        <div class="d-flex align-items-center justify-content-between p-3">
                            <div class="d-flex align-items-center">
                                <div class="font-size-16 mr-3 compliance_rep_icon img-round-border">

                                    <img class="img-fluid" src="../../../../assets/images/complienceIcon.svg"/>

                                </div>
                                <div>
                                    <p class="m-0 font-weight-bold">Compliance Report</p>
                                    <p class="m-0 text-dark">
                                        Compliance report allows you to download the risk report of all screened
                                        transactions
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class=" mx-1" style="margin-left: auto !important; padding-right: 6px !important;"
                                     ngbDropdown #myDropCompliance="ngbDropdown"
                                     [autoClose]="'outside'">
                                    <div class="d-flex btn-with-hover btn btn-round m-auto h-39 pl-0 pr-0">
                                        <input autocomplete="off" class="form-control search-custom test "
                                               id="dropdownManual" ngbDropdownAnchor
                                               (focus)="myDropCompliance.open(); focusCompliance=true"
                                               (blur)="focusCompliance=false" type="text"
                                               [value]="complianceFilterDates.length ? complianceFilterDates.length > 1 ? complianceFilterDates.length + ' Months selected' : complianceFilterDates[0].date : 'Select month'"
                                               (keydown)="$event.preventDefault()"/>
                                        <svg class="arrow-svg" *ngIf="!focusCompliance"
                                             (click)="myDropCompliance.open()" width="15"
                                             height="8" viewBox="0 0 15 8" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M14.5009 0.231167C14.2634 0.0624024 13.9578 -0.018849 13.6508 0.00511375C13.3439 0.0290765 13.0603 0.156311 12.862 0.3591L7.93651 5.43752L3.01091 0.359184C2.81318 0.155599 2.52944 0.0278505 2.2221 0.00404118C1.91476 -0.0197681 1.609 0.0623121 1.37209 0.232225C1.13517 0.402138 0.986511 0.645966 0.958804 0.910069C0.931097 1.17417 1.02661 1.43692 1.22434 1.6405L7.04322 7.64077C7.15238 7.75325 7.28898 7.84375 7.44334 7.90585C7.5977 7.96796 7.76606 8.00015 7.93651 8.00015C8.10695 8.00015 8.27531 7.96796 8.42968 7.90585C8.58404 7.84375 8.72063 7.75325 8.82979 7.64077L14.6487 1.64042C14.8463 1.43663 14.9418 1.17375 14.9141 0.909527C14.8864 0.645302 14.7378 0.401323 14.5009 0.231167Z"
                                                    fill="#A7ADB7"/>
                                        </svg>
                                        <svg class="arrow-svg" *ngIf="focusCompliance"
                                             (click)="myDropCompliance.close()" width="15" height="8" viewBox="0 0 15 8"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M1.37199 7.76899C1.60956 7.93775 1.91512 8.019 2.22209 7.99504C2.52907 7.97108 2.81258 7.84384 3.01088 7.64105L7.93642 2.56264L12.862 7.64097C13.0597 7.84455 13.3435 7.9723 13.6508 7.99611C13.9582 8.01992 14.2639 7.93784 14.5008 7.76793C14.7378 7.59801 14.8864 7.35419 14.9141 7.09008C14.9418 6.82598 14.8463 6.56324 14.6486 6.35965L8.8297 0.359385C8.72054 0.246898 8.58395 0.156401 8.42958 0.0942984C8.27522 0.0321956 8.10686 5.72205e-06 7.93642 5.72205e-06C7.76597 5.72205e-06 7.59761 0.0321956 7.44325 0.0942984C7.28889 0.156401 7.15229 0.246898 7.04313 0.359385L1.22425 6.35974C1.02659 6.56353 0.931129 6.8264 0.958829 7.09063C0.986527 7.35485 1.13512 7.59883 1.37199 7.76899Z"
                                                    fill="#A7ADB7"/>
                                        </svg>
                                    </div>
                                    <ul ngbDropdownMenu class="whitelistscroll py-0"
                                        style="max-height: 12rem !important; overflow: auto;">
                                        <div ngbDropdownItem class="d-flex align-items-center p-0 pl-3"
                                             *ngFor="let data of customCalenderCompliance; let i = index;"
                                             style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                            <input type="checkbox" class="dropdown-content brd_Box p-0 mr-2"
                                                   (change)="handleDateFilter($event, data, ReportTypeEnum.COMPLIANCE)"
                                                   id="cdate{{i}}">
                                            <label for="cdate{{i}}" class="m-0">
                                                <li class="p-0 pt-2 pb-2 text-wrap">
                                                    <div class="d-flex align-items-center ">
                                                        <div class="text-left text-margin">
                                                            <div class="text-dark ">
                                                                {{ data?.date }}
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto"></div>
                                                    </div>
                                                </li>
                                            </label>
                                        </div>
                                    </ul>
                                </div>
                                <button (click)="!loading[0]&&downloadMultipleReports(ReportTypeEnum.COMPLIANCE)"
                                        class="btn btn-outline-primary btn-round"
                                        [style]="loading[0]&&'padding-right: 2rem !important;'" style="width: 11.5rem;"
                                        [ngClass]="{'disabled': !complianceFilterDates.length}"
                                        [disabled]="!complianceFilterDates.length">
                                    <i *ngIf="!loading[0]" aria-hidden="true" class="icon-download mr-2"
                                       [ngClass]="{'disabled': !complianceFilterDates.length}"></i>
                                    <span *ngIf="!loading[0]" [ngClass]="{'disabled': !complianceFilterDates.length}">Download CSV</span>
                                    <span *ngIf="loading[0]">Processing</span>
                                    <span class="loader" *ngIf="loading[0]"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        </div>
    </div>
</div>

<ng-template #downloadPdfTemplate let-modal>
    <div class="modal-header border-0 px-4">
        <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss('cross_click');">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="empty d-flex justify-content-center">
                <img src="../../../assets/images/mail.svg" alt="icon">
            </div>
            <div class="txt txt-format ">
                <span>You will receive a mail containing PDF for your Holding Statement Shortly!</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary w-100" (click)="modal.dismiss()">Close</button>
    </div>
</ng-template>

<ng-template #downloadCSVTemplate let-modal>
    <div class="modal-header border-0 px-4">
        <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss('cross_click');">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="empty d-flex justify-content-center img-height">
                <img src="../../../assets/images/downloadstars.svg" alt="icon" width="350">
            </div>
            <div class="txt txt-format ">
                <span>Your Holding Statement CSV is now downloading. Please check your browser downloads.</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary w-100" (click)="modal.dismiss()">Close</button>
    </div>
</ng-template>