import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-signer-user-list',
  templateUrl: './signer-user-list.component.html',
  styleUrls: ['./signer-user-list.component.scss']
})
export class SignerUserListComponent implements OnInit {
  scrollId: number;

  @Input() component_for: string;
  @Input() signerUserData: any;
  @Input() uniqId:any;
  constructor () {}

  ngOnInit(): void {
    // if (this.component_for == 'txuser') {
    //  this.signerUserData.walletinfor.walletKeys = this.signerUserData.walletinfor.walletKeys.map((signer) => {
    //     signer.isSigned = false;
    //     if (this.signerUserData.signs.filter(sign => sign.userid == signer.userid).length) {
    //       signer.isSigned = true;
    //     }
    //     return signer;
    //   });
    // }
  }
  prev(id:string, event: Event) {
    this.sideScroll(id, 'left', 25, 45, 15);
    event.stopPropagation();
  }
  next(id:string, event: Event) {
    this.sideScroll(id, 'right', 25, 45, 15);
    event.stopPropagation();
  }
  sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      if (direction == 'left') {
        document.getElementById(element).scrollLeft -= step;
      } else {
        document.getElementById(element).scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
}