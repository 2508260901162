<button appDisableClick id="CB_send_asset" class="btn btn-round btn-outline-primary" *ngIf="sendassets_component_for === 'normal_send'" type="submit" (click)="openD(content3)">
  <i class="icon-send mr-2" aria-hidden="true"></i> Send
</button>
<div class="modal-footer border-0" *ngIf="sendassets_component_for === 'sendbyUnstake'">
  <button appDisableClick class="btn btn-outline-primary w-100 send"
   type="submit" (click)="openD(content3)">
  Send
</button>
</div>
<button appDisableClick class="btn btn-round btn-outline-primary" *ngIf="sendassets_component_for === 'wallet_details_sendassets'"
[ngClass]="{'disabled': singleWallet?.tag ===  'hot' || singleWallet?.tag ===  'hot_pipeline' || singleWallet.isArchived === 1 || singleWallet?.access  == 'READ' || singleWallet?.access  == 'VIEWER' || (!singleWallet.isInitiator && singleWallet?.version  == 2)}"
[disabled]="singleWallet?.tag ===  'hot' || singleWallet?.tag ===  'hot_pipeline' || singleWallet.isArchived === 1 || singleWallet?.access  == 'READ' || singleWallet?.access  == 'VIEWER' || (!singleWallet.isInitiator && singleWallet?.version  == 2)"
  type="submit" (click)="openD(content3)"> <i class="icon-send mr-2"></i>  Send
</button> 

<button appDisableClick *ngIf="sendassets_component_for == 'wallet_dropdown_sendassets'" 
[ngClass]="{'disabled': singleWallet?.tags[0] ==  'hot' || singleWallet?.tags[0] ==  'hot_pipeline' || singleWallet?.isArchived || singleWallet?.access  == 'READ' || singleWallet?.access  == 'VIEWER' || (!singleWallet.isInitiator && singleWallet?.version  == 2)}"
[disabled]="singleWallet?.tags[0] ==  'hot' || singleWallet?.tags[0] ==  'hot_pipeline' || singleWallet.isArchived || singleWallet?.access  == 'READ' || singleWallet?.access  == 'VIEWER' || (!singleWallet.isInitiator && singleWallet?.version  == 2)" class="btn px-4 py-2" style="padding: 0.25rem 1.5rem;" 
(click)="((singleWallet?.tags[0] !=  'hot' || singleWallet?.tags[0] !=  'hot_pipeline') && !singleWallet.isArchived) && openD(content3)"
>
  <i class="icon-send mt-1 mr-2 pl-1" ></i>
  <span style="margin-left: 2px;">Send</span>
</button>

<button appDisableClick *ngIf="sendassets_component_for == 'gas-station'" disabled class="btn px-4 py-2 disabled" style="padding: 0.25rem 1.5rem;" (click)="openD(content3)">
  <i class="icon-send mt-1 mr-2 pl-1" ></i>
  <span style="margin-left: 2px;">Send</span>
</button>

<ng-template #content3 let-modal>
  <div *ngIf="!sender_details">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-send text-dark mr-2"></i>
        <ng-container>Send Assets</ng-container>
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="mb-4" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
            <ng-container [ngTemplateOutlet]="walletLabel"></ng-container>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search wallet" id="dropdownManual"
              [(ngModel)]="searchWalletInput" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
            <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
              <div class="d-flex position-relative" *ngFor="let walletdata of filterArchive(_wallets) | wallet_search: searchWalletInput" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                <div class="w-100" [ngbTooltip]="((walletdata.access  == IWalletUserType.READ || walletdata.access  == IWalletUserType.VIEWER) && 'Send is disabled for view only members') || ((!walletdata.isInitiator && walletdata.version  == 2) && 'Send is disabled for signers')" tooltipClass="tooltip-view-wallet">
                  <button class="dropdown-content w-100 p-0 m-0 border-0" [ngClass]="{'disabled': (walletdata.access  == IWalletUserType.READ || walletdata.access  == IWalletUserType.VIEWER) || (!walletdata.isInitiator && walletdata.version  == 2) }"
                  [disabled]="(walletdata.access  == IWalletUserType.READ || walletdata.access  == IWalletUserType.VIEWER) || (!walletdata.isInitiator && walletdata.version  == 2)" (click)="selectWallet(walletdata)">
                  <app-wallet-card [option]=walletdata [AllWallets]="wallets" [dropdown_card]="'dropdown-card'"
                    [card_padding]="'p-3'"></app-wallet-card>
                </button>
                </div>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedWallet">
            <ng-container [ngTemplateOutlet]="walletLabel"></ng-container>
            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedWallet && !(sendassets_component_for === 'wallet_details_sendassets') && !(sendassets_component_for === 'gas-station')"
              (click)="selectedWallet = !selectedWallet;selectedAsset? selectedAsset = !selectedAsset:'';selectedAddress? selectedAddress = !selectedAddress:'';
              selectedNFT? selectedNFT = !selectedNFT:'';assets? assets = []:'';walletNFT? walletNFT = []:''">
              Clear
            </span>
            <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'"></app-wallet-card>
          </div>
          <ng-container *ngIf="orgProfile.orgType !== orgType.sub">
            <div class="mb-4" *ngIf="sendassets_component_for != 'gas-station'">
              <!-- <div class="d-flex">
                <button class="type-pill-button" [ngClass]="{ 'active': sendTxType == 'send_assets', 'disabled': !assets }" (click)="selectTxType('send_assets')" [disabled]="!assets">
                  Assets 
                 <ng-container *ngIf="selectedWallet"> &nbsp;({{assets?.length}}) </ng-container>
                </button>
                <button class="type-pill-button" [ngClass]="{ 'active': sendTxType == 'send_nft', 'disabled': !walletNFT.length  }" (click)="selectTxType('send_nft')" [disabled]="!walletNFT.length">
                  NFT 
                  <ng-container *ngIf="selectedWallet"> &nbsp;({{walletNFT?.length}}) </ng-container>
                </button>
              </div> -->
            </div>
          </ng-container>
          
          <ng-container *ngIf="sendTxType == 'send_assets'">
          <div class="mb-4" ngbDropdown #myDropAssets="ngbDropdown" *ngIf="!selectedAsset">
            <label class="text-dark">Asset</label>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search asset" id="dropdownManual1"
              [(ngModel)]="searchAssetInput" ngbDropdownAnchor (focus)="myDropAssets.open()" type="text"
              [ngClass]="{'disabled': !selectedWallet}" [disabled]="!selectedWallet">
            <ul ngbDropdownMenu aria-labelledby="dropdownManual1" class="whitelistscroll w-100 py-0">
              <div class="d-flex" *ngFor="let assetsdata of assets | wallet_search: searchAssetInput"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                <button class="dropdown-content w-100 p-0 m-0 border-0">
                  <app-wallet-card [option]=assetsdata [dropdown_card]="'dropdown-card'" [card_padding]="'p-3'"
                    [assetsCard]="'assets_card'" (click)="selectAssets(assetsdata)"></app-wallet-card>
                </button>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedAsset">
            <label class="text-dark">Asset</label>
            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedAsset && !(sendassets_component_for === 'gas-station')"
              (click)="selectedAsset = !selectedAsset;selectedAddress? selectedAddress = !selectedAddress:''">Clear</span>
            <app-wallet-card [option]=selectedAsset [assetsCard]="'assets_card'" [card_padding]="'p-3'">
            </app-wallet-card>
          </div>
        </ng-container>
        <ng-container *ngIf="sendTxType == 'send_nft'">
          <div class="mb-4" ngbDropdown #myDropAssets="ngbDropdown" *ngIf="!selectedNFT">
            <label class="text-dark">NFT</label>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search NFT" id="dropdownManual1"
              [(ngModel)]="searchAssetInput" ngbDropdownAnchor (focus)="myDropAssets.open()" type="text"
              [ngClass]="{'disabled': !selectedWallet}" [disabled]="!selectedWallet">
            <ul ngbDropdownMenu aria-labelledby="dropdownManual1" class="whitelistscroll w-100 py-0">
              <div class="d-flex" *ngFor="let item of walletNFT | wallet_search: searchAssetInput; let i = index"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                <button class="dropdown-content w-100 p-0 m-0 border-0" (click)="selectNFT(item)">
                  <div class="card card-border border-bottom-0 m-0">
                    <div class="card-body p-3 bg-white">
                        <div class="media border-after-xs">
                            <img class="align-self-center mr-2 img-fluid img-round-border p-0" src={{item.imageurl}} (error) ="$event.target.src = 'https://assets.lmnl.app/nft-default.png';$event.target.classList.add('nft-defualt-img-roung');" alt="img">
                            <div class="d-flex flex-column text-left">
                              <span>
                                  <ng-container *ngIf="item.comman_name;else defaultName">{{item.comman_name}}</ng-container>
                                  <ng-template #defaultName>NFT #{{item.coin}}</ng-template>
                              </span>
                              <span class="text-dark dottedtextnft" placement="top" [ngbTooltip]="(item.coin.length > 10) ? item.coin : ''">{{item.coin}}</span>
                            </div>
                            <div class="d-flex flex-column media-body text-right">
                                <h6 class="card-balance m-0 font-size-14">${{item.balanceUSD | amountToPretty : 2}}</h6>
                                <span class="card-balance-coin text-dark">{{item.nativePrice | amountToPretty : 5}} {{item.chain}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </button>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedNFT">
            <label class="text-dark">NFT</label>
            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedNFT"
              (click)="selectedNFT = !selectedNFT;selectedAddress? selectedAddress = !selectedAddress:''">Clear</span>
              <div class="card card-border">
                <div class="card-body p-3">
                    <div class="media border-after-xs">
                        <img class="align-self-center mr-2 img-fluid img-round-border p-0" src={{selectedNFT.imageurl}} (error) ="$event.target.src = 'https://assets.lmnl.app/nft-default.png';$event.target.classList.add('nft-defualt-img-roung');" alt="img">
                        <div class="d-flex flex-column">
                          <span>
                            <ng-container *ngIf="selectedNFT.comman_name;else defaultName1">{{selectedNFT.comman_name}}</ng-container>
                            <ng-template #defaultName1>NFT #{{selectedNFT.coin}}</ng-template>
                          </span>
                          <span class="text-dark dottedtextnft" placement="top" [ngbTooltip]="(selectedNFT.coin.length > 10) ? selectedNFT.coin : ''">{{selectedNFT.coin}}</span>
                        </div>
                        <div class="d-flex flex-column media-body text-right">
                            <h6 class="card-balance m-0 font-size-14">${{selectedNFT.balanceUSD | amountToPretty : 2}}</h6>
                            <span class="card-balance-coin text-dark">{{selectedNFT.nativePrice | amountToPretty : 5}} {{selectedNFT.chain}}</span>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </ng-container>
          <div ngbDropdown #myDropAddress="ngbDropdown" *ngIf="!selectedAddress" class="mb-4">
            <label class="text-dark">Address</label>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search address"
              id="dropdownManual2" ngbDropdownAnchor (focus)="myDropAddress.open()" [(ngModel)]="searchAssetAddress" type="text"
              [ngClass]="{'disabled': !selectedWallet}" [disabled]="!selectedWallet">
            <ul ngbDropdownMenu aria-labelledby="dropdownManual2" class="whitelistscroll w-100 py-0"
              *ngIf="selectedWallet">
              <div class="d-flex" *ngFor="let policy of selectedWallet.policy | walletAddressSearch: searchAssetAddress">
                <button *ngIf="policy.subtype.toLowerCase() === 'address'"
                  class="dropdown-content card w-100 p-0 brd_Box" (click)="selectAddress(policy)">
                  <li ngbDropdownItem class="d-flex p-3">
                    <i class="icon-address text-secondary" style="border-radius: 50%;padding: 12px;border: 2px solid #f7c325;display: flex;justify-content: center;font-size: 22px!important;align-items: center;width: 50px;height: 50px;"></i>
                    <div class="details d-flex flex-column align-items-center">
                      <div class="col text-left p-0">
                        <p class="m-0 truncate_Name">{{ policy.name }}</p>
                      </div>
                      <div class="col text-left p-0 t_Id">
                        <span>{{ policy.condition.substring(0, 8) }}...</span>
                        <span>{{ policy.condition.substring(policy.condition.length - 4) }}</span>
                      </div>
                    </div>
                  </li>
                </button>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedAddress" class="mb-4">
            <label class="text-dark">Address</label>
            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedAddress"
              (click)="selectedAddress = !selectedAddress">Clear</span>
            <app-address-card [option2]=selectedAddress [card_padding]="'p-3'">
            </app-address-card>
          </div>
          <div *ngIf="selectedAddress?.condition && trmStatus">
            <app-aml [walletID]="selectedWallet.id" [chain]="selectedWallet.chain.toLowerCase()" [component_for]="'Whitelist_Policy'" [address]="selectedAddress?.condition"></app-aml>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer border-0" *ngIf="sendTxType == 'send_assets'">
      <button [ngClass]="{'disabled': !selectedWallet || !selectedAsset || !selectedAddress }"
        [disabled]="!selectedWallet || !selectedAsset || !selectedAddress" type="submit"
        class="btn btn-outline-primary w-100" (click)="senderAssetsConfirm()">
        <i class="icon-check mr-1"></i> Confirm
      </button>
    </div>
    <div class="modal-footer border-0" *ngIf="sendTxType == 'send_nft'">
      <button [ngClass]="{'disabled': !selectedWallet || !selectedNFT || !selectedAddress }"
        [disabled]="!selectedWallet || !selectedNFT || !selectedAddress" type="submit"
        class="btn btn-outline-primary w-100" (click)="senderNFTConfirm()">
        <i class="icon-check mr-1"></i> Confirm
      </button>
    </div>
  </div>

  <div *ngIf="sender_details">
  <div *ngIf="sendTxType == 'send_assets'">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-send text-dark mr-2"></i>
        Send Assets
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close text-dark"></i>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="selectedWallet">
        <ng-container [ngTemplateOutlet]="walletLabel"></ng-container>
        <div class="card card-border" *ngIf="!selectedAddressAmount.amount">
          <div class="card-body align-items-center p-3">
              <div class="media border-after-xs align-items-center">
                  <img class="mr-3 img-fluid img-round-border" [src]="selectedWallet.chain | getIcon">
                  <div class="d-flex flex-column text-left">
                      <span>{{selectedWallet.name}}</span>
                      <span class="network text-dark" [ngClass]="selectedWallet.chain.toLowerCase()">
                          {{ selectedWallet.chain | getName }}
                      </span>
                  </div>
                  <div class="media-body text-right">
                      <h2 class="card-balance m-0">${{sumofwallets(selectedWallet.id) | amountToPretty : 2}}</h2>
                      <span class="text-dark">{{selectedAsset.balance | amountToPretty : 5}} {{selectedAsset.coin}}</span>
                  </div>
              </div>
          </div>
      </div>
      <ng-container *ngIf="selectedAddressAmount.amount">
        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'"></app-wallet-card>
      </ng-container>
      </div>  
      <div class="d-flex flex-column align-items-center mt-5">
        <div class="row align-items-start w-75">
          <div class="col-5 text-center">
            <img class="img-fluid img-round-border-big mb-1 bg-white p-3" src={{getImageUrl(selectedAsset)}} alt="nft img" >
            <span class="d-block">
              {{selectedAsset.coin}}
            </span>
          </div>
          <div class="col-2 text-center mt-4 pt-3 text-dark">
            <i class="icon-arrow-right"></i>
          </div>
          <div class="col-5 text-center">
            <i class="icon-address text-secondary icon-round-border-big mb-1 bg-white"></i>
            <span class="d-block">{{selectedAddressAmount.policy?.name || addressLabel}}</span>
          </div>
        </div>
      </div>
      <div class="mt-3 mb-3 text-center">
        <span class="d-block text-dark text-break px-2">{{selectedAddressAmount.address}}</span>
        <span *ngIf="getMemoValue">Memo/Tag - {{getMemoValue}}</span>
      </div>
      <div>
        <div class="d-flex align-items-center justify-content-center mt-5">
          <input class="form-control text-right amount-input" [type]="selectedAddressAmount.amount?'text':'number'"
            (input)="transactionAmmountValidations(inputAmount.value)" (keypress)="allowNumberDecimal($event)" onwheel="return false;" value="{{selectedAddressAmount.amount | amountToPretty : 5}}" placeholder="0.00" #inputAmount
            autofocus [style.width]="inputWidth" [readonly]="selectedAddressAmount.amount"> 
          <span class="h3 m-0 pl-2 text-dark">{{selectedAsset.coin}}</span>
        </div>
        <div class="text-center">
          <label *ngIf="inputAmount.value && usdAmount;else secondUSD" class="text-dark m-0">${{ usdAmount * inputAmount.value | number: '1.0-2'}}</label>
          <label #secondUSD *ngIf="!inputAmount.value && !selectedAddressAmount.amount" class="text-dark m-0">$0.00</label>
          <label class="text-dark m-0" *ngIf="inputAmount.value && selectedAddressAmount.amount && !usdAmount">${{selectedAddressAmount.usdamount | amountToPretty : 2}}</label>
        </div>
      </div>
      <div class="text-center">
        <p *ngIf="invalidAmmount" class="text-dark">Please enter valid amount</p>
        <p *ngIf="allowedDecimals" class="text-dark">Only five Decimal places allowed</p>
      </div>
      <div class="mt-5">
        
        <input *ngIf="!txRequest && !mpcTxRequest" type="text" [placeholder]="'Add a note..'" class="form-control" [(ngModel)]="getNoteInputValue" />
        <p *ngIf="txRequest || mpcTxRequest" class="text-center w-75 ml-auto mr-auto mb-0 font-size-12 font-italic">{{getNoteInputValue}}</p>
      </div>
      <div *ngIf="(mpc_asset_changes?.length)" style="height: 100px;overflow: auto;width: 90%;margin-left: auto;margin-right: auto;">
        <div *ngFor="let item of mpc_asset_changes" class="d-flex text-white align-items-center justify-content-center" style="text-align: center;">
          <p style="color: black;margin: 0px;font-style: italic;font-weight: 400;font-size: 13px;line-height: 1.5;">{{item}}</p>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-center mt-5">
        <span class="d-inline-block text-dark w-75 text-center">It is a long established fact that a reader will be distracted by the readable</span>
      </div> -->
      <div *ngIf="deviceName?.toLowerCase() == 'ledger' && (selectedWallet.version != 2 || isV2MultisigWallet(selectedWallet) )" class="d-flex align-items-center justify-content-center text-dark mt-5">
        <i class="icon-ledger mr-2"></i>
        <span>Open app in your Ledger device and click Sign.</span>
      </div>
    </div>
    <div class="modal-footer border-0">
      <div *ngIf="browser == 'Firefox' && deviceName == 'ledger' && (selectedWallet.version != 2 || isV2MultisigWallet(selectedWallet) ); else not_fox" class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use Google Chrome or other supported browser.</div>
      <ng-template #not_fox>
        <button [ngClass]="{'disabled': isSigning || !inputAmount.value || invalidAmmount || allowedDecimals }"
        [disabled]="isSigning || !inputAmount.value || invalidAmmount || allowedDecimals" class="btn btn-primary active w-100"
        type="submit" (click)="transactionType(inputAmount.value)">
        <i *ngIf="!isSigning" class="icon-check mr-1"></i>
        <div *ngIf="isSigning" class="loader-box">
          <div class="loader-15 bg-white"></div>
        </div>
        <span *ngIf="!isSigning"> {{selectedWallet.version == 2 ? 'Approve': 'Sign'}} </span>
      </button>
      </ng-template>
    </div>
    
  </div>
  <div *ngIf="sendTxType == 'send_nft'">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-send text-dark mr-2"></i>
        Send Assets
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close text-dark"></i>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="selectedWallet">
        <ng-container [ngTemplateOutlet]="walletLabel"></ng-container>
        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'"></app-wallet-card>
      </div>  
      <div class="d-flex flex-column align-items-center mt-5">
        <div class="row align-items-start w-75">
          <div class="col-5 text-center">
            <img class="img-fluid img-round-border-big mb-1 bg-white" src={{selectedNFT.imageurl}} (error) ="$event.target.src = 'https://assets.lmnl.app/nft-default.png';$event.target.classList.add('nft-defualt-img-roung');" alt="nft img" >
            <span class="d-block">
              <ng-container *ngIf="selectedNFT.comman_name;else defaultName2">{{selectedNFT.comman_name}}</ng-container>
              <ng-template #defaultName2>NFT #{{selectedNFT.coin}}</ng-template>
            </span>
            <span class="d-block dottedtextnft" placement="top" [ngbTooltip]="(selectedNFT.coin.length > 10) ? selectedNFT.coin : ''">{{selectedNFT.coin}}</span>
          </div>
          <div class="col-2 text-center mt-4 pt-3 text-dark">
            <i class="icon-arrow-right"></i>
          </div>
          <div class="col-5 text-center">
            <i class="icon-address text-secondary icon-round-border-big mb-1 bg-white"></i>
            <span class="d-block">{{selectedAddressAmount.policy?.name || addressLabel}}</span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-5">
        <span class="font-size-20">${{selectedAddressAmount.usdamount | amountToPretty : 2}}</span>
        <span class="text-dark font-size-16 d-inline-block mx-2">|</span>
        <span class="text-dark font-size-16">{{selectedAddressAmount.amount | amountToPretty : 5}} {{selectedNFT.chain}}</span>
      </div>
      <div class="mt-3 mb-3 text-center">
        <span class="text-dark">{{selectedAddressAmount.address}}</span>
      </div>
      <!-- <div class="w-75 m-auto text-center">
        <span class="text-dark">It is a long established fact that a reader will be distracted by the readable</span>
      </div> -->
      <div *ngIf="deviceName?.toLowerCase() == 'ledger'" class="d-flex align-items-center justify-content-center text-dark mt-5">
        <i class="icon-ledger mr-2"></i>
        <span>Open Ether app in your Ledger device and click Sign.</span>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button [ngClass]="{'disabled': isSigning }"
        [disabled]="isSigning" class="btn btn-primary active w-100"
        type="submit" (click)="onSubmit(selectedAddressAmount.amount);onSign()">
        <i *ngIf="!isSigning" class="icon-check mr-1"></i>
        <div *ngIf="isSigning" class="loader-box">
          <div class="loader-15 bg-white"></div>
        </div>
        <span *ngIf="!isSigning"> Sign </span>
      </button>
    </div>
    
  </div>
  </div>
</ng-template>

<ng-template #successModal let-modal>
  <div class="modal-custom">
      <div class="modal-header border-0">
          <h2 class="modal-title"><i class="icon-shield mr-2"></i> Transaction Signed</h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button>
      </div>
      <div class="modal-body d-flex align-items-center">
          <div class="row">
              <div class="col-12">
                  <div class="mb-3 position-relative">
                      <div class="background_success text-center"> <img class="w-50"
                              src="../../../assets/images/check-outline-success.gif"> </div>
                  </div>
                  <div class="mb-5 text-center">
                      <div class="h5">Success</div>
                      <div *ngIf="!successMessage || successMessage === 'approved'" class="text-muted">Awesome! You have successfully {{walletVersionType == 2 ? 'approved': 'signed'}} the transaction 🎉</div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
          <button type="submit" class="btn btn-outline-primary w-100"
              (click)="modal.dismiss('Cross click');"> Done
          </button>
      </div>
  </div>
</ng-template>

<ng-template #errorModal let-modal>
  <div class="modal-custom">
      <!-- <div class="modal-header border-0">
          <h2 class="modal-title"><i class="icon-shield mr-2"></i> Failed to sign transaction</h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button>
      </div> -->
      <div class="modal-body d-flex align-items-center  justify-content-center" style="background-color: #fff;">
          <div class="row">
              <div class="col-12">
                <div class="position-relative text-center mb-4">
                  <img  src="../../../assets/images/error.svg"> 
                  
              </div>
                  <div class="mb-5 text-center">
                      <div class="font-size-20" style="font-weight: 500; margin-bottom: 15px;">Failed to {{walletVersionType == 2 ? 'approve': 'sign'}} transaction</div>
                      <div class=" px-4" style="color: #6D6F71;"><span class="">{{errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)}}.</span></div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100"
          (click)="modal.dismiss('Cross click');">Close
        </button>
        <div class="w-100 text-center mt-3 mb-2">
          <img src="../../../../assets/images/contact-support.svg" class="mr-2 inverse-icon"/>
            <a class='support_email'  href='mailto:support@lmnl.app' supportLink target="_top">Contact Support</a>
        </div>
      </div>
  </div>
</ng-template>
<ng-template #banDialog let-modal>
  <div class="modal-body">
      <div style="height: 250px;" class="mb-3 d-flex justify-content-center flex-column align-items-center w-100">
          <img class="w-50" src="../../../assets/images/error.svg">
      </div>
      <div class="mb-5 text-center">
          <h5>Transaction Blocked: Blacklisted Address</h5>
          <div class="px-4 my-4">
              Transaction cannot be processed because the address is blacklisted. Please contact the wallet owner.
              For assistance or concern, please contact our support team.
          </div>
      </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.dismiss()">I understand</button>
  </div>
</ng-template>
<ng-template #walletLabel>
  <label class="text-dark"> <ng-container *ngIf="sendassets_component_for == 'gas-station'">Gas Station</ng-container> Wallet</label>
</ng-template>