"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UInt8 = void 0;
const uint_1 = require("./uint");
const utils_1 = require("@xrplf/isomorphic/utils");
const utils_2 = require("../utils");
/**
 * Derived UInt class for serializing/deserializing 8 bit UInt
 */
class UInt8 extends uint_1.UInt {
  constructor(bytes) {
    super(bytes !== null && bytes !== void 0 ? bytes : UInt8.defaultUInt8.bytes);
  }
  static fromParser(parser) {
    return new UInt8(parser.read(UInt8.width));
  }
  /**
   * Construct a UInt8 object from a number
   *
   * @param val UInt8 object or number
   */
  static from(val) {
    if (val instanceof UInt8) {
      return val;
    }
    if (typeof val === 'number') {
      UInt8.checkUintRange(val, 0, 0xff);
      const buf = new Uint8Array(UInt8.width);
      (0, utils_2.writeUInt8)(buf, val, 0);
      return new UInt8(buf);
    }
    throw new Error('Cannot construct UInt8 from given value');
  }
  /**
   * get the value of a UInt8 object
   *
   * @returns the number represented by this.bytes
   */
  valueOf() {
    return parseInt((0, utils_1.bytesToHex)(this.bytes), 16);
  }
}
exports.UInt8 = UInt8;
UInt8.width = 8 / 8; // 1
UInt8.defaultUInt8 = new UInt8(new Uint8Array(UInt8.width));
