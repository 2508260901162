<!-- TYPE: MULTISIG -->
<ng-container *ngIf="txdata?.walletinfor && txdata?._txType == 'multisig_tx'">
    <!-- Column: Type -->
    <td class="m-t-10 pl-4">
        <div class="d-flex align-items-center">
            <img *ngIf="txdata?.isRbf && txdata?.speedUpTransactionList && txdata?.speedUpTransactionList.length"
                class="cursor-pointer mr-2 open-state" [ngClass]="{'open-state' :  txdata?.tx_state == 'open'}"
                (click)="onChangeTxState(txdata)"
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBnc/RCcIwEAbg/z/wvSM4gitkAjeRggjSAUTwJW7gJu0IjtARfBc8c3ko9JqW0ntJ+C9fkgM2Fk/nJlJw5PcXYrz3S4fr+rrXnbRp24kIXlBWFlhjBaoIPJnDS3NQZQvqp/SyQyE+bm8OzRlcQnnG0c0OW1ZCE+ixzZ5WeGQlHub/U0NCfUJdCVn9AYaBV93eNb1AAAAAAElFTkSuQmCC' />

            <div class="d-flex flex-column">
                <span class="text-capitalize">{{txdata?._metaData?.tx_category_type}}</span>
                <span class="text-capitalize">{{txdata?._metaData?.tx_sub_category_type}}</span>
            </div>
        </div>
        <div class="speed-up-pill" *ngIf="txdata?.isRbf" title="Most recent transaction">
            <img src="../../../../../assets/images/speed-transaction.svg" alt="speed-transaction.svg">
            <span>Speed Up</span>
        </div>
    </td>

    <!-- Column: Asset -->
    <td class="m-t-10" [ngClass]="{'pl-4':pendingtransactions_component_for === 'pentrs_wallet_details'}">
        <!-- Type: NFT -->
        <div *ngIf="txdata.tokenType == 2;else elseDiv" class="d-flex align-items-center">
            <img class="align-self-center mr-1 img-fluid img-round-border p-0 mr-2" src={{txdata.walletinfor.imageurl}}
                (error)="$event.target.src = 'https://assets.lmnl.app/nft-default.png';$event.target.classList.add('nft-defualt-img-roung');"
                alt="nft img">
            <div class="d-flex flex-column">
                <span class="d-inline-block">
                    <ng-container *ngIf="txdata.walletinfor.comman_name;else defaultName">
                        {{txdata.walletinfor.comman_name}}</ng-container>
                    <ng-template #defaultName>NFT #{{txdata.asset}}</ng-template>
                </span>
                <span class="text-dark dottedtextnft" placement="top"
                    [ngbTooltip]="(txdata.asset.length > 10) ? txdata.asset : ''">{{txdata.asset}}</span>
            </div>
        </div>
        <!-- Type: Asset -->
        <ng-template #elseDiv>
            <div class="d-flex">
                <img class="align-self-center mr-1 img-fluid img-round-border mr-2" [src]="txdata.asset | getIcon">
                <div class="d-flex flex-column">
                    <span>{{ txdata.asset | getName }}</span>
                    <span class="text-dark mr-6">{{ txdata.walletinfor.name }}</span>
                </div>
            </div>
        </ng-template>
    </td>
    <!-- Column: Value -->
    <td>
        <div class="d-flex flex-column">
            <span class="d-block" placement="top">{{txdata?._metaData?.tx_detail_description}}</span>
            <span class="d-block text-dark">{{txdata?._metaData?.tx_detail_title}}</span>
        </div>
    </td>
    <!-- Column: WalletType -->
    <td appHideIfCustodyOrg>
        {{ getWalletType(txdata?.walletinfor?.type, txdata?.walletinfor?.subtype) }}
    </td>
    <!-- Column: Details -->
    <td class="span-overflow m-t-2 whitespace-wrap">
        <div class="float-container">
            <div class="float-child second" *ngIf="txdata?._metaData?.destination_title">
                <a>
                    <span class="dottedtext" placement="top" ngbTooltip="{{txdata?._metaData?.destination_title}}">
                        {{txdata?._metaData?.destination_title}}</span>
                </a>
            </div>
            <div class="float-child second">
                <a>
                    <span class="dottedtext text-dark" ngbTooltip="{{txdata?._metaData?.destination_address}}">
                        {{txdata?._metaData?.destination_address.substring(0,8)}}...{{txdata?._metaData?.destination_address.substring(txdata?._metaData?.destination_address.length
                        - 5)}}
                    </span>
                </a>
            </div>
            <div *ngIf="txdata.memoData?.value">
                <span>Memo/Tag - {{txdata.memoData.value}}</span>
            </div>
            <div *ngIf="txdata.explorerLink" class="float-child first dottedtext">
                <a href="{{txdata.explorerLink}}" target="_blank">
                    <!-- <span class="border-bottom-td dottedtext"
                    ngbTooltip="{{txdata.identifier || txdata.destinationAddress}}"
                    [ngClass]="{'m-b-0' : txdata.explorerLink != blank}"> {{
                    txdata.identifier || txdata.destinationAddress}}
                </span> -->
                    <span class="border-bottom-td" [ngClass]="{'m-b-0' : txdata.explorerLink != blank}">
                        View in Explorer
                    </span>
                </a>
            </div>
        </div>
    </td>
    <!-- Column: Status -->
    <td class="text-muted m-t-10 body-font">
        <div class="status word-breakdiv">
            <div *ngIf="txdata.status === 1">
                <span class="">Signing</span>
                <ngb-progressbar class="my-1 int-sign-progress" [value]="txdata._signersSigns"
                    [max]="(txdata.walletinfor.type.toLowerCase() == 'multisig_shield' || txdata.walletinfor.type.toLowerCase() == 'gnosis')?txdata._signers - 1 : txdata._signers"
                    height="5px"></ngb-progressbar>
                <span class="d-flex"><b class="font-size-12">{{(txdata.walletinfor.type.toLowerCase() ==
                        'multisig_shield' || txdata.walletinfor.type.toLowerCase() == 'gnosis')?txdata._signers -
                        txdata._signersSigns - 1 : txdata._signers - txdata._signersSigns}}</b> <span
                        class="font-size-12 ml-1 text-dark">Remaining</span></span>
            </div>
            <div *ngIf="txdata.status === 2">
                <span class="">Pending</span>
                <ngb-progressbar class="my-1 pending-progress" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                <span class="font-size-12 text-dark">All Signed</span>
            </div>
        </div>
    </td>
    <!-- Column: Members -->
    <td>
        <ng-container *ngIf="!(!txdata.txn_req_id && txdata.status === FIREWALL_PENDING_STATUS); else firewallMembers">
            <ng-container *ngIf="txdata.walletinfor.version == 2 && txdata.status == 2 && orgProfile.orgType == orgType.sub &&orgProfile.custodyType == custodyType.CUSTODY;else standard">
                <app-signer-user-list [signerUserData]="filterInitiatorsList(txdata.signs)" [uniqId]="uniqId"
                    [component_for]="'txuser'"></app-signer-user-list>
            </ng-container>
            <ng-template #standard>
                <app-signer-user-list [signerUserData]="filterPolicySigner(txdata.signs)" [uniqId]="uniqId"
                    [component_for]="'txuser'"></app-signer-user-list>
            </ng-template>
        </ng-container>
        <ng-template #firewallMembers>
            <app-signer-user-list [signerUserData]="txdata.firewallTeamMembers?.length ? txdata.firewallTeamMembers[0].members : []" [uniqId]="uniqId" [component_for]="'mpcTx'"></app-signer-user-list>
        </ng-template>
    </td>
    <!-- Column: Notes -->
    <td>
        <div *ngIf="txdata?._metaData?.comment">
            <img placement="top" width="25" [ngbTooltip]="list" src='../../../../assets/images/notes.png' />
            <ng-template #list>
                <div class="d-flex flex-column text-left text-dark">
                    <span>{{txdata?._metaData?.comment}}</span>
                    <span class="text-right text-primary">{{txdata?._metaData?.comment_owner_name}}</span>
                </div>
            </ng-template>
        </div>
        <div class="ml-2 text-dark" *ngIf="!txdata?._metaData?.comment">
            -
        </div>
    </td>
    <!-- Column: Time -->
    <td class="time">
        <div>
            {{ txdata.timestamp | dateWithZone }}
        </div>
    </td>
    <!-- Column: Action -->
    <td class="m-t-10 pr-4">
        <div class="d-flex justify-content-end">
            
            <div class="d-flex justify-content-end mr-1" *ngIf="isChainSupported(txdata?.chain) && isInitiateRbfTxn(txdata)">
                <a href="javascript:void(0);" (click)="openSpeedUpTxnModal(txdata);" placement="top"
                    ngbTooltip="Speed up transaction" class="speedup-btn btn btn-round btn-sm">
                    Speed Up
                </a>
            </div>
            <button class="btn btn-primary-light btn-round btn-sm " title="View"
                (click)="onViewFirewallTransactionClick(txdata)">View</button>
        </div>
    </td>
</ng-container>