<div class="card">
  <div class="card-body p-4">
    <div class="d-flex justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="m-0">Notifications and Alerts</h2>
      </div>
      <div class="notification-btns">
        <div>
          <button
            routerLink="/settings/email-notifications"
            class="btn btn-transparent btn-round btn-md mr-2"
            title="View Key Cards"
          >
            <i class="icon-eye"></i>
            <span class="d-inline ml-1">View</span>
          </button>
        </div>
      </div>
    </div>
    <div class="border-0">
      <p class="text-dark">
        <ng-container *ngIf="!isCustody">
          Add notifications and set alerts for specific triggers to the assigned
          users in the organization. It enables you to configure dedicated email
          addresses to receive alerts on account updates, compliance, security
          and daily transactions.
        </ng-container>
        <ng-container *ngIf="isCustody"
          >Add notifications and set alerts for specific triggers to the
          assigned users in the organization. It enables you to configure email
          address to receive updates on compliance, transfer or wallet balance
          alerts.</ng-container
        >
      </p>
    </div>
  </div>
</div>

<ng-template #notifications let-modal>
  <div *ngIf="stage == 'email_edit'" class="modal-custom">
    <div class="modal-header">
      <h2 class="modal-title">
        <img
          class="img-fluid mr-2"
          src="../../../../assets/images/notification_bell.svg"
          alt="bell"
        />
        Notifications
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <form [formGroup]="registerForm">
            <!-- <div class="mb-5 mt-3">
                    <div class="d-flex align-items-center  mb-2">
                        <div class="d-flex">
                            <img class="img-fluid mr-2" src="../../../../assets/images/Billing_Alerts.svg"  alt="Billing_Alerts" />
                        </div>
                        <label class="font-weight-bold d-flex align-items-center mb-0">
                            Account Alerts</label>
                        </div>
                        <div class="font-size-12 mb-3">
                            Assign an email id to receive notifications on account updates
                        </div>
                        <div class="d-flex align-items-center">
                            <input formControlName="billEmail" [ngClass]="{ 'is-invalid': submitted && f.billEmail.errors }" class="form-control" type="email" value={{billingEmail}} [(ngModel)]="billingEmail" placeholder="Enter Account email ">
                        </div>
                        <div  class="mt-2 text-danger" *ngIf="submitted && f.billEmail.errors" >
                            Email must be a valid email address
                        </div>
                </div> -->
            <div class="mb-5" *ngIf="!isCustody">
              <div class="d-flex align-items-center mb-2">
                <div class="d-flex">
                  <img
                    class="img-fluid mr-2"
                    src="../../../../assets/images/Compliance_Alerts.svg"
                    alt="compliance_alt_icon"
                  />
                </div>
                <label class="font-weight-bold d-flex align-items-center mb-0">
                  Compliance Alerts</label
                >
              </div>
              <div class="font-size-12 mb-3">
                Assign an email id to receive notifications on compliance
                updates
              </div>
              <div class="d-flex align-items-center">
                <input
                  formControlName="compEmail"
                  [ngClass]="{ 'is-invalid': submitted && f.compEmail.errors }"
                  class="form-control"
                  type="email"
                  value="{{ complianceEmail }}"
                  [(ngModel)]="complianceEmail"
                  placeholder="Enter Compliance email"
                />
              </div>
              <div
                class="mt-2 text-danger"
                *ngIf="submitted && f.compEmail.errors"
              >
                Email must be a valid email address
              </div>
            </div>

            <!-- <div class="mb-5">
                    <div class="d-flex align-items-center  mb-2">
                    <div class="d-flex">
                        <img class="img-fluid mr-2" src="../../../../assets/images/Icon-Lock.svg"  alt="lock" />
                    </div>
                    <label class="font-weight-bold d-flex align-items-center mb-0">
                        Security Alerts</label>
                    </div>
                        <div class="font-size-12 mb-3">
                            Assign an email id to receive notifications on security related alerts or updates
                        </div>
                    <div class="d-flex align-items-center">
                        <input formControlName="secEmail" [ngClass]="{ 'is-invalid': submitted && f.secEmail.errors }" class="form-control" type="email" value={{securityEmail}} [(ngModel)]="securityEmail" placeholder="Enter Security email ">
                    </div>
                    <div  class="mt-2 text-danger" *ngIf="submitted && f.secEmail.errors" >
                        Email must be a valid email address
                    </div>
                </div> -->

            <div class="mb-5">
              <div class="d-flex align-items-center mb-2">
                <div class="d-flex">
                  <img
                    class="img-fluid mr-2"
                    src="../../../../assets/images/transaction.svg"
                    alt="transaction"
                  />
                </div>
                <label class="font-weight-bold d-flex align-items-center mb-0">
                  Transaction Alerts</label
                >
              </div>
              <div class="font-size-12 mb-3">
                Assign an email id to receive notifications on Hot Wallet
                Deposits and Withdrawals
              </div>
              <div class="d-flex align-items-center">
                <input
                  formControlName="tranEmail"
                  [ngClass]="{ 'is-invalid': submitted && f.tranEmail.errors }"
                  class="form-control"
                  type="email"
                  value="{{ transactionEmail }}"
                  [(ngModel)]="transactionEmail"
                  placeholder="Enter Transaction email "
                />
              </div>
              <div
                class="mt-2 text-danger"
                *ngIf="submitted && f.tranEmail.errors"
              >
                Email must be a valid email address
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button
        [ngClass]="{ disabled: !complianceEmail || !transactionEmail }"
        [disabled]="!complianceEmail || !transactionEmail"
        type="submit"
        class="btn btn-outline-primary w-100"
        (click)="save_Emails()"
      >
        Save
      </button>
    </div>
  </div>

  <div *ngIf="stage == 'success'" class="modal-custom">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <img
          class="img-fluid mr-2"
          src="../../../../assets/images/notification_bell.svg"
          alt="bell"
        />
        Notifications
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body d-flex align-items-center">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 position-relative">
            <div class="background_success text-center">
              <img
                class="w-50"
                src="../../../assets/images/check-outline-success.gif"
              />
            </div>
          </div>
          <div class="mb-5 text-center">
            <div class="h5">Success</div>
            <div class="text-muted">
              Awesome! We have updated the email addresses. 🎉
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button
        title=""
        type="submit"
        class="btn btn-outline-primary w-100"
        (click)="close()"
      >
        Done
      </button>
    </div>
  </div>
</ng-template>
