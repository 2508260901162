import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpeedUpTransactionComponent } from '../speed-up-transaction.component';

@Component({
  selector: 'app-speed-up-confirmation-popup',
  templateUrl: './speed-up-confirmation-popup.component.html',
  styleUrls: ['./speed-up-confirmation-popup.component.scss']
})
export class SpeedUpConfirmationPopupComponent implements OnInit {

  @Input() component_for: string = "speed-up-transaction";
  @Input() transactionData: any = null;


  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openSpeedUpForm(){
    if(this.transactionData) {
      this.modalService.dismissAll();
      const modalRef = this.modalService.open(SpeedUpTransactionComponent, { centered: true });
      modalRef.componentInstance.component_for = 'speed-up-transaction';
      modalRef.componentInstance.transactionData = this.transactionData;
    }
  }
}
