/**
 * Needed polyfill for Promise.allSettled as it doesn't exist on RN.
 */
export const allSettled = promises => {
  return Promise.all(promises.map(p => {
    return p.then(value => {
      return {
        status: "fulfilled",
        value
      };
    }).catch(reason => {
      return {
        status: "rejected",
        reason
      };
    });
  }));
};
/**
 * Helper to know in advance if a domain is compatible with the nano
 *
 * @param domain string representing the domain
 * @returns {Boolean}
 */
export const validateDomain = domain => {
  if (typeof domain !== "string") {
    return false;
  }
  const lengthIsValid = domain.length > 0 && Number(domain.length) < 30;
  const containsOnlyValidChars = new RegExp("^[a-zA-Z0-9\\-\\_\\.]+$").test(domain);
  return lengthIsValid && containsOnlyValidChars;
};
