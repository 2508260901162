"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRANSACTION_RESULT_WIDTH = exports.TRANSACTION_TYPE_WIDTH = exports.LEDGER_ENTRY_WIDTH = exports.TYPE_WIDTH = void 0;
exports.TYPE_WIDTH = 2;
exports.LEDGER_ENTRY_WIDTH = 2;
exports.TRANSACTION_TYPE_WIDTH = 2;
exports.TRANSACTION_RESULT_WIDTH = 1;
