"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BinarySerializer = exports.BytesList = void 0;
const utils_1 = require("@xrplf/isomorphic/utils");
/**
 * Bytes list is a collection of Uint8Array objects
 */
class BytesList {
  constructor() {
    this.bytesArray = [];
  }
  /**
   * Get the total number of bytes in the BytesList
   *
   * @return the number of bytes
   */
  getLength() {
    return (0, utils_1.concat)(this.bytesArray).byteLength;
  }
  /**
   * Put bytes in the BytesList
   *
   * @param bytesArg A Uint8Array
   * @return this BytesList
   */
  put(bytesArg) {
    const bytes = Uint8Array.from(bytesArg); // Temporary, to catch instances of Uint8Array being passed in
    this.bytesArray.push(bytes);
    return this;
  }
  /**
   * Write this BytesList to the back of another bytes list
   *
   *  @param list The BytesList to write to
   */
  toBytesSink(list) {
    list.put(this.toBytes());
  }
  toBytes() {
    return (0, utils_1.concat)(this.bytesArray);
  }
  toHex() {
    return (0, utils_1.bytesToHex)(this.toBytes());
  }
}
exports.BytesList = BytesList;
/**
 * BinarySerializer is used to write fields and values to Uint8Arrays
 */
class BinarySerializer {
  constructor(sink) {
    this.sink = new BytesList();
    this.sink = sink;
  }
  /**
   * Write a value to this BinarySerializer
   *
   * @param value a SerializedType value
   */
  write(value) {
    value.toBytesSink(this.sink);
  }
  /**
   * Write bytes to this BinarySerializer
   *
   * @param bytes the bytes to write
   */
  put(bytes) {
    this.sink.put(bytes);
  }
  /**
   * Write a value of a given type to this BinarySerializer
   *
   * @param type the type to write
   * @param value a value of that type
   */
  writeType(type, value) {
    this.write(type.from(value));
  }
  /**
   * Write BytesList to this BinarySerializer
   *
   * @param bl BytesList to write to BinarySerializer
   */
  writeBytesList(bl) {
    bl.toBytesSink(this.sink);
  }
  /**
   * Calculate the header of Variable Length encoded bytes
   *
   * @param length the length of the bytes
   */
  encodeVariableLength(length) {
    const lenBytes = new Uint8Array(3);
    if (length <= 192) {
      lenBytes[0] = length;
      return lenBytes.slice(0, 1);
    } else if (length <= 12480) {
      length -= 193;
      lenBytes[0] = 193 + (length >>> 8);
      lenBytes[1] = length & 0xff;
      return lenBytes.slice(0, 2);
    } else if (length <= 918744) {
      length -= 12481;
      lenBytes[0] = 241 + (length >>> 16);
      lenBytes[1] = length >> 8 & 0xff;
      lenBytes[2] = length & 0xff;
      return lenBytes.slice(0, 3);
    }
    throw new Error('Overflow error');
  }
  /**
   * Write field and value to BinarySerializer
   *
   * @param field field to write to BinarySerializer
   * @param value value to write to BinarySerializer
   */
  writeFieldAndValue(field, value, isUnlModifyWorkaround = false) {
    const associatedValue = field.associatedType.from(value);
    if (associatedValue.toBytesSink === undefined || field.name === undefined) {
      throw new Error();
    }
    this.sink.put(field.header);
    if (field.isVariableLengthEncoded) {
      this.writeLengthEncoded(associatedValue, isUnlModifyWorkaround);
    } else {
      associatedValue.toBytesSink(this.sink);
    }
  }
  /**
   * Write a variable length encoded value to the BinarySerializer
   *
   * @param value length encoded value to write to BytesList
   */
  writeLengthEncoded(value, isUnlModifyWorkaround = false) {
    const bytes = new BytesList();
    if (!isUnlModifyWorkaround) {
      // this part doesn't happen for the Account field in a UNLModify transaction
      value.toBytesSink(bytes);
    }
    this.put(this.encodeVariableLength(bytes.getLength()));
    this.writeBytesList(bytes);
  }
}
exports.BinarySerializer = BinarySerializer;
