<div>
    <div class="modal-header">
        <h2 class="modal-title">
            <ng-container>Speed Up your transaction</ng-container>
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="speed-up-form-modal">
                    <div class="row">
                        <div class="col-5">
                            <div class="asset-block">
                                <div class="asset-icon">
                                    <img class="img-fluid" [src]="transactionData.asset | getIcon">
                                </div>

                                <div class="asset-text">
                                    <span class="body-font">{{ transactionData.asset | getName }}</span>
                                    <span class="text-dark"> {{ transactionData?.walletinfor?.name }} </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 arrow-block">
                            <img class="img-fluid" src="../../../../assets/images/right_arrow.svg">
                        </div>
                        <div class="col-5">
                            <div class="asset-block">
                                <div class="asset-icon">
                                    <img class="img-fluid" src="../../../../assets/images/self_address.svg">
                                </div>
                                <div class="asset-text">
                                    <span class="body-font dottedtext" placement="top"
                                        ngbTooltip="{{transactionData?._metaData?.destination_title || transactionData?.addressLabel}}">
                                        {{transactionData?._metaData?.destination_title ||
                                        transactionData?.addressLabel}}</span>

                                    <span class="dottedtext text-dark"
                                        ngbTooltip="{{transactionData?._metaData?.destination_address || transactionData?.destinationAddress}}">
                                        <ng-container
                                            *ngIf="transactionData?._metaData?.destination_address;else elseBlock">
                                            {{
                                            transactionData?._metaData?.destination_address.substring(0,8)}}...{{transactionData?._metaData?.destination_address.substring(transactionData?._metaData?.destination_address.length
                                            - 5)}}
                                        </ng-container>

                                        <ng-template #elseBlock>
                                            {{transactionData?.destinationAddress.substring(0,8)}}...{{transactionData?.destinationAddress.substring(transactionData?.destinationAddress.length
                                            - 5)}}
                                        </ng-template>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="transaction-block">
                                <span class="body-font h4">{{transactionData?._metaData?.tx_detail_description}}</span>
                                <span class="text-dark h6">{{transactionData?._metaData?.tx_detail_title}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="transaction-block"
                                *ngIf="transactionData?._metaData?.comment || transactionData?.comment ">
                                <span class="text-dark"> Note: {{transactionData?._metaData?.comment ||
                                    transactionData?.comment}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <input [(ngModel)]="isManuallyAdjustfFee" class="checkbox_animated ml-3" type="checkbox"
                            (change)="toggleAdjustFee()" />
                        <span class="body-font font-size-12">Manually adjust fee</span>
                    </div>

                    <div class="row mt-4">
                        <ng-container *ngIf="isEVM">
                            <div class="col-6">
                                <label class="text-dark">Base Fee</label>
                                <div class="d-flex flex-column text-left">
                                    <div class="input-group mb-3">
                                        <input type="text" autocomplete="off" [(ngModel)]="baseFee" class="form-control"
                                            placeholder="Enter max base fee" [disabled]="!isManuallyAdjustfFee"
                                            pattern="^\d+(\.\d+)?$"  maxlength="10" (change)="onChangeBaseFee()">
                                        <div class="input-group-append">
                                            <span class="input-group-text">Gwei</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="text-dark">Priority fee</label>
                                <div class="d-flex flex-column text-left">
                                    <div class="input-group mb-3">
                                        <input type="text" autocomplete="off" [(ngModel)]="priorityFee"
                                            class="form-control" placeholder="Enter priority fee"
                                            [disabled]="!isManuallyAdjustfFee" pattern="^\d+(\.\d+)?$"  maxlength="10">
                                        <div class="input-group-append">
                                            <span class="input-group-text">Gwei</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isUTXO">
                            <div class="col-12">
                                <label class="text-dark">Fee</label>
                                <div class="d-flex flex-column text-left">
                                    <div class="input-group mb-3">
                                        <input type="text" autocomplete="off" [(ngModel)]="baseFee" class="form-control"
                                            placeholder="Enter fee" [disabled]="!isManuallyAdjustfFee" pattern="^\d+(\.\d+)?$" maxlength="10"
                                            (change)="onChangeBaseFee()">
                                        <div class="input-group-append">
                                            <span class="input-group-text">Sats/ Byte</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>


                        <div class="text-dark ml-3">
                            <span
                                *ngIf="baseFee && priceIncreaseparcentage && priceChangeDirection != 'same';else elsePriceBlock">{{priceIncreaseparcentage}}%
                                {{priceChangeDirection}} than current market fees</span>
                            <ng-template #elsePriceBlock>
                                <span> Same as current market fees </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="text-dark mt-4">
                        <span>This transaction, with a higher fee, will replace the original {{ isUTXO ? 'using Replace
                            by Fee (RBF)' : '' }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100" (click)="!isLoading && onSubmitSpeedUpTxnForm();"
            [ngClass]="{'disabled': isLoading }" [disabled]="!(this.baseFee > 0) ||  isLoading">
            <div class="loader-box" *ngIf="isLoading">
                <div class="loader-15 bg-white"></div>
            </div>
            <span *ngIf="!isLoading">{{ walletType == "Multisig" ? 'Sign' : 'Submit'}}</span>
        </button>
    </div>
</div>