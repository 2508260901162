import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Wallet } from '../../entities/wallet';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tokenType } from '../../entities/TransactionRequest';
import { QrCodeService } from "./qr-code.service";
@Component({
  selector: 'app-receive-assets',
  templateUrl: './receive-assets.component.html',
  styleUrls: ['./receive-assets.component.scss']
})
export class ReceiveAssetsComponent implements OnInit {
  receiver_details = false;
  wallets: Wallet[];
  _wallets: Wallet[];
  selectedWallet: any;
  searchWalletInput: string;
  @Input() receiveassets_component_for: string;
  @Input() walletDetailWalletId: any;
  @Input() singleWallet: any;


  private onDestroy$: Subject<void> = new Subject<void>();

  qrCodeImageUrl: string | null = null;

  constructor(private data: DataService, private modalService: NgbModal,
    private qrCodeService: QrCodeService) {
    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallets => {
      if (wallets) {
        // this.wallets = [...new Map(wallets.map(item => [item.id, item])).values()];
        this.wallets = [...new Map(wallets.filter((value) => value.tokenType === tokenType.nativeToken && value.isArchived != 1).map(item => [item.id, item])).values()];
        this.wallets = this.wallets.sort((a, b) => a.name.localeCompare(b.name));
        this._wallets = wallets;
      }

    });
  }

  generateQrCode(code): void {
    const img = ""; // Pass icon to put in center of qr
    this.qrCodeService.generateQrCode(code, img)
      .then((url: string) => {
        this.qrCodeImageUrl = url
      })
      .catch((error: any) => {
        this.qrCodeImageUrl = null;
        console.error('Error generating QR code:', error);
      });
  }

  ngOnInit(): void {
  }

  singleWalletReceiveFilter(data) {
    this.selectedWallet = data;
  }

  open(content) {
    if (this.receiveassets_component_for === 'receive_wallet') {
      this.wallets = this.wallets.filter((ele) => ele.isArchived !== 1)
    }
    this.receiver_details = false;
    this.selectedWallet = null;
    this.searchWalletInput = '';
    if (this.walletDetailWalletId) {
      this.receiver_details = true;
      this.selectedWallet = this.wallets.filter(ele => ele.id == this.walletDetailWalletId)[0];
      this.generateQrCode(this.selectedWallet?.address)
      // if (this.receiveassets_component_for == 'gas-station') {
      //   this.singleWallet = {
      //     name: 'Gas Station',
      //     chain: this.singleWallet.protocol,
      //     address: this.singleWallet.gasStationAddress
      //   }
      //   this.selectedWallet = this.singleWallet;
      // } else {
      //   this.selectedWallet = this.wallets.filter(ele => ele.id == this.walletDetailWalletId)[0];
      // }
    }
    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}