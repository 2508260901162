import { firstValueFrom } from "rxjs";
import { scopes } from "src/app/shared/constants/authScopes";

export async function signSolanaStakingTransaction(amount,comment,wallet,validator,type) {
    try {
      let dataObj = {
        amount: type == 'claimrewards' ? amount : amount.toString(),
        comment: comment,
        timestamp: new Date().getTime(),
      };

      try {
        return await this.apiService.executeApiWithAuth2Fa(scopes.WalletTxCreate, (accessToken) =>
          this.httpService.stakingPrebuild(
            wallet.id,
            comment,
            validator.id,
            type,
            dataObj,
            accessToken
          )
        );
      } catch (error) {
        return Promise.reject(error);
      }
    } catch (e) {
      return Promise.reject({ success: false, message: 'message signing failed' });
    }
}