<div class="container-fluid">
    <div style="display: flex; flex-direction: row; justify-content: space-between;" class="mb-2">
        <label class="mb-1 text-muted font-weight-500">
          {{getInputLabel()}}
        </label>

         <!-- Create team Button  -->
        <button appDisableClick class="btn btn-link primary-color text-underline p-0 pb-0 pr-0 pt-0"
            (click)="openAddTeamModal()">
            <span class="d-inline text-primary">Add Team</span>
        </button>
    </div>
    <div class="mb-4 mt-1" style="margin-left: auto !important; " ngbDropdown #myDropInitiator="ngbDropdown">
        <div class="d-flex teams-dropdown">
            <input autocomplete="off" class="form-control search-custom  test " id="dropdownManual" ngbDropdownAnchor
                (focus)="myDropInitiator.open(); focusTotInit=true" (blur)="focusTotInit=false" type="text"
                placeholder="Select Team" [value]="teamConfig.team?.name" [(ngModel)]="teamName"
                (ngModelChange)="searchTerm = $event;onTeamValueChange($event)" />
        </div>
        <ul ngbDropdownMenu class="whitelistscroll py-0">
            <div class="d-flex" *ngFor="let data of teamsListData | teamListSearch:searchTerm"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%; border: none !important;"
                    (click)="onDropDownBtnClick(data)" [disabled]="data.disabled" [ngClass]="{ 'disabled': data.disabled }">
                    <li ngbDropdownItem class="p-3 text-wrap">
                        <div class="d-flex  flex-column">
                            <div class="text-left text-margin">
                                {{data.name}}
                            </div>
                            <div *ngIf = "data.disabled" class="text-left text-margin warning-text" >
                                *Team has less than {{minimumRequiredInitiators}} members
                            </div>
                            <div class="ml-auto"></div>
                        </div>
                    </li>
                </button>
            </div>
        </ul>
    </div>
</div>
<div *ngIf="teamConfig?.team" class="mt-4">
    <div class="d-flex mb-1">
        <label class="text-muted font-weight-500">
            Members
        </label>
        <div class="d-inline float-right" style="margin-left: auto;">
            <span class="text-muted font-weight-500" href="#">{{teamConfig.totalMembersCountText}}</span>
        </div>
    </div>
    <app-member-list [members]="teamMembers"></app-member-list>


    <div class="d-flex flex-column mt-4">
        <div class="d-flex flex-row justify-content-between align-items-center">
            <label class="text-muted font-weight-500 mb-0">
                <div>{{getMinMembersLabel()}}</div>
            </label>
            <div class="d-flex flex-row align-items-center">
                <input autocomplete="off" class="form-control form-control-sm input-number mr-2" type="number"
                    [ngClass]="{'invalid-state': (( minMembersRequired.dirty || minMembersRequired.touched) && !validateNumberRange(teamConfig.minMembersRequired))}"
                    [(ngModel)]="teamConfig.minMembersRequired" #minMembersRequired="ngModel"
                    (ngModelChange)="onMinMembersRequiredChange($event)" [disabled]="isSigners">
                <span class="font-weight-500">of {{teamMembers?.length}}</span>
            </div>
        </div>
        <div class="note-text text-muted " *ngIf="isCompliance">{{actionDescription.COMPLIANCE}}</div>

        <!-- Display validation error message -->
        <div class="d-flex flex-row-reverse text-danger mt-1"
            *ngIf="(minMembersRequired.dirty || minMembersRequired.touched)">
            <div *ngIf="!validateNumberRange(teamConfig.minMembersRequired)">
                <div *ngIf="isCustodyOrg && (teamConfig.minMembersRequired < minimumRequiredInitiators) && !isNegativeValue">
                    At least {{minimumRequiredInitiators}} Initiators Required.
                </div>
                <div *ngIf="teamConfig.minMembersRequired > teamMembers.length || isNegativeValue || teamConfig.minMembersRequired < 0">
                    Please enter a valid number.
                </div>
            </div>
        </div>
    </div>

    <div class="mt-4 notes">
        <label class="text-muted font-weight-500"  *ngIf="!isCompliance">Note:- </label>
        <div class="mt-1 text-dark">
            <ul class="default-bullet-list">
                <li class="mb-1" *ngIf="isInitiators">{{actionDescription.INITIATION}}</li>
                <li class="mb-1" *ngIf="isSigners">{{signerText === walletUserType.SIGNER ? actionDescription.SIGNING : actionDescription.APPROVAL}}</li>
                <!-- <li class="mb-1" *ngIf="isCompliance">{{actionDescription.COMPLIANCE}}</li> -->
            </ul>
        </div>
    </div>

</div>