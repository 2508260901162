import { Injectable } from '@angular/core';
import * as QRCode from 'qrcode';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  generateQrCode(data: string, logoSrc: string): Promise<string> {
    if (logoSrc) {
      return new Promise((resolve, reject) => {
        QRCode.toCanvas(data, { errorCorrectionLevel: 'M', width: 100 })
          .then((canvas: HTMLCanvasElement) => {
            const ctx = canvas.getContext('2d');
            if (ctx) {
              const logo = new Image();
              logo.src = logoSrc;

              logo.onload = () => {
                const logoSize = 75; // Adjust the size of the logo
                const centerX = (canvas.width - logoSize) / 2;
                const centerY = (canvas.height - logoSize) / 2;

                ctx.drawImage(logo, centerX, centerY, logoSize, logoSize);
                resolve(canvas.toDataURL());
              };

              logo.onerror = (error) => reject(error);
            } else {
              reject('Canvas context not supported');
            }
          })
          .catch((error) => reject(error));
      });
    } else {
      return QRCode.toDataURL(data);
    }

  }
}
