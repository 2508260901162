import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Subscription, concat, of } from "rxjs";
import { UserRoleEnum, orgType } from "../../../entities/members";
import {
  FrozenTxStatus,
  RuleTypeTitle,
  TxCheckStatus,
  TxDirection,
} from "../../../entities/firewall.model";
import { isV2MultisigWallet } from "../../../helpers/WalletUtils";
import {
  Approval,
  Content,
  Firewall,
  FirewallApiResponse,
  FirewallResponseData,
  TeamActionType,
  TxnSigner,
} from "../../../entities";
import { FirewallCheckStatus, PendingTxnStatus, getFirewallPolicyName } from "./firewall.utils";
import { GetNamePipe } from "../../pipes/coins";
import { AmountBeautifyPipe } from "../../pipes/amountrounded.pipe";
import { FirewallService } from "../../../../components/firewall/firewall.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthServiceJWT } from "../../../services/auth.service";
import {
  QuorumApproveRejectRequest,
  QuorumApproveRejectResponse,
  QuorumReqApprovalStatus,
} from "../../../entities/quorums.model";
import { catchError, concatMap, toArray } from "rxjs/operators";
import Swal from "sweetalert2";
import { HttpService } from "../../../services/http.service";
import { WalletserviceService } from "../../../services/walletservice.service";
import { DataService } from "../../../services/data.service";
import { MpcTxData } from "../../../entities/mpcTxRequest";
import { scopes } from "../../../constants/authScopes"
import { MembersType } from "../../new-group-wallet/mobile-teams/mobile-teams.component";
import { ErrorPrompt } from "../../custom-prompt/custom-prompt.service";
import { UserModeEnum } from "../../../entities/User";

enum ApprovalTabs {
  MEMBERS = "MEMBERS",
  INITIATION = "Initiation",
  FIREWALL_APPROVAL = "Approval",
  SIGNING = "Signing",
}

enum AccordionTabs {
  DETAILS = "DETAILS",
  FIREWALL = "FIREWALL",
  COMPLIANCE = "COMPLIANCE",
}

@Component({
  selector: "app-firewall-txn",
  templateUrl: "./pending-txn-info.component.html",
  styleUrls: ["./pending-txn-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [GetNamePipe, AmountBeautifyPipe],
})
export class PendingTxnInfoComponent implements OnInit {
 
  @Input() componentFor: string = "send-receive";
  @Input() title: string;
  @Input() isCustodyOrg: boolean = false;
  @Input() selectedTransactionData: any;
  @Output() onRejectTxn: EventEmitter<any> = new EventEmitter();
  subscription: Subscription;
  selectedTab: string = ApprovalTabs.INITIATION;
  readonly txnDirection = TxDirection;
  readonly ItransactionStatus = PendingTxnStatus;
  readonly frozenTxStatus = FrozenTxStatus;
  readonly userRole = UserRoleEnum;
  readonly userOrgType = orgType;
  readonly IFirewallCheckStatus = FirewallCheckStatus;
  isLoading: boolean = false;

  // Loading state indicators
  isApprovalInProgress: boolean = false;
  isRejectionInProgress: boolean = false;
  isUnqurantineInProgress: boolean = false;

  firewallResponse: FirewallResponseData;
  isV2MultisigWallet = isV2MultisigWallet;
  commentUnqurantined: string = "";
  commentQurantined: string = "";

  transactionDetails: Content[] = [];
  firewallDetails: Content[] = [];
  complianceDetails: Content[][] = [];
  moreDetails: Content[] = [];

  uniqueApprovals: Approval[] = [];
  allApprovals: Approval[] = [];
  approvalTabs = ApprovalTabs;
  pendingFirewallApproval: number = 0;
  selectedAccrodion = AccordionTabs.DETAILS;
  accordionTabs = AccordionTabs;
  user: any = {};

  isLivenessTransactionExists: boolean = false;
  txnInitiators: TxnSigner[] = []
  txnSigners: TxnSigner[] = []
  members: TxnSigner[] = []
  teamActionType = TeamActionType;
  readonly EXCHANGE = "exchange";
  constructor(
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private data: DataService,
    public firewallService: FirewallService,
    private modalService: NgbModal,
    private authService: AuthServiceJWT,
    public activeModal: NgbActiveModal,
    public getCoinNamePipe: GetNamePipe,
    public amountPrettyPipe: AmountBeautifyPipe
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  get isReceiveTransaction() {
    return TxDirection.Receive === this.selectedTransactionData?.direction;
  }

  get isApprovalOrRejectionDone() {
    return (
      this.getQuorumsToApprove().length === 0 ||
      FirewallCheckStatus.REJECTED_STATUS  === this.selectedTransactionData.status
    );
  }

  get isCurrentUserPartOfApprovers() {
    let currentUser = this.authService.getUser;
    if (currentUser) {
      return this.allApprovals.some((approval) =>
        approval.members.some((member) => member.email === currentUser.email)
      );
    }
    return false;
  }

  getQuorumsToApprove() {
    let currentUser = this.authService.getUser;
    const quorumsToApprove =
      this.allApprovals.filter((approval) =>
        approval.members.some(
          (member) =>
            member.email === currentUser.email && !member.approvalStatus
        )
      ) || null;

    return quorumsToApprove;
  }

  ngOnInit(): void {
    this.populateActiveTab();
    if (this.selectedTransactionData.sequenceId)
      this.initializeFirewallDetails();

    this.populateTransactionDetails();
  }

  initializeFirewallDetails() {
    const { sequenceId, orgid } = this.selectedTransactionData;
    this.isLoading = true
    this.subscription = this.firewallService
      .getFirewallStatistics(sequenceId)
      .subscribe(
        (firewallResponse: FirewallApiResponse) => {
          this.populateFirewallAndApprovalsDetails(firewallResponse.data);
          this.isLoading = false;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  populateActiveTab() {
    const { selectedTransactionData } = this;
    if (selectedTransactionData) {
      if (this.isReceiveTransaction) {
        this.selectedTab = ApprovalTabs.FIREWALL_APPROVAL;
        return;
      }
      if (!selectedTransactionData.txn_req_id) {
        if (FirewallCheckStatus.FIREWALL_PENDING_STATUS === selectedTransactionData.status) {
          this.selectedTab = ApprovalTabs.FIREWALL_APPROVAL;
        } else if (selectedTransactionData.status === PendingTxnStatus.Pending) {
          this.selectedTab = ApprovalTabs.INITIATION;
          if (!selectedTransactionData.initiatorsList?.length) {
            this.selectedTab = ApprovalTabs.MEMBERS;
          }
        } else if (selectedTransactionData.status === PendingTxnStatus.Completed) {
          this.selectedTab = ApprovalTabs.MEMBERS;
        }
      } else if (selectedTransactionData.status === PendingTxnStatus.Pending || selectedTransactionData.status === PendingTxnStatus.ExchangeApprovalPending) {
        this.selectedTab = ApprovalTabs.SIGNING;
      }
    }
  }

  populateFirewallAndApprovalsDetails(firewallResponse: FirewallResponseData) {
    // this.resetData();

    this.firewallResponse = firewallResponse;

    firewallResponse.firewall?.forEach((firewall: Firewall) => {
      // TODO: Simplify this logic - Currently Skipping any rule related to liveness tests
      if (
        getFirewallPolicyName(firewall.ruleName) !==
        RuleTypeTitle.LIVENESS
      ) {
        this.firewallDetails.push(
          {
            key: "Policy",
            values: [{ value: getFirewallPolicyName(firewall.ruleName) }],
          },
          {
            key: "Outcome",
            values: [
              { value: firewall.outcome },
              {
                value: this.getRuleName(
                  firewall.isDefaultRule,
                  firewall.priority
                ),
              },
            ],
          }
        );
      }
    });

    firewallResponse.outputs?.forEach((outcome: any) => {
      if (outcome.description && outcome.description.length > 0) {
        let complianceData = this.populateDescriptionDetails(outcome);
        this.complianceDetails.push(complianceData);
      }
    });

    this.allApprovals = firewallResponse.approval || [];
    this.pendingFirewallApproval = this.allApprovals[0]?.approvalsNeeded || 0;

    // Filter out all unique combinations of teamId and approvals Needed
    this.uniqueApprovals = Object.values(
      firewallResponse.approval.reduce((acc, approval) => {
        const existingApproval = acc.find(
          (p) =>
            p.teamId === approval.teamId &&
            p.approvalsNeeded === approval.approvalsNeeded
        );

        if (!existingApproval) {
          acc.push(approval);
        }

        return acc;
      }, [])
    );

    // If no approvals move the tab to signing directly for non-custody orgs only
    if (
      !this.isCustodyOrg &&
      firewallResponse.firewall.length &&
      this.allApprovals.length === 0 &&
      !this.isReceiveTransaction
    ) {
      this.selectedTab = ApprovalTabs.SIGNING;
    }

    firewallResponse.moreDetails?.forEach((details) => {
      this.moreDetails.push({
        key: details.key,
        values: [{ value: details.value }],
      });
    });
  }

  // Contains logic to populate empty objects
  populateDescriptionDetails(outcome: any) {
    let resultSet = [];
    let currentPosition = 1;
    let maxPosition = 0;

    outcome.description?.forEach((desc: any) => {
      const position = desc[2];
      maxPosition = Math.max(maxPosition, position);

      // Check if the position is what we expect
      while (currentPosition < position) {
        // Insert empty objects for missing positions
        resultSet.push({ key: "", values: [{ value: "" }] });
        currentPosition++; // Increment the current position
      }

      resultSet.push({ key: desc[0], values: [{ value: desc[1] }] });
      currentPosition++; // Increment the current position
    });

    // Optionally, add empty objects for any remaining positions
    while (currentPosition <= maxPosition) {
      resultSet.push({ key: "", values: [{ value: "" }] });
      currentPosition++;
    }
    return resultSet;
  }

  populateTransactionDetails() {
    let {
      walletinfor: walletDetails,
      addressLabel,
      destinationAddress,
      txHash,
      explorerLink,
    } = this.selectedTransactionData;
    // Transform values to display
    let destinationAddressTansformed = "Not Available";
    if (destinationAddress) {
      destinationAddressTansformed = `${destinationAddress.substring( 0, 10 )}...${destinationAddress.substring(destinationAddress.length - 5)}`;
    }
    let assetTransformedValue = this.getCoinNamePipe.transform(this.selectedTransactionData["asset"]);
    let amount = Number(this.selectedTransactionData["amount"]) || 0;
    let amountPrettyValue = `${this.amountPrettyPipe.transform(amount, 5)} ${this.selectedTransactionData["asset"]}`;
    let amountUsdPrettyValue = this.getUsdAmount();
    
    let sequenceId = this.selectedTransactionData.sequenceId;
    let sequenceIdTransformed = "-";
    if (sequenceId) {
      sequenceIdTransformed = `${sequenceId.substring(
        0,
        10
      )}...${sequenceId.substring(sequenceId.length - 5)}`;
    }
    const fromAccountName = (walletDetails.type === this.EXCHANGE) ? this.selectedTransactionData?.wallet?.account?.name : walletDetails["name"];
    this.transactionDetails = [
      { key: "From", values: [{ value: fromAccountName }] },
      { key: "Asset", values: [{ value: assetTransformedValue }] },
      {
        key: "Amount",
        values: [{ value: amountPrettyValue }, { value: amountUsdPrettyValue }],
      },
      {
        key: "To",
        values: [
          { value: addressLabel },
          { value: destinationAddressTansformed, tooltip: destinationAddress },
        ],
      },
      {
        key: "Sequence ID",
        values: [
          { value: sequenceIdTransformed ?? "-", tooltip: sequenceId ?? "" },
        ],
      },
    ];
    if (this.componentFor == "quarantine") {
      // Adding the new object to the array
      let _txHash = `${txHash.substring(0, 10)}...${txHash.substring(
        txHash.length - 5
      )}`;
      let newObj = {
        key: "Transaction Hash",
        values: [{ value: `${_txHash} (${explorerLink})`, tooltip: txHash }],
      };
      this.transactionDetails.push(newObj);
    }
    const membersTypeMap: Map<string, TxnSigner> = this.selectedTransactionData.signers?.reduce((acc, member) => acc.set(`${member.id || member.userid}_${member.user_type}`, member), new Map()) || new Map();
    const membersMap: Map<number, TxnSigner> = this.selectedTransactionData?.signs?.reduce((acc, member) => acc.set(member.id || member.userid, member), new Map()) || new Map();
    this.members = Array.from(membersMap.values())?.filter((e: TxnSigner) => e.userType !== UserModeEnum.MACHINE);
    const membersTypeValue: TxnSigner[] = Array.from(membersTypeMap.values());
    this.txnInitiators = membersTypeValue?.filter((member) => member.user_type === MembersType.INITIATORS);
    this.txnSigners = membersTypeValue?.filter((member) => member.user_type === MembersType.SIGNERS);
    this.isLivenessTransactionExists = this.selectedTransactionData.livenessPending;
  }

  getRuleName(isDefaultRule: number, priority: number): string {
    if (isDefaultRule === 1) return `( Default rule )`;
    return `( Rule - ${priority} )`;
  }

  getQuorumConfig(config: string): string {
    return config?.split("of")[0] ?? "";
  }

  isTransactionBlocked() {
    return this.isAnyFirewallOutcomeBlocked();
  }

  isTransactionAutoApproved() {
    return this.isAllFirewallOutcomeAllow() && this.allApprovals.length === 0;
  }

  isAllFirewallOutcomeAllow() {
    return this.firewallResponse.firewall.every(
      (firewall: Firewall) => TxCheckStatus.Allow === firewall.outcome
    );
  }

  isAnyFirewallOutcomeBlocked() {
    return this.firewallResponse.firewall.some(
      (firewall: Firewall) => TxCheckStatus.Block === firewall.outcome
    );
  }

  closeModal() {
    this.activeModal.close();
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  openModel(content: any) {
    this.commentUnqurantined = "";
    this.commentQurantined = "";
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      keyboard: false,
      backdrop: "static",
    });
  }

  async approveOrRejectTransaction(_isApprove: boolean) {
    const adjustProgress = (flag: boolean) => {
      if (_isApprove) {
        this.isApprovalInProgress = flag
      } else {
        this.isRejectionInProgress = flag
      }
    }
    adjustProgress(true);
    let { orgid, sequenceId } = this.selectedTransactionData;
    let quorumsToApprove = this.getQuorumsToApprove();
    let quorumApproveRequests: QuorumApproveRejectRequest = {
      requestIdentifier: sequenceId,
      quorumAppConfigId: quorumsToApprove.map((q) => q.quorumAppConfigId),
    }
    if (!_isApprove) {
      quorumApproveRequests['comment'] = this.commentQurantined;
    }

    this.authService
      .writeAccess(scopes["FirewallQuorum"])
      .subscribe(async (accessToken) => {
        try {
          const response = await this.firewallService.approveOrRejectTransaction(orgid, quorumApproveRequests, _isApprove, accessToken).toPromise();
          if (response.success) {
            if (response.data.length) {
              this.showAlerts(response.data);
              await this.refreshPendingTx();
              this.activeModal.close();
              this.modalService.dismissAll();
            }
          } else {
            Swal.fire(
              "Failed",
              `Firewall quorum ${_isApprove ? "Approval" : "Rejection"} failed`,
              "error"
            );
          }
        } catch (e) {
          ErrorPrompt.fire({
            icon: "error",
            title: `Quorum ${_isApprove ? "Approval" : "Rejection"} failed`,
            text: e.error.message,
            showConfirmButton: false,
          });
          console.error(e);
        } finally {
          adjustProgress(false)
        }
      }, (error) => {
        adjustProgress(false)
      });
  }

  showMultisigSignerButtons() {
    return (
      isV2MultisigWallet(this.selectedTransactionData?.walletinfor) &&
      this.showApproveMultisigButton(this.selectedTransactionData)
    );
  }

  showApproveMultisigButton(txData: any) {
    if (txData.signersList) {
      const walletSignerConfig =
        Number(txData.walletinfor.config.split("of")[0]) - 1;
      const approvedSigners = txData.signersList.filter(
        (ele) => ele.approved === true
      );
      const nonApprovedSigners = txData.signersList.filter(
        (ele) => ele.approved === false
      );

      if (approvedSigners.length == walletSignerConfig) {
        return false;
      } else if (
        approvedSigners.some((signer) => signer.email === this.user.email)
      ) {
        return false;
      } else if (
        nonApprovedSigners.some((signer) => signer.email === this.user.email)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  showAlerts(responses: QuorumApproveRejectResponse[]) {
    const allApproved = responses.every(
      (response) => response.status === QuorumReqApprovalStatus.APPROVED
    );
    const allRejected = responses.every(
      (response) => response.status === QuorumReqApprovalStatus.REJECTED
    );

    if (allApproved) {
      Swal.fire(
        "Transaction Approved",
        `You've successfully approved the transaction. It will now be processed accordingly.`,
        "success"
      );
    } else if (allRejected) {
      const message = this.isReceiveTransaction
        ? "The transaction has been quarantined for further review."
        : "The transaction has been rejected and will not be processed.";
      Swal.fire(
        this.isReceiveTransaction
          ? "Transaction Quarantined"
          : "Transaction Rejected",
        message,
        "success"
      );
    } else {
      Swal.fire(
        "Partial Success",
        "Something went wrong while performing this action. Please try again.",
        "info"
      );
    }
  }

  async refreshPendingTx() {
    let wallets = await this.httpService.getAllMultisigWallets().toPromise();
    this.walletService.clearWallet();
    for (let i = 0; i < wallets.length; i++) {
      this.walletService.setWallet(wallets[i]);
    }
    this.data.changeWallets(wallets);
    this.httpService
      .getAllPendingWalletTransactions()
      .subscribe((txRequests) => {
        this.data.changePendingTxRequest(txRequests);
      });
    this.httpService.getTravelRulePending().subscribe((txRequests) => {
      this.data.changePendingTravelRuleTxRequest(txRequests);
    });
    this.httpService.getAllPendingMessageRequest().subscribe((txRequests) => {
      this.data.changePendingMessageSigningRequest(txRequests.data);
    });
    this.httpService.getTeamsPendingTxReq().subscribe((txRequests) => {
      this.data.changeMpcPendingTxRequest(txRequests.data);
    });
    this.httpService.getAllMultisigWalletTransactions().subscribe((txs) => {
      this.data.changeTxs(txs);
    });
    this.httpService
      .getAllPendingPoliciesTransactions()
      .subscribe((txRequests) => {
        this.data.changePendingPolicyRequest(txRequests.data.policyList);
      });
    this.httpService.getprofile(true).subscribe((userDetailRequests) => {
      this.data.changeUserProfileRequest(userDetailRequests);
    });
    this.httpService.getStakingWalletList().subscribe((data) => {
      this.data.changeStakingWalletRequest(data.data);
    });
    this.httpService._getStakingWalletList().subscribe((data) => {
      this.data.changeStakingV2WalletRequest(data.data);
    });
    this.data.triggerRefresh.next("true");
  }

  getStatusText(txData: any) {
    const wallet = this.walletService.getWallet(
      `${txData.walletid}_${txData.asset.toLowerCase()}`
    );
    if (wallet) {
      const m = Number.parseInt(wallet.config.split("of")[0]);
      if (txData.signs.filter((ele) => ele.approved).length >= m) {
        return "Execute";
      }
    }
    const signs = txData?.signs;
    if (signs && signs.length) {
      for (const sign of signs) {
        if (sign.isMine && sign.approved) {
          return "";
        }
      }
    } else {
      const signers = txData?.signers || [];
      if(signers && signers.length){
        for (const signer of signers) {
          if (signer && signer.approved) {
            return "";
          }
        }
      }
    }
    return "Approve";
  }

  onMpcApprove() {
    const txReq = this.selectedTransactionData;
    let status = this.getStatusText(txReq);
    if (status == "Signed") return;
    try {
      this.data.newMpcTxRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  approveMultiSig(txReq: MpcTxData) {
    try {
      this.data.newMpcTxRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  onApproveMultisig() {
    const txReq = this.selectedTransactionData;
    let status = this.getStatusText(txReq);
    if (status == "Signed") return;
    try {
      // console.log("Multi Signature Transaction", txReq);
      this.data.newTxRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  getRejectText() {
    if (
      this.selectedTransactionData.status == 2 ||
      this.selectedTransactionData.type === 2 ||
      (this.selectedTransactionData.hasOwnProperty("isElegibleForRbf") && !this.selectedTransactionData["isElegibleForRbf"])
    ) {
      return "";
    } else {
      return "Reject";
    }
  }

  onReject() {
    this.onRejectTxn.emit(this.selectedTransactionData);
  }

  initiateTravelRule() {
    this.data.newTxRequest(this.selectedTransactionData);
  }

  onMessageSignApprove() {
    let status = this.getStatusText(this.selectedTransactionData);
    if (status == "Signed") return;
    try {
      this.data.newMessageSignRequest(this.selectedTransactionData);
    } catch (error) {
      console.error(error);
    }
  }

  /* Working
    We show the initiation phase buttons if the current tab is INITIATION or MEMBERS
    else specific case of custody org, during Liveness transaction in the pending state (which is firewall pending)
    we show the initiation phase buttons.
  */
  showInitiationPhaseButtons(): boolean {
    return (this.selectedTab === this.approvalTabs.INITIATION || this.selectedTab === this.approvalTabs.MEMBERS)
      || (this.isCustodyOrg && this.selectedTab === this.approvalTabs.FIREWALL_APPROVAL && this.isLivenessTransactionExists)
  }

  showApprovalTabs(): boolean {
    // If it is a custody org and has firewall details we show the approval tabs
    return !this.isCustodyOrg || (this.firewallDetails?.length > 0);
  }


  /**
   * Get the USD amount of the transaction
   */
  getUsdAmount() {
    const usdAmount = this.selectedTransactionData["usdAmount"];
    
    // Adding fallback for a bug in the API response
    const usdAmountAlt = this.selectedTransactionData["usdamount"];

    const amountUsd = usdAmount ?? usdAmountAlt;
    const amountUsdPrettyValue = `$${this.amountPrettyPipe.transform(
      amountUsd || 0,
      2
    )}`;

    return amountUsdPrettyValue;
  }

  checkMultiSigRemaining() {
    const { walletinfor, _signersSigns = 0, _signers = 0 } = this.selectedTransactionData;
    if (walletinfor) {
      const hasRemaining = (walletinfor.type?.toLowerCase() == 'multisig_shield' || walletinfor.type?.toLowerCase() == 'gnosis') ?  Number(_signers.toString()) -  Number(_signersSigns.toString()) - 1 > 0 :  Number(_signers.toString()) -  Number(_signersSigns.toString()) > 0 ;      
      return hasRemaining;
    }
    return false;
  }

  onClickAccordion(accordionTab: AccordionTabs, displayOpen: boolean) {
    if (!displayOpen) return;
    if (this.selectedAccrodion === accordionTab) {
      this.selectedAccrodion = null;
    } else {
      this.selectedAccrodion = accordionTab;
    }
  }
}
