<div class="w-100" *ngIf="wallets.length">
    <div class="header-title-margin text-muted py-3">
        <h2>Hot Wallets <a *ngIf="wallets.length > 6" routerLink="/wallets" fragment="hot"
            class="btn btn-link text-primary font-weight-bold p-0 float-right">View All</a></h2>
    </div>
</div>
<div class="w-100">
    <div class="row">
        <div class="col-12 col-xl-4" *ngFor="let hot_wallet of wallets.slice(0, 6)">
            <div class="card">
                <div class="card-body p-4">
                    <div class="media border-after-xs">
                        <img class="align-self-center mr-2 img-fluid img-round-border"
                            [src]='hot_wallet.coin | getIcon'>
                        <div class="media-body details align-self-center">
                            <div class="d-flex flex-column">
                                <span>{{ hot_wallet.name }}</span>
                                <span class="coin_type network {{ hot_wallet.chain.toLowerCase() }}">{{ hot_wallet.chain | getName }}</span>
                            </div>
                        </div>
                        <div class="media-body text-right">
                            <h6 class="card-balance">${{ hot_wallet.balanceUSD | number : '1.2-2'}}</h6>
                            <span class="text_muted_new">{{ hot_wallet.balance | number : '1.5-5'}} {{hot_wallet.coin}}
                            </span>
                        </div>
                    </div>
                    <div class="d-flex mt-3 font-13">
                        <div class="d-flex  flex-column px-1">
                            <div class="col p-0 text-center">
                                <span class="pipeline_type pills pills-primary"
                                    *ngIf="hot_wallet.subtype === 'hot'">Withdrawal</span>
                                <span class="pipeline_type pills pills-primary"
                                    *ngIf="hot_wallet.subtype === 'deposit'">Deposit</span>
                                <span class="pipeline_type pills pills-primary"
                                    *ngIf="hot_wallet.subtype === 'hot_pipeline'">Withdrawal</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>