import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DataService } from "../../../shared/services/data.service";
import { AuthServiceJWT } from "../../../shared/services/auth.service";
import { HttpService } from "../../../shared/services/http.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { MembersService } from "../../../shared/services/members.service";
import {
  CUSTODY_TYPE,
  orgType,
  orgUserRequestStatus,
} from "../../../shared/entities/members";
import { SegmentService } from 'ngx-segment-analytics';

@Component({
  selector: "add-team-custody",
  templateUrl: "./add-team-custody.component.html",
  styleUrls: ["./add-team-custody.component.scss"],
})
export class AddTeamCustodyComponent implements OnInit {
  @Output() isTeamCreatedSuccessfully = new EventEmitter<boolean>();

  total_members;
  dropdownSettingsInitiator: IDropdownSettings = {};
  dropdownTeamType: IDropdownSettings = {};
  getmem: any;
  minInt = [];

  members: any;
  _membersInitiator = [];
  selected_asset_value: string;
  added_member = false;
  total_members_limit = false;
  enable_preivew = false;
  user: any;
  createWalletOption = false;
  selected_Asset = false;
  shield: boolean = true;
  fixed_shield = true;
  focusTotInit = false;
  focusToType = false;
  focusTotSign = false;
  teamName: string;
  minimumInitiator = [];

  rangeNode = [
    {
      key: 1,
    },
    {
      key: 2,
    },
    {
      key: 3,
    },
    {
      key: 4,
    },
    {
      key: 5,
    },
    {
      key: 6,
    },
    {
      key: 7,
    },
    {
      key: 8,
    },
    {
      key: 9,
    },
    {
      key: 10,
    },
  ];
  walletType = [
    {
      name: 'Multi Sig',
      value: 'multisig_shield'
    },
    {
      name: "MPC",
      value: "mpc",
    },
  ];
  selected_type: any = {};
  minimum_initiator_selected = 1;

  orgType = orgType;
  custodyType = CUSTODY_TYPE;

  @Input() component_for: string;
  @Input() orgProfile: any;
  @Input() buttonClass: string;
  
  isLoading: boolean = false;
  status: string;

  constructor(
    private memberservice: MembersService,
    private data: DataService,
    public authService: AuthServiceJWT,
    private httpService: HttpService,
    private modalService: NgbModal,
    private segment: SegmentService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.init();
  }

  async init() {
    let profile = this.data.getUserProfile.getValue();
    this.getMember(profile.organizations[0].id);
  }

  async getMember(ordId) {
    let members = await this.httpService.getOrgMembers(ordId);
    this.members = members.members;
    this.members = this.members.filter((e) => e.userType == 1);
  }

  ngOnInit(): void {
    this.getmem = [];
    this.memberservice.resetMember();
    this.dropdownSettingsInitiator = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      enableCheckAll: false,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: "",
    };
    this.dropdownTeamType = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      closeDropDownOnSelection: false,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
  }

  addTeam(addTeamModal: any) {
    this.modalService.dismissAll();
    this.status = "";
    this._membersInitiator = [];
    this.minimum_initiator_selected = 1;
    this.teamName = "";
    this.selected_type = {};
    this.minInt = [];
    this.getmem = [];
    this.memberservice.resetMember();
    this.selected_type.name = "MPC";
    this._membersInitiator = this.members
      ?.filter((ele) => ele.status === orgUserRequestStatus.active)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  
    this.modalService
      .open(addTeamModal, {
        windowClass: "add-team modal-custom-background",
        centered: true,
      })
      .result.then(
        () => {},
        () => {}
      );
  }

  get getMembers() {
    return this.members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  onMemberSelect(memberOption: any, type) {
    const member = this.getMembers[memberOption.id];

    const isPresentInt = this.minInt.find((data) => data.email == member.email);
   
    if (type === "initiator") {
      if (!isPresentInt) {
        this.minInt.push(member);
        this.minInitiatorRequired();
      }
    }
  }

  onMemberDeSelect(memberOption: any, type) {
    const member: any = this.getMembers[memberOption.id];

    if (type === "initiator") {
      this.minInt = this.minInt.filter((ele) => ele.email !== member.email);
      this.minInitiatorRequired();
      if (
        this.minInt.length != 0 &&
        this.minInt.length < this.minimum_initiator_selected
      )
        this.minimum_initiator_selected = this.minInt.length;
    }
  }

  onDropDownBtnClick(data) {
    // this._membersInitiator = this.members.filter(ele => ele.canBeInitiator == true)
    this._membersInitiator = this.members?.filter(
      (ele) => ele.status === orgUserRequestStatus.active
    );
    this.selected_type = data;
  }

  numberList() {
    return this.rangeNode;
  }


  minInitiatorRequired() {
    this.minimumInitiator = [];
    for (let x = 1; x <= this.minInt.length; x++) {
      this.minimumInitiator.push(x);
    }
  }

  async addTeamBtn() {
    this.isLoading = true;

    const body = {
      name: this.teamName,
      initiator_config:
        this.minimum_initiator_selected + "of" + this.minInt.length,
      signer_config: null,
      type: "mpc",
      initiator_ids: this.minInt.map((ele) => {
        return ele.id;
      }),
      signer_ids: []
    };
    try {
      const response = await this.httpService.add_team(body).toPromise();
      this.segment.track("team-created", body).catch(()=>{});

      if (response) {
        this.isLoading = false;
        this.status = "success";
        this.isTeamCreatedSuccessfully.emit(true);
      } else {
        this.modalService.dismissAll();
        this.isLoading = false;
        Swal.fire({
          icon: "error",
          title: "Failed to create team",
          text: response.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.isLoading = false;
      this.modalService.dismissAll();
      Swal.fire({
        icon: "error",
        title: "Failed to add team",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }
}
