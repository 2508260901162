import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../services/http.service';
import { GasstationRule, Wallet } from '../../../shared/entities/wallet';
import { DataService } from '../../services/data.service';
import { WalletserviceService } from '../../services/walletservice.service';
import Swal from 'sweetalert2';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-gasstation-autofill',
  templateUrl: './gasstation-autofill.component.html',
  styleUrls: ['./gasstation-autofill.component.scss']
})
export class GasstationAutofillComponent implements OnInit, OnDestroy {
  @Input() wallet: Wallet;
  @Input() display: 'icon' | 'dropdown' = 'dropdown';
  hasRule: boolean = false;
  mode: string;
  rule: GasstationRule = {} as GasstationRule;
  rules: GasstationRule[] = [];
  wallets: Wallet[] = [];

  subscriber: Subscription = new Subscription();
  invalidAmmount: boolean = false;
  allowedDecimals: boolean = false;
  isEthBased: boolean = false;
  amtMessage: string = '';
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public modalService: NgbModal,
    public httpService: HttpService,
    public data: DataService,
    public walletService: WalletserviceService,
  ) { }

  ngOnInit(): void {
    this.onInitialize();
    this.isEthBased = this.wallet.parentchain?.toUpperCase() === 'EVM';
  }

  async onInitialize() {
    await this.getGasStationRules();
    await this.onRuleUpdated();

  }

  async onRuleUpdated() {
    const rule = this.rules?.find(rule => rule.destinitionWalletId === this.wallet.id);
    if(rule) {
      this.rule = rule;
      this.hasRule = this.rule?.status === 1;
    } else {
      await this.getWallets();
      this.hasRule = false;
      const chain = this.wallet.chain;
      const gasStation = this.wallets.find((wallet) => wallet.chain === chain && wallet.type === 'gasStation' && wallet.subtype === 'cold');
      if (gasStation) {
        this.rule.gasStationWalletDetails = gasStation;
        this.rule.gasstationWalletId = gasStation.id;
        this.rule.destinitionWalletId = this.wallet.id;
        this.rule.DestnitionWalletDetails = this.wallet;
        this.rule.ruleType = 'auto';
      };
      
    }
  }

  async updateGasStationRules() {
    let res = await this.httpService.getGasStationRules().toPromise();
    this.data.newGasstaionRules( res.data || []);
  }

  async createDefaultRule(body) {
    let res = await this.httpService.createDefaultRule(body).toPromise();
    return res.data;
  }

  async getGasStationRules() {
    this.subscriber.add(this.data.gasStationRules.subscribe(async (data) => {
      this.rules = data;
      await this.onRuleUpdated();
    }));
  }

  async getWallets() {
    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      this.wallets = res;
    })
  }

  async onIconClicked(gasStationModal: TemplateRef<any>) {
    if (this.hasRule) {
      this.mode = 'disable';
      this.modalService.open(gasStationModal)
    } else {
      this.mode = 'enable';
      this.modalService.open(gasStationModal);
    }
  }

  async onClickConfirm() {
    if (this.mode === 'update') {
      await this.updateGasStation();
    } else if (this.mode === 'disable') {
      await this.disableGasStation();
    } else if (this.mode === 'enable') {
      if (this.rule.id) {
        await this.enableGasStation();
      } else {
        const ruledata: GasstationRule = await this.createDefaultRule({
          gasStationWalletId: this.rule.gasstationWalletId,
          destinationWalletId: this.wallet.id
        });
        this.rule = {
          ...this.rule,
          ...ruledata
        };
        await this.updateGasStationRules();
      }
      this.mode = 'update';
    }
  }

  async updateGasStation() {
    try {
      let bodyObj = {
        "rule": {
          "gasstation_walletid":this.rule.gasstationWalletId,
          "min_amount":this.rule.min_amount,
          "transfer_amount":this.rule.transfer_amount,
          "ruleType":this.rule.ruleType,
          "max_fees":this.rule.max_fees,
          "status":1
        } 
      }
      const response = await this.httpService.updateGasStationRule(this.rule.id, bodyObj).toPromise();
      if (response.success === true) {
        this.modalService.dismissAll();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Awesome! You have successfully updated the gas station rule",
          showConfirmButton: false,
        });
        await this.updateGasStationRules();
      } else {
        this.modalService.dismissAll();
        Swal.fire({
          icon: "error",
          title: "Failed to update rule",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      Swal.fire({
        icon: "error",
        title: "Failed to update rule",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }

  async disableGasStation() {
    try {
      const response = await this.httpService.disableGasStationRule(this.rule.id, this.rule.gasstationWalletId).toPromise();
      if (response.success === true) {
        this.modalService.dismissAll();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Awesome! You have successfully disabled the gas station rule",
          showConfirmButton: false,
        });
        await this.updateGasStationRules();
      } else {
        this.modalService.dismissAll();
        Swal.fire({
          icon: "error",
          title: "Failed to disable rule",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      Swal.fire({
        icon: "error",
        title: "Failed to disable rule",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }

  async enableGasStation() {
    try {
      const response = await this.httpService.enableGasStationRule(this.rule.id, this.rule.gasstationWalletId).toPromise();
      if (response.success === true) {
        // this.modalService.dismissAll();
        // Swal.fire({
        //   icon: "success",
        //   title: "Success",
        //   text: "Awesome! You have successfully enabled the gas station rule",
        //   showConfirmButton: false,
        // });
        await this.updateGasStationRules();
      } else {
        this.modalService.dismissAll();
        Swal.fire({
          icon: "error",
          title: "Failed to enable rule",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      Swal.fire({
        icon: "error",
        title: "Failed to enable rule",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }
  
  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }//end
  
  async transactionAmmountValidations(val: number) {
    if (val != undefined) {
      if (Number(val) > Number(this.wallet.balance)) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
        this.amtMessage = 'Low gas-station balance';
      } else if (Number(val) < 0.00001) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
        this.amtMessage = 'Transfer value low, should be greater than 0.00001';
      }
      else {
        if (this.decimalPlaces(val) > 5) {
          this.allowedDecimals = true;
          this.invalidAmmount = false;
        } else {
          this.allowedDecimals = false;
          this.invalidAmmount = false;
        }
      }
    } else {
      this.invalidAmmount = true;
      this.allowedDecimals = false;
    }
  }//end

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
    this.onDestroy$.next();
  }

}
