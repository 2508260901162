export const APIKeyDownMenu = [
    {
        id: 1,
        name: 'API key',
        icon: '',
        callback: 'updateKeyStatus',
        disabled: false
    },
    {
        id: 2,
        name: 'Delete API key',
        icon: 'trash.svg',
        callback: 'deleteAPIKey',
        disabled: false
    },
    {
        id: 3,
        name: 'IP Whitelist',
        icon: 'lock.svg',
        callback: 'restrictIpAddress',
        disabled: false
    },
    {
        id: 4,
        name: 'Rotate key',
        icon: 'key.svg',
        callback: 'handleRotateKeys',
        disabled: false
    },
]

export const APIKeyUrls = {
    UPDATE_KEY_STATUS: "/admin/updateKeyStatus",
    DELETE_API_KEY: "/admin/applicationuser/",
    ROTATE_API_KEY: "/admin/rotateClientKeys/"
}

export const PromptMessages = {
    DISABLE_KEY: {
        title: 'Disable API Key',
        text: 'Are you sure you want to disable this API key? This action will deactivate the key immediately.',
        btnText: 'Yes, Disable it',
        icon: 'info_group.svg'
    },
    ENABLE_KEY: {
        title: 'Enable API Key',
        text: 'Are you sure you want to enable this API key? This action will activate the key immediately.',
        btnText: 'Yes, Enable it',
        icon: 'info_group.svg'
    },
    DELETE_KEY: {
        title: 'Delete API Key',
        text: 'Are you sure you want to delete this API key? This action cannot be undone.',
        btnText: 'Delete Key'
    },
    SUCCESS_DISABLE_KEY: {
        title: "Success",
        text: "API Key disabled successfully !"
    },
    SUCCESS_ENABLE_KEY: {
        title: "Success",
        text: "API Key enabled successfully !"
    },
    SUCCESS_DELETE_KEY: {
        title: "Success",
        text: "API Key deleted successfully !"
    }
}

export enum StepsEnum {
    STEP1 = 'step-1',
    STEP2 = 'step-2',
    STEP3 = 'step-3' //for future
}
