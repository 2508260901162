import { Title } from "@angular/platform-browser";
import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { Subscription } from "rxjs";
import { PolicyService } from "../../../shared/services/policy.service";
import { Wallet } from "../../../shared/entities/wallet";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { getName } from "../../helpers/coins";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { csvGeneration } from "../../helpers/general";
import { environment } from "src/environments/environment";
import * as utils from "./utils";
import {
  CustomPrompt,
  ErrorPrompt,
} from "../custom-prompt/custom-prompt.service";
import { HttpService } from "../../services/http.service";

@Component({
  selector: "app-policies-list",
  templateUrl: "./policies-list.component.html",
  styleUrls: ["./policies-list.component.scss"],
})
export class PoliciesListComponent implements OnInit {
  pageTitle = `${environment.buildName} Vaults - Policy`;

  page = 1;
  pageSize = 10;
  pagestart: any;
  policySearch = "";
  wallets: Wallet[];
  policyAddress: string;
  policyTimePeriod: string;
  policyType: string;
  listPolicyData: any = [];
  component_for = "policy_list";
  filterPolicyType = "All";
  removePolicyTxData: any;
  profileData: any;
  deviceName: string;
  addpolicy_component_for: string = "policy_list";
  orgSubscription: Subscription;
  orgRole: number;
  orgId: any;
  loading: boolean = true;
  @Input() policylist_component_for: string;
  @Input() walletDetailWalletId: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  constructor(
    private data: DataService,
    private modalService: NgbModal,
    private policyService: PolicyService,
    private httpservice: HttpService,
    private titleService: Title
  ) {
    // this.walletDetailWalletId = this.route.snapshot.paramMap.get('walletId');
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnInit(): void {
    this.filterWalletDetailData();
    this.init();
  }

  min = (size, policy) => {
    return Math.min(size, policy);
  };

  async preparePolicyList() {
    this.data.wallets
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (wallets) => {
        if (!wallets) return;
        this.wallets = wallets;
        this.loading = true;
        let allWallets = wallets;
        if (this.walletDetailWalletId) {
          const wallet = wallets.find(
            (wallet) => wallet.id.toString() === this.walletDetailWalletId
          );
          allWallets = [wallet];
        }
        const listData = [];
        await Promise.all(
          allWallets.map(async (wallet) => {
            wallet.coinfullname = getName(wallet.chain);
            const policies = await this.policyService.getWalletPolicySync(
              wallet.id, true
            );
            if (!policies) return;
            policies.forEach((policy) => {
              listData.push(utils.getPolicyListData(wallet, policy));
            });
          })
        );
        this.listPolicyData = listData.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        this.listPolicyData.filter(policy => {
          if(policy.subtype.toLowerCase() == 'threshold') {
            policy.name = "Per Transaction";
          }
        });
        this.loading = false;
      });
  }

  async init() {
    this.profileData = this.data.getUserProfile.getValue();
    this.orgId = this.profileData.organizations[0].id;
    this.orgRole = this.profileData.organizations[0].type;
    this.preparePolicyList();
    return;
  }

  async getDeviceName(): Promise<boolean> {
    let deviceList = [];
    if (this.profileData.keys["ETH"].length) {
      this.profileData.keys["ETH"].forEach((element) => {
        deviceList.push(element.provider.toLowerCase());
      });
      if (deviceList.includes("trezor")) {
        this.deviceName = "trezor";
      } else if (deviceList.includes("ledger")) {
        this.deviceName = "ledger";
      } else if (
        deviceList.includes("ledger") &&
        deviceList.includes("trezor")
      ) {
        this.deviceName = "trezor";
      }
    } else {
      return true;
    }
  }

  async openRemovePolicyPrompt(policy) {
    CustomPrompt.fire(
      {
        title: "Remove Policy",
        text: "Are you sure, you want to remove this policy?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        callbackData: policy,
      },
      (result) => {
        if (result.success) {
          this.removePolicy(result.data);
          result && result.modalRef.close("closed");
        }
      }
    );
  }

  async removePolicy(policyData: any) {
    if (policyData.subtype?.toLowerCase() === "address") {
      this.policyAddress = policyData.condition;
      this.policyType = "whitelist_address";
    }
    if (policyData.subtype?.toLowerCase() === "velocity") {
      this.policyTimePeriod = policyData.condition;
      this.policyType = "wallet_velocity";
    }
    if (policyData.subtype?.toLowerCase() === "threshold") {
      this.policyType = "wallet_threshold";
    }
    let policyRequest = "remove policy";
    try {
      const response = await this.policyService.removePolicy(
        policyData,
        this.policyType,
        this.policyAddress,
        this.policyTimePeriod,
        this.deviceName,
        policyRequest
      );

      if (response && response?.success === true) {
        this.modalService.dismissAll();
        Swal.fire({
          title: "Success!",
          text: "Policy successfully removed",
          icon: "success",
          confirmButtonText: "Cool",
        });

        // Remove the policy from the wallet list and update wallets for quick data refresh
        if(this.wallets && this.wallets.length){
          this.wallets = this.wallets.map(wallet => {
            if (wallet.id === policyData.walletid) {
              wallet.policy = wallet.policy?.filter(policy => policy.id !== policyData.policyid)
            }
            return wallet;
          });
          this.data.changeWallets(this.wallets);
        }
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to remove policy",
          text: response.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to remove policy",
        text: "Failed to remove policy",
        showConfirmButton: false,
      });
      console.error(e);
    }
  }

  search(policySearch): void {
    this.policyTypeFilter("All");
    this.policySearch = policySearch;
  }

  policyTypeFilter(type) {
    this.policySearch = "";
    this.filterPolicyType = type;
    this.page = 1;
  }

  filterPolicy(type) {
    return this.listPolicyData.filter(
      (policy) => policy.subtype.toLowerCase() == type
    ).length;
  }

  async downloadCSV() {
    this.filterWalletDetailData();
    let csv = [];

    csv = this.listPolicyData.map((policy) => {
      let obj = {};
      obj["Id"] = policy.policyid;
      obj["Wallet"] = policy.walletname;
      obj["Network"] = policy.chain;
      obj["Asset"] = policy.coin.toLowerCase();

      switch (policy.subtype.toLowerCase()) {
        case "address":
          obj["Type"] = "Whitelist Address";
          obj["Details"] = `${policy.name} (${policy.condition})`;
          break;
        case "velocity":
          obj["Type"] = "Spending Limit";
          obj["Details"] = `USD ${parseFloat(policy.amount).toFixed(2)} (${
            policy.condition
          })`;
          break;
        case "threshold":
          obj["Type"] = "Transaction Limit";
          obj["Details"] = `USD ${parseFloat(policy.amount).toFixed(
            2
          )} (per transaction)`;
          break;
        default:
          obj["Type"] = policy.subtype;
          obj["Details"] = policy.name;
          break;
      }

      obj["Approvers"] = JSON.stringify(
        (policy?.approvers &&
          policy?.approvers.map((member) => member.displayName).toString()) ||
          "N/A"
      );
      obj["Status"] = "Active";
      obj["Time"] = policy.created_at;

      return obj;
    });
    let keys = [
      "ID",
      "Wallet",
      "Network",
      "Asset",
      "Type",
      "Details",
      "Approvers",
      "Status",
      "Time",
    ];
    csvGeneration(csv, keys, "Policies_List");
  }
  filterWalletDetailData() {
    if (this.policylist_component_for == "wallet_details") {
      this.pageTitle = `${environment.buildName} Vaults - Wallets`;
      this.titleService.setTitle(this.pageTitle);
      this.listPolicyData = this.listPolicyData.filter(
        (obj) => obj.walletid == this.walletDetailWalletId
      );
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
