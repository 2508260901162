<div class="py-3">
    <img class="back-errow cursor-pointer" (click)="backTOStakingPage()" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAAlCAYAAADiMKHrAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGiSURBVHgB3dlNTsJAFMDx9zoxYYk3kBu44sMVHsETqEsTF+UGcgEZPhbuxBOIJxBXAiuP0CPgzijw7CMpQWk7pU0c3/wTkjKdzS9DSSeDUDDf98tLwsfwshl+AkJqDbQegcUQCsSgFeEzARxvDc8VUkVrPQdLeZCzBBBXXuyO/Wm5UCmgdSEqAIvtjTKBAOnyTusALLbXM5UF1Nd6CJbLjJIC4jKhJIE4I0oaiEtFSQRxiSipIC4WJRnE7aCkg7gfKBdA3AblCohbo0wgRGx/fqyG8I8rlWAe7QzQuEKyCr6QTr0leR1HQNzRAeG9R0CugKKaHhA9gUMR4EjNppNxtdGooAM/wfAxGi+QrjZ/6dd+a4gE53GTkfCm17ttg5BUdDGbTEaJK4bQrNdOYDp9fQEBqe0vrsDU7wEXYCpuUDpMJd2QDFNpN6XClGmCRJgRxUmDZUJxkmCZUZwU2F4ozgSrVevvs3ASWCz3+VTau2J4PnUo8nxqoDsXhPAQd8/2+VShk0QuZsWCfrdTAYvlXqmo9YoRdcO9zBvvZ5ZIZ2C5b39KLOTpqjoHAAAAAElFTkSuQmCC'/>
</div>
<div class="center card card-margin">
    <!-- <button class="btn-primary" >Back</button> -->
    <div class="d-flex justify-content-between align-items-center p-4">
        <h2 class="mr-2 mb-0 font-weight-500">Validator List</h2>
        <div class="d-flex align-items-center">
            <img class="align-self-center mr-1 img-fluid img-round-border mr-2" [src]="validatorsListData.wallet.coin | getIcon">
            <div class="d-flex flex-column">
                <span>{{validatorsListData.wallet.name}}</span>
                <span class="eth mb-0 network text-dark">  {{ validatorsListData.wallet.chain | getName }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            
                <div class="table-responsive table-new">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="text-left pl-4" style="width: 18%;">Validator</th>
                                <th scope="col" class="text-left" style="width: 8%;">Amount</th>
                                <th scope="col" class="text-left" style="width: 18%;">Deposit Transaction</th>
                                <th scope="col" class="text-left" style="width: 18%;">Withdrawal Address</th>
                                <th scope="col" class="text-left" style="width: 8%;">Rewards</th>
                                <th scope="col" class="text-left" style="width: 8%;">Status</th>
                                <th scope="col" style="width: 14%;"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="validatorsListData.stakingValidatorList.length">
                            <tr *ngFor="let validator of validatorsListData.stakingValidatorList | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                                    <td class="pl-4">
                                        <a href="{{validator.validatorurl}}" target="_blank">
                                            <span ngbTooltip="{{validator.validator}}">
                                                {{validator.validator.substring(0,11)}}...{{validator.validator.substring(validator.validator.length - 11)}}
                                            </span>
                                        </a>
                                    </td>
                                  <td>
                                    <span class="text-dark">
                                        {{validator.amount}} {{validatorsListData.wallet.coin}}   
                                    </span>
                                    </td>
                                        
                                        <td>
                                            <ng-container *ngIf="validator.transactionHash">
                                                <a href="{{validator.depositTxurl}}" target="_blank">
                                                    <span ngbTooltip="{{validator.transactionHash}}">
                                                        {{validator.transactionHash.substring(0,11)}}...{{validator.transactionHash.substring(validator.transactionHash.length - 11)}}
                                                    </span>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngIf="!validator.transactionHash">
                                                <span>-</span>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <a href="{{validator.withdrwalAddressurl}}" target="_blank">
                                                <span ngbTooltip="{{validator.withdrawalAddress}}">
                                                    {{validator.withdrawalAddress.substring(0,11)}}...{{validator.withdrawalAddress.substring(validator.withdrawalAddress.length - 11)}}
                                                </span>
                                            </a>
                                        </td>
                                        <td>
                                            <span class="text-dark">
                                                {{validator.rewards}} {{validatorsListData.wallet.coin}}   
                                            </span>
                                            </td>
                                        <td>
                                            <span class="text-dark">
                                                {{validator.Status}}
                                            </span>
                                        </td>
                                        <td class="pr-4">
                                            <div class="d-flex justify-content-end">
                                                <a href="{{validator.url}}" target="_blank" class="btn btn-view-only btn-round font-size-12 mr-2">View</a>
                                                <div [ngbTooltip]="(!validator.isPresignedTransaction.presignedTransactionstatus && 'Admins can exit validator once it’s active.')">
                                                    <button class="btn btn-red-light btn-round font-size-12 mr-2" (click)="ethExitUnstake(validatorsListData.wallet.id,'',validatorsListData.stakingEnabledValidatorDetails[0].id,'unstaking',validator)" [ngClass]="{'disabled': !validator.isPresignedTransaction.presignedTransactionstatus }"
                                                [disabled]="!validator.isPresignedTransaction.presignedTransactionstatus">
                                                Exit
                                                </button>        
                                                </div>
                                            </div>
                                            
                                        </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!validatorsListData.stakingValidatorList.length">
                            <tr>
                                <td colspan="7" class="text-center">No data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            <div class="col-12 my-3" *ngIf="validatorsListData.stakingValidatorList.length">
              <div class="d-flex justify-content-between">
                <span>Showing <span>{{validatorsListData.stakingValidatorList.length | paginationFromTo : page : pageSize}}</span> of {{validatorsListData.stakingValidatorList.length}} entries</span>
                <div class="pagination">
                  <ngb-pagination [collectionSize]="validatorsListData.stakingValidatorList.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="3" [boundaryLinks]="true" [ellipses]="false">
                    <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i></ng-template>
                    <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left" placement="top" ngbTooltip="Previous"></i></ng-template>
                    <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right" placement="top" ngbTooltip="Next"></i></ng-template> 
                    <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right" placement="top" ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>  
                  </ngb-pagination>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
