import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthServiceJWT } from './auth.service';
import { ErrorMsgs, NoShowError } from '../entities/Error';

interface RequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
}

/**
 * Represents the base API response.
 * @template T - The type of the data in the response.
 */
export interface BaseApiResponse<T> {
  success: boolean;
  message?: string;
  data?: T;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private auth: AuthServiceJWT
  ) {}

  get<T>(url: string, options: RequestOptions = {}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, options).pipe(catchError(this.handleError));
  }

  post<T>(url: string, body: any, options: RequestOptions = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, body, options).pipe(catchError(this.handleError));
  }

  put<T>(url: string, body: any, options: RequestOptions = {}): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, body, options).pipe(catchError(this.handleError));
  }

  delete<T>(url: string, options: RequestOptions = {}): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, options).pipe(catchError(this.handleError));
  }

  // Other methods (patch, head, options) can be added similarly

  private handleError(error: HttpErrorResponse): Observable<never> {
    // Handle and log errors
    return throwError(error);
  }

  /**
   * Execute an HTTP service method with a write access token obtained from the
   * AuthServiceJWT. If the user denies the request, an error is thrown.
   *
   * @param writeAccessKey The key to request write access for.
   * @param httpServiceMethod The HTTP service method to execute.
   * @param httpServiceArgs The arguments to pass to the HTTP service method.
   * @returns The result of the HTTP service method.
   */
  async executeApiWithAuth2Fa<T>(
    writeAccessKey: string,
    httpServiceMethod: (...args: any[]) => any,
    ...httpServiceArgs: any[]
  ): Promise<T> {
    try {
      // Get the access token
      const accessToken = await firstValueFrom(this.auth.writeAccess(writeAccessKey));

      // Call the HTTP service method with access token
      const result = await firstValueFrom(httpServiceMethod(...httpServiceArgs, accessToken));

      return result as T;
    } catch (error) {
      if (error.message === ErrorMsgs.POPUP_CLOSED) {
        return Promise.reject(new NoShowError('User denied transaction signing', error));
      }
      return Promise.reject(error);
    }
  }
}
