import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { HttpService } from '../../services/http.service';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';

@Component({
  selector: 'app-enable-mobile',
  templateUrl: './enable-mobile.component.html',
  styleUrls: ['./enable-mobile.component.scss']
})
export class EnableMobileComponent implements OnInit {
  step: string = '';
  deviceList:any;
  @Input() member:any = [];
  @Input() component_for:string;
  @Input() sdkEnabled: boolean;
  
  constructor(private modalService: NgbModal,private httpService: HttpService,) { }

  ngOnInit(): void {
    this.deviceList = this.member.providers;
  }

  open(content: any) {
    this.deviceList = this.member.providers;

    if(this.deviceList?.includes('mobile pending') || this.deviceList?.includes('mpc pending')||this.deviceList?.includes('mobile') ) 
    {
      this.step = 'step-1';
    } else 
    {
      this.step = 'step-2';
    }
     
    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true });
  }

  async enableMobile(userId)
  {
    let bodyObj;
    try {
      bodyObj = {
        "toUserId": userId
      }
      const response = await this.httpService.enablingMobileDevice(bodyObj).toPromise();
      console.log('response',response);
      if (response.success === true) {
        this.modalService.dismissAll();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Awesome! You have successfully initiated request for enabling mobile signing 🎉",
          showConfirmButton: false,
        })
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to initiate request for enabling mobile signing",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to initiate request for enabling mobile signing",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }

}
