<!-- <a href="javascript:void(0);" *ngIf="component_for == 'staking-approval-popup' && type == 'stake'" (click)="open(content3)" placement="top" ngbTooltip="Stake" class="text-dark text-underline font-weight-bold mr-2">Stake</a> -->
<a href="javascript:void(0);" *ngIf="component_for == 'staking-add'" (click)="open(content3);stakeAdd()" placement="top" 
ngbTooltip="Stake" class="text-dark text-underline font-weight-bold mr-2">Stake</a>
<a href="javascript:void(0);" *ngIf="component_for == 'staking-enable'" (click)="open(content3)" placement="top"
    [ngbTooltip]="actionType == 'btn'? '' : 'Enable Staking'" class="text-dark text-underline font-weight-bold mr-2">
    <span *ngIf="actionType == 'btn'; else textAction">
        <button appdisableclick="" class="btn btn-outline-grey btn-round font-size-12 ml-4 primary ng-star-inserted"><i class="icon-add-alt mr-1"></i>Add Wallet </button>
    </span>
    <ng-template #textAction>Enable Staking</ng-template></a>
<!-- <a>
    <button class="btn btn-link text-primary px-2" data-original-title="Arrove Policy" *ngIf="component_for == 'sign-staking' || component_for == 'sign-unstake' || component_for == 'sign-withdraw'" title="stake Policy" type="submit" (click)="open(content3)">Approve</button>
</a> -->
<button class="btn btn-primary flex-1" data-original-title="Approve" *ngIf="stakingData" title="Approve" type="submit" (click)="open(content3)">Approve</button>

<!-- <a>
    <button class="btn btn-link text-primary px-2" data-original-title="Arrove Policy" *ngIf="type=='policy' && action =='approve'" title="Policy" type="submit" (click)="open(content3)">Approve</button>
</a> -->
<ng-template #content3 let-modal>
    <div *ngIf="status == 'step-0'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-staking text-dark mr-2"></i>
                <ng-container>Enable Staking</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
                        <label class="text-dark">Wallet</label>
                        <input autocomplete="off" class="form-control search-custom" placeholder="Search wallet"
                            id="dropdownManual" [(ngModel)]="searchWalletInput" ngbDropdownAnchor
                            (focus)="myDrop.open()" type="text">
                        <ul ngbDropdownMenu class="whitelistscroll w-100 py-0">
                            <div class="d-flex" *ngFor="let walletdata of _wallets | wallet_search: searchWalletInput">
                                <button class="dropdown-content w-100 p-0 m-0 border-0 brd_Box">
                                    <app-wallet-card [option]=walletdata [AllWallets]="wallets"
                                        [dropdown_card]="'dropdown-card'" [card_padding]="'p-3'"
                                        (click)="selectWallet(walletdata)"></app-wallet-card>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div class="mb-4" *ngIf="selectedWallet">
                        <label class="text-dark">Wallet</label>
                        <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedWallet"
                            (click)="selectedWallet = !selectedWallet;selectedAsset? selectedAsset = !selectedAsset:''">
                            Clear
                        </span>
                        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'">
                        </app-wallet-card>
                    </div>
                    <div class="mb-4" ngbDropdown #myDropAssets="ngbDropdown" *ngIf="!selectedAsset">
                        <label class="text-dark">Select asset</label>
                        <input autocomplete="off" class="form-control search-custom" placeholder="Search asset"
                            id="dropdownManual1" [(ngModel)]="searchAssetInput" ngbDropdownAnchor
                            (focus)="myDropAssets.open()" type="text" [ngClass]="{'disabled': !selectedWallet}"
                            [disabled]="!selectedWallet">
                        <ul ngbDropdownMenu aria-labelledby="dropdownManual1" class="whitelistscroll w-100 py-0">
                            <div class="d-flex" *ngFor="let assetsdata of assets | wallet_search: searchAssetInput"
                                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                                <button class="dropdown-content w-100 p-0 m-0 border-0">
                                    <app-wallet-card [option]=assetsdata [dropdown_card]="'dropdown-card'"
                                        [card_padding]="'p-3'" [assetsCard]="'assets_card'"
                                        (click)="selectAssets(assetsdata)"></app-wallet-card>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div class="mb-4" *ngIf="selectedAsset">
                        <label class="text-dark">Asset</label>
                        <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedAsset"
                            (click)="selectedAsset = !selectedAsset">Clear</span>
                        <app-wallet-card [option]=selectedAsset [assetsCard]="'assets_card'" [card_padding]="'p-3'">
                        </app-wallet-card>
                    </div>
                    <!-- <div class="custom-mr">
                        <span class="d-inline-block text-dark">
                            You are staking assets on Polygon. Assets will be locked for 9 days.
                        </span>
                    </div> -->
                    <div>
                        <div class="mb-3" ngbDropdown #myDropValidator="ngbDropdown" *ngIf="!validatorDetail">
                            <label class="text-dark">Validator</label>
                            <!-- <input autocomplete="off" class="form-control" type="text" value="Infosys" readonly> -->
                            <input autocomplete="off" class="form-control search-custom" placeholder="Search asset"
                            id="dropdownManual2" [(ngModel)]="searchValidatorInput" ngbDropdownAnchor
                            (focus)="myDropValidator.open()" type="text" [ngClass]="{'disabled': !selectedWallet}"
                            [disabled]="!selectedWallet">
                            <ul ngbDropdownMenu aria-labelledby="dropdownManual2" class="w-100 py-0">
                                <div class="d-flex" *ngFor="let validator of validatorList | validator_search: searchValidatorInput"
                                    style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                                <button class="dropdown-content w-100 p-0 m-0 border-0">
                                    <app-wallet-card [option]=validator [dropdown_card]="'dropdown-card'"
                                        [card_padding]="'p-3'" validator="true"
                                        (click)="selectValidator(validator)"></app-wallet-card>
                                </button>
                                </div>
                            </ul>
                        </div>
                        <div class="mb-4" *ngIf="validatorDetail">
                            <label class="text-dark">Validator</label>
                            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="validatorDetail"
                                (click)="validatorDetail = null">Clear</span>
                            <app-wallet-card [option]=validatorDetail  validator="true" [card_padding]="'p-3'">
                            </app-wallet-card>
                        </div>
                        <!-- <div class="mb-3">
                            <label class="text-dark">Amount</label>
                            <div class="position-relative">
                                <input autocomplete="off" class="form-control" placeholder="Enter amount" id="dropdownManual" type="number" [(ngModel)]="stakeAmount" />
                                <span class="bg-white input-inner-right">{{selectedAsset?.coin}}</span>
                            </div>
                        </div> -->
                        <div class="mt-3">
                            <label class="text-dark">Comment</label>
                            <textarea autocomplete="off" [ngClass]="{'is-invalid': comment?.length > 100}"
                                class="form-control" [(ngModel)]="comment" placeholder="Enter comment"></textarea>
                            <div class="d-block invalid-feedback" *ngIf="comment?.length > 100">Only 100 characters
                                allowed.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100"
                [ngClass]="{'disabled': !selectedWallet || !selectedAsset || comment?.length > 100 }"
                [disabled]="!selectedWallet || !selectedAsset || comment?.length > 100"
                (click)="enableStaking(selectedWallet.id,selectedAsset.identifier,validatorDetail?.id,comment)">
                <div *ngIf="isSigning" class="loader-box">
                    <div class="loader-15"></div>
                </div>
                <span *ngIf="!isSigning">Next</span>
            </button>
        </div>
    </div>
    <div *ngIf="status == 'step-1'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-staking text-dark mr-2"></i>
                <ng-container>Stake Asset</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <!-- <div class="mb-4" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
                        <label class="text-dark">Wallet</label>
                        <input autocomplete="off" class="form-control search-custom" placeholder="Search wallet"
                        id="dropdownManual" [(ngModel)]="searchWalletInput" ngbDropdownAnchor (focus)="myDrop.open()"
                        type="text">
                        <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
                        <div class="d-flex"
                            *ngFor="let walletdata of _wallets | wallet_search: searchWalletInput"
                            style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                            <button class="dropdown-content w-100 p-0 m-0 border-0">
                            <app-wallet-card [option]=walletdata [AllWallets]="wallets" [dropdown_card]="'dropdown-card'" [card_padding]="'p-3'"
                                (click)="selectWallet(walletdata)"></app-wallet-card>
                            </button>
                        </div>
                        </ul>
                    </div> -->
                    <div class="mb-4" *ngIf="selectedWallet">
                        <label class="text-dark">Wallet</label>
                        <!-- <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedWallet"
                        (click)="selectedWallet = !selectedWallet;selectedAsset? selectedAsset = !selectedAsset:''">
                        Clear
                        </span> -->
                        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'">
                        </app-wallet-card>
                    </div>
                    <!-- <div class="mb-4" ngbDropdown #myDropAssets="ngbDropdown" *ngIf="!selectedAsset">
                        <label class="text-dark">Select asset</label>
                        <input autocomplete="off" class="form-control search-custom" placeholder="Search asset" id="dropdownManual1"
                          [(ngModel)]="searchAssetInput" ngbDropdownAnchor (focus)="myDropAssets.open()" type="text"
                          [ngClass]="{'disabled': !selectedWallet}" [disabled]="!selectedWallet">
                        <ul ngbDropdownMenu aria-labelledby="dropdownManual1" class="whitelistscroll w-100 py-0">
                          <div class="d-flex" *ngFor="let assetsdata of assets | wallet_search: searchAssetInput"
                            style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                            <button class="dropdown-content w-100 p-0 m-0 border-0">
                              <app-wallet-card [option]=assetsdata [dropdown_card]="'dropdown-card'" [card_padding]="'p-3'"
                                [assetsCard]="'assets_card'" (click)="selectAssets(assetsdata)"></app-wallet-card>
                            </button>
                          </div>
                        </ul>
                      </div> -->
                    <div class="mb-4" *ngIf="selectedAsset">
                        <label class="text-dark">Asset</label>
                        <!-- <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedAsset"
                        (click)="selectedAsset = !selectedAsset">Clear</span> -->
                        <!-- <app-wallet-card [option]=selectedAsset [assetsCard]="'assets_card'" [card_padding]="'p-3'">
                            </app-wallet-card> -->
                        <div class="card card-border">
                            <div class="card-body p-3">
                                <div class="media border-after-xs">
                                    <img class="align-self-center mr-2 img-fluid img-round-border"
                                        [src]="selectedAsset.assetSymbol | getIcon">
                                    <div class="details align-self-center">
                                        {{selectedAsset.assetSymbol}}</div>
                                    <div class="media-body text-right">
                                        <h2 class="card-balance">${{ selectedAsset.assetBalanceUsd | amountToPretty :
                                            2}}</h2>
                                        <span class="card-balance-coin">{{ selectedAsset.assetBalance | amountToPretty :
                                            5}} {{selectedAsset.assetSymbol}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-mr">
                        <span class="d-inline-block text-dark">
                            You can withdraw assets only after unstaking has successfully completed the checkpoints,
                            which can take up to 9 days.
                        </span>
                    </div>
                    <div>
                        <div class="mb-3">
                            <label class="text-dark">Validator</label>
                            <!-- <input autocomplete="off" class="form-control" type="text" [value]="selectedWalletValidator" readonly> -->
                            <app-wallet-card [option]=selectedWalletValidator  validator="true" [card_padding]="'p-3'">
                            </app-wallet-card>
                        </div>
                        <div class="mb-3">
                            <label class="text-dark">Amount</label>
                            <div class="position-relative">
                                <input autocomplete="off" class="form-control"
                                    [ngClass]="{'is-invalid': invalidAmmount || allowedDecimals}"
                                    onwheel="return false;" placeholder="Enter amount"
                                    (keyup)="transactionAmmountValidations(stakeAmount)" id="dropdownManual"
                                    type="number" [(ngModel)]="stakeAmount" />
                                <span class="input-inner-right">{{selectedAsset?.assetSymbol}}</span>
                            </div>
                            <div class="d-block invalid-feedback" *ngIf="invalidAmmount">Please enter Valid amount</div>
                            <div class="d-block invalid-feedback" *ngIf="allowedDecimals">Only five Decimal places
                                allowed.</div>

                        </div>
                        <div class="mt-3">
                            <label class="text-dark">Comment</label>
                            <textarea autocomplete="off" class="form-control"
                                [ngClass]="{'is-invalid': comment?.length > 100}" [(ngModel)]="comment"
                                placeholder="Enter comment" type="text"></textarea>
                            <div class="d-block invalid-feedback" *ngIf="comment?.length > 100">Only 100 characters
                                allowed.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100"
                [ngClass]="{'disabled': !selectedWallet || !stakeAmount || invalidAmmount || allowedDecimals || comment?.length > 100 }"
                [disabled]="!selectedWallet || !selectedAsset || !stakeAmount || invalidAmmount || allowedDecimals || comment?.length > 100"
                (click)="addStaking(selectedWallet.walletid,selectedAsset.assetIdentifier,stakeAmount,comment, selectedWallet.validatorId)">
                <div *ngIf="isSigning" class="loader-box">
                    <div class="loader-15"></div>
                </div>
                <span *ngIf="!isSigning">Next</span>
            </button>
        </div>
    </div>
    <div *ngIf="status == 'step-2'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-staking text-dark mr-2"></i>
                <ng-container>Approve Staking</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close text-dark"></i>
            </button>
        </div>
        <div class="modal-body modal-body-custom">
            <div class="d-flex flex-column align-items-center mt-5">
                <div class="row align-items-start w-75">
                    <div class="col-5 text-center">
                        <img class="align-self-center mr-1 mb-1 img-fluid img-round-border"
                            [src]="stakingData.asset | getIcon">
                        <span class="d-block">{{stakingData.asset}}</span>
                    </div>
                    <div class="col-2 mt-3 text-center">
                        <i class="icon-arrow-right"></i>
                    </div>
                    <div class="col-5 text-center">
                        <i class="icon-address text-secondary icon-round-border mb-1 bg-white"></i>
                        <span class="d-block">{{stakingData._metaData.destination_title}}</span>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <span class="d-block text-dark">{{stakingData.destinationAddress}}</span>
                    <span class="d-block">Address</span>
                </div>
            </div>
            <!-- <div class="mt-4">
                <div class="text-center">
                    <span class="h4 m-0 pl-2">Staking Approve MATIC</span>
                </div>
            </div> -->
            <div class="w-75 m-auto" *ngIf="stakingData._metaData.comment">
                <span class="text-center d-block mt-4 text-dark">{{stakingData._metaData.comment}}</span>
            </div>
        </div>
        <div class="modal-footer custom-border bg-white">
            <div class="d-flex align-items-center mb-4 w-100">
                <!-- <img class="mr-3 img-fluid img-round-border" src={{getImageUrl(selectedAsset)}}> -->
                <i class="icon-wallet text-primary icon-round-border mr-3"></i>
                <div class="d-flex flex-column">
                    <span class="body-font">{{stakingData.walletinfor.name}}</span>
                    <span class="network text-left text-muted {{stakingData.walletinfor.chain.toLowerCase()}}">
                        <!-- {{selectedAsset.chain}} -->
                        {{stakingData.walletinfor.chain | getName}}
                    </span>
                </div>
                <div class="media-body text-right">
                    <h2 class="card-balance body-font">{{stakingData.walletinfor.balance | amountToPretty : 5}}
                        {{stakingData.walletinfor.coin}}
                    </h2>
                    <span class="card-balance-coin text-muted">${{stakingData.walletinfor.balanceUSD | amountToPretty :
                        2}}</span>
                </div>
            </div>
            <div *ngIf="browser == 'Firefox' && device == 'ledger'; else not_fox_a"
                class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use
                Google Chrome or other supported browser.</div>
            <ng-template #not_fox_a>
                <button class="btn btn-primary active w-100 row py-2" (click)="onSubmit(stakingData.amount);onSign()">
                    <ng-container *ngIf="!isSigning">
                        <i class="icon-check mr-1"></i>
                        <span class="signin"> Sign </span>
                    </ng-container>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                </button>
            </ng-template>
        </div>
    </div>
    <div *ngIf="status == 'step-3'">
        <div class="modal-header">
          <h2 class="modal-title text-capitalize">
            <i *ngIf="stakingData?._metaData?.tx_category_type =='staking'; else elseIcon" class="icon-staking text-dark mr-2"></i>
            <ng-template #elseIcon><i class="icon-shield mr-2"></i></ng-template>
            <ng-container>Sign Transaction - {{stakingData._metaData.tx_category_type}} {{stakingData._metaData.tx_sub_category_type}}</ng-container>
        </h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
            <i class="icon-close text-dark"></i>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="stakingData && stakingData._metaData.tx_sub_category_type.toLowerCase() == 'stake' && stakingData.type == 3;else secondCard">
            <label class="text-dark">From</label>
            <app-wallet-asset-card class="selected-card" [option]="stakingData.walletinfor" [card]="'wallets'" [card_padding]="'p-3'"></app-wallet-asset-card>
          </div>
          <ng-template #secondCard>
               <div *ngIf="stakingData && (stakingData._metaData.tx_sub_category_type.toLowerCase() == 'unstake' || stakingData._metaData.tx_sub_category_type.toLowerCase() == 'claim rewards') && stakingData.type == 3;else thirdCard">
                <label class="text-dark">Validator</label>
                <app-validator-card [validatorData]="validatorDetail" [card]="'validator_amount'" [stakeAmountData]="stakingWaletDetail" [card_padding]="'p-3'"></app-validator-card>
              </div>
              <ng-template #thirdCard>
                <div>
                    <label class="text-dark">Wallet</label>
                    <app-wallet-card [option]="stakingData.walletinfor" [AllWallets]="wallets" [card_padding]="'p-3'"></app-wallet-card>
                  </div>
              </ng-template>
          </ng-template>
            
          <div class="d-flex flex-column align-items-center mt-5">
            <div class="row align-items-start w-75" 
                *ngIf="(stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'unstake' || stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'withdraw');else secondDiv">
                <div class="col-5 text-center">
                    <img *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'unstake' && stakingData.type == 3;else secondIcon" class="img-fluid img-round-border-big mb-1 bg-white p-3" [src]="validatorDetail?.logo" />
                    <ng-template #secondIcon>
                        <i class="icon-address text-secondary icon-round-border-big mb-1 bg-white"></i>
                    </ng-template>
                    <span class="d-block">{{stakingData._metaData.destination_title}}</span>
                  </div>
              <div class="col-2 text-center mt-4 pt-3 text-dark">
                <i class="icon-arrow-right"></i>
              </div>
              <div class="col-5 text-center">
                <img class="img-fluid img-round-border-big mb-1 bg-white p-3" [src]="stakingData._metaData.meta_asset_details.coin | getIcon" alt="nft img" >
                <span class="d-block text-uppercase">
                    {{stakingData._metaData.meta_asset_details.coin}}
                </span>
              </div>
            </div>
            <ng-template #secondDiv>
                <div class="row align-items-start w-75" 
                *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'claim rewards';else thirdDiv">
              <div class="col-12 text-center">
                <img class="img-fluid img-round-border-big mb-1 bg-white p-3" [src]="stakingData._metaData.meta_asset_details.coin | getIcon" alt="nft img" >
                <span class="d-block text-uppercase">
                    {{stakingData._metaData.meta_asset_details.coin}}
                </span>
              </div>
            </div>
            <ng-template #thirdDiv>
                <div class="row align-items-start w-75">
              <div class="col-5 text-center">
                <img class="img-fluid img-round-border-big mb-1 bg-white p-3" [src]="stakingData._metaData.meta_asset_details.coin | getIcon" alt="nft img" >
                <span class="d-block text-uppercase">
                    {{stakingData._metaData.meta_asset_details.coin}}
                </span>
              </div>
              <div class="col-2 text-center mt-4 pt-3 text-dark">
                <i class="icon-arrow-right"></i>
              </div>
              <div class="col-5 text-center">
                <img *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'stake' && stakingData.type == 3;else secondStakeIcon" class="img-fluid img-round-border-big mb-1 bg-white p-3" [src]="validatorDetail?.logo" />
                <ng-template #secondStakeIcon>
                    <i class="icon-address text-secondary icon-round-border-big mb-1 bg-white"></i>
                </ng-template>
                <span class="d-block">{{stakingData._metaData.destination_title}}</span>
              </div>
            </div>
            </ng-template>
            </ng-template>
          </div>
          <div>
            <div class="d-flex align-items-center justify-content-center mt-5">
              <input class="form-control text-right amount-input" type="text" value="{{stakingData._metaData.meta_asset_details.amount | amountToPretty : 5}}" 
                #stakeAmountInput [style.width]="(stakeAmountInput.value.toString().length * 15) + 'px'" readonly> 
              <span class="h3 m-0 pl-2 text-dark text-uppercase">{{stakingData._metaData.meta_asset_details.coin}}</span>
            </div>
            <div class="text-center">
                <label *ngIf="stakingData._metaData.meta_asset_details.amount_usd"
                    class="text-dark m-0">${{stakingData._metaData.meta_asset_details.amount_usd | amountToPretty : 2}}</label>
            </div>
            <div class="mt-3 text-center">
                <span class="text-dark">{{stakingData.destinationAddress}}</span>
              </div>
          </div>
          <div class="d-flex justify-content-center mt-3" *ngIf="stakingData._metaData.comment">
            <span class="d-inline-block text-dark w-75 text-center">{{stakingData._metaData.comment}}</span>
          </div>
          <div *ngIf="device?.toLowerCase() == 'ledger'" class="d-flex align-items-center justify-content-center text-dark mt-5">
            <i class="icon-ledger mr-2"></i>
            <span>Open app in your Ledger device and click Sign.</span>
          </div>
        </div>
        <div class="modal-footer border-0">
            <div *ngIf="browser == 'Firefox' && device == 'ledger'; else not_fox_b"
                class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use
                Google Chrome or other supported browser.</div>
            <ng-template #not_fox_b>
                <button class="btn btn-primary active w-100 row py-2" (click)="onSubmit(stakingData.amount);onSign()">
                    <ng-container *ngIf="!isSigning">
                        <i class="icon-check mr-1"></i>
                        <span class="signin"> Sign </span>
                    </ng-container>
                    <div *ngIf="isSigning" class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                </button>
            </ng-template>
        </div>
        
      </div>
    <div *ngIf="status == 'success'" class="modal-custom">
        <div class="modal-header border-0">
            <h2 class="modal-title">
                <i *ngIf="stakingData?._metaData?.tx_category_type =='staking'; else elseIcon" class="icon-staking mr-2"></i>
                <ng-template #elseIcon><i class="icon-shield mr-2"></i></ng-template>
                <div *ngIf="component_for == 'staking-enable'">
                    <span *ngIf="actionType == 'btn'; else textAction">
                        <button appdisableclick="" class="btn btn-outline-grey btn-round font-size-12 ml-4 primary ng-star-inserted"><i class="icon-add-alt mr-1"></i>Add Wallet </button>
                    </span>
                    <ng-template #textAction>Enable Staking</ng-template>
                </div>
                <div *ngIf="component_for == 'staking-add'">
                    Stake Asset</div>
                <div *ngIf="stakingData?.txType.toLowerCase() == 'approvestake' || stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'enable'">
                    Approve Staking</div>
                <div *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'stake' 
                || stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'unstake' 
                || stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'withdraw' || stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'approve' 
                || stakingData?._metaData?.tx_sub_category_type?.toLowerCase() == 'multicall'" class="text-capitalize">
                    Sign Transaction - {{stakingData._metaData.tx_category_type}} {{stakingData._metaData.tx_sub_category_type}}</div>
                
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body d-flex align-items-center">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50"
                                src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5">Success</div>
                        <div class="text-muted w-75 m-auto">
                            <ng-container *ngIf="component_for == 'staking-enable'">Awesome! You have successfully
                                initiated <br>request to enable staking. 🎉</ng-container>
                            <ng-container *ngIf="component_for == 'staking-add'">Awesome! You have successfully
                                initiated <br>staking transaction. 🎉</ng-container>
                            <ng-container
                                *ngIf="stakingData?.txType.toLowerCase() == 'approvestake' || stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'enable'">
                                Awesome! You have successfully signed approve staking transaction. 🎉</ng-container>
                            <ng-container *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'stake'">
                                Awesome! You have successfully signed <br>staking transaction. 🎉
                            </ng-container>
                            <ng-container
                                *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'unstake'">
                                Awesome! You have successfully signed <br>unstaking transaction. 🎉
                            </ng-container>
                            <ng-container
                                *ngIf="stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'withdraw'">
                                Awesome! You have successfully signed <br>staking withdraw transaction. 🎉
                            </ng-container>
                            <ng-container *ngIf="stakingData?.txType?.toLowerCase() == 'approve' ">
                                Awesome! You have successfully signed <br>Approve asset. 🎉
                            </ng-container>
                            <ng-container
                                *ngIf="stakingData?._metaData?.tx_sub_category_type?.toLowerCase() == 'multicall'">
                                Awesome! You have successfully signed <br>Swap transaction. 🎉
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');"> Done
            </button>
        </div>
    </div>
</ng-template>