<div>
    <div class="modal-header border-0 px-4">
        <div class="d-inline">
            <h2 class="modal-title">
                Update exchange API key
            </h2>
        </div>
        <button type="button" class="close float-right" aria-label="Close" (click)="exit()">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!(dynamicFields.length > 0) || showSkeleton">
            <table *ngFor="let item of [].constructor(4); let i = index">
                <tr>
                    <td style="width: 470px;height:50px">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                </tr>
                <tr>
                    <td style="width: 470px;height:50px">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                </tr>
            </table>
        </ng-container>
        <ng-container *ngIf="dynamicFields.length > 0 && !showSkeleton">
            <form [formGroup]="updateKeyForm">
                <div class="mt-2 pb-2">
                    <div class="d-inline">
                        <label class="mb-2 text-muted font-weight-500">
                            Connection Name
                        </label>
                    </div>
                    <input autocomplete="off" class="form-control" formControlName="connectionName"
                        placeholder="Enter connection name" type="text" maxlength="50" pattern="[a-zA-Z0-9 ]*">
                </div>
                <div *ngFor="let field of dynamicFields" style="margin-bottom: 20px">
                    <span class="mb-2 text-muted font-weight-500">{{ field.label }}</span>
                    <div class="custom-search text-dark" style="margin-top: 4px">
                        <div class="position-relative">
                            <input class="form-control" [type]="field.encrypted ? 'password' : 'text'"
                                [placeholder]="'Enter ' + field.label" [formControlName]="field.keyName"
                                style="padding: 0px; padding-left: 10px; padding-right: 10px" />
                            <button class="btn btn-link" *ngIf="field.visible"
                                style="position: absolute;left: 86%;bottom: 4px;"
                                (click)="toggleVisibility(field)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-eye" viewBox="0 0 16 16">
                                    <path
                                        d="M1.772 1.772a10.5 10.5 0 0 1 12.456 12.456l-1.5-1.5a8.5 8.5 0 0 0-9.456-9.456l-1.5-1.5zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm-.5 2a.5.5 0 0 0 1 0V8a.5.5 0 0 0-1 0v.5zm7.865 1.904a6.5 6.5 0 0 0-9.77-.002l-.707.707a7.498 7.498 0 0 1 11.183 0l-.707-.705zM15 8a7.5 7.5 0 0 0-11-6.633 7.5 7.5 0 0 0-4.5 13.766.498.498 0 0 0 .201.142l1.356.678a2 2 0 0 0 2.682-2.682l-.678-1.356a.498.498 0 0 0-.142-.201A7.5 7.5 0 0 0 8 15a7.5 7.5 0 0 0 6.633-11zM8 13a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="add-con-btn d-flex align-items-end">
                    <button class="btn btn-primary active w-100" (click)="submitUpdateForm()"
                        [disabled]="!updateKeyForm.valid">
                        <div class="loader-box align-loader" *ngIf="loading">
                            <div class="loader-15 bg-white"></div>
                        </div>
                        <div *ngIf="!loading">
                            Update API Keys</div>
                    </button>
                </div>
            </form>
         </ng-container>
    </div>
</div>
