import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../src/environments/environment";
import { LoggerService } from "../services/logger.service";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { APIKeyUrls } from "../constants/apiKeys.const";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";


@Injectable({
    providedIn: "root",
})

export class ApiKeyService {
    baseUrl: string = environment.apiUrl;
    APIKeyUrls = APIKeyUrls;
    constructor(
        private httpClient: HttpClient,
        private logger: LoggerService,
        private calendar: NgbCalendar
    ) {

    }

    getUserIpList(): Observable<any> {
        console.log(this.baseUrl + "/account/getUsersIPList", 'this.data++++');
        return this.httpClient.get(this.baseUrl + "/account/getUsersIPList").pipe(
            map((data: string) => {
               
                return data;
            }),
            catchError((error) => {
                this.logger.error('Error in getUserIpList', error);
                return throwError(error.error);
            })
        );
    }

    addOrDisableIpAddress(IpData, event, accessToken?): Observable<any> {
        let payload = {}
        if (event === "oncreate") {
            payload = {
                event: event,
                request: {
                    coUserId: IpData.coUserId,
                    ipAddress: IpData.ipAddress,
                    subnetMask: IpData.subnetMask,
                    coUserName: IpData.coUserName,
                    deviceType: 0 // default for web
                }
            }
        } else if (event === "enable" || event === "disable") {
            payload = {
                event: event === "enable" ? "onenable" : "ondisable",
                request: {
                    id: IpData.id,
                    coUserId: IpData.coUserId,
                    coUserName: IpData.coUserName,
                }
            }
        }
        let request;
        if(accessToken) {
            let headers: HttpHeaders = new HttpHeaders();
            headers = headers.append("Authorization", "Bearer " + accessToken);
            request = this.httpClient.post(
                this.baseUrl + "/account/restrictUserIP", payload,
            { headers: headers }
            )
        } else {
            request = this.httpClient.post( this.baseUrl + "/account/restrictUserIP", payload);
        }
        return request
        .pipe(
            map((data: string) => {
                return data;
            }),
            catchError((error) => {
                console.error(error);
                this.logger.error(error, error);
                return throwError(error.error);
            })
        );
    }

    commonAPiCall(type, url, data?, headers?) {
        const attacher =  data ?
        headers ? this.httpClient[type](`${this.baseUrl}${url}`, data, headers) :
        this.httpClient[type](`${this.baseUrl}${url}`, data, headers) :
        headers ? this.httpClient[type](`${this.baseUrl}${url}`, headers ) :
        this.httpClient[type](`${this.baseUrl}${url}`);
        return attacher
        .pipe(
            map((data: string) => {
            return data;
        }),
        catchError((error) => {
            this.logger.error(error, error);
            return throwError(error.error);
        }))
    }

    updateApiKeyStatus(payload, accessToken) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append("Authorization", "Bearer " + accessToken);
        return this.commonAPiCall('patch', APIKeyUrls.UPDATE_KEY_STATUS, payload, { headers });
    }

    deleteAPIkey(userId, accessToken) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append("Authorization", "Bearer " + accessToken);
        return this.commonAPiCall('delete', APIKeyUrls.DELETE_API_KEY+userId, null, { headers })
    }

    rotateAPIKey(userId, payload, accessToken) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append("Authorization", "Bearer " + accessToken);
        return this.commonAPiCall('post', APIKeyUrls.ROTATE_API_KEY+userId, payload, { headers })
    }

    dateConversion(dateString) {
        if(dateString) {
        const date = new Date(dateString);
          return (
            date.getDate() +
            " " +
            date.toLocaleString("en-us", { month: "short" }) +
            ", " +
            date.getFullYear()
          );
        }
      }
    
      setMaxDateRange() {
        let minRange = this.calendar.getToday();
        let date = new Date();
        const days = (date.getFullYear() + 1) % 4 == 0 ? 366 : 365;
        date.setDate(date.getDate()+days);
        let maxRange = { month: date.getMonth()+1, year: date.getFullYear(), day:date.getDate() };
        return {minRange, maxRange}
      }
}

