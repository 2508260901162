import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TeamMember } from 'src/app/shared/entities';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MembersListComponent {

  @Input() members: TeamMember[] = [];

  NUMBER_OF_MEMBERS_TO_SHOW: number = 5;



  constructor() { }

}
