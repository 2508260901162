var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { bip32asBuffer } from "./bip32";
const addressFormatMap = {
  legacy: 0,
  p2sh: 1,
  bech32: 2,
  cashaddr: 3
};
export function getWalletPublicKey(transport, options) {
  return __awaiter(this, void 0, void 0, function* () {
    const {
      path,
      verify,
      format
    } = Object.assign({
      verify: false,
      format: "legacy"
    }, options);
    if (!(format in addressFormatMap)) {
      throw new Error("btc.getWalletPublicKey invalid format=" + format);
    }
    const buffer = bip32asBuffer(path);
    const p1 = verify ? 1 : 0;
    const p2 = addressFormatMap[format];
    const response = yield transport.send(0xe0, 0x40, p1, p2, buffer);
    const publicKeyLength = response[0];
    const addressLength = response[1 + publicKeyLength];
    const publicKey = response.slice(1, 1 + publicKeyLength).toString("hex");
    const bitcoinAddress = response.slice(1 + publicKeyLength + 1, 1 + publicKeyLength + 1 + addressLength).toString("ascii");
    const chainCode = response.slice(1 + publicKeyLength + 1 + addressLength, 1 + publicKeyLength + 1 + addressLength + 32).toString("hex");
    return {
      publicKey,
      bitcoinAddress,
      chainCode
    };
  });
}
