'use strict';

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ('get' in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, 'default', {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o['default'] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== 'default' && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.Buffer256Bit = exports.NetworkSchema = void 0;
const v = __importStar(require('valibot'));
const Uint32Schema = v.pipe(v.number(), v.integer(), v.minValue(0), v.maxValue(0xffffffff));
const Uint8Schema = v.pipe(v.number(), v.integer(), v.minValue(0), v.maxValue(0xff));
exports.NetworkSchema = v.object({
  messagePrefix: v.union([v.string(), v.instance(Uint8Array)]),
  bech32: v.string(),
  bip32: v.object({
    public: Uint32Schema,
    private: Uint32Schema
  }),
  pubKeyHash: Uint8Schema,
  scriptHash: Uint8Schema,
  wif: Uint8Schema
});
exports.Buffer256Bit = v.pipe(v.instance(Uint8Array), v.length(32));