"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DecProto = exports.IntProto = exports.DecCoin = exports.Coin = exports.protobufPackage = void 0;
/* eslint-disable */
const binary_1 = require("../../../binary");
const helpers_1 = require("../../../helpers");
exports.protobufPackage = "cosmos.base.v1beta1";
function createBaseCoin() {
  return {
    denom: "",
    amount: ""
  };
}
exports.Coin = {
  typeUrl: "/cosmos.base.v1beta1.Coin",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.denom !== "") {
      writer.uint32(10).string(message.denom);
    }
    if (message.amount !== "") {
      writer.uint32(18).string(message.amount);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string();
          break;
        case 2:
          message.amount = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseCoin();
    if ((0, helpers_1.isSet)(object.denom)) obj.denom = String(object.denom);
    if ((0, helpers_1.isSet)(object.amount)) obj.amount = String(object.amount);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.denom !== undefined && (obj.denom = message.denom);
    message.amount !== undefined && (obj.amount = message.amount);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseCoin();
    message.denom = object.denom ?? "";
    message.amount = object.amount ?? "";
    return message;
  }
};
function createBaseDecCoin() {
  return {
    denom: "",
    amount: ""
  };
}
exports.DecCoin = {
  typeUrl: "/cosmos.base.v1beta1.DecCoin",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.denom !== "") {
      writer.uint32(10).string(message.denom);
    }
    if (message.amount !== "") {
      writer.uint32(18).string(message.amount);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDecCoin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.denom = reader.string();
          break;
        case 2:
          message.amount = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseDecCoin();
    if ((0, helpers_1.isSet)(object.denom)) obj.denom = String(object.denom);
    if ((0, helpers_1.isSet)(object.amount)) obj.amount = String(object.amount);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.denom !== undefined && (obj.denom = message.denom);
    message.amount !== undefined && (obj.amount = message.amount);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseDecCoin();
    message.denom = object.denom ?? "";
    message.amount = object.amount ?? "";
    return message;
  }
};
function createBaseIntProto() {
  return {
    int: ""
  };
}
exports.IntProto = {
  typeUrl: "/cosmos.base.v1beta1.IntProto",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.int !== "") {
      writer.uint32(10).string(message.int);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.int = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseIntProto();
    if ((0, helpers_1.isSet)(object.int)) obj.int = String(object.int);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.int !== undefined && (obj.int = message.int);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseIntProto();
    message.int = object.int ?? "";
    return message;
  }
};
function createBaseDecProto() {
  return {
    dec: ""
  };
}
exports.DecProto = {
  typeUrl: "/cosmos.base.v1beta1.DecProto",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.dec !== "") {
      writer.uint32(10).string(message.dec);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDecProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dec = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseDecProto();
    if ((0, helpers_1.isSet)(object.dec)) obj.dec = String(object.dec);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.dec !== undefined && (obj.dec = message.dec);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseDecProto();
    message.dec = object.dec ?? "";
    return message;
  }
};
