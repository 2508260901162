<button appDisableClick *ngIf="receiveassets_component_for != 'wallet_dropdown_receiveassets' && receiveassets_component_for != 'gas-station'" [ngClass]="{'disabled': singleWallet?.suspendedStatus  == 1 || singleWallet?.isArchived == 1}"
        [disabled]="singleWallet?.isArchived == 1 || singleWallet?.suspendedStatus == 1" id="CB_receive_asset" class="btn btn-round btn-outline-primary" type="submit" (click)="open(content2)">
  <i class="icon-receive mr-2" aria-hidden="true"></i> Receive
</button>

<div appDisableClick [ngbTooltip]="singleWallet?.suspendedStatus == 1 && 'Funds can still be received to this wallet'"  *ngIf="receiveassets_component_for == 'wallet_dropdown_receiveassets'" class="d-flex mb-2 py-2 commonCursor theme-font" [ngClass]="{'disabled': singleWallet?.suspendedStatus == 1 || singleWallet?.isArchived == 1}" style="padding: 0.25rem 1.5rem;" (click)="singleWallet?.suspendedStatus != 1  && open(content2)">
  <i class="icon-receive mt-1 mr-2 pl-1" ></i>
  <span class="btn-color" style="margin-left: 2px;">Receive</span>
</div>

<div appDisableClick *ngIf="receiveassets_component_for == 'gas-station'" class="d-flex mb-2 py-2 commonCursor" style="padding: 0.25rem 1.5rem;" (click)="open(content2)">
  <i class="icon-receive mt-1 mr-2 pl-1 refill-icon" ></i>
  <span class="btn-color" style="margin-left: 2px;">Refill</span>
</div>

<ng-template #content2 let-modal>
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-receive text-dark mr-2"></i>
        Receive Assets
      </h2>
      <button type="button" class="close" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <i class="icon-close"></i>
      </button>
    </div>
    <ng-container>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="mb-4" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
              <label class="text-dark">Select wallet</label>
              <input autocomplete="off" class="form-control search-custom" placeholder="Search wallet"
                id="dropdownManual" [(ngModel)]="searchWalletInput" ngbDropdownAnchor (focus)="myDrop.open()"
                type="text" />
              <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
                <div class="d-flex" *ngFor="let walletdata of wallets | wallet_search: searchWalletInput" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                  <button class="dropdown-content w-100 p-0 m-0 border-0">
                    <app-wallet-card [option]="walletdata" [AllWallets]="_wallets" [dropdown_card]="'dropdown-card'"
                      [card_padding]="'p-3'" (click)="singleWalletReceiveFilter(walletdata)"></app-wallet-card>
                  </button>
                </div>
              </ul>
            </div>
            <div *ngIf="selectedWallet">
              <label class="text-dark"><ng-container *ngIf="receiveassets_component_for == 'gas-station'">Gas Station</ng-container> Wallet</label>
              <span class="cursor-pointer text-muted text-underline float-right" *ngIf="receiveassets_component_for != 'wallet_dropdown_receiveassets' && receiveassets_component_for != 'gas-station'"
                (click)="selectedWallet = !selectedWallet">
                Clear
              </span>
              <app-wallet-card [option]="selectedWallet" [AllWallets]="_wallets" [card_padding]="'p-3'">
              </app-wallet-card>
            </div>
          </div>
        </div>
        <div *ngIf="selectedWallet">
          <div class="text-center">
            <div *ngIf="qrCodeImageUrl">
              <img [src]="qrCodeImageUrl" alt="QR Code" height="100" />
            </div>
            <i *ngIf="!qrCodeImageUrl" class="icon-address text-secondary icon-round-border-big mb-1 bg-white"></i>
          </div>
          <div class="m-t-20 m-b-20">
            <div class="text-center text-dark text-break">
              <span class="font-weight-bold">Address <i class="icon-copy small text-dark cursor-pointer" ngxClipboard
                  [cbContent]="selectedWallet.address" placement="top" triggers="click:blur" ngbTooltip="Copied"></i>
              </span>
              <p class="address-class">{{ selectedWallet.address }}</p>
              <p class="mt-5" *ngIf="selectedWallet?.parentchain?.toLowerCase()==='evm'">
                Please make sure the sender's address supports withdrawals to smart contract address on {{ selectedWallet.chain
                | getName }} Network
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
</ng-template>