import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService, BaseApiResponse } from "./api.service";
import { OrgCategory } from "../entities/User";
import { AuthServiceJWT } from "./auth.service";
import { HttpService } from "./http.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  private userOrgList$: BehaviorSubject<PendingOrganization[]> =
    new BehaviorSubject<PendingOrganization[]>([]);
  public getUserOrgList$ = this.userOrgList$.asObservable();
  private currentOrgSubject: BehaviorSubject<PendingOrganization>= new BehaviorSubject<PendingOrganization>(null);
  public currentOrg$: Observable<PendingOrganization>;
  public hasPendingInvite : boolean = false;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
    const currentOrg = localStorage.getItem("currentOrg");
    if(!currentOrg){
      return;
    }
    this.currentOrgSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentOrg"))
    );
    this.currentOrg$ = this.currentOrgSubject.asObservable();
  }

  getMyOrgList(): Observable<OrgListResponseDTO> {
    return this.apiService.get("/users/my-orgs");
  }

  onStorageChange(event: StorageEvent) {
    if (event.key === 'currentOrg') {
      const eventOrg = JSON.parse(event.newValue);
      let currentOrg: any = {};
      try {
        currentOrg = this.getCurrentOrg()
      } catch (err) {
        console.info('no curent org', err);
      }
      console.log('currentOrg', currentOrg);
      if (currentOrg.orgId !== eventOrg.orgId) {
        window.location.reload();
      }
    }
  }

  fetchOrgList(): Observable<PendingOrganization[]> {
    return this.getMyOrgList().pipe(
      map((res: OrgListResponseDTO) => {
        if (res && res.success && res.data) {
          let orgs = res.data.orgs || [];
          let orgsMng = [];
          let orgsNotMng = [];
          orgs.forEach((org) => {
            if (org?.isMng) {
              orgsMng.push(org);
            } else if (!org?.isMng) {
              orgsNotMng.push(org);
            }
          });

          let invitedOrgs = res.data.invites || [];
          if (invitedOrgs.length) {
            invitedOrgs = invitedOrgs.map((org) => ({
              ...org,
              isInvited: true,
            }));
          }
          const myOrgList: PendingOrganization[] = [...orgsNotMng, ...invitedOrgs];
          this.changeInUserOrgList(myOrgList);
          this.hasPendingInvite = invitedOrgs.length > 0;
          if(!myOrgList.length){
            if (orgsMng.length) {
              window.location.href = environment.adminPanelUrl;
              return [];
            }
            this.navigateToRestrictedPage();
          }
          return myOrgList;
        } else {
          this.changeInUserOrgList([]);
          this.navigateToRestrictedPage();
          return [];
        }
      })
    );
  }

  getCurrentOrg(): PendingOrganization {
    return (
      this.currentOrgSubject.value
    );
  }

  changeInUserOrgList(userOrgList: PendingOrganization[]) {
    this.userOrgList$.next(userOrgList);
  }

  setCurrentOrganization(org: PendingOrganization) {
    localStorage.setItem("currentOrg", JSON.stringify(org));
    this.currentOrgSubject.next(org);
  }

  navigateToRestrictedPage() {
    this.router.navigate(['/restricted-access']);
  }
}

export interface PendingOrganization {
  orgId: number;
  role: number;
  orgName: string;
  photoUrl?: string;
  type?: string;
  createdAt: string;
  inviteId?: number;
  isInvited?: boolean;
  orgCategory?: OrgCategory;
  isMng?: boolean;
}

export interface OrgListResponseDTO
  extends BaseApiResponse<Record<string, PendingOrganization[]>> {
  success: boolean;
  data: {
    orgs: PendingOrganization[];
    invites: PendingOrganization[];
  };
}
