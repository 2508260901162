import { Pipe, PipeTransform } from '@angular/core';
import { tokenType } from '../../entities/TransactionRequest';

@Pipe({
    name: 'teamTypeFilter'
  })
  export class TeamTypeFilterPipe implements PipeTransform {
    transform(_listWalletData: Array<any>, filterWalletType: any): Array<any> {
      if (filterWalletType === 'All') {
          return _listWalletData;
      }
      else {
        return _listWalletData.filter(item => item.type === 'mpc');
      }
    }
  }