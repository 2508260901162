"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProofOps = exports.ProofOp = exports.DominoOp = exports.ValueOp = exports.Proof = exports.protobufPackage = void 0;
/* eslint-disable */
const binary_1 = require("../../binary");
const helpers_1 = require("../../helpers");
exports.protobufPackage = "tendermint.crypto";
function createBaseProof() {
  return {
    total: BigInt(0),
    index: BigInt(0),
    leafHash: new Uint8Array(),
    aunts: []
  };
}
exports.Proof = {
  typeUrl: "/tendermint.crypto.Proof",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.total !== BigInt(0)) {
      writer.uint32(8).int64(message.total);
    }
    if (message.index !== BigInt(0)) {
      writer.uint32(16).int64(message.index);
    }
    if (message.leafHash.length !== 0) {
      writer.uint32(26).bytes(message.leafHash);
    }
    for (const v of message.aunts) {
      writer.uint32(34).bytes(v);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProof();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.total = reader.int64();
          break;
        case 2:
          message.index = reader.int64();
          break;
        case 3:
          message.leafHash = reader.bytes();
          break;
        case 4:
          message.aunts.push(reader.bytes());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseProof();
    if ((0, helpers_1.isSet)(object.total)) obj.total = BigInt(object.total.toString());
    if ((0, helpers_1.isSet)(object.index)) obj.index = BigInt(object.index.toString());
    if ((0, helpers_1.isSet)(object.leafHash)) obj.leafHash = (0, helpers_1.bytesFromBase64)(object.leafHash);
    if (Array.isArray(object?.aunts)) obj.aunts = object.aunts.map(e => (0, helpers_1.bytesFromBase64)(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.total !== undefined && (obj.total = (message.total || BigInt(0)).toString());
    message.index !== undefined && (obj.index = (message.index || BigInt(0)).toString());
    message.leafHash !== undefined && (obj.leafHash = (0, helpers_1.base64FromBytes)(message.leafHash !== undefined ? message.leafHash : new Uint8Array()));
    if (message.aunts) {
      obj.aunts = message.aunts.map(e => (0, helpers_1.base64FromBytes)(e !== undefined ? e : new Uint8Array()));
    } else {
      obj.aunts = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBaseProof();
    if (object.total !== undefined && object.total !== null) {
      message.total = BigInt(object.total.toString());
    }
    if (object.index !== undefined && object.index !== null) {
      message.index = BigInt(object.index.toString());
    }
    message.leafHash = object.leafHash ?? new Uint8Array();
    message.aunts = object.aunts?.map(e => e) || [];
    return message;
  }
};
function createBaseValueOp() {
  return {
    key: new Uint8Array(),
    proof: undefined
  };
}
exports.ValueOp = {
  typeUrl: "/tendermint.crypto.ValueOp",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.key.length !== 0) {
      writer.uint32(10).bytes(message.key);
    }
    if (message.proof !== undefined) {
      exports.Proof.encode(message.proof, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValueOp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.bytes();
          break;
        case 2:
          message.proof = exports.Proof.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseValueOp();
    if ((0, helpers_1.isSet)(object.key)) obj.key = (0, helpers_1.bytesFromBase64)(object.key);
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = exports.Proof.fromJSON(object.proof);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.key !== undefined && (obj.key = (0, helpers_1.base64FromBytes)(message.key !== undefined ? message.key : new Uint8Array()));
    message.proof !== undefined && (obj.proof = message.proof ? exports.Proof.toJSON(message.proof) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseValueOp();
    message.key = object.key ?? new Uint8Array();
    if (object.proof !== undefined && object.proof !== null) {
      message.proof = exports.Proof.fromPartial(object.proof);
    }
    return message;
  }
};
function createBaseDominoOp() {
  return {
    key: "",
    input: "",
    output: ""
  };
}
exports.DominoOp = {
  typeUrl: "/tendermint.crypto.DominoOp",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.input !== "") {
      writer.uint32(18).string(message.input);
    }
    if (message.output !== "") {
      writer.uint32(26).string(message.output);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDominoOp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.input = reader.string();
          break;
        case 3:
          message.output = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseDominoOp();
    if ((0, helpers_1.isSet)(object.key)) obj.key = String(object.key);
    if ((0, helpers_1.isSet)(object.input)) obj.input = String(object.input);
    if ((0, helpers_1.isSet)(object.output)) obj.output = String(object.output);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.key !== undefined && (obj.key = message.key);
    message.input !== undefined && (obj.input = message.input);
    message.output !== undefined && (obj.output = message.output);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseDominoOp();
    message.key = object.key ?? "";
    message.input = object.input ?? "";
    message.output = object.output ?? "";
    return message;
  }
};
function createBaseProofOp() {
  return {
    type: "",
    key: new Uint8Array(),
    data: new Uint8Array()
  };
}
exports.ProofOp = {
  typeUrl: "/tendermint.crypto.ProofOp",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.key.length !== 0) {
      writer.uint32(18).bytes(message.key);
    }
    if (message.data.length !== 0) {
      writer.uint32(26).bytes(message.data);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProofOp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.string();
          break;
        case 2:
          message.key = reader.bytes();
          break;
        case 3:
          message.data = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseProofOp();
    if ((0, helpers_1.isSet)(object.type)) obj.type = String(object.type);
    if ((0, helpers_1.isSet)(object.key)) obj.key = (0, helpers_1.bytesFromBase64)(object.key);
    if ((0, helpers_1.isSet)(object.data)) obj.data = (0, helpers_1.bytesFromBase64)(object.data);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.type !== undefined && (obj.type = message.type);
    message.key !== undefined && (obj.key = (0, helpers_1.base64FromBytes)(message.key !== undefined ? message.key : new Uint8Array()));
    message.data !== undefined && (obj.data = (0, helpers_1.base64FromBytes)(message.data !== undefined ? message.data : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBaseProofOp();
    message.type = object.type ?? "";
    message.key = object.key ?? new Uint8Array();
    message.data = object.data ?? new Uint8Array();
    return message;
  }
};
function createBaseProofOps() {
  return {
    ops: []
  };
}
exports.ProofOps = {
  typeUrl: "/tendermint.crypto.ProofOps",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.ops) {
      exports.ProofOp.encode(v, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProofOps();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ops.push(exports.ProofOp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseProofOps();
    if (Array.isArray(object?.ops)) obj.ops = object.ops.map(e => exports.ProofOp.fromJSON(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.ops) {
      obj.ops = message.ops.map(e => e ? exports.ProofOp.toJSON(e) : undefined);
    } else {
      obj.ops = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBaseProofOps();
    message.ops = object.ops?.map(e => exports.ProofOp.fromPartial(e)) || [];
    return message;
  }
};
