import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { WalletserviceService } from '../../services/walletservice.service';
import { toPretty } from '../../helpers/trezor/numberHelper'
import BN from "bignumber.js";
import { Wallet } from '../../entities/wallet';
import { LoggerService } from '../../services/logger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { Policy } from 'src/app/shared/entities/Policy';
import { AuthServiceJWT } from '../../services/auth.service';
import {toPrettyAmount } from '../pipes/amountrounded.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';

interface addressAmountPair {
  address: string;
  amount: number;
  policy: Policy
}

@Component({
  selector: 'app-staking-claming-rewards',
  templateUrl: './staking-claming-rewards.component.html',
  styleUrls: ['./staking-claming-rewards.component.scss']
})
export class StakingClamingRewardsComponent implements OnInit {

  closeResult = '';
  wallets: Wallet[];
  _wallet: any;
  searchWalletInput: string;
  searchAssetInput: string;
  invalidAmmount = false;
  isSigning = false;
  allowedDecimals = false;
  stakeInputAmount:any;
  comment:string;
  status: string = 'step-1';
  stakingInfoData:any;
  _stakeWalletData:any;
  pendingTx:any = [];
  
  @Input() walletDetailWalletId: any;
  @Input() component_for: string;
  @Input() stakeWalletData: any;
  
  private onDestroy$: Subject<void> = new Subject<void>();
  claimDropdown: { lable: string; amount: any; nonce: any; }[];
  selectedClaimOption: any;
  searchClaimOption: any;

  constructor(private data: DataService,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private logger: LoggerService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public authService: AuthServiceJWT,
    ) {

  

      this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallets => {
        if (wallets) {
          this.wallets = wallets;
        }
      });

    this.wallets = walletService.getAllWallets();

    let result = this.groupBy(this.wallets, (obj) => obj.chain);

  

  }//end constructor

  ngOnInit(): void {
    
    if(this.component_for == 'unstake' || this.component_for == 'staking-claming-rewards' || this.component_for == 'withdraw' || this.component_for == 'claim')
      {
        this._stakeWalletData = this.stakeWalletData;
        if(this.component_for == 'claim') {
          this.generateClaimDropdown();
        }
      }

  }



  
  pretty(number, decimal) {
    return toPretty(number, decimal)
  }

  groupBy<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    key: K | { (obj: T): string }
  ): Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
      const value = keyFn(obj);
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);
  }

  sumofwallets() {
    let total = new BN(0);
    if (this.wallets && this.wallets.length > 0)
      for (let i = 0; i < this.wallets.length; i++) {
        total = total.plus(new BN(this.wallets[i].balanceUSD));
      }

    return total

  }


  open(content) {

    
    this.isSigning = false;
  
    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
  
    }, (reason) => {
      this.resetSendModel()
    });
  }

  
  resetSendModel() {
    this.status = 'step-1';
    this.stakeInputAmount = null;
    this.comment = "";
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.searchClaimOption = "";
    this.selectedClaimOption = null;
  }

  
  async refreshPendingTx() {
    let txRequests = await this.httpService.getAllPendingWalletTransactions().toPromise();
    this.data.changePendingTxRequest(txRequests);
  }

 
  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }//end

  resetAmmountValidationErrormessages() {
    this.invalidAmmount = false;
    this.allowedDecimals = false;
  }//end

  async transactionAmmountValidations(val: number) {
    if (val != undefined) {
      let amount;
      if(this.component_for == 'unstake')
      {
        amount = toPrettyAmount.prototype.transform(this.stakeWalletData.stakingInfo.unstake.available,5);
      } 
      
      if (Number(val) > Number(amount)) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else if (Number(val) < 0.00001) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      }else {
        if (this.decimalPlaces(val) > 5) {
          this.allowedDecimals = true;
          this.invalidAmmount = false;
        } else {
          this.allowedDecimals = false;
          this.invalidAmmount = false;
        }
      }
    } else {
      this.invalidAmmount = false;
      this.allowedDecimals = false;
    }
  }//end

  
  async unStaking(id,amount, address, comment, validator)
  {
    this.isSigning = true;
    try {
      const response = await this.httpService.unStaking(id,amount,address,comment, validator).toPromise();

      console.log(response);
      if (response.success === true) {
        this.status = "step-success";
        this.isSigning = false;
      } else {
        this.modalService.dismissAll();
        this.isSigning = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to unstaking",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to unstaking",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.log(e);
    }
    this.refreshPendingTx();
  }
  async withdrawStaking(id, address, comment, validator, nonce)
  {
    this.isSigning = true;
    try {
      const response = await this.httpService.withdrawStaking(id,address,comment, validator, nonce).toPromise();

      console.log(response);
      if (response.success === true) {
        this.status = "step-success";
        this.isSigning = false;
      } else {
        this.modalService.dismissAll();
        this.isSigning = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to Withdraw Staking",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to Withdraw Staking",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.log(e);
    }
    this.refreshPendingTx(); 
  }

  async claimStaking(id, address, comment, validator)
  {
    this.isSigning = true;
    
    try {
      const response = await this.httpService.claimRewardStaking(id,address,comment, validator).toPromise();

      console.log(response);
      if (response.success === true) {
        this.status = "step-success";
        this.isSigning = false;
      } else {
        this.modalService.dismissAll();
        this.isSigning = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to Claim Rewards",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to Claim Rewards",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.log(e);
    }
    this.refreshPendingTx(); 
  }
  
  
  maxAmount()
  {
    if(this.component_for == 'unstake')
    {
      this.stakeInputAmount = toPrettyAmount.prototype.transform(this.stakeWalletData.stakingInfo.unstake.available,5);
      this.transactionAmmountValidations(toPrettyAmount.prototype.transform(this.stakeWalletData.stakingInfo.unstake.available,5));
    }

    // if(this.component_for == 'staking-claming-rewards')
    // {
    //   this.stakeInputAmount = toPrettyAmount.prototype.transform(this.stakeWalletData.stakingInfo.claimrewards.claimableRewards,5);
    //   this.transactionAmmountValidations(toPrettyAmount.prototype.transform(this.stakeWalletData.stakingInfo.claimrewards.claimableRewards,5));
    // }
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  generateClaimDropdown() {
    const data = [{lable: "Claimable Rewards", amount: this._stakeWalletData.stakingInfo.claimrewards.claimableRewards, nonce: null}];
    this._stakeWalletData.stakingInfo.withdraw.claimableStake?.map((w) => {
      data.push({lable: "Claimable Stake", ...w});
    });
    this.claimDropdown = data;
  }

  selectClaimOption(claim) {
    this.selectedClaimOption = claim;
    this.searchClaimOption = `${claim.lable} - ${toPrettyAmount.prototype.transform(claim.amount, 2)} MATIC`;
  }

  submitClaimOption() {
    if(this.selectedClaimOption?.nonce) {
      this.withdrawStaking(this._stakeWalletData.walletid, this._stakeWalletData.assetIdentifier, "claim stake", this._stakeWalletData.validatorId, this.selectedClaimOption.nonce);
    } else {
      this.claimStaking(this._stakeWalletData.walletid, this._stakeWalletData.assetIdentifier, "take withdraw reward", this._stakeWalletData.validatorId);
    }
  }
}