import TrezorConnect from "trezor-connect";
import { Output } from "../../../entities/Output";
import {
  TransactionRequest,
  tokenType,
  txType,
} from "../../../entities/TransactionRequest";
import { processSig } from "../../gnosisHelper";
import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import Eth from "@ledgerhq/hw-app-eth";
import { Guid } from "guid-typescript";
import { LedgerService } from "../../ledger/ledger";
import { HttpErrorResponse } from "@angular/common/http";

export async function signEthGnosisOfflineNew(
  output: Output,
  req?: TransactionRequest,
  type: string = txType.Normal,
  skipTravelRule?: boolean
) {
  try {
    let m = Number.parseInt(this.wallet.config.split("of")[0]);
    var walletkey = this.wallet.walletKeys;
    var objwalletkey = walletkey.find((x) => x.ismine === true);
    if (
      (!req &&
        !(
          req &&
          req.signs &&
          req.signs.filter((ele) => ele.approved == true).length >= m
        )) || req['actionType'] == 'travel-rule'
    ) {

      // let txnPreBuildRes;
      let txnPreBuildBody;
      if (this.wallet.tokenType == tokenType.nftToken) {
        txnPreBuildBody = {
          output: output,
          wallet: this.wallet,
          type: "nft",
          symbol: this.wallet.coin,
          tokenAddress: this.wallet.identifier,
          tokenType: tokenType.nftToken,
        };
      } else {
        txnPreBuildBody = {
          output: output,
          wallet: this.wallet,
          symbol: this.wallet.coin,
        };
      }

      let txnPreBuildRes;

      try {
        txnPreBuildRes = await this.httpService.getPrebuildTx({
          reqobj: txnPreBuildBody,
        });
      } catch (error) {
        this.logger.error('Error fetching prebuild transaction:', error);
        return [{ error: error.error.message || 'Failed to create Prebuild' }];
      }
      let txReqId;
      let safeTxHash = "";
      let mypath = "m/44'/60'/0'/0/0";
      if (txnPreBuildRes) {
        safeTxHash = txnPreBuildRes?.txHex;
        txReqId = txnPreBuildRes?.txReqId;
        for (let i = 0; i < this.wallet.walletKeys.length; i++) {
          if (this.wallet.walletKeys[i].ismine) {
            //   myAddress = this.wallet.walletKeys[i].xpub;
            mypath = this.wallet.walletKeys[i].path;
          }
        }
      }

      let trezorResonse;
      if (
        !objwalletkey ||
        !objwalletkey.provider ||
        objwalletkey.provider == "trezor"
      ) {

        //Trezor
        let sig;
        sig = await TrezorConnect.ethereumSignMessage({
          path: mypath || "m/44'/60'/0'/0/0",
          message: safeTxHash,
          hex: true,
        });

        trezorResonse = sig;

        if (!sig.success) return [{ error: trezorResonse.payload.code }];

        sig = processSig("0x" + sig.payload.signature);
        txnPreBuildRes.signature = sig;
        txnPreBuildRes.sender = trezorResonse.payload.address;

      } else if (objwalletkey.provider == "ledger") {
        //Ledger
        try {
          let Coinname = "Ethereum";
          let transport = await Transportwebusb.create();
          await LedgerService.openApp(Coinname, transport);
          transport = await LedgerService.getNewTransport();
          const eth = new Eth(transport);

          const sig1 = await eth.signPersonalMessage(
            mypath || "m/44'/60'/0'/0/0",
            safeTxHash.substring(2)
          );
          let sig = sig1["r"] + sig1["s"] + sig1["v"].toString(16);
          var address = await eth
            .getAddress(mypath || "m/44'/60'/0'/0/0")
            .then((o) => o.address);
          txnPreBuildRes.sender = address;
          trezorResonse = {
            signature: sig,
            address: address,
          };
          sig = processSig("0x" + sig);
          txnPreBuildRes.signature = sig;
        } catch (err) {
          this.logger.error(err);
          return [{ error: err.message || 'Please open Ethereum App on Ledger' }];
        }
      }
      // console.log("prebuild custom object---------", txnPreBuildRes);
      let gnosisResult = "";
      let sequenceId = Guid.create().toString();
      if (req) {
        if ('sequenceId' in req) {
          sequenceId = req['sequenceId'] as string;
        }
      }
      let sendTransactionBody = {};

      txnPreBuildRes.txHex = txnPreBuildRes.txHex.substring(2);
      if (this.wallet.tokenType == tokenType.nftToken) {
        let tx = {
          amount: 0,
          destinationAddress: output.address,
          coinSymbol: this.wallet.coin,
          tokenType: tokenType.nftToken,
          transactionHash: txnPreBuildRes.txHex,
        };
        sendTransactionBody = {
          txHex: txnPreBuildRes.txHex,
          nonce: txnPreBuildRes?.txDataResponse?.nonce,
          halfSigned: {
            payload: JSON.stringify(tx),
            txBase64: "",
            txHex: txnPreBuildRes.txHex,
            sequenceId: sequenceId,
          },
          txRaw: {
            safeInstance: txnPreBuildRes.txRaw.safeInstance,
            to: txnPreBuildRes.txRaw.to,
            valueInWei: txnPreBuildRes.txRaw.valueInWei,
            data: txnPreBuildRes.txRaw.data,
            operation: txnPreBuildRes.txRaw.operation,
            nonce: txnPreBuildRes.txRaw.nonce,
            safeTxGas: txnPreBuildRes.txRaw.safeTxGas,
            baseGas: txnPreBuildRes.txRaw.baseGas,
            gasPrice: txnPreBuildRes.txRaw.gasPrice,
            gasToken: txnPreBuildRes.txRaw.gasToken,
            refundReceiver: txnPreBuildRes.txRaw.refundReceiver,
            txType: txnPreBuildRes.txRaw.txType,
            contractTransactionHash:
              txnPreBuildRes.txRaw.contractTransactionHash,
            value: txnPreBuildRes.txRaw.value,
            safeTxHash: txnPreBuildRes.txRaw.safeTxHash,
            sender: txnPreBuildRes.sender,
            signature: txnPreBuildRes.signature,
          },
          signature: txnPreBuildRes.signature,
        };
      } else {
        sendTransactionBody = {
          txReqId: txReqId,
          txHex: txnPreBuildRes?.txHex,
          signature: txnPreBuildRes.signature,
          sequenceId: sequenceId,
        }
      }

      let transactionBody = { ...sendTransactionBody, isInternal: skipTravelRule ?? false, comment: output.comment }  // Skip travel rule

      let apiResult = await this.httpService.SendTransaction(
        this.wallet.id,
        transactionBody
      );

      return [trezorResonse, gnosisResult, apiResult];
    } else {
      console.info("this is send approve");

      let safeTxHash = JSON.parse(req.raw)?.safeTxHash;
      let pubKey = "";
      let gnosisResult = "";
      let signature = "";
      let mypath = "m/44'/60'/0'/0/0";
      if (this.wallet.tag === "refill") {
        safeTxHash = req.identifier;
      }
      for (let i = 0; i < this.wallet.walletKeys.length; i++) {
        if (this.wallet.walletKeys[i].ismine) {
          //   myAddress = this.wallet.walletKeys[i].xpub;
          mypath = this.wallet.walletKeys[i].path;
          pubKey = this.wallet.walletKeys[i].xpub;
        }
      }

      let trezorResonse;
      if (
        !objwalletkey ||
        !objwalletkey.provider ||
        objwalletkey.provider == "trezor"
      ) {
        //Trezor

        let sig;
        sig = await TrezorConnect.ethereumSignMessage({
          path: mypath || "m/44'/60'/0'/0/0",
          message: safeTxHash,
          hex: true,
        });

        trezorResonse = sig;
        console.info("Signing Trezor " + JSON.stringify(trezorResonse));

        this.logger.info("Signing result " + JSON.stringify(trezorResonse));
        if (!sig.success) return [{ error: trezorResonse.payload.code }];

        sig = processSig("0x" + sig.payload.signature);
        signature = sig;
        // txnPreBuildRes.sender = trezorResonse.payload.address;
        pubKey = trezorResonse.payload.address;
        console.info("sig", sig);
      } else if (objwalletkey.provider == "ledger") {
        //Ledger

        try {
          let Coinname = "Ethereum";
          let transport = await Transportwebusb.create();
          await LedgerService.openApp(Coinname, transport);
          transport = await LedgerService.getNewTransport();
          const eth = new Eth(transport);

          const sig1 = await eth.signPersonalMessage(
            mypath || "m/44'/60'/0'/0/0",
            safeTxHash.substring(2)
          );
          let sig = sig1["r"] + sig1["s"] + sig1["v"].toString(16);
          var address = await eth
            .getAddress(mypath || "m/44'/60'/0'/0/0")
            .then((o) => o.address);
          console.log("legder eth sign gnosis", address);
          // txnPreBuildRes.sender = address
          trezorResonse = {
            signature: sig,
            address: address,
          };
          sig = processSig("0x" + sig);
          signature = sig;
          pubKey = address;
        } catch (err) {
          this.logger.error(err);
          return [{ error: err.message || 'Please open Ethereum App on Ledger' }];
        }
      }

      // If trpid is present then it is a teams sign transaction (Multisig v2 wallets)
      if (req && req.trpId) {
        const unixTimeStamp = new Date().getTime()
        const body = {
          trpId: req.trpId,
          signature,
          timestamp: unixTimeStamp
        }
        const apiResult = await this.httpService.approveTeamsTx(body).toPromise();
        return [trezorResonse, gnosisResult, apiResult];
      }

      let body = {
        pubKey: pubKey,
        signature: signature,
      };
      let apiResult = await this.httpService
        .approveMultiSigSendTxnReq(this.wallet.id, req.id, body)
        .toPromise();
      console.info("apires------------", apiResult);
      return [trezorResonse, gnosisResult, apiResult];
    }
  } catch (e) {
    if (e instanceof HttpErrorResponse) {
      return [{ error: e?.error?.message || e?.message || e?.error?.code }];
    }
    return [{ error: e }];
  }
}
