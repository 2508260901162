import axios, { AxiosError } from "axios";

export const ErrorMessage=(err:unknown): string =>{
    const errors = err as Error | AxiosError | string;

    if (axios.isAxiosError(errors))
    {
        let axiosError=errors as AxiosError;
        
        if(axiosError.response){
            return (axiosError?.response?.data as any)?.message;
        }
    }
    else if(errors instanceof Error){
        return errors.message;
    }
    else{
        return String(errors);
    }

    return "An unexpected error occurred.";
}

/**
 * simply the complex json string from exchanges
 * @param comment 
 * @returns 
 */
export const simplifyErrorMessage = (comment: string) => {
    comment = comment.replace(/^"|"$/g, '');
    comment = comment.substring(comment.lastIndexOf(':') + 1).trim();
    comment = comment.replace(/\\"/g, '"').replace(/}$/, '').trim();
    comment = comment.replace(/"/g, '');
    const jsonMatch = comment.match(/{.*}/);
    if (jsonMatch) {
        const jsonObject = JSON.parse(jsonMatch[0]);
        return jsonObject.msg || 'Error details not provided';
    }
    return comment;
}