import { Injectable } from '@angular/core';
import { ApiService } from "./api.service"
import { Observable, throwError } from 'rxjs';
import { WalletRequest } from "../entities/wallet"
import { AuthServiceJWT } from './auth.service';
import { switchMap, catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorMsgs, NoShowError } from '../entities/Error';
import { TeamsV2 } from '../entities';
import { scopes } from '../constants/authScopes';
@Injectable({
  providedIn: 'root'
})
export class WalletService {
  
  constructor(private auth: AuthServiceJWT, private ApiService: ApiService, private httpService: HttpService) { }

  createWallet(walletRequest: WalletRequest, accessToken?: string) {
    let headers: HttpHeaders = new HttpHeaders();
    if (accessToken) {
      headers = headers.append("Authorization", "Bearer " + accessToken);
    }
    return this.ApiService.post("/wallet/create", walletRequest, { headers });
  }

  createWalletRequest(walletRequestBody: WalletRequest, accessToken?: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    if (accessToken) {
      headers = headers.append("Authorization", "Bearer " + accessToken);
    }
    let body = { wallet: walletRequestBody }
    return this.ApiService.post("/wallet/request", body, { headers });
  }

  createWalletWith2FA(walletRequestBody: WalletRequest, orgType: 'CUSTODY' | 'NONCUSTODY'): Observable<any> {
    return this.auth.writeAccess(scopes.WalletCreate).pipe(
      switchMap((accessToken: string) => {
        if (!accessToken) {
          return throwError("Unathorized access token");
        }
        
        if (orgType === 'CUSTODY') {
          return this.createWalletRequest(walletRequestBody, accessToken);
        }
  
        if (orgType === 'NONCUSTODY') {
          return this.createWallet(walletRequestBody, accessToken);
        }
      }),
      catchError(err => {
        if (err.message === ErrorMsgs.POPUP_CLOSED){
          return throwError(new NoShowError("User denied wallet creation", err));
        }
        return throwError(err);
      })
    );
  }
  
  createNewMultisigWalletWith2FA(walletName: string, m, coSigners: string[], type: string, chain: string): Observable<any>{
    return this.auth.writeAccess(scopes.WalletCreate).pipe(
      switchMap((accessToken: string) => {
        if (!accessToken) {
          return throwError("Unathorized access token");
        }
        return this.httpService.addNewMultisigWallet(walletName,m,coSigners, type, chain, accessToken)
      }),
      catchError(err => {
        if (err.message === ErrorMsgs.POPUP_CLOSED){
          return throwError(new NoShowError("User denied wallet creation", err));
        }
        return throwError(err);
      })
    );
  }

  /**
   * Retrieves the teams associated with a given wallet.
   * @param walletId - The ID of the wallet.
   * @returns A Promise that resolves to the teams associated with the wallet.
   */
  getTeamsAssociatedWithWallet(walletId: number): Observable<{ initiatorTeam: TeamsV2, signerTeam: TeamsV2 }> {
    const walletTeamsUrl = `/wallet/ext/${walletId}/teams`
    return this.ApiService.get<any>(walletTeamsUrl).pipe(
      map(response => response.data)
    )
  }

}
