"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isOfflineDirectSigner = void 0;
function isOfflineDirectSigner(signer) {
  return signer.signDirect !== undefined;
}
exports.isOfflineDirectSigner = isOfflineDirectSigner;
