import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions, ApexTooltip, ChartComponent } from 'ng-apexcharts';
import { ConstantService } from '../../services/constant.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any;
  colors: string[];
  plotOptions?: ApexPlotOptions;
  grid: ApexGrid;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  stroke: {
    show: boolean;
  }
};

interface GraphDataItem {
  label: string;
  value: number;
  color: string;
  totalAvailableBalance: number;
}

const componentLabels = {
  exchangeOverview: 'exchange-overview',
  exchange: 'exchange'
}

@Component({
  selector: 'app-custom-graph',
  templateUrl: './custom-graph.component.html',
  styleUrls: ['./custom-graph.component.scss']
})
export class CustomGraphComponent implements OnInit, OnDestroy {

  @ViewChild("customGraphModal") customGraphModal: ElementRef;
  @Input() openGraphModal: any;
  @Input() graphData: any;
  @Input() component: string;
  modalRef: NgbModalRef;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  windowClass = "";
  formattedGraphData: GraphDataItem[] = [];
  noRecordsFound = false;

  constructor(private modalService: NgbModal, private constantService: ConstantService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openGraphModal'] && changes['openGraphModal'].currentValue) {
      if (this.graphData && this.graphData.length > 0) {
        this.noRecordsFound = false;
        const { seriesData, seriesLabels, seriesColors } = this.prepareChartData();
        this.setDialogPosition();
        this.formatGraphData();
        this.openModal();
        this.chartFunc(seriesData, seriesLabels, seriesColors);
      } else {
        this.setDialogPosition();
        this.openModal();
        this.noRecordsFound = true;
      }

    }
  }

  private prepareChartData() {
    let seriesData = [];
    let seriesLabels = [];
    let seriesColors = [];
    let labelFormatter;

    labelFormatter = (item: any) => `${this.safeCapitalize(item.labelNameForGraph)} | ${item.percentageOfTotal ?? 'N/A'} %`;
    seriesData = this.graphData.map((item: any) => this.safeParseNumber(item.percentageOfTotal) ?? 0);
    seriesLabels = this.graphData.map(labelFormatter);
    seriesColors = this.graphData.map((item: any) => item.color);

    return { seriesData, seriesLabels, seriesColors };
  }

  private setDialogPosition() {
    if (this.component === componentLabels.exchangeOverview) {
      this.windowClass = "custom-graph-modal-exchange-overview";
    } else if (this.component === componentLabels.exchange) {
      this.windowClass = "custom-graph-modal-exchange";
    }
  }

  private formatGraphData(): void {
    this.formattedGraphData = this.graphData.map((item: any) => ({
      label: item.labelNameForGraph,
      value: item.percentageOfTotal,
      color: item.color,
      totalAvailableBalance: item.totalAvailableBalanceForGraph
    }));
  }


  private chartFunc(seriesData, seriesLabels, seriesColors) {
    this.chartOptions = {
      dataLabels: { enabled: false },
      series: seriesData,
      chart: {
        animations: { enabled: true },
        type: "donut",
        height: "190px"
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "90%",
            labels: { show: false, value: { show: false } }
          }
        }
      },
      stroke: { show: false },
      legend: {
        show: false,
        width: 5,
        height: 32,
        formatter: (legendName: string, opts?: any) => {
          return `<span style='font-size:25px;font-weight:800'>${legendName}</span>`;
        },
        labels: { colors: [this.constantService.getTheme()['c-body-font']] },
        position: "right"
      },
      colors: seriesColors,
      labels: seriesLabels,
      tooltip: {
        enabled: true,
        y: {
          formatter: () => '',
          title: {
            formatter: (seriesName) => seriesName
          }
        }
      }
    };
  }

  private openModal() {
    this.modalRef = this.modalService
      .open(this.customGraphModal, {
        centered: false,
        backdrop: true,
        keyboard: true,
        scrollable: false,
        size: 'lg',
        windowClass: this.windowClass,
        backdropClass: "exchange-backdrop"
      });
    this.modalRef.result.then(() => { }, () => { });
  }

  private safeParseNumber = (value: string): number | null => {
    const parsedValue = Number(value.replace(/,/g, ''));
    return isNaN(parsedValue) ? null : parsedValue;
  };

  private safeCapitalize = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  ngOnDestroy(): void {
    this.modalRef?.close();
  }
}
