<ng-container *ngIf="step == steps.STEP1">
    <div class="modal-header">
        <h2 class="modal-title">Add Expiry Date</h2>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
               <div class="mb-4">
                <span class="font-size-12 mb-2 d-block font-weight-600 text-dark">Expiry Date</span>
                    <label class="font-size-12 mb-2 d-block text-dark">Choose an expiration date for the API key</label>
                    <div class="d-flex align-items-center pl-0 position-relative custom-datepicker-apikey">
                        <ngb-datepicker #datepicker 
                        [(ngModel)]="keyExpiration" 
                        [minDate]="today"
                        [maxDate]="maxRange"
                        ></ngb-datepicker>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100" 
        [ngClass]="{'disabled' : !keyExpiration || isLoading}" 
        [disabled]="!keyExpiration || isLoading" (click)="rotateKey(keyExpiration)">
            <div *ngIf="isLoading" class="loader-box">
                <div class="loader-15"></div>
            </div>
            <ng-container *ngIf="!isLoading">Rotate API Key</ng-container>
        </button>
    </div>
</ng-container>
<ng-container *ngIf="step == steps.STEP2">
    <div class="modal-header">
        <h2 class="modal-title"><i class="icon-key mr-2"></i>API Key Rotated</h2>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <label>Client ID</label>
                    <div class="d-flex align-items-center pl-0 position-relative">
                        <input disabled class="form-control" type="text" value="{{apiData?.data.client_id}}">
                        <div class="position-absolute copy_Icon">
                            <i class="icon-copy text-blue cursor-pointer" ngxClipboard [cbContent]="apiData?.data.client_id" placement="left" triggers="click:blur" ngbTooltip="Copied"></i>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <label>Client Secret</label>
                    <div class="d-flex align-items-center pl-0 position-relative">
                        <input disabled class="form-control pr-4" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" type="text" value="{{apiData?.data.client_secret}}">
                        <div class="position-absolute copy_Icon">
                            <i class="icon-copy  text-blue cursor-pointer" ngxClipboard [cbContent]="apiData?.data.client_secret" placement="left" triggers="click:blur" ngbTooltip="Copied"></i>
                        </div>
                    </div>
                </div>
                <div class="mb-3 mt-164">
                    <label class="ip-lable">
                        Currently, any IP can access your wallets by using this API key. <span (click)="restrictIpAddress(userDetails)" class="l-color font-weight-500">Click here</span> to specify particular IP addresses for restricted access. You can change this from in API Key list.
                    </label>
                </div>
                <div class="mb-3">
                    <div class="d-flex p-3 info-box-gk">
                        <img src="../../../../assets/images/info.svg"  class="pr-1"/>
                        <span class="d-inline-flex pr-3"> API key can be viewed only once. Please store it securely!</span>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-primary w-100" (click)="downloadAPI()">
            Download client ID and secret
        </button>
    </div>
</ng-container>

<app-ip-address-restriction [openIpAddressRestrictModal]="openIpAddressRestrictModal" [currentIpDetails]="currentIpDetails"></app-ip-address-restriction>
