var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { bip32asBuffer } from "./bip32";
import { MAX_SCRIPT_BLOCK } from "./constants";
export function provideOutputFullChangePath(transport, path) {
  const buffer = bip32asBuffer(path);
  return transport.send(0xe0, 0x4a, 0xff, 0x00, buffer);
}
export function hashOutputFull(transport_1, outputScript_1) {
  return __awaiter(this, arguments, void 0, function* (transport, outputScript, additionals = []) {
    let offset = 0;
    const p1 = Number(0x80);
    const isDecred = additionals.includes("decred");
    ///WARNING: Decred works only with one call (without chunking)
    //TODO: test without this for Decred
    if (isDecred) {
      return transport.send(0xe0, 0x4a, p1, 0x00, outputScript);
    }
    while (offset < outputScript.length) {
      const blockSize = offset + MAX_SCRIPT_BLOCK >= outputScript.length ? outputScript.length - offset : MAX_SCRIPT_BLOCK;
      const p1 = offset + blockSize === outputScript.length ? 0x80 : 0x00;
      const data = outputScript.slice(offset, offset + blockSize);
      yield transport.send(0xe0, 0x4a, p1, 0x00, data);
      offset += blockSize;
    }
  });
}
