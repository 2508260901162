import { StatusENUM } from "./misc";
import { QuorumAppConfig } from "./quorums.model";
import { Wallet } from "./wallet";
import { WalletGroup } from "./walletGroup.model";

/**
 * Represents a firewall policy.
 */
export interface IFirewallPolicy {
  id: number
  createdAt: string
  updatedAt: string
  status: number
  version: string
  createdBy: number
  firewall: Firewall
  ruleType: RuleType
  rules: Rule[]
}

/**
 * Represents a firewall entity.
 */
export interface Firewall {
  id: number
  createdAt: string
  updatedAt: string
  status: string
}

/**
 * Represents a rule type in the firewall.
 */
export interface RuleType {
  id: number
  createdAt: string
  updatedAt: string
  name: string
}

/**
 * Represents a firewall rule.
 */
export interface Rule {
  id: number
  createdAt: string
  updatedAt: string
  description: string
  chain: string
  asset: string
  amount: number
  amountOperator: string
  ruleType: number
  orgId: number
  priority: number
  origin: number
  destination: number
  outcome: string
  direction: string
  country: string
  originType: OriginDestinationType;
  destinationType: OriginDestinationType;
  rulesExpectedValues?: {
    ruleKey: string
    value: string
    operator: string
  }[];
  quorumAppConfig?: QuorumAppConfig;
  originStrategy: OriginDestinationValues;
  destinationStrategy: OriginDestinationValues;

  //Used internally
  teamConfig?: string;
  number?: number;
  status: StatusENUM,
}

/**
 * Represents the origin destination type.
 */
export interface OriginDestinationType {
  id: number
  createdAt: string
  updatedAt: string
  type: OriginDestinationTypeEnum
  status: number
}

/**
 * Represents a rule request data transfer object.
 */
export interface RuleRequestDto {
  description: string
  chain: string
  asset: string
  amount: number
  priority: number
  originType: number
  destinationType: number
  outcome: string
  direction: string
  team: string
  approvalsNeeded: string
  rulesExpectedValues: {
    ruleKey: string
    value: string
    operator: string
  }[]
  ruleType: number
}

/**
 * Represents the direction of a transaction.
 */
export enum TxDirection {
  Send = 'send',
  Receive = 'receive'
}

/**
 * Represents the outcome of a rule in a firewall.
 */
export enum RuleOutcome {
  Approval = "3",
  Accept = "1",
  Reject = "2",
  Cancel = "4",
  Decline = "5"
}


/**
 * Represents the status of a transaction check.
 */
export enum TxCheckStatus {
  Pending = "Pending",
  Allow = "Allow",
  Block = "Block",
  PendingApproval = "Pending Approval",
}

/**
 * Represents the title of a rule type.
 */
export enum RuleTypeTitle {
  TRAVEL_RULE = "Travel Rule",
  TRANSACTION_RISK = "Transaction Risk (AML)",
  TRANSFER_POLICY = "Transfers",
  TRANSACTION_ANOMOLY = "Transaction Anomaly",
  USER_ACCOUNTS = "User Accounts",
  COUNTRY_RISK = "Country Risk",
  LIVENESS = "Liveness",
}

/**
 * Represents the description of different rule types.
 */
export enum RuleTypeDescription {
  TRAVEL_RULE = "Approves or rejects transactions based on Travel Rule compliance, enhancing regulatory transparency.",
  TRANSACTION_RISK = "Automate transaction AML compliance by setting up rules to Allow, Block or Review transactions based on transaction risk score generated by integrated AML providers.",
  TRANSFER_POLICY = "Defines transaction limits within your organization, ensuring safe and controlled operations.",
  TRANSACTION_ANOMOLY = "Flags transactions that deviate from expected patterns, providing a layer of security against unusual activities.",
  USER_ACCOUNTS = "Customizes user-level policies based on behavior and transaction patterns for adaptive security measures.",
  COUNTRY_RISK = "Evaluates transactions for geopolitical risks and compliance with international sanctions and regulations.",
  LIVENESS = "Liveness",
}

/**
 * Represents the type codes for different rules.
 */
export enum RuleTypeCode {
  TRAVEL_RULE = "TRAVEL_RULE",
  TRANSACTION_RISK = "TRANSACTION_RISK",
  TRANSFER_POLICY = "TRANSFER_POLICY",
  LIVENESS = "LIVENESS",
  TRANSACTION_ANOMOLY= "TRANSACTION_ANOMOLY",
  USER_ACCOUNTS = "USER_ACCOUNTS",
}

/**
 * Represents the operators used in firewall rules.
 */
export enum RulesOperators {
  'equals' = "eq",
  'not equals' = "ne",
  'less than' = "lt",
  'less than or equal' = "lte",
  'greater than' = "gt",
  'greater than or equal' = "gte",
  'in' = "in",
  "any" = "any"
}

/**
 * Represents the status of a frozen transaction.
 */
export enum FrozenTxStatus {
  PENDING = 0,
  APPROVE = 1,
  FROZEN = 2,
  UNFROZEN = 3,
}

/**
 * Represents the names of different rule applications.
 */
export enum RuleAppName {
  TRAVEL_RULE = "Notabene",
  TRANSACTION_RISK = "TRM",
  TRANSFER_POLICY = "Transfer Policy",
  LIVENESS = "Liveness",
  COUNTRY_RISK_TYPE = "Country Risk",
}


/**
 * Enum representing the types of origin and destination.
 */
export enum OriginDestinationTypeEnum {
  /**
   * Origin or destination is a single wallet.
   */
  WALLET = "WALLET",

  /**
   * Origin or destination is a group of wallets.
   */
  WALLET_GROUP = "WALLET_GROUP",

  /**
   * Origin or destination is a list of wallets.
   */
  WALLET_LIST = "WALLET_LIST",

  /**
   * Origin or destination is a list of addresses.
   */
  ADDRESS_LIST = "ADDRESS_LIST",

  /**
   * Origin or destination can be any type.
   */
  ANY = "ANY",
}


export interface OriginDestinationValues{
  wallet? : Wallet;
  walletGroup? : WalletGroup;
}