<ng-container *ngIf="isEthBased && display === 'dropdown'">
  <ng-container *ngIf="!hasRule">
    <div ngbDropdownItem style="padding: 0px">
      <button
        class="btn px-4 py-2 body-font"
        [ngClass]="{ disabled: wallet?.isArchived }"
        [disabled]="wallet?.isArchived"
        (click)="mode = 'enable'; modalService.open(gasStationModal)"
      >
        <img
          class="mr-2"
          style="width: 20px"
          src="../../../../assets/images/fuel-tank.svg"
        />
        <li>Enable Autogas Refill</li>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="hasRule">
    <div ngbDropdownItem style="padding: 0px">
      <button
        class="btn px-4 py-2"
        [ngClass]="{ disabled: wallet?.isArchived }"
        [disabled]="wallet?.isArchived"
        (click)="mode = 'update'; modalService.open(gasStationModal)"
      >
        <img
          class="mr-2"
          style="width: 20px"
          src="../../../../assets/images/fuel-tank.svg"
        />
        <li>Update Autogas Refill</li>
      </button>
    </div>
    <div ngbDropdownItem style="padding: 0px">
      <button
        class="btn px-4 py-2"
        [ngClass]="{ disabled: wallet?.isArchived }"
        [disabled]="wallet?.isArchived"
        (click)="mode = 'disable'; modalService.open(gasStationModal)"
      >
        <img
          class="mr-2"
          style="width: 20px"
          src="../../../../assets/images/fuel-tank.svg"
        />
        <li>Disable Autogas Refill</li>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="isEthBased && display === 'icon'">
  <img
    class="mr-2"
    style="width: 20px"
    ngbTooltip="{{ hasRule ? 'Gas Station Refill: Enabled' : 'Gas Station Refill: Disabled'}}"
    src="../../../../assets/images/{{hasRule ? 'fuel-tank-filled' : 'fuel-tank'}}.svg"
    (click)="onIconClicked(gasStationModal)"
  />
</ng-container>
<ng-template #gasStationModal let-modal>
  <div class="modal-container">
    <div class="modal-header">
      <h2 class="modal-title">
        <ng-container>Auto Gas Refill</ng-container>
      </h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.close()"
      >
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="mode !== 'update'">
        <div class="enable-section">
          <img
            src="../../../../assets/images/gastation-icon.png"
            class="gasstation-icon"
          />
          <div>
            <p>
              Transferring tokens on certain networks requires you to have a
              specific type of native asset (eg: ETH,MATIC) to pay for gas or
              transaction fee.
            </p>
            <p>
              Gas Station ensures you don't need a constant native asset
              balance; it auto-adds the necessary amount to your destination
              wallet for seamless transactions.
            </p>
            <p>
              The Gas Station automatically tops up destination addresses with
              the necessary native asset for transaction fees, adhering to
              preset or user-defined rules.
            </p>
            <p>
              <a class="click-here" href="https://docs.lmnl.app/docs/gas-station-guide" target="_blank">Click Here</a> for more information on Auto
              gas refill
            </p>
            <ng-container *ngIf="mode === 'enable'"
              >On continuing you will edit the refill schedule for wallet or
              save the default set values.</ng-container
            >
            <ng-container *ngIf="mode === 'disable'"
              >On continuing you will disable the refill schedule for wallet or
              save the default set values.</ng-container
            >
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode === 'update'">
        <div>
          <label class="text-dark">Gas Station</label>
          <div class="card card-border">
            <div class="card-body align-items-center p-3">
              <div class="media border-after-xs align-items-center">
                <img
                  class="mr-3 img-fluid img-round-border"
                  [src]="
                    rule?.gasStationWalletDetails?.chain | lowercase | getIcon
                  "
                />
                <div class="d-flex flex-column text-left">
                  <span>{{ rule?.gasStationWalletDetails?.name }}</span>
                  <span
                    class="network text-dark"
                    [ngClass]="
                      rule?.gasStationWalletDetails?.chain?.toLowerCase()
                    "
                  >
                    {{ rule?.gasStationWalletDetails?.chain | getName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <label class="text-dark">Destination</label>
          <div class="card card-border">
            <div class="card-body align-items-center p-3">
              <div class="media border-after-xs align-items-center">
                <img
                  class="mr-3 img-fluid img-round-border"
                  [src]="
                    rule?.DestnitionWalletDetails?.chain | lowercase | getIcon
                  "
                />
                <div class="d-flex flex-column text-left">
                  <span>{{ rule?.DestnitionWalletDetails?.name }}</span>
                  <span
                    class="network text-dark"
                    [ngClass]="
                      rule?.DestnitionWalletDetails?.chain?.toLowerCase()
                    "
                  >
                    {{ rule?.DestnitionWalletDetails?.chain | getName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 amt-input">
          <label class="text-dark">If balance in Destination less than</label>
          <input
            autocomplete="off"
            min="0"
            class="form-control text-dark"
            type="number"
            [(ngModel)]="rule.min_amount"
          />
          <span class="coin-legend">{{rule.DestnitionWalletDetails.coin}}</span>
        </div>

        <div class="mt-4 amt-input">
          <label class="text-dark"
            >Transfer from gas station to destination</label
          >
          <input
            autocomplete="off"
            min="0"
            class="form-control text-dark"
            type="number"
            [(ngModel)]="rule.transfer_amount"
            (input)="transactionAmmountValidations(destinationWallet.value)"
            #destinationWallet
          />
          <span class="coin-legend">{{rule.DestnitionWalletDetails.coin}}</span>
          <div class="d-block invalid-feedback" *ngIf="invalidAmmount">Please enter valid amount <ng-container *ngIf="amtMessage">- {{amtMessage}}</ng-container></div>
          <div class="d-block invalid-feedback" *ngIf="allowedDecimals">Only five Decimal places allowed.</div>
        </div>

        <div class="mt-4">
          <label for="amountControlSelect1" class="text-dark"
            >Maximum fees</label
          >
          <select
            [(ngModel)]="rule.ruleType"
            class="form-control text-dark"
            #type
          >
            <option value="auto">Auto</option>
            <option value="custom">Custom</option>
          </select>
        </div>

        <div
          class="input-group mt-4"
          *ngIf="type.value?.toLowerCase() == 'custom'"
        >
          <input
            autocomplete="off"
            min="0"
            class="form-control text-dark border-radius-left"
            placeholder="Max fees"
            type="number"
            (keypress)="omit_special_char($event)"
            [(ngModel)]="rule.max_fees"
          />
          <div class="input-group-append">
            <span
              class="input-group-text font-size-14 border-radius-right text-capitalize"
              >Gwei</span
            >
          </div>
        </div>
      </ng-container>
    </div>

    <div class="modal-footer border-0">
      <button
        type="submit"
        class="btn btn-primary w-100"
        (click)="onClickConfirm()"
        [disabled]="invalidAmmount || allowedDecimals"
      >
        <i class="icon-check mr-1"></i> Continue
      </button>
    </div>
  </div>
</ng-template>
