"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STArray = void 0;
const enums_1 = require("../enums");
const serialized_type_1 = require("./serialized-type");
const st_object_1 = require("./st-object");
const binary_parser_1 = require("../serdes/binary-parser");
const utils_1 = require("@xrplf/isomorphic/utils");
const ARRAY_END_MARKER = Uint8Array.from([0xf1]);
const ARRAY_END_MARKER_NAME = 'ArrayEndMarker';
const OBJECT_END_MARKER = Uint8Array.from([0xe1]);
/**
 * TypeGuard for Array<JsonObject>
 */
function isObjects(args) {
  return Array.isArray(args) && args.every(arg => typeof arg === 'object' && Object.keys(arg).length === 1 && typeof Object.values(arg)[0] === 'object');
}
/**
 * Class for serializing and deserializing Arrays of Objects
 */
class STArray extends serialized_type_1.SerializedType {
  /**
   * Construct an STArray from a BinaryParser
   *
   * @param parser BinaryParser to parse an STArray from
   * @returns An STArray Object
   */
  static fromParser(parser) {
    const bytes = [];
    while (!parser.end()) {
      const field = parser.readField();
      if (field.name === ARRAY_END_MARKER_NAME) {
        break;
      }
      bytes.push(field.header, parser.readFieldValue(field).toBytes(), OBJECT_END_MARKER);
    }
    bytes.push(ARRAY_END_MARKER);
    return new STArray((0, utils_1.concat)(bytes));
  }
  /**
   * Construct an STArray from an Array of JSON Objects
   *
   * @param value STArray or Array of Objects to parse into an STArray
   * @param definitions optional, types and values to use to encode/decode a transaction
   * @returns An STArray object
   */
  static from(value, definitions = enums_1.DEFAULT_DEFINITIONS) {
    if (value instanceof STArray) {
      return value;
    }
    if (isObjects(value)) {
      const bytes = [];
      value.forEach(obj => {
        bytes.push(st_object_1.STObject.from(obj, undefined, definitions).toBytes());
      });
      bytes.push(ARRAY_END_MARKER);
      return new STArray((0, utils_1.concat)(bytes));
    }
    throw new Error('Cannot construct STArray from value given');
  }
  /**
   * Return the JSON representation of this.bytes
   *
   * @param definitions optional, types and values to use to encode/decode a transaction
   * @returns An Array of JSON objects
   */
  toJSON(definitions = enums_1.DEFAULT_DEFINITIONS) {
    const result = [];
    const arrayParser = new binary_parser_1.BinaryParser(this.toString(), definitions);
    while (!arrayParser.end()) {
      const field = arrayParser.readField();
      if (field.name === ARRAY_END_MARKER_NAME) {
        break;
      }
      const outer = {};
      outer[field.name] = st_object_1.STObject.fromParser(arrayParser).toJSON(definitions);
      result.push(outer);
    }
    return result;
  }
}
exports.STArray = STArray;
