import { Component, Input, OnInit } from '@angular/core';
import {
  getUserProfile,
  updateUserDisplayName,
} from '../../shared/helpers/user.utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OrganizationService,
  PendingOrganization,
} from '../../shared/services/organization.service';
import { Router } from '@angular/router';
import { HttpService } from '../../shared/services/http.service';
import {
  ErrorPrompt,
  SuccessPrompt,
} from '../../shared/components/custom-prompt/custom-prompt.service';
import { SuccessPromptPayload } from 'src/app/shared/constants/promptMessages';
import {
  orgUserRequestStatus,
  OrgCategory,
} from '../../shared/entities/User';
import { delay, takeUntil } from 'rxjs/operators';
import { RejectInvitationPopupComponent } from './reject-invitation-popup/reject-invitation-popup.component';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/shared/services/data.service';
import { Subject } from 'rxjs';
import { AuthServiceJWT } from "../../shared/services/auth.service";

@Component({
  selector: 'app-choose-organization',
  templateUrl: './choose-organization.component.html',
  styleUrls: ['./choose-organization.component.scss'],
})
export class ChooseOrganizationComponent implements OnInit {
  @Input() component_for: string = 'choose-organization';
  myOrgList: PendingOrganization[] = [];
  invitedOrgList: PendingOrganization[] = [];
  userInfo;
  isOrgModal: boolean = false;
  loading: boolean = false;
  currentOrg: PendingOrganization;
  selectedOrg: PendingOrganization;
  OrgCategory = OrgCategory;
  isWhitelabel: boolean = false;
  onDestroy$: Subject<void> = new Subject();

  constructor(
    private orgSvc: OrganizationService,
    private modalService: NgbModal,
    private router: Router,
    private httpService: HttpService,
    private dataService: DataService,
    private authService: AuthServiceJWT,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.isOrgModal = this.component_for === 'switch-org-modal';
    this.currentOrg = this.orgSvc.getCurrentOrg();
    this.orgSvc.fetchOrgList().subscribe((orgList) => {
      if (orgList.length === 1 && !this.isOrgModal && !orgList[0].inviteId) {
        this.orgSvc.setCurrentOrganization(orgList[0]);
        this.authService.triggerLoginSuccessEvent();
        this.router.navigateByUrl('/');
      } else {
        this.loading = false;
      }
    });
    this.selectedOrg = this.currentOrg;
    this.isWhitelabel = environment.buildType !== 'vaults';
    this.getUser();
    this.fetchOrgList();
  }

  getUser() {
    this.userInfo = getUserProfile() || null;
  }

  fetchOrgList() {
    this.loading = true;
    this.orgSvc.getUserOrgList$
      .pipe(delay(500))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (orgs: PendingOrganization[]) => {
          if (orgs && orgs.length) {
            this.myOrgList = [...orgs];
          }
        },
        (err) => {
          this.myOrgList = [];
          this.loading = false;
        }
      );
  }

  ngOndestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSelectOrg(selectedOrg?: PendingOrganization) {
    this.selectedOrg = selectedOrg;
    if (!this.isOrgModal) {
      this.onSwitchOrg();
    }
  }

  onSwitchOrg() {
    if (this.selectedOrg) {
      const currentPath =
        this.router.url == '/choose-organization' ? '/' : this.router.url;
      this.orgSvc.setCurrentOrganization(this.selectedOrg);

      // Trigger required events
      this.authService.triggerLoginSuccessEvent();

      this.dataService.loadData();
      window.location.reload();
      window.location.href = currentPath;
    }
  }

  acceptOrRejectInvite(org: PendingOrganization, isAccept: boolean) {
    const status = isAccept
      ? orgUserRequestStatus.ACTIVE
      : orgUserRequestStatus.DECLINED;
    if (!org?.inviteId) return;
    this.loading = true;
    const resObserable = isAccept
      ? this.httpService.acceptDeclineOrgWith2FA(org?.inviteId, status)
      : this.httpService.acceptDeclineOrg(org?.inviteId, status);
    resObserable.subscribe(
      async (res) => {
        this.loading = false;
        if (res && res?.success) {
          await this.orgSvc.fetchOrgList().toPromise();
          if (isAccept) {
            SuccessPrompt.fire(SuccessPromptPayload.ACCEPT_ORG_INVITE);
            // Update user display name
            if (res?.data) updateUserDisplayName(res.data?.displayName);
          } else {
            SuccessPrompt.fire(SuccessPromptPayload.REJECT_ORG_INVITE);
          }
        }
      },
      (err) => {
        this.loading = false;
        ErrorPrompt.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            err?.error?.message ||
            err?.message ||
            'Failed to accept/reject invitation. Please try again later.',
        });
      }
    );
  }

  rejectInviteConfirm(org: PendingOrganization) {
    const modalRef = this.modalService.open(RejectInvitationPopupComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "invitation-custom-modal",
    });
    modalRef.componentInstance.component_for = 'reject-invitation-modal';
    modalRef.result.then((res) => {
      if (res && res?.result === true) {
        this.acceptOrRejectInvite(org, false);
      }
    });
  }

  close() {
    this.modalService.dismissAll();
  }
}
