import { Pipe, PipeTransform } from '@angular/core';
// import { isError } from 'util';
import { tokenType } from '../../entities/TransactionRequest';
@Pipe({
  name: 'policyTypeFilter'
})
export class PolicyTypeFilterPipe implements PipeTransform {
  transform(_listPolictData: Array<any>, filterPolicyType: string): Array<any> {
    if (filterPolicyType === 'All') {
      return _listPolictData;
    }
    else {
      return _listPolictData.filter(item => item.subtype.toLowerCase() === filterPolicyType);
    }
  }
}
@Pipe({
  name: 'walletTypeFilter'
})
export class WalletTypeFilterPipe implements PipeTransform {
  transform(_listWalletData: Array<any>, filterWalletType: any): Array<any> {
    if (filterWalletType.filterWalletType === 'All') {
        return _listWalletData.filter(item => item.isArchived !== true)
    }
    else if (filterWalletType.filterWalletType === 'archive') {
      return _listWalletData.filter(item => item.isArchived === true)
    }
    else {
      _listWalletData = _listWalletData.filter(item => item.isArchived !== true)
      return _listWalletData.filter(item => item.tags.indexOf(filterWalletType.filterWalletType) !== -1);
    }
  }
}
@Pipe({
  name: 'balanceSort'
})
export class WalletBalanceSortPipe implements PipeTransform {
  transform(_listWalletData: Array<any>, sortType: boolean, key: string): Array<any> {
    if (sortType) {
      return [..._listWalletData].sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
    } else {
      return [..._listWalletData].sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
    }
  }
}

@Pipe({
  name: 'ExchangeFilter'
})
export class ExchangeFilterPipe implements PipeTransform {
  transform(exchangeList: Array<any>, filterValue: any): Array<any> {
    if (filterValue === 'active') {
      return exchangeList.filter(row => row.status === filterValue);
    } else {
      return exchangeList;
    }
  }
}

@Pipe({
  name: 'userTableSort'
})
export class UserTableSortPipe implements PipeTransform {
  transform(array: any[], field: string, order: string = 'asc'): any[] {
    if (!array || !field) {
      return array;
    }
    /**
     * @description When isOwner is 1 , the userType in resp is "admin" instead of "owner"
     */
    if (field === '_userType') {
      array = array.map(el => {
        if (el.isOwner === 1) {
          el._userType = "owner";
        }
        return el;
      })
    };

    const isAsc = order === 'asc';

    array.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];
      if(valueA && valueB){
        return isAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    });
    return array;
  }
}
@Pipe({
  name: 'actionsFilter'
})
export class actionsFilterPipe implements PipeTransform {
  meFilter(filterData)
  {
    let user = JSON.parse(localStorage?.getItem("user"));
    let data = [];
        let data1 = [];
        let data2 = [];
        filterData.forEach(item => {
          if((item._metaData?.tx_sub_category_type.toLowerCase() == 'send' || item._metaData?.tx_sub_category_type.toLowerCase() == 'multicall' || item._metaData?.tx_category_type.toLowerCase() == 'staking' || item.tokenType == tokenType.nftToken.toString()) && (item.walletinfor?.access == 'SIGNER' || item.walletinfor?.access == 'INITIATOR') && item.status == 1 && item.signs.filter(e=>e.email == user.email && e.approved == false).length) data.push(item);
          if(item.msgToSign && !item.txn_req_id && (item.walletinfor?.access == 'SIGNER' || item.walletinfor?.access == 'INITIATOR') && item.status == 1 && item.signers?.filter(e=>e.email == user.email && e.approved == false).length) data1.push(item);
          if(item._txType == 'policy_tx' && item.status == 2 && item.signers.filter(e=>e.email == user.email && e.approved == false).length) data2.push(item);
        });
        let array = [...data, ...data1, ...data2];
        array.sort((a, b) => {
          return (b.timestamp || b.createdAt || b.created_at).localeCompare((a.timestamp || a.createdAt || a.created_at))
        })
        return array;
  }
  transform(_listWalletData: Array<any>, filterWalletType: string, meStatus:boolean): Array<any> {
    if (filterWalletType.toLowerCase() === 'all') {
      return _listWalletData;
    }
    if(!meStatus) {
      filterWalletType = filterWalletType.toLowerCase();
      if (filterWalletType == 'transactions') {
        return _listWalletData.filter(item => (item._metaData?.tx_sub_category_type.toLowerCase() == 'send' || item._metaData?.tx_sub_category_type.toLowerCase() == 'multicall') && item.tokenType != tokenType.nftToken.toString());
      } else if (filterWalletType == 'mobile') {
        return _listWalletData.filter(item => item.msgToSign && !item._mpcTxType?.tx_category_type);
      } else if (filterWalletType == 'policies') {
        return _listWalletData.filter(item => item._txType == 'policy_tx');
      } else if (filterWalletType == 'staking') {
        return _listWalletData.filter(item => item._metaData?.tx_category_type.toLowerCase() == 'staking' || item._mpcTxType?.tx_category_type?.toLowerCase() == 'staking');
      } else if (filterWalletType == 'me') {
        return this.meFilter(_listWalletData);
      } else if (filterWalletType == 'nft'){
        return _listWalletData.filter(item => item.tokenType == tokenType.nftToken.toString());
      } else if (filterWalletType == 'travel rule'){
        return _listWalletData.filter(item => item.actionType == 'travel-rule');
      } else if (filterWalletType == 'wallet request'){
        return _listWalletData.filter(item => item.type == 'walletReq');
      }
    }
    if(meStatus) {
      let user = JSON.parse(localStorage.getItem("user"));
      filterWalletType = filterWalletType.toLowerCase();
      if (filterWalletType == 'transactions') {
        return _listWalletData.filter(item => (item._metaData?.tx_sub_category_type.toLowerCase() == 'send' || item._metaData?.tx_sub_category_type.toLowerCase() == 'multicall') && item.tokenType != tokenType.nftToken.toString() && (item.walletinfor?.access == 'SIGNER' || item.walletinfor?.access == 'INITIATOR') && item.status == 1 && item.signs.filter(e=>e.email == user.email && e.approved == false).length);
      } else if (filterWalletType == 'mobile') {
        return _listWalletData.filter(item => item.msgToSign && !item._mpcTxType?.tx_category_type && !item.txn_req_id && (item.walletinfor?.access == 'SIGNER' || item.walletinfor?.access == 'INITIATOR') && item.status == 1 && item.signers.filter(e=>e.email == user.email && e.approved == false).length);
      } else if (filterWalletType == 'policies') {
        return _listWalletData.filter(item => item._txType == 'policy_tx' && item.status == 2 && item.signers.filter(e=>e.email == user.email && e.approved == false).length);
      } else if (filterWalletType == 'staking') {
  return _listWalletData.filter(item => (item._metaData?.tx_category_type.toLowerCase() == 'staking' || item._mpcTxType?.tx_category_type?.toLowerCase() == 'staking') && (item.walletinfor?.access == 'SIGNER' || item.walletinfor?.access == 'INITIATOR')  && (item.signs?.filter(e=>e.email == user.email && e.approved == false).length && item.signers?.filter(e=>e.email == user.email && e.approved == false).length));
      } else if (filterWalletType == 'me') {
        return this.meFilter(_listWalletData);
      } else if (filterWalletType == 'nft'){
        return _listWalletData.filter(item => item.tokenType == tokenType.nftToken.toString() && (item.walletinfor?.access == 'SIGNER' || item.walletinfor?.access == 'INITIATOR') && item.status == 1 && item.signs.filter(e=>e.email == user.email && e.approved == false).length);
      } else if (filterWalletType == 'travel rule'){
        return _listWalletData.filter(item => item.actionType == 'travel-rule' && item.signers.filter(e=>e.email == user.email).length);
      } else if (filterWalletType == 'wallet request'){
        return [];
      }
    }
  }
}
@Pipe({
  name: 'refillFilters'
})
export class refillFilterPipe implements PipeTransform {
  transform(_refillData: Array<any>, filterRefillType: string): Array<any> {
    if (filterRefillType === 'all') {
      return _refillData;
    } else if (filterRefillType === 'low-refill') {
      return _refillData.filter(item => item.scheduledBatch.remainingTxs <= 1);
    } else if (filterRefillType === 'low-balance') {
      return _refillData.filter(item => item.scheduledBatch.currentbalance <= item.scheduledBatch.balancetrigger);
    }
  }
}
