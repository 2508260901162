"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isBrowser = exports.isNode = exports.isReactNative = void 0;
function isReactNative() {
  return typeof document === 'undefined' && typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
}
exports.isReactNative = isReactNative;
function isNode() {
  return typeof process !== 'undefined' && typeof process.versions !== 'undefined' && typeof process.versions.node !== 'undefined';
}
exports.isNode = isNode;
function isBrowser() {
  return !isReactNative() && !isNode();
}
exports.isBrowser = isBrowser;
