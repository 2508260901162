import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CUSTODY_TYPE } from '../../entities/members';
import { AuthServiceJWT } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { MembersService } from '../../services/members.service';
import { TeamsService } from '../../services/teams.service';
import { FilterType, PaginationMeta, TeamSigningSubType, TeamSigningType, TeamsListResponse, TeamsV2 } from '../../entities';
import { Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import { AddTeamV2Component } from '../add-team-v2/add-team-v2.component';
import { EditTeamV2Component } from '../edit-team-v2/edit-team-v2.component';
import { UserModeEnum } from '../../entities/User';

@Component({
  selector: 'app-add-team-list-v2',
  templateUrl: './add-team-list-v2.component.html',
  styleUrls: ['./add-team-list-v2.component.scss']
})
export class AddTeamListV2Component implements OnInit {
  pageTitle = `${environment.buildName} Vaults - Teams`;

  getmem = []
  members: any;
  _members: any;

  loading: boolean = true
  isContentLoading: boolean = false;
  showNoResultsFound: boolean = false

  total_members_limit = false;
  user: any;
  createWalletOption = false
  selected_Asset = false;
  shield: boolean = true;
  fixed_shield = true;
  focusTot = false

  searchTerm: string = '';
  searchTermChanged$ = new Subject<string>();
  filterTeamType = FilterType.ALL;

  teamsListData: TeamsV2[]
  paginationMetaData: PaginationMeta = {}
  orgProfile: any = {};
  custodyType = CUSTODY_TYPE
  isUserOwner: boolean = false;

  PAGE_SIZE = 5;

  FilterType = FilterType;

  constructor(private memberservice: MembersService, public authService: AuthServiceJWT, private httpService: HttpService,
    private modalService: NgbModal, private titleService: Title, public teamsService: TeamsService) {

    this.titleService.setTitle(this.pageTitle);

    this.user = JSON.parse(localStorage.getItem("user"));

    this.getTeamsList()

    this.getProfile();
  }

  onPageChange(pageNumber: number) {
    this.isContentLoading = true;
    this.getTeamsList(pageNumber, this.getFilterType(this.filterTeamType), this.searchTerm);
  }

  getTeamsList(pageNumber?: number, type?: TeamSigningType, searchString?: string) {
    this.showNoResultsFound = false;

    this.teamsService.getTeamsByPage(pageNumber ?? 1, this.PAGE_SIZE, type, searchString)
      .subscribe(({ teamListData, paginationMeta }: TeamsListResponse) => {
        this.teamsListData = teamListData;
        this.paginationMetaData = paginationMeta
        this.loading = false
        this.isContentLoading = false

        // Update the showNoResultsFound flag based on the filtered results
        this.showNoResultsFound = (this.teamsListData.length === 0);
      })

  }

  ngOnInit(): void {
    this.getmem = [];
    this.memberservice.resetMember();

    this.searchTermChanged$
      .pipe(debounceTime(600), distinctUntilChanged(), delay(300),)
      .subscribe((search) => {
        this.isContentLoading = true;
        this.getTeamsList(1, this.getFilterType(this.filterTeamType), search);
      });
  }

  searchTermChanged(newTerm: string): void {
    this.searchTermChanged$.next(newTerm);
  }


  addTeam(addTeamModal: any) {
    this.getmem = [];
    this.memberservice.resetMember();

    this.modalService.open(addTeamModal, { windowClass: 'add-team modal-custom-background', centered: true })
  }

  get getMembers() {
    return this.members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  resetAndrefreshData() {
    this.filterTeamType = FilterType.ALL;
    this.searchTerm = '';

    this.getTeamsList();
  }

  search(teamSearch): void {
    this.searchTerm = teamSearch;
  }

  teamTypeFilter(type) {
    this.searchTerm = '';
    this.filterTeamType = type;

    this.isContentLoading = true;

    if (type !== FilterType.ALL) {
      type = type === FilterType.SIGNED ? TeamSigningType.SIGNING : TeamSigningType.NON_SIGNING;
      this.getTeamsList(1, type)
    }
    else this.getTeamsList();
  }


  async getProfile() {
    let profile = await this.httpService.getprofile().toPromise();
    this.orgProfile = profile['organizations'][0];
    this.isUserOwner = this.orgProfile?.isowner === 1;
  }

  getFilterType(type: FilterType) {
    switch (type) {
      case FilterType.SIGNED:
        return TeamSigningType.SIGNING;
      case FilterType.NON_SIGNED:
        return TeamSigningType.NON_SIGNING;
      default:
        return undefined;
    }
  }


  getSigningTypeLabel(type: TeamSigningType): string {
    switch (type) {
      case TeamSigningType.NON_SIGNING:
        return 'Non Signing';
      case TeamSigningType.SIGNING:
        return 'Signing';
      default:
        return 'Unknown';
    }
  }

  getSigningSubTypeLabel(subType: TeamSigningSubType): string {
    switch (subType) {
      case TeamSigningSubType.MPC:
        return 'Mobile';
      case TeamSigningSubType.MULTISIG:
        return 'Hardware Wallet';
      default:
        return 'Unknown';
    }
  }

  openAddTeamModal() {
    this.modalService.dismissAll();
    const addTeamModalRef = this.modalService
      .open(AddTeamV2Component, {
        windowClass: "add-team modal-custom-background",
        centered: true,
      })
    addTeamModalRef.componentInstance.orgProfile = this.orgProfile;

    addTeamModalRef.componentInstance.teamCreated.subscribe((isTeamCreated) => {
      // Refresh team list data
      if(isTeamCreated) this.resetAndrefreshData();
    });
  }


  openEditTeamModal(team: TeamsV2) {
    this.modalService.dismissAll();
    const editTeamModalRef = this.modalService
      .open(EditTeamV2Component, {
        windowClass: "add-team modal-custom-background",
        centered: true,
    })
    editTeamModalRef.componentInstance.orgProfile = this.orgProfile;
    editTeamModalRef.componentInstance.team = team;
    editTeamModalRef.componentInstance.teamEdited.subscribe((isteamEdited) => {
      // Refresh team list data
      if(isteamEdited) this.resetAndrefreshData();
    });
  }

  isMachineUserTeam(team: TeamsV2) {
    return team.teamMembers.some((u) => u.user.type === UserModeEnum.MACHINE);
  }

}
