import { Output, PreBuildTx, SignedTransaction, TransactionRequest } from '../../../entities';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import { environment } from '../../../../../environments/environment';
// import Swal from 'sweetalert2';
import Str from "@ledgerhq/hw-app-str";
import * as StellarSdk from "@stellar/stellar-sdk";
import { LedgerService } from '../../ledger/ledger';
import { Guid } from "guid-typescript";
import { ErrorPrompt } from 'src/app/shared/components/custom-prompt/custom-prompt.service';

export async function signXLMTransaction(output: Output, req: TransactionRequest, type: string) {
    function buf2hex(buffer) {
        return [...new Uint8Array(buffer)]
            .map(x => x.toString(16).padStart(2, '0'))
            .join('');
    };
    try {
        let transport = await TransportWebHID.create();
        let txParam: any = {}
        var txJSON;
        var txid;
        let CoinName = "Stellar";
        let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
        if (req && 'raw' in req) {
            txid = req.id;
            var raw_tx = req.raw;
            const str = new Str(transport);
            var transaction = StellarSdk.TransactionBuilder.fromXDR(raw_tx, environment.xlm_config.XLM.network == "testnet" ? `Test SDF Network ; September 2015` : `Public Global Stellar Network ; September 2015`);
            let Addressresult = await str.getPublicKey("44'/148'/0'");
            let pubkey = Addressresult.publicKey;
            if (objwalletkey[0].xpub != pubkey) {
                return [{ "error": "Wrong Ledger Device PubKey" }];
            }
            const result = await str.signTransaction(environment.xlm_config.XLM.path || "44'/148'/0'", transaction.signatureBase());
            const signbuf2hex = await buf2hex(result.signature)
            // const hex2buff = await fromHexString(signbuf2hex)
            const keyPair = StellarSdk.Keypair.fromPublicKey(objwalletkey[0].xpub);
            const hint = keyPair.signatureHint();
            const decorated1 = new StellarSdk.xdr.DecoratedSignature({ hint: hint, signature: result.signature });
            transaction.signatures.push(decorated1);
            let signaddedtx = transaction
                .toEnvelope()
                .toXDR()
                .toString('base64');
            await transport.close();
            var data =
            {
                "sig": signbuf2hex,
                "base64": signaddedtx,
                "pubkey": objwalletkey[0].xpub
            }

            var respApprove = await this.httpService.ApproveTx(JSON.stringify(data), this.wallet.id, txid);
            if (respApprove.status !== 200) {
                return [{ "error": respApprove }]
            }
            return "success";
        }
        else {
            var respData: PreBuildTx = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            });

            var raw_tx = respData.txJson.raw_data_hex;
            let currentapp = await LedgerService.getCurrentApp(transport);
            if (currentapp.name != CoinName) {
                return [{ "error": "please Open Stellar App on Ledger and Try Again" }]
            }
            const str = new Str(transport);
            let tx_data = StellarSdk.TransactionBuilder.fromXDR(raw_tx, environment.xlm_config.XLM.network == "testnet" ? `Test SDF Network ; September 2015` : `Public Global Stellar Network ; September 2015`);
            let Addressresult = await str.getPublicKey("44'/148'/0'");
            let pubkey = Addressresult.publicKey;
            if (objwalletkey[0].xpub != pubkey) {
                return [{ "error": "Wrong Ledger Device PubKey" }];
            }
            const result = await str.signTransaction(environment.xlm_config.XLM.path || "44'/148'/0'", tx_data.signatureBase());
            const signbuf2hex = await buf2hex(result.signature)
            // const hex2buff = await fromHexString(signbuf2hex)
            const keyPair1 = StellarSdk.Keypair.fromPublicKey(objwalletkey[0].xpub);
            const hint1 = keyPair1.signatureHint();
            const decorated1 = new StellarSdk.xdr.DecoratedSignature({ hint: hint1, signature: result.signature });
            tx_data.signatures.push(decorated1);
            let signaddedtx = tx_data
                .toEnvelope()
                .toXDR()
                .toString('base64');

            let finalTx =
            {
                raw_data_hex: signaddedtx,
                signature: [signbuf2hex],
                pubkey: objwalletkey[0].xpub
            }

            let tx = {
                destinationAddress: respData.txOutputs.destinationAddress,
                data: (finalTx),
                value: respData?.txOutputs.destinationAmount.toString(),
                amount: respData?.txOutputs.destinationAmount.toString()
            }

            let sequenceId = Guid.create().toString();
            if (req) {
                if ('sequenceId' in req) {
                  sequenceId = req['sequenceId'] as string;
                }
            }

            const signTransactionResponse: SignedTransaction = {
                sequenceId: sequenceId,
                halfSigned: {
                    payload: JSON.stringify(tx),
                    txHex: signaddedtx
                },
                memo: output.data,
                comment: output.comment
            };
            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            console.info(sendresult);
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        }
    }
    catch (err) {

        if (err.message == "Ledger device: UNKNOWN_ERROR (0x6a8d)") {
            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: "Allow unverified contracts from Ledger app setting.",
                showConfirmButton: false,
                // timer: 1500
            })
        }
        else if (err.name == "TransportOpenUserCancelled") {
            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: "It seems you have not connected your ledger device or please recheck the connection and try again.",
                showConfirmButton: false,
                // timer: 1500
            })
        } else {
            if (err.response.data.message) {
                ErrorPrompt.fire({
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: this.walletService.setCustomErrorMsg(err.response.data.message),
                    showConfirmButton: false,
                })
            } else {
                ErrorPrompt.fire({
                    // position: 'top-end',
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: this.walletService.setCustomErrorMsg(err.message),
                    showConfirmButton: false,
                    // timer: 1500
                })
            }
          
        }
        this.segment.track("send-asset-transaction-sign-failed", {user: this.authService.getUser, error: err})
        .then(() => console.log("Send asset transaction sign failed")).catch((err)=>{});
    }
}