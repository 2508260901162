import { User } from "./User";

/**
 * @description Teams signing type enum
 * @enum {number}
 * NON_SIGNING = 1,
 * SIGNING = 2
 */
export enum TeamSigningType {
    NON_SIGNING = 1,
    SIGNING = 2,
}

/**
 * @description Teams signing sub type enum
 * @enum {number}
 * MPC = 1,
 * MULTISIG = 2
 */
export enum TeamSigningSubType {
    MPC = 1,
    MULTISIG = 2,
}

export interface TeamMember {
    id: number;
    teamId: number;
    userId: number;
    user: User
}

export interface TeamsV2 {
    id: number;
    name: string;
    type: TeamSigningType;
    subType?: TeamSigningSubType;
    orgId: number;
    teamMembers?: TeamMember[];
    walletCount?: number;
}

export interface PaginationMeta {
    totalCount?: number;
    currentPage?: number;
    totalPages?: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    pageSize?: number;
}

export interface TeamsListResponse {
    teamListData : TeamsV2[];
    paginationMeta : PaginationMeta
}

export interface ComplianceTeamData {
    quorumAppConfigId: number;
    quorumId: number;
    approvalsNeeded: number;
    teamId: number;
    status: number;
    position: number;
  }

export class TeamsV2Request {
    constructor(public name: string, public type: TeamSigningType,
        public members: number[], public subType?: TeamSigningSubType) { }
}

export interface EditTeamRequest {
    teamId: number;
    memberIds: number[];
}

export interface UpdateComplianceTeamRequest {
    teamId: number;
    approvalsNeeded: number;
}

export enum FilterType {
    ALL = "All", SIGNED="Signed", NON_SIGNED="Non signed"
}

export enum TeamActionType {
    SIGNING = "Signing",
    APPROVAL = "Final Approval"
}

export enum ActionDescription {
    INITIATION = "Initiator team is the team whose members can start a  transaction from this wallet.",
    SIGNING = "Signer team is the team whose members can sign outgoing transactions from this wallet.",
    APPROVAL = "Approver team is the team whose members can approve outgoing transactions from this wallet.",
    COMPLIANCE = "Minimum number of approvals required to approve compliance activities."
}

export enum WalletUser {
    INITIATOR = "Initiator",
    APPROVER = "Approver",
    SIGNER = "Signer"
}
