var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import axios from "axios";
import { log } from "@ledgerhq/logs";
import { getRegistries } from "../registries";
import { validateDomain } from "../utils";
/**
 * Get an APDU to sign a domain resolution on the nano
 *
 * @param {string} domain
 * @param {SupportedRegistries} registryName
 * @param {string} challenge
 * @returns {Promise<AddressResolutionResponse[]>}
 */
export const signDomainResolution = (domain, registryName, challenge) => __awaiter(void 0, void 0, void 0, function* () {
  if (!validateDomain(domain)) {
    throw new Error(`Domains with more than 255 caracters or with unicode are not supported on the nano. Domain: ${domain}`);
  }
  const registries = yield getRegistries();
  const registry = registries.find(r => r.name === registryName);
  if (!registry) return null;
  const url = registry.signatures.forward.replace("{name}", domain).replace("{challenge}", challenge);
  return axios.request({
    method: "GET",
    url
  }).then(({
    data
  }) => data.payload).catch(error => {
    /* istanbul ignore next: don't test logs */
    if (error.status !== 404) {
      log("domain-service", "failed to get APDU for a domain", {
        domain,
        error
      });
    }
    return null;
  });
});
/**
 * Get an APDU to sign an address resolve resolution on the nano
 *
 * @param {string} address
 * @param {SupportedRegistries} registryName
 * @param {string} challenge
 * @returns {Promise<AddressResolutionResponse[]>}
 */
export const signAddressResolution = (address, registryName, challenge) => __awaiter(void 0, void 0, void 0, function* () {
  const registries = yield getRegistries();
  const registry = registries.find(r => r.name === registryName);
  if (!registry) return null;
  const url = registry.signatures.reverse.replace("{address}", address).replace("{challenge}", challenge);
  return axios.request({
    method: "GET",
    url
  }).then(({
    data
  }) => data.payload).catch(error => {
    /* istanbul ignore next: don't test logs */
    if (error.status !== 404) {
      log("domain-service", "failed to get APDU for an address", {
        address,
        error
      });
    }
    return null;
  });
});
