import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportsHttpService } from "../../services/reports-http.service";
import {
  getName,
  getSupportedCoins,
  subName,
} from "../../../shared/helpers/coins";
import { BlockDetails } from "../../.././shared/entities/walletRequest";
import {
  ErrorPrompt,
  SuccessPrompt,
} from "../custom-prompt/custom-prompt.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../services/data.service";

@Component({
  selector: "app-missed-transaction",
  templateUrl: "./missed-transaction.component.html",
  styleUrls: ["./missed-transaction.component.scss"],
})
export class MissedTransactionComponent implements OnInit {
  selected_Asset: any = false;
  coinList = [];
  search_coin: string = "";
  selected_asset_value: any;
  rescanTypeList: Array<{ value: string; label: string }> = [
    { value: "blockRange", label: "Block Range" },
    { value: "transactionHash", label: "Transaction Hash" },
  ];
  rescanType: any = {};
  coinData: any;
  txHash: string;
  fromBlock: number;
  toBlock: number;
  blocksRange: number;
  rescanForm: FormGroup;
  ifinputsValid: boolean = false;

  constructor(
    private modalService: NgbModal,
    private reportsHttpService: ReportsHttpService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
   this.loadCoins();
  }

  open(content) {
    this.clearAllselectedFields();
    this.modalService.open(content, {
      windowClass: "modal-custom-background",
      centered: true,
    });
  }

  loadCoins() {
     this.dataService.coinList.subscribe((res) => {
     this.coinList = res;
    });
  }
  changeAsset() {
    this.clearAllselectedFields();
    this.selected_Asset = false;
    this.selected_Asset = "";
  }
  selectAsset(asset: string) {
    this.clearAllselectedFields();
    this.selected_Asset = asset;
    this.selected_asset_value = asset;
    this.reportsHttpService.getCoinDetails(asset).subscribe((res: any) => {
      this.coinData = res.data;
    });
  }

  getName(id) {
    return getName(id);
  }

  getSubName(id) {
    return subName(id);
  }

  getSupportedCoins(key) {
    return getSupportedCoins(key);
  }

  selectRescanType(event) {
    this.toBlock = this.fromBlock = null;
    this.txHash = "";
    this.ifinputsValid = false ;
    this.rescanType = event;
  }
  sendRescanRequest() {
    let body: BlockDetails = {
      blockdetails: {
        coin: this.selected_Asset,
      },
    };

    if (this.rescanType?.value == "blockRange") {
      body.blockdetails.blocknumberRange = {
        from: this.fromBlock,
        to: this.toBlock,
      };
    } else {
      body.blockdetails.transactionHash = this.txHash;
    }
    this.reportsHttpService.sendRescanRequest(body).subscribe(
      (res: any) => {
        SuccessPrompt.fire({
          title: "Your request to rescan transaction(s) has been received",
          text: "We are working on this, your transaction records will be updated within a few mins.",
        });
      },
      (error) => {
        ErrorPrompt.fire({
          title: "Error",
          text: error.error.message,
        });
      }
    );
    this.modalService.dismissAll();
  }

  clearAllselectedFields() {
    this.selected_Asset = false;
    this.rescanType = "";
    this.txHash = "";
    this.fromBlock = null;
    this.toBlock = undefined;
    this.coinData = {};
    this.ifinputsValid = false;
  }

  validateUserInput(): boolean {
    this.fromBlock = this.fromBlock ? Number(this.fromBlock.toString().replace(/[^0-9]/g, '')) : null;
    this.toBlock = this.toBlock ? Number(this.toBlock.toString().replace(/[^0-9]/g, '')) : null;
    this.blocksRange = Math.abs(this.toBlock - this.fromBlock)

    if (this.rescanType.value == "blockRange") {
      if (
        this.fromBlock == null ||
        this.toBlock == null ||
        this.fromBlock > this.coinData?.latest_sync_blocknumber ||
        this.toBlock > this.coinData?.latest_sync_blocknumber ||
        (this.blocksRange > 10)
      ) {
        this.ifinputsValid = false;
      } else {
        this.ifinputsValid = true;
      }
    } else if (this.rescanType.value == "transactionHash") {
      if (this.txHash == "") {
        this.ifinputsValid = false;
      } else {
        this.ifinputsValid = true;
      }
    }
    return this.ifinputsValid;
  }
}
