import { Component, Input, OnInit } from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
  selector: 'app-copy-address',
  templateUrl: './copy-address.component.html',
  styleUrls: ['./copy-address.component.scss']
})
export class CopyAddressComponent implements OnInit {
  @Input() address: string;
  constructor(private clipboard: Clipboard) { }

  ngOnInit(): void {
  }
  copy_Address(address_Value,copy)
  {
  this.clipboard.copy(address_Value);
  setTimeout(()=>{
    copy.close();
  }, 1000);
  }
}
