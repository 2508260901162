var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { pathElementsToBuffer } from "../bip32";
import { MerkelizedPsbt } from "./merkelizedPsbt";
import { ClientCommandInterpreter } from "./clientCommands";
import { createVarint } from "../varint";
import { hashLeaf, Merkle } from "./merkle";
const CLA_BTC = 0xe1;
const CLA_FRAMEWORK = 0xf8;
var BitcoinIns;
(function (BitcoinIns) {
  BitcoinIns[BitcoinIns["GET_PUBKEY"] = 0] = "GET_PUBKEY";
  // GET_ADDRESS = 0x01, // Removed from app
  BitcoinIns[BitcoinIns["REGISTER_WALLET"] = 2] = "REGISTER_WALLET";
  BitcoinIns[BitcoinIns["GET_WALLET_ADDRESS"] = 3] = "GET_WALLET_ADDRESS";
  BitcoinIns[BitcoinIns["SIGN_PSBT"] = 4] = "SIGN_PSBT";
  BitcoinIns[BitcoinIns["GET_MASTER_FINGERPRINT"] = 5] = "GET_MASTER_FINGERPRINT";
  BitcoinIns[BitcoinIns["SIGN_MESSAGE"] = 16] = "SIGN_MESSAGE";
})(BitcoinIns || (BitcoinIns = {}));
var FrameworkIns;
(function (FrameworkIns) {
  FrameworkIns[FrameworkIns["CONTINUE_INTERRUPTED"] = 1] = "CONTINUE_INTERRUPTED";
})(FrameworkIns || (FrameworkIns = {}));
/**
 * This class encapsulates the APDU protocol documented at
 * https://github.com/LedgerHQ/app-bitcoin-new/blob/master/doc/bitcoin.md
 */
export class AppClient {
  constructor(transport) {
    this.transport = transport;
  }
  makeRequest(ins, data, cci) {
    return __awaiter(this, void 0, void 0, function* () {
      let response = yield this.transport.send(CLA_BTC, ins, 0, 0, data, [0x9000, 0xe000]);
      while (response.readUInt16BE(response.length - 2) === 0xe000) {
        if (!cci) {
          throw new Error("Unexpected SW_INTERRUPTED_EXECUTION");
        }
        const hwRequest = response.slice(0, -2);
        const commandResponse = cci.execute(hwRequest);
        response = yield this.transport.send(CLA_FRAMEWORK, FrameworkIns.CONTINUE_INTERRUPTED, 0, 0, commandResponse, [0x9000, 0xe000]);
      }
      return response.slice(0, -2); // drop the status word (can only be 0x9000 at this point)
    });
  }
  getExtendedPubkey(display, pathElements) {
    return __awaiter(this, void 0, void 0, function* () {
      if (pathElements.length > 6) {
        throw new Error("Path too long. At most 6 levels allowed.");
      }
      const response = yield this.makeRequest(BitcoinIns.GET_PUBKEY, Buffer.concat([Buffer.from(display ? [1] : [0]), pathElementsToBuffer(pathElements)]));
      return response.toString("ascii");
    });
  }
  getWalletAddress(walletPolicy, walletHMAC, change, addressIndex, display) {
    return __awaiter(this, void 0, void 0, function* () {
      if (change !== 0 && change !== 1) throw new Error("Change can only be 0 or 1");
      if (addressIndex < 0 || !Number.isInteger(addressIndex)) throw new Error("Invalid address index");
      if (walletHMAC != null && walletHMAC.length != 32) {
        throw new Error("Invalid HMAC length");
      }
      const clientInterpreter = new ClientCommandInterpreter(() => {});
      clientInterpreter.addKnownList(walletPolicy.keys.map(k => Buffer.from(k, "ascii")));
      clientInterpreter.addKnownPreimage(walletPolicy.serialize());
      const addressIndexBuffer = Buffer.alloc(4);
      addressIndexBuffer.writeUInt32BE(addressIndex, 0);
      const response = yield this.makeRequest(BitcoinIns.GET_WALLET_ADDRESS, Buffer.concat([Buffer.from(display ? [1] : [0]), walletPolicy.getWalletId(), walletHMAC || Buffer.alloc(32, 0), Buffer.from([change]), addressIndexBuffer]), clientInterpreter);
      return response.toString("ascii");
    });
  }
  signPsbt(psbt, walletPolicy, walletHMAC, progressCallback) {
    return __awaiter(this, void 0, void 0, function* () {
      const merkelizedPsbt = new MerkelizedPsbt(psbt);
      if (walletHMAC != null && walletHMAC.length != 32) {
        throw new Error("Invalid HMAC length");
      }
      const clientInterpreter = new ClientCommandInterpreter(progressCallback);
      // prepare ClientCommandInterpreter
      clientInterpreter.addKnownList(walletPolicy.keys.map(k => Buffer.from(k, "ascii")));
      clientInterpreter.addKnownPreimage(walletPolicy.serialize());
      clientInterpreter.addKnownMapping(merkelizedPsbt.globalMerkleMap);
      for (const map of merkelizedPsbt.inputMerkleMaps) {
        clientInterpreter.addKnownMapping(map);
      }
      for (const map of merkelizedPsbt.outputMerkleMaps) {
        clientInterpreter.addKnownMapping(map);
      }
      clientInterpreter.addKnownList(merkelizedPsbt.inputMapCommitments);
      const inputMapsRoot = new Merkle(merkelizedPsbt.inputMapCommitments.map(m => hashLeaf(m))).getRoot();
      clientInterpreter.addKnownList(merkelizedPsbt.outputMapCommitments);
      const outputMapsRoot = new Merkle(merkelizedPsbt.outputMapCommitments.map(m => hashLeaf(m))).getRoot();
      yield this.makeRequest(BitcoinIns.SIGN_PSBT, Buffer.concat([merkelizedPsbt.getGlobalKeysValuesRoot(), createVarint(merkelizedPsbt.getGlobalInputCount()), inputMapsRoot, createVarint(merkelizedPsbt.getGlobalOutputCount()), outputMapsRoot, walletPolicy.getWalletId(), walletHMAC || Buffer.alloc(32, 0)]), clientInterpreter);
      const yielded = clientInterpreter.getYielded();
      const ret = new Map();
      for (const inputAndSig of yielded) {
        ret.set(inputAndSig[0], inputAndSig.slice(1));
      }
      return ret;
    });
  }
  getMasterFingerprint() {
    return __awaiter(this, void 0, void 0, function* () {
      return this.makeRequest(BitcoinIns.GET_MASTER_FINGERPRINT, Buffer.from([]));
    });
  }
  signMessage(message, pathElements) {
    return __awaiter(this, void 0, void 0, function* () {
      if (pathElements.length > 6) {
        throw new Error("Path too long. At most 6 levels allowed.");
      }
      const clientInterpreter = new ClientCommandInterpreter(() => {});
      // prepare ClientCommandInterpreter
      const nChunks = Math.ceil(message.length / 64);
      const chunks = [];
      for (let i = 0; i < nChunks; i++) {
        chunks.push(message.subarray(64 * i, 64 * i + 64));
      }
      clientInterpreter.addKnownList(chunks);
      const chunksRoot = new Merkle(chunks.map(m => hashLeaf(m))).getRoot();
      const response = yield this.makeRequest(BitcoinIns.SIGN_MESSAGE, Buffer.concat([pathElementsToBuffer(pathElements), createVarint(message.length), chunksRoot]), clientInterpreter);
      return response.toString("base64");
    });
  }
}
