var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
const REGISTRIES = [{
  name: "ens",
  resolvers: {
    forward: "https://explorers.api.live.ledger.com/blockchain/v4/eth/ens/resolve/{name}",
    reverse: "https://explorers.api.live.ledger.com/blockchain/v4/eth/ens/reverse-resolve/{address}"
  },
  signatures: {
    forward: "https://nft.api.live.ledger.com/v1/names/ens/forward/{name}?challenge={challenge}",
    reverse: "https://nft.api.live.ledger.com/v1/names/ens/reverse/{address}?challenge={challenge}"
  },
  patterns: {
    forward: new RegExp("\\.eth$"),
    reverse: new RegExp("^0x[0-9a-fA-F]{40}$")
  },
  coinTypes: [60]
}];
/**
 * Method is voluntarly made async so it can be replaced by a backend call once implemented
 */
export const getRegistries = () => __awaiter(void 0, void 0, void 0, function* () {
  return REGISTRIES;
});
/**
 * Get an array of registries compatible with a given domain
 *
 * @param {string} domain
 * @returns {Promise<AddressResolutionResponse[]>}
 */
export const getRegistriesForDomain = domain => __awaiter(void 0, void 0, void 0, function* () {
  const registries = yield getRegistries();
  return registries.filter(registry => registry.patterns.forward.test(domain));
});
/**
 * Get an array of registries compatible with a given address
 *
 * @param {string} address
 * @returns {Promise<AddressResolutionResponse[]>}
 */
export const getRegistriesForAddress = address => __awaiter(void 0, void 0, void 0, function* () {
  const registries = yield getRegistries();
  return registries.filter(registry => registry.patterns.reverse.test(address));
});
