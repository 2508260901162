"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BytesLookup = exports.Bytes = exports.FieldLookup = exports.XrplDefinitionsBase = void 0;
const bytes_1 = require("./bytes");
Object.defineProperty(exports, "Bytes", {
  enumerable: true,
  get: function () {
    return bytes_1.Bytes;
  }
});
Object.defineProperty(exports, "BytesLookup", {
  enumerable: true,
  get: function () {
    return bytes_1.BytesLookup;
  }
});
const field_1 = require("./field");
Object.defineProperty(exports, "FieldLookup", {
  enumerable: true,
  get: function () {
    return field_1.FieldLookup;
  }
});
const constants_1 = require("./constants");
/**
 * Stores the various types and fields for rippled to be used to encode/decode information later on.
 * XrplDefinitions should be instantiated instead of this class.
 */
class XrplDefinitionsBase {
  /**
   * Present rippled types in a typed and updatable format.
   * For an example of the input format see `definitions.json`
   * To generate a new definitions file from rippled source code, use this tool: https://github.com/RichardAH/xrpl-codec-gen
   *
   * See the definitions.test.js file for examples of how to create your own updated definitions.json.
   *
   * @param enums - A json encoding of the core types, transaction types, transaction results, transaction names, and fields.
   * @param types - A list of type objects with the same name as the fields defined.
   *              You can use the coreTypes object if you are not adding new types.
   */
  constructor(enums, types) {
    this.type = new bytes_1.BytesLookup(enums.TYPES, constants_1.TYPE_WIDTH);
    this.ledgerEntryType = new bytes_1.BytesLookup(enums.LEDGER_ENTRY_TYPES, constants_1.LEDGER_ENTRY_WIDTH);
    this.transactionType = new bytes_1.BytesLookup(enums.TRANSACTION_TYPES, constants_1.TRANSACTION_TYPE_WIDTH);
    this.transactionResult = new bytes_1.BytesLookup(enums.TRANSACTION_RESULTS, constants_1.TRANSACTION_RESULT_WIDTH);
    this.field = new field_1.FieldLookup(enums.FIELDS, enums.TYPES);
    this.transactionNames = Object.entries(enums.TRANSACTION_TYPES).filter(([_key, value]) => value >= 0).map(([key, _value]) => key);
    this.dataTypes = {}; // Filled in via associateTypes
    this.associateTypes(types);
  }
  /**
   * Associates each Field to a corresponding class that TypeScript can recognize.
   *
   * @param types a list of type objects with the same name as the fields defined.
   *              Defaults to xrpl.js's core type definitions.
   */
  associateTypes(types) {
    // Overwrite any existing type definitions with the given types
    this.dataTypes = Object.assign({}, this.dataTypes, types);
    Object.values(this.field).forEach(field => {
      field.associatedType = this.dataTypes[field.type.name];
    });
    this.field['TransactionType'].associatedType = this.transactionType;
    this.field['TransactionResult'].associatedType = this.transactionResult;
    this.field['LedgerEntryType'].associatedType = this.ledgerEntryType;
  }
  getAssociatedTypes() {
    return this.dataTypes;
  }
}
exports.XrplDefinitionsBase = XrplDefinitionsBase;
