"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BytesLookup = exports.Bytes = void 0;
/*
 * @brief: Bytes, name, and ordinal representing one type, ledger_type, transaction type, or result
 */
class Bytes {
  constructor(name, ordinal, ordinalWidth) {
    this.name = name;
    this.ordinal = ordinal;
    this.ordinalWidth = ordinalWidth;
    this.bytes = new Uint8Array(ordinalWidth);
    for (let i = 0; i < ordinalWidth; i++) {
      this.bytes[ordinalWidth - i - 1] = ordinal >>> i * 8 & 0xff;
    }
  }
  toJSON() {
    return this.name;
  }
  toBytesSink(sink) {
    sink.put(this.bytes);
  }
  toBytes() {
    return this.bytes;
  }
}
exports.Bytes = Bytes;
/*
 * @brief: Collection of Bytes objects, mapping bidirectionally
 */
class BytesLookup {
  constructor(types, ordinalWidth) {
    this.ordinalWidth = ordinalWidth;
    Object.entries(types).forEach(([k, v]) => {
      this.add(k, v);
    });
  }
  /**
   * Add a new name value pair to the BytesLookup.
   *
   * @param name - A human readable name for the field.
   * @param value - The numeric value for the field.
   * @throws if the name or value already exist in the lookup because it's unclear how to decode.
   */
  add(name, value) {
    if (this[name]) {
      throw new SyntaxError(`Attempted to add a value with a duplicate name "${name}". This is not allowed because it is unclear how to decode.`);
    }
    if (this[value.toString()]) {
      throw new SyntaxError(`Attempted to add a duplicate value under a different name (Given name: "${name}" and previous name: "${this[value.toString()]}. This is not allowed because it is unclear how to decode.\nGiven value: ${value.toString()}`);
    }
    this[name] = new Bytes(name, value, this.ordinalWidth);
    this[value.toString()] = this[name];
  }
  from(value) {
    return value instanceof Bytes ? value : this[value];
  }
  fromParser(parser) {
    return this.from(parser.readUIntN(this.ordinalWidth).toString());
  }
}
exports.BytesLookup = BytesLookup;
