import { Directive, Input, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appDisableStakingAssetClick]',
  providers: [NgbTooltip]
})
export class DisablestakingassetClickDirective {
  @Input('appDisableStakingAssetClick') disableButtonCondition: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private toolTipService: NgbTooltip) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.createTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.toolTipService.close();
  }

  ngOnInit() {
    // Check the condition and disable the button accordingly
    console.info('disable  button directive',this.disableButtonCondition);
    
    if (!this.disableButtonCondition) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', true);
      this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'not-allowed');
      this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '0.6');
    }
  }

  private createTooltip() {
    if (!this.disableButtonCondition)
    {
      this.toolTipService.ngbTooltip = 'Staking is disabled for this asset';
      this.toolTipService.open({ host: this.elementRef.nativeElement });
    }
  }

}
