import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'titleCaseAfterComma'
})
export class TitleCaseAfterCommaPipe implements PipeTransform {
  transform(value: string, enumData?: any): string {
    if (!value) {
      return value;
    }

    // Split the input string by commas
    const parts = value.split(',');

    // Transform each part to title case
    const titleCaseParts = enumData ? parts.map(part => (enumData[part.trim()] || this.toTitleCase(part.trim()))) : parts.map(part => this.toTitleCase(part.trim()));

    // Join the title case parts back together with commas
    return titleCaseParts.join(', ');
  }

  private toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
}


@Pipe({
  name: 'contentTitle',
})
export class ContentTitlePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value && this.isFirstLetterCapital(value)){
      return value;
    }
    return this.toTitleCase(value || '');
  }

  private toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  private isFirstLetterCapital(str) {
    if (typeof str !== 'string' || str.length === 0) {
      return false; // Handle cases where input is not a non-empty string
    }
  
    // Get the first character of the string and compare it to its uppercase version
    return str.charAt(0) === str.charAt(0).toUpperCase();
  }

}
@Pipe({
  name: 'splitstring'
})
export class SplitStringPipe implements PipeTransform {
  constructor() { }

  transform(value: string, separator: string): string[] {
    return value ? value.split(separator).filter(x => x) : [];
  }

}
