import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { HttpService } from "src/app/shared/services/http.service";
import { WalletserviceService } from "src/app/shared/services/walletservice.service";
import { Observable } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Wallet } from "src/app/shared/entities/wallet";
import { ComposeTransaction } from "../../helpers/trezor/transactionComposer";
import {
  tokenType,
  TransactionRequest,
} from "../../entities/TransactionRequest";
import { LoggerService } from "../../../shared/services/logger.service";
import Swal from "sweetalert2";
import { PolicyService } from "../../../shared/services/policy.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { error } from "../../entities/cosmosTransaction";
import { u8aToHex } from "../../helpers/trezor/polkadotUtils";
import { MpcTxData } from "../../entities/mpcTxRequest";
import { orgType, CUSTODY_TYPE } from "../../entities/members";
import { SegmentService } from "ngx-segment-analytics";
import { getWalletType, isV2MultisigWallet, WalletSubType } from "@helpers/WalletUtils";
import { TransactionService } from "../../services/transaction.service";

import { AuthServiceJWT } from "../../services/auth.service";
import { ErrorPrompt } from "../custom-prompt/custom-prompt.service";
import { TransactionStatus } from "../../entities/Transaction";
import { FirewallCheckStatus } from "./pending-txn-info/firewall.utils";
import { isNonCustodyOrg } from "../../helpers/org.utils";

@Component({
  selector: "app-pending-transactions",
  templateUrl: "./pending-transactions.component.html",
  styleUrls: ["./pending-transactions.component.scss"],
})
export class PendingTransactionsComponent implements OnInit {
  page = 1;
  pageSize = 10;
  filter = "";
  filterActionsType = "All";
  Requested_cancelation_transaction: TransactionRequest;
  reset_cancelation_tx: TransactionRequest;
  Requested_mpc_cancelation_transaction: MpcTxData;
  resetMpcCancelData: MpcTxData;
  Requested_message_signing_cancelation_txn: any;
  pendingtxs: TransactionRequest[] = [];
  filteredOptions: Observable<Wallet[]>;
  wallets: Wallet[];
  composeTransaction: ComposeTransaction;
  txRequest: TransactionRequest;
  Error_canceltransaction = false;
  Wallet_Name: string;
  balanceUSD: number;
  amount: number;
  balance: number;
  policiesPendingTx: any = [];
  policyAddress: string;
  policyTimePeriod: string;
  policyType: string;
  user: any;
  policyTxId: Number;
  policyWalletId: Number;
  component_for = "policy_pending";
  deviceName: string;
  _wallets: any;
  allMember: any;
  stakingTx: any;
  nftTx: any = [];
  error_message: string =
    "Something went wrong. Please try again after sometime!";
  isCanceling: boolean = false;
  teamWalletPendingTx: MpcTxData[] = [];
  meFilter: boolean = false;
  configPolicy: any;
  loading: boolean = true;
  pendingSearch: string;
  messageSignPendingTx = [];
  explainTransactionMpcMsg: any = [];
  walletReq: any = [];
  orgProfile: any = {};
  orgType = orgType;
  custodyType = CUSTODY_TYPE;
  filterType = [
    "Transactions",
    "Mobile",
    "Policies",
    "Staking",
    "NFT",
    "Travel Rule",
  ];

  pendingTravelRules = [];
  selectedTravelRule = null;
  getWalletType = getWalletType;
  isV2MultisigWallet = isV2MultisigWallet;
  walletSubType = WalletSubType;

  FIREWALL_PENDING_STATUS = FirewallCheckStatus.FIREWALL_PENDING_STATUS;
  LIVENESS_CHECK = FirewallCheckStatus.LIVENESS_CHECK;
  isNonCustodyOrgType: boolean = false;
  firewallLivenessTxs = [];

  @Input() model: any;
  @Input() pendingtransactions_component_for: string;
  @Input() walletDetailWalletId: any;
  @Input() pendingtx_component_for: string;
  @ViewChild("livenessKycCheck") livenessKycCheck: ElementRef;
  @ViewChild("canceltrsancation") cancelTxnRef: ElementRef;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private segment: SegmentService,
    private data: DataService,
    private walletService: WalletserviceService,
    private httpService: HttpService,
    private modalService: NgbModal,
    private logger: LoggerService,
    private policyService: PolicyService,
    public authService: AuthServiceJWT,
    private txnService: TransactionService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getPolicyConfig();
    this.init();
  }

  ngOnInit(): void {
    this.filterPenTxsWalletDeails();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  async init() {
    let getMember = await this.httpService.getAllMembers().toPromise();
    this.allMember = getMember[0].members;

    this.data.pendingTravelRuleTxRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((txs: any) => {
        if (txs == null) return;
        this.pendingTravelRules = txs.data.map((obj) => {
          let trpData: any = {};
          try {
            trpData = JSON.parse(obj.data || "");
          } catch (err) {
            // no need to stop flow on error
            // this just indicates trp is not staking type
          }
          if (trpData?.transactionType !== 3 && trpData.memo) {
            obj.memo = trpData.memo;
          } else {
            if (obj.memoData) {
              obj.memo = obj.memoData.value;
            }
          }
          obj.signers = obj.signers.map((el) => ({ ...el, user_type: el.userType }));
          obj.actionType = "travel-rule";
          obj.signs = obj.signers.filter((el) => el.userType === 1);
          obj.asset = obj.wallet.coin.toLowerCase();
          obj.walletinfor = this.walletService.getWallet(obj.walletId + "_" + obj.asset.toLowerCase() );
          return obj;
        });
        this.filterPenTxsWalletDeails();
      });

    this.data.pendingTxRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((txs: TransactionRequest[]) => {
        if (txs == null) return;
        this.pendingtxs = this.txnService.processMultiSigPendingTxnData(txs, this.allMember);

        //staking tx data filter
        if(this.pendingtxs && this.pendingtxs.length){
          this.stakingTx = this.pendingtxs.filter(
            (ele) => ele._metaData?.tx_category_type.toLowerCase() == "staking"
          );
          this.nftTx = this.pendingtxs.filter(
            (ele) => ele.tokenType == tokenType.nftToken.toString()
          );
        }
        
        this.loading = false;
        this.filterPenTxsWalletDeails();
      });

    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((wallets) => {
      if (wallets == null) return;
      this._wallets = wallets;
      this._wallets = [
        ...new Map(this._wallets.map((item) => [item.id, item])).values(),
      ];
      this.filterPenTxsWalletDeails();
    });

    this.data.pendingMpcRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((tx) => {
        if (tx == null) return;

        this.firewallLivenessTxs = tx.filter((tx) => this.isLivenessType(tx));
        this.teamWalletPendingTx = tx.filter((tx) => !this.isLivenessType(tx));
        this.teamWalletPendingTx = this.txnService.processMpcPendingTnxData(
          this.teamWalletPendingTx
        );
        this.filterPenTxsWalletDeails();
        console.log("_mpcTxType", this.teamWalletPendingTx);
        this.loading = false;
      });

    this.data.walletRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((req) => {
        if (req == null) return;
        this.walletReq = req;
        this.walletReq.forEach((element) => {
          element.type = "walletReq";
          element.walletType = getWalletType(element.w_type);
          // element.w_createdAt = "2023-05-09T05:43:17.000Z"
        });
        if (this.pendingtransactions_component_for === "pentrs_wallet_details")
          this.walletReq = [];
        this.filterPenTxsWalletDeails();
      });

    this.data.pendingMessageSigningRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((tx) => {
        if (tx == null) return;
        this.messageSignPendingTx = tx;
        for (let i = 0; i < this.messageSignPendingTx.length; i++) {
          this.messageSignPendingTx[i].asset =
            this.messageSignPendingTx[i].wallet.coin.toLowerCase();
          this.messageSignPendingTx[i].walletinfor =
            this.walletService.getWallet(
              this.messageSignPendingTx[i].walletId +
                "_" +
                this.messageSignPendingTx[i].asset.toLowerCase()
            );

          let initiator_config =
            this.messageSignPendingTx[i].team.initiator_config.split("of");
          let signer_config =
            this.messageSignPendingTx[i].team.signer_config.split("of");
          this.messageSignPendingTx[i].team.initiators = initiator_config[0];
          this.messageSignPendingTx[i].team.signers = signer_config[0];
          this.messageSignPendingTx[i].team.initiatorsSigns =
            this.messageSignPendingTx[i]?.signers.filter(
              (ele) => ele.approved === true && ele.user_type == 1
            ).length;
          this.messageSignPendingTx[i].team.signersSigns =
            this.messageSignPendingTx[i]?.signers.filter(
              (ele) => ele.approved === true && ele.user_type == 2
            ).length;
          this.messageSignPendingTx[i].initiatorsList =
            this.messageSignPendingTx[i]?.signers.filter(
              (ele) => ele.user_type == 1
            );
          this.messageSignPendingTx[i].signersList = this.messageSignPendingTx[
            i
          ]?.signers.filter((ele) => ele.user_type == 2);
        }
        if (this.messageSignPendingTx)
          this.messageSignPendingTx.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        this.filterPenTxsWalletDeails();
        this.loading = false;
      });

    this.data.getUserProfile
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((userProfile) => {
        if (!userProfile) return;
        this.orgProfile = userProfile["organizations"][0];
        this.isNonCustodyOrgType = isNonCustodyOrg(this.orgProfile)
        if (
          this.orgProfile.orgType == orgType.sub &&
          this.orgProfile.custodyType == this.custodyType.CUSTODY
        ) {
          this.filterType = ["Mobile", "Policies", "Wallet Request"];
        } else if (
          this.orgProfile.orgType == orgType.sub &&
          this.orgProfile.custodyType == this.custodyType.NONCUSTODY
        ) {
          this.filterType = ["Mobile", "Policies"];
        }
      });
    
    this.txnService.rejectTxnSubject.pipe(takeUntil(this.onDestroy$)).subscribe((txn) => {
      this.open(this.cancelTxnRef);
      if (txn?.walletinfor && txn?._txType == 'multisig_tx') {
        this.gettransaction(txn);
      }
      if (txn.msgToSign && !txn?.source && !this.isLivenessType(txn) && txn?._mpcTxType) {
        this.getMpcTransaction(txn);
      }
      if (txn?.actionType == 'travel-rule') {
        this.setTx(txn);
      }
    })

    this.refreshPendingPolicyData();
    this.pendingPolicyData();
  }

  filterPenTxsWalletDeails() {
    if (this.pendingtransactions_component_for === "pentrs_wallet_details") {
      if (this.messageSignPendingTx) {
        this.messageSignPendingTx = this.messageSignPendingTx.filter(
          (obj) => obj.walletId == this.walletDetailWalletId
        );
      }
      if (this.teamWalletPendingTx) {
        this.teamWalletPendingTx = this.teamWalletPendingTx.filter(
          (obj) => obj.walletId == this.walletDetailWalletId
        );
      }
      if (this.pendingTravelRules) {
        this.pendingTravelRules = this.pendingTravelRules.filter(
          (obj) => obj.walletId == this.walletDetailWalletId
        );
      }
      if (this.pendingtxs) {
        this.pendingtxs = this.pendingtxs.filter(
          (obj) => obj.walletid == this.walletDetailWalletId
        );
      }
      if (!this.policiesPendingTx) {
        this.policiesPendingTx = this.policiesPendingTx.filter(
          (obj) => obj.wallet_id == this.walletDetailWalletId
        );
      }
      //staking tx data filter
      this.stakingTx = this.pendingtxs.filter(
        (ele) => ele._metaData?.tx_category_type.toLowerCase() == "staking"
      );
      this.nftTx = this.pendingtxs.filter(
        (ele) => ele.tokenType == tokenType.nftToken.toString()
      );
    }
  }

  actionsFilter(type) {
    type = type.toLowerCase();
    this.filterActionsType = type;
    if (type == "me") {
      this.meFilter = true;
    } else if (type == "all") {
      this.meFilter = false;
    }
  }

  refreshPendingPolicyData() {
    this.data.pendingPolicyRequest
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((txRequests) => {
        if (txRequests == null) return;
        this.policiesPendingTx = txRequests;
        this.processPolicyData();
      });
  }

  pendingPolicyData() {
    this.httpService
      .getAllPendingPoliciesTransactions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((txRequests) => {
        this.policiesPendingTx = txRequests.data.policyList;
        this.processPolicyData();
      });
  }

  processPolicyData() {
    if (this.policiesPendingTx)
      this.policiesPendingTx.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    if (this.pendingtransactions_component_for === "pentrs_wallet_details") {
      if (!this.policiesPendingTx) return;
      this.policiesPendingTx = this.policiesPendingTx.filter(
        (obj) => obj.wallet_id == this.walletDetailWalletId
      );
    }
    this.policiesPendingTx.forEach((element) => {
      let signer_config = this.configPolicy?.split("of");
      element._signers = signer_config ? signer_config[0] : "";
      element._signersSigns = element.signers.filter(
        (ele) => ele.approved === true
      ).length;
      element["_txType"] = "policy_tx";
    });
  }

  getStatusText(txData: any) {
    return this.txnService.getStatusText(txData);
  }

  gettransaction(txData: TransactionRequest) {
    this.Requested_cancelation_transaction = txData;
  }

  getMpcTransaction(txData: MpcTxData) {
    this.Requested_mpc_cancelation_transaction = txData;
  }

  setTx(txData) {
    this.selectedTravelRule = txData;
  }

  cancelTx() {
    if (this.selectedTravelRule?.actionType == "travel-rule") {
      this.rejectTravelRule();
    }
    if (this.Requested_cancelation_transaction?.chain == "DOT") {
      this.dotCanceltransaction();
    }
    if (
      this.Requested_message_signing_cancelation_txn?.source ===
        "walletconnect" ||
      this.Requested_message_signing_cancelation_txn?.source === "mmi"
    ) {
      this.rejectMessageSigningTx();
    } else if (
      this.Requested_mpc_cancelation_transaction?.walletinfor?.version === 2
    ) {
      this.rejectMpcTx();
    } else {
      this.canceltransaction();
    }
  }

  async initiateTravelRule(txReq) {
    this.data.newTxRequest(txReq);
  }

  async rejectTravelRule() {
    try {
      this.isCanceling = true;
      let res = await this.httpService
        .rejectTravelRule(
          this.selectedTravelRule.walletId,
          this.selectedTravelRule.sequenceId,
          this.selectedTravelRule
        )
        .toPromise();
      if (res.success) {
        Swal.fire({
          title: "Success!",
          text: "Awesome! Travel rule transaction rejected successfully.",
          icon: "success",
          confirmButtonText: "Cool",
        });
      } else {
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to reject travel rule transaction!",
          text: res.data,
          // showConfirmButton: false,
        });
      }
    } catch (err) {
      this.logger.error(err, err);
    }
    this.modalService.dismissAll();
    setTimeout(function () {
      this.Error_canceltransaction = false;
      this.isCanceling = false;
    }, 10);
  }

  getMessageSigningTransaction(txData: any) {
    this.Requested_message_signing_cancelation_txn = txData;
  }

  async rejectMessageSigningTx() {
    try {
      let txData: any = this.Requested_message_signing_cancelation_txn;
      let res = await this.httpService
        .rejectMessageSigningRequest(txData.walletId, txData.id)
        .toPromise();
      if (res.success === false) {
        this.Error_canceltransaction = true;
        this.Requested_message_signing_cancelation_txn = {};
        //err
      } else {
        this.Error_canceltransaction = false;
        this.Requested_message_signing_cancelation_txn = {};
        this.genericSweetalert(
          "Success",
          "Awesome! You successfully rejected the transaction.",
          "success",
          "Cool"
        );
        let txRequests = await this.httpService
          .getAllPendingMessageRequest()
          .toPromise();
        this.data.changePendingMessageSigningRequest(txRequests.data);
        this.Requested_message_signing_cancelation_txn = {};
      }
    } catch (err) {
      this.Requested_message_signing_cancelation_txn = {};
      this.Error_canceltransaction = true;
      this.logger.error(err, err);
    }
  }

  async rejectMpcTx() {
    try {
      let txData: MpcTxData = this.Requested_mpc_cancelation_transaction;
      let res = await this.httpService
        .rejectMpcTx(txData.id, txData.walletId)
        .toPromise();
      if (res.success === false) {
        this.Requested_mpc_cancelation_transaction = this.resetMpcCancelData;
        this.Error_canceltransaction = true;
        this.Requested_mpc_cancelation_transaction = this.resetMpcCancelData;
        //err
      } else {
        this.Error_canceltransaction = false;
        this.Requested_mpc_cancelation_transaction = this.resetMpcCancelData;
        this.genericSweetalert(
          "Success",
          "Awesome! You successfully rejected the transaction.",
          "success",
          "Cool"
        );
        let txRequests = await this.httpService
          .getTeamsPendingTxReq()
          .toPromise();
        console.info("my testing data :", txRequests);

        this.data.changeMpcPendingTxRequest(txRequests.data);
        this.Requested_mpc_cancelation_transaction = this.resetMpcCancelData;
      }
    } catch (err) {
      this.Requested_mpc_cancelation_transaction = this.resetMpcCancelData;
      this.Error_canceltransaction = true;
      this.logger.error(err, err);
    }
  }

  async canceltransaction() {
    let txData: TransactionRequest = this.Requested_cancelation_transaction;
    try {
      let res;
      if (txData.walletinfor?.parentchain === "EVM") {
        res = await this.httpService
          .rejectMultiSigSendTxnReq(txData.walletid.toString(), txData.id)
          .toPromise();
      } else {
        res = await this.httpService
          .addNewMultisigTransaction(txData.walletid.toString(), {...txData, status: TransactionStatus.REJECTED })
          .toPromise();
      }
      if (!res) {
        this.Error_canceltransaction = true;
        this.Requested_cancelation_transaction = this.reset_cancelation_tx;
      } else {
        this.Error_canceltransaction = false;
        const clickcancel = document.getElementById(
          "closemodalbtn"
        ) as HTMLElement;
        clickcancel.click();
        this.genericSweetalert(
          "Success",
          "Awesome! You successfully rejected the transaction.",
          "success",
          "Cool"
        );
        let txRequests = await this.httpService
          .getAllPendingWalletTransactions()
          .toPromise();
        this.data.changePendingTxRequest(txRequests);
        this.Requested_cancelation_transaction = this.reset_cancelation_tx;
      }
    } catch (err) {
      this.Error_canceltransaction = true;
      this.Requested_cancelation_transaction = this.reset_cancelation_tx;
      this.logger.error(err, err);
    }
  }

  async dotCanceltransaction() {
    this.isCanceling = true;
    try {
      this.Error_canceltransaction = false;
      let txData: TransactionRequest = this.Requested_cancelation_transaction;
      let getCancellation = await this.httpService
        .getDotCancleTransaction(txData.walletid.toString(), txData)
        .toPromise();
      if (getCancellation.success == false) {
        this.Error_canceltransaction = true;
        this.Requested_cancelation_transaction = this.reset_cancelation_tx;
        this.isCanceling = false;
        //err
      } else {
        txData.status = "5";
        this.Error_canceltransaction = false;
        let getWalletcancel = this.walletService.getAllWallets();
        getWalletcancel = getWalletcancel.filter(
          (ele) => ele.id == this.Requested_cancelation_transaction.walletid
        );
        this.composeTransaction = new ComposeTransaction(
          getWalletcancel[0],
          this.data,
          this.httpService,
          this.logger,
          this.segment,
          this.authService,
          this.walletService
        );
        const signData: any | error[] = await this.composeTransaction.signDot(
          getCancellation.data.txDataResponse
        );
        if (
          signData[0]?.error ||
          signData.error_message.toLowerCase() != "No errors".toLowerCase()
        ) {
          if (
            signData.error_message &&
            signData.error_message.toLowerCase() != "No errors".toLowerCase()
          ) {
            ErrorPrompt.fire({
              icon: "error",
              title: "Failed to sign transaction!",
              text: this.walletService.setCustomErrorMsg(
                signData.error_message
              ),
              showConfirmButton: false,
            });
            this.modalService.dismissAll();
            this.isCanceling = false;
            this.Requested_cancelation_transaction = this.reset_cancelation_tx;
            return;
          }

          ErrorPrompt.fire({
            icon: "error",
            title: "Failed to sign transaction!",
            // text:
            //   signData[0]?.error.charAt(0).toUpperCase() +
            //   signData[0]?.error.slice(1),
            text: this.walletService.setCustomErrorMsg(signData[0]?.error),
            showConfirmButton: false,
          });
          this.modalService.dismissAll();
          this.isCanceling = false;
          this.Requested_cancelation_transaction = this.reset_cancelation_tx;
          return;
        }
        const signatureData = u8aToHex(signData.signature);
        let bodyContent = {
          rawTx: getCancellation?.data.txJson.raw_data,
          signature: signatureData,
        };
        let postCancellation = await this.httpService
          .postDotCancleTransaction(
            txData.walletid.toString(),
            txData,
            bodyContent
          )
          .toPromise();
        if (postCancellation.success == true) {
          Swal.fire({
            title: "Success!",
            text: postCancellation.data,
            icon: "success",
            confirmButtonText: "Cool",
          });
          this.isCanceling = false;
          this.modalService.dismissAll();
        }
        const clickcancel = document.getElementById(
          "canceltransaction"
        ) as HTMLElement;
        clickcancel.click();
        let txRequests = await this.httpService
          .getAllPendingWalletTransactions()
          .toPromise();
        this.data.changePendingTxRequest(txRequests);
        this.Requested_cancelation_transaction = this.reset_cancelation_tx;
      }
    } catch (err) {
      this.Error_canceltransaction = true;
      this.isCanceling = false;
      this.Requested_cancelation_transaction = this.reset_cancelation_tx;
      this.logger.error(err, err);
      if (err?.error?.success == false) {
        this.error_message = err.error.message;
      }
    }
  }

  getPolicyTxId(walletId, policyTxId) {
    this.policyTxId = policyTxId;
    this.policyWalletId = walletId;
  }

  open(canceltrsancation: any) {
    this.resetSendModel();
    this.modalService.open(canceltrsancation, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  resetSendModel() {
    this.Error_canceltransaction = false;
    this.isCanceling = false;
  }

  checkAccess(signer) {
    if (
      signer.filter((item) => item.email != this.user.email).length ==
      signer.length
    )
      return true;
    return false;
  }

  concat(a, b, c, d, e, f) {
    if (a && b && c && d && e && f) {
      let data = [...c, ...a, ...b, ...d, ...e, ...f];
      data.sort((a, b) => {
        return (
          b.timestamp ||
          b.createdAt ||
          b.created_at ||
          b.createdAt ||
          b.w_created_at
        ).localeCompare(
          a.timestamp ||
            a.createdAt ||
            a.created_at ||
            a.createdAt ||
            a.w_created_at
        );
      });
      if (this.pendingtx_component_for == "dashboared-pending") {
        return data.slice(0, 4);
      }
      return data;
    }
    if (a) return a;
    if (b) return b;
    if (c) return c;
    if (d) return d;
    if (e) return e;
    if (f) return f;
  }

  async getPolicyConfig() {
    let _configPolicy = await this.httpService
      .getConfigPolicyApprovals()
      .toPromise();
    this.configPolicy = _configPolicy.data.config;
  }

  filterPolicySigner(data) {
    return data?.filter((e) => e?.userType == 1);
  }

  filterInitiatorsList(data) {
    return data?.filter((e) => e?.user_type == 1 && e?.userType == 1);
  }

  onMessageSignApprove(txReq) {
    let status = this.getStatusText(txReq);
    if (status == "Signed") return;
    try {
      this.data.newMessageSignRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  // Action: Policy

  async signPendingPolicy(deviceName, policiestxdata) {
    this.deviceName = deviceName;
    let policyRequest = "add policy";

    if (
      policiestxdata.sub_type.toLowerCase() === "address" ||
      policiestxdata.sub_type.toLowerCase() === "remove_address" ||
      policiestxdata.sub_type.toLowerCase() === "stakingenabled"
    ) {
      this.policyAddress = policiestxdata.condition;
      this.policyType = "whitelist_address";
    }
    if (
      policiestxdata.sub_type.toLowerCase() === "velocity" ||
      policiestxdata.sub_type.toLowerCase() === "remove_velocity"
    ) {
      this.policyTimePeriod = policiestxdata.condition;
      this.policyType = "wallet_velocity";
    }
    if (
      policiestxdata.sub_type.toLowerCase() === "threshold" ||
      policiestxdata.sub_type.toLowerCase() === "remove_threshold"
    ) {
      this.policyType = "wallet_threshold";
    }
    if (policiestxdata.sub_type.toLowerCase() === "enable_app") {
      this.policyAddress = policiestxdata.condition;
      this.policyType = "enable_app";
    }
    try {
      const response = await this.policyService.approvePolicy(
        policiestxdata,
        this.policyType,
        this.policyAddress,
        this.policyTimePeriod,
        this.deviceName,
        policyRequest
      );
      if (response.success === true) {
        this.segment
          .track("policy-approved", {
            txData: policiestxdata,
            policyType: this.policyType,
            policyAddress: this.policyAddress,
            policyTimePeriod: this.policyTimePeriod,
            deviceName: this.deviceName,
            policyRequest,
          })
          .catch((err) => {});
        this.modalService.dismissAll();
        Swal.fire({
          title: "Success!",
          text: "Policy is approved",
          icon: "success",
          confirmButtonText: "Cool",
        });
        this.pendingPolicyData();
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to approve policy",
          text: response.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to sign policy",
        text: "Failed to sign policy",
        showConfirmButton: false,
      });
    }
  }

  async rejectPolicy() {
    let data = await this.httpService
      .rejectPolicy(this.policyWalletId, this.policyTxId)
      .toPromise();
    if (data.success == true) {
      this.genericSweetalert(
        "Success",
        "Awesome! You successfully rejected the policy.",
        "success",
        "Cool"
      );
      this.pendingPolicyData();
    } else {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Oops...",
        text: `${data.message}`,
      });
    }
  }
  genericSweetalert(title, text, icon, btntext) {
    this.modalService.dismissAll();
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: btntext,
    });
  }

  isLivenessTransactionExists(txData: any): boolean {
    let sequenceId = txData.sequenceId;
    return this.firewallLivenessTxs.some(
      (tx: any) => tx.sequenceId === sequenceId
    );
  }

  isLivenessType(txData: any): boolean {
    return (
      this.FIREWALL_PENDING_STATUS === txData.status &&
      this.LIVENESS_CHECK === txData.ruleName
    );
  }

  onViewFirewallTransactionClick(txData: any) {
    this.txnService.viewFirewallTransaction(txData, !this.isNonCustodyOrgType);
  }
}
