"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.types = exports.ShaMap = exports.HashPrefix = exports.quality = exports.TransactionResult = exports.Type = exports.LedgerEntryType = exports.TransactionType = exports.Field = exports.DEFAULT_DEFINITIONS = exports.ledgerHashes = exports.binary = exports.hashes = void 0;
const enums_1 = require("./enums");
Object.defineProperty(exports, "DEFAULT_DEFINITIONS", {
  enumerable: true,
  get: function () {
    return enums_1.DEFAULT_DEFINITIONS;
  }
});
Object.defineProperty(exports, "Field", {
  enumerable: true,
  get: function () {
    return enums_1.Field;
  }
});
Object.defineProperty(exports, "TransactionType", {
  enumerable: true,
  get: function () {
    return enums_1.TransactionType;
  }
});
Object.defineProperty(exports, "LedgerEntryType", {
  enumerable: true,
  get: function () {
    return enums_1.LedgerEntryType;
  }
});
Object.defineProperty(exports, "Type", {
  enumerable: true,
  get: function () {
    return enums_1.Type;
  }
});
Object.defineProperty(exports, "TransactionResult", {
  enumerable: true,
  get: function () {
    return enums_1.TransactionResult;
  }
});
const types = __importStar(require("./types"));
exports.types = types;
const binary = __importStar(require("./binary"));
exports.binary = binary;
const shamap_1 = require("./shamap");
Object.defineProperty(exports, "ShaMap", {
  enumerable: true,
  get: function () {
    return shamap_1.ShaMap;
  }
});
const ledgerHashes = __importStar(require("./ledger-hashes"));
exports.ledgerHashes = ledgerHashes;
const hashes = __importStar(require("./hashes"));
exports.hashes = hashes;
const quality_1 = require("./quality");
Object.defineProperty(exports, "quality", {
  enumerable: true,
  get: function () {
    return quality_1.quality;
  }
});
const hash_prefixes_1 = require("./hash-prefixes");
Object.defineProperty(exports, "HashPrefix", {
  enumerable: true,
  get: function () {
    return hash_prefixes_1.HashPrefix;
  }
});
