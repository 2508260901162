import { Component, Input, OnInit } from '@angular/core';
import { GetIconPipe } from '../../shared/components/pipes/coins';
import { getName } from '../../shared/helpers/coins';

@Component({
  selector: 'app-asset-listing',
  templateUrl: './asset-listing.component.html',
  styleUrls: ['./asset-listing.component.scss'],
  providers: [GetIconPipe]
})
export class AssetListingComponent implements OnInit {

  @Input() assetsList: string | string[];
  isAnyCase: boolean = false;
  displayArray: { name: string, image: string }[] = [];
  hiddenItems: string[] = [];

  constructor(private iconPine: GetIconPipe) { }

  ngOnInit(): void {
    if (Array.isArray(this.assetsList)) {
      this.handleAssetDetails(this.assetsList);
    } else {
      const data = this.assetsList.split(',');
      if (data.length) {
        if (data[0]?.toLowerCase() === 'any') {
          this.isAnyCase = true;
        } else {
          this.handleAssetDetails(data);
        }
      }
    }
  }

  handleAssetDetails(data: string[]) {
    this.hiddenItems = data.splice(4).map((e) => getName(e));
    for (const iterator of data) {
      this.displayArray.push({
        name: getName(iterator),
        image: this.iconPine.transform(iterator)
      })
    }
  }

}
