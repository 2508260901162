
<button appDisableClick class="btn px-0 py-2 justify-content-start pl-4 enable-btn"  *ngIf="component_for == 'user-management'" [ngClass]="{'disabled': (member.isOwner || member.isOwner && deviceList?.includes('mobile pending'))}" (click)="open(mobileEnable)" [disabled]="member.isOwner || (member.isOwner && deviceList?.includes('mobile pending')) "><i class="icon-mobile mr-2 icon-box-center"></i>
    {{ deviceList?.includes('mobile') ? 'Re-enable Mobile' : 'Enable Mobile' }}
  </button>
  <button appDisableClick class="btn btn-primary btn-round" [disabled]="!sdkEnabled" *ngIf="component_for == 'dev_onbording' && !deviceList?.includes('mpc')" (click)="open(mobileEnable)">Setup MPC</button>
  <button appDisableClick class="btn btn-round btn-outline-light-grey" *ngIf="component_for == 'dev_api_key' && !deviceList?.includes('mpc')" (click)="open(mobileEnable)">Enable MPC Singer</button>
  <ng-template #mobileEnable let-modal>
    <div *ngIf="step == 'step-1'">
      <div class="modal-header">
          <h2 class="modal-title">
              <i class="icon-mobile text-dark mr-2"></i>
              <ng-container>
                {{ deviceList?.includes('mobile') ? 'Re-enabling Mobile device' : 'Enabling mobile device' }}
              </ng-container>
          </h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
              <i class="icon-close"></i>
          </button>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-12">
                <h2 class="text-center mt-4 mb-5 font-size-18 line-height-24">You are enabling Mobile Signing<br> capability for {{member.displayName}}</h2>
                <ul class="dotline">
                    <li class="d-flex align-items-start mb-5">
                        <i class="icon-mobile-key text-primary icon-round-border-big"></i>
                        <span class="font-size-16 w-75 ml-4 mt-1">Approve on your mobile device to share signing access securely</span>
                    </li>
                    <li class="d-flex align-items-start mb-5">
                        <i class="icon-approve-round text-primary icon-round-border-big"></i>
                        <span class="font-size-16 w-75 ml-4 mt-1">{{member.displayName}} needs to accept signing access securely on their mobile</span>
                    </li>
                    <li class="d-flex align-items-start">
                        <i class="icon-member-approve text-primary icon-round-border-big"></i>
                        <span class="font-size-16 w-75 ml-4 mt-1">Now you can add {{member.displayName}} to Mobile Signing team</span>
                    </li>
                </ul>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100" (click)="enableMobile(member.userid)">
            Confirm
        </button>
      </div>
    </div>
    <div *ngIf="step == 'step-2'">
        <div class="modal-header">
            <h2 class="modal-title">
                <i class="icon-mobile text-dark mr-2"></i>
                <ng-container *ngIf="component_for == 'user-management'">Enabling mobile device</ng-container>
                <ng-container *ngIf="component_for == 'dev_onbording' || component_for == 'dev_api_key'">Setup MPC</ng-container>
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body d-flex justify-content-center align-items-center">
            <div class="row">
                <div class="col-12 d-flex flex-column align-items-center">
                    <i class="icon-mobile icon-round-bg-big text-primary"></i>
                    <span class="d-block mt-4 font-size-16 text-center">
                        <ng-container *ngIf="component_for == 'user-management' || component_for == 'dev_api_key'">To enable mobile, member must have<br> setup Liminal vaults app on iPhone</ng-container>
                        <ng-container *ngIf="component_for == 'dev_onbording'">To enable MPC signer, owner must have<br> setup Liminal vaults app on iPhone</ng-container>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
          <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');">
              Done
          </button>
        </div>
      </div>
  <div *ngIf="step == 'success'">
    <div class="modal-header border-0">
        <h2 class="modal-title"><i class="icon-add mr-2"></i> Add Smart Consolidation Rule</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
                class="icon-close"></i> </button>
    </div>
    <div class="modal-body d-flex align-items-center">
        <div class="row">
            <div class="col-12">
                <div class="mb-3 position-relative">
                    <div class="background_success text-center"> <img class="w-50"
                            src="../../../../assets/images/check-outline-success.gif"> </div>
                </div>
                <div class="mb-5 text-center">
                    <div class="h5">Success</div>
                    <div class="text-muted">Awesome! You have successfully added
                        <br> the consolidation rule. 🎉
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100"
            (click)="modal.dismiss('Cross click');"> Done
        </button>
    </div>
  </div>
  
  </ng-template>