import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MpcTxData } from "@entities/mpcTxRequest";
import { orgType, CUSTODY_TYPE } from "../../../entities/members";
import {
  getParentChain,
  getWalletType,
  isV2MultisigWallet,
} from "../../../helpers/WalletUtils";
import { TransactionService } from "../../../services/transaction.service";
import { DataService } from "../../../services/data.service";
import { SpeedUpConfirmationPopupComponent } from "../../speed-up-transaction/speed-up-confirmation-popup/speed-up-confirmation-popup.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FirewallCheckStatus, PendingTxnStatus } from "../pending-txn-info/firewall.utils";
import { TeamActionType } from "../../../entities/teams.model";
import { WalletType } from "../../../../shared/constants/exchange.const";
import { isNonCustodyOrg } from "../../../helpers/org.utils";

@Component({
  selector: "app-mpc-wallet-table-data, [mpc-wallet-table-data]",
  templateUrl: "./mpc-wallet-table-data.component.html",
  styleUrls: ["./mpc-wallet-table-data.component.scss"],
})
export class MpcWalletTableDataComponent implements OnInit {
  @Input() txdata: any = {};
  @Input() pendingtransactions_component_for: string;
  @Input() orgProfile: any;
  @Input() isLivenessTransactionExists: boolean = false;
  @Output() onRejectTxn = new EventEmitter<boolean>();
  @Input() uniqId: number;
  orgType = orgType;
  custodyType = CUSTODY_TYPE;
  walletType = WalletType;

  getWalletType = getWalletType;
  isV2MultisigWallet = isV2MultisigWallet;
  isExpanded: boolean = false;

  FIREWALL_PENDING_STATUS = FirewallCheckStatus.FIREWALL_PENDING_STATUS;
  user: any;
  teamActionType = TeamActionType;
  readonly ItransactionStatus = PendingTxnStatus;
  isNonCustodyOrgType: boolean = false;


  constructor(
    private data: DataService,
    private txnService: TransactionService,
    private modalService: NgbModal
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  ngOnInit(): void {
    this.isNonCustodyOrgType = isNonCustodyOrg(this.orgProfile)
  }

  getStatusText(txData: any) {
    return this.txnService.getStatusText(txData);
  }

  onMpcApprove(txReq: MpcTxData) {
    let status = this.getStatusText(txReq);
    if (status == "Signed") return;
    try {
      this.data.newMpcTxRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  isChainSupported(chain): boolean {
    if (getParentChain(chain) == "EVM" || getParentChain(chain) == "UTXO") {
      return true;
    }
    return false;
  }

  open() {
    this.onRejectTxn.emit(true);
  }

  showApproveMultisigButton(txData: any) {
    if (txData.signersList) {
      const walletSignerConfig =
        Number(txData.walletinfor.config.split("of")[0]) - 1;
      const approvedSigners = txData.signersList.filter(
        (ele) => ele.approved === true
      );
      const nonApprovedSigners = txData.signersList.filter(
        (ele) => ele.approved === false
      );

      if (approvedSigners.length == walletSignerConfig) {
        return false;
      } else if (
        approvedSigners.some((signer) => signer.email === this.user.email)
      ) {
        return false;
      } else if (
        nonApprovedSigners.some((signer) => signer.email === this.user.email)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  approveMultiSig(txReq: MpcTxData) {
    try {
      this.data.newMpcTxRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  openSpeedUpTxnModal(transactionData) {
    const modalRef = this.modalService.open(SpeedUpConfirmationPopupComponent, { centered: true });
    modalRef.componentInstance.component_for = "speed-up-transaction";
    modalRef.componentInstance.transactionData = transactionData;
  }

  onChangeTxState(txData: any): void {
    if (txData?.tx_state == "open") {
      txData.tx_state = "close";
    } else {
      txData.tx_state = "open";
    }
  }

  isInitiateRbfTxn(txData: any): boolean {
    return this.txnService.isInitiateRbfTxn(txData);
  }

  onViewFirewallTransactionClick(txData: any) {
    this.txnService.viewFirewallTransaction(txData, !this.isNonCustodyOrgType);
  }
}
