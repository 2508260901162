import { Component, Input, OnInit } from '@angular/core';
import { FirewallApprovalStatus } from '../firewall.utils';
import { UserModeEnum } from '../../../../entities/User';

@Component({
  selector: 'app-approval-details',
  templateUrl: './approval-details.component.html',
  styleUrls: ['./approval-details.component.scss']
})
export class ApprovalDetailsComponent implements OnInit {

  @Input() listOfMembers: any[] = [];
  @Input() requiredApprovals: Number;
  @Input() teamName: string;
  constructor() { }

  ngOnInit(): void {
    if (this.listOfMembers?.length) {
      this.listOfMembers = this.listOfMembers.filter((e) => e.userType !== UserModeEnum.MACHINE);
    }
  }

  isMemberApproved(member: any): boolean {
    if (member.approved) return member.approved;
    return FirewallApprovalStatus.APPROVED === member.approvalStatus;
  }

  isMemberRejected(member: any): boolean {
    return FirewallApprovalStatus.REJECTED === member.approvalStatus;
  }

  getApprovalStatus(member: any) {
    if (member.approved) {
      return member.approved ? "Approved" : "Pending"
    }
    switch (member.approvalStatus) {
      case FirewallApprovalStatus.APPROVED:
        return "Approved"
      case FirewallApprovalStatus.PENDING:
        return "Pending"
      case FirewallApprovalStatus.REJECTED:
        return "Rejected"
      default:
        break;
    }
  }

}
