import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { getParentChain, getWalletType } from "../../../helpers/WalletUtils";
import { orgType, CUSTODY_TYPE } from "../../../entities/members";
import { TransactionRequest } from "../../../entities";
import { DataService } from "../../../services/data.service";
import { TransactionService } from "../../../services/transaction.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpeedUpConfirmationPopupComponent } from "../../speed-up-transaction/speed-up-confirmation-popup/speed-up-confirmation-popup.component";
import { isNonCustodyOrg } from "../../../helpers/org.utils";

@Component({
  selector: "app-multisig-wallet-table-data, [multisig-wallet-table-data]",
  templateUrl: "./multisig-wallet-table-data.component.html",
  styleUrls: ["./multisig-wallet-table-data.component.scss"],
})
export class MultisigWalletTableDataComponent implements OnInit {
  @Input() txdata: any = {};
  @Input() pendingtransactions_component_for: string;
  @Input() orgProfile: any;
  @Input() uniqId: number;
  @Output() onRejectTxn = new EventEmitter<boolean>();

  orgType = orgType;
  custodyType = CUSTODY_TYPE;

  getWalletType = getWalletType;
  isExpanded: boolean = false;
  isNonCustodyOrgType: boolean = false;

  constructor(
    private modalService: NgbModal,
    private data: DataService,
    private txnService: TransactionService
  ) {}

  ngOnInit(): void {
    this.isNonCustodyOrgType = isNonCustodyOrg(this.orgProfile)
  }

  filterPolicySigner(data) {
    return data?.filter((e) => e?.userType == 1);
  }

  filterInitiatorsList(data) {
    return data?.filter((e) => e?.user_type == 1 && e?.userType == 1);
  }

  getRejectText(txData: TransactionRequest) {
    if (txData.status == 2 || txData.type === 2 ||  (txData.hasOwnProperty("isElegibleForRbf") && !txData["isElegibleForRbf"])) {
      return "";
    } else {
      return "Reject";
    }
  }

  isChainSupported(chain): boolean {
    if (getParentChain(chain) == "EVM" || getParentChain(chain) == "UTXO") {
      return true;
    }
    return false;
  }

  getStatusText(txData: any) {
    return this.txnService.getStatusText(txData);
  }

  onOpprove(txReq: TransactionRequest) {
    let status = this.getStatusText(txReq);
    if (status == "Signed") return;
    try {
      // console.log("Multi Signature Transaction", txReq);
      this.data.newTxRequest(txReq);
    } catch (error) {
      console.error(error);
    }
  }

  open() {
    this.onRejectTxn.emit(true);
  }

  openSpeedUpTxnModal(transactionData) {
    const modalRef = this.modalService.open(SpeedUpConfirmationPopupComponent, { centered: true });
    modalRef.componentInstance.component_for = "speed-up-transaction";
    modalRef.componentInstance.transactionData = transactionData;
  }

  onChangeTxState(txData: any): void {
    if (txData?.tx_state == "open") {
      txData.tx_state = "close";
    } else {
      txData.tx_state = "open";
    }
  }

  isInitiateRbfTxn(txData: any): boolean {
    return this.txnService.isInitiateRbfTxn(txData);
  }

  onViewFirewallTransactionClick(txData: any) {
    this.txnService.viewFirewallTransaction(txData, !this.isNonCustodyOrgType);
  }
}
