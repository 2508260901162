<div>
    <div class="modal-header">
        <h2 class="modal-title">
            <ng-container>Speed Up your transaction</ng-container>
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 confirmation-modal">
                <div class="img-logo">
                    <img src="../../../../assets/images/speed-transaction.svg" alt="speed-transaction.svg">
                </div>

                <div class="content">
                    <p class="body-font  mt-2">It seems your transaction is taking more than expected time to
                        confirm. You may replace this unconfirmed transaction with a new one that has a higher fee,
                        increasing the likelihood of faster confirmation.</p>
                    <p class="text-dark  mt-5">Note: While we cannot guarantee inclusion of your transaction in the
                        very next block, using Speed Up can significantly increase its chances for faster
                        confirmation.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100" (click)="openSpeedUpForm();">
            <span>Proceed</span>
        </button>
    </div>
</div>