"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Uint64 = exports.Uint53 = exports.Uint32 = exports.Int53 = exports.Decimal = void 0;
var decimal_1 = require("./decimal");
Object.defineProperty(exports, "Decimal", {
  enumerable: true,
  get: function () {
    return decimal_1.Decimal;
  }
});
var integers_1 = require("./integers");
Object.defineProperty(exports, "Int53", {
  enumerable: true,
  get: function () {
    return integers_1.Int53;
  }
});
Object.defineProperty(exports, "Uint32", {
  enumerable: true,
  get: function () {
    return integers_1.Uint32;
  }
});
Object.defineProperty(exports, "Uint53", {
  enumerable: true,
  get: function () {
    return integers_1.Uint53;
  }
});
Object.defineProperty(exports, "Uint64", {
  enumerable: true,
  get: function () {
    return integers_1.Uint64;
  }
});
