"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GrantQueueItem = exports.GrantAuthorization = exports.Grant = exports.GenericAuthorization = exports.protobufPackage = void 0;
/* eslint-disable */
const any_1 = require("../../../google/protobuf/any");
const timestamp_1 = require("../../../google/protobuf/timestamp");
const binary_1 = require("../../../binary");
const helpers_1 = require("../../../helpers");
exports.protobufPackage = "cosmos.authz.v1beta1";
function createBaseGenericAuthorization() {
  return {
    msg: ""
  };
}
exports.GenericAuthorization = {
  typeUrl: "/cosmos.authz.v1beta1.GenericAuthorization",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.msg !== "") {
      writer.uint32(10).string(message.msg);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenericAuthorization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.msg = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseGenericAuthorization();
    if ((0, helpers_1.isSet)(object.msg)) obj.msg = String(object.msg);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.msg !== undefined && (obj.msg = message.msg);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseGenericAuthorization();
    message.msg = object.msg ?? "";
    return message;
  }
};
function createBaseGrant() {
  return {
    authorization: undefined,
    expiration: undefined
  };
}
exports.Grant = {
  typeUrl: "/cosmos.authz.v1beta1.Grant",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.authorization !== undefined) {
      any_1.Any.encode(message.authorization, writer.uint32(10).fork()).ldelim();
    }
    if (message.expiration !== undefined) {
      timestamp_1.Timestamp.encode(message.expiration, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGrant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.authorization = any_1.Any.decode(reader, reader.uint32());
          break;
        case 2:
          message.expiration = timestamp_1.Timestamp.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseGrant();
    if ((0, helpers_1.isSet)(object.authorization)) obj.authorization = any_1.Any.fromJSON(object.authorization);
    if ((0, helpers_1.isSet)(object.expiration)) obj.expiration = (0, helpers_1.fromJsonTimestamp)(object.expiration);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.authorization !== undefined && (obj.authorization = message.authorization ? any_1.Any.toJSON(message.authorization) : undefined);
    message.expiration !== undefined && (obj.expiration = (0, helpers_1.fromTimestamp)(message.expiration).toISOString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseGrant();
    if (object.authorization !== undefined && object.authorization !== null) {
      message.authorization = any_1.Any.fromPartial(object.authorization);
    }
    if (object.expiration !== undefined && object.expiration !== null) {
      message.expiration = timestamp_1.Timestamp.fromPartial(object.expiration);
    }
    return message;
  }
};
function createBaseGrantAuthorization() {
  return {
    granter: "",
    grantee: "",
    authorization: undefined,
    expiration: undefined
  };
}
exports.GrantAuthorization = {
  typeUrl: "/cosmos.authz.v1beta1.GrantAuthorization",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.granter !== "") {
      writer.uint32(10).string(message.granter);
    }
    if (message.grantee !== "") {
      writer.uint32(18).string(message.grantee);
    }
    if (message.authorization !== undefined) {
      any_1.Any.encode(message.authorization, writer.uint32(26).fork()).ldelim();
    }
    if (message.expiration !== undefined) {
      timestamp_1.Timestamp.encode(message.expiration, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGrantAuthorization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.granter = reader.string();
          break;
        case 2:
          message.grantee = reader.string();
          break;
        case 3:
          message.authorization = any_1.Any.decode(reader, reader.uint32());
          break;
        case 4:
          message.expiration = timestamp_1.Timestamp.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseGrantAuthorization();
    if ((0, helpers_1.isSet)(object.granter)) obj.granter = String(object.granter);
    if ((0, helpers_1.isSet)(object.grantee)) obj.grantee = String(object.grantee);
    if ((0, helpers_1.isSet)(object.authorization)) obj.authorization = any_1.Any.fromJSON(object.authorization);
    if ((0, helpers_1.isSet)(object.expiration)) obj.expiration = (0, helpers_1.fromJsonTimestamp)(object.expiration);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.granter !== undefined && (obj.granter = message.granter);
    message.grantee !== undefined && (obj.grantee = message.grantee);
    message.authorization !== undefined && (obj.authorization = message.authorization ? any_1.Any.toJSON(message.authorization) : undefined);
    message.expiration !== undefined && (obj.expiration = (0, helpers_1.fromTimestamp)(message.expiration).toISOString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseGrantAuthorization();
    message.granter = object.granter ?? "";
    message.grantee = object.grantee ?? "";
    if (object.authorization !== undefined && object.authorization !== null) {
      message.authorization = any_1.Any.fromPartial(object.authorization);
    }
    if (object.expiration !== undefined && object.expiration !== null) {
      message.expiration = timestamp_1.Timestamp.fromPartial(object.expiration);
    }
    return message;
  }
};
function createBaseGrantQueueItem() {
  return {
    msgTypeUrls: []
  };
}
exports.GrantQueueItem = {
  typeUrl: "/cosmos.authz.v1beta1.GrantQueueItem",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.msgTypeUrls) {
      writer.uint32(10).string(v);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGrantQueueItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.msgTypeUrls.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseGrantQueueItem();
    if (Array.isArray(object?.msgTypeUrls)) obj.msgTypeUrls = object.msgTypeUrls.map(e => String(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.msgTypeUrls) {
      obj.msgTypeUrls = message.msgTypeUrls.map(e => e);
    } else {
      obj.msgTypeUrls = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBaseGrantQueueItem();
    message.msgTypeUrls = object.msgTypeUrls?.map(e => e) || [];
    return message;
  }
};
