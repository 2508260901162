import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExchangePromptMessages } from '../../../../shared/constants/exchange.const';
import { ExchangeService } from '../exchange.service';
import { HttpService } from '../../../../shared/services/http.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ErrorPrompt, SuccessPrompt } from '../../../../shared/components/custom-prompt/custom-prompt.service';

@Component({
  selector: 'app-update-keys',
  templateUrl: './update-keys.component.html',
  styleUrls: ['./update-keys.component.scss']
})
export class UpdateKeysComponent implements OnInit, OnDestroy {

  @Input() currentKeyData: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  dynamicFields = [];
  updateKeyForm: FormGroup;
  loading = false;
  showSkeleton = false;
  onDestroy$: Subject<void> = new Subject();
  readonly APIKEY = "apiKey";
  constructor(
    private fb: FormBuilder,
    private exchangeService: ExchangeService,
    private httpservice: HttpService,
  ) { }

  ngOnInit(): void {
    this.showSkeleton = true;
    this.setupForm();
  }

  setupForm() {
    this.updateKeyForm = this.fb.group({
      connectionName: [this.currentKeyData['name'], [Validators.required]],
    });

    this.httpservice.getAppsField(this.currentKeyData.appId).subscribe({
      next: (res) => {
        this.dynamicFields = res?.data || [];
        this.dynamicFields.forEach((field) => {
          field['visible'] = field.encrypted;
          this.updateKeyForm.addControl(field.keyName, this.fb.control(field.keyName === this.APIKEY ? this.currentKeyData['value'] : '', [Validators.required]));
        });
        this.showSkeleton = false;
      },
      error: (error) => {
        console.error(`error in fetching app fields in UI : ${error}`);
        this.showSkeleton = false;
      },
    });

  }

  exit() {
    this.close.emit('');
  }

  toggleVisibility(field: any): void {
    field.encrypted = !field.encrypted;
  }

  async submitUpdateForm() {
    this.loading = true;
    const payload = {
      connectionName: this.updateKeyForm.get('connectionName').value,
      exchangeName: this.currentKeyData.exchangeName,
      accountId: this.currentKeyData.id,
      keys: {}
    };

    this.dynamicFields.map((field) => {
      payload["keys"][field.keyName] = this.updateKeyForm.value[field.keyName];
    });

    this.exchangeService.updateAccountKeys(payload)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (result) => {
          if (result) {
            this.loading = false;
            this.showSuccessAlert();
          }
        },
        error: (err) => {
          this.loading = false;
          const message = err.code === "INVALID_CREDENTIALS" ? err?.message : "";
          this.showErrorAlert(message);
        }
      });

  }

  showSuccessAlert() {
    SuccessPrompt.fire({
      title: ExchangePromptMessages.EXCHANGE_KEYS_UPDATE_SUCCESS.title,
      text: ExchangePromptMessages.EXCHANGE_KEYS_UPDATE_SUCCESS.subTitle,
      confirmButtonText: ExchangePromptMessages.EXCHANGE_KEYS_UPDATE_SUCCESS.confirmTxt
    }, () => {
      this.close.emit('updatekey');
    })
  }

  showErrorAlert(text) {
    ErrorPrompt.fire({
      title: ExchangePromptMessages.EXCHANGE_KEYS_UPDATE_FAILED.title,
      text: text || ExchangePromptMessages.EXCHANGE_KEYS_UPDATE_FAILED.subTitle,
    }, () => {
      this.close.emit('');
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
