import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription, take } from 'rxjs';
import { SuccessPromptPayload, ErrorPromptPayload } from '../../constants/promptMessages';
import { TeamSigningType, TeamSigningSubType, TeamsV2Request, TeamsV2, Wallet } from '../../entities';
import { isCustodyOrg } from '../../helpers/org.utils';
import { AuthServiceJWT } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { MembersService } from '../../services/members.service';
import { TeamsService } from '../../services/teams.service';
import { SuccessPrompt, ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { OrgDetails } from '../../entities/walletRequest';
import { User } from '../../entities/User';
import { OrgMember } from '../../entities/members';
import { scopes } from '../../constants/authScopes';

type MemberItem = { id: number, displayName: string };

@Component({
  selector: 'app-edit-team-v2',
  templateUrl: './edit-team-v2.component.html',
  styleUrls: ['./edit-team-v2.component.scss']
})
export class EditTeamV2Component implements OnInit {

  @Input() orgProfile: OrgDetails;
  @Input() team: TeamsV2;
  @Output() teamEdited: EventEmitter<boolean> = new EventEmitter();

  // Enums intialisation for use down the line
  TeamSigningType = TeamSigningType;
  TeamSigningSubType = TeamSigningSubType;
  searchDropdownSettings: IDropdownSettings = {};
  members: OrgMember[];
  filteredMembers: OrgMember[];
  selectedMembers: MemberItem[] = [];
  user: User;
  wallets: (Wallet & {approvalsNeeded: number})[];
  erroredWallets = [];
  hasUpdateMembers: boolean = false;

  isLoading: boolean = false;
  status: string = '';
  errorMessage: string = ''

  private subscriptions: Subscription = new Subscription();

  constructor(
    private memberservice: MembersService,
    private data: DataService,
    public authService: AuthServiceJWT,
    private httpService: HttpService,
    private activeModal: NgbActiveModal,
    private teamService: TeamsService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.init();
  }

  ngOnInit(): void {
    this.memberservice.resetMember();
    this.searchDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      enableCheckAll: false,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: "",
    };
  }

  async init() {
    let profile = this.data.getUserProfile.getValue();
    await this.getMember(profile.organizations[0].id);
    this.wallets = await this.teamService.getWalletAssociatedWithTeam(this.team.id).toPromise();
    if (this.team.type === TeamSigningType.NON_SIGNING) {
      this.filteredMembers = this.members && this.members.length && [...this.members] || [];
    } else if (this.team.type === TeamSigningType.SIGNING) {
      this.filterMembersBySubType()
    }
  }

  async getMember(orgId) {
    let members = await this.httpService.getOrgMembers(orgId);
    this.members = members.members;
    this.members = this.members.filter((member) => member.userType == 1);
    this.filteredMembers = Array.isArray(this.members) ? [...this.members] : [];
    this.selectedMembers = this.team.teamMembers.map((item) => ({id: item.userId, displayName: item.user.displayName }))
  }

  filterMembersBySubType() {
    this.filteredMembers = this.members.filter((member) => {
      return member?.providers?.find((member) => member == "mobile_mpc");
    }).sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  onMemberSelect(_member: MemberItem) {
    // const signer = this.filteredMembers.find(member => member.id === _member.id)
    // this.selectedMembers.push(signer);
    this.onCheckConfig();
  }

  onMemberDeSelect(_member: MemberItem) {
    // this.selectedMembers = this.selectedMembers.filter(member => member.id !== _member.id);
    this.onCheckConfig();
  }


  onCheckConfig() {
    this.hasUpdateMembers = false;
    this.erroredWallets = [];
    for (const wallet of this.wallets) {
      const count = this.selectedMembers.length;
      const maxInConfig = wallet.approvalsNeeded;
      if (count < Number(maxInConfig)) {
        this.erroredWallets.push({
          id: wallet.id,
          name: wallet.name,
          count: Number(maxInConfig)
        })
      }
    }
    if (this.selectedMembers.length !== this.team.teamMembers.length) {
      this.hasUpdateMembers = true;
    } else {
      this.team.teamMembers.forEach((member) => {
        const exists = this.selectedMembers.find((m) => m.id === member.userId);
        if (!exists) {
          this.hasUpdateMembers = true;
        }
      });
    }
  }

  get getMembers() {
    return this.members?.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  async submitTeam() {
    console.log(this.selectedMembers, "===== Submitting Data =====")
    this.authService.writeAccess(scopes.TeamUpdate).pipe(take(1)).subscribe(async (token) => {
      try {
        this.isLoading = true;
        const data  = await this.teamService.editTeam({
          teamId: this.team.id,
          memberIds: this.selectedMembers.map((m) => m.id)
        }, token).toPromise();
        if (data.success) {
          this.status = 'success';
          SuccessPrompt.fire(SuccessPromptPayload.TEAM_UPDATED,() => {
            // Additional actions after modal closed
            this.resetAndCloseModal();
          });
          // Emit save
          this.teamEdited.emit(true);
        } else {
          this.errorMessage = 'Failed to update team. Please try again.'
          this.status = 'error';
          const errorPayload = ErrorPromptPayload.TEAM_UPDATED_FAILED;
          errorPayload.text = data.message;
          ErrorPrompt.fire(errorPayload,() => {
            this.resetAndCloseModal();
          });
        }
      } catch (err) {
        const error = err?.error;
        this.errorMessage = error.message || 'Failed to update team. Please try again.';
        this.status = 'error';
        const errorPayload = ErrorPromptPayload.TEAM_UPDATED_FAILED;
        errorPayload.text = error.message || 'Failed to update team. Please try again.';
        ErrorPrompt.fire(errorPayload,() => {
          this.resetAndCloseModal();
        });    
      } finally {
        this.isLoading = false;
      }
    });
  }

  isCustodyOrg(): boolean {
    return isCustodyOrg(this.orgProfile)
  }

  resetAndCloseModal() {
    this.activeModal.dismiss('Cross click');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
