"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LedgerSigner = void 0;
const amino_1 = require("@cosmjs/amino");
const ledgerconnector_1 = require("./ledgerconnector");
class LedgerSigner {
  constructor(transport, options = {}) {
    this.hdPaths = options.hdPaths || [(0, amino_1.makeCosmoshubPath)(0)];
    this.connector = new ledgerconnector_1.LedgerConnector(transport, options);
  }
  async getAccounts() {
    if (!this.accounts) {
      const pubkeys = await this.connector.getPubkeys();
      this.accounts = await Promise.all(pubkeys.map(async pubkey => ({
        algo: "secp256k1",
        address: await this.connector.getCosmosAddress(pubkey),
        pubkey: pubkey
      })));
    }
    return this.accounts;
  }
  /**
   * Shows the user's address in the device and returns an address/pubkey pair.
   *
   * The address will be shown with the native prefix of the app (e.g. cosmos, persistence, desmos)
   * and does not support the usage of other address prefixes.
   *
   * @param path The HD path to show the address for. If unset, this is the first account.
   */
  async showAddress(path) {
    return this.connector.showAddress(path);
  }
  async signAmino(signerAddress, signDoc) {
    const accounts = this.accounts || (await this.getAccounts());
    const accountIndex = accounts.findIndex(account => account.address === signerAddress);
    if (accountIndex === -1) {
      throw new Error(`Address ${signerAddress} not found in wallet`);
    }
    const message = (0, amino_1.serializeSignDoc)(signDoc);
    const accountForAddress = accounts[accountIndex];
    const hdPath = this.hdPaths[accountIndex];
    const signature = await this.connector.sign(message, hdPath);
    return {
      signed: signDoc,
      signature: (0, amino_1.encodeSecp256k1Signature)(accountForAddress.pubkey, signature)
    };
  }
}
exports.LedgerSigner = LedgerSigner;
