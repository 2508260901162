import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.scss']
})

export class DateSelectionComponent {
  @Input() placeholder: string = 'Choose Date';
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  private _selectedDate: NgbDateStruct;
  @Input() 
  set selectedDate(date: NgbDateStruct | Date) {
    if (date instanceof Date) {
      this._selectedDate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    } else {
      this._selectedDate = date;
    }
  }
  get selectedDate(): NgbDateStruct | Date {
    return this._selectedDate;
  }  
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Output() dateChange: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();


  constructor() { }

  onDateChange(newDate: NgbDateStruct) {
    this._selectedDate = newDate;
    this.dateChange.emit(newDate);
  }

  clearDate() {
    const clearDateValue = null as unknown as NgbDateStruct;
    this._selectedDate = clearDateValue;
    this.dateChange.emit(clearDateValue);
  }
}
