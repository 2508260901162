import { Injectable } from '@angular/core';
import { ErrorPrompt, SuccessPrompt } from '../components/custom-prompt/custom-prompt.service';
import { SuccessPromptPayload } from '../constants/promptMessages';
import { UserRoleEnum } from '../entities/members';
import { AuthServiceJWT } from './auth.service';
import { HttpService } from './http.service';
import { NoShowError, ErrorMsgs } from '../entities/Error';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { scopes } from '../constants/authScopes';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userDataSubject = new BehaviorSubject<any>(null);
  userData = this.userDataSubject.asObservable();

  constructor(
    private auth: AuthServiceJWT,
    private httpService: HttpService
  ) {}

  async updateUserRole(orgId: number, userId: number, userRole: UserRoleEnum): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth.writeAccess(scopes.UserRoleUpdate).subscribe({
        next: async (accessToken) => {
          let response: any = await this.httpService
            .updateOrgUserRole(orgId, userId, userRole, accessToken)
            .toPromise()
            .catch((err) => {
              console.error('Failed to update the user type', err);
              reject({ success: false, message: err.message });
            });
          if (response.success === true) {
            SuccessPrompt.fire(SuccessPromptPayload.USER_ROLE_CHANGE);
          } else {
            ErrorPrompt.fire({
              icon: 'error',
              title: 'Failed to update the user type',
              text: response.message,
              showConfirmButton: false,
            });
          }
          resolve(response);
        },
        error: (error) => {
          if (error.message === ErrorMsgs.POPUP_CLOSED) {
            reject(new NoShowError('User denied transaction signing', error));
          } else {
            reject({ success: false, message: 'Failed to update the user type' });
          }
        },
      });
    });
  }
  /**
   * Retrieves user data by user ID.
   * @param userId - The ID of the user.
   */
  getUserData(userId: number) {
    this.httpService.getUserById(userId).subscribe((userData) => {
      this.userDataSubject.next(userData);
    });
  }
}
