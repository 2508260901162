"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Any = exports.protobufPackage = void 0;
/* eslint-disable */
const binary_1 = require("../../binary");
const helpers_1 = require("../../helpers");
exports.protobufPackage = "google.protobuf";
function createBaseAny() {
  return {
    typeUrl: "",
    value: new Uint8Array()
  };
}
exports.Any = {
  typeUrl: "/google.protobuf.Any",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.typeUrl !== "") {
      writer.uint32(10).string(message.typeUrl);
    }
    if (message.value.length !== 0) {
      writer.uint32(18).bytes(message.value);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAny();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.typeUrl = reader.string();
          break;
        case 2:
          message.value = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseAny();
    if ((0, helpers_1.isSet)(object.typeUrl)) obj.typeUrl = String(object.typeUrl);
    if ((0, helpers_1.isSet)(object.value)) obj.value = (0, helpers_1.bytesFromBase64)(object.value);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.typeUrl !== undefined && (obj.typeUrl = message.typeUrl);
    message.value !== undefined && (obj.value = (0, helpers_1.base64FromBytes)(message.value !== undefined ? message.value : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBaseAny();
    message.typeUrl = object.typeUrl ?? "";
    message.value = object.value ?? new Uint8Array();
    return message;
  }
};
