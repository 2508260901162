<div class="modal-custom modal-slide-out firewall-slider"
     *ngIf="componentFor == 'send-receive'">
    <div class="modal-header">
        <div class="d-inline">
            <h2 class="modal-title pl-2">{{title}}</h2>
        </div>
        <div class="d-flex align-items-center">
            <ng-container [ngTemplateOutlet]="options"></ng-container>
            <button type="button"
                    aria-label="Close"
                    class="close"
                    (click)="closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
    </div>
    <div class="modal-body reduce-height">
        <ng-container *ngIf="!isLoading">
            <div class="details-section"
                *ngIf="transactionDetails && transactionDetails.length > 0;">
                <div class="d-flex top-container">
                    <div class="flex-1">Transaction Details</div>
                    <div class="status word-breakdiv">
                        <ng-container *ngIf="selectedTransactionData.msgToSign && !selectedTransactionData?.source && !isLivenessTransactionExists && selectedTransactionData?._mpcTxType">
                            <div class="progress-section" *ngIf="!selectedTransactionData.txn_req_id && selectedTransactionData.status === ItransactionStatus.Pending">
                                <span class="">Initiating</span>
                                <ngb-progressbar class="my-1 progress-bar int-sign-progress"
                                                    [value]="selectedTransactionData.team.initiatorsSigns"
                                                    [max]="selectedTransactionData.team.initiators"
                                                    height="5px"></ngb-progressbar>
                                <span class="d-flex text-dark"><b class="font-size-12">{{selectedTransactionData.team.initiators -
                                        selectedTransactionData.team.initiatorsSigns}}</b> <span class="font-size-12 ml-1">Remaining</span></span>
                            </div>
                            <div class="progress-section" *ngIf="selectedTransactionData.txn_req_id && (selectedTransactionData.status === ItransactionStatus.Pending || selectedTransactionData.status === ItransactionStatus.ExchangeApprovalPending)">
                                <span class="">{{selectedTransactionData.walletinfor.type === EXCHANGE ? teamActionType.APPROVAL : teamActionType.SIGNING}}</span>
                                <ngb-progressbar class="my-1 progress-bar int-sign-progress"
                                                    [value]="selectedTransactionData.team?.signersSigns"
                                                    [max]="selectedTransactionData.team?.signers"
                                                    height="5px"></ngb-progressbar>
                                <span class="d-flex text-dark"
                                        *ngIf="selectedTransactionData.team?.signers"><b class="font-size-12">{{selectedTransactionData.team?.signers -
                                        selectedTransactionData.team?.signersSigns}}</b> <span class="font-size-12 ml-1">Remaining</span></span>
                            </div>
                            <div class="progress-section" *ngIf="selectedTransactionData.status === ItransactionStatus.Completed">
                                <span class="">Pending</span>
                                <ngb-progressbar class="my-1  progress-bar pending-progress"
                                                    [value]="100"
                                                    [max]="100"
                                                    height="5px"></ngb-progressbar>
                                <span class="text-dark font-size-12">All Signed</span>
                            </div>
                        </ng-container>
                        <ng-container  *ngIf="selectedTransactionData?.walletinfor && selectedTransactionData?._txType == 'multisig_tx'">
                            <div  class="progress-section" *ngIf="selectedTransactionData.status === ItransactionStatus.Pending">
                                <span class="">Signing</span>
                                <ngb-progressbar class="my-1 progress-bar int-sign-progress"
                                                    [value]="selectedTransactionData._signersSigns"
                                                    [max]="(selectedTransactionData.walletinfor.type.toLowerCase() == 'multisig_shield' || selectedTransactionData.walletinfor.type.toLowerCase() == 'gnosis') ? selectedTransactionData._signers - 1 : selectedTransactionData._signers"
                                                    height="5px"></ngb-progressbar>
                                <span class="d-flex">
                                    <b class="font-size-12">
                                        {{ 
                                            (selectedTransactionData.walletinfor.type.toLowerCase() == 'multisig_shield' || selectedTransactionData.walletinfor.type.toLowerCase() == 'gnosis') 
                                            ? selectedTransactionData._signers - selectedTransactionData._signersSigns - 1
                                            : selectedTransactionData._signers - selectedTransactionData._signersSigns
                                        }}
                                    </b>
                                    <span class="font-size-12 ml-1 text-dark">Remaining</span></span>
                            </div>
                            <div class="progress-section" *ngIf="selectedTransactionData.status === ItransactionStatus.Completed">
                                <span class="">Pending</span>
                                <ngb-progressbar class="my-1  progress-bar pending-progress"
                                                    [value]="100"
                                                    [max]="100"
                                                    height="5px"></ngb-progressbar>
                                <span class="font-size-12 text-dark">All Signed</span>
                            </div>
                        </ng-container>
                        <div class="progress-section" *ngIf="!selectedTransactionData.txn_req_id && IFirewallCheckStatus.FIREWALL_PENDING_STATUS === selectedTransactionData.status">
                            <span>Pending</span>
                            <ngb-progressbar class="my-1 progress-bar int-sign-progress"
                                                max="100"
                                                value="50"
                                                height="5px"></ngb-progressbar>
                            <span *ngIf="selectedTransactionData.livenessPending" class="d-flex text-dark font-size-12">Liveness Check</span>
                            <span *ngIf="!selectedTransactionData.livenessPending && pendingFirewallApproval > 0" class="d-flex text-dark font-size-12">{{pendingFirewallApproval}} Remaining</span>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="accordion; context:{ header: { content: transactionDetails, startIdx: 0, endIdx: 2 }, body: { content: detailsAccordionBody }, selectedAccordionTab: accordionTabs.DETAILS, displayOpen: true}"></ng-container>
                <ng-template  #detailsAccordionBody>
                    <app-txn-content [content]="transactionDetails" [startIdx]="2"></app-txn-content>
                </ng-template>
            </div>
            <ng-container>
                <div class="details-section"
                    *ngIf="firewallDetails && firewallDetails.length > 0 ">
                    <div class="d-flex top-container">
                        <div class="flex-1">Firewall</div>
                    </div>
                    <ng-container *ngTemplateOutlet="accordion; context:{ header: { content: firewallDetails, startIdx: 0, endIdx: 2 }, body: { content: firewallAccordionBody }, selectedAccordionTab: accordionTabs.FIREWALL, displayOpen: firewallDetails.length > 2 }"></ng-container>
                    <ng-template  #firewallAccordionBody>
                        <ng-container *ngIf="firewallDetails && firewallDetails.length > 0; else noDataToShow">
                            <app-txn-content [content]="firewallDetails"
                                            [startIdx]="2"
                                            classnames="row-1-2"></app-txn-content>
                        </ng-container>
                    </ng-template>
                </div>
                <div class="details-section"
                    *ngIf="complianceDetails && complianceDetails.length > 0">
                    <ng-container *ngTemplateOutlet="accordion; context:{ header: { title: 'Compliance' }, body: { content: complianceAccordionBody, startIdx: 2 }, selectedAccordionTab: accordionTabs.COMPLIANCE, displayOpen: true}"></ng-container>
                    <ng-template #complianceAccordionBody>
                        <ng-container *ngIf="complianceDetails && complianceDetails.length > 0; else noDataToShow">
                            <ng-container *ngFor="let complianceItem of complianceDetails; let i = index">
                                <app-txn-content [content]="complianceItem"
                                                columns="3"
                                                [addSeparator]="i > 0"></app-txn-content>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </div>
            </ng-container>

            <!-- These are the Tabs shown for (INITIATORS, FIREWALL APPROVERS, SIGNERS) -->
            <div class="mt-5 approvals" *ngIf="showApprovalTabs()">
                <ng-container *ngIf="selectedTransactionData?.signers?.length|| members.length">
                    <ul ngbNav
                    #nav="ngbNav"
                    class="nav-tabs"
                    [(activeId)]="selectedTab">
                    <!-- Initiation Tab (NA for Receive) -->
                    <li ngbNavItem
                        [ngbNavItem]="approvalTabs.INITIATION"
                        *ngIf="!isReceiveTransaction && selectedTransactionData?.initiatorsList">
                        <a ngbNavLink
                            (click)="selectTab(approvalTabs.INITIATION)">Initiation</a>
                            <ng-template ngbNavContent>
                                <app-approval-details [teamName]="selectedTransactionData?.initiatorsList[0]?.teamName"
                                                    [requiredApprovals]="getQuorumConfig(selectedTransactionData?.team?.initiator_config)"
                                                    [listOfMembers]="selectedTransactionData?.initiatorsList"></app-approval-details>
                                </ng-template>
                    </li>

                    <!-- Firewall Approval Tab -->
                    <li *ngIf="firewallDetails.length > 0 && (uniqueApprovals?.length || isTransactionBlocked() ||  isTransactionAutoApproved())"
                        ngbNavItem
                        [ngbNavItem]="approvalTabs.FIREWALL_APPROVAL">
                        <a ngbNavLink
                            (click)="selectTab(approvalTabs.FIREWALL_APPROVAL)">Approval</a>

                        <ng-template ngbNavContent>
                            <ng-container *ngIf="!isTransactionBlocked() && !isTransactionAutoApproved()">
                                <span *ngFor="let team of uniqueApprovals">
                                    <app-approval-details [requiredApprovals]="team.approvalsNeeded"
                                                            [teamName]="team.teamName"
                                                            [listOfMembers]="team?.members"></app-approval-details>
                                </span>
                            </ng-container>

                            <span class="font-weight-500 font-size-16"
                                    *ngIf="isTransactionBlocked()">The transaction was blocked by firewall rules</span>
                            <span class="font-weight-500 font-size-16"
                                    *ngIf="isTransactionAutoApproved()">The transaction was auto-approved by firewall rules</span>
                        </ng-template>

                    </li>

                    <!-- Signing Tab (NA for Receive)-->
                    <li ngbNavItem
                        [ngbNavItem]="approvalTabs.SIGNING"
                        *ngIf="!isCustodyOrg && !isReceiveTransaction && selectedTransactionData?.signersList">
                        <a ngbNavLink
                            (click)="selectTab(approvalTabs.SIGNING)">{{selectedTransactionData.walletinfor.type === EXCHANGE ? teamActionType.APPROVAL : teamActionType.SIGNING}}</a>
                        <ng-template ngbNavContent>
                            <app-approval-details [requiredApprovals]="getQuorumConfig(selectedTransactionData?.team?.signer_config)"
                                                    [listOfMembers]="selectedTransactionData?.signersList"
                                                    [teamName]="selectedTransactionData?.signersList[0].teamName"></app-approval-details>
                        </ng-template>  
                    </li>
                    
                    <li ngbNavItem
                        [ngbNavItem]="approvalTabs.MEMBERS"
                        *ngIf="!isReceiveTransaction && members.length">
                        <a ngbNavLink (click)="selectTab(approvalTabs.MEMBERS)">Members</a>
                        <ng-template ngbNavContent>
                            <app-approval-details [listOfMembers]="members"
                                                    [teamName]="members[0]?.teamName"></app-approval-details>
                        </ng-template>  
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav"class="mt-2"></div>
                </ng-container>
            </div>

            <!-- These are shown specifically for custody when there are no firewall details -->
            <div class="mt-5" *ngIf="isCustodyOrg && firewallDetails.length === 0">
                <ng-container class="mt-5" >
                    <app-approval-details [teamName]="selectedTransactionData?.initiatorsList[0]?.teamName"
                        [requiredApprovals]="getQuorumConfig(selectedTransactionData?.team?.initiator_config)"
                        [listOfMembers]="selectedTransactionData?.initiatorsList"></app-approval-details>
                </ng-container>
            </div>

        </ng-container>
        <ng-container *ngIf="isLoading">
            <div class="d-flex align-items-center justify-content-between skelton-bar">
                <ngx-skeleton-loader></ngx-skeleton-loader>
                <ngx-skeleton-loader ></ngx-skeleton-loader>
            </div>
            <div class="mt-2">
                <ngx-skeleton-loader [theme]="{height: '100px'}"></ngx-skeleton-loader>
            </div>
            <div class="mt-2">
                <ngx-skeleton-loader [theme]="{height: '70px'}"></ngx-skeleton-loader>
            </div>
            <div class="mt-2">
                <ngx-skeleton-loader [theme]="{height: '70px'}"></ngx-skeleton-loader>
            </div>
            <div class="d-flex align-items-center skelton-bar mt-4 mb-4">
                <ngx-skeleton-loader></ngx-skeleton-loader>
                <ngx-skeleton-loader ></ngx-skeleton-loader>
                <ngx-skeleton-loader ></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader></ngx-skeleton-loader>
        </ng-container>
    </div>
    <div class="modal-footer">
        <!-- Firewall Approve / Reject buttons -->
        <div class="d-flex w-100" *ngIf="IFirewallCheckStatus.FIREWALL_PENDING_STATUS === selectedTransactionData.status && selectedTab === approvalTabs.FIREWALL_APPROVAL && !isApprovalOrRejectionDone && isCurrentUserPartOfApprovers">
            <div class="d-flex w-100" *ngIf="!isTransactionBlocked() && !isTransactionAutoApproved()">
                <button type="button"
                        class="btn btn-danger col-md-6 mr-1"
                        [disabled]="isApprovalInProgress || isRejectionInProgress"
                        (click)="openModel(quarantineComment)">
                    <div *ngIf="isRejectionInProgress"
                         class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isRejectionInProgress">{{ isReceiveTransaction ? "Quarantine" :"Reject"}}</span>
                </button>
                <button type="button"
                        class="btn btn-primary col-md-6 ml-1"
                        [disabled]="isRejectionInProgress || isApprovalInProgress"
                        (click)="approveOrRejectTransaction(true)">
                    <div *ngIf="isApprovalInProgress"
                         class="loader-box">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <span *ngIf="!isApprovalInProgress">Approve</span>
                </button>
            </div>
        </div>

        <!-- Signing Phase Buttons for Multisig Wallets -->
        <div class="d-flex w-100" *ngIf="selectedTab === approvalTabs.SIGNING && showMultisigSignerButtons()">
            <div class="d-flex w-100">
                <button *ngIf="showApproveMultisigButton(selectedTransactionData)"
                        class="btn btn-primary col-md-6 mr-1"
                        type="submit"
                        (click)="approveMultiSig(selectedTransactionData)">Approve</button>
                <button class="btn btn-danger col-md-6 ml-1"
                        data-original-title="Cancel transaction"
                        title="Cancel transaction"
                        type="submit"
                        *ngIf="getRejectText()"
                        (click)="onReject()">{{ getRejectText() }}</button>
            </div>
        </div>

        <!-- Initiation Phase Functionalities -->
        <div class="d-flex w-100" *ngIf="showInitiationPhaseButtons()">
            <ng-container *ngIf="selectedTransactionData.msgToSign && !selectedTransactionData?.source && selectedTransactionData?._mpcTxType" [ngTemplateOutlet]="mpcOptions"></ng-container>
            <ng-container  *ngIf="selectedTransactionData?.walletinfor && selectedTransactionData?._txType == 'multisig_tx'" [ngTemplateOutlet]="multisigOptions"></ng-container>
            <ng-container *ngIf="selectedTransactionData?.source=='mmi' || selectedTransactionData?.source=='walletconnect'" [ngTemplateOutlet]="walletconnect"></ng-container>
        </div>
        <div class="d-flex w-100" *ngIf="selectedTransactionData.status === ItransactionStatus.Completed && (selectedTab === approvalTabs.INITIATION || selectedTab === approvalTabs.MEMBERS)">
            <ng-container *ngIf="selectedTransactionData.actionType == 'travel-rule'" [ngTemplateOutlet]="travelRule"></ng-container>
        </div>
        <div class="d-flex w-100" *ngIf="selectedTransactionData.status === ItransactionStatus.ExchangeApprovalPending">
            <ng-container [ngTemplateOutlet]="exchangeTxn"></ng-container>
        </div>
    </div>
    <ng-template #noDataToShow>
        <p class="d-flex flex-1 justify-content-center">No data available to show.</p>
    </ng-template>
</div>

<ng-template #quarantineComment
             let-modal>
    <div class="modal-body text-center unquarantine-comment">
        <div>
            <svg width="58"
                 height="58"
                 viewBox="0 0 58 58"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M28.9987 53.1666C42.2904 53.1666 53.1654 42.2916 53.1654 29C53.1654 15.7083 42.2904 4.83331 28.9987 4.83331C15.707 4.83331 4.83203 15.7083 4.83203 29C4.83203 42.2916 15.707 53.1666 28.9987 53.1666Z"
                      stroke="#F7931A"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                <path d="M29 19.3333V31.4167"
                      stroke="#F7931A"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                <path d="M28.9863 38.6667H29.008"
                      stroke="#F7931A"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
            </svg>

        </div>
        <div class="mt-4">
            <span class="text-center font-size-16 font-weight-bold">{{ isReceiveTransaction ? "Quarantine" :"Reject"}}
                the transaction?</span>
        </div>
        <div class="mt-2 mb-4 textbox">
            <textarea class="form-control search-custom mt-1"
                      rows="4"
                      placeholder="Explain the reason"
                      [(ngModel)]="commentQurantined"></textarea>
        </div>
        <div class="d-flex">
            <button type="submit"
                    [disabled]="isRejectionInProgress"
                    [ngClass]="{'disabled': isRejectionInProgress}"
                    class="btn btn-danger w-50 mr-2"
                    (click)="modal.dismiss('Cross click');">
                <span>Close</span>
            </button>
            <button type="submit"
                    [disabled]="isRejectionInProgress || !commentQurantined"
                    [ngClass]="{'disabled': isRejectionInProgress || !commentQurantined}"
                    class="btn btn-primary w-50 ml-2"
                    (click)="approveOrRejectTransaction(false)">
                <div *ngIf="isRejectionInProgress"
                     class="loader-box">
                    <div class="loader-15 bg-white"></div>
                </div>
                <span *ngIf="!isRejectionInProgress">Proceed</span>
            </button>
        </div>

    </div>
</ng-template>

<ng-template #options>
    <button class="btn btn-view-only btn-round btn-sm" title="View only"
        *ngIf="selectedTransactionData.walletinfor.access == 'READ' || selectedTransactionData.walletinfor.access == 'VIEWER'">
        View only</button>
    <button class="btn btn-view-only btn-round font-size-12" title="Mobile only"
        *ngIf="(selectedTransactionData.walletinfor.access == 'SIGNER' ) && selectedTransactionData.txn_req_id && selectedTransactionData?.walletinfor?.type === 'mpc'">
        Mobile only
    </button>
</ng-template>

<ng-template #multisigOptions>
    <ng-container *ngIf="selectedTransactionData.walletinfor.access == 'SIGNER' &&  selectedTransactionData.status === ItransactionStatus.Pending && checkMultiSigRemaining()">
         <button class="btn btn-danger flex-1 mr-2"
                data-original-title="Cancel trsansaction"
                title="Cancel transaction"
                type="submit"
                *ngIf="getRejectText()"
                (click)="onReject()">{{ getRejectText() }} </button>
        <ng-container 
            *ngIf="selectedTransactionData.metadata && 
                    selectedTransactionData.mysign == false && 
                    selectedTransactionData.status == ItransactionStatus.Pending && 
                    selectedTransactionData.tokenType != 2;
                    else approveSecond">
            <app-staking-approval-popup [component_for]="'approvestaking'" [stakingApproveData]="selectedTransactionData"> </app-staking-approval-popup>
        </ng-container>
        <ng-template #approveSecond>
            <button *ngIf="selectedTransactionData.status === ItransactionStatus.Pending && getStatusText(selectedTransactionData)"
                    class="btn btn-primary flex-1"
                    type="submit"
                    (click)="onApproveMultisig()"> {{ getStatusText(selectedTransactionData) }}</button>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #mpcOptions>
    <ng-container *ngIf="(selectedTransactionData.walletinfor.access == 'SIGNER' || selectedTransactionData.walletinfor.access == 'INITIATOR') && !selectedTransactionData.txn_req_id">
        <ng-container *ngFor="let element of txnInitiators">
            <div class="d-flex w-100" *ngIf="element.email == user.email && element.user_type == 1">
                <button appDisableClick
                        class="btn btn-danger flex-1 mr-2"
                        data-original-title="Cancel trsansaction"
                        title="Cancel transaction"
                        type="submit"
                        (click)="onReject()">Reject</button>
                <button appDisableClick
                        class="btn btn-primary flex-1 mr-2"
                        *ngIf="element.approved === false"
                        type="submit"
                        (click)="onMpcApprove()">Approve</button>
                        <app-liveness-kyc *ngIf="selectedTransactionData.livenessPending && element.id === selectedTransactionData.livenessPendingUserId"       
                        component_for="verify-liveness-check"
                        [identifier]="selectedTransactionData.sequenceId"></app-liveness-kyc>
            </div>
        </ng-container>
    </ng-container> 
    <!-- V2 Multisig wallets signing -->
    <ng-container *ngIf="selectedTransactionData?.walletinfor?.access == 'SIGNER' && selectedTransactionData?.txn_req_id && isV2MultisigWallet(selectedTransactionData?.walletinfor)">
        <ng-container *ngFor="let element of txnSigners">
            <div class="d-flex w-100" *ngIf="element.email == user.email && element.user_type == 2">
                 <button class="btn btn-danger flex-1 mr-2"
                        data-original-title="Cancel trsansaction"
                        title="Cancel transaction"
                        type="submit"
                        (click)="onReject()">Reject</button>
                <button *ngIf="selectedTransactionData.status === ItransactionStatus.Pending && showApproveMultisigButton(selectedTransactionData)"
                        class="btn flex-1 btn-primary"
                        type="submit"
                        (click)="approveMultiSig(selectedTransactionData)">Sign</button>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #walletconnect>
    <ng-container *ngIf="(selectedTransactionData.walletinfor.access == 'SIGNER' || selectedTransactionData.walletinfor.access == 'INITIATOR') && selectedTransactionData.status === ItransactionStatus.Pending">
        <ng-container *ngFor="let element of txnInitiators">
            <div class="d-flex w-100" *ngIf="element.email == user.email && element.user_type == 1">
                <button appDisableClick
                       class="btn btn-danger flex-1 mr-2"
                        data-original-title="Cancel trsansaction"
                        title="Cancel transaction"
                        type="submit"
                        (click)="onReject()">Reject</button>
                <button appDisableClick
                        class="btn flex-1 btn-primary"
                        *ngIf="element.approved === false"
                        type="submit"
                        (click)="onMessageSignApprove()">Approve</button>
                
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #travelRule>
    <ng-container *ngFor="let element of members">
        <div class="d-flex w-100" *ngIf="element.email == user.email && element.userType == 1">
             <button appDisableClick
                class="btn btn-danger flex-1 mr-2" data-original-title="Cancel transaction"
                title="Cancel transaction" 
                type="submit" 
                (click)="onReject()">Reject</button>
            <button appDisableClick 
                class="btn flex-1 btn-primary"
                type="submit" 
                (click)="initiateTravelRule()" 
                *ngIf="selectedTransactionData.status === ItransactionStatus.Completed">Approve</button>
           
        </div>
    </ng-container>
</ng-template>
<ng-template #exchangeTxn>
    <ng-container
        *ngIf="(selectedTransactionData.walletinfor.access == 'SIGNER' || (selectedTransactionData.walletinfor.isSigner && selectedTransactionData.walletinfor.isInitiator)) && selectedTab === approvalTabs.SIGNING">
        <ng-container *ngFor="let element of txnSigners">
            <div class="d-flex w-100" *ngIf="element.email == user.email && element.user_type == 2">
                <button appDisableClick class="btn btn-danger flex-1 mr-2" data-original-title="Cancel trsansaction"
                    title="Cancel transaction" type="submit" (click)="onReject()">Reject</button>
                <button appDisableClick class="btn btn-primary flex-1 mr-2" *ngIf="element.approved === false"
                    type="submit" (click)="onMpcApprove()">Approve</button>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #accordion let-header="header" let-body="body" let-selectedAccordionTab="selectedAccordionTab" let-displayOpen="displayOpen">
    <div class="accordion-container">
        <div class="accordion-header">
            <button class="btn d-flex w-100 justify-content-between" (click)="onClickAccordion(selectedAccordionTab, displayOpen)">
                <div class="accordion-title-row" >
                    <span *ngIf="!header.content" class="m-0 font-size-14 font-weight-500 accordion-title">{{header.title}}</span>
                    <ng-container *ngIf="header.content">
                        <app-txn-content [content]="header.content" [startIdx]="header.startIdx" [endIdx]="header.endIdx"></app-txn-content>
                    </ng-container>
                </div>
                <img *ngIf="displayOpen && selectedAccrodion === selectedAccordionTab" class="accordion-panel-icon" src="../../../../../assets/images/up_arrow.svg" alt="down_arrow" />
                <img *ngIf="displayOpen && selectedAccrodion !== selectedAccordionTab" class="accordion-panel-icon" src="../../../../../assets/images/down_arrow.svg" alt="down_arrow" />
            </button>
        </div>
        <div class="accordion-body" *ngIf="selectedAccrodion === selectedAccordionTab" [ngClass]="selectedAccrodion === selectedAccordionTab ? 'is--opened' : 'is--closed'">
            <div class="card-body">
                <!-- {{body.content}} -->
                 <ng-container [ngTemplateOutlet]="body.content"></ng-container>
            </div>
        </div>
    </div>
</ng-template>




































<!-- <div class="details-section" *ngIf="!isReceiveTransaction">
             <ngb-accordion [activeIds]="selectedAccrodion" (panelChange)="selectedAccrodion = accordionTabs.MORE_DETAILS">
                <ngb-panel [id]="accordionTabs.MORE_DETAILS" >
                    <ng-template ngbPanelHeader
                                 let-opened="opened">
                        <button ngbPanelToggle
                                class="btn w-100 d-flex justify-content-between">
                            <span class="m-0 font-size-14 font-weight-50 accordion-title">More Details</span>
                            <img *ngIf="!opened"
                                 class="accordion-panel-icon"
                                 src="../../../../../assets/images/down_arrow.svg"
                                 alt="down_arrow" />
                            <img *ngIf="opened"
                                 class="accordion-panel-icon"
                                 src="../../../../../assets/images/up_arrow.svg"
                                 alt="down_arrow" />
                        </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ng-container *ngIf="moreDetails && moreDetails.length > 0; else noDataToShow">
                            <app-txn-content [content]="moreDetails" classnames="row-2"></app-txn-content>
                        </ng-container>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div> -->