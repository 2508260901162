"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IMultiServiceProvider = void 0;
const provider_1 = require("./provider");
class IMultiServiceProvider extends provider_1.IBaseJsonRpcProvider {
  constructor(config) {
    super();
    this.config = config;
  }
}
exports.IMultiServiceProvider = IMultiServiceProvider;
