import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ConstantService } from "../../services/constant.service";
import { Subscription } from "rxjs";
import { PolicyService } from "../../../shared/services/policy.service";
import { DataService } from "src/app/shared/services/data.service";
import { Wallet, WalletSuspendStatus } from "../../../shared/entities/wallet";
import Swal from "sweetalert2";
import { getIcon, getName, getSupportedCoins, getSupportedCoinGroupText } from '../../helpers/coins';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UAParser } from 'ua-parser-js';
import { HttpService } from '../../services/http.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WalletserviceService } from '../../services/walletservice.service';
import { allowNumber, allowNumberDecimal } from '../../helpers/inputNumber';
import { memoValidationHandler } from '../../helpers/memo.utils';
import { SegmentService } from "ngx-segment-analytics";
import { ErrorPrompt } from "../../../shared/components/custom-prompt/custom-prompt.service";
import { ArchivedStatus, WalletMessgaes } from "../../constants/wallet.const";

@Component({
  selector: "app-add-policy",
  templateUrl: "./add-policy.component.html",
  styleUrls: ["./add-policy.component.scss"],
})
export class AddPolicyComponent implements OnInit {
  closeResult = "";
  selectPolicyType: string = "";
  selectPolicyIcon: string = "";
  selectPolicyMsg: string = "";
  selectWalletName: string = "";
  selectWalletIcon: string = "";
  selectWalletParentChain: string = "";
  selectWalletCoin: string = "";
  step: string = "step-0";
  wallets: Wallet[];
  _wallets: any = [];
  _wallets_copy: any = [];
  singleWalletData: any;
  policyLabel: string = "";
  policyAddress: string = null;
  policyType: string;
  policyWalletId: number;
  policyAmount: number = null;
  policyTimePeriod: string = "";
  customPolicyTimePeriod: string = null;
  nextButtonAll = true;
  ledgerConnect = false;
  isSigning = false;
  // organizationId: number;
  profileData: any;
  deviceName: string;
  searchWalletInput: string;
  approveCommonTitle = false;
  approveRemoveTitle = false;
  whitelistAddress = [];
  whitelistNew = true;
  whitelistNew_internal = true;
  addressInvalid = false;
  selectGroupWallet: boolean = false;
  addressTxType: string = "external_address";
  selectInterWalletAddress: any;
  addressWallets: any = [];
  protocol:any;
  inputPolicyAddress:string;
  selected_protocol_value:any;
  interval_unit: string = 'hours';
  timePeriod = '';
  orgRole:number;
  policyApprovals:any = [];
  fromWalletConnect = false;
  internalWalletAddress :any;
  addressWallets_internal: any=[];
  memoSupportChain = [
    {parentchain:'COSMOS',validation:'numeric'},
    {parentchain:'XLM',validation:'string'},
    {parentchain:'XRPDROP',validation:'numeric'},
    {parentchain:'BNB',validation:'string'},
    // {chain:'EOS',validation:'numeric'},
    // {chain:'HBAR',validation:'numeric'},
    // {chain:'STX',validation:'string'},
  ]
  inputPolicyMemo:string | number;
  selectedPolicyMemo:string | number;
  showMemo: boolean = false;
  isMemoValidator = {
    isValid: true,
    message: ''
  }
  memoValidation:any;
  trmStatus = false;
  // protocol:any;
  from:any = ''
  @Input() component_for: string;
  @Input() changePolicyData: any;
  @Input() pendingPolicyData: any;
  @Input() transactionStatus: boolean;
  @Input() addpolicy_component_for: string;
  @Input() walletDetailWalletId: any;
  @Input() isSettings: boolean;

  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();

  orgSubscription: Subscription;
  private onDestroy$: Subject<void> = new Subject<void>();

  browser: string;

  @ViewChild("errorModal") errorModal: ElementRef;
  @ViewChild("successModal") successModal: ElementRef;
  @ViewChild("addPolicyModal") addPolicyModal: ElementRef;
  errMsg;
  public isWalletArchived: boolean;
  public addPolicyTooltip: string;
  constructor(
    private org: ConstantService,
    private modalService: NgbModal,
    private data: DataService,
    private policyService: PolicyService,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private segment: SegmentService
  ) {
    // this.browser = new UAParser().getBrowser().name;
    // this.init();
  }
  
  ngOnInit(): void {
    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((wallets) => {
      if (wallets == null) return;
      this.wallets = wallets.filter((ele) => ele.isArchived != 1 && ele.suspendedStatus !== WalletSuspendStatus.SUSPENDED);
      this._wallets = [
        ...new Map(this.wallets.map((item) => [item.id, item])).values(),
      ];
      const archivedWallet = this.wallets.find(row => row.id === Number(this.walletDetailWalletId));
      this.isWalletArchived = archivedWallet ? archivedWallet['isArchived'] === ArchivedStatus.ARCHIVED : true;
      this.addPolicyTooltip = this.isWalletArchived ? WalletMessgaes.ARCHIVED_POLICY : "";
      this._wallets.forEach((element) => {
        element["coinfullname"] = getName(element.chain);
      });
      this._wallets = this._wallets.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      this.policyListWalletDetail();
      this._wallets_copy = JSON.parse(JSON.stringify(this._wallets));
      if (this.selectPolicyType) {
        this.setFilteredWallets();
      }
      // this.protocol = [...new Map(this._wallets.map(item => [item.chain, item])).values()];
    });

    this.data.getUserProfile.subscribe((user_detail) => {
      this.profileData = user_detail;
      this.orgRole = this.profileData.organizations[0].type;
      this.trmStatus = this.profileData.organizations[0].trmStatus;
    });

    this.walletService.policyNotWhitelistedStatus.subscribe((getStatus) => {
      if (getStatus?.isNotWhitelisted) {
        this.from = getStatus?.from;
        this.fromWalletConnect = true;
        this.selectPolicyType = "Whitelist Address";
        this.addressTxType = "external_address";
        this.selectPolicyIcon =
          "../../../assets/images/policy/whitelist-address-icon-round.svg";
        this.selectPolicyMsg =
          "Allow wallet members to send funds to this address.";
        this.selectWallet(getStatus?.data?.wallet, "singleWallet");
        this.setPolicyLabel({ value: getStatus?.data?.url });
        this.setPolicyAddress(getStatus?.data?.destinationAddress);
        this.inputPolicyAddress = getStatus?.data?.destinationAddress;
        this.nextButtonAll = false;
        this.addPolicy(this.addPolicyModal, "wallet-connect");
      }
    });
  }

  policyListWalletDetail() {
    if (this.addpolicy_component_for == "policy_list") {
      const walletIndex = this._wallets.findIndex(
        (ele) => ele.id == this.walletDetailWalletId
      );
      this.httpService.getWalletPolicies(this.walletDetailWalletId).subscribe((data)=>{
        this.singleWalletData = this._wallets[walletIndex];
        this.singleWalletData.policy = data;
        this._wallets_copy[walletIndex].policy = data;
      })
    }
  }

  getIcon = (id) => {
    return getIcon(id);
  };

  // checkAdmin(orgid) {
  //   console.log("Profile Data", this.profileData);
  //   if(this.singleWalletData?.isArchived ===1){
  //     return 'archived'
  //   }
  //   if (this.profileData[0].type == 2) {
  //     return 'admin';
  //   } 
  //   else {
  //     return 'not admin';
  //   }
  // }

  checkWalletStatus() {
    if (this.singleWalletData?.isArchived === 1) {
      return "archived";
    }
  }

  approvePendingPolicy() {
    this.approveCommonTitle = true;
    this.selectWalletName = this.pendingPolicyData.wallet.name;
    this.selectWalletIcon = getIcon(
      this.pendingPolicyData.wallet.coin.toLowerCase()
    );
    this.selectWalletCoin = this.pendingPolicyData.wallet.coin.toLowerCase();
    this.selectedPolicyMemo = this.pendingPolicyData.memo;
    // let checkWallet = this._wallets.filter(item => item.id === this.pendingPolicyData.wallet.id);
    // let checkdevice = checkWallet[0].walletKeys.filter(item => item.ismine === true && item.provider === 'ledger');
    // if (checkdevice.length) this.ledgerConnect = true;

    // this.deviceName = checkWallet[0].walletKeys.filter(item => item.ismine === true)[0].provider;

    if (
      this.pendingPolicyData.sub_type.toLowerCase() == "address" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "remove_address" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "enable_app" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "stakingenabled"
    ) {
      this.step = "step-1.1";
      this.policyLabel = this.pendingPolicyData.name;
      this.policyAddress = this.pendingPolicyData.condition;
      this.selectPolicyIcon =
        "../../../assets/images/policy/whitelist-address-icon-round.svg";
    }
    if (
      this.pendingPolicyData.sub_type.toLowerCase() == "velocity" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "remove_velocity"
    ) {
      this.step = "step-2.1";
      this.policyAmount = this.pendingPolicyData.amount;
      this.selectPolicyIcon =
        "../../../assets/images/policy/velocity-icon-round.svg";
      this.policyTimePeriod = this.pendingPolicyData.condition;
    }
    if (
      this.pendingPolicyData.sub_type.toLowerCase() == "threshold" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "remove_threshold"
    ) {
      this.step = "step-3.1";
      this.policyAmount = this.pendingPolicyData.amount;
      this.selectPolicyIcon =
        "../../../assets/images/policy/thresold-icon-round.svg";
    }
    if (
      this.pendingPolicyData.sub_type.toLowerCase() == "remove_address" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "remove_velocity" ||
      this.pendingPolicyData.sub_type.toLowerCase() == "remove_threshold"
    ) {
      this.approveRemoveTitle = true;
      this.approveCommonTitle = false;
    }
  }

  signPendingPolicy() {
    this.isSigning = true;
    this.parentFun.emit(this.deviceName);
  }

  getChangePolicyData() {
    if (this.changePolicyData) {
      this.selectWalletName = this.changePolicyData.walletname;
      this.selectWalletIcon = getIcon(this.changePolicyData.chain);
      this.selectWalletCoin = this.changePolicyData.chain;
      this.policyWalletId = this.changePolicyData.walletid;
      this.selectPolicyType = this.changePolicyData.subtype.toLowerCase();
      this.policyAmount = this.changePolicyData.amount.replace(/\.?0+$/, "");
      // this.organizationId = this.changePolicyData.orgid;
      // let checkDeviceName = this.changePolicyData.walletKeys.filter(item => item.ismine === true);
      // this.deviceName = checkDeviceName[0].provider;
      // if (this.deviceName === 'ledger') {
      //   this.ledgerConnect = true;
      // }

      if (this.selectPolicyType === "velocity") {
        this.selectPolicyIcon =
          "../../../assets/images/policy/velocity-icon-round.svg";
        this.selectPolicyMsg =
          "Define the maximum USD value that can be sent to whitelisted addresses in a given time period.";
        this.nextButtonAll = false;
        this.policyTimePeriod = this.changePolicyData.condition;
        if (!(["", "24h", "7d", "30d"].indexOf(this.policyTimePeriod) > -1)) {
          this.customPolicyTimePeriod = String(
            this.changePolicyData.condition.match(/\d+/g)
          );
        }
        this.selectPolicyType = "Spending Limit";
      } else if (this.selectPolicyType === "threshold") {
        this.selectPolicyIcon =
          "../../../assets/images/policy/thresold-icon-round.svg";
        this.selectPolicyMsg =
          "Define the maximum USD value that can be sent to whitelisted addresses in a single transaction.";
        this.nextButtonAll = false;
        this.policyTimePeriod = this.changePolicyData.condition;
        this.selectPolicyType = "Transaction Limit";
      }
    }
  }

  async getDeviceName(): Promise<boolean> {
    let deviceList = [];
    if (this.profileData.keys["ETH"].length) {
      this.profileData.keys["ETH"].forEach((element) => {
        deviceList.push(element.provider.toLowerCase());
      });
      if (deviceList.includes("trezor")) {
        this.deviceName = "trezor";
      } else if (deviceList.includes("ledger")) {
        this.deviceName = "ledger";
      } else if (
        deviceList.includes("ledger") &&
        deviceList.includes("trezor")
      ) {
        this.deviceName = "trezor";
      }
    } else {
      // this.modalService.dismissAll();
      // Swal.fire({
      //   text: "Please link Ethereum asset in devices to access Policy",
      //   icon: "info",
      //   showConfirmButton: false,
      // });
      return true;
    }
    if (this.deviceName === "ledger") {
      this.ledgerConnect = true;
    }
  }

  async addPolicy(addPolicyModal: any, fromSource?: any) {
    this.modalService.dismissAll();
    if (fromSource === "wallet-connect") {
      this.fromWalletConnect = true;
    } else {
      this.fromWalletConnect = false;
    }
    this.modalService
      .open(addPolicyModal, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      })
      .result.then(
        (result: any) => {
          this.closeResult = `Closed with: ${result}`;
          this.resetSendModel();
        },
        (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.resetSendModel();
        }
      );
  }

  private getDismissReason(reason: any): string {
    this.resetSendModel();
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  resetSendModel() {
    this.step = "step-0";
    this.selectWalletName = "";
    this.selectPolicyType = "";
    this.policyLabel = "";
    this.policyAddress = null;
    this.policyTimePeriod = "";
    this.policyAmount = null;
    this.nextButtonAll = true;
    this.isSigning = false;
    this.deviceName = "";
    this.ledgerConnect = false;
    this.searchWalletInput = "";
    this.approveCommonTitle = false;
    this.approveRemoveTitle = false;
    this.whitelistNew = true;
    this.addressInvalid = false;
    this.selectGroupWallet = false;
    this.selectInterWalletAddress = null;
    this.inputPolicyAddress = "";
    this.timePeriod = "";
    this.showMemo = false;
    this.inputPolicyMemo = null;
    this.isMemoValidator = {
      isValid: true,
      message: ''
    }
  }

  setPolicyLabel(inputPolicyLabel) {
    this.policyLabel = inputPolicyLabel.value;
  }

  setPolicyAddress(inputPolicyAddress) {
    if (this.whitelistAddress.includes(inputPolicyAddress) && this.showMemo == false) {
      this.whitelistNew = false;
    } else {
      this.policyAddress = inputPolicyAddress;
      this.whitelistNew = true;
    }
  }

  async createPolicy() {
    this.isSigning = true;
    let policyRequest = "add policy";

    try {
      const response = await this.policyService.addPolicy(
        this.policyWalletId,
        this.selectWalletName,
        this.policyLabel,
        this.policyType,
        this.policyAddress === null
          ? this.internalWalletAddress
          : this.policyAddress,
        this.policyAmount,
        this.policyTimePeriod,
        this.deviceName,
        policyRequest,
        this.inputPolicyMemo
      );

      if (response.success === true) {
        this.segment.track("policy-initiated", {
          walletId: this.policyWalletId,
          walletName: this.selectWalletName,
          policyLabel: this.policyLabel,  
          policyType: this.policyType,
          policyAddress: this.policyAddress === null
            ? this.internalWalletAddress
            : this.policyAddress,
          policyAmount: this.policyAmount,
          policyTimePeriod: this.policyTimePeriod,
          deviceName: this.deviceName,
          policyRequest
        }).catch((err)=>{})
        this.modalService.dismissAll();
        this.isSigning = false;
        this.modalService.open(this.successModal, {
          windowClass: "modal-custom-background",
          centered: true,
        });
        // Swal.fire({
        //   title: 'Success!',
        //   text: 'Awesome! You have successfully initiated this policy request 🎉',
        //   icon: 'success',
        //   confirmButtonText: 'Done'
        // });
      } else {
        const segmentData = {
          error: response,
          userData: this.profileData,
          policyType: this.policyType
        }
        this.segment.track("policy-initiation-failed", segmentData).catch((err)=>{});
        this.modalService.dismissAll();
        this.isSigning = false;
        // ErrorPrompt.fire({
        //   icon: "error",
        //   title: "Failed to add policy",
        //   text: response.message,
        //   showConfirmButton: false,
        // })
        this.errMsg = response.message;

        this.modalService.open(this.errorModal, {
          windowClass: "modal-custom-background",
          centered: true,
        });
      }
    } catch (e) {
      var msgtext;
      if (e.message) {
        msgtext = e.message;
      } else {
        msgtext = "Failed to add policy";
      }
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to add policy",
        text: msgtext,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }

  getImageUrl(walletImg) {
    if (!walletImg.coin.toString().includes(" "))
      return getIcon(walletImg.coin);
    else return walletImg.imageurl;
  }

  selectAddressType(type) {
    this.addressTxType = type;
    if (type === "external_address") {
      this.searchWalletInput = "";
      this.policyLabel = "";
      this.selectInterWalletAddress = null;
      this.internalWalletAddress = null;
    } else {
      this.inputPolicyAddress = "";
      this.policyLabel = "";
      this.policyAddress = null;
    }
    this.inputPolicyMemo = "";
    this.isMemoValidator = {
      isValid: true,
      message: ''
    }
  }

  selectWallet(walletsdata, type) {
    if (type == "groupWallet") {
      this.selectGroupWallet = true;
      // this.selectWalletName = type;
    } else {
      this.selectGroupWallet = false;
    }
    if (walletsdata.policy) {
      walletsdata.policy.map((policy) => {
        if (policy.type == "Address") {
          this.whitelistAddress.push(policy.condition);
        }
      });
    }
    if(this.memoSupportChain.filter(e=>e.parentchain == walletsdata.parentchain).length)
    {
      this.memoValidation = this.memoSupportChain.filter(e=>e.parentchain == walletsdata.parentchain)[0];
      this.showMemo = true;
    }    
    // this.organizationId = walletsdata.orgid;
    this.selectWalletName = walletsdata.name;
    this.selectWalletIcon = getIcon(walletsdata.chain);
    this.selectWalletCoin = walletsdata.chain;
    this.policyWalletId = walletsdata.id;
    this.selectWalletParentChain = walletsdata.parentchain;
    // console.log("Selected Wallet", this.selectWalletName, this.selectWalletCoin, walletsdata);
    // let checkDevice = walletsdata.walletKeys.filter(item => item.ismine === true && item.provider === 'ledger');
    // let checkDeviceName = walletsdata.walletKeys.filter(item => item.ismine === true);
    // if (checkDevice.length) this.ledgerConnect = true;
    // this.deviceName = walletsdata.walletKeys.filter(item => item.ismine === true)[0].provider;
    // this.deviceName = checkDeviceName[0].provider;
    this.addressWallets = this._wallets.filter(
      (ele) =>
        ele.id != this.policyWalletId &&
        ele.chain.toLocaleLowerCase() ==
          this.selectWalletCoin.toLocaleLowerCase()
    );
    if(this.showMemo == false)
    {
      this.addressWallets_internal = this.alreadywhitelistedInternalWallets(this.addressWallets,this.whitelistAddress);
    } else 
    {
      this.addressWallets_internal = this.addressWallets;
    }

    this.walletDetailWalletId = walletsdata.id;
    if (this.isSettings || this.addpolicy_component_for == "policy_list") {
      this.policyListWalletDetail();
      if (walletsdata.subtype === 'hot') {
          this.clearPolicyTypeSelect();
      }
    }
  }

  alreadywhitelistedInternalWallets(allWallets,selectedWallets){
    allWallets.forEach(el => {
      if(selectedWallets.includes(el.address)) {
        allWallets = allWallets.filter((el) => !selectedWallets.includes(el.address));
      }
    });
    return allWallets
  }
  resetInternalWallet() {
    this.selectInterWalletAddress = !this.selectInterWalletAddress;
    this.whitelistNew_internal = true;
  }
  selectPolicy(policyType, policyIcon, policyMsg) {
    this.selectPolicyType = policyType;
    this.selectPolicyIcon = policyIcon;
    this.selectPolicyMsg = policyMsg;
    this.setFilteredWallets();
  }

  setFilteredWallets() {
    let filteredList = JSON.parse(JSON.stringify(this._wallets_copy));
    if (this.selectPolicyType == "Whitelist Address") {
      this.selectPolicyType = "Whitelist Address";
      this.nextButtonAll = false;
      filteredList = this._wallets_copy.filter(wc => wc.subtype !== 'hot')
    } else if (this.selectPolicyType == "Spending Limit") {
      this.selectPolicyType = "Spending Limit";
      this.nextButtonAll = false;
      filteredList = this._wallets_copy.filter(wc => wc.subtype !== 'deposit')
    } else if (this.selectPolicyType == "Transaction Limit") {
      this.selectPolicyType = "Transaction Limit";
      this.nextButtonAll = false;
      filteredList = this._wallets_copy.filter(wc => wc.subtype !== 'deposit')
    } else if (this.selectPolicyType == "Blacklist Address") {
      this.selectPolicyType = "Blacklist Address";
      this.nextButtonAll = false;
    }
    this._wallets = filteredList;
  }

  addPolicyNext() {
    if (this.selectPolicyType == "Whitelist Address") {
      this.step = "step-1";
    } else if (this.selectPolicyType == "Spending Limit") {
      this.step = "step-2";
    } else if (this.selectPolicyType == "Transaction Limit") {
      this.step = "step-3";
    } else if (this.selectPolicyType == "Blacklist Address") {
      this.step = "step-1";
    }
  }

  confirmPolicy() {
    // let _profileData = this.profileData.organizations.filter(obj => obj.id == this.organizationId && obj.type == 2);
    if (this.selectPolicyType == "Whitelist Address") {
      // this.step = "step-1.1";
      this.policyType = "whitelist_address";
    } else if (this.selectPolicyType == "Spending Limit") {
      // this.step = "step-2.1";
      this.policyType = "wallet_velocity";
    } else if (this.selectPolicyType == "Transaction Limit") {
      // this.step = "step-3.1";
      this.policyType = "wallet_threshold";
    } else if (this.selectPolicyType == "Blacklist Address") {
      // this.step = "step-1.1";
      this.policyType = "blacklist_address";
    }
  }

  selectTimePeriodButton(timePeriodButton) {
    this.policyTimePeriod = timePeriodButton;
  }

  selectTimePeriod(timePeriod) {
    // let min = 1;
    // let max = 10000000000;
    // timePeriod.value < min ? timePeriod.value = min : timePeriod.value;
    // timePeriod.value > max ? timePeriod.value = max : timePeriod.value;
    this.timePeriod = timePeriod;
    if (this.interval_unit === "hours") {
      this.policyTimePeriod = timePeriod.value + "h";
    } else {
      this.policyTimePeriod = timePeriod.value + "d";
    }
  }

  selectPolicyAmount(policyAmount) {
    // let min = 1;
    // let max = 10000000000;
    // policyAmount.value < min ? policyAmount.value = min : policyAmount.value; policyAmount.value > max ? policyAmount.value = max : policyAmount.value;
    this.policyAmount = policyAmount.value;
  }

  clearWalletSelect() {
    this.selectWalletName = "";
    this.searchWalletInput = "";
    this.policyLabel = "";
    this.policyAddress = null;
    this.policyTimePeriod = "";
    this.policyAmount = null;
    this.selectGroupWallet = false;
    this.selectInterWalletAddress = null;
    this.internalWalletAddress = null;
    this.timePeriod = "";
    this.whitelistAddress=[];
    this.addressWallets_internal = this.addressWallets;
    this.showMemo = false;
    this.inputPolicyMemo = null;

    if(this.isSettings || this.addpolicy_component_for == "policy_list") {
      this.singleWalletData = null;
    }
  }

  clearPolicyTypeSelect() {
    this.selectPolicyType = "";
    this.policyLabel = "";
    this.policyAddress = null;
    this.policyTimePeriod = "";
    this.policyAmount = null;
    this.timePeriod = "";
    this.inputPolicyAddress = "";
    this.policyLabel = "";
    this.searchWalletInput = "";
  }

  onAmlResponse(eventData: { status: string }) {
    if (eventData.status == "invalid") {
      this.addressInvalid = true;
    } else {
      this.addressInvalid = false;
    }
  }

  selectInternalWallet(data) {
    if (this.whitelistAddress.includes(data.address) && this.showMemo == false) {
      this.whitelistNew_internal = false;
    }
    this.internalWalletAddress = data.address;
    this.policyLabel = data.name;
    this.selectInterWalletAddress = data;
  }

  // selectProtocol(data)
  // {
  //   this.addressWallets = this._wallets.filter(ele => ele.chain.toLocaleLowerCase() == data.toLocaleLowerCase());
  // }

  getSupportedCoins = (key) => {
    return getSupportedCoins(key);
  };

  getName = (id) => {
    return getName(id);
  };

  getSupportedCoinGroupText = (key) => {
    return getSupportedCoinGroupText(key);
  };

  selectProtocol(asset, t) {
    this.selected_protocol_value = asset;
    this.addressWallets = this._wallets.filter(
      (ele) => ele.chain.toLocaleLowerCase() == asset.toLocaleLowerCase()
    );
    console.info("this.addressWallets",this.addressWallets);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  selectRole(unit) {
    this.interval_unit = unit;
    if (this.timePeriod !== "") {
      this.selectTimePeriod(this.timePeriod);
    }
  }

  // omit_special_char(event)
  // {
  //   var k;
  //   k = event.charCode;  //  k = event.keyCode;  (Both can be used)
  //   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 46);
  // }

  allowNumberDecimal(event) {
    return allowNumberDecimal(event);
  }
  allowNumber(event) {
    return allowNumber(event);
  }

  onMemoChange() {
    this.isMemoValidator= memoValidationHandler(this.inputPolicyMemo, this.selectWalletParentChain);
  }

  // async init()
  // {
  //   let profile = await this.httpService.getprofile().toPromise();
  //   this.org.changeOrg(profile["organizations"]);
  //   this.profileData = profile;

  //   this.orgRole = this.profileData.organizations[0].type;
  //   // this.orgId = this.profileData.organizations[0].id;
  //   // this.policyApprovals = await this.httpService.getOrgMembers(this.orgId);
  //   // this.policyApprovals = this.policyApprovals.members.filter(ele => ele.type == 2);
  //   // console.log('poicy',this.policyApprovals);
  // }
}
