import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { ConstantService } from "../../services/constant.service";
import { HttpService } from "../../services/http.service";
import { environment } from "src/environments/environment";
import { SegmentService } from 'ngx-segment-analytics';
import { ErrorPrompt } from "../custom-prompt/custom-prompt.service";
@Component({
  selector: "app-consolidation-rule-view",
  templateUrl: "./consolidation-rule-view.component.html",
  styleUrls: ["./consolidation-rule-view.component.scss"],
})
export class ConsolidationRuleViewComponent implements OnInit {
  pageTitle = `${environment.buildName} Vaults - Consolidation`;

  page = 1;
  pageSize = 15;
  walletList: any;
  _walletList: any = [];
  __walletList: any = [];
  removeRuleWalletId: any;
  removeRuleId: any;
  ruleSearch: string;
  orgnanization: any = [];
  loading: any;

  constructor(
    private segment: SegmentService,
    private modalService: NgbModal,
    private httpService: HttpService,
    private org: ConstantService,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.pageTitle);
    this.consolidationWalletRuleList();
  }

  ngOnInit(): void {
    this.loading = true;
    this.org.currentOrg.subscribe((org) => {
      let organizationData = org;
      this.orgnanization = organizationData[0];
      console.log("org", this.orgnanization);
    });
  }

  async consolidationWalletRuleList() {
    let response = await this.httpService
      .getOrgConsolidationRules()
      .toPromise();
    this.walletList = response.data;
    console.log("data dc", this.walletList);
    this._walletList = [];
    this.walletList.forEach((ele) => {
      // Object.keys(ele.walletSettings).map(function (key) {
      //   arr.push({ [key]: ele.walletSettings[key] })
      //   return arr;
      // });
      // console.log('arr', arr);
      let arr = {
        id: ele.id,
        walletID: ele.wallet_id,
        walletname: ele.wallet_label,
        chain: ele.wallet_chain,
        asset: ele.asset,
        ConsolidateMaxFee: ele.max_fee,
        ConsolidateMinAmount: ele.min_amount,
        lastUsed: ele.last_used,
        currency: ele.currency,
      };
      // ele.threshold_rules.sort((a, b) => {
      //   if (a.threshold === null) {
      //     return -1;
      //   }

      //   if (b.threshold === null) {
      //     return 1;
      //   }

      //   if (a.threshold === b.threshold) {
      //     return 0;
      //   }

      //   return a.threshold < b.threshold ? -1 : 1;
      // });
      arr["ConsolidateDestinationAddress"] =
        ele.threshold_rules[0]["destination_address"];
      arr["DestinationAddressLabel"] = ele.threshold_rules[0]["address_label"];
      arr["DestinationAddressID"] = ele.threshold_rules[0]["id"];
      if (ele.threshold_rules.length > 1) {
        arr["spillover"] = {
          value: ele.threshold_rules[0]["threshold"],
          address: ele.threshold_rules[1]["destination_address"],
          label: ele.threshold_rules[1]["address_label"],
          id: ele.threshold_rules[1]["id"],
        };
      }
      this._walletList.push(arr);
      // arr.forEach(element => {
      //   var asset = Object.keys(element)[0];
      //   var value = Object.values(element)[0];
      //   this._walletList.push({
      //     id: ele.id,
      //     walletname: ele.name,
      //     chain: ele.chain,
      //     asset: asset,
      //     ConsolidateMinFee: value['ConsolidateMinFee'],
      //     ConsolidateMaxFee: value['ConsolidateMaxFee'],
      //     ConsolidateMinAmount: value['ConsolidateMinAmount'],
      //     ConsolidateDestinationAddress: value['ConsolidateDestinationAddress'],
      //     lastUsed: value['lastUsed']
      //   });
      // });
    });
    this.__walletList = this._walletList;
    console.log("Wallets", this.__walletList);
    this.loading = false;
  }

  open(canceltrsancation: any) {
    this.modalService.open(canceltrsancation, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  removeRule(id, ruleId) {
    this.removeRuleWalletId = id;
    this.removeRuleId = ruleId;
  }

  async removeConsolidationRule() {
    try {
      const response = await this.httpService
        .removeConsolidationRule(
          this.removeRuleWalletId,
          this.removeRuleId.toString()
        )
        .toPromise();

      if (response.success === true) {
        this.segment.track("consolidation-rule-removed", {walletId: this.removeRuleWalletId, ruleId: this.removeRuleId.toString()}).catch((err)=>{});
        this.modalService.dismissAll();
        Swal.fire({
          title: "Success!",
          text: "Consolidation rule successfully removed",
          icon: "success",
          confirmButtonText: "Cool",
        });
        this.consolidationWalletRuleList();
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to remove rule",
          text: response.message,
          showConfirmButton: false,
        });
        console.info(response);
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to remove rule",
        text: e.error.message,
        showConfirmButton: false,
      });
      console.error(e);
    }
  }
}
