"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatErrorMessage = exports.formatJsonRpcError = exports.formatJsonRpcResult = exports.formatJsonRpcRequest = exports.payloadId = void 0;
const error_1 = require("./error");
const constants_1 = require("./constants");
function payloadId() {
  const date = Date.now() * Math.pow(10, 3);
  const extra = Math.floor(Math.random() * Math.pow(10, 3));
  return date + extra;
}
exports.payloadId = payloadId;
function formatJsonRpcRequest(method, params, id) {
  return {
    id: id || payloadId(),
    jsonrpc: "2.0",
    method,
    params
  };
}
exports.formatJsonRpcRequest = formatJsonRpcRequest;
function formatJsonRpcResult(id, result) {
  return {
    id,
    jsonrpc: "2.0",
    result
  };
}
exports.formatJsonRpcResult = formatJsonRpcResult;
function formatJsonRpcError(id, error) {
  return {
    id,
    jsonrpc: "2.0",
    error: formatErrorMessage(error)
  };
}
exports.formatJsonRpcError = formatJsonRpcError;
function formatErrorMessage(error) {
  if (typeof error === "undefined") {
    return error_1.getError(constants_1.INTERNAL_ERROR);
  }
  if (typeof error === "string") {
    error = Object.assign(Object.assign({}, error_1.getError(constants_1.SERVER_ERROR)), {
      message: error
    });
  }
  if (error_1.isReservedErrorCode(error.code)) {
    error = error_1.getErrorByCode(error.code);
  }
  if (!error_1.isServerErrorCode(error.code)) {
    throw new Error("Error code is not in server code range");
  }
  return error;
}
exports.formatErrorMessage = formatErrorMessage;
