import { RuleTypeTitle, RuleOutcome, Rule } from "../../../entities/firewall.model";

export enum FirewallCheckStatus {
    FIREWALL_PENDING_STATUS = 4,
    REJECTED_STATUS = 3,
    LIVENESS_CHECK = "LIVENESS",
}

export enum FirewallApprovalStatus {
    PENDING = undefined,
    APPROVED = 1,
    REJECTED = 2,
}

export enum PendingTxnStatus {
    Pending = 1,
    Completed = 2,
    Cancelled = 3,
    FirewallPending = 4,
    ExchangeApprovalPending = 5

}

export function getFirewallPolicyName(name: string) {
    return RuleTypeTitle[name as keyof typeof RuleTypeTitle] ?? "-"
}


export function isOutComeApproval(ruleOutcome: any): boolean {
    return ruleOutcome.toString() === RuleOutcome.Approval
}

export function getApprovalConfig(rule: Rule) {
    let quorum = rule.quorumAppConfig?.quorum;
    let approvalsNeeded = quorum?.approvalsNeeded;
    let teamName = quorum?.team.name;
    if (approvalsNeeded && teamName) {
        return `${approvalsNeeded} of ${teamName}`
    }
    return
}

export function getCssClassBasedOnRuleOutcome(outcome: RuleOutcome) {
    switch (outcome.toString()) {
        case RuleOutcome.Approval:
            return 'text-approval'
        case RuleOutcome.Accept:
            return 'text-success'
        case RuleOutcome.Reject:
            return 'text-red';
        default:
            break;
    }
    return '';
}


export enum DirectionTabs {
    Send = 'Send Rules',
    Receive = 'Receive Rules'
}
