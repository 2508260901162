import { Component, Input, OnInit } from "@angular/core";
import { getParentChain, getWalletType } from "../../helpers/WalletUtils";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpService } from "../../services/http.service";
import { TransactionService } from "../../services/transaction.service";
import {
  ErrorPrompt,
  SuccessPrompt,
} from "../custom-prompt/custom-prompt.service";
import {
  ErrorPromptPayload,
  SuccessPromptPayload,
} from "../../constants/promptMessages";

@Component({
  selector: "app-speed-up-transaction",
  templateUrl: "./speed-up-transaction.component.html",
  styleUrls: ["./speed-up-transaction.component.scss"],
})
export class SpeedUpTransactionComponent implements OnInit {
  @Input() component_for: string = "speed-up-transaction";
  @Input() transactionData: any = null;

  confirmationModalReference = null;
  speedTransactionformModalReference = null;
  isManuallyAdjustfFee: boolean = false;
  isUTXO: boolean = false;
  isEVM: boolean = false;
  isLoading: boolean = false;
  baseFee: number = 0;
  priorityFee: number = 0;
  walletId: number;
  sequenceId: number;
  currentChain: string;
  priceIncreaseparcentage: number = 10;
  defaultRbfInceseFeePercentage: number = 10;
  priceChangeDirection: "higher" | "lower" | "same" = "higher";
  currentMarketGasFee: number;
  walletType: string;
  getWalletType = getWalletType;
  defaultMaxPriorityfee: number;
  maxfeeOverTime: number = 2;
  maxBaseFee: number;

  constructor(
    private httpService: HttpService,
    private txnService: TransactionService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.walletId = this.transactionData?.walletid;
    this.sequenceId = this.transactionData?.sequenceId;
    this.currentChain = this.transactionData?.chain;

    if (this.transactionData && this.transactionData.chain) {
      this.isUTXO = getParentChain(this.transactionData?.chain) == "UTXO";
      this.isEVM = getParentChain(this.transactionData?.chain) == "EVM";
    }

    this.currentChain && this.currentMarketFees(this.currentChain);

    if (
      this.transactionData?.walletinfor &&
      this.transactionData?.walletinfor?.type
    ) {
      this.walletType = this.getWalletType(
        this.transactionData?.walletinfor?.type
      );
    }
  }

  onSubmitSpeedUpTxnForm() {
    let payload = {};
    payload["chain"] = this.transactionData?.chain;
    payload["walletType"] = this.walletType;
    payload["feeRate"] = this.baseFee;

    if (this.isEVM) {
      payload["maxPriorityFee"] = this.priorityFee;
    }
    
    if (!this.walletId || !this.sequenceId) {
      return this.showErrorPrompt(
        "Something went wrong. Unable to create transaction"
      );
    }

    if(this.baseFee > this.maxBaseFee){
      return this.showErrorPrompt("Base fee is too high", false);
    }

    // UTXO  MPC/Multisig Transaction
    if (
      getParentChain(this.transactionData?.chain) == "UTXO" ||
      getParentChain(this.transactionData?.chain) == "EVM"
    ) {
      if (this.walletType == "Mobile") {
        return this.initiateUTXOMPCTxn(this.walletId, this.sequenceId, payload);
      }

      if (this.walletType == "Multisig") {
        return this.initiateUTXOMultiSigTxn(this.walletId, this.sequenceId, {
          ...payload,
          ...this.transactionData,
        });
      }
    }
  }

  initiateUTXOMPCTxn(walletId: number, sequenceId: number, payload?: any) {
    this.isLoading = true;
    this.txnService
      .createMpcSpeedUpTransaction(walletId, sequenceId, payload)
      .subscribe(
        (res: any) => {
          console.log("createSpeedUpTxn Response===", res);
          this.isLoading = false;
          if (res?.success) {
            this.showSuccessPrompt(this.getSuccessMessage(payload.chain));
          } else {
            this.showErrorPrompt(res?.message);
          }
        },
        (error) => {
          this.isLoading = false;
          console.log("Error in createSpeedUpTxn:initiateUTXOMPCTxn", error);
          this.showErrorPrompt(error);
        }
      );
  }

  initiateUTXOMultiSigTxn(walletId: number, sequenceId: number, payload: any) {
    this.isLoading = true;
    this.txnService
      .createMultiSigSpeedUpTransaction(walletId, sequenceId, payload)
      .subscribe(
        (res: any) => {
          console.log("createSpeedUpTxn Response===", res);
          this.isLoading = false;
          if (res?.success) {
            this.showSuccessPrompt(this.getSuccessMessage(payload.chain));
          } else {
            this.showErrorPrompt(res?.message);
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(
            "Error in createSpeedUpTxn:initiateUTXOMultiSigTxn",
            error
          );
          this.showErrorPrompt(error);
        }
      );
  }

  showSuccessPrompt(msg: string) {
    this.modalService.dismissAll();
    SuccessPrompt.fire(SuccessPromptPayload.SPEED_UP_TXN(msg));
  }

  showErrorPrompt(error: any, dismissAll: boolean = true) {
    dismissAll && this.modalService.dismissAll();
    const errMsg =
      (typeof error === "string" ? error : error?.message) ||
      "Something went wrong";
    ErrorPrompt.fire(ErrorPromptPayload.SPEED_UP_TXN_FAILED(errMsg));
  }

  getSuccessMessage(chain) {
    const successMsg =
      getParentChain(chain) === "EVM"
        ? "You have successfully resubmitted the transaction. We expect it to be completed in the nxt 1~2 blocks."
        : "You have successfully reinitiated the transaction. Please complete the remaining signatures on priority.";
    return successMsg;
  }

  currentMarketFees(chainName: string) {
    this.httpService
      .getCurrentMarketfees(chainName.toUpperCase())
      .subscribe((res: any) => {
        if (res && res.success) {
          // calculate RBF rate (market fee + 10% fee)
          if (res.data && res.data?.feesRate) {
            const feeRate = Number(res.data?.feesRate);
            this.currentMarketGasFee = feeRate || 0;
            this.maxBaseFee = Math.max(this.maxfeeOverTime * this.currentMarketGasFee, this.currentMarketGasFee) || 0;
            this.calculateBaseFee(this.defaultRbfInceseFeePercentage);
            this.onChangeBaseFee();
          }
          if(this.isEVM && res?.data?.maxPriorityFeePerGasGwei){
            const maxPriorityFeePerGasGwei = Number(res.data?.maxPriorityFeePerGasGwei);
            this.defaultMaxPriorityfee = this.priorityFee = maxPriorityFeePerGasGwei;
          }
        }
      });
  }

  validateTxn(): boolean {
    if (this.isUTXO) {
      return this.isManuallyAdjustfFee && !(this.baseFee > 0);
    }

    if (this.isEVM) {
      return (
        (this.isManuallyAdjustfFee && !(this.baseFee > 0)) ||
        !(this.priorityFee > 0)
      );
    }

    return false;
  }

  calculateBaseFee(InceseFeePercentage: number) { 
    this.baseFee = this.txnService.calculateRbfFees(
      Number(this.currentMarketGasFee),
      InceseFeePercentage
    );
  }

  toggleAdjustFee() {
    if (this.isManuallyAdjustfFee) {
      this.calculateBaseFee(0);
    } else {
      this.isEVM && (this.priorityFee = this.defaultMaxPriorityfee);
      this.calculateBaseFee(this.defaultRbfInceseFeePercentage);
    }
    this.onChangeBaseFee();
  }

  onChangeBaseFee() {
    const { percentageChange, changeDirection } =
      this.txnService.calculatePercentageChange(
        Number(this.currentMarketGasFee),
        Number(this.baseFee)
      );

    this.priceIncreaseparcentage = Math.abs(percentageChange);
    this.priceChangeDirection = changeDirection as any;
  }
}
