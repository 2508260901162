var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import invariant from "invariant";
import { MAX_SCRIPT_BLOCK } from "./constants";
import { createVarint } from "./varint";
export function getTrustedInputRaw(transport, transactionData, indexLookup) {
  return __awaiter(this, void 0, void 0, function* () {
    let data;
    let firstRound = false;
    if (typeof indexLookup === "number") {
      firstRound = true;
      const prefix = Buffer.alloc(4);
      prefix.writeUInt32BE(indexLookup, 0);
      data = Buffer.concat([prefix, transactionData], transactionData.length + 4);
    } else {
      data = transactionData;
    }
    const trustedInput = yield transport.send(0xe0, 0x42, firstRound ? 0x00 : 0x80, 0x00, data);
    const res = trustedInput.slice(0, trustedInput.length - 2).toString("hex");
    return res;
  });
}
export function getTrustedInput(transport_1, indexLookup_1, transaction_1) {
  return __awaiter(this, arguments, void 0, function* (transport, indexLookup, transaction, additionals = []) {
    const {
      inputs,
      outputs,
      locktime,
      nExpiryHeight,
      extraData
    } = transaction;
    if (!outputs || !locktime) {
      throw new Error("getTrustedInput: locktime & outputs is expected");
    }
    const isDecred = additionals.includes("decred");
    const processScriptBlocks = (script, sequence) => __awaiter(this, void 0, void 0, function* () {
      const seq = sequence || Buffer.alloc(0);
      const scriptBlocks = [];
      let offset = 0;
      while (offset !== script.length) {
        const blockSize = script.length - offset > MAX_SCRIPT_BLOCK ? MAX_SCRIPT_BLOCK : script.length - offset;
        if (offset + blockSize !== script.length) {
          scriptBlocks.push(script.slice(offset, offset + blockSize));
        } else {
          scriptBlocks.push(Buffer.concat([script.slice(offset, offset + blockSize), seq]));
        }
        offset += blockSize;
      }
      // Handle case when no script length: we still want to pass the sequence
      // relatable: https://github.com/LedgerHQ/ledger-live-desktop/issues/1386
      if (script.length === 0) {
        scriptBlocks.push(seq);
      }
      let res;
      for (const scriptBlock of scriptBlocks) {
        res = yield getTrustedInputRaw(transport, scriptBlock);
      }
      return res;
    });
    const processWholeScriptBlock = block => getTrustedInputRaw(transport, block);
    yield getTrustedInputRaw(transport, Buffer.concat([transaction.version, transaction.timestamp || Buffer.alloc(0), transaction.nVersionGroupId || Buffer.alloc(0), createVarint(inputs.length)]), indexLookup);
    for (const input of inputs) {
      const treeField = isDecred ? input.tree || Buffer.from([0x00]) : Buffer.alloc(0);
      const data = Buffer.concat([input.prevout, treeField, createVarint(input.script.length)]);
      yield getTrustedInputRaw(transport, data);
      // iteration (eachSeries) ended
      // TODO notify progress
      // deferred.notify("input");
      yield isDecred ? processWholeScriptBlock(Buffer.concat([input.script, input.sequence])) : processScriptBlocks(input.script, input.sequence);
    }
    yield getTrustedInputRaw(transport, createVarint(outputs.length));
    for (const output of outputs) {
      const data = Buffer.concat([output.amount, isDecred ? Buffer.from([0x00, 0x00]) : Buffer.alloc(0),
      //Version script
      createVarint(output.script.length), output.script]);
      yield getTrustedInputRaw(transport, data);
    }
    const endData = [];
    if (nExpiryHeight && nExpiryHeight.length > 0) {
      endData.push(nExpiryHeight);
    }
    if (extraData && extraData.length > 0) {
      endData.push(extraData);
    }
    let extraPart;
    if (endData.length) {
      const data = Buffer.concat(endData);
      extraPart = isDecred ? data : Buffer.concat([createVarint(data.length), data]);
    }
    const res = yield processScriptBlocks(Buffer.concat([locktime, extraPart || Buffer.alloc(0)]));
    invariant(res, "missing result in processScriptBlocks");
    return res;
  });
}
