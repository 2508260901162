import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiKeyService } from '../../services/api-key.service';
import { Subject } from 'rxjs';
import { ErrorPrompt, SuccessPrompt, CustomPrompt } from "../custom-prompt/custom-prompt.service";
import { NgForm } from '@angular/forms';
import { AuthServiceJWT } from '../../services/auth.service';
import { scopes } from '../../constants/authScopes';

@Component({
  selector: 'app-ip-address-restriction',
  templateUrl: './ip-address-restriction.component.html',
  styleUrls: ['./ip-address-restriction.component.scss']
})
export class IpAddressRestrictionComponent implements OnInit {
  @ViewChild("ipAddressRestrictionc") ipAddressRestrictionc: ElementRef;
  @Input() openIpAddressRestrictModal: any;
  @Input() currentIpDetails: any;
  private unsubscribe = new Subject<void>();
  ipAddress: string;
  subnetMask: string;
  ipAddressList = [];
  isLoading = false;
  ipForm: NgForm;
  modalRef: any;

  constructor(
    private modalService: NgbModal,
    private apiKeyService: ApiKeyService,
    private cdr: ChangeDetectorRef,
    private authService: AuthServiceJWT
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openIpAddressRestrictModal'] && changes['openIpAddressRestrictModal'].currentValue) {
      this.ipAddressList = [];
      this.resetForm();
      this.getIpList();
      this.openModal();
    }
  }

  getIpList() {
    this.isLoading = true;
    this.apiKeyService.getUserIpList().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        if (res?.success) {
          this.ipAddressList = res?.data;
          this.ipAddressList = this.ipAddressList.filter((record) => record.userId == this.currentIpDetails.id && record.status == 1);
        } else {
          this.ipAddressList = [];
        }
      },
      error: err => {
        this.ipAddressList = [];
        this.isLoading = false;
      }
    })
  }


  enableDisableIpList(address, action) {
    CustomPrompt.fire({
      callbackData: address,
      icon: `${action}.svg`,
      cancelButtonText: 'Cancel',
      confirmButtonText: action,
      title: `${action} IP Address: ${address.ipAddress}`,
      text: action === 'enable' ? Action.enable : Action.disable,
    }, (result) => {
      if (result.success) {
        this.modalService.dismissAll();
        this.authService.writeAccess(scopes.ApiKeyIpEnableDisable).subscribe((token) => {
          const payload = {
            id: result.data.id,
            coUserId: result.data.userId,
            coUserName: this.currentIpDetails.name,
          }
          this.apiKeyService.addOrDisableIpAddress(payload, action, token).subscribe({
            next: (res: any) => {
              if (res?.success && res.data) {
                const id = this.ipAddressList.findIndex((item) => item.id === res.data.id);
                this.ipAddressList[id].status = res.data.status;
                this.cdr.detectChanges();
                result.modalRef.close('closed');
                SuccessPrompt.fire({
                  title: "Success",
                  text: `IP address ${res.data.status === 1 ? 'enabled' : 'disabled'} sucessfully !`
                });
              }
              this.resetForm();
            },
            error: (error) => {
              ErrorPrompt.fire({
                title: "Error",
                text: error?.message,
              });
            }
          });
        });
      }
    })
  }



  onIpFormSubmit(form) {
    const payload = {
      coUserId: this.currentIpDetails.id,
      ipAddress: this.ipAddress,
      subnetMask: this.subnetMask,
      coUserName: this.currentIpDetails.name
    }
    this.authService.writeAccess(scopes.ApiKeyIpEnableDisable).subscribe((token) => {
      this.ipForm = form;
      this.apiKeyService.addOrDisableIpAddress(payload, 'oncreate', token).subscribe({
        next: (res: any) => {
          if (res?.success && res.data) {
            this.ipAddressList.push(res.data);
            SuccessPrompt.fire({
              title: "Success",
              text: "IP address added sucessfully !"
            })
          }
          this.resetForm();
        },
        error: (error) => {
          const errors = error?.message ? [{ msg: error?.message }] : error.data.errors;
          let msg = "";
          errors.forEach((err, i) => {
            msg += `${err.msg}<br/>`;
          })
          ErrorPrompt.fire({
            title: "Error",
            text: msg
          });
        }
      });
    }); 
  }

  resetForm() {
    this.ipAddress = "";
    this.subnetMask = "";
    this.ipForm?.form?.reset();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.ipAddressRestrictionc, {
      centered: true,
    });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


}

const enum Action {
  enable = "Are you sure you want to enable this IP address from the whitelist? Enabling this IP address will allow API key access from this location to be accessed.",
  disable = "Are you sure you want to disable this IP address from the whitelist? Disabling this IP address will allow API key access from this location to be blocked."
}