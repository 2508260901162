import { Wallet } from './wallet';

export class Transaction {
  id: number;
  txid: string;
  raw: string;
  walletid: number;
  type: string;
  fee: string;
  effectivechange?: string;
  runningbalance?: string;
  timestamp: Date;
  externaladdress: string;
  externalWalletName?: string;
  fromToAddress?: any;
  block?: number;
  blockConfirmed?: number;
  coin: string;
  wallet: Wallet;
  asset: string;
  effectivechangeusd: any;
  trmResponse: any;
  policyName?: string;
}

export interface BlockchainTx {
  tx_id: string;
  net_change: number;
  balance: number;
  fee?: any;
  block: any[];
}

export interface WalletInfo {
  walletHoldingType: string;
  walletStorageType: string;
  type: string;
  displayName: string;
  walletId: string;
  walletCountry: string;
}

export interface Input {
  address: string;
  walletInfo: WalletInfo;
  valueUnitAmount: number;
}

export interface Output {
  address: string;
  walletInfo: WalletInfo;
  valueUnitAmount: number;
}

export interface PublicTransaction {
  id: number;
  baseValueUnitAmount: number;
  coin: string;
  sourceWallet: string;
  date: Date;
  destinationWallet: string;
  transferType: string;
  type: string;
  walletInfo: WalletInfo;
  inputs?: Input[];
  provider: string;
  outputs?: Output[];
  walletType: string;
  txid: string;
  feesUnitAmount: number;
  usd: number;
  flow: string;
  valueUnitAmount: number;
  lastBalanceUnitAmount?: number;
}

export enum TransactionStatus {
  INITIATED = 1,
  PENDING = 2,
  CONFIRMED = 4,
  REJECTED = 5,
}

/**
 * Enum representing the possible status options for a transaction.
 */
export enum TxStatusOptions {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}
