import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() totalRecords: number = 0;
  @Input() recordList: number = 0;
  @Input() page: number = 1;
  @Input() pageSize: number = 10;
  @Input() ellipses: boolean = false;
  @Input() boundaryLinks: boolean = true;
  @Input() maxSize: number = 5;
  @Output() pageChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onPageChange(pageNo){
    this.pageChange.emit(pageNo);
  }

  getRange(): string {
    const start = (this.page - 1) * this.pageSize + 1;
    const end = Math.min(this.page * this.pageSize, this.totalRecords);
    const total = this.totalRecords;
    if (total === 0) {
      return '0 Items';
    } else {
      return `${start} to ${end} of ${total} entries`;
    }
  }
}
