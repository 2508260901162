  <!-- <button
        appDisableClick
        class="btn btn-secondary-dark btn-round rescan-btn"
        (click)="open(missedTxn)"
      >
        Rescan
      </button> --> 
      <!-- Note : should be enabled once Rescan starts working -->

<ng-template #missedTxn let-modal>
  <div class="modal-header">
    <h2 class="modal-title">Rescan Transaction</h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="icon-close text-dark"></i>
    </button>
  </div>
  <div class="modal-body" style="min-height: 400px">
    <div class="mt-2 pb-2">
      <div class="d-flex font-weight-bold text-muted text-left">
        <label class="mb-2 text-muted font-weight-500 w-100">
          Select Protocol
        </label>
        <div class="px-2 flex-shrink-1">
          <label
            *ngIf="selected_Asset"
            class="link_style text-muted"
            (click)="changeAsset()"
            >Clear</label
          >
        </div>
      </div>

      <div ngbDropdown #myDrop="ngbDropdown" *ngIf="!selected_Asset">
        <input
          autocomplete="off"
          class="form-control search-custom"
          [(ngModel)]="coinname"
          placeholder="Select protocol"
          ngbDropdownAnchor
          (focus)="myDrop.open()"
          type="text"
        />
        <ul ngbDropdownMenu class="digital_asset w-100 py-0">
          <div *ngFor="let t of coinList | search_coin : coinname : 'coinname'">
            <button
              class="dropdown-content card w-100 mb-0 p-0 brd_Box"
              (click)="selectAsset(t.coinname)"
            >
              <li ngbDropdownItem class="p-3">
                <div class="align-items-center">
                  <div class="media border-after-xs align-items-center">
                    <div class="d-flex align-items-center box_Circle_Qr p-2">
                      <span
                        class="network text-dark text-left font-size-14"
                        [ngClass]="t?.protocol?.toLowerCase()"
                      ></span>
                    </div>
                    <div class="d-flex flex-column text-left">
                      <span #name class="m-0 truncate_Name font-size-14">{{
                        getName(t.coinname)
                      }}</span>
                      <span class="text-muted text-left font-size-12"
                        >{{
                          getSupportedCoins(t.coinname) &&
                          getSupportedCoins(t.coinname).length > 0
                            ? getName(t.coinname) + " and all supported tokens"
                            : getSubName(t.coinname) || getName(t.coinname)
                        }}
                      </span>
                    </div>
                    <div class="media-body text-right">
                      <div
                        class="position-relative d-flex justify-content-end ml-3"
                      >
                        <ul class="d-flex assets-group ml-2">
                          <li>
                            <span>
                              <img
                                class="img-fluid for-light img-round-border bg-white"
                                [src]="t.coinname | getIcon"
                                alt=""
                              />
                            </span>
                          </li>
                          <li
                            *ngIf="
                              getSupportedCoins(t.coinname) &&
                              getSupportedCoins(t.coinname).length > 0
                            "
                          >
                            <span
                              *ngIf="
                                getSupportedCoins(t.coinname) &&
                                getSupportedCoins(t.coinname).length > 0
                              "
                              class="mr-1 img-round-border last_count text-primary"
                            >
                              + {{ getSupportedCoins(t.coinname).length - 1 }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </button>
          </div>
        </ul>
      </div>
      <div class="card card-border mb-0" *ngIf="selected_Asset">
        <div class="card-body">
          <div class="align-items-center">
            <div class="media border-after-xs align-items-center">
              <div class="d-flex align-items-center box_Circle_Qr p-2">
                <span
                  class="network text-dark text-left font-size-14"
                  [ngClass]="selected_asset_value.toLowerCase()"
                ></span>
              </div>
              <div class="d-flex flex-column text-left">
                <span #name class="m-0 truncate_Name font-size-14">{{
                  getName(selected_asset_value)
                }}</span>
                <span class="text-muted text-left font-size-12"
                  >{{
                    getSupportedCoins(selected_asset_value) &&
                    getSupportedCoins(selected_asset_value).length > 0
                      ? getName(selected_asset_value) +
                        " and all supported tokens"
                      : getSubName(selected_asset_value) ||
                        getName(selected_asset_value)
                  }}
                </span>
              </div>
              <div class="media-body text-right">
                <div class="position-relative d-flex justify-content-end ml-3">
                  <ul class="d-flex assets-group ml-2">
                    <li>
                      <span>
                        <img
                          class="img-fluid for-light img-round-border bg-white"
                          [src]="selected_asset_value | getIcon"
                          alt=""
                        />
                      </span>
                    </li>
                    <li
                      style="margin-top: 10px"
                      *ngIf="
                        getSupportedCoins(selected_asset_value) &&
                        getSupportedCoins(selected_asset_value).length > 0
                      "
                    >
                      <span
                        style="display: initial; padding: 8.5px"
                        *ngIf="
                          getSupportedCoins(selected_asset_value) &&
                          getSupportedCoins(selected_asset_value).length > 0
                        "
                        class="mr-1 img-round-border last_count text-primary"
                      >
                        +
                        {{ getSupportedCoins(selected_asset_value).length - 1 }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex font-weight-bold text-muted text-left justify-content-between mt-2"
      *ngIf="selected_Asset && coinData?.latest_sync_blocknumber"
    >
      <div>
        <div>Last Synced Block</div>
        <span class="text-primary">{{
          coinData?.latest_sync_blocknumber
        }}</span>
      </div>
      <div>
        <div>Latest Block</div>
        <span class="text-primary">{{ coinData?.latest_blocknumber }}</span>
      </div>
    </div>

    <div
      ngbDropdown
      #myrescan="ngbDropdown"
      *ngIf="selected_Asset"
      class="mt-3 m"
    >
      <div class="d-flex font-weight-bold text-muted text-left">
        <label class="mb-2 text-muted font-weight-500 w-100"> Rescan </label>
      </div>
      <input
        autocomplete="off"
        class="form-control search-custom"
        [(ngModel)]="rescanType.label"
        placeholder="Select Rescan"
        ngbDropdownAnchor
        (focus)="myrescan.open()"
        type="text"
        [disabled]="!coinData?.latest_sync_blocknumber"
      />
      <ul
        ngbDropdownMenu
        class="whitelistscroll py-0"
        style="max-height: 12rem !important; overflow: auto"
      >
        <div
          ngbDropdownItem
          class="d-flex align-items-center p-0"
          *ngFor="let data of rescanTypeList"
        >
          <button
            class="dropdown-content card brd_Box p-0 m-0"
            style="width: 100%; border: none !important"
            (click)="selectRescanType(data)"
          >
            <li ngbDropdownItem class="text-wrap">
              <div class="d-flex flex-column">
                <div class="text-left text-margin py-1">
                  {{ data.label }}
                </div>
                <div class="ml-auto"></div>
              </div>
            </li>
          </button>
        </div>
      </ul>
    </div>

    <ng-container *ngIf="rescanType?.value == 'blockRange'">
      <div
        class="d-flex font-weight-bold text-muted text-left justify-content-between mt-2 mb-2"
      >
        <div>
          <div class="ml-1">From</div>
          <span
            ><input
              type="number"
              [(ngModel)]="fromBlock"
              class="text-primary form-control search-custom mt-1"
              (ngModelChange)="validateUserInput()"
          /></span>
        </div>
        <div>
          <div class="ml-1">To</div>
          <span
            ><input
              type="number"
              [(ngModel)]="toBlock"
              class="text-primary form-control search-custom mt-1"
              (ngModelChange)="validateUserInput()"
          /></span>
        </div>
      </div>
      <div class="text-error" *ngIf="((fromBlock > coinData?.latest_sync_blocknumber) || (toBlock > coinData?.latest_sync_blocknumber)  )">Invalid Range - Block number should be lower than the last synced block.</div>
      <span class="mt-2 text-error" *ngIf="((toBlock - fromBlock) > 10 || ((fromBlock - toBlock) > 10) && toBlock!= null )"> You can only scan up to 10 blocks at a time. Selected block range count is {{blocksRange}}.</span>
    </ng-container>
    <div class="d-inline mt-3" *ngIf="rescanType?.value == 'transactionHash'">
      <label class="mb-2 mt-2 text-muted font-weight-500">
        Enter Tx Hash
      </label>
      <div>
        <input
          class="form-control search-custom mt-1"
          style="border-radius: 6px"
          [(ngModel)]="txHash"
          placeholder="Enter Tx Hash"
          type="text"
          (ngModelChange)="validateUserInput()"
        />
      </div>
      <div></div>
    </div>
  </div>

  <div class="modal-footer border-0">
    <button
      class="btn btn-primary w-100"
      data-original-title=""
      title=""
      (click)="sendRescanRequest()"
      type="submit"
      [disabled]="!ifinputsValid"
    >
      Submit
    </button>
  </div>
</ng-template>
