<div class="row w-100" *ngIf="loading">
    <div class="col-2">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="col-2">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
    <div class="col-3 row">
        <div class="col-4">
            <ngx-skeleton-loader></ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader></ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader></ngx-skeleton-loader>
        </div>
    </div>
    <div class="col-5">
        <ngx-skeleton-loader></ngx-skeleton-loader>
    </div>
</div>

<div class="card card-margin mt-3" *ngIf="loading">
    <div class="table-responsive styled-table">
        <table class="table">
            <thead>
            <tr>
                <th scope="col" style="width: 160px">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of [].constructor(10); let i = index">
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="container-fluid" [ngClass]="loading? 'hide': ''">
    <div class="center card card-margin">
        <div class="col-12 d-flex align-items-center p-4 pr-15 banner-border-bottom">
            <div class="d-flex align-items-center mr-auto" *ngIf="!isWalletTab">
                <h2 class="mr-5 text-muted mb-0 body-font">History</h2>
            </div>
            <div class="d-flex">
                <div
                        class="search-round"
                        id="transaction-search-id"
                        *ngIf="txs != null"
                >
                    <i class="icon-search" aria-hidden="true"></i>
                    <input
                            class="form-control"
                            placeholder="Search"
                            [(ngModel)]="txHistoryService.currentSearchString"
                            (ngModelChange)="search($event)"
                            type="text"
                    />
                </div>
                <button
                        [ngClass]="loading ? 'disabled' : (successButtonActive ? 'text-success' : '')"
                        class="btn btn-md btn-round m-l-20 tx-status"
                        (click)="filterTxByStatus('CONFIRMED')"
                >
                    Success | {{ txSuccessCount }}
                </button>

                <button
                        [ngClass]="loading ? 'disabled' : (pendingButtonActive ? 'text-pending' : '')"
                        class="btn btn-md btn-round m-l-20 tx-status"
                        (click)="filterTxByStatus('PENDING')"
                >
                    Pending | {{ txPendingCount }}
                </button>

                <button
                        [ngClass]="loading ? 'disabled' : (failedButtonActive ? 'text-failed' : '')"
                        class="btn btn-md btn-round m-l-20 tx-status"
                        (click)="filterTxByStatus('FAILED')"
                >
                    Rejected | {{ txFailCount }}
                </button>
                <div
                        class="text-right m-l-20"
                        *ngIf="
            transactions_component_for!=='recenttrs_wallet_details'
          "
                >
                    <app-date-range-selection
                            (dateRangeSelection)="onDateRangeSelection($event)"
                    ></app-date-range-selection>
                </div>
                <div *ngIf="!isWalletTab">
                    <app-missed-transaction></app-missed-transaction>
                </div>
                <div
                        class="text-right m-l-20"
                        *ngIf="
            transactions_component_for!=='recenttrs_wallet_details'
          "
                >
                    <button
                            [ngClass]="this.fromDate && !downloading ? '' : 'disabled'"
                            class="btn btn-outline-primary btn-md btn-round"
                            (click)="Downloadcsv()"
                            [ngbTooltip]="this.fromDate ? '' : 'Please select date range to download report'"
                            [disabled]="downloading"
                    >
                        <i class="icon-download m-r-10" aria-hidden="true"></i>Download
                    </button>
                </div>
                <div
                        *ngIf="
            transactions_component_for === 'recenttrs_wallet_details'
          "
                >
                    <a
                            (click)="showHistoryWalletDetail()"
                            class="btn btn-outline-primary btn-md"
                    >View all</a
                    >
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div
                        class="card"
                        [ngClass]="{
            'card-shadow-border':
              transactions_component_for === 'wallet_details'
          }"
                >
                    <div class="table-responsive table-new">
                        <table class="table">
                            <thead>
                            <tr>
                                <th
                                        scope="col"
                                        class="text-left min-w-28"
                                        [ngClass]="{
                      'pl-4': transactions_component_for === 'wallet_details'
                    }"
                                >
                                    Asset
                                </th>
                                <th scope="col" class="text-left">Type</th>
                                <th scope="col" class="text-left">Amount</th>
                                <th scope="col" class="text-left">Transaction Hash</th>
                                <th scope="col" class="text-left">From</th>
                                <th scope="col" class="text-left">To</th>
                                <th scope="col" class="text-left time-header">Time</th>
                                <th scope="col" class="text-center">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    *ngFor="let txdata of txs, let i = index"
                            >
                                <td
                                        [ngClass]="{
                      'pl-4': transactions_component_for === 'wallet_details'
                    }"
                                >
                                    <div
                                            *ngIf="txdata.NftAsset; else elseDiv"
                                            class="d-flex align-items-center"
                                    >
                                        <!--suppress TypeScriptUnresolvedReference -->
                                        <img
                                                class="align-self-center mr-1 img-fluid img-round-border p-0 mr-2"
                                                src="{{ txdata.NftAsset.imageurl }}"
                                                (error)="
                          $event.target.src =
                            'https://assets.lmnl.app/nft-default.png';
                          $event.target.classList.add('nft-defualt-img-roung')
                        "
                                                alt="nft img"
                                        />
                                        <div class="d-flex flex-column">
                        <span class="d-inline-block">
                          <ng-container
                                  *ngIf="
                              txdata.NftAsset.comman_name;
                              else defaultName
                            "
                          >{{ txdata.NftAsset.comman_name }}</ng-container
                          >
                          <ng-template #defaultName
                          >NFT #{{ txdata.NftAsset.symbol }}</ng-template
                          >
                        </span>
                                            <span
                                                    class="text-dark dottedtextnft"
                                                    placement="top"
                                                    [ngbTooltip]="
                            txdata.NftAsset.symbol.length > 10
                              ? txdata.NftAsset.symbol
                              : ''
                          "
                                            >{{ txdata.NftAsset.symbol }}</span
                                            >
                                        </div>
                                    </div>
                                    <ng-template #elseDiv>
                                        <div class="d-inline-flex align-items-center">
                        <span class="mr-1 link_Circle position-relative mr-2">
                          <img
                                  class="img-fluid for-light img-round-border"
                                  [src]="txdata.coin | getIcon"
                                  alt=""
                          />
                        </span>
                                            {{ txdata.coin }}
                                        </div>
                                    </ng-template>
                                </td>
                                <td>
                                    <container-element [ngSwitch]="txdata.type.toLowerCase()">
                                        <p
                                                *ngSwitchCase="'send'"
                                                class="text-primary m-0"
                                                style="text-transform: capitalize"
                                        >
                                            {{ txdata.type }}
                                        </p>
                                        <p
                                                *ngSwitchCase="'receive'"
                                                class="text-primary m-0"
                                                style="text-transform: capitalize"
                                        >
                                            {{ txdata.type }}
                                        </p>
                                        <p
                                                *ngSwitchDefault
                                                class="m-0"
                                                style="text-transform: capitalize"
                                        >
                                            {{ txdata.type }}
                                        </p>
                                    </container-element>
                                    <p
                                            *ngIf="txdata.NftAsset"
                                            class="text-dark text-capitalize m-0"
                                    >
                                        NFT
                                    </p>
                                </td>
                                <td class="text-muted">
                                    <p class="mb-0 body-font" *ngIf="txdata.effectivechangeusd">
                                        ${{ txdata.effectivechangeusd | amountToPretty : 2 }}
                                    </p>
                                    <span class="amtDigit text-dark">
                      <ng-container *ngIf="txdata.NftAsset; else elseCoin"
                      >{{ txdata.NftAsset.nativePrice | amountToPretty : 5 }}
                          {{ txdata.wallet.chain }}</ng-container
                      >
                      <ng-template #elseCoin
                      >{{ txdata.effectivechange | amountToPretty : 5 }}
                          {{ txdata.coin }}</ng-template
                      >
                    </span>
                                </td>
                                <!-- Tx Hash Column -->
                                <td class="text-left"
                                    *ngIf="txdata.status === TxStatus.CONFIRMED.toLowerCase() ;else pendingTxId">
                                    <div>
                                        <a (click)="navigateToExplorer(txdata?.explorerLink)" class="cursor-pointer"
                                           ngbTooltip="{{ txdata?.txid }}">
                                            <p class="border-bottom-td m-0 d-inline-block">
                                                {{ txdata?.txid?.substring(0, 10) }}
                                                ...{{ txdata?.txid?.substring(txdata?.txid?.length - 5) }}
                                            </p>
                                        </a>
                                    </div>
                                </td>
                                <ng-template #pendingTxId>
                                    <td class="pr-60">
                                        <span>&mdash;</span>
                                    </td>
                                </ng-template>
                                <!-- From Address Column -->
                                <td>
                                    <div class="float-container">
                                        <span class="d-block">{{ txdata?.fromToAddress?.fromName }}</span>
                                        <app-copy-address
                                                [address]="txdata?.fromToAddress?.fromAddress"
                                        ></app-copy-address>
                                    </div>
                                </td>
                                <td>
                                    <div class="float-container">
                                        <span class="d-block">{{ txdata?.fromToAddress?.toName }}</span>
                                        <app-copy-address
                                                [address]="txdata?.fromToAddress?.toAddress"
                                        ></app-copy-address>
                                        <span class="d-block" *ngIf="txdata.raw"
                                        >Memo/Tag - {{ txdata.raw }}</span
                                        >
                                    </div>
                                </td>
                                <ng-template>
                                    <div>
                                        <span>{{ txdata?.block || '-' }}</span> <br/>
                                        <span>{{ txdata.blockConfirmed ? (txdata.blockConfirmed === 1 ? txdata.blockConfirmed + ' Confirmation' : txdata.blockConfirmed + ' Confirmations') : '-' }}</span>
                                    </div>
                                </ng-template>
                                <td class="text-muted text-left">
                                    <div>
                                        {{ txdata.timestamp | dateWithZone }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div>
                                        <ng-container
                                                *ngIf="txdata.status === TxStatus.CONFIRMED.toLowerCase() || txdata?.type=='receive';">
                                            <span class="text-success">Success</span>
                                        </ng-container>
                                        <ng-container *ngIf="txdata.status === TxStatus.PENDING.toLowerCase();">
                                            <span class="text-pending ">Pending</span>
                                        </ng-container>
                                        <ng-container *ngIf="txdata.status === TxStatus.FAILED.toLowerCase();">
                                            <span class="text-failed ">Rejected</span>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="txs?.length == 0"
                            >
                                <td colspan="9" class="text-center">No data</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="pagination-block">
                    <app-pagination [totalRecords]="paginationMetaData?.totalCount" [recordList]="txs?.length"
                                    [page]="paginationMetaData.currentPage" [pageSize]="paginationMetaData.pageSize"
                                    (pageChange)="onPageChange($event)" [maxSize]="3"></app-pagination>
                </div>
            </div>

        </div>
    </div>
</div>

<div
        *ngIf="!txs?.length && !loading"
        class="card d-flex align-items-center p-4 card-shadow-border"
>
    <img
            src="../../../assets/images/placeholder/transaction.png"
            width="125"
            class="mb-4"
            alt="transaction"/>
    <h6>No transaction yet</h6>
    <p class="mb-0">Send or receive assets to view the transaction history</p>
</div>

<ng-template #transactionalert let-modal>
    <div class="modal-header border-0">
        <h2 class="modal-title">No Transactions</h2>
        <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
        >
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body text-center">
        <span h5 class="text-center">No transactions in selected time period.</span>
    </div>
</ng-template>
