"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PermanentLockedAccount = exports.PeriodicVestingAccount = exports.Period = exports.DelayedVestingAccount = exports.ContinuousVestingAccount = exports.BaseVestingAccount = exports.protobufPackage = void 0;
/* eslint-disable */
const auth_1 = require("../../auth/v1beta1/auth");
const coin_1 = require("../../base/v1beta1/coin");
const binary_1 = require("../../../binary");
const helpers_1 = require("../../../helpers");
exports.protobufPackage = "cosmos.vesting.v1beta1";
function createBaseBaseVestingAccount() {
  return {
    baseAccount: undefined,
    originalVesting: [],
    delegatedFree: [],
    delegatedVesting: [],
    endTime: BigInt(0)
  };
}
exports.BaseVestingAccount = {
  typeUrl: "/cosmos.vesting.v1beta1.BaseVestingAccount",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.baseAccount !== undefined) {
      auth_1.BaseAccount.encode(message.baseAccount, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.originalVesting) {
      coin_1.Coin.encode(v, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.delegatedFree) {
      coin_1.Coin.encode(v, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.delegatedVesting) {
      coin_1.Coin.encode(v, writer.uint32(34).fork()).ldelim();
    }
    if (message.endTime !== BigInt(0)) {
      writer.uint32(40).int64(message.endTime);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBaseVestingAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.baseAccount = auth_1.BaseAccount.decode(reader, reader.uint32());
          break;
        case 2:
          message.originalVesting.push(coin_1.Coin.decode(reader, reader.uint32()));
          break;
        case 3:
          message.delegatedFree.push(coin_1.Coin.decode(reader, reader.uint32()));
          break;
        case 4:
          message.delegatedVesting.push(coin_1.Coin.decode(reader, reader.uint32()));
          break;
        case 5:
          message.endTime = reader.int64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseBaseVestingAccount();
    if ((0, helpers_1.isSet)(object.baseAccount)) obj.baseAccount = auth_1.BaseAccount.fromJSON(object.baseAccount);
    if (Array.isArray(object?.originalVesting)) obj.originalVesting = object.originalVesting.map(e => coin_1.Coin.fromJSON(e));
    if (Array.isArray(object?.delegatedFree)) obj.delegatedFree = object.delegatedFree.map(e => coin_1.Coin.fromJSON(e));
    if (Array.isArray(object?.delegatedVesting)) obj.delegatedVesting = object.delegatedVesting.map(e => coin_1.Coin.fromJSON(e));
    if ((0, helpers_1.isSet)(object.endTime)) obj.endTime = BigInt(object.endTime.toString());
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.baseAccount !== undefined && (obj.baseAccount = message.baseAccount ? auth_1.BaseAccount.toJSON(message.baseAccount) : undefined);
    if (message.originalVesting) {
      obj.originalVesting = message.originalVesting.map(e => e ? coin_1.Coin.toJSON(e) : undefined);
    } else {
      obj.originalVesting = [];
    }
    if (message.delegatedFree) {
      obj.delegatedFree = message.delegatedFree.map(e => e ? coin_1.Coin.toJSON(e) : undefined);
    } else {
      obj.delegatedFree = [];
    }
    if (message.delegatedVesting) {
      obj.delegatedVesting = message.delegatedVesting.map(e => e ? coin_1.Coin.toJSON(e) : undefined);
    } else {
      obj.delegatedVesting = [];
    }
    message.endTime !== undefined && (obj.endTime = (message.endTime || BigInt(0)).toString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseBaseVestingAccount();
    if (object.baseAccount !== undefined && object.baseAccount !== null) {
      message.baseAccount = auth_1.BaseAccount.fromPartial(object.baseAccount);
    }
    message.originalVesting = object.originalVesting?.map(e => coin_1.Coin.fromPartial(e)) || [];
    message.delegatedFree = object.delegatedFree?.map(e => coin_1.Coin.fromPartial(e)) || [];
    message.delegatedVesting = object.delegatedVesting?.map(e => coin_1.Coin.fromPartial(e)) || [];
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = BigInt(object.endTime.toString());
    }
    return message;
  }
};
function createBaseContinuousVestingAccount() {
  return {
    baseVestingAccount: undefined,
    startTime: BigInt(0)
  };
}
exports.ContinuousVestingAccount = {
  typeUrl: "/cosmos.vesting.v1beta1.ContinuousVestingAccount",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.baseVestingAccount !== undefined) {
      exports.BaseVestingAccount.encode(message.baseVestingAccount, writer.uint32(10).fork()).ldelim();
    }
    if (message.startTime !== BigInt(0)) {
      writer.uint32(16).int64(message.startTime);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContinuousVestingAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.baseVestingAccount = exports.BaseVestingAccount.decode(reader, reader.uint32());
          break;
        case 2:
          message.startTime = reader.int64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseContinuousVestingAccount();
    if ((0, helpers_1.isSet)(object.baseVestingAccount)) obj.baseVestingAccount = exports.BaseVestingAccount.fromJSON(object.baseVestingAccount);
    if ((0, helpers_1.isSet)(object.startTime)) obj.startTime = BigInt(object.startTime.toString());
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.baseVestingAccount !== undefined && (obj.baseVestingAccount = message.baseVestingAccount ? exports.BaseVestingAccount.toJSON(message.baseVestingAccount) : undefined);
    message.startTime !== undefined && (obj.startTime = (message.startTime || BigInt(0)).toString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseContinuousVestingAccount();
    if (object.baseVestingAccount !== undefined && object.baseVestingAccount !== null) {
      message.baseVestingAccount = exports.BaseVestingAccount.fromPartial(object.baseVestingAccount);
    }
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = BigInt(object.startTime.toString());
    }
    return message;
  }
};
function createBaseDelayedVestingAccount() {
  return {
    baseVestingAccount: undefined
  };
}
exports.DelayedVestingAccount = {
  typeUrl: "/cosmos.vesting.v1beta1.DelayedVestingAccount",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.baseVestingAccount !== undefined) {
      exports.BaseVestingAccount.encode(message.baseVestingAccount, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDelayedVestingAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.baseVestingAccount = exports.BaseVestingAccount.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseDelayedVestingAccount();
    if ((0, helpers_1.isSet)(object.baseVestingAccount)) obj.baseVestingAccount = exports.BaseVestingAccount.fromJSON(object.baseVestingAccount);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.baseVestingAccount !== undefined && (obj.baseVestingAccount = message.baseVestingAccount ? exports.BaseVestingAccount.toJSON(message.baseVestingAccount) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseDelayedVestingAccount();
    if (object.baseVestingAccount !== undefined && object.baseVestingAccount !== null) {
      message.baseVestingAccount = exports.BaseVestingAccount.fromPartial(object.baseVestingAccount);
    }
    return message;
  }
};
function createBasePeriod() {
  return {
    length: BigInt(0),
    amount: []
  };
}
exports.Period = {
  typeUrl: "/cosmos.vesting.v1beta1.Period",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.length !== BigInt(0)) {
      writer.uint32(8).int64(message.length);
    }
    for (const v of message.amount) {
      coin_1.Coin.encode(v, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePeriod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.length = reader.int64();
          break;
        case 2:
          message.amount.push(coin_1.Coin.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBasePeriod();
    if ((0, helpers_1.isSet)(object.length)) obj.length = BigInt(object.length.toString());
    if (Array.isArray(object?.amount)) obj.amount = object.amount.map(e => coin_1.Coin.fromJSON(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.length !== undefined && (obj.length = (message.length || BigInt(0)).toString());
    if (message.amount) {
      obj.amount = message.amount.map(e => e ? coin_1.Coin.toJSON(e) : undefined);
    } else {
      obj.amount = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBasePeriod();
    if (object.length !== undefined && object.length !== null) {
      message.length = BigInt(object.length.toString());
    }
    message.amount = object.amount?.map(e => coin_1.Coin.fromPartial(e)) || [];
    return message;
  }
};
function createBasePeriodicVestingAccount() {
  return {
    baseVestingAccount: undefined,
    startTime: BigInt(0),
    vestingPeriods: []
  };
}
exports.PeriodicVestingAccount = {
  typeUrl: "/cosmos.vesting.v1beta1.PeriodicVestingAccount",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.baseVestingAccount !== undefined) {
      exports.BaseVestingAccount.encode(message.baseVestingAccount, writer.uint32(10).fork()).ldelim();
    }
    if (message.startTime !== BigInt(0)) {
      writer.uint32(16).int64(message.startTime);
    }
    for (const v of message.vestingPeriods) {
      exports.Period.encode(v, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePeriodicVestingAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.baseVestingAccount = exports.BaseVestingAccount.decode(reader, reader.uint32());
          break;
        case 2:
          message.startTime = reader.int64();
          break;
        case 3:
          message.vestingPeriods.push(exports.Period.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBasePeriodicVestingAccount();
    if ((0, helpers_1.isSet)(object.baseVestingAccount)) obj.baseVestingAccount = exports.BaseVestingAccount.fromJSON(object.baseVestingAccount);
    if ((0, helpers_1.isSet)(object.startTime)) obj.startTime = BigInt(object.startTime.toString());
    if (Array.isArray(object?.vestingPeriods)) obj.vestingPeriods = object.vestingPeriods.map(e => exports.Period.fromJSON(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.baseVestingAccount !== undefined && (obj.baseVestingAccount = message.baseVestingAccount ? exports.BaseVestingAccount.toJSON(message.baseVestingAccount) : undefined);
    message.startTime !== undefined && (obj.startTime = (message.startTime || BigInt(0)).toString());
    if (message.vestingPeriods) {
      obj.vestingPeriods = message.vestingPeriods.map(e => e ? exports.Period.toJSON(e) : undefined);
    } else {
      obj.vestingPeriods = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBasePeriodicVestingAccount();
    if (object.baseVestingAccount !== undefined && object.baseVestingAccount !== null) {
      message.baseVestingAccount = exports.BaseVestingAccount.fromPartial(object.baseVestingAccount);
    }
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = BigInt(object.startTime.toString());
    }
    message.vestingPeriods = object.vestingPeriods?.map(e => exports.Period.fromPartial(e)) || [];
    return message;
  }
};
function createBasePermanentLockedAccount() {
  return {
    baseVestingAccount: undefined
  };
}
exports.PermanentLockedAccount = {
  typeUrl: "/cosmos.vesting.v1beta1.PermanentLockedAccount",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.baseVestingAccount !== undefined) {
      exports.BaseVestingAccount.encode(message.baseVestingAccount, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePermanentLockedAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.baseVestingAccount = exports.BaseVestingAccount.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBasePermanentLockedAccount();
    if ((0, helpers_1.isSet)(object.baseVestingAccount)) obj.baseVestingAccount = exports.BaseVestingAccount.fromJSON(object.baseVestingAccount);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.baseVestingAccount !== undefined && (obj.baseVestingAccount = message.baseVestingAccount ? exports.BaseVestingAccount.toJSON(message.baseVestingAccount) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBasePermanentLockedAccount();
    if (object.baseVestingAccount !== undefined && object.baseVestingAccount !== null) {
      message.baseVestingAccount = exports.BaseVestingAccount.fromPartial(object.baseVestingAccount);
    }
    return message;
  }
};
