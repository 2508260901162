/* istanbul ignore file: pure exports */
/**
 * List of selectors (hexadecimal representation of the used method's signature) related to
 * ERC20 (Tokens), ERC721/ERC1155 (NFT).
 * You can verify and/or get more info about them on http://4byte.directory
 */
export var ERC20_CLEAR_SIGNED_SELECTORS;
(function (ERC20_CLEAR_SIGNED_SELECTORS) {
  ERC20_CLEAR_SIGNED_SELECTORS["APPROVE"] = "0x095ea7b3";
  ERC20_CLEAR_SIGNED_SELECTORS["TRANSFER"] = "0xa9059cbb";
})(ERC20_CLEAR_SIGNED_SELECTORS || (ERC20_CLEAR_SIGNED_SELECTORS = {}));
export var ERC721_CLEAR_SIGNED_SELECTORS;
(function (ERC721_CLEAR_SIGNED_SELECTORS) {
  ERC721_CLEAR_SIGNED_SELECTORS["APPROVE"] = "0x095ea7b3";
  ERC721_CLEAR_SIGNED_SELECTORS["SET_APPROVAL_FOR_ALL"] = "0xa22cb465";
  ERC721_CLEAR_SIGNED_SELECTORS["TRANSFER_FROM"] = "0x23b872dd";
  ERC721_CLEAR_SIGNED_SELECTORS["SAFE_TRANSFER_FROM"] = "0x42842e0e";
  ERC721_CLEAR_SIGNED_SELECTORS["SAFE_TRANSFER_FROM_WITH_DATA"] = "0xb88d4fde";
})(ERC721_CLEAR_SIGNED_SELECTORS || (ERC721_CLEAR_SIGNED_SELECTORS = {}));
export var ERC1155_CLEAR_SIGNED_SELECTORS;
(function (ERC1155_CLEAR_SIGNED_SELECTORS) {
  ERC1155_CLEAR_SIGNED_SELECTORS["SET_APPROVAL_FOR_ALL"] = "0xa22cb465";
  ERC1155_CLEAR_SIGNED_SELECTORS["SAFE_TRANSFER_FROM"] = "0xf242432a";
  ERC1155_CLEAR_SIGNED_SELECTORS["SAFE_BATCH_TRANSFER_FROM"] = "0x2eb2c2d6";
})(ERC1155_CLEAR_SIGNED_SELECTORS || (ERC1155_CLEAR_SIGNED_SELECTORS = {}));
export default {
  ERC20_CLEAR_SIGNED_SELECTORS,
  ERC721_CLEAR_SIGNED_SELECTORS,
  ERC1155_CLEAR_SIGNED_SELECTORS
};
