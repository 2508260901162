<div class="d-inline-block avatar-group members">
    <div class="scoll-pane rounded-pill">
        <ng-container>
            <ul class="d-flex p-2">
                <ng-container *ngFor="let member of members;let i = index;">
                    <li *ngIf="i < NUMBER_OF_MEMBERS_TO_SHOW" class="d-inline-block avatar-main" placement="top" ngbTooltip="{{ member.user?.displayName }}">
                        <img *ngIf="member.user?.photoURL" class="align-self-center img-40 rounded-circle text-left avatar" src="{{member.user?.photoURL}}">
                        <img *ngIf="!member.user?.photoURL" class="align-self-center img-40 rounded-circle text-left avatar" src="assets/images/user-default-image.svg">
                      </li>
                </ng-container>
                <!-- Show a "show more" button when there are more than x members -->
                <li *ngIf="members.length > NUMBER_OF_MEMBERS_TO_SHOW" class="d-inline-block li-more">
                    <div class="avatar-more align-self-center rounded-circle text-center" [ngbPopover]="popoverContent"
                        triggers="mouseenter:mouseleave" [openDelay]="100" [closeDelay]="200" placement="auto"
                        popoverClass="popover-class">
                        <div class="d-flex justify-content-center align-items-center text-muted" style="height: 100%;">
                            +{{ members.length - NUMBER_OF_MEMBERS_TO_SHOW }}
                        </div>
                    </div>
                </li>
            </ul>
        </ng-container>
        <ng-template #popoverContent>
            <div class="popover-content-grid">
              <div *ngFor="let member of members; let i = index" class="popover-member">
                <img *ngIf="member.user?.photoURL" class="popover-avatar img-30 rounded-circle mr-2" src="{{ member.user?.photoURL }}">
                <img *ngIf="!member.user?.photoURL" class="popover-avatar img-30 rounded-circle mr-2" src="assets/images/user-default-image.svg">
                
                <span class="popover-name" ngbTooltip="{{ member.user?.displayName }}">{{ member.user?.displayName }}</span>
              </div>
            </div>
          </ng-template>
          
    </div>
</div>