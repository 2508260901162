<div class="card-body p-0" >
  <div class="row walletCard" >
    <div class="col-6 pl-0" >
      <div class="card card-shadow-border addresses-card">
        <div class="card-body justify-content-between p-4 align-items-center" >
          <div class="media border-after-xs">
            <div class="d-flex flex-column mt-1 text-dark">
              <span>{{ "Addresses Created" }}
                <span
                  class="info-icon"
                  ngbTooltip ='Number of Addresses in wallet'
                >
                  <img
                  src="../../../../assets/images/info-grey.svg"
                />
                </span>
              </span>
            </div>
            <div class="media-body text-right mt-1">
              <h2 class="card-balance m-0">{{ totalAddresses | number }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 pr-0" >
      <div class="card mb-4 card-shadow-border addresses-card">
        <div class="card-body justify-content-between p-4">
          <div class="media border-after-xs align-items-center">
            <div class="d-flex flex-column mt-1 text-dark">
              <span>{{ "Addresses with Balance" }}
                <span 
                class="info-icon"
                ngbTooltip ='Number of Addresses with balance'
                >
                  <img
                  src="../../../../assets/images/info-grey.svg"
                />
                </span>
              </span>
            </div>
            <div class="media-body text-right mt-1">
              <h2 class="card-balance m-0">
                {{ addressWithBalance | number }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="center card card-margin card-shadow-border addresses-details">
  <div class="d-flex justify-content-between p-4">
    <h2 class="mr-2 mb-0 font-weight-500">Addresses Balance</h2>
  </div>
  <div class="d-flex justify-content-between pl-4">
    <ul class="nav nav-tab mb-4">
      <li class="nav-item" >
        <a
          class="nav-link"
          [class]="{ active: _tab == 'native' }"
          (click)="changeTab('native')"
          [class.disabled]="loading"
        >
          Native Assets
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [class]="{ active: _tab == 'others' }"
          (click)="changeTab('others')"
          [class.disabled]="loading"
          >Other Assets
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [class]="{ active: _tab == 'search' }"
          (click)="changeTab('search')"
          [class.disabled]="loading"
          >Search
        </a>
      </li>
    </ul>
  </div>
  <div class="table-responsive table-new" *ngIf="loading">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of [].constructor(10); let i = index">
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
            </tr>
        </tbody>
    </table>
</div>
  <div *ngIf="_tab == 'native' && !loading">
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive table-new">
          <table class="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-left pl-4"
                  style="width: 20%; min-width: 150px"
                >
                  Address Index
                </th>
                <th scope="col" class="text-left" style="width: 20%">
                  Address
                </th>
                <th scope="col" class="text-left" style="width: 20%">Assets</th>
                <th
                  scope="col"
                  class="text-right"
                  style="width: 20%; min-width: 130px"
                >
                  Balance
                </th>
                <th
                  scope="col"
                  class="text-right"
                  style="width: 20%; min-width: 180px"
                >
                  Last Transaction
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let addr of addressesArray
                    | slice
                      : (page - 1) * pageSize
                      : (page - 1) * pageSize + pageSize
                "
              >
                <ng-container>
                  <td scope="col" class="text-left pl-4" style="width: 16%">
                    {{ addr.addressIndex }}
                  </td>
                  <td scope="col" class="text-left" style="width: 16%;">
                      <app-copy-address [address]="addr?.address">
                    </app-copy-address>
                  </td>

                  <!-- Column: Asset -->
                  <td class="cursor-pointer">
                    <div class="position-relative d-flex justify-content-start">
                      <ul class="d-flex assets-group">
                        <li *ngFor="let asset of addr?.assets | slice : 0 : 3">
                          <span>
                            <img
                              class="img-fluid for-light img-round-border"
                              [src]="asset.name | getIcon"
                              alt=""
                            />
                          </span>
                          <span>
                            {{ asset.name }}
                          </span>
                        </li>
                        <li *ngIf="addr?.assets?.length > 3">
                          <span class="mr-1 img-round-border last_count">
                            + {{ addr.assets.length - 3 }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </td>

                  <!-- Column: Balance -->
                  <td scope="col" class="text-right" style="width: 12%">
                    <span class="d-block" placement="top">
                      {{ getTotalCryptoBalance(addr.assets) }}
                      {{ addr.assets[0].name }}
                    </span>
                    <span class="d-block text-dark">
                      {{ addr.balanceUSD | currency: 'USD': 'symbol':'1.2-2':'en-US' }}
                    </span>
                  </td>

                  <!-- Column: Last Transaction -->
                   <ng-container *ngIf="addr.lastTxnTimestamp">
                    <td scope="col" class="text-right" *ngIf="addr.lastTxnTimestamp | dateWithZone as formattedDate" >
                      <span class="d-block" placement="top">
                        {{ formattedDate.split(' ')[0] }}
                      </span>
                      <span class="d-block text-dark text-right">
                        {{ formattedDate.split(' ')[1] + ' ' + formattedDate.split(' ')[2] }}
                      </span>
                    </td>
                   </ng-container>
                  <td scope="col" class="text-right" *ngIf="!addr.lastTxnTimestamp">
                    --
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 my-3"  *ngIf="addressesArray && addressesArray.length > 0">
      <div class="d-flex justify-content-between align-items-center">
        <span class="table-footer-text">
          <span>{{
            addressesArray?.length | paginationFromTo : page : pageSize
          }}</span>
          of {{totalAddresses}} Items
        </span>
        <div class="pagination">
          <ngb-pagination
            [collectionSize]="addressesArray?.length"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="3"
            [boundaryLinks]="true"
            [ellipses]="false"
            (pageChange)="onPageChange($event)"
          >
            <ng-template class="left_arrow" ngbPaginationFirst
              ><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i
              ><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i
            ></ng-template>
            <ng-template class="left_arrow" ngbPaginationPrevious
              ><i
                class="icon-arrow-left"
                placement="top"
                ngbTooltip="Previous"
              ></i
            ></ng-template>
            <ng-template class="right_arrow" ngbPaginationNext
              ><i class="icon-arrow-right" placement="top" ngbTooltip="Next"></i
            ></ng-template>
            <ng-template class="right_arrow" ngbPaginationLast
              ><i class="icon-arrow-right" placement="top" ngbTooltip="Last"></i
              ><i class="icon-arrow-right"></i
            ></ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="_tab == 'others' && !loading">
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive table-new">
          <table class="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th
                  scope="col"
                  class="text-left pl-4"
                  style="width: 20%; min-width: 150px"
                >
                  Address Index
                </th>
                <th scope="col" class="text-left" style="width: 20%">
                  Address
                </th>
                <th scope="col" class="text-left" style="width: 20%">Assets</th>
                <th
                  scope="col"
                  class="text-left"
                  style="width: 20%; min-width: 130px"
                >
                  Balance
                </th>
                <th
                  scope="col"
                  class="text-left"
                  style="width: 20%; min-width: 180px"
                >
                  Last Transaction
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="addressesArray && addressesArray?.length">
                <ng-container
                  *ngFor="
                    let addr of addressesArray
                      | slice
                        : (page - 1) * pageSize
                        : (page - 1) * pageSize + pageSize
                  "
                >
                  <tr *ngIf="addr.balanceUSD > 0">
                    <!-- Column: accordion -->
                    <td>
                      <img
                        class="cursor-pointer"
                        [ngClass]="{ 'open-state': addr.state == 'open' }"
                        *ngIf="addr.assets.length > 0"
                        (click)="accordion(addr.address)"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBnc/RCcIwEAbg/z/wvSM4gitkAjeRggjSAUTwJW7gJu0IjtARfBc8c3ko9JqW0ntJ+C9fkgM2Fk/nJlJw5PcXYrz3S4fr+rrXnbRp24kIXlBWFlhjBaoIPJnDS3NQZQvqp/SyQyE+bm8OzRlcQnnG0c0OW1ZCE+ixzZ5WeGQlHub/U0NCfUJdCVn9AYaBV93eNb1AAAAAAElFTkSuQmCC"
                      />
                    </td>

                    <!-- Column: Address Index -->
                    <td scope="col" class="text-left pl-4" style="width: 16%">
                      {{ addr.addressIndex }}
                    </td>

                    <!-- Column: Address -->
                    <td scope="col" class="text-left" style="width: 16%">
                        <app-copy-address [address]="addr?.address">
                       </app-copy-address>
                    </td>

                    <!-- Column: Asset -->
                    <td class="cursor-pointer">
                      <div
                        class="position-relative d-flex justify-content-start"
                      >
                        <ul
                          class="d-flex assets-group"
                          *ngIf="addr && addr?.assets && addr?.assets?.length"
                        >
                          <li
                            *ngFor="let asset of addr?.assets | slice : 0 : 3"
                          >
                            <span>
                              <img
                                class="img-fluid for-light img-round-border"
                                [src]="asset.name | getIcon"
                                alt=""
                              />
                            </span>
                          </li>
                          <li *ngIf="addr?.assets?.length > 3">
                            <span class="mr-1 img-round-border last_count">
                              + {{ addr?.assets?.length - 3 }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </td>

                    <!-- Column: Balance -->
                    <td scope="col" class="text-left" style="width: 12%">
                      <span class="d-block" placement="top">
                        {{ addr.balanceUSD | currency: 'USD': 'symbol':'1.2-2':'en-US' }}
                      </span>
                    </td>

                    <!-- Column: Last Transaction -->
                    <ng-container *ngIf="addr.lastTxnTimestamp">
                      <td scope="col" class="text-left" *ngIf="addr.lastTxnTimestamp | dateWithZone as formattedDate" >
                        <span class="d-block" placement="top">
                          {{ formattedDate.split(' ')[0] }}
                        </span>
                        <span class="d-block text-dark text-left">
                          {{ formattedDate.split(' ')[1] + ' ' + formattedDate.split(' ')[2] }}
                        </span>
                      </td>
                      <td scope="col" class="text-left" *ngIf="!addr.lastTxnTimestamp">
                        --
                      </td>
                     </ng-container>
                  </tr>
                  <ng-container
                    *ngIf="addr.state === 'open' && addr.assets.length > 0"
                  >
                    <tr *ngFor="let addrAsset of addr.assets" style="background-color: #1B1D32;">
                      <td scope="col"></td>
                      <td
                        scope="col"
                        class="text-left pl-4"
                        style="width: 20%; min-width: 150px"
                      ></td>
                      <td scope="col" class="text-left" style="width: 20%"></td>
                      <td scope="col" class="text-left" style="width: 20%">
                        <span>
                          <img
                            class="img-fluid for-light img-round-border"
                            [src]="addrAsset.name | getIcon"
                            alt="addrAsset.name"
                          />
                        </span>
                      </td>
                      <td
                        scope="col"
                        colspan="2"
                        class="text-left"
                        style="width: 20%; min-width: 130px"
                      >
                        <span class="d-block" placement="top">
                          {{ addrAsset.balance | amountToPretty : 8 }} {{ addrAsset.name }}
                        </span>
                        <span class="d-block text-dark">
                          {{ addrAsset.balanceUSD | currency: 'USD': 'symbol':'1.2-2':'en-US' }}
                        </span>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 my-3" *ngIf="addressesArray && addressesArray?.length">
      <div class="d-flex justify-content-between align-items-center">
        <span class="table-footer-text">
          <span>{{
            addressesArray?.length | paginationFromTo : page : pageSize
          }}</span>
          of {{addressesArray?.length}} Items
        </span>
        <div class="pagination">
          <ngb-pagination
            [collectionSize]="totalPages"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="3"
            [boundaryLinks]="true"
            [ellipses]="false"
          >
            <ng-template class="left_arrow" ngbPaginationFirst
              ><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i
              ><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i
            ></ng-template>
            <ng-template class="left_arrow" ngbPaginationPrevious
              ><i
                class="icon-arrow-left"
                placement="top"
                ngbTooltip="Previous"
              ></i
            ></ng-template>
            <ng-template class="right_arrow" ngbPaginationNext
              ><i class="icon-arrow-right" placement="top" ngbTooltip="Next"></i
            ></ng-template>
            <ng-template class="right_arrow" ngbPaginationLast
              ><i class="icon-arrow-right" placement="top" ngbTooltip="Last"></i
              ><i class="icon-arrow-right"></i
            ></ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="_tab == 'search'">
    <div class="d-flex align-items-center">
      <div class="pl-4 pb-4" >
        <div class="search-round" >
          <i class="icon-search" aria-hidden="true"></i>
          <input
            style="width: 394px;"
            class="form-control"
            [(ngModel)]="addressSearch"
            placeholder="Search by Address"
            type="text"
          />
        </div>
      </div>
      <div class="pl-4 pb-4" style="margin-left: 13%;">
        <button
          type="submit"
          class="btn btn-round btn-outline-primary"
          (click)="searchAddressDetails()"
          [disabled]="addressSearch === ''"
        >
          Search
        </button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="searchAssetsArray.length !== 0 && !isSearchLoader">
    <div class="col-sm-12">
      <div class="table-responsive table-new">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-left" style="width: 50%">Asset</th>
              <th
                scope="col"
                class="text-left"
                style="width: 20%; min-width: 130px"
              >
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let asset of searchAssetsArray
                  | slice
                    : (page - 1) * pageSize
                    : (page - 1) * pageSize + pageSize
              "
            >
              <ng-container>
                <!-- Column: Asset -->
                <td class="cursor-pointer">
                  <div class="position-relative d-flex justify-content-start">
                    <ul class="d-flex assets-group ml-4">
                      <li>
                        <span>
                          <img
                            class="img-fluid for-light img-round-border"
                            [src]="asset.name | getIcon"
                            alt=""
                          />
                        </span>
                        <span class="ml-3">
                          {{ asset.name | getName }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </td>

                <!-- Column: Balance -->
                <td scope="col" class="text-left" style="width: 12%">
                  <span class="d-block" placement="top">
                    {{ asset.balance | amountToPretty : 8 }} {{ asset.name }}
                  </span>
                  <span class="d-block text-dark">
                    {{ asset.balanceUSD | currency: 'USD': 'symbol':'1.2-2':'en-US' }}
                  </span>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 my-3" *ngIf="searchAssetsArray.length !== 0">
    <div class="d-flex justify-content-between align-items-center">
      <span class="table-footer-text">
        <span>{{
          searchAssetsArray?.length | paginationFromTo : page : pageSize
        }}</span>
        of {{ searchAssetsArray?.length }} Items
      </span>
      <div class="pagination">
        <ngb-pagination
          [collectionSize]="searchAssetsArray?.length"
          [(page)]="page"
          [pageSize]="pageSize"
          [maxSize]="3"
          [boundaryLinks]="true"
          [ellipses]="false"
        >
          <ng-template class="left_arrow" ngbPaginationFirst
            ><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i
            ><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i
          ></ng-template>
          <ng-template class="left_arrow" ngbPaginationPrevious
            ><i
              class="icon-arrow-left"
              placement="top"
              ngbTooltip="Previous"
            ></i
          ></ng-template>
          <ng-template class="right_arrow" ngbPaginationNext
            ><i class="icon-arrow-right" placement="top" ngbTooltip="Next"></i
          ></ng-template>
          <ng-template class="right_arrow" ngbPaginationLast
            ><i class="icon-arrow-right" placement="top" ngbTooltip="Last"></i
            ><i class="icon-arrow-right"></i
          ></ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
  <ng-container class="table-responsive table-new" *ngIf="isSearchLoader">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
                <th scope="col">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of [].constructor(5); let i = index">
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
                <td>
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </td>
            </tr>
        </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="!addressesFound || noNativeAssets || noNonNativeAssets">
    <div class="no-addresses">
      <img
        src="../../../../assets/images/no-addresses-found.svg"
      />
      <p class="no-addresses-message ml-2" 
         *ngIf="!addressesFound" 
        >Address not found</p>
      <p class="no-addresses-message ml-2" 
         *ngIf="noNativeAssets"
         >
          Addresses not found
        </p>
        <p class="no-addresses-message ml-2" 
        *ngIf="noNonNativeAssets"
        >
         Asset Details not found
       </p>
    </div>
  </ng-container>
</div>
