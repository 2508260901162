export enum TravelRuleTransactionStatus {
    NEW = "New",
    MISSING_BENEFICIARY_DATA = "Missing Beneficiary Data",
    WAITING_FOR_INFORMATION = "Waiting For Information",
    CANCELLED = "Cancelled",
    INCOMPLETE = "Incomplete",
    SENT = "Sent",
    ACK = "Acknowledged",
    ACCEPTED = "Accepted",
    DECLINED = "Declined",
    REJECTED = "Rejected",
    NOT_READY = "Not Ready",
    SAVED = "Saved",
    ANY = 'Any'
}