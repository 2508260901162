import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { LoggerService } from "../../shared/services/logger.service";
import { catchError, map } from "rxjs/operators";
import { IFirewallPolicy } from "../../shared/entities/firewall.model";
import { FirewallApiResponse } from "../../shared/entities";
import { QuorumApproveRejectRequest, QuorumApproveRejectResponse } from "../../shared/entities/quorums.model";

@Injectable({
  providedIn: "root",
})
export class FirewallService {
  private baseUrl: string = environment.apiUrl;
  loadingSubject: BehaviorSubject<boolean>;
  policiesSubject: BehaviorSubject<IFirewallPolicy[]>;
  ruleSubject: BehaviorSubject<IFirewallPolicy>;

  constructor(private _http: HttpClient, private logger: LoggerService) {
    this.policiesSubject = new BehaviorSubject<IFirewallPolicy[]>([]);
    this.loadingSubject = new BehaviorSubject<boolean>(false);
    this.ruleSubject = new BehaviorSubject<IFirewallPolicy>(
      {} as IFirewallPolicy
    );
  }

  fetchPolicyListing(orgId: number) {
    this.loadingSubject.next(true);
    return this._http.get(this.baseUrl + "/firewall/policies", {
      params: {
        orgId: String(orgId)
      }
    }).pipe(
      map((response: any) => {
        return response.data.list;
      }),
      catchError((error) => {
        this.logger.error(error, error);
        this.loadingSubject.next(false);
        return throwError("Wallet not found!");
      })
    ).subscribe((response) => {
      this.policiesSubject.next(response);
      this.loadingSubject.next(false);
    })
  }

  fetchRuleListing(ruleId: number) {
    this.loadingSubject.next(true);
    return this._http.get(this.baseUrl + `/firewall/policies/${ruleId}/rules`).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((error) => {
        this.logger.error(error, error);
        this.loadingSubject.next(false);
        return throwError("Wallet not found!");
      })
    ).subscribe((response) => {
      this.ruleSubject.next(response);
      this.loadingSubject.next(false);
    })
  }

  getFirewallStatistics(txDetails: string): Observable<FirewallApiResponse> {
    return this._http.get<FirewallApiResponse>(this.baseUrl + `/firewall/tx-details`, {
      params: {
        id: txDetails
      }
    });
  }



  approveOrRejectTransaction(orgId: string, body: QuorumApproveRejectRequest, isApprove: boolean = false, accessToken: string): Observable<{
    success: boolean,
    data: QuorumApproveRejectResponse[]
  }> {
    let url = isApprove ? `/quorums/approve` : `/quorums/reject`;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append(
      'Authorization',
      'Bearer ' + accessToken
    );
    return this._http.post<{
      success: boolean,
      data: QuorumApproveRejectResponse[]
    }>(this.baseUrl + url, body, { headers });
  }

}
