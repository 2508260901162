import { Injectable } from "@angular/core";
import { WalletserviceService } from "./walletservice.service";
import { HttpService } from "./http.service";
import { from, Observable, of, Subject, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { getWalletType } from "@helpers/WalletUtils";
import { signShieldRbfUTXOTransaction } from "../../shared/helpers/trezor/signers/signShieldRbfUTXOTransaction";
import { getParentChain } from "../helpers/WalletUtils";
import { tokenType, TransactionRequest } from "../entities/TransactionRequest";
import { toPrettyAmount } from "../components/pipes/amountrounded.pipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PendingTxnInfoComponent } from "../components/pending-transactions/pending-txn-info/pending-txn-info.component";
import { FirewallCheckStatus } from "../components/pending-transactions/pending-txn-info/firewall.utils";
import { AccessControlService } from "./access-control.service";
import { TxDirection } from "../entities/firewall.model";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  public rejectTxnSubject: Subject<any> = new Subject();
  constructor(
    private walletService: WalletserviceService,
    private httpService: HttpService,
    private modalService: NgbModal,
    private accessControlService: AccessControlService,

  ) {}
  private oldMultisigPendingTxs: TransactionRequest[] = [];
  private oldMpcPendingTxs: TransactionRequest[] = [];
  FIREWALL_PENDING_STATUS = FirewallCheckStatus.FIREWALL_PENDING_STATUS;


  getStatusText(txData: any) {
    const wallet = this.walletService.getWallet(
      `${txData.walletid}_${txData.asset.toLowerCase()}`
    );
    if (wallet) {
      const m = Number.parseInt(wallet.config.split("of")[0]);
      if (txData.signs.filter((ele) => ele.approved).length >= m) {
        return "Execute";
      }
    }
    const signs = txData?.signs;
    if (signs && signs.length) {
      for (const sign of signs) {
        if (sign.isMine && sign.approved) {
          return "";
        }
      }
    } else {
      const signers = txData?.signers || [];
      if(signers && signers.length){
        for (const signer of signers) {
          if (signer && signer.approved) {
            return "";
          }
        }
      }
    }
    return "Approve";
  }

  processMultiSigPendingTxnData(
    pendingTxn: any[],
    allMember?: any[],
    isRbf: boolean = false
  ) {
    if (pendingTxn && pendingTxn.length) {
      for (let i = 0; i < pendingTxn.length; i++) {
        if (pendingTxn[i].tokenType == tokenType.nftToken.toString()) {
          let data = this.walletService.getAllWallets();
          for (let j = 0; j < data.length; j++) {
            if (
              data[j].id == pendingTxn[i].walletid &&
              data[j].coin.toLowerCase() == pendingTxn[i].asset.toLowerCase() &&
              data[j].identifier.toLowerCase() ==
                JSON.parse(pendingTxn[i].raw).to.toLowerCase()
            ) {
              pendingTxn[i]["walletinfor"] = data[j];
            }
          }
        } else {
          pendingTxn[i]["walletinfor"] = this.walletService.getWallet(
            pendingTxn[i].walletid + "_" + pendingTxn[i].asset.toLowerCase()
          );
        }
      }

      if (pendingTxn)
        pendingTxn.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
      // if (pendingTxn) pendingTxn = pendingTxn.filter(object => !(object.type == 2 || (object.status == 1 && (object["walletinfor"]["subtype"] == 'deposit' || object["walletinfor"]["subtype"] == 'gasStation'))));
      if (pendingTxn)
        pendingTxn = pendingTxn.filter(
          (object) => !(object.type == 2 && object.status == "1")
        );

      pendingTxn.forEach((element) => {
        if (element.raw) {
          element.chain.toLocaleLowerCase() === "sol" ||
          element.chain.toLocaleLowerCase() === "xlm"
            ? (element["txType"] = "")
            : (element["txType"] = JSON.parse(element.raw).txType);
        }
        element["mysign"] = false;
        element["_txType"] = "multisig_tx";
        if (element?.metadata?.metadata) {
          var metaData = JSON.parse(element.metadata.metadata);
        }
        let tx_category_type: string = metaData?.tx_category_type
          ? metaData.tx_category_type
          : "";
        let tx_sub_category_type: string = metaData?.tx_sub_category_type
          ? metaData.tx_sub_category_type
          : element.type == 2
          ? "Scheduled Send"
          : "Send";
        let tx_detail_description: string = metaData?.tx_detail_description
          ? metaData.tx_detail_description
          : toPrettyAmount.prototype.transform(element.amount, 5) +
            " " +
            element.asset;
        let tx_detail_title: string = metaData?.tx_detail_title
          ? metaData.tx_detail_title
          : "$" +
            " " +
            toPrettyAmount.prototype.transform(element.usdamount, 2);
        let destination_address: string = metaData?.destination_address
          ? metaData.destination_address
          : element.destinationAddress;
        let destination_title: string = metaData?.destination_title
          ? metaData.destination_title
          : element.addressLabel;
        let comment: string = metaData?.comment
          ? metaData.comment
          : element.comment;
        let comment_owner: string | number = metaData?.comment_owner
          ? metaData.comment_owner
          : element.userid;
        let amount: number = metaData?.meta_asset_details.amount
          ? metaData.meta_asset_details.amount
          : element.amount;
        let amount_usd: number = metaData?.meta_asset_details.amount_usd
          ? metaData.meta_asset_details.amount_usd
          : element.usdamount;
        let coin: string = metaData?.meta_asset_details?.coin?.toLowerCase()
          ? metaData?.meta_asset_details?.coin?.toLowerCase()
          : element.asset;

        let getUserName;
        const currentUser =
          (allMember &&
            allMember.length &&
            allMember.filter((ele) => ele.userid == element.userid)[0]) ||
          [];

        getUserName =
          (currentUser && currentUser.length && currentUser[0]) || {};

        let comment_owner_name = getUserName?.displayName
          ? getUserName.displayName
          : null;

        element["_metaData"] = {
          tx_category_type: tx_category_type,
          tx_sub_category_type: tx_sub_category_type,
          tx_detail_description: tx_detail_description,
          tx_detail_title: tx_detail_title,
          destination_address: destination_address,
          destination_title: destination_title,
          comment: comment,
          comment_owner: comment_owner,
          comment_owner_name: comment_owner_name,
          meta_asset_details: {
            amount: amount,
            amount_usd: amount_usd,
            coin: coin,
          },
        };

        let signer_config = element.walletinfor?.config?.split("of");
        element._signers = signer_config ? signer_config[0] : "";
        element._signersSigns = element.signs.filter(
          (ele) => ele.approved === true
        ).length;

        element.signs.forEach((ele) => {
          if (ele.isMine == true && ele.approved == true) {
            element["mysign"] = true;
          }
        });

        // Check RBF Data integrity
        element = this.getRbfData(element, "multisig");
      });

      console.info("pendingtxs", pendingTxn);
    }
    !isRbf && (this.oldMultisigPendingTxs = pendingTxn);
    return pendingTxn;
  }

  processMpcPendingTnxData(pendingTxn: any[], isRbf: boolean = false) {
    if (pendingTxn && pendingTxn.length) {
      for (let i = 0; i < pendingTxn.length; i++) {
        const teamTx = pendingTxn[i];
        pendingTxn[i]["walletinfor"] = this.walletService.getWallet(
          pendingTxn[i].walletId + "_" + pendingTxn[i].asset.toLowerCase()
        );

        if(!pendingTxn[i].team) pendingTxn[i].team = {};
        let initiator_config = pendingTxn[i]?.team?.initiator_config && pendingTxn[i]?.team?.initiator_config?.split("of");
        let signer_config = pendingTxn[i]?.team?.signer_config && pendingTxn[i]?.team?.signer_config?.split("of");
        pendingTxn[i].team.initiators = initiator_config && initiator_config.length && initiator_config[0] || [];
        pendingTxn[i].team.signers = signer_config && signer_config.length && signer_config[0] || [];

        if(pendingTxn[i].signers && pendingTxn[i].signers.length){
          pendingTxn[i].team.initiatorsSigns = pendingTxn[i].signers.filter(
            (ele) => ele.approved === true && ele.user_type == 1
          ).length;
          pendingTxn[i].team.signersSigns = pendingTxn[i].signers.filter(
            (ele) => ele.approved === true && ele.user_type == 2
          ).length;
          pendingTxn[i].initiatorsList = pendingTxn[i].signers.filter(
            (ele) => ele.user_type == 1
          );
          pendingTxn[i].signersList = pendingTxn[i].signers.filter(
            (ele) => ele.user_type == 2
          );
        }

        pendingTxn[i]["_mpcTxType"] = {};
        if (teamTx.livenessPending && teamTx.status === this.FIREWALL_PENDING_STATUS) {
          pendingTxn[i].initiatorsList = (pendingTxn[i].initiatorsList || []).filter(ele => ele.id === teamTx.livenessPendingUserId); 
        }

        if (pendingTxn[i]?.metadata) {
          try {
            let stringfyData = pendingTxn[i]?.metadata?.metadata;
            let jsonData = JSON.parse(stringfyData);

            if (jsonData) {
              pendingTxn[i]["explainTransactionMpcMsg"] =
                jsonData?.asset_changes?.map(
                  (ele) => ele.tx_detail_description
                );
              jsonData?.tx_category_type
                ? (pendingTxn[i]["_mpcTxType"]["tx_category_type"] =
                    jsonData?.tx_category_type)
                : "";
              jsonData?.tx_sub_category_type
                ? (pendingTxn[i]["_mpcTxType"]["tx_sub_category_type"] =
                    jsonData?.tx_sub_category_type)
                : "";
            }
            console.log(
              "flag-check :",
              pendingTxn[i]["_mpcTxType"].tx_category_type === "staking"
            );
          } catch (err) {
            console.log("error in parse---", err);
          }
        }
        let trpData: any = {};
        try {
          trpData = JSON.parse(pendingTxn[i].data);
        } catch (err) {
          // no need to stop flow on error
          // this just indicates trp is not staking type
        }

        if (trpData?.transactionType !== 3) {
          // memo logic
          pendingTxn[i].memo = pendingTxn[i].data;
        } else {
          // staking logic
          pendingTxn[i].memo = null;
        }

        let element = pendingTxn[i];

        // Check RBF Data integrity
        element = this.getRbfData(element, "mpc");
      }

      pendingTxn.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }

    !isRbf && (this.oldMpcPendingTxs = pendingTxn);
    return pendingTxn;
  }

  getRbfData(transaction: any, txType: "multisig" | "mpc") {
    if (transaction.hasOwnProperty("isRbf") && transaction["isRbf"] == true) {
      const oldPendingTxn =
        txType === "mpc" ? this.oldMpcPendingTxs : this.oldMultisigPendingTxs;
      if (oldPendingTxn && oldPendingTxn.length) {
        oldPendingTxn.forEach((item: any) => {
          if (item.id === transaction.id) {
            if (item?.tx_state == "open") {
              transaction["tx_state"] = "open";
              transaction["speedUpTransactionList"] =
                item["speedUpTransactionList"] || [];
            } else {
              transaction["tx_state"] = "close";
            }
          }
        });
      } else {
        transaction["tx_state"] = "close";
      }
      transaction = this.setRbfData(transaction, txType);
    }
    return transaction;
  }

  setRbfData(transaction: any, txType: "multisig" | "mpc") {
    if (!transaction["parentTrnxReq"]) {
      return transaction;
    }

    const txIds = (transaction["childTrnxReq"] &&
      transaction["childTrnxReq"].length && [
        transaction["parentTrnxReq"],
        ...transaction["childTrnxReq"],
      ]) || [transaction["parentTrnxReq"]];
    this.getRbfTxndata(txIds).subscribe(
      (txs: any) => {
        if (!txs.length) {
          transaction["speedUpTransactionList"] = [];
          return transaction;
        }

        // Process data based on txType
        let processedData;
        if (txType === "multisig") {
          processedData =
            this.processMultiSigPendingTxnData(txs, [], true) || [];
        } else {
          processedData = this.processMpcPendingTnxData(txs, true) || [];
        }

        // Add isElegibleForRbf flag for child transactions
        transaction["speedUpTransactionList"] =
          (processedData.length &&
            processedData?.map((tx: any) => ({
              ...tx,
              isElegibleForRbf: false,
            }))) ||
          [];
        return transaction;
      },
      (error: any) => {
        console.error("Error fetching RBF transaction data:", error);
        transaction["speedUpTransactionList"] = [];
        return transaction;
      }
    );
  }

  getRbfTxndata(txnIds: Array<number>): any {
    return this.httpService.getRbfTxnData(txnIds);
  }

  calculateRbfFees(currentPrice: number, percentageIncrease: number): number {
    const feeMultiplier = 1 + percentageIncrease / 100;
    const finalPrice = currentPrice * feeMultiplier;
    return parseFloat(finalPrice.toFixed(2)) || 0;
  }

  calculatePercentageChange(currentPrice: number, marketPrice: number) {
    // Calculate percentage change
    const percentageChange =
      ((marketPrice - currentPrice) / Math.abs(currentPrice)) * 100;

    // Determine the direction of change
    const changeDirection =
      percentageChange > 0 ? "higher" : percentageChange < 0 ? "lower" : "same";

    return {
      percentageChange: Math.round(percentageChange),
      changeDirection,
    };
  }

  createMpcSpeedUpTransaction(
    walletId: number,
    sequenceId: number,
    payload: any = {}
  ): Observable<any> {
    return this.httpService
      .createSpeedUpTxnPreBuild(walletId, sequenceId, payload)
      .pipe(
        map((response: any) => {
          console.log("createMpcSpeedUpTransaction==", response);
          return response;
        }),
        catchError((error) => {
          console.log("createMpcSpeedUpTransaction error==", error);
          return throwError(error?.error || error || "Something went wrong");
        })
      );
  }

  createMultiSigSpeedUpTransaction(
    walletId: number,
    sequenceId: number,
    payload: any = {}
  ): Observable<any> {
    return this.httpService
      .createSpeedUpTxnPreBuild(walletId, sequenceId, {
        feeRate: payload?.feeRate,
        maxPriorityFee: payload?.maxPriorityFee || null,
      })
      .pipe(
        switchMap((response: any) => {
          if (!response.success || response?.error || !response.data) {
            return throwError(
              response?.message ||
                response?.error ||
                "Transaction Signing failed"
            );
          }
          const { txHex, trezorTx } = response.data;
          if (!txHex || !trezorTx) {
            return throwError("Transaction Signing failed");
          }
          return this.signMultiSigTransaction(
            walletId,
            payload?.chain,
            trezorTx,
            txHex
          ).pipe(
            switchMap((res: any) => {
              if (res && res.signature) {
                const signPayload = {
                  signature: res.signature,
                  txHex,
                  trezorTx,
                };
                return this.createMultisigRbfTransaction(
                  walletId,
                  signPayload,
                  payload
                );
              } else {
                return throwError("Transaction Signing failed");
              }
            })
          );
        }),
        catchError((error) =>
          throwError(error?.error || error || "Something went wrong")
        )
      );
  }

  signMultiSigTransaction(
    walletId: number,
    chain: string,
    trezorTx: any,
    txHex: string
  ): Observable<any> {
    return from(
      of(this.walletService.getWallet(`${walletId}_${chain.toLowerCase()}`))
    ).pipe(
      switchMap((wallet) =>
        from(signShieldRbfUTXOTransaction(trezorTx, txHex, wallet)).pipe(
          map((signResponse: any[]) => {
            const [txData, signature] = signResponse;
            if (!signature || txData?.error) {
              throw new Error("Transaction Signing Failed");
            }
            return signature;
          }),
          catchError((error) => {
            const customMsg = this.walletService.setCustomErrorMsg(
              error.message || "Transaction Signing Failed"
            );
            return throwError(new Error(customMsg));
          })
        )
      )
    );
  }

  createMultisigRbfTransaction(
    walletId: number,
    signPayload: any,
    txreq: any
  ): Observable<any> {
    const txPayload = {
      txHex: signPayload?.txHex,
      halfSigned: {
        payload: "Tx Payload",
        txBase64: signPayload?.signature,
        txHex: signPayload?.txHex,
        trezorTx: signPayload?.trezorTx,
        sequenceId: txreq?.sequenceId,
      },
      isInternal: undefined,
    };

    return this.httpService
      .addNewMultisigRbfTransaction(walletId.toString(), txPayload)
      .pipe(
        map((response: any) => {
          console.log("addNewMultisigRbfTransaction==", response);
          return response;
        }),
        catchError((error) =>
          throwError(error?.error || error || "Something went wrong")
        )
      );
  }

  isInitiateRbfTxn(txData: any): boolean {
    // Check if the transaction data is eligible for rbf transaction
    if (
      txData?.hasOwnProperty("isElegibleForRbf") &&
      txData?.isElegibleForRbf === false
    ) {
      return false;
    }

    // Check if the transaction data is available
    if (txData && txData?.status === 2) {
      // Check if the wallet type is Mobile and the access is INITIATOR with status 2
      if (
        getWalletType(txData?.walletinfor?.type) === "Mobile" &&
        txData?.walletinfor?.access === "INITIATOR"
      ) {
        if (getParentChain(txData?.chain) === "EVM") {
          if (txData?.elegibleForSpeedUp) {
            return true;
          }
          return false;
        }
        return true;
      }
      // Check if the wallet type is Multisig and the access is SIGNER with status 2
      else if (
        getWalletType(txData?.walletinfor?.type) === "Multisig" &&
        txData?.walletinfor?.access === "SIGNER"
      ) {
        if (getParentChain(txData?.chain) === "EVM") {
          if (txData?.elegibleForSpeedUp) {
            return true;
          }
          return false;
        }
        return true;
      }
    }
    return false;
  }

  viewFirewallTransaction(txData: any, isCustodyOrg: boolean) {
    let modalRef = this.modalService.open(PendingTxnInfoComponent, {
      windowClass: "modal-side-background",
      keyboard: true,
    });
    modalRef.componentInstance.title = txData.direction === TxDirection.Receive ? "Receive Transaction" : "Send Transaction";
    modalRef.componentInstance.selectedTransactionData = txData;
    modalRef.componentInstance.componentFor = "send-receive";
    modalRef.componentInstance.isCustodyOrg = isCustodyOrg;
    modalRef.componentInstance.onRejectTxn.subscribe((txn) => {
      this.rejectTxnSubject.next(txData);
    }); 
  }
}
