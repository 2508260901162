import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-asset-card',
  templateUrl: './wallet-asset-card.component.html',
  styleUrls: ['./wallet-asset-card.component.scss']
})
export class WalletAssetCardComponent implements OnInit {
  @Input() option: any;
  @Input() dropdown_card: string;
  @Input() card_padding: any;
  @Input() card: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
