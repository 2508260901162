<ng-container *ngIf="component_for == 'all_assets'">
    <span class="ml-1 cursor-pointer" placement="left" [ngbTooltip]="list" tooltipClass="frozen-balance-tooltip" placement="top">
        <i class="icon-info" style="font-size: 12px!important;"></i>
    </span>
    <ng-template #list>
        <div class="d-flex flex-column text-left">
            <span>Spendable Balance: ${{ (sumofwallets(data) - data.unspendableBalances?.frozenUsd - data.unspendableBalances?.pendingUsd) | amountToPretty : 2 }} ({{ (data.assets[0].balance - data.unspendableBalances?.frozen - data.unspendableBalances?.pending) | amountToPretty : 5}} {{data.assets[0].coin}})</span>
            <span>Pending Balance: ${{ ((data.unspendableBalances?.pendingUsd)?data.unspendableBalances.pendingUsd:'0') | amountToPretty : 2 }} ({{ ((data.unspendableBalances?.pending)?data.unspendableBalances.pending:'0') | amountToPretty : 5}} {{data.assets[0].coin}})</span>
            <span>Quarantined Balance: ${{ ((data.unspendableBalances?.frozenUsd)?data.unspendableBalances.frozenUsd:'0') | amountToPretty : 2}} ({{ ((data.unspendableBalances?.frozen)?data.unspendableBalances.frozen:'0') | amountToPretty : 5}} {{data.assets[0].coin}})</span>
            <span>Total Balance: ${{sumofwallets(data) | amountToPretty : 2}} ({{ data.assets[0].balance | amountToPretty : 5 }} {{data.assets[0].coin}})</span>
        </div>
    </ng-template>
</ng-container>

<ng-container *ngIf="component_for == 'single_assets'">
<span class="ml-1 cursor-pointer" placement="left" [ngbTooltip]="assetsBalance" tooltipClass="frozen-balance-tooltip" placement="top">
    <i class="icon-info" style="font-size: 12px!important;"></i>
  </span>
<ng-template #assetsBalance>
    <div class="d-flex flex-column text-left">
        <span>Spendable Balance: ${{ (data.balanceUSD - data.unspendableBalances?.frozenUsd - data.unspendableBalances?.pendingUsd) | amountToPretty : 2 }} ({{ (data.balance - data.unspendableBalances?.frozen - data.unspendableBalances?.pending) | amountToPretty : 5}} {{data.coin}})</span>
        <span>Pending Balance: ${{ ((data.unspendableBalances?.pendingUsd)?data.unspendableBalances.pendingUsd:'0') | amountToPretty : 2 }} ({{ ((data.unspendableBalances?.pending)?data.unspendableBalances.pending:'0') | amountToPretty : 5}} {{data.coin}})</span>
        <span>Quarantined Balance: ${{ ((data.unspendableBalances?.frozenUsd?data.unspendableBalances.frozenUsd:'0')) | amountToPretty : 2}} ({{ ((data.unspendableBalances?.frozen)?data.unspendableBalances.frozen:'0') | amountToPretty : 5}} {{data.coin}})</span>
        <span>Total Balance: ${{data.balanceUSD | amountToPretty : 2}} ({{ data.balance | amountToPretty : 5 }} {{data.coin}})</span>
    </div>
</ng-template>
</ng-container>