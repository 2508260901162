<!-- Pagination Block -->
<div class="px-4" *ngIf="totalRecords > 0">
    <div class="d-flex justify-content-between">
         <span class="table-footer-text">Showing
            <span>{{ getRange() }}</span>
        </span>
        <div class="pagination">
            <ngb-pagination [collectionSize]="totalRecords" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true"
                [ellipses]="false" [maxSize]="maxSize" (pageChange)="onPageChange($event)">
                <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left" placement="top"
                        ngbTooltip="First"></i><i class="icon-arrow-left" placement="top"
                        ngbTooltip="First"></i></ng-template>
                <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left" placement="top"
                        ngbTooltip="Previous"></i></ng-template>
                <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right" placement="top"
                        ngbTooltip="Next"></i></ng-template>
                <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right" placement="top"
                        ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>
            </ngb-pagination>
        </div>
    </div>
</div>