import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { AccessControlService } from '../services/access-control.service';

@Directive({
  selector: '[appHideIfCustodyOrg]'
})
export class HideIfCustodyOrgDirective implements OnInit {
  @Input() appHideIfCustodyOrg: boolean;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private accessControlService: AccessControlService
  ) {}

  ngOnInit() {
    const isCustodyOrg = this.accessControlService.getIsCustodyOrg();
    if (isCustodyOrg && !(this.appHideIfCustodyOrg === false)) {
      this.renderer.removeChild(this.elementRef.nativeElement.parentNode, this.elementRef.nativeElement);
    }
  }
}
