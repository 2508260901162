"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.coreTypes = exports.DEFAULT_DEFINITIONS = exports.XrplDefinitionsBase = exports.XrplDefinitions = exports.TRANSACTION_TYPES = exports.decodeLedgerData = exports.decodeQuality = exports.encodeQuality = exports.encodeForMultisigning = exports.encodeForSigningClaim = exports.encodeForSigning = exports.encode = exports.decode = void 0;
const coretypes_1 = require("./coretypes");
const ledger_hashes_1 = require("./ledger-hashes");
Object.defineProperty(exports, "decodeLedgerData", {
  enumerable: true,
  get: function () {
    return ledger_hashes_1.decodeLedgerData;
  }
});
const enums_1 = require("./enums");
Object.defineProperty(exports, "XrplDefinitionsBase", {
  enumerable: true,
  get: function () {
    return enums_1.XrplDefinitionsBase;
  }
});
Object.defineProperty(exports, "TRANSACTION_TYPES", {
  enumerable: true,
  get: function () {
    return enums_1.TRANSACTION_TYPES;
  }
});
Object.defineProperty(exports, "DEFAULT_DEFINITIONS", {
  enumerable: true,
  get: function () {
    return enums_1.DEFAULT_DEFINITIONS;
  }
});
const xrpl_definitions_1 = require("./enums/xrpl-definitions");
Object.defineProperty(exports, "XrplDefinitions", {
  enumerable: true,
  get: function () {
    return xrpl_definitions_1.XrplDefinitions;
  }
});
const types_1 = require("./types");
Object.defineProperty(exports, "coreTypes", {
  enumerable: true,
  get: function () {
    return types_1.coreTypes;
  }
});
const utils_1 = require("@xrplf/isomorphic/utils");
const {
  signingData,
  signingClaimData,
  multiSigningData,
  binaryToJSON,
  serializeObject
} = coretypes_1.binary;
/**
 * Decode a transaction
 *
 * @param binary hex-string of the encoded transaction
 * @param definitions Custom rippled types to use instead of the default. Used for sidechains and amendments.
 * @returns the JSON representation of the transaction
 */
function decode(binary, definitions) {
  if (typeof binary !== 'string') {
    throw new Error('binary must be a hex string');
  }
  return binaryToJSON(binary, definitions);
}
exports.decode = decode;
/**
 * Encode a transaction
 *
 * @param json The JSON representation of a transaction
 * @param definitions Custom rippled types to use instead of the default. Used for sidechains and amendments.
 *
 * @returns A hex-string of the encoded transaction
 */
function encode(json, definitions) {
  if (typeof json !== 'object') {
    throw new Error();
  }
  return (0, utils_1.bytesToHex)(serializeObject(json, {
    definitions
  }));
}
exports.encode = encode;
/**
 * Encode a transaction and prepare for signing
 *
 * @param json JSON object representing the transaction
 * @param signer string representing the account to sign the transaction with
 * @param definitions Custom rippled types to use instead of the default. Used for sidechains and amendments.
 * @returns a hex string of the encoded transaction
 */
function encodeForSigning(json, definitions) {
  if (typeof json !== 'object') {
    throw new Error();
  }
  return (0, utils_1.bytesToHex)(signingData(json, coretypes_1.HashPrefix.transactionSig, {
    definitions
  }));
}
exports.encodeForSigning = encodeForSigning;
/**
 * Encode a transaction and prepare for signing with a claim
 *
 * @param json JSON object representing the transaction
 * @param signer string representing the account to sign the transaction with
 * @param definitions Custom rippled types to use instead of the default. Used for sidechains and amendments.
 * @returns a hex string of the encoded transaction
 */
function encodeForSigningClaim(json) {
  if (typeof json !== 'object') {
    throw new Error();
  }
  return (0, utils_1.bytesToHex)(signingClaimData(json));
}
exports.encodeForSigningClaim = encodeForSigningClaim;
/**
 * Encode a transaction and prepare for multi-signing
 *
 * @param json JSON object representing the transaction
 * @param signer string representing the account to sign the transaction with
 * @param definitions Custom rippled types to use instead of the default. Used for sidechains and amendments.
 * @returns a hex string of the encoded transaction
 */
function encodeForMultisigning(json, signer, definitions) {
  if (typeof json !== 'object') {
    throw new Error();
  }
  if (json['SigningPubKey'] !== '') {
    throw new Error();
  }
  const definitionsOpt = definitions ? {
    definitions
  } : undefined;
  return (0, utils_1.bytesToHex)(multiSigningData(json, signer, definitionsOpt));
}
exports.encodeForMultisigning = encodeForMultisigning;
/**
 * Encode a quality value
 *
 * @param value string representation of a number
 * @returns a hex-string representing the quality
 */
function encodeQuality(value) {
  if (typeof value !== 'string') {
    throw new Error();
  }
  return (0, utils_1.bytesToHex)(coretypes_1.quality.encode(value));
}
exports.encodeQuality = encodeQuality;
/**
 * Decode a quality value
 *
 * @param value hex-string of a quality
 * @returns a string representing the quality
 */
function decodeQuality(value) {
  if (typeof value !== 'string') {
    throw new Error();
  }
  return coretypes_1.quality.decode(value).toString();
}
exports.decodeQuality = decodeQuality;
