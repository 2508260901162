import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthServiceJWT } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import Swal from 'sweetalert2';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { scopes } from '../../constants/authScopes';

@Component({
  selector: 'app-eth-validators-list',
  templateUrl: './eth-validators-list.component.html',
  styleUrls: ['./eth-validators-list.component.scss']
})
export class EthValidatorsListComponent implements OnInit {
  page = 1;
  pageSize = 10;
  // isSigning:boolean = false;
  
  @Input() validatorsListData: any;
  @Output("stakeingParentFun") stakeingParentFun: EventEmitter<any> = new EventEmitter();
  constructor(public authService: AuthServiceJWT,private httpService: HttpService,) { }

  ngOnInit(): void {
    console.info('data',this.validatorsListData)
  }
  backTOStakingPage()
  {
    this.stakeingParentFun.emit(false)
  }
  async ethExitUnstake(walletId,comment,validatorId,type,validatorData)
  {
    // this.isSigning = true;
    this.authService
      .writeAccess(scopes.WalletTxCreate)
      .subscribe(async (accessToken) => {
        try {
          let dataObj = {
            "amount": validatorData.amount,
            "comment": comment,
            "isPresignedExitTransaction": validatorData.isPresignedTransaction.presignedTransactionstatus,
            "presignedExitTransaction": validatorData.isPresignedTransaction.preSignedExitTransaction,
            "presignedRawdata": JSON.parse(validatorData.isPresignedTransaction.raw)
          }
          console.info(dataObj);
          const response = await this.httpService
            .stakingPrebuild(walletId, comment, validatorId, type, dataObj, accessToken)
            .toPromise();
          console.info("response Data", response);
          if (response.success === true) {
            // this.isSigning = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Awesome! You have successfully unstake",
              showConfirmButton: false,
            });
          } else {
            // this.isSigning = false;
            ErrorPrompt.fire({
              icon: "error",
              title: "Failed to unstake",
              text: response.message,
              showConfirmButton: false,
            });
          }
        } catch (e) {
          // this.isSigning = false;
          ErrorPrompt.fire({
            icon: "error",
            title: "Failed to unstake",
            text: e.error.message,
            showConfirmButton: false,
          });
          console.info(e);
        }
      });
  }
}
