<div>
    <input class="form-control" [placeholder]="placeholder" [ngModel]="selectedDate" 
        (ngModelChange)="onDateChange($event)" [minDate]="minDate" [maxDate]="maxDate"
        [readonly]="readonly" [disabled]="disabled" (click)="dp.toggle();" ngbDatepicker #dp="ngbDatepicker" />
    <i class="icon-calendar text-primary cursor-pointer" (click)="dp.toggle();"></i>
</div>
<ng-template #footerTemplate>
    <hr class="my-0">
    <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="clearDate()">Clear</button>
    <button class="btn btn-outline-secondary btn-sm m-2 float-right" (click)="closePicker()">Close</button>
</ng-template>
