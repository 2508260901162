// import { TypedDataUtils } from 'eth-sig-util'
export const EMPTY_DATA = '0x'
import Web3 from "web3";

import GnosisSafeSol from './GnosisSafeAbi.json'
import { AbiItem } from 'web3-utils'
import { List } from 'immutable'
// import contract from '@truffle/contract/index.js'
// import HumanFriendlyToken from './HumanFriendlyToken.json'
import { environment } from 'src/environments/environment';
import {getParentChain} from './WalletUtils'
export type TxArgs = {
    baseGas: number
    data: string
    gasPrice: string
    gasToken: string
    nonce: number
    operation: number
    refundReceiver: string
    safeInstance: any
    safeTxGas: number
    sender?: string
    sigs: string
    to: string
    valueInWei: string
}

// export function generateSafeTxHash(safeAddress: string, version: string, networkId :number,txArgs: TxArgs, chain: string): string {

//     const EIP712_DOMAIN = [
//         {
//             type: 'uint256',
//             name: 'chainId',
//         },
//         {
//             type: 'address',
//             name: 'verifyingContract',
//         },
//     ]


//     const EIP712_DOMAIN_BEFORE_V130 = [
//         {
//             type: 'address',
//             name: 'verifyingContract',
//         },
//     ]

//     let eip712WithChainId = false;
//     if (version == '1.3.0') {
//         eip712WithChainId = true;
//     }
//     if(version.includes("1.3.0")){
//         eip712WithChainId = true;
//     }
//     if (version.includes("1.3.1")) {
//         eip712WithChainId = true;
//     }
//     const messageTypes = {
//         EIP712Domain: eip712WithChainId ? EIP712_DOMAIN : EIP712_DOMAIN_BEFORE_V130,
//         SafeTx: [
//             { type: 'address', name: 'to' },
//             { type: 'uint256', name: 'value' },
//             { type: 'bytes', name: 'data' },
//             { type: 'uint8', name: 'operation' },
//             { type: 'uint256', name: 'safeTxGas' },
//             { type: 'uint256', name: 'baseGas' },
//             { type: 'uint256', name: 'gasPrice' },
//             { type: 'address', name: 'gasToken' },
//             { type: 'address', name: 'refundReceiver' },
//             { type: 'uint256', name: 'nonce' },
//         ],
//     }

//     const primaryType: 'SafeTx' = 'SafeTx' as const

//     const typedData = {
//         types: messageTypes,
//         domain: {
//             chainId: eip712WithChainId ? networkId: undefined,
//             verifyingContract: safeAddress,
//         },
//         primaryType,
//         message: {
//             to: formatAddress(txArgs.to,'0x',chain),
//             value: txArgs.valueInWei,
//             data: txArgs.data,
//             operation: txArgs.operation,
//             safeTxGas: txArgs.safeTxGas,
//             baseGas: txArgs.baseGas,
//             gasPrice: txArgs.gasPrice,
//             gasToken: txArgs.gasToken,
//             refundReceiver: txArgs.refundReceiver,
//             nonce: txArgs.nonce,
//         },
//     }
//     // console.log(safeAddress)
//     console.log(txArgs,safeAddress,version,networkId)
//     // console.log("Signmessage");
//     // console.log(typedData)
//     // console.log(typedData.message)
//     // console.log(`0x${TypedDataUtils.sign<typeof messageTypes>(typedData).toString('hex')}`, "signmessage")
//     return `0x${TypedDataUtils.sign<typeof messageTypes>(typedData).toString('hex')}`
// }


const ETH_SIGN_NOT_SUPPORTED_ERROR_MSG = 'ETH_SIGN_NOT_SUPPORTED'

// type EthSignerArgs = {
//     safeTxHash: string
//     sender: string
// }
// export const ethSigner = async ({ safeTxHash, sender }: EthSignerArgs): Promise<string> => {
//     const web3 = new Web3.providers.HttpProvider(environment.evm_config.ETH.web3http)

//     return new Promise(function (resolve, reject) {
//         const provider = web3
//         provider.send(
//             {
//                 jsonrpc: '2.0',
//                 method: 'eth_sign',
//                 params: [sender, safeTxHash],
//                 id: new Date().getTime(),
//             },
//             async function (err, signature) {
//                 if (err) {
//                     return reject(err)
//                 }

//                 if (signature?.result == null) {
//                     reject(new Error(ETH_SIGN_NOT_SUPPORTED_ERROR_MSG))
//                     return
//                 }

//                 const sig = signature.result.replace(EMPTY_DATA, '')
//                 let sigV = parseInt(sig.slice(-2), 16)

//                 // Metamask with ledger returns v = 01, this is not valid for ethereum
//                 // For ethereum valid V is 27 or 28
//                 // In case V = 0 or 01 we add it to 27 and then add 4
//                 // Adding 4 is required to make signature valid for safe contracts:
//                 // https://gnosis-safe.readthedocs.io/en/latest/contracts/signatures.html#eth-sign-signature
//                 switch (sigV) {
//                     case 0:
//                     case 1:
//                         sigV += 31
//                         break
//                     case 27:
//                     case 28:
//                         sigV += 4
//                         break
//                     default:
//                         throw new Error('Invalid signature')
//                 }

//                 resolve(sig.slice(0, -2) + sigV.toString(16))
//             },
//         )
//     })
// }

export function processSig(signature: string) {
    const sig = signature.replace(EMPTY_DATA, '')
    let sigV = parseInt(sig.slice(-2), 16)

    // Metamask with ledger returns v = 01, this is not valid for ethereum
    // For ethereum valid V is 27 or 28
    // In case V = 0 or 01 we add it to 27 and then add 4
    // Adding 4 is required to make signature valid for safe contracts:
    // https://gnosis-safe.readthedocs.io/en/latest/contracts/signatures.html#eth-sign-signature
    switch (sigV) {
        case 0:
        case 1:
            sigV += 31
            break
        case 27:
        case 28:
            sigV += 4
            break
        default:
            throw new Error('Invalid signature')
    }

    return sig.slice(0, -2) + sigV.toString(16)
}


export const getGnosisSafeInstanceAt = (safeAddress: string): any => {
    const web3 = web3ReadOnly
    return (new web3.eth.Contract(GnosisSafeSol.abi as AbiItem[], safeAddress) as unknown)
}

const httpProviderOptions = {
    timeout: 10_000,
}
export const web3ReadOnly = new Web3(
    new Web3.providers.HttpProvider(environment.evm_config.ETH.web3http)
    // new Web3.providers.WebsocketProvider(web3websocket)
);

/**
* Creates a Contract instance of the GnosisSafe contract
* @param {Web3} web3
* @param {ETHEREUM_NETWORK} networkId
*/

export function getGnosisSafeContract(web3: Web3, networkId = 1) {
    const networks = GnosisSafeSol.networks
    // TODO: this may not be the most scalable approach,
    //  but up until v1.2.0 the address is the same for all the networks.
    //  So, if we can't find the network in the Contract artifact, we fallback to MAINNET.
    //@ts-ignore
    const contractAddress = networks[networkId]?.address ?? networks[1].address
    return (new web3.eth.Contract(GnosisSafeSol.abi as AbiItem[], contractAddress))
}

// https://docs.gnosis.io/safe/docs/contracts_signatures/#pre-validated-signatures
export const getPreValidatedSignatures = (from: string, initialString: string = EMPTY_DATA): string => {
    return `${initialString}000000000000000000000000${from.replace(
        EMPTY_DATA,
        '',
    )}000000000000000000000000000000000000000000000000000000000000000001`
}


export type Confirmation = {
    owner: string
    signature: string | null
}

export const generateSignaturesFromTxConfirmations = (
    confirmations?: List<Confirmation>,
    preApprovingOwner?: string,
): string => {
    let confirmationsMap =
        confirmations?.map((value) => {
            return {
                signature: value.signature,
                owner: value.owner.toLowerCase(),
            }
        }) || List([])

    console.log("confirmationsMap", confirmationsMap)
    if (preApprovingOwner) {
        confirmationsMap = confirmationsMap.concat({ owner: preApprovingOwner, signature: null })
    }

    // The constant parts need to be sorted so that the recovered signers are sorted ascending
    // (natural order) by address (not checksummed).
    confirmationsMap = confirmationsMap.sort((ownerA, ownerB) => ownerA.owner.localeCompare(ownerB.owner))

    let sigs = '0x'
    confirmationsMap.forEach(({ signature, owner }) => {
        if (signature) {
            if (signature.startsWith("0x"))
                sigs += signature.slice(2)
            else
                sigs += signature
        } else {
            // https://docs.gnosis.io/safe/docs/contracts_signatures/#pre-validated-signatures
            sigs += getPreValidatedSignatures(owner, '')
        }
    })
    console.log(sigs)
    return sigs
}



export async function executeETHTransaction(to: string, valueInWei: string, dataexec: string, operation: number, safeTxGas: string, baseGas: number, gasPrice: number,
    gasToken: string, refundReceiver: string, confirmations: Confirmation[]) {


    // let confirmations = [
    //     {signature: "0x66b96a2fd49434c9805751f58f7fb52a3916c72aeafd35704c6c1fb540d101cb56cfb688eb16d1e4ca8c4e62d453a86e863bb77c25907621a9fc193479642eb71f", owner: "0x3f2329c9adfbccd9a84f52c906e936a42da18cb8"},
    //     {signature: "0xf18ec77479a298af122cdf5b71130b32c98384ae096df6c093c63b5bdba29cc61ee03b6d2e589018f5d2258ed7d983f1e782983b42b5a0166a667f4524f4c3d31b", owner: "0xed3853a625693f4b07c26b69b349386258ca6948"},
    //     { signature: null,owner: "0x4f4F1488ACB1Ae1b46146CEfF804f591dFe660ac"}

    // ]
    let c: List<Confirmation> = List<Confirmation>(confirmations);
    // c.push(confirmations[0])
    // c.push(confirmations[1])

    console.log(c.size)

    let contarct = getGnosisSafeContract(web3ReadOnly, 1)
    // console.log(contarct);


    // "0x00C56BB7D517293ef7d4e6941C4e07C14a157f4D",
    // "10000000000000000",
    // "0x",
    // 0,
    // 41749,
    // 0,
    // 0,
    // "0x0000000000000000000000000000000000000000",
    // "0x0000000000000000000000000000000000000000",
    let data = contarct.methods['execTransaction'](
        to,
        valueInWei,
        dataexec,
        operation,
        safeTxGas,
        baseGas,
        gasPrice,
        gasToken,
        refundReceiver,

        generateSignaturesFromTxConfirmations(c)

    )
    console.log(data.encodeABI());
    return data.encodeABI();
}


// export async function getHumanFriendlyToken(chainname:string) {
//     if(getParentChain(chainname) == "EVM")
//     {
//         var web3 = new Web3(
//             new Web3.providers.HttpProvider(environment.evm_config[chainname].web3http)
//             // new Web3.providers.WebsocketProvider(web3websocket)
//         );
    
//     }else{
//         var web3 = new Web3(
//             new Web3.providers.HttpProvider(environment.utxo_config[chainname].web3http)
//             // new Web3.providers.WebsocketProvider(web3websocket)
//         );
//     }
//     const humanErc20Token = await contract(HumanFriendlyToken)
//     humanErc20Token.setProvider(web3.currentProvider)

//     return humanErc20Token
// }

  export function formatAddress(address: string, type: string = "0x", chain:string) {
    if(chain === "XINFIN")
    {
        if (type === "0x" && address.toLocaleLowerCase().startsWith("xdc")) {
            return address.replace("xdc", "0x");
        }
        if (type === "xdc" && address.toLocaleLowerCase().startsWith("0x")) {
            return address.replace("0x", "xdc");
        }
        return address;
    } else 
    {
        return address;
    }
        
}