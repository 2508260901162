import { Guid } from "guid-typescript";
import { LedgerService } from '../../ledger/ledger';
import TrezorConnect from 'trezor-connect';
import { processSig } from "@helpers/gnosisHelper";
import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import Eth from "@ledgerhq/hw-app-eth";
import { HalfSignedStakeingTransaction } from "../stakingSign";
import { ErrorPrompt } from "src/app/shared/components/custom-prompt/custom-prompt.service";
import { scopes } from "src/app/shared/constants/authScopes";

export async function signETHStakingTransaction(amount, comment, wallet, validator, type, txReq) {
    try {
      if (type === 'unstaking') {
        let dataObj = {
          amount: type == 'claimrewards' ? amount : amount.toString(),
          comment: comment,
          timestamp: new Date().getTime(),
        };
        console.info('dataObj', dataObj);
        try {
          try {
            return await this.apiService.executeApiWithAuth2Fa(scopes.WalletTxCreate, (accessToken) =>
              this.httpService.stakingPrebuild(
                wallet.id,
                comment,
                validator.id,
                type,
                dataObj,
                accessToken
              )
            );
          } catch (error) {
            return Promise.reject(error);
          }
        } catch (e) {
          return Promise.reject({ success: false, message: 'Failed to sign unstake' });
        }
      } else {
        console.info('staking flow');

        let dataObj = {
          amount: type == 'claimrewards' ? amount : amount.toString(),
          comment: comment,
        };
        console.info('dataObj', dataObj);
        try {
          var stakingResponse = await this.httpService
            .stakingPrebuild(wallet.id, comment, validator.id, type, dataObj)
            .toPromise();
          var txnPreBuildRes = stakingResponse.data;
        } catch (error) {
          return [{ error: error.error.message }];
        }
        console.info('prebuild', stakingResponse);

        if (!txReq && stakingResponse.success) return 'success';

        console.log('respData', txnPreBuildRes);
        let m = Number.parseInt(wallet.config.split('of')[0]);
        var walletkey = wallet.walletKeys;
        // txnPreBuildRes.ethPrebuildResult.txRaw.nonce = '0';
        var objwalletkey = walletkey.find((x) => x.ismine === true);
        if (!(txReq.signs && txReq.signs.filter((ele) => ele.approved == true).length >= m)) {
          console.info('eth req');

          let safeTxHash = '';
          let mypath = "m/44'/60'/0'/0/0";
          if (txnPreBuildRes) {
            txnPreBuildRes.ethPrebuildResult.txOutputs.amount =
              txnPreBuildRes.ethPrebuildResult.txOutputs.destinationAmount;
            safeTxHash = txnPreBuildRes.ethPrebuildResult.txRaw?.safeTxHash;
            for (let i = 0; i < wallet.walletKeys.length; i++) {
              if (wallet.walletKeys[i].ismine) {
                mypath = this.wallet.walletKeys[i].path;
              }
            }
          }

          let trezorResonse;
          if (!objwalletkey || !objwalletkey.provider || objwalletkey.provider == 'trezor') {
            console.log('trezor');
            //Trezor
            let sig;
            sig = await TrezorConnect.ethereumSignMessage({
              path: mypath || "m/44'/60'/0'/0/0",
              message: safeTxHash,
              hex: true,
            });

            trezorResonse = sig;
            console.log('Signing Trezor ' + JSON.stringify(trezorResonse));
            this.logger.info('Signing result ' + JSON.stringify(trezorResonse));
            if (!sig.success) return [{ error: trezorResonse.payload.code }];

            sig = processSig('0x' + sig.payload.signature);
            txnPreBuildRes.ethPrebuildResult.txRaw.signature = sig;
            txnPreBuildRes.ethPrebuildResult.txRaw.sender = trezorResonse.payload.address;
            console.log('sig', sig);
          } else if (objwalletkey.provider == 'ledger') {
            //Ledger
            console.info('Ledger');
            try {
              let Coinname = 'Ethereum';
              let transport = await Transportwebusb.create();
              await LedgerService.openApp(Coinname, transport);
              transport = await LedgerService.getNewTransport();
              const eth = new Eth(transport);

              const sig1 = await eth.signPersonalMessage(
                mypath || "m/44'/60'/0'/0/0",
                safeTxHash.substring(2)
              );
              let sig = sig1['r'] + sig1['s'] + sig1['v'].toString(16);
              var address = await eth
                .getAddress(mypath || "m/44'/60'/0'/0/0")
                .then((o) => o.address);
              txnPreBuildRes.ethPrebuildResult.txRaw.sender = address;
              trezorResonse = {
                signature: sig,
                address: address,
              };
              sig = processSig('0x' + sig);
              txnPreBuildRes.ethPrebuildResult.txRaw.signature = sig;
              console.log('sig', sig);
            } catch (err) {
              if (err.name == 'TransportStatusError') {
                return [{ error: 'Please open Ethereum App on Ledger' }];
              } else {
                console.error(err);
                this.logger.error(err);
                return [{ error: err.message }];
              }
            }
          }

          let sequenceId = Guid.create().toString();
          let halfSignedPayload = {
            flowId: txnPreBuildRes.prebuildTransactionResult.flowId,
            txHex: txnPreBuildRes.ethPrebuildResult.txHex,
            payload: txnPreBuildRes.prebuildTransactionResult.payload,
            txRaw: txnPreBuildRes.ethPrebuildResult.txRaw,
          };
          console.info('halfSignedPayload', halfSignedPayload);

          const signTransactionResponse: signedStakingTransaction = {
            partialSignedTx: {
              halfSigned: {
                payload: JSON.stringify(halfSignedPayload),
              },
              signature: txnPreBuildRes.ethPrebuildResult.txRaw.signature,
              sequenceId: sequenceId,
            },
          };
          if (txnPreBuildRes.ethPrebuildResult.txRaw.signature) {
            var sendresult = await this.httpService.sendStakingTransaction(
              wallet.id,
              validator.id,
              type,
              signTransactionResponse
            );
            sendresult = sendresult;
          }
          console.log(sendresult);
          if (sendresult.status !== 200) {
            return [{ error: sendresult }];
          }
          return 'success';
        }
      }
    } catch (err) {
      if (err.message == 'Ledger device: UNKNOWN_ERROR (0x6a8d)') {
        ErrorPrompt.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Failed to sign transaction!',
          text: 'Allow unverified contracts from Ledger app setting.',
          showConfirmButton: false,
          // timer: 1500
        });
      } else if (err.name == 'TransportOpenUserCancelled') {
        ErrorPrompt.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Failed to sign transaction!',
          text: 'It seems you have not connected your ledger device or please recheck the connection and try again.',
          showConfirmButton: false,
          // timer: 1500
        });
      } else {
        ErrorPrompt.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Failed to sign transaction!',
          text: this.walletService.setCustomErrorMsg(err.message),
          showConfirmButton: false,
          // timer: 1500
        });
      }
      this.segment
        .track('send-asset-transaction-sign-failed', { user: this.authService.getUser, error: err })
        .then(() => console.log('Send asset transaction sign failed'))
        .catch((err) => {});
    }
}
export type signedStakingTransaction = HalfSignedStakeingTransaction;