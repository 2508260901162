import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private data: DataService) {
    datadogLogs.init({
      clientToken: 'pub1db878b6e0aa8a1183cd035505848336',
      datacenter: 'us',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: "Liminal-web",
      env: environment.env,
      version: environment.version,
    });
    datadogLogs.setGlobalContextProperty("team", environment.env);
    datadogRum.init({
      applicationId: 'fe6b117d-0bd5-47ef-ba32-1022d037deac',
      clientToken: 'pub3a729a879e5cf8d8d96626f309b33587',
      site: 'datadoghq.com',
      service: 'liminal-web',
      env: environment.env,
      version: environment.version,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: ["https://pre-dev-api.lmnl.dev", "https://beta-api.lmnl.app", "https://api.lmnl.app", "https://api.lmnl.dev"]
    });

    this.data.getUserProfile.subscribe((userProfile) => {
      if (userProfile) {
        datadogLogs.setGlobalContextProperty("user-id", userProfile.id);
        datadogRum.setGlobalContextProperty("user-id", userProfile.id);
      }
    });
    datadogRum.startSessionReplayRecording();
  }
  info(message: string, context?: {}): void {
    try {
      if (typeof message === 'object') {
        datadogLogs.logger.info(JSON.stringify(message), context);
      } else {
        datadogLogs.logger.info(message, context);
      }
    } catch (e) {
      datadogLogs.logger.error(e);
    }
  }

  error(message: any, context?: {}): void {
    try {
      let logmsg = "";
      if (typeof message[Symbol.iterator] === 'function') {
        for (const msg of message) {
          if (typeof msg === 'string') {
            logmsg += msg;
          } else if (msg instanceof Error) {
            logmsg += msg.stack;
          } else {
            logmsg += JSON.stringify(msg);
          }
        }
      } else {
        logmsg = message;
      }
      datadogLogs.logger.error(logmsg, context);
    } catch (e) {
      datadogLogs.logger.error(e);
    }
  }

  warn(message: string, context?: {}): void {
    datadogLogs.logger.warn(message, context);
  }
}