"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryClientImpl = exports.QueryTallyResultResponse = exports.QueryTallyResultRequest = exports.QueryDepositsResponse = exports.QueryDepositsRequest = exports.QueryDepositResponse = exports.QueryDepositRequest = exports.QueryParamsResponse = exports.QueryParamsRequest = exports.QueryVotesResponse = exports.QueryVotesRequest = exports.QueryVoteResponse = exports.QueryVoteRequest = exports.QueryProposalsResponse = exports.QueryProposalsRequest = exports.QueryProposalResponse = exports.QueryProposalRequest = exports.protobufPackage = void 0;
/* eslint-disable */
const gov_1 = require("./gov");
const pagination_1 = require("../../base/query/v1beta1/pagination");
const binary_1 = require("../../../binary");
const helpers_1 = require("../../../helpers");
exports.protobufPackage = "cosmos.gov.v1beta1";
function createBaseQueryProposalRequest() {
  return {
    proposalId: BigInt(0)
  };
}
exports.QueryProposalRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryProposalRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalId !== BigInt(0)) {
      writer.uint32(8).uint64(message.proposalId);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryProposalRequest();
    if ((0, helpers_1.isSet)(object.proposalId)) obj.proposalId = BigInt(object.proposalId.toString());
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalId !== undefined && (obj.proposalId = (message.proposalId || BigInt(0)).toString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryProposalRequest();
    if (object.proposalId !== undefined && object.proposalId !== null) {
      message.proposalId = BigInt(object.proposalId.toString());
    }
    return message;
  }
};
function createBaseQueryProposalResponse() {
  return {
    proposal: gov_1.Proposal.fromPartial({})
  };
}
exports.QueryProposalResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryProposalResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposal !== undefined) {
      gov_1.Proposal.encode(message.proposal, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposal = gov_1.Proposal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryProposalResponse();
    if ((0, helpers_1.isSet)(object.proposal)) obj.proposal = gov_1.Proposal.fromJSON(object.proposal);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposal !== undefined && (obj.proposal = message.proposal ? gov_1.Proposal.toJSON(message.proposal) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryProposalResponse();
    if (object.proposal !== undefined && object.proposal !== null) {
      message.proposal = gov_1.Proposal.fromPartial(object.proposal);
    }
    return message;
  }
};
function createBaseQueryProposalsRequest() {
  return {
    proposalStatus: 0,
    voter: "",
    depositor: "",
    pagination: undefined
  };
}
exports.QueryProposalsRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryProposalsRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalStatus !== 0) {
      writer.uint32(8).int32(message.proposalStatus);
    }
    if (message.voter !== "") {
      writer.uint32(18).string(message.voter);
    }
    if (message.depositor !== "") {
      writer.uint32(26).string(message.depositor);
    }
    if (message.pagination !== undefined) {
      pagination_1.PageRequest.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryProposalsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalStatus = reader.int32();
          break;
        case 2:
          message.voter = reader.string();
          break;
        case 3:
          message.depositor = reader.string();
          break;
        case 4:
          message.pagination = pagination_1.PageRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryProposalsRequest();
    if ((0, helpers_1.isSet)(object.proposalStatus)) obj.proposalStatus = (0, gov_1.proposalStatusFromJSON)(object.proposalStatus);
    if ((0, helpers_1.isSet)(object.voter)) obj.voter = String(object.voter);
    if ((0, helpers_1.isSet)(object.depositor)) obj.depositor = String(object.depositor);
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageRequest.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalStatus !== undefined && (obj.proposalStatus = (0, gov_1.proposalStatusToJSON)(message.proposalStatus));
    message.voter !== undefined && (obj.voter = message.voter);
    message.depositor !== undefined && (obj.depositor = message.depositor);
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageRequest.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryProposalsRequest();
    message.proposalStatus = object.proposalStatus ?? 0;
    message.voter = object.voter ?? "";
    message.depositor = object.depositor ?? "";
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageRequest.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryProposalsResponse() {
  return {
    proposals: [],
    pagination: undefined
  };
}
exports.QueryProposalsResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryProposalsResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.proposals) {
      gov_1.Proposal.encode(v, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      pagination_1.PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryProposalsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposals.push(gov_1.Proposal.decode(reader, reader.uint32()));
          break;
        case 2:
          message.pagination = pagination_1.PageResponse.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryProposalsResponse();
    if (Array.isArray(object?.proposals)) obj.proposals = object.proposals.map(e => gov_1.Proposal.fromJSON(e));
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageResponse.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.proposals) {
      obj.proposals = message.proposals.map(e => e ? gov_1.Proposal.toJSON(e) : undefined);
    } else {
      obj.proposals = [];
    }
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageResponse.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryProposalsResponse();
    message.proposals = object.proposals?.map(e => gov_1.Proposal.fromPartial(e)) || [];
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageResponse.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryVoteRequest() {
  return {
    proposalId: BigInt(0),
    voter: ""
  };
}
exports.QueryVoteRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryVoteRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalId !== BigInt(0)) {
      writer.uint32(8).uint64(message.proposalId);
    }
    if (message.voter !== "") {
      writer.uint32(18).string(message.voter);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryVoteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.uint64();
          break;
        case 2:
          message.voter = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryVoteRequest();
    if ((0, helpers_1.isSet)(object.proposalId)) obj.proposalId = BigInt(object.proposalId.toString());
    if ((0, helpers_1.isSet)(object.voter)) obj.voter = String(object.voter);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalId !== undefined && (obj.proposalId = (message.proposalId || BigInt(0)).toString());
    message.voter !== undefined && (obj.voter = message.voter);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryVoteRequest();
    if (object.proposalId !== undefined && object.proposalId !== null) {
      message.proposalId = BigInt(object.proposalId.toString());
    }
    message.voter = object.voter ?? "";
    return message;
  }
};
function createBaseQueryVoteResponse() {
  return {
    vote: gov_1.Vote.fromPartial({})
  };
}
exports.QueryVoteResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryVoteResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.vote !== undefined) {
      gov_1.Vote.encode(message.vote, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryVoteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.vote = gov_1.Vote.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryVoteResponse();
    if ((0, helpers_1.isSet)(object.vote)) obj.vote = gov_1.Vote.fromJSON(object.vote);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.vote !== undefined && (obj.vote = message.vote ? gov_1.Vote.toJSON(message.vote) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryVoteResponse();
    if (object.vote !== undefined && object.vote !== null) {
      message.vote = gov_1.Vote.fromPartial(object.vote);
    }
    return message;
  }
};
function createBaseQueryVotesRequest() {
  return {
    proposalId: BigInt(0),
    pagination: undefined
  };
}
exports.QueryVotesRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryVotesRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalId !== BigInt(0)) {
      writer.uint32(8).uint64(message.proposalId);
    }
    if (message.pagination !== undefined) {
      pagination_1.PageRequest.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryVotesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.uint64();
          break;
        case 2:
          message.pagination = pagination_1.PageRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryVotesRequest();
    if ((0, helpers_1.isSet)(object.proposalId)) obj.proposalId = BigInt(object.proposalId.toString());
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageRequest.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalId !== undefined && (obj.proposalId = (message.proposalId || BigInt(0)).toString());
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageRequest.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryVotesRequest();
    if (object.proposalId !== undefined && object.proposalId !== null) {
      message.proposalId = BigInt(object.proposalId.toString());
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageRequest.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryVotesResponse() {
  return {
    votes: [],
    pagination: undefined
  };
}
exports.QueryVotesResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryVotesResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.votes) {
      gov_1.Vote.encode(v, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      pagination_1.PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryVotesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.votes.push(gov_1.Vote.decode(reader, reader.uint32()));
          break;
        case 2:
          message.pagination = pagination_1.PageResponse.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryVotesResponse();
    if (Array.isArray(object?.votes)) obj.votes = object.votes.map(e => gov_1.Vote.fromJSON(e));
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageResponse.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.votes) {
      obj.votes = message.votes.map(e => e ? gov_1.Vote.toJSON(e) : undefined);
    } else {
      obj.votes = [];
    }
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageResponse.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryVotesResponse();
    message.votes = object.votes?.map(e => gov_1.Vote.fromPartial(e)) || [];
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageResponse.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryParamsRequest() {
  return {
    paramsType: ""
  };
}
exports.QueryParamsRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryParamsRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.paramsType !== "") {
      writer.uint32(10).string(message.paramsType);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryParamsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.paramsType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryParamsRequest();
    if ((0, helpers_1.isSet)(object.paramsType)) obj.paramsType = String(object.paramsType);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.paramsType !== undefined && (obj.paramsType = message.paramsType);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryParamsRequest();
    message.paramsType = object.paramsType ?? "";
    return message;
  }
};
function createBaseQueryParamsResponse() {
  return {
    votingParams: gov_1.VotingParams.fromPartial({}),
    depositParams: gov_1.DepositParams.fromPartial({}),
    tallyParams: gov_1.TallyParams.fromPartial({})
  };
}
exports.QueryParamsResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryParamsResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.votingParams !== undefined) {
      gov_1.VotingParams.encode(message.votingParams, writer.uint32(10).fork()).ldelim();
    }
    if (message.depositParams !== undefined) {
      gov_1.DepositParams.encode(message.depositParams, writer.uint32(18).fork()).ldelim();
    }
    if (message.tallyParams !== undefined) {
      gov_1.TallyParams.encode(message.tallyParams, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryParamsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.votingParams = gov_1.VotingParams.decode(reader, reader.uint32());
          break;
        case 2:
          message.depositParams = gov_1.DepositParams.decode(reader, reader.uint32());
          break;
        case 3:
          message.tallyParams = gov_1.TallyParams.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryParamsResponse();
    if ((0, helpers_1.isSet)(object.votingParams)) obj.votingParams = gov_1.VotingParams.fromJSON(object.votingParams);
    if ((0, helpers_1.isSet)(object.depositParams)) obj.depositParams = gov_1.DepositParams.fromJSON(object.depositParams);
    if ((0, helpers_1.isSet)(object.tallyParams)) obj.tallyParams = gov_1.TallyParams.fromJSON(object.tallyParams);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.votingParams !== undefined && (obj.votingParams = message.votingParams ? gov_1.VotingParams.toJSON(message.votingParams) : undefined);
    message.depositParams !== undefined && (obj.depositParams = message.depositParams ? gov_1.DepositParams.toJSON(message.depositParams) : undefined);
    message.tallyParams !== undefined && (obj.tallyParams = message.tallyParams ? gov_1.TallyParams.toJSON(message.tallyParams) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryParamsResponse();
    if (object.votingParams !== undefined && object.votingParams !== null) {
      message.votingParams = gov_1.VotingParams.fromPartial(object.votingParams);
    }
    if (object.depositParams !== undefined && object.depositParams !== null) {
      message.depositParams = gov_1.DepositParams.fromPartial(object.depositParams);
    }
    if (object.tallyParams !== undefined && object.tallyParams !== null) {
      message.tallyParams = gov_1.TallyParams.fromPartial(object.tallyParams);
    }
    return message;
  }
};
function createBaseQueryDepositRequest() {
  return {
    proposalId: BigInt(0),
    depositor: ""
  };
}
exports.QueryDepositRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryDepositRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalId !== BigInt(0)) {
      writer.uint32(8).uint64(message.proposalId);
    }
    if (message.depositor !== "") {
      writer.uint32(18).string(message.depositor);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDepositRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.uint64();
          break;
        case 2:
          message.depositor = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryDepositRequest();
    if ((0, helpers_1.isSet)(object.proposalId)) obj.proposalId = BigInt(object.proposalId.toString());
    if ((0, helpers_1.isSet)(object.depositor)) obj.depositor = String(object.depositor);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalId !== undefined && (obj.proposalId = (message.proposalId || BigInt(0)).toString());
    message.depositor !== undefined && (obj.depositor = message.depositor);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryDepositRequest();
    if (object.proposalId !== undefined && object.proposalId !== null) {
      message.proposalId = BigInt(object.proposalId.toString());
    }
    message.depositor = object.depositor ?? "";
    return message;
  }
};
function createBaseQueryDepositResponse() {
  return {
    deposit: gov_1.Deposit.fromPartial({})
  };
}
exports.QueryDepositResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryDepositResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.deposit !== undefined) {
      gov_1.Deposit.encode(message.deposit, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDepositResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deposit = gov_1.Deposit.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryDepositResponse();
    if ((0, helpers_1.isSet)(object.deposit)) obj.deposit = gov_1.Deposit.fromJSON(object.deposit);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.deposit !== undefined && (obj.deposit = message.deposit ? gov_1.Deposit.toJSON(message.deposit) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryDepositResponse();
    if (object.deposit !== undefined && object.deposit !== null) {
      message.deposit = gov_1.Deposit.fromPartial(object.deposit);
    }
    return message;
  }
};
function createBaseQueryDepositsRequest() {
  return {
    proposalId: BigInt(0),
    pagination: undefined
  };
}
exports.QueryDepositsRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryDepositsRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalId !== BigInt(0)) {
      writer.uint32(8).uint64(message.proposalId);
    }
    if (message.pagination !== undefined) {
      pagination_1.PageRequest.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDepositsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.uint64();
          break;
        case 2:
          message.pagination = pagination_1.PageRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryDepositsRequest();
    if ((0, helpers_1.isSet)(object.proposalId)) obj.proposalId = BigInt(object.proposalId.toString());
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageRequest.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalId !== undefined && (obj.proposalId = (message.proposalId || BigInt(0)).toString());
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageRequest.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryDepositsRequest();
    if (object.proposalId !== undefined && object.proposalId !== null) {
      message.proposalId = BigInt(object.proposalId.toString());
    }
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageRequest.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryDepositsResponse() {
  return {
    deposits: [],
    pagination: undefined
  };
}
exports.QueryDepositsResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryDepositsResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.deposits) {
      gov_1.Deposit.encode(v, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      pagination_1.PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDepositsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deposits.push(gov_1.Deposit.decode(reader, reader.uint32()));
          break;
        case 2:
          message.pagination = pagination_1.PageResponse.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryDepositsResponse();
    if (Array.isArray(object?.deposits)) obj.deposits = object.deposits.map(e => gov_1.Deposit.fromJSON(e));
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageResponse.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.deposits) {
      obj.deposits = message.deposits.map(e => e ? gov_1.Deposit.toJSON(e) : undefined);
    } else {
      obj.deposits = [];
    }
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageResponse.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryDepositsResponse();
    message.deposits = object.deposits?.map(e => gov_1.Deposit.fromPartial(e)) || [];
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageResponse.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryTallyResultRequest() {
  return {
    proposalId: BigInt(0)
  };
}
exports.QueryTallyResultRequest = {
  typeUrl: "/cosmos.gov.v1beta1.QueryTallyResultRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.proposalId !== BigInt(0)) {
      writer.uint32(8).uint64(message.proposalId);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTallyResultRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.proposalId = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryTallyResultRequest();
    if ((0, helpers_1.isSet)(object.proposalId)) obj.proposalId = BigInt(object.proposalId.toString());
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.proposalId !== undefined && (obj.proposalId = (message.proposalId || BigInt(0)).toString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryTallyResultRequest();
    if (object.proposalId !== undefined && object.proposalId !== null) {
      message.proposalId = BigInt(object.proposalId.toString());
    }
    return message;
  }
};
function createBaseQueryTallyResultResponse() {
  return {
    tally: gov_1.TallyResult.fromPartial({})
  };
}
exports.QueryTallyResultResponse = {
  typeUrl: "/cosmos.gov.v1beta1.QueryTallyResultResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.tally !== undefined) {
      gov_1.TallyResult.encode(message.tally, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTallyResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tally = gov_1.TallyResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryTallyResultResponse();
    if ((0, helpers_1.isSet)(object.tally)) obj.tally = gov_1.TallyResult.fromJSON(object.tally);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.tally !== undefined && (obj.tally = message.tally ? gov_1.TallyResult.toJSON(message.tally) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryTallyResultResponse();
    if (object.tally !== undefined && object.tally !== null) {
      message.tally = gov_1.TallyResult.fromPartial(object.tally);
    }
    return message;
  }
};
class QueryClientImpl {
  constructor(rpc) {
    this.rpc = rpc;
    this.Proposal = this.Proposal.bind(this);
    this.Proposals = this.Proposals.bind(this);
    this.Vote = this.Vote.bind(this);
    this.Votes = this.Votes.bind(this);
    this.Params = this.Params.bind(this);
    this.Deposit = this.Deposit.bind(this);
    this.Deposits = this.Deposits.bind(this);
    this.TallyResult = this.TallyResult.bind(this);
  }
  Proposal(request) {
    const data = exports.QueryProposalRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Proposal", data);
    return promise.then(data => exports.QueryProposalResponse.decode(new binary_1.BinaryReader(data)));
  }
  Proposals(request) {
    const data = exports.QueryProposalsRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Proposals", data);
    return promise.then(data => exports.QueryProposalsResponse.decode(new binary_1.BinaryReader(data)));
  }
  Vote(request) {
    const data = exports.QueryVoteRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Vote", data);
    return promise.then(data => exports.QueryVoteResponse.decode(new binary_1.BinaryReader(data)));
  }
  Votes(request) {
    const data = exports.QueryVotesRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Votes", data);
    return promise.then(data => exports.QueryVotesResponse.decode(new binary_1.BinaryReader(data)));
  }
  Params(request) {
    const data = exports.QueryParamsRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Params", data);
    return promise.then(data => exports.QueryParamsResponse.decode(new binary_1.BinaryReader(data)));
  }
  Deposit(request) {
    const data = exports.QueryDepositRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Deposit", data);
    return promise.then(data => exports.QueryDepositResponse.decode(new binary_1.BinaryReader(data)));
  }
  Deposits(request) {
    const data = exports.QueryDepositsRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "Deposits", data);
    return promise.then(data => exports.QueryDepositsResponse.decode(new binary_1.BinaryReader(data)));
  }
  TallyResult(request) {
    const data = exports.QueryTallyResultRequest.encode(request).finish();
    const promise = this.rpc.request("cosmos.gov.v1beta1.Query", "TallyResult", data);
    return promise.then(data => exports.QueryTallyResultResponse.decode(new binary_1.BinaryReader(data)));
  }
}
exports.QueryClientImpl = QueryClientImpl;
