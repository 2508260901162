"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.equal = exports.concat = exports.HEX_REGEX = void 0;
const utils_1 = require("@noble/hashes/utils");
exports.HEX_REGEX = /^[A-F0-9]*$/iu;
function concat(views) {
  return (0, utils_1.concatBytes)(...views);
}
exports.concat = concat;
function equal(buf1, buf2) {
  if (buf1.byteLength !== buf2.byteLength) {
    return false;
  }
  const dv1 = new Int8Array(buf1);
  const dv2 = new Int8Array(buf2);
  for (let i = 0; i !== buf1.byteLength; i++) {
    if (dv1[i] !== dv2[i]) {
      return false;
    }
  }
  return true;
}
exports.equal = equal;
