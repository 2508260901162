import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { DataService } from '../../../../shared/services/data.service';
import { HttpService } from '../../../../shared/services/http.service';
import {
  ErrorPrompt,
  SuccessPrompt,
} from '../../../../shared/components/custom-prompt/custom-prompt.service';
import { ExchangeService } from '../exchange.service';
import { IAddExchangeAccount } from '@entities/Exchange';
import { TeamsService } from '../../../../shared/services/teams.service';
import { TeamSigningSubType, TeamSigningType, TeamsV2 } from '@entities/teams.model';
import {
  AccountToolTips,
  exchangeGuides,
  ExchangePromptMessages,
} from '../../../../shared/constants/exchange.const';
import { MembersType } from '../../../../shared/components/new-group-wallet/mobile-teams/mobile-teams.component';
import { MPCTeamConfig, MPCWalletData } from '@entities/wallet';

interface ApiKeysInfo {
  [key: string]: any;
}

@Component({
  selector: 'app-add-exchange',
  templateUrl: './add-exchange.component.html',
  styleUrls: ['./add-exchange.component.scss'],
})
export class AddExchangeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('exchangeModal') exchangeModal: ElementRef;
  @ViewChild('failureExchangeModal') failureExchangeModal: ElementRef;
  @Input() openExchangeModal: any;
  @Input() exchangeId: string;
  @Input() accountId: number;
  @Input() openComponent: string;
  @Output() close: EventEmitter<any> = new EventEmitter();

  private accountInfoSubscription$: Subscription;

  addExchangeForm: FormGroup;
  selectedExchange = null;
  currentStep: number = 1;
  apiKeysInfo: ApiKeysInfo;
  onDestroy$: Subject<void> = new Subject();
  exchangeSearchText;
  teamsListData: TeamsV2[];
  selectedMainAccount: any;
  userprofile: any;
  dynamicFields = [];
  mainAccountDetails = [];
  tooltipText = '';
  loading = false;
  exchangeList = [];
  orgProfile: any;
  teamsListInitiators: TeamsV2[];
  teamsListApprovers: TeamsV2[];
  filteredExchangeList: any[] = [];
  teamsSubscription$: Subscription;
  submitAccountInfo = false;
  hasSubAccSupport = false;
  mainAccountTooltip = '';
  messageConstants = ExchangePromptMessages.EXCHANGE_ADD_FAILED;
  isAppsLoading = false;
  isBalanceSyncError: boolean;
  MembersType = MembersType;
  mpcWalletData: MPCWalletData;
  isCommonSignersInitiators: boolean = false;
  focusTotInit: boolean;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private modalService: NgbModal,
    private httpService: HttpService,
    private exchangeService: ExchangeService,
    private teamsService: TeamsService
  ) {
    this.dataService.getUserProfile.pipe(takeUntil(this.onDestroy$)).subscribe((user_detail) => {
      this.userprofile = user_detail;
      this.orgProfile = user_detail['organizations'][0];
    });
  }

  /**
   * Lifecycle hook that is called when any data-bound property of the component changes.
   *
   * @param changes - An object containing the changed properties and their current and previous values.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openExchangeModal'] && changes['openExchangeModal'].currentValue) {
      if (this.openComponent === 'overview') {
        this.handleOverviewAdd();
      } else {
        this.filteredExchangeList = [];
        this.getExchangeList();
        this.getTeamList();
        this.openModal();
      }
    }
  }

  /**
   * @description Gets called with we try to add an exchange from overview page
   * with prefilled data
   */
  async handleOverviewAdd() {
    this.filteredExchangeList = [];
    this.getTeamList();
    /** set currentstep to 0 to show loader and skip first step  */
    this.currentStep = 0;
    this.openModal();
    await this.getExchangeList();
    const ex = this.exchangeList.find((exchange) => exchange.id === Number(this.exchangeId));
    this.exchangeSelected(ex);
    this.currentStep = 2;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initializeExchangeForm();
  }

  /**
   * Performs search functionality
   */
  filterExchanges() {
    this.filteredExchangeList = this.exchangeList.filter((exchange) =>
      exchange.name.toLowerCase().includes(this.exchangeSearchText.toLowerCase())
    );
  }

  openModal() {
    this.mpcWalletData = new MPCWalletData();
    this.modalService
      .open(this.exchangeModal, {
        centered: true,
      })
      .result.then(
        (_result) => {
          this.resetModal();
        },
        (_reason) => {
          this.resetModal();
        }
      );
  }

  resetModal() {
    this.addExchangeForm.reset();
    this.currentStep = 1;
    this.resetExchangeSelection();
    this.mpcWalletData = null;
  }

  onInitiatorsSelect(initiators: MPCTeamConfig) {
    this.mpcWalletData.initiators = initiators;
  }

  onSignersSelect(signers: MPCTeamConfig) {
    this.mpcWalletData.signers = signers;
  }

  get isValidInitiators(): boolean {
    return this.mpcWalletData.initiators?.isValidTeam;
  }

  get isValidSigners(): boolean {
    return this.mpcWalletData.signers?.isValidTeam;
  }

  onNextOverview() {
    this.currentStep++;
  }

  getMpcCommonMembers() {
    const initiatorMembers = this.mpcWalletData.initiators?.team?.teamMembers;
    const signerMembers = this.mpcWalletData.signers?.team?.teamMembers;

    if (initiatorMembers && signerMembers) {
      const initiatorIds = new Set(initiatorMembers.map((member) => member.user?.id));
      return signerMembers.filter((signerMember) => initiatorIds.has(signerMember.user?.id));
    }

    return [];
  }

  resetExchangeSelection() {
    this.exchangeSearchText = '';
    this.filteredExchangeList = this.exchangeList;
  }

  initializeExchangeForm(): void {
    this.addExchangeForm = this.fb.group({
      accountType: ['', [Validators.required]],
      mainAccount: ['', [Validators.required]],
      exchangeName: ['', [Validators.required]],
      connectionName: ['', [Validators.required]],
    });

    this.addExchangeForm.get('accountType').valueChanges.subscribe((value) => {
      const mainAccountControl = this.addExchangeForm.get('mainAccount');

      if (value === 'main') {
        mainAccountControl.setValue('');
        mainAccountControl.disable();
      } else if (value === 'sub') {
        mainAccountControl.enable();
      }
    });
  }

  isSubAccountSelected() {
    return this.addExchangeForm.get('accountType').value === 'sub' && this.hasSubAccSupport;
  }

  toggleVisibility(field: any): void {
    field.encrypted = !field.encrypted;
  }

  prevStep(): void {
    this.currentStep--;
  }

  /**
   * Retrieves the list of exchanges asynchronously.
   * Sets the exchangeList and filteredExchangeList properties based on the retrieved data.
   * Handles loading and error states.
   */
  async getExchangeList() {
    this.isAppsLoading = true;
    try {
      const data = await this.exchangeService.getExchangesList().toPromise();
      if (data?.data?.length) {
        this.exchangeList = data.data.map((item) => ({
          name: item.name,
          exchange: item.name,
          exchangeIcon: item.appId.imageurl,
          app_id: item.appId.id,
          id: item.id,
          status: item.status,
          has: item.has,
          loading: false,
        }));

        this.filteredExchangeList = [...this.exchangeList];
      }
      this.isAppsLoading = false;
    } catch (error) {
      this.isAppsLoading = false;
      this.filteredExchangeList = [];
    }
  }

  exchangeSelected(ex) {
    this.initializeExchangeForm();
    this.dynamicFields = [];
    this.selectedExchange = ex;
    this.loading = true;
    this.addExchangeForm.controls['exchangeName'].setValue(ex.name);
    this.getMainAccounts();
    this.getAppFields(ex);
    this.resetExchangeSelection();
    this.hasSubAccSupport = ex?.has?.hasSubAccSupport;
  }

  isSubAccountDisable(data): boolean {
    return data.length <= 0;
  }

  /**
   * Retrieves the main accounts for the selected exchange.
   * If sub-account support is available and there are main accounts,
   * sets the account type to 'sub', otherwise sets it to 'main'.
   * Updates the tooltip text and main account tooltip accordingly.
   * Sets the main account details for the overview.
   * Handles any errors that occur during the process.
   */
  async getMainAccounts() {
    try {
      const details = await this.exchangeService
        .getMainAccounts('main', this.selectedExchange.id)
        .toPromise();
      this.mainAccountDetails = details?.data || [];
      if (this.mainAccountDetails.length && this.hasSubAccSupport) {
        this.addExchangeForm.get('accountType').setValue('sub');
      } else {
        this.addExchangeForm.get('accountType').setValue('main');
      }
      this.tooltipText = !this.hasSubAccSupport
        ? AccountToolTips.NO_SUB_ACC_ACCESS
        : this.isSubAccountDisable(this.mainAccountDetails)
          ? AccountToolTips.MAIN_ACC_REQ
          : '';
      this.mainAccountTooltip =
        this.mainAccountDetails.length > 0 ? AccountToolTips.ONE_MAIN_ACC : '';
      this.setMainAccForOverview(this.mainAccountDetails);
      this.loading = false;
    } catch (error) {
      console.error(`Error ocurred in fetching main account in UI : ${error}`);
      this.loading = false;
    }
  }

  setMainAccForOverview(mainAccDetails) {
    if (this.accountId) {
      const account = mainAccDetails.find((acc) => acc.accountId === Number(this.accountId));
      this.OnMainAccountSelect(account);
    }
  }

  /**
   * Retrieves the fields for the specified exchange app and updates the form with the dynamic fields.
   * @param exchange - The exchange object.
   */
  getAppFields(exchange) {
    this.currentStep++;
    this.httpService.getAppsField(exchange.app_id).subscribe({
      next: (res) => {
        this.dynamicFields = res?.data || [];
        this.dynamicFields.forEach((field) => {
          field['visible'] = field.encrypted;
          this.addExchangeForm.addControl(
            field.keyName,
            this.fb.control('', [Validators.required])
          );
        });
      },
      error: (error) => {
        console.error(`error in fetching app fields in UI : ${error}`);
      },
    });
  }

  // Step2
  submitExchangeForm(): void {
    const exchangeData: IAddExchangeAccount = {
      exchangeId: this.selectedExchange.id,
      connectionName: this.addExchangeForm.value.connectionName,
      exchangeSubType: 'main',
      userId: this.userprofile.id,
      keys: {},
    };

    this.dynamicFields.map((field) => {
      exchangeData['keys'][field.keyName] = this.addExchangeForm.value[field.keyName];
    });
    this.apiKeysInfo = exchangeData['keys'];
    this.currentStep++;
  }

  addExchangeConnection(): void {
    if (this.submitAccountInfo) {
      return;
    }
    this.submitAccountInfo = true;
    this.finalSubmit();
  }

  /**
   * Performs the final submission of the exchange form.
   * Constructs the exchange data object with the selected exchange, account type, connection name, user ID, initiator team ID, approver team ID, and keys.
   * Maps the dynamic fields to the exchange data object.
   * Calls the addExchangeAccount method of the exchange service to add the exchange account.
   * Displays success or failure alerts based on the result.
   */
  async finalSubmit() {
    const exchangeData = {
      exchangeId: this.selectedExchange.id,
      exchangeSubType: this.addExchangeForm.value.accountType,
      connectionName: this.addExchangeForm.value.connectionName,
      userId: this.userprofile.id,
      initiatorTeamId: this.mpcWalletData.initiators?.team?.id,
      approverTeamId: this.mpcWalletData.signers?.team?.id,
      minInitiatorsRequired: this.mpcWalletData.initiators?.minMembersRequired,
      keys: {},
    };
    this.dynamicFields.map((field) => {
      exchangeData['keys'][field.keyName] = this.addExchangeForm.value[field.keyName];
    });

    this.accountInfoSubscription$ = this.exchangeService
      .addExchangeAccount(exchangeData, this.orgProfile.id)
      .pipe(
        switchMap((accountInfo: any) => {
          if (!accountInfo.success) {
            return throwError('Failed to add exchange account');
          }
          return this.exchangeService.syncExchangeAccountBalance(accountInfo.data.accountId).pipe(
            catchError((error) => {
              console.error(`Error occurred in sync balance API: ${JSON.stringify(error)}`);
              let balanceErrorDesc = ExchangePromptMessages.EXCHANGE_BALANCE_SYNC_FAILED.subTitle;
              if (error.code === 'NO_ASSETS_FOUND_FROM_EXCHANGE_ACCOUNT') {
                balanceErrorDesc = error.message;
              }
              this.showErrorAlert(
                ExchangePromptMessages.EXCHANGE_BALANCE_SYNC_FAILED.title,
                balanceErrorDesc,
                true
              );
              this.isBalanceSyncError = true;
              return throwError(error);
            })
          );
        }),
        catchError((error) => {
          console.error(`Error occurred in add exchange account API: ${JSON.stringify(error)}`);
          this.submitAccountInfo = false;
          if (!this.isBalanceSyncError) {
            this.showErrorAlert(
              ExchangePromptMessages.EXCHANGE_ADD_FAILED.Failed,
              error.message,
              false
            );
          }
          return throwError(error);
        })
      )
      .subscribe((balanceInfo) => {
        if (balanceInfo.success) {
          this.showSuccessAlert(balanceInfo);
        } else {
          this.showErrorAlert(
            ExchangePromptMessages.EXCHANGE_BALANCE_SYNC_FAILED.Error,
            ExchangePromptMessages.EXCHANGE_ADD_FAILED.subTitle,
            false
          );
        }
        this.submitAccountInfo = false;
      });
  }

  showSuccessAlert(accountInfo) {
    SuccessPrompt.fire(
      {
        title: ExchangePromptMessages.EXCHANGE_ADD_SUCCESS.title,
        text: ExchangePromptMessages.EXCHANGE_ADD_SUCCESS.subTitle,
        confirmButtonText: ExchangePromptMessages.EXCHANGE_ADD_SUCCESS.confirmTxt,
      },
      () => {
        this.modalService.dismissAll();
        this.close.emit(accountInfo);
      }
    );
  }

  showErrorAlert(title, message, isBalanceSyncError) {
    ErrorPrompt.fire(
      {
        title: title,
        text: message,
      },
      () => {
        if (isBalanceSyncError) {
          this.modalService.dismissAll();
        }
      }
    );
  }

  getTeamList() {
    this.teamsSubscription$ = this.teamsService.getAllTeams().subscribe((allTeams) => {
      this.teamsListData = allTeams.teamListData;
      this.teamsListInitiators = allTeams.teamListData.filter(
        (team) => TeamSigningType.NON_SIGNING === team.type
      );
      this.teamsListApprovers = allTeams.teamListData.filter(
        (team) => TeamSigningType.SIGNING === team.type && TeamSigningSubType.MPC === team.subType
      );
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.teamsSubscription$) {
      this.teamsSubscription$.unsubscribe();
    }
    if (this.accountInfoSubscription$) {
      this.accountInfoSubscription$.unsubscribe();
    }
  }

  OnMainAccountSelect(data) {
    this.selectedMainAccount = data;
    this.addExchangeForm.controls['mainAccount'].setValue(data.name);
  }

  openHelp(): void {
    window.open(exchangeGuides[this.selectedExchange.name], '_blank');
  }
}
