import { Component, Input, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from "../../services/data.service";
import { AuthServiceJWT } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WalletserviceService } from '../../services/walletservice.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Wallet } from '../../entities/wallet';
import { Policy } from '../../entities/Policy';

import { UserRoleEnum } from '../../entities/members';
import { environment } from 'src/environments/environment';
import { SegmentService } from 'ngx-segment-analytics';
import { IConsolidationRule } from '@entities/ConsolidationRule';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { PolicyService } from '../../services/policy.service';
@Component({
  selector: 'app-consolidation-rule-add',
  templateUrl: './consolidation-rule-add.component.html',
  styleUrls: ['./consolidation-rule-add.component.scss']
})
export class ConsolidationRuleAddComponent implements OnInit {
  closeResult = '';
  wallets: Wallet[];
  _wallets: Wallet[] = [];
  assets: Wallet[];
  searchWalletInput: string;
  searchAssetInput: string;
  selectedWallet: Wallet[];
  selectedAsset: Wallet[];
  selectedAddress: any;
  nextAddress: any;
  selectedAmount: any;
  // selectedConsolidationType:string;
  isSigning: boolean = false;
  setAmount: number;
  // minMaxError: boolean = false;
  // minFees:number;
  maxFees: number;
  step: string = 'step-1';
  spillover: number;
  currency: number = 0;
  consolidationType: string = "Auto";
  private onDestroy$: Subject<void> = new Subject<void>();
  @Input() orgType: any;
  @Input() state: any = null;
  @Input() display: any = "button";
  @Input() rule: any = null;
  @ViewChild("successModal") successModal: ElementRef;
  userTypeEnum = UserRoleEnum;


  isOwner = false;
  userType = UserRoleEnum.admin;
  _update = false;

  constructor(private segment: SegmentService, private walletService: WalletserviceService, private data: DataService, public authService: AuthServiceJWT, private httpService: HttpService, private modalService: NgbModal,
    private policyService: PolicyService) {
    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallets => {
      if (wallets) {
        this.wallets = wallets;
        const consolid_supp_chains = environment.smart_consolidation_supported_chains;
        this._wallets = [...new Map(wallets.map(item => [item.id, item])).values()];
        // this._wallets = this._wallets.filter(ele => ele.chain == 'ETH' && ele.subtype?.toLowerCase() == 'deposit' && ele.isArchived != 1);
        this._wallets = this._wallets.filter(ele => ele.subtype?.toLowerCase() == 'deposit' && ele.isArchived != 1 && consolid_supp_chains.includes(ele.parentchain?.toUpperCase()));
        // this._wallets = this._wallets.filter(ele => ele.isArchived != 1);
        // console.log('rule',this._wallets);
      }
    });

    this.data.getUserProfile.subscribe(async user => {

      this.isOwner = user['organizations'][0]?.isowner === 1 ? true : false;
      this.httpService.$OrgMembers(user['organizations'][0]?.id).subscribe((members: any) => {
        const userNew = members.members.find((e: any) => e.id === user.id);
        this.userType = userNew?.userType;
      })
    });

  }

  ngOnInit(): void {
    console.log(this.rule);
  }

  open(content: any) {
    if (this._wallets.length == 0) {
    return;
    }
    this._update = false;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result: any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason: any) => {
      this.resetSendModel();
    });
  }

  update(content: any) {
    this.resetSendModel();
    this.setAmount = this.rule.ConsolidateMinAmount;
    this.currency = this.rule.currency;
    // this.currency ^= 1;
    // console.log(this.currency, this.rule.currency);
    // this.selectedWallet = {
    //   // @ts-ignore
    //   id: this.rule.walletID,
    //   name: this.rule.walletname,
    //   chain: this.rule.chain,
    //   coin: this.rule.coin,
    // };
    this.selectWallet(this._wallets.find(e => e.id === this.rule.walletID));
    console.log("Assets", this.assets);
    if (!this.rule.asset) this.rule.asset = 'All Assets';
    // @ts-ignore
    this.selectedAsset = this.assets.find(e => e.coin.toLowerCase() === this.rule.asset.toLowerCase());
    console.log("Selected Asset", this.selectedAsset);
    this.selectedAddress = this.selectedAsset['policy'].find(e => e.condition === this.rule.ConsolidateDestinationAddress);
    // this.selectedAddress = {
    //   name: this.rule.DestinationAddressLabel,
    //   condition: this.rule.ConsolidateDestinationAddress
    // };
    if (this.rule.ConsolidateMaxFee) {
      this.consolidationType = "Custom";
      this.maxFees = this.rule.ConsolidateMaxFee;
    }
    if ('spillover' in this.rule) {
      this._update = true;
      this.spillover = this.rule.spillover.value;
      this.nextAddress = {
        name: this.rule.spillover.label,
        condition: this.rule.spillover.address
      };
    }
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result: any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason: any) => {
      this.resetSendModel();
    });
  }

  resetSendModel() {
    this.searchWalletInput = '';
    this.searchAssetInput = '';
    this.selectedWallet = null;
    this.selectedAsset = null;
    this.selectedAddress = null;
    this.nextAddress = null;
    // this.selectedConsolidationType = '';
    this.setAmount = null;
    // this.minFees = null;
    this.maxFees = null;
    this.currency = 0;
    this.spillover = null;
    this.step = "step-1";
    this.consolidationType = "Auto";
    this._update = false;
  }

  async selectWallet(wallet) {
    this.selectedWallet = wallet;
    wallet.policy = await this.policyService.getWalletPolicySync(wallet.id);
    this.assets = this.wallets.filter(obj => obj.id == wallet.id);
    this.assets = this.assets.sort((a, b) => a.coin.localeCompare(b.coin));
    let total = this.assets.reduce((accumulator, object) => {
      object.policy = wallet.policy;
      // @ts-ignore
      return accumulator + parseFloat(object["balanceUSD"]);
    }, 0);
    // let policies = [...new Set(this.assets.map(a => a.policy).flat())];
    let policies = wallet.policy;
    // @ts-ignore
    this.assets.unshift({
      "coin": "All Assets",
      "balanceUSD": total,
      // @ts-ignore
      "policy": policies,
      "imageurl": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAObSURBVHgBlVbNThNRFD7nUtoCBQcFUcEwQiKghp+EPbBwI5jAE4BPABMTt8Ja+XkDyxOgAnHhAtjoxsTKpkACFgORBixF/myh93rOZVoozBT4NtM79879zvnOXxGugTeT4fY8UN2Iog0QTQBlAGAclIooJeeOhQy+6nwScvoWr0Iw+ilsQgrf0el2XitQq/RpiAji9M5ExGp6adrH30NSWVZPQ+RaRCNTi72IMHZiPYSkkuMvux6NXTBmeqlZqdQAkfbSMi4UDvR31Y3nJBqd+G6At7CfLu+jpelwJEKfzkJSDp23nAkB5QR7SGR9abILRMNTCwMC8TV7kO8RUFtRAqVF3sz+9n4SovFDeibStg5anXVDWWQstcIZIjNIxhY2BrOtWWSCwSJ/PrTWlMH9siJwA5N9WYzCfuKYlyFIHnZYPS3xzF2UOCCIDGHWelbfgedJKm4UQNvju+Alb66C+UgM5n/FIH3h2b2RqXBQx0yqDn3b8HS4j0lKi3zwtKnyyiSMRvMm1FcanIrtw5PhwbN7qCDIT8rSbn0jx4TlYk/csEFShch6J7TWlgEbKQT260SyYT1vmOVSQCHaxNuPi92cIbW3iyHg9zhelDiSsLq5B+XFPnADkxEM6fEPZG0o5AI2hRCym9c1d0pcScJrcSj0eaDylntyVBgFWnLyqjd7R3GCGKSaaCoN+Fy9Wfq9o8V+eK8ELkPViSHmWfkQqGtwARNjszfPOfhrsQM4oPRtqDLA58nLeu8UrwDFWcObb2ZeCl3wEc0QKMh3JNraOYTq8sAFkvU/+1B5sxBcIfO0R7pLUPyVlHMe7r6xvYThdL75wa2sNWcekzB5eYnfnUikdOHavQ+Qurog/UP7/47gMmzuJnTmVZEndyjwToiSAgyLRgV7w8VK4+MDr4VMqbnksdQtJRfWt05I3DKPjeU7qG7mdK/jxkpq4RHqdBfi2K9b/vxqLCcRy5grvZeju/qJIH7aDdWkYGXmkrB6HsSVUuPs9op9+Lpgb04NpdGikHvSC6uzIZg+o7MOj6iaESLfljdhey8B1wGTfP6xnlmzdDSnWs6SaI70D52KoGa8HjQaq+1GeQlYha8LUdjTowKDIOU49zens9nz6HRgmdxSeOjVVBQ7Eqxs/LXjQn9OOBbnPMhJlCZTKRi0Z78GtyjuY5ydLBU/GSwTJqHv/Di/ElGWdxLaFaDu7lRvtpYYQlAh2pt1k8kJ/wF9t6QdbKiP4AAAAABJRU5ErkJggg=="
    });
    // console.log('assets',this.assets);
  }

  selectAssets(assets) {
    this.selectedAsset = assets;
  }

  onSelected(value) {
    if (value == 'Auto') {
      // this.minFees = null;
      this.maxFees = null;
    }
  }

  selectAddress(policy: Policy) {
    this.selectedAddress = policy;
  }

  selectNextAddress(policy: Policy) {
    this.nextAddress = policy;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  // async checkRemoveExistRule(id,asset,type)
  // {
  //   const data = await this.httpService.getConsolidationRules().toPromise();
  //   let WalletList = data.data.filter(ele => Object.values(ele.walletSettings).length);
  //   WalletList = WalletList.filter(ele => ele.id == id && Object.keys(ele.walletSettings).includes(asset));
  //   console.log('WalletList',WalletList);
  //   if(WalletList.length)
  //   {
  //     let maxfees = WalletList[0].walletSettings[asset]['ConsolidateMaxFee'];
  //     let minfees = WalletList[0].walletSettings[asset]['ConsolidateMinFee'];
  //     console.log(maxfees);
  //     console.log(minfees);
  //     let feesType;
  //     if(maxfees && maxfees)
  //     {
  //       feesType = 'Custom';
  //     }else{
  //       feesType = 'Auto';
  //     }
  //     console.log(feesType);

  //     if(feesType != type)
  //     {
  //       const response = await this.httpService.removeConsolidationRule(id,asset).toPromise();
  //       console.log(response);
  //       console.log('remove api call');
  //     }
  //   }
  // }

  async addConsolidationRule(id, asset, minAmount, type, maxFees, address) {
    if (this.rule) {
      this.updateRule(id, asset, minAmount, type, maxFees, address);
    } else {
      this.addRule(id, asset, minAmount, type, maxFees, address);
    }
  }

  async updateRule(id, asset, minAmount, type, maxFees, address) {
    try {
      let bodyObj = {
        "max_fee": maxFees,
        "min_amount": minAmount,
        // @ts-ignore
        "currency": parseInt(this.currency)
      };
      let response = await this.httpService.updateConsolidationRule(this.rule.id, bodyObj).toPromise();

      const update0Threshold: any = {
        "destination_address": address
      }
      if (this.spillover && 'spillover' in this.rule) {
        update0Threshold.threshold = this.spillover;
      }
      await this.httpService.updateConsolidationThreshold(this.rule.id, this.rule.DestinationAddressID, update0Threshold).toPromise();

      await this.httpService.updateConsolidationThreshold(this.rule.id, this.rule.spillover.id, {
        "destination_address": this.nextAddress.condition,
      }).toPromise();
      if (this.spillover && !('spillover' in this.rule)) {
        await this.httpService.addConsolidationThreshold(id, this.rule.id, [{ "destination_address": address, "threshold": this.spillover }]).toPromise();
      }
      if (response.success === true) {
        this.segment.track("consolidation-rule-updated", {
          ruleId: this.rule.id
        }).catch((err) => {});
        this.modalService.dismissAll();
        this.modalService.open(this.successModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result: any) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason: any) => {
          this.resetSendModel();
        });
      } else {
        this.handleConsolidationRuleError("UPDATE", response?.message);
      }
    } catch (error) {
      const errorMessage = error?.error?.message || error?.message;
      this.handleConsolidationRuleError("UPDATE", errorMessage);
    }
  }

  async addRule(id, asset, minAmount, type, maxFees, address) {
    //  await this.checkRemoveExistRule(id,asset,type);
    try {
      let bodyObj: IConsolidationRule = {
        "rule": {
          "asset": asset == "All Assets" ? null : asset,
          "min_amount": minAmount,
          // @ts-ignore
          "currency": parseInt(this.currency),
          "threshold_rules": [
            { "destination_address": address }
          ]
        }
      }

      if (type == 'Custom') bodyObj.rule["max_fee"] = maxFees;
      // @ts-ignore
      if (this.spillover) {
        bodyObj.rule["threshold_rules"].push({ "destination_address": this.nextAddress.condition });
        bodyObj.rule["threshold_rules"][0].threshold = this.spillover;
      }

      this.isSigning = true;

      const response = await this.httpService.addConsolidationRule(id, bodyObj).toPromise();
      // console.log(response);
      if (response.success === true) {
        this.segment.track("consolidation-rule-added", {
          walletId: id,
          ruleDetails: bodyObj
        }).catch((err) => { });
        this.modalService.dismissAll();
        this.modalService.open(this.successModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result: any) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason: any) => {
          this.resetSendModel();
        });
        this.isSigning = false;
      } else {
        this.handleConsolidationRuleError("ADD", response?.message);
      }
    } catch (error) {
      const errorMessage = error?.error?.message || error?.message;
      this.handleConsolidationRuleError("ADD", errorMessage);
    }
  }

  omit_special_char(event) {
    var k;

    if (this.setAmount < 0 || this.maxFees < 0) {
      this.setAmount = null;
      // this.minFees = null;
      this.maxFees = null;
    }
    k = event.charCode;  //  k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 46);
  }

  handleConsolidationRuleError(type: 'ADD' | 'UPDATE', errorMessgage: string) {
    this.isSigning = false;
    this.modalService.dismissAll();
    ErrorPrompt.fire({
      icon: "error",
      title: type == 'ADD' ? 'Failed to Add Rule' : "Failed to update rule",
      text: errorMessgage || 'Sorry, Something went wrong',
      showConfirmButton: false,
    })
  }
}