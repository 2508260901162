"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PolkadotGenericAppLegacy = void 0;
const ledger_js_1 = require("@zondax/ledger-js");
const generic_app_1 = require("./generic_app");
/**
 * Class representing a legacy Polkadot generic application.
 * Implements the ISubstrateAppLegacy interface.
 * @deprecated Use PolkadotGenericApp from generic_app.ts instead.
 */
class PolkadotGenericAppLegacy {
  /**
   * Constructs a new PolkadotGenericAppLegacy instance.
   * @param transport - The transport instance.
   * @param ss58prefix - The SS58 prefix.
   * @param txMetadataChainId - The chain ID in the transaction metadata service.
   * @param txMetadataSrvUrl - The optional transaction metadata service URL.
   * @deprecated Use PolkadotGenericApp constructor from generic_app.ts instead.
   */
  constructor(transport, ss58prefix, txMetadataChainId, txMetadataSrvUrl) {
    this.genericApp = new generic_app_1.PolkadotGenericApp(transport, txMetadataChainId, txMetadataSrvUrl);
    this.ss58prefix = ss58prefix;
  }
  /**
   * Converts a ResponseError to a legacy ResponseBase format.
   * @param e - The ResponseError instance.
   * @returns The legacy ResponseBase object.
   * @deprecated
   */
  convertToLegacyError(e) {
    return {
      error_message: e.errorMessage,
      return_code: e.returnCode
    };
  }
  /**
   * Converts account, change, and addressIndex to a BIP32Path.
   * @param account - The account number.
   * @param change - The change number.
   * @param addressIndex - The address index.
   * @returns The BIP32Path.
   * @deprecated
   */
  convertLegacyPath(account, change, addressIndex) {
    return (0, ledger_js_1.numbersToBip32Path)([0x8000002c, 0x80000162, account, change, addressIndex]);
  }
  /**
   * Retrieves the version of the application.
   * @returns A promise that resolves to a ResponseVersion object.
   * @deprecated Use getVersion method from PolkadotGenericApp in generic_app.ts instead.
   */
  async getVersion() {
    try {
      const version = await this.genericApp.getVersion();
      const legacyError = this.convertToLegacyError(ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.NoErrors));
      return {
        ...legacyError,
        major: version.major ?? 0,
        minor: version.minor ?? 0,
        patch: version.patch ?? 0,
        device_locked: version.deviceLocked ?? false,
        test_mode: version.testMode ?? false
      };
    } catch (e) {
      const legacyError = this.convertToLegacyError(e);
      return {
        device_locked: false,
        major: 0,
        minor: 0,
        patch: 0,
        test_mode: false,
        ...legacyError
      };
    }
  }
  /**
   * Retrieves application information.
   * @returns A promise that resolves to an object containing application information.
   * @deprecated Use appInfo method from PolkadotGenericApp in generic_app.ts instead.
   */
  async appInfo() {
    try {
      const info = await this.genericApp.appInfo();
      const legacyError = this.convertToLegacyError(ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.NoErrors));
      return {
        ...legacyError,
        ...info
      };
    } catch (e) {
      const legacyError = this.convertToLegacyError(e);
      return {
        ...legacyError
      };
    }
  }
  /**
   * Retrieves the address for a given account, change, and address index.
   * @param account - The account number.
   * @param change - The change number.
   * @param addressIndex - The address index.
   * @param requireConfirmation - Whether to require confirmation on the device.
   * @param scheme - The cryptographic scheme.
   * @returns A promise that resolves to a ResponseAddress object.
   * @throws {ResponseError} If the scheme is not supported.
   * @deprecated Use getAddress method from PolkadotGenericApp in generic_app.ts instead.
   */
  async getAddress(account, change, addressIndex, requireConfirmation, scheme) {
    if (scheme !== 0 /* SCHEME.ED25519 */) {
      throw ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.AlgorithmNotSupported);
    }
    try {
      const bip44Path = this.convertLegacyPath(account, change, addressIndex);
      const address = await this.genericApp.getAddress(bip44Path, this.ss58prefix, requireConfirmation);
      const legacyError = this.convertToLegacyError(ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.NoErrors));
      return {
        ...legacyError,
        ...address
      };
    } catch (e) {
      const legacyError = this.convertToLegacyError(e);
      return {
        address: 'ERROR',
        pubKey: 'ERROR',
        ...legacyError
      };
    }
  }
  /**
   * Signs a transaction blob.
   * @param account - The account number.
   * @param change - The change number.
   * @param addressIndex - The address index.
   * @param message - The transaction blob.
   * @param scheme - The cryptographic scheme.
   * @returns A promise that resolves to a ResponseSign object.
   * @throws {ResponseError} If the scheme is not supported.
   * @deprecated Use sign method from PolkadotGenericApp in generic_app.ts instead.
   */
  async sign(account, change, addressIndex, message, scheme) {
    try {
      if (scheme !== 0 /* SCHEME.ED25519 */) {
        throw ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.AlgorithmNotSupported);
      }
      const bip44Path = this.convertLegacyPath(account, change, addressIndex);
      const signature = await this.genericApp.sign(bip44Path, message);
      const legacyError = this.convertToLegacyError(ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.NoErrors));
      return {
        ...legacyError,
        ...signature
      };
    } catch (e) {
      const legacyError = this.convertToLegacyError(e);
      return {
        signature: Buffer.alloc(0),
        ...legacyError
      };
    }
  }
  /**
   * Signs a raw transaction blob.
   * @param account - The account number.
   * @param change - The change number.
   * @param addressIndex - The address index.
   * @param message - The transaction blob.
   * @param scheme - The cryptographic scheme.
   * @returns A promise that resolves to a ResponseSign object.
   * @throws {ResponseError} If the scheme is not supported.
   * @deprecated Use signRaw method from PolkadotGenericApp in generic_app.ts instead.
   */
  async signRaw(account, change, addressIndex, message, scheme) {
    try {
      if (scheme !== 0 /* SCHEME.ED25519 */) {
        throw ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.AlgorithmNotSupported);
      }
      const bip44Path = this.convertLegacyPath(account, change, addressIndex);
      const signature = await this.genericApp.signRaw(bip44Path, message);
      const legacyError = this.convertToLegacyError(ledger_js_1.ResponseError.fromReturnCode(ledger_js_1.LedgerError.NoErrors));
      return {
        ...legacyError,
        ...signature
      };
    } catch (e) {
      const legacyError = this.convertToLegacyError(e);
      return {
        signature: Buffer.alloc(0),
        ...legacyError
      };
    }
  }
}
exports.PolkadotGenericAppLegacy = PolkadotGenericAppLegacy;
