"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponseError = void 0;
/******************************************************************************
 *  (c) 2018 - 2024 Zondax AG
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 *****************************************************************************/
const common_1 = require("./common");
/**
 * Class representing a response error.
 * Extends the built-in Error class to include additional properties.
 */
class ResponseError extends Error {
  /**
   * Creates an instance of ResponseError.
   * @param returnCode - The return code associated with the error.
   * @param errorMessage - The error message describing the error.
   */
  constructor(returnCode, errorMessage) {
    super(errorMessage);
    this.errorMessage = errorMessage;
    this.returnCode = returnCode;
    this.name = 'ResponseReturnCode';
  }
  /**
   * Creates a ResponseError instance from a return code.
   * @param returnCode - The return code to convert into a ResponseError.
   * @returns A new instance of ResponseError.
   */
  static fromReturnCode(returnCode) {
    return new ResponseError(returnCode, (0, common_1.errorCodeToString)(returnCode));
  }
}
exports.ResponseError = ResponseError;
