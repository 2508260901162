import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsHttpService {
  baseUrl = environment.apiUrl

  constructor( private httpClient:HttpClient) { }

  /**
   * Retrieves the supported coins.
   * @returns An Observable that emits a string representing the supported coins.
   * @throws Throws an error if the coins are not found.
   */
  getSupportedCoins(): Observable<string> {
    return this.httpClient.get(this.baseUrl + "/blocks/rescan/get").pipe(
      map((data: string) => {
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Coin not found!");
      })
    );
  }

/**
 * Retrieves the details of a specific coin.
 * @param coin - The name of the coin.
 * @returns An Observable that emits the details of the coin as a string.
 * @throws If the coin is not found, an error message is thrown.
 */
getCoinDetails(coin: string): Observable<string> {
  const url = `${this.baseUrl}/blocks/rescan/get/${coin}`;
  return this.httpClient.get(url).pipe(
    map((data: string) => {
      return data;
    }),
    catchError((error) => {
      console.log(error);
      return throwError("Coin not found!");
    })
  );
}
/**
 * Sends a rescan request.
 * @param data - The data to be sent with the request.
 * @returns An Observable that emits a string response.
 */
sendRescanRequest(data: any): Observable<string> {
  const url = `${this.baseUrl}/blocks/rescan/create`;
  return this.httpClient.post(url, data).pipe(
    map((data: string) => {
      return data;
    }),
    catchError((error) => {
       throw error;
    })
  );
}

}
