import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { HttpService } from '../../services/http.service';
import { DataService } from '../../services/data.service';
import { SegmentService } from 'ngx-segment-analytics';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
@Component({
  selector: 'app-add-view-policy-manager',
  templateUrl: './add-view-policy-manager.component.html',
  styleUrls: ['./add-view-policy-manager.component.scss']
})
export class AddViewPolicyManagerComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {};
  selectedItems: any = [];
  step:string;
  policyApprovals:any;
  focusTotInit = false;
  minimum_approvals_selected:number = 1;
  configPolicyApprovals:any;
  configPolicy:any;
  orgId:any;
  profileData:any;
  @Input() component_for: string;

  constructor(
    private segment: SegmentService,
    private modalService: NgbModal,
    private httpService: HttpService,
    private data: DataService,
  ) 
  { 
    this.init();
  }

  async init()
  {
    this.profileData = await this.data.getUserProfile.getValue();
    this.profileData.isOwner = this.profileData?.organizations[0]?.isowner === 1 ? true : false;
    this.orgId = this.profileData.organizations[0].id;
    
    this.policyApprovals = await this.httpService.getOrgMembers(this.orgId);
    this.policyApprovals = this.policyApprovals.members.filter(ele => ele.type == 2);
    this.policyApprovals = this.policyApprovals.sort((a, b) => a.displayName.localeCompare(b.displayName));
    this.getPolicyConfig();
  }

  ngOnInit(): void {}
  open(content: any) {
    if(this.component_for == 'view-policy-manager' || this.component_for == 'edit-policy-manager'){
      this.step = 'view-policy';
    }
    this.modalService.open(content, { windowClass: 'new-group-wallet modal-custom-background', centered: true });
  }

setPolicyConfig(data)
{
  this.configPolicy = data;
  console.info('config',this.configPolicy);
  
}
async getPolicyConfig()
{
  this.configPolicyApprovals = await this.httpService.getConfigPolicyApprovals().toPromise();
  this.configPolicy = this.configPolicyApprovals.data.config;
  let config = []
  config = this.configPolicy?.split("of");
  this.configPolicy = (config.length)?config[0]:"";
  console.log('getPolicyConfig',this.configPolicy);
}
async setConfig()
{
  let bodyObj;
    try {
      bodyObj = {
        "config": this.configPolicy+'of'+this.policyApprovals.length
      }
      console.info('bodyObj',bodyObj);
      const response = await this.httpService.setConfigPolicyApprovals(bodyObj).toPromise();
      console.log('response',response);
      if (response.success === true) {
        this.segment.track("minimum-approvals-modified", bodyObj).catch((err)=>{});
        this.modalService.dismissAll();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Awesome! You have successfully updated minimum approvals 🎉",
          showConfirmButton: false,
        })
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to updated minimum approvals",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to updated minimum approvals",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
}
}
