"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newKusamaApp = newKusamaApp;
exports.newPolkadotApp = newPolkadotApp;
exports.newPolymeshApp = newPolymeshApp;
exports.newDockApp = newDockApp;
exports.newCentrifugeApp = newCentrifugeApp;
exports.newEdgewareApp = newEdgewareApp;
exports.newEquilibriumApp = newEquilibriumApp;
exports.newGenshiroApp = newGenshiroApp;
exports.newStatemintApp = newStatemintApp;
exports.newStatemineApp = newStatemineApp;
exports.newNodleApp = newNodleApp;
exports.newSoraApp = newSoraApp;
exports.newPolkadexApp = newPolkadexApp;
exports.newBifrostApp = newBifrostApp;
exports.newKaruraApp = newKaruraApp;
exports.newReefApp = newReefApp;
exports.newAcalaApp = newAcalaApp;
exports.newXXNetworkApp = newXXNetworkApp;
exports.newParallelApp = newParallelApp;
exports.newAstarApp = newAstarApp;
exports.newComposableApp = newComposableApp;
exports.newStafiApp = newStafiApp;
exports.newAlephZeroApp = newAlephZeroApp;
exports.newInterlayApp = newInterlayApp;
exports.newUniqueApp = newUniqueApp;
exports.newBifrostKusamaApp = newBifrostKusamaApp;
const supported_apps_1 = require("./supported_apps");
// Legacy code
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newKusamaApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Kusama');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newPolkadotApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Polkadot');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newPolymeshApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Polymesh');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newDockApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Dock');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newCentrifugeApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Centrifuge');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newEdgewareApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Edgeware');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newEquilibriumApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Equilibrium');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newGenshiroApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Genshiro');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newStatemintApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Statemint');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newStatemineApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Statemine');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newNodleApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Nodle');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newSoraApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Sora');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newPolkadexApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Polkadex');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newBifrostApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Bifrost');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newKaruraApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Karura');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newReefApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Reef');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newAcalaApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Acala');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newXXNetworkApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'XXNetwork');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newParallelApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Parallel');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newAstarApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Astar');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newComposableApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Composable');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newStafiApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Stafi');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newAlephZeroApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'AlephZero');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newInterlayApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Interlay');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newUniqueApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'Unique');
}
/**
 * @deprecated Chains will progressively migrate to the generic app
 */
function newBifrostKusamaApp(transport) {
  return (0, supported_apps_1.newSubstrateApp)(transport, 'BifrostKusama');
}
