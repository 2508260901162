/**
 * Represents the keys for the available scopes.
 */
type ScopeKeys =
    | "PoliciesAdd"
    | "PoliciesApprove"
    | "PoliciesRemove"
    | "WalletTxCreate"
    | "TeamTxApprove"
    | "StakingEnable"
    | "WalletMessageCreate"
    | "TeamMessageApprove"
    | "GasStationTransactionApprove"
    | "StakingUnstakeEth"
    | "FirewallQuorum"
    | "UserRoleUpdate"
    | "WalletCreate"
    | "ProtocolEnable"
    | "UserDeactivate"
    | "ApiKeyEnableDisable"
    | "ApiKeyCreate"
    | "ApiKeyIpEnableDisable"
    | "TeamUpdate";

/**
 * Represents the values for the available scopes.
 */
type Scopes =
    | "policies:approve"
    | "policies:add"
    | "policies:remove"
    | "wallet:tx-create"
    | "team:tx-approve"
    | "staking:enable"
    | "wallet:message-create"
    | "team:message-approve"
    | "gasstation:tx-create"
    | "staking:unstake-eth"
    | "firewall:quorum"
    | "user:role-update"
    | "wallet:create"
    | "protocol:enable"
    | "user:deactivate"
    | "api:key-enable-disable"
    | "api:key-create"
    | "api:ip-enable-disable"
    | "team:update";

/**
 * Represents the mapping between scope keys and scope values.
 */
export const scopes: Record<ScopeKeys, Scopes> = {
    /**
     * Represents the "PoliciesAdd" scope key mapped to the "policies:add" scope value.
     */
    "PoliciesAdd": "policies:add",
    /**
     * Represents the "PoliciesApprove" scope key mapped to the "policies:approve" scope value.
     */
    "PoliciesApprove": "policies:approve",
    /**
     * Represents the "PoliciesRemove" scope key mapped to the "policies:remove" scope value.
     */
    "PoliciesRemove": "policies:remove",
    /**
     * Represents the "WalletTxCreate" scope key mapped to the "wallet:tx-create" scope value.
     */
    "WalletTxCreate": "wallet:tx-create",
    /**
     * Represents the "TeamTxApprove" scope key mapped to the "team:tx-approve" scope value.
     */
    "TeamTxApprove": "team:tx-approve",
    /**
     * Represents the "StakingEnable" scope key mapped to the "staking:enable" scope value.
     */
    "StakingEnable": "staking:enable",
    /**
     * Represents the "WalletMessageCreate" scope key mapped to the "wallet:message-create" scope value.
     */
    "WalletMessageCreate": "wallet:message-create",
    /**
     * Represents the "TeamMessageApprove" scope key mapped to the "team:message-approve" scope value.
     */
    "TeamMessageApprove": "team:message-approve",
    /**
     * Represents the "GasStationTransactionApprove" scope key mapped to the "gasstation:tx-create" scope value.
     */
    "GasStationTransactionApprove": "gasstation:tx-create",
    /**
     * Represents the "StakingUnstakeEth" scope key mapped to the "staking:unstake-eth" scope value.
     */
    "StakingUnstakeEth": "staking:unstake-eth",
    /**
     * Represents the "FirewallQuorum" scope key mapped to the "firewall:quorum" scope value.
     */
    "FirewallQuorum": "firewall:quorum",
    /**
     * Represents the "UserRoleUpdate" scope key mapped to the "user:role-update" scope value.
     */
    "UserRoleUpdate": "user:role-update",
    /**
     * Represents the "WalletCreate" scope key mapped to the "wallet:create" scope value.
     */
    "WalletCreate": "wallet:create",
    /**
     * Represents the "ProtocolEnable" scope key mapped to the "protocol:enable" scope value.
     */
    "ProtocolEnable": "protocol:enable",
    /**
     * Represents the "UserDeactivate" scope key mapped to the "user:deactivate" scope value.
     */
    "UserDeactivate": "user:deactivate",
    /**
     * Represents the "ApiKeyEnableDisable" scope key mapped to the "api:key_enable_disable" scope value.
     */
    "ApiKeyEnableDisable": "api:key-enable-disable",
    /**
     * Represents the "ApiKeyCreate" scope key mapped to the "api:key-create" scope value.
     */
    "ApiKeyCreate": "api:key-create",
    /**
     * Represents the "ApiKeyIpEnableDisable" scope key mapped to the "api:ip_enable_disable" scope value.
     */
    "ApiKeyIpEnableDisable": "api:ip-enable-disable",
    /**
     * Represents the "TeamUpdate" scope key mapped to the "team:update" scope value.
     */
    "TeamUpdate": "team:update"
};