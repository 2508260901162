import { crypto } from "bitcoinjs-lib";
import { pointAddScalar } from "tiny-secp256k1";
import { BufferWriter } from "../buffertools";
import { HASH_SIZE, OP_CHECKSIG, OP_DUP, OP_EQUAL, OP_EQUALVERIFY, OP_HASH160 } from "../constants";
import { hashPublicKey } from "../hashPublicKey";
class BaseAccount {
  constructor(psbt, masterFp) {
    this.psbt = psbt;
    this.masterFp = masterFp;
  }
}
/**
 * Superclass for single signature accounts. This will make sure that the pubkey
 * arrays and path arrays in the method arguments contains exactly one element
 * and calls an abstract method to do the actual work.
 */
class SingleKeyAccount extends BaseAccount {
  spendingCondition(pubkeys) {
    if (pubkeys.length != 1) {
      throw new Error("Expected single key, got " + pubkeys.length);
    }
    return this.singleKeyCondition(pubkeys[0]);
  }
  setInput(i, inputTx, spentOutput, pubkeys, pathElems) {
    if (pubkeys.length != 1) {
      throw new Error("Expected single key, got " + pubkeys.length);
    }
    if (pathElems.length != 1) {
      throw new Error("Expected single path, got " + pathElems.length);
    }
    this.setSingleKeyInput(i, inputTx, spentOutput, pubkeys[0], pathElems[0]);
  }
  setOwnOutput(i, cond, pubkeys, paths) {
    if (pubkeys.length != 1) {
      throw new Error("Expected single key, got " + pubkeys.length);
    }
    if (paths.length != 1) {
      throw new Error("Expected single path, got " + paths.length);
    }
    this.setSingleKeyOutput(i, cond, pubkeys[0], paths[0]);
  }
}
export class p2pkh extends SingleKeyAccount {
  singleKeyCondition(pubkey) {
    const buf = new BufferWriter();
    const pubkeyHash = hashPublicKey(pubkey);
    buf.writeSlice(Buffer.from([OP_DUP, OP_HASH160, HASH_SIZE]));
    buf.writeSlice(pubkeyHash);
    buf.writeSlice(Buffer.from([OP_EQUALVERIFY, OP_CHECKSIG]));
    return {
      scriptPubKey: buf.buffer()
    };
  }
  setSingleKeyInput(i, inputTx, _spentOutput, pubkey, path) {
    if (!inputTx) {
      throw new Error("Full input base transaction required");
    }
    this.psbt.setInputNonWitnessUtxo(i, inputTx);
    this.psbt.setInputBip32Derivation(i, pubkey, this.masterFp, path);
  }
  setSingleKeyOutput(i, cond, pubkey, path) {
    this.psbt.setOutputBip32Derivation(i, pubkey, this.masterFp, path);
  }
  getDescriptorTemplate() {
    return "pkh(@0)";
  }
}
export class p2tr extends SingleKeyAccount {
  singleKeyCondition(pubkey) {
    const xonlyPubkey = pubkey.slice(1); // x-only pubkey
    const buf = new BufferWriter();
    const outputKey = this.getTaprootOutputKey(xonlyPubkey);
    buf.writeSlice(Buffer.from([0x51, 32])); // push1, pubkeylen
    buf.writeSlice(outputKey);
    return {
      scriptPubKey: buf.buffer()
    };
  }
  setSingleKeyInput(i, _inputTx, spentOutput, pubkey, path) {
    const xonly = pubkey.slice(1);
    this.psbt.setInputTapBip32Derivation(i, xonly, [], this.masterFp, path);
    this.psbt.setInputWitnessUtxo(i, spentOutput.amount, spentOutput.cond.scriptPubKey);
  }
  setSingleKeyOutput(i, cond, pubkey, path) {
    const xonly = pubkey.slice(1);
    this.psbt.setOutputTapBip32Derivation(i, xonly, [], this.masterFp, path);
  }
  getDescriptorTemplate() {
    return "tr(@0)";
  }
  /*
  The following two functions are copied from wallet-btc and adapted.
  They should be moved to a library to avoid code reuse.
  */
  hashTapTweak(x) {
    // hash_tag(x) = SHA256(SHA256(tag) || SHA256(tag) || x), see BIP340
    // See https://github.com/bitcoin/bips/blob/master/bip-0340.mediawiki#specification
    const h = crypto.sha256(Buffer.from("TapTweak", "utf-8"));
    return crypto.sha256(Buffer.concat([h, h, x]));
  }
  /**
   * Calculates a taproot output key from an internal key. This output key will be
   * used as witness program in a taproot output. The internal key is tweaked
   * according to recommendation in BIP341:
   * https://github.com/bitcoin/bips/blob/master/bip-0341.mediawiki#cite_ref-22-0
   *
   * @param internalPubkey A 32 byte x-only taproot internal key
   * @returns The output key
   */
  getTaprootOutputKey(internalPubkey) {
    if (internalPubkey.length != 32) {
      throw new Error("Expected 32 byte pubkey. Got " + internalPubkey.length);
    }
    // A BIP32 derived key can be converted to a schnorr pubkey by dropping
    // the first byte, which represent the oddness/evenness. In schnorr all
    // pubkeys are even.
    // https://github.com/bitcoin/bips/blob/master/bip-0340.mediawiki#public-key-conversion
    const evenEcdsaPubkey = Buffer.concat([Buffer.from([0x02]), internalPubkey]);
    const tweak = this.hashTapTweak(internalPubkey);
    // Q = P + int(hash_TapTweak(bytes(P)))G
    const outputEcdsaKey = Buffer.from(pointAddScalar(evenEcdsaPubkey, tweak));
    // Convert to schnorr.
    const outputSchnorrKey = outputEcdsaKey.slice(1);
    // Create address
    return outputSchnorrKey;
  }
}
export class p2wpkhWrapped extends SingleKeyAccount {
  singleKeyCondition(pubkey) {
    const buf = new BufferWriter();
    const redeemScript = this.createRedeemScript(pubkey);
    const scriptHash = hashPublicKey(redeemScript);
    buf.writeSlice(Buffer.from([OP_HASH160, HASH_SIZE]));
    buf.writeSlice(scriptHash);
    buf.writeUInt8(OP_EQUAL);
    return {
      scriptPubKey: buf.buffer(),
      redeemScript: redeemScript
    };
  }
  setSingleKeyInput(i, inputTx, spentOutput, pubkey, path) {
    if (!inputTx) {
      throw new Error("Full input base transaction required");
    }
    this.psbt.setInputNonWitnessUtxo(i, inputTx);
    this.psbt.setInputBip32Derivation(i, pubkey, this.masterFp, path);
    const userSuppliedRedeemScript = spentOutput.cond.redeemScript;
    const expectedRedeemScript = this.createRedeemScript(pubkey);
    if (userSuppliedRedeemScript && !expectedRedeemScript.equals(userSuppliedRedeemScript)) {
      // At what point might a user set the redeemScript on its own?
      throw new Error(`User-supplied redeemScript ${userSuppliedRedeemScript.toString("hex")} doesn't
       match expected ${expectedRedeemScript.toString("hex")} for input ${i}`);
    }
    this.psbt.setInputRedeemScript(i, expectedRedeemScript);
    this.psbt.setInputWitnessUtxo(i, spentOutput.amount, spentOutput.cond.scriptPubKey);
  }
  setSingleKeyOutput(i, cond, pubkey, path) {
    this.psbt.setOutputRedeemScript(i, cond.redeemScript);
    this.psbt.setOutputBip32Derivation(i, pubkey, this.masterFp, path);
  }
  getDescriptorTemplate() {
    return "sh(wpkh(@0))";
  }
  createRedeemScript(pubkey) {
    const pubkeyHash = hashPublicKey(pubkey);
    return Buffer.concat([Buffer.from("0014", "hex"), pubkeyHash]);
  }
}
export class p2wpkh extends SingleKeyAccount {
  singleKeyCondition(pubkey) {
    const buf = new BufferWriter();
    const pubkeyHash = hashPublicKey(pubkey);
    buf.writeSlice(Buffer.from([0, HASH_SIZE]));
    buf.writeSlice(pubkeyHash);
    return {
      scriptPubKey: buf.buffer()
    };
  }
  setSingleKeyInput(i, inputTx, spentOutput, pubkey, path) {
    if (!inputTx) {
      throw new Error("Full input base transaction required");
    }
    this.psbt.setInputNonWitnessUtxo(i, inputTx);
    this.psbt.setInputBip32Derivation(i, pubkey, this.masterFp, path);
    this.psbt.setInputWitnessUtxo(i, spentOutput.amount, spentOutput.cond.scriptPubKey);
  }
  setSingleKeyOutput(i, cond, pubkey, path) {
    this.psbt.setOutputBip32Derivation(i, pubkey, this.masterFp, path);
  }
  getDescriptorTemplate() {
    return "wpkh(@0)";
  }
}
