import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
    name: 'negtopos'
})
export class NegtoposService implements PipeTransform {
    transform(val: string): number {
      if(val != undefined)
      {
        val.toString().replace(/,/g, "");
        return Math.abs(Number(val));
      }else{
        return 0;
      }
    }
}

