<div class="content-container container" [ngClass]="{'border-top': addSeparator, 'pt-2': addSeparator}">
    <div class="row" [ngClass]="classnames">
      <div  *ngFor="let item of content | slice:startIdx:endIdx" class="my-2 col">
        <span class="font-weight-500 font-size-14 content-title">{{item.key | contentTitle}}</span>
        <span class="d-flex flex-column" *ngFor="let valueObj of item.values">
          <!-- Not provider  -->
          <span *ngIf="valueObj.tooltip && item.key !== COMPLIANCE_PROVIDER && item.key !== TRANSACTION_HASH" class="text-muted mt-1" [ngbTooltip]="valueObj.tooltip" placement="top">{{valueObj.value}} 
            <i class="icon-copy small text-dark cursor-pointer" ngxClipboard [cbContent]="valueObj.tooltip" placement="top" triggers="click:blur" ngbTooltip="Copied"></i> 
          </span>
          <span *ngIf="!valueObj.tooltip && item.key !== COMPLIANCE_PROVIDER && item.key !== TRANSACTION_HASH" class="text-muted mt-1">{{ valueObj.value}}</span>
  
          <!-- Provider (Has URL) -->
          <span *ngIf="item.key === COMPLIANCE_PROVIDER || item.key === TRANSACTION_HASH" class="d-flex flex-row align-items-center">
            <span class="text-muted mt-1 mr-2">{{extractTextBeforeParentheses(valueObj.value)}}</span>
            <a [href]="extractURL(valueObj.value)" target="_blank" rel="noopener noreferrer" class="mt-2">
              <i [ngbTooltip]="getProviderToolTip(valueObj.value)" class="icon-open-link"></i>
            </a>
          </span>
        </span>
      </div>
    </div>
  </div>