import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tokenType } from 'src/app/shared/entities/TransactionRequest';
import { getName } from 'src/app/shared/helpers/coins';
import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-nft-wallet',
  templateUrl: './nft-wallet.component.html',
  styleUrls: ['./nft-wallet.component.scss']
})
export class NftWalletComponent implements OnInit {
  pageTitle = `${environment.buildName} Vaults - Wallets`;
  nftWallet: any = [];

  private onDestroy$: Subject<void> = new Subject<void>();
  constructor(private data: DataService,private titleService:Title) {
    this.titleService.setTitle(`${environment.buildName} Vaults - NFT`);

    // this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((wallets) => {
    //   if (!wallets) return;
    //   // this.wallets = [...new Map(wallets.map(item => [item.id, item])).values()];
    //   this.nftWallet = wallets
    //   // this.nftWallet = this.nftWallet.filter(obj => obj.type.toLowerCase() == 'gnosis' && obj.chain.toLowerCase() == 'eth' && obj.tokenType == 2);
    //   console.log('dhruv',this.nftWallet);
      
      
      
    // })
    const distinct = (value, index, self) => {
      // console.log(value);
      // console.log(index);
      return self.indexOf(value) === index
    }

    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallets => {
      if (wallets == null) return;
      this.nftWallet = wallets;
      this.nftWallet = this.nftWallet.sort((a, b) => a.name.localeCompare(b.name));
      this.nftWallet = this.nftWallet.filter(obj => obj.type.toLowerCase() == 'gnosis' && obj.tokenType == tokenType.nftToken && (obj.chain.toLowerCase() == 'eth' || obj.chain.toLowerCase() == 'polygon'));
      // this.nftWallet = this.nftWallet.filter(ele => ele.id == '132' || ele.id == '133');
      // console.log('listtop',this.nftWallet);
      
      const ids = this.nftWallet.map(item => item.id);

      const uniqIds = ids.filter(distinct);

      this.nftWallet = uniqIds.map(id => {
        const arr = this.nftWallet.filter(item => item.id === id);
        // console.log('arr',arr);

        var name: string;
        var chain: string;
        var parentchain: string;
        var walletKeys: any;
        var address: any;
        var nft_val = [];
        var coinfullname: string;

        arr.forEach((item: any) => {
          coinfullname = getName(item.chain);
          nft_val.push(item);
          chain = item.chain;
          name = item.name;
          parentchain = item.parentchain;
          walletKeys = item.walletKeys;
          address = item.address;
        });

        return { name: name, id: id, chain: chain, parentchain: parentchain, walletKeys: walletKeys, address: address, nft: nft_val, coinfullname:coinfullname }
      });
      console.log('list',this.nftWallet);
    });
   }

  ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
