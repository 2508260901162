export function sortWallets(wallets) {
  wallets.sort((a, b) => {
    if (
      a.scheduledBatch.currentbalance <= a.scheduledBatch.balancetrigger &&
      b.scheduledBatch.currentbalance >= b.scheduledBatch.balancetrigger
    ) {
      return -1;
    } else if (
      a.scheduledBatch.currentbalance >= a.scheduledBatch.balancetrigger &&
      b.scheduledBatch.currentbalance <= b.scheduledBatch.balancetrigger
    ) {
      return 1;
    } else if (
      a.scheduledBatch.currentbalance <= a.scheduledBatch.balancetrigger &&
      b.scheduledBatch.currentbalance <= b.scheduledBatch.balancetrigger
    ) {
      return a.scheduledBatch.currentbalance - b.scheduledBatch.currentbalance;
    } else {
      return a.scheduledBatch.remainingTxs - b.scheduledBatch.remainingTxs;
    }
  });
}
