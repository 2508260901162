<div class="card card-margin" *ngIf="loading">
    <div class="row w-100 p-4">
        <div class="col-2">
            <ngx-skeleton-loader></ngx-skeleton-loader>
        </div>

        <div class="ml-auto col-3 row">
            <div class="col-4">
                <ngx-skeleton-loader></ngx-skeleton-loader>
            </div>
            <div class="col-4">
                <ngx-skeleton-loader></ngx-skeleton-loader>
            </div>
            <div class="col-4">
                <ngx-skeleton-loader></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="table-responsive table-new">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" style="width: 160px;">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                    <th scope="col">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of [].constructor(10); let i = index">
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="center card card-margin"
    [ngClass]="{'card-shadow-border':pendingtransactions_component_for === 'pentrs_wallet_details'}"
    *ngIf="(policiesPendingTx?.length || pendingtxs?.length || teamWalletPendingTx?.length || messageSignPendingTx?.length || walletReq?.length || pendingTravelRules?.length) && !loading">
    <div class="d-flex justify-content-between p-4">
        <div class="d-flex justify-content-between align-items-center"
            [ngClass]="{'w-100':pendingtx_component_for === 'dashboared-pending'}">
            <h2 class="mr-2 mb-0 font-weight-500">Pending Actions</h2>
            <!-- Dashboard Page Button -->
            <a href="#" class="text-primary font-size-16 hover-primary" routerLink="/pending"
                *ngIf="pendingtx_component_for === 'dashboared-pending'">View all</a>
        </div>

        <!-- Pending Page -->
        <div class="d-flex align-items-center" *ngIf="pendingtx_component_for != 'dashboared-pending'">
            <!-- Search Filter -->
            <div class="mr-3">
                <div class="search-round">
                    <i class="icon-search" aria-hidden="true"></i>
                    <input class="form-control" [(ngModel)]="pendingSearch" placeholder="Search" type="text" />
                </div>
            </div>

            <!-- Only show in pending page -->
            <ul class="d-flex flex-wrap"
                *ngIf="(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq) | actionsFilter:'All' : meFilter).length && pendingtransactions_component_for != 'pentrs_wallet_details'">
                <!-- Pending Filter (All/Me) -->
                <li ngbDropdown>
                    <span class="d-flex align-items-center custom-dropdown tag cursor-pointer mr-2"
                        [ngClass]="{ 'active': filterActionsType === 'all' || filterActionsType === 'me'}"
                        id="dropdownAll" ngbDropdownToggle>
                        <ng-container *ngIf="!meFilter">All Pending <b class="ml-2">{{
                                (concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                                |
                                actionsFilter:'All' : meFilter).length}}</b></ng-container>
                        <ng-container *ngIf="meFilter">My Pending <b
                                class="ml-2">{{(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                                |
                                actionsFilter:'me' : meFilter).length}}</b></ng-container>
                        <span class="d-flex ml-2">
                            <svg fill="#6F757A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                style="width: 10px;">
                                <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z">
                                </path>
                            </svg>
                        </span>
                    </span>
                    <div ngbDropdownMenu aria-labelledby="dropdownAll" class="p-0 custom-dropdown-menu">
                        <button class="d-flex justify-content-between border-0 rounded-0 px-3 py-3" ngbDropdownItem
                            (click)="actionsFilter('all')">
                            <span>All Pending <b class="ml-2">{{
                                    (concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                                    |
                                    actionsFilter:'All' : meFilter).length}}</b></span>
                        </button>
                        <button class="d-flex justify-content-between border-0 rounded-0 px-3 py-3" ngbDropdownItem
                            (click)="actionsFilter('me')">
                            <span>My Pending <b
                                    class="ml-2">{{(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                                    | actionsFilter:'me' : meFilter).length}}</b></span>
                        </button>
                    </div>
                </li>

                <!-- Pending Filter (Type) -->
                <li ngbDropdown>
                    <span class="d-flex align-items-center custom-dropdown tag cursor-pointer"
                        [ngClass]="{'active':['transactions','mobile','policies','staking','nft','travel-rule','wallet request'].includes(filterActionsType)}"
                        id="dropdownTx" ngbDropdownToggle>
                        <ng-container
                            *ngIf="!['transactions','mobile','policies','staking','nft', 'travel rule','wallet request'].includes(filterActionsType)">Select
                            Type</ng-container>
                        <ng-container
                            *ngIf="['transactions','mobile','policies','staking','nft', 'travel rule','wallet request'].includes(filterActionsType)">{{filterActionsType}}
                            <b class="ml-2">{{(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                                |
                                actionsFilter:filterActionsType : meFilter).length}}</b></ng-container>
                        <span class="d-flex ml-2">
                            <svg fill="#6F757A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                                style="width: 10px;">
                                <path
                                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z">
                                </path>
                            </svg>
                        </span>
                    </span>
                    <div ngbDropdownMenu aria-labelledby="dropdownTx" class="p-0 custom-dropdown-menu">
                        <button *ngFor="let filter of filterType"
                            class="d-flex justify-content-between border-0 rounded-0 px-3 py-3" ngbDropdownItem
                            (click)="actionsFilter(filter)">
                            <span>{{filter}} <b
                                    class="ml-2">{{(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                                    | actionsFilter:filter : meFilter).length}}</b></span>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- Pending Actions Table -->
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive table-new">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left pl-4" style="width: 8%;">Type</th>
                            <th scope="col" class="text-left" style="width: 16%;min-width: 180px;">Asset</th>
                            <th scope="col" class="text-left" style="width: 12%;min-width: 130px;">Value</th>
                            <th scope="col" class="text-left" style="min-width: 130px;" appHideIfCustodyOrg>Wallet Type</th>
                            <th scope="col" class="text-left" style="width: 12%;">Details</th>
                            <th scope="col" class="text-left" style="width: 9%;">Status</th>
                            <th scope="col" class="text-left" style="width: 12%;">Members</th>
                            <th scope="col" class="text-left" style="width: 7%;">Notes</th>
                            <th scope="col" class="text-left time-header-two-lines" style="width: 7%;">Time</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody
                        *ngFor="let txdata of concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq) | actionsFilter:filterActionsType : meFilter | pendingTxSearch:pendingSearch | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                        <ng-container>
                            <!-- TYPE: MULTISIG -->
                            <ng-container *ngIf="txdata?.walletinfor && txdata?._txType == 'multisig_tx'">
                                <tr multisig-wallet-table-data [txdata]="txdata" [orgProfile]="orgProfile"
                                    [pendingtransactions_component_for]="pendingtransactions_component_for"
                                    (onRejectTxn)="open(canceltrsancation);gettransaction(txdata)"
                                    [ngClass]="{'open-state-tr' :  txdata?.tx_state == 'open'}"
                                    [uniqId]="i">
                                </tr>
                            </ng-container>

                            <!-- TYPE: MPC -->
                            <ng-container
                                *ngIf="(txdata.msgToSign && !txdata?.source && !isLivenessType(txdata) && txdata?._mpcTxType) || txdata?.wallet?.subtype ===  walletSubType.deposit">
                                <tr mpc-wallet-table-data [txdata]="txdata" [orgProfile]="orgProfile"
                                    [pendingtransactions_component_for]="pendingtransactions_component_for"
                                    [isLivenessTransactionExists]="isLivenessTransactionExists(txdata)"
                                    (onRejectTxn)="open(canceltrsancation);getMpcTransaction(txdata)"
                                    [ngClass]="{'open-state-tr' :  txdata?.tx_state == 'open'}"
                                    [uniqId]="i">
                                </tr>
                            </ng-container>

                            <tr>
                                <!-- TYPE: POLICY -->
                                <ng-container *ngIf="txdata?.sub_type">
                                    <!-- Column: Type -->
                                    <td class="pl-4">Policy</td>
                                    <!-- Column: Asset -->
                                    <td [ngClass]="{'pl-4':pendingtransactions_component_for === 'pentrs_wallet_details'}">
                                        <div class="d-flex">
                                            <img class="align-self-center mr-1 img-fluid img-round-border mr-2" [src]="txdata?.wallet?.coin | getIcon">
                                            <div class="d-flex flex-column">
                                                <span>{{txdata?.wallet?.coin | getName }}</span>
                                                <span class="text-dark mr-6">{{ txdata.wallet?.name }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Column: Value -->
                                    <td>
                                        <div class="d-flex flex-column"
                                            *ngIf="txdata.sub_type.toLowerCase() == 'address' || txdata.sub_type.toLowerCase() == 'remove_address' || txdata.sub_type.toLowerCase() == 'stakingenabled'">
                                            <span class="d-block">{{txdata.name}}</span>
                                            <app-copy-address [address]="txdata.condition"></app-copy-address>
                                            <span *ngIf="txdata.memoData?.value">Memo/Tag - {{txdata.memoData.value}}</span>
                                        </div>
                                        <div class="d-flex flex-column"
                                            *ngIf="txdata.sub_type == 'velocity' || txdata.sub_type.toLowerCase() == 'remove_velocity'">
                                            <span class="d-block">In {{txdata.condition}}</span>
                                            <span class="d-block">${{txdata.amount | number : '1.2-2'}}</span>
                                        </div>
                                        <div class="d-flex flex-column"
                                            *ngIf="txdata.sub_type == 'threshold'|| txdata.sub_type.toLowerCase() == 'remove_threshold'">
                                            <span class="d-block">${{txdata.amount | number : '1.2-2'}}</span>
                                        </div>
                                        <div class="d-flex flex-column"
                                            *ngIf="txdata.sub_type == 'enable_app'|| txdata.sub_type.toLowerCase() == 'enable_app'">
                                            <span class="d-block">{{txdata.name}}</span>
                                        </div>
                                    </td>
                                    <!-- Column: WalletType -->
                                    <td appHideIfCustodyOrg>
                                        {{ getWalletType(txdata?.walletinfor?.type, txdata?.walletinfor?.subtype) }}
                                    </td>
                                    <!-- Column: Details -->
                                    <td>
                                        <div [ngSwitch]="txdata.sub_type.toLowerCase()">  
                                            <span *ngSwitchCase="'address'">Add Whitelist Address</span>  
                                            <span *ngSwitchCase="'remove_address'">Remove Whitelist Address</span>  
                                            <span *ngSwitchCase="'velocity'">Update Spending Limit</span>
                                            <span *ngSwitchCase="'remove_velocity'">Remove Spending Limit</span>
                                            <span *ngSwitchCase="'threshold'">Update Transaction Limit</span>
                                            <span *ngSwitchCase="'remove_threshold'">Remove Transaction Limit</span>
                                            <span *ngSwitchCase="'enable_app'">Enable Apps</span>
                                            <span *ngSwitchCase="'stakingenabled'">Enable Staking</span>
                                        
                                        </div>
                                    </td>
                                    <!-- Column: Status -->
                                    <td class="body-font">
                                        <div class="status word-breakdiv">
                                            <div *ngIf="txdata.status === 2">
                                                <span class="">Pending</span>
                                                <ngb-progressbar class="my-1 int-sign-progress" [value]="txdata._signersSigns" [max]="txdata._signers"
                                                    height="5px"></ngb-progressbar>
                                                <span class="d-flex"><b class="font-size-12">{{txdata._signers - txdata._signersSigns}}</b>
                                                    <span class="font-size-12 ml-1 text-dark">Remaining</span></span>
                                            </div>
                                            <div *ngIf="txdata.status === 1">
                                                <span class="">Pending</span>
                                                <ngb-progressbar class="my-1 pending-progress" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                                                <span class="font-size-12 text-dark">All Signed</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Column: Members -->
                                    <td>
                                        <ng-container *ngIf="!(orgProfile.orgType == orgType.sub && orgProfile.custodyType == custodyType.CUSTODY && txdata.txn_req_id);else orgIcon">
                                            <app-signer-user-list [signerUserData]="filterPolicySigner(txdata.signers)" [uniqId]="i" [component_for]="'policytxuser'"></app-signer-user-list>
                                        </ng-container>
                                        <ng-template #orgIcon>
                                            <img src="../../../../assets/images/logo/signer-logo.png" width="44" class="img-fluid" alt="logo">
                                        </ng-template>
                                    </td>
                                    <!-- Column: Notes -->
                                    <td>
                                        <div class="ml-2 text-dark">
                                            -
                                        </div>
                                    </td>
                                    <!-- Column: Time -->
                                    <td class="time">
                                        <div>
                                            {{ txdata.created_at | dateWithZone }}
                                        </div>
                                    </td>
                                    <!-- Column: Action -->
                                    <td class="text-right pr-4">
                                        <button class="btn btn-view-only btn-round btn-sm" title="View only" *ngIf="checkAccess(txdata.signers)">View only</button>
                                        <div *ngFor="let Policysigners of txdata.signers">
                                            <div class="d-flex justify-content-end" *ngIf="Policysigners.email == user.email">
                                                <app-add-policy *ngIf="Policysigners.approved === false" [component_for]="component_for"
                                                    [pendingPolicyData]="txdata" (parentFun)="signPendingPolicy($event,txdata)">
                                                </app-add-policy>
                                                <button appDisableClick class="btn btn-red-light btn-round btn-sm" data-original-title="Reject Policy"
                                                    title="Reject Policy" (click)="open(rejectpolicy);getPolicyTxId(txdata.wallet.id,txdata.id);"
                                                    type="submit">Reject</button>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- TYPE: TRAVEL RULE -->
                                <ng-container *ngIf="txdata?.actionType == 'travel-rule'">
                                    <!-- Column: Type -->
                                    <td class="m-t-10 pl-4">
                                        <div>Send</div>
                                        <span>Approval</span>
                                    </td>
                                    <!-- Column: Asset -->
                                    <td class="m-t-10" [ngClass]="{'pl-4':pendingtransactions_component_for === 'pentrs_wallet_details'}">
                                        <div class="d-flex">
                                            <img class="align-self-center mr-1 img-fluid img-round-border mr-2" [src]="txdata?.asset | getIcon">
                                            <div class="d-flex flex-column">
                                                <span>{{ txdata?.asset | getName }}</span>
                                                <span class="text-dark mr-6">{{ txdata?.wallet?.name }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Column: Value -->
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span class="d-block" placement="top">{{txdata?.amount | amountToPretty : 5}} {{ txdata?.asset | uppercase }}</span>
                                            <span class="d-block text-dark">${{txdata?.usdAmount | amountToPretty : 2}}</span>
                                        </div>
                                    </td>
                                     <!-- Column: WalletType -->
                                     <td appHideIfCustodyOrg>
                                        {{ getWalletType(txdata?.wallet?.type, txdata?.walletinfor?.subtype) }}
                                    </td>

                                    <!-- Column: Details -->
                                    <td class="span-overflow m-t-2 whitespace-wrap">
                                        <div class="float-container">
                                            <div class="float-child second" *ngIf="txdata?.addressLabel">
                                                <a>
                                                    <span class="dottedtext" placement="top" ngbTooltip="{{txdata?.addressLabel}}">
                                                        {{txdata?.addressLabel}}</span>
                                                </a>
                                            </div>
                                            <div class="float-child second">
                                                <a>
                                                    <span class="dottedtext text-dark" ngbTooltip="{{txdata?.destination}}">
                                                        {{txdata?.destination.substring(0,8)}}...{{txdata?.destination.substring(txdata?.destination.length
                                                        - 5)}}
                                                    </span>
                                                </a>
                                            </div>
                                            <div *ngIf="txdata.link" class="float-child first dottedtext">
                                                <a href="{{txdata.link}}" target="_blank">
                                                    <span class="border-bottom-td">
                                                        View Travel Rule
                                                    </span>
                                                </a>
                                            </div>
                                            <div *ngIf="txdata.memo">
                                                <span>Memo/Tag - {{txdata.memo}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Column: Status -->
                                    <td class="text-muted m-t-10 body-font">
                                        <div class="status word-breakdiv">
                                            <div *ngIf="txdata.status === 2">
                                                <span class="">Success</span>
                                                <ngb-progressbar class="my-1 pending-progress" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                                                <span class="text-dark font-size-12">Travel Rule</span>
                                            </div>
                                            <div *ngIf="txdata.status === 5">
                                                <span class="">Failed</span>
                                                <ngb-progressbar class="my-1 failed" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                                                <span class="text-dark font-size-12">Travel Rule</span>
                                            </div>
                                            <div *ngIf="txdata.status === 1">
                                                <span class="">Pending</span>
                                                <ngb-progressbar class="my-1 int-sign-progress" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                                                <span class="text-dark font-size-12">Travel Rule</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- Column: Members -->
                                    <td>
                                        <app-signer-user-list [signerUserData]="txdata.signers" [uniqId]="i" [component_for]="'mpcTx'"></app-signer-user-list>
                                    </td>
                                    <!-- Column: Notes -->
                                    <td>
                                        <div *ngIf="txdata?.comment">
                                            <img placement="top" [ngbTooltip]="list" width="25" src='../../../../assets/images/notes.png' />
                                            <ng-template #list>
                                                <div class="d-flex flex-column text-left text-dark">
                                                    <span>{{txdata?.comment}}</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="ml-2 text-dark" *ngIf="!txdata?.comment">
                                            -
                                        </div>
                                    </td>
                                    <!-- Column: Time -->
                                    <td class="time">
                                        <div>
                                            {{ txdata.createdAt | dateWithZone }}
                                        </div>
                                    </td>
                                    <!-- Column: Action -->
                                    <td class="m-t-10 pr-4">
                                        <div class="d-flex justify-content-end">
                                            <button class="btn btn-primary-light btn-round btn-sm mr-1" title="View" (click)="onViewFirewallTransactionClick(txdata)">View</button>
                                        </div>
                                    </td>
                                </ng-container>
                            
                                <!-- TYPE: WALLETCONNECT/MMI -->
                                <ng-container *ngIf="txdata?.source=='mmi' || txdata?.source=='walletconnect'">
                                    <td class="m-t-10 pl-4">
                                        <div>Send</div>
                                        <span>Approval</span>
                                    </td>
                                
                                    <td class="m-t-10" [ngClass]="{'pl-4':pendingtransactions_component_for === 'pentrs_wallet_details'}">
                                        <div class="d-flex">
                                            <img class="align-self-center mr-1 img-fluid img-round-border mr-2" [src]="txdata?.asset | getIcon">
                                            <div class="d-flex flex-column">
                                                <span>{{ txdata?.asset | getName }}</span>
                                                <span class="text-dark">{{ txdata?.walletinfor?.name }}</span>
                                            </div>
                                
                                        </div>
                                    </td>
                                
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span class="d-block">-</span>
                                        </div>
                                    </td>
                                    <!-- Column: WalletType -->
                                    <td appHideIfCustodyOrg>
                                        {{ getWalletType(txdata?.walletinfor?.type, txdata?.walletinfor?.subtype) }}
                                    </td>

                                    <td class="span-overflow m-t-2 whitespace-wrap">
                                        <div class="float-container">
                                
                                            <div class="float-child second">
                                                <span class="text-dark">
                                                    Signature request
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-muted m-t-10 body-font">
                                        <div class="status word-breakdiv">
                                            <div *ngIf="txdata.status === 1">
                                                <span class="">Initiating</span>
                                                <ngb-progressbar class="my-1 int-sign-progress" [value]="txdata.team.initiatorsSigns"
                                                    [max]="txdata.team.initiators" height="5px"></ngb-progressbar>
                                                <span class="d-flex text-dark"><b class="font-size-12">{{txdata.team.initiators -
                                                        txdata.team.initiatorsSigns}}</b> <span class="font-size-12 ml-1">Remaining</span></span>
                                            </div>
                                            <div *ngIf="txdata.status === 2">
                                                <span class="">Signing</span>
                                                <ngb-progressbar class="my-1 int-sign-progress" [value]="txdata.team.signersSigns"
                                                    [max]="txdata.team.signers" height="5px"></ngb-progressbar>
                                                <span class="d-flex text-dark"><b class="font-size-12">{{txdata.team.signers -
                                                        txdata.team.signersSigns}}</b> <span class="font-size-12 ml-1">Remaining</span></span>
                                            </div>
                                            <div *ngIf="txdata.status != 2 && txdata.status != 1">
                                                <span class="">Pending</span>
                                                <ngb-progressbar class="my-1 pending-progress" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                                                <span class="text-dark font-size-12">All Signed</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="txdata.status === 1">
                                            <app-signer-user-list [signerUserData]="txdata.initiatorsList" [uniqId]="i" [component_for]="'mpcTx'">
                                            </app-signer-user-list>
                                        </ng-container>
                                        <ng-container *ngIf="txdata.status === 2">
                                            <app-signer-user-list [signerUserData]="txdata.signersList" [uniqId]="i" [component_for]="'mpcTx'">
                                            </app-signer-user-list>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <div *ngIf="txdata?.message">
                                            <img placement="top" [ngbTooltip]="list" width="25" src='../../../../assets/images/notes.png' />
                                            <ng-template #list>
                                                <div class="d-flex flex-column text-left p-2">
                                                    <span>{{txdata?.message.length<=80 ? txdata?.message :
                                                            txdata?.message.substring(0,80)+'...'}}</span>
                                                            <span class="text-right text-primary">Wallet Connect</span>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="ml-2 text-dark" *ngIf="!txdata?.message">
                                            -
                                        </div>
                                    </td>
                                    <td class="time">
                                        <div>
                                            {{ txdata.createdAt | dateWithZone }}
                                        </div>
                                    </td>
                                    <td *ngIf="txdata.walletinfor" class="m-t-10 pr-4 text-right">
                                        <div class="d-flex justify-content-end">
                                            <button class="btn btn-primary-light btn-round btn-sm mr-1" title="View"  (click)="onViewFirewallTransactionClick(txdata)">View</button>
                                        </div>
                                    </td>
                                </ng-container> 

                                <!-- TYPE: Wallet Request -->
                                <ng-container *ngIf="txdata?.type == 'walletReq'">
                                    <td class="m-t-10 pl-4">
                                        <div class="d-flex flex-column">
                                            <span class="text-capitalize">Wallet</span>
                                            <span class="text-capitalize">Approval</span>
                                        </div>
                                    </td>
                                  
                                <td class="m-t-10"
                                    [ngClass]="{'pl-4':pendingtransactions_component_for === 'pentrs_wallet_details'}">
                                    <div class="d-flex">
                                        <!-- <img class="align-self-center mr-1 img-fluid img-round-border mr-2"
                                                [src]="txdata?.w_chain | getIcon"> -->
                                                <img class="align-self-center mr-1 img-fluid img-round-border mr-2" src="../../../../assets/images/universal-wallet-icon.svg" alt="img" >       
                                                <div class="d-flex flex-column">
                                                    <span>All Assets</span>
                                                    <!-- <span>{{ txdata?.w_chain | getName }}</span> -->
                                                <span class="text-dark">{{ txdata?.w_name }}</span>
                                                </div>
                                                
                                        </div>
                                    </td>
                                
                                <td>
                                    <div class="d-flex flex-column">
                                        <span  class="d-block" >-</span>
                                    </div>
                                </td>
                                <!-- Column: WalletType -->
                                <td appHideIfCustodyOrg>
                                    {{ txdata?.walletType }}
                                </td>
                                <td class="span-overflow m-t-2 whitespace-wrap">
                                    <div class="float-container">
                                       
                                        <div class="float-child second">
                                                <span class="d-block">
                                                    Wallet creation
                                                </span>
                                                <span class="d-block text-dark">{{ txdata?.w_chain | getName }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-muted m-t-10 body-font" >
                                    <div class="status word-breakdiv">
                                        <div>
                                            <span class="">Pending</span>
                                            <ngb-progressbar class="my-1 pending-progress" [value]="50" [max]="100" height="5px"></ngb-progressbar>
                                            <!-- <span class="text-dark font-size-12">All Signed</span> -->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    
                                    <app-members-list [members]="txdata?.teamMembers" *ngIf="txdata.status !== 4"> </app-members-list>
                                    
                                </td>
                                <td>
                                    <div class="ml-2 text-dark">
                                        -
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{ txdata.w_created_at | dateWithZone }}
                                    </div>
                                </td>
                                <td class="m-t-10 text-right pr-4">
                                    <button class="btn btn-view-only btn-round btn-sm" title="View only">View only</button>
                                </td>
                                </ng-container>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="txdata?.speedUpTransactionList?.length && txdata.tx_state == 'open'">
                            <!-- Speed Up Transaction Content  -->
                            <ng-container
                                *ngFor="let speedUpTxData of txdata?.speedUpTransactionList;index as i; last as isLast">

                                <!-- TYPE: MULTISIG -->
                                <ng-container
                                    *ngIf="speedUpTxData?.walletinfor && speedUpTxData?._txType == 'multisig_tx'">
                                    <tr [ngClass]="{ 'row-first': i === 0, 'row-last': isLast, 'open-state-tr': txdata?.tx_state === 'open' }"
                                        multisig-wallet-table-data [txdata]="speedUpTxData" [orgProfile]="orgProfile"
                                        [pendingtransactions_component_for]="pendingtransactions_component_for"
                                        (onRejectTxn)="open(canceltrsancation);gettransaction(speedUpTxData)">
                                    </tr>
                                </ng-container>

                                <!-- TYPE: MPC -->
                                <ng-container
                                    *ngIf="speedUpTxData.msgToSign && !speedUpTxData?.source && !isLivenessType(speedUpTxData) && speedUpTxData?._mpcTxType">
                                    <tr [ngClass]="{ 'row-first': i === 0, 'row-last': isLast, 'open-state-tr': txdata?.tx_state === 'open' }"
                                        mpc-wallet-table-data [txdata]="speedUpTxData" [orgProfile]="orgProfile"
                                        [pendingtransactions_component_for]="pendingtransactions_component_for"
                                        [isLivenessTransactionExists]="isLivenessTransactionExists(speedUpTxData)"
                                        (onRejectTxn)="open(canceltrsancation);getMpcTransaction(speedUpTxData)">
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </tbody>
                </table>
            </div>
            <div class="col-12 my-3"
                *ngIf="(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq) | actionsFilter:filterActionsType:meFilter).length > 0 && pendingtx_component_for != 'dashboared-pending'">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="table-footer-text">Showing
                        <span>{{(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                            | actionsFilter:filterActionsType:meFilter | pendingTxSearch:pendingSearch).length |
                            paginationFromTo : page : pageSize}}</span> of
                        {{(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq)
                        | actionsFilter:filterActionsType:meFilter | pendingTxSearch:pendingSearch).length}}
                        entries</span>
                    <div class="pagination">
                        <ngb-pagination
                            [collectionSize]="(concat(pendingtxs,policiesPendingTx,pendingTravelRules,teamWalletPendingTx,messageSignPendingTx,walletReq) | actionsFilter:filterActionsType:meFilter | pendingTxSearch:pendingSearch).length"
                            [(page)]="page" [pageSize]="pageSize" [maxSize]="3" [boundaryLinks]="true"
                            [ellipses]="false">
                            <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left"
                                    placement="top" ngbTooltip="First"></i><i class="icon-arrow-left" placement="top"
                                    ngbTooltip="First"></i></ng-template>
                            <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left"
                                    placement="top" ngbTooltip="Previous"></i></ng-template>
                            <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right"
                                    placement="top" ngbTooltip="Next"></i></ng-template>
                            <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right"
                                    placement="top" ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Cancel transaction confirmation flow -->
    <ng-template #canceltrsancation let-modal>
        <div class="modal-header border-0">
            <h2 class="modal-title">
                Cancel Transaction
            </h2>
            <button type="button" class="close" id="closemodalbtn" (click)="modal.dismiss('Cross click')">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="my-4">
                <p class="text-left" *ngIf="!Error_canceltransaction">Are you sure you want to cancel this transaction?</p>
                <p class="text-left text-red" *ngIf="Error_canceltransaction && !isCanceling">{{error_message}}</p>
            </div>
        </div>
        <div class="modal-footer border-0" *ngIf="!Error_canceltransaction">
            <button (click)="cancelTx()" class="btn btn-primary" [ngClass]="{'disabled': isCanceling }" [disabled]="isCanceling" type="button">
                <ng-container *ngIf="!isCanceling">
                    <i class="icon-check mr-1"></i> Yes
                </ng-container>
                <div *ngIf="isCanceling" class="loader-box">
                    <div class="loader-15 bg-white"></div>
                </div>
            </button>
        </div>
    </ng-template>

    <!-- Cancel pending policy flow -->
    <ng-template #rejectpolicy let-modal>
        <div class="modal-header">
            <h2 class="modal-title">
                Reject Policy
            </h2>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="m-t-20  m-b-20">
                <p class="text-left" *ngIf="!Error_canceltransaction"><span>Are you sure, you want to reject this policy request?</span></p>
                <p class="text-left txt-danger" *ngIf="Error_canceltransaction"><span>Something went wrong. Please try again after sometime!</span></p>
            </div>
        </div>
        <div class="modal-footer" *ngIf="!Error_canceltransaction">
            <button class="btn btn-primary" type="button" (click)="rejectPolicy()"> <i class="icon-check mr-1"></i>Yes</button>
        </div>
    </ng-template>
</div>

<div *ngIf="!policiesPendingTx?.length && !pendingtxs?.length && !teamWalletPendingTx?.length && !messageSignPendingTx?.length && !walletReq?.length && !pendingTravelRules?.length && !loading" class="card d-flex align-items-center p-4 card-shadow-border">
    <ng-container *ngIf='!["pentrs_wallet_details"].includes(pendingtransactions_component_for)'>   
        <img  src="../../../assets/images/placeholder/action.png" width="125" class="mb-4">
        <h6>No pending actions yet</h6>
    </ng-container> 
    <p class="mb-0">There is no pending actions right now.</p>
</div>

<ng-container>
    <app-send-assets></app-send-assets>
</ng-container>