<div class="d-inline-block avatar-group customers">
    <div class="scoll-pane rounded-pill">
        <ng-container *ngIf="!isAnyCase">
            <ul class="d-flex p-2">
                <ng-container *ngFor="let asset of displayArray">
                    <li class="d-inline-block asset-image" 
                        placement="top" 
                        [ngbTooltip]="asset.name">
                        <img class="align-self-center img-30 rounded-circle text-left" [src]="asset.image">
                    </li>
                </ng-container>
                <ng-container *ngIf="hiddenItems.length">
                    <li class="d-flex hidden-item"  placement="top"  [ngbTooltip]="hiddenItems">
                        <div class="align-self-center rounded-circle hidden-text">
                            <span>+{{hiddenItems.length}}</span>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
        <ng-container *ngIf="isAnyCase">
           <div class="any-item">
                <span>Any</span>
           </div>
        </ng-container>
    </div>
</div>