<div class="card card-border" *ngIf="card == 'validator'" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body align-items-center" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img class="mr-2 img-fluid img-round-border" [src]="validatorData.logo" />
            <div class="d-flex flex-column text-left">
                <span class="">{{validatorData?.provider_name}}</span>
                <span class="text-dark font-size-12">{{ validatorData?.address }}</span>
            </div>
        </div>
    </div>
</div>
<div class="card card-border" *ngIf="card == 'validator_amount'" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body align-items-center" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img class="mr-2 img-fluid img-round-border" [src]="validatorData.logo" />
            <div class="d-flex flex-column text-left">
                <span class="body-font">{{validatorData?.provider_name}}</span>
            </div>
            <div class="d-flex flex-column ml-auto text-right" *ngIf="stakeAmountData.parentChain!='SOL'">
                <span class="body-font">{{stakeAmountData?.sumBalance?.total_staking_amount | amountToPretty : 5}} {{validatorData?.coin}}</span>
                <span class="body-font font-size-12 text-dark">${{stakeAmountData?.sumBalance?.total_usd_staking_amount | amountToPretty : 2}}</span>
            </div>
            <div class="d-flex flex-column ml-auto text-right" *ngIf="stakeAmountData.parentChain=='SOL'">
                <span class="body-font">{{stakeAmountData?.sumBalance?.total_unstaked_amount > 0 ? stakeAmountData?.sumBalance?.total_unstaked_amount: stakeAmountData?.sumBalance?.total_staking_amount | amountToPretty : 5}} {{validatorData?.coin}}</span>
                <span class="body-font font-size-12 text-dark">${{stakeAmountData?.sumBalance?.total_usd_unstaked_amount | amountToPretty : 2}}</span>
            </div>
        </div>
    </div>
</div>

<!-- <div class="card card-border" *ngIf="card == 'claim'" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''">
    <div class="card-body align-items-center" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img class="mr-2 img-fluid img-round-border" [src]="validatorData.chain | getIcon">
            <div class="d-flex flex-column text-left">
                <span class="text-black">{{validatorData.provider_name}}</span>
            </div>
            <div class="d-flex flex-column ml-auto text-right">
                <span class="text-black">{{stakeAmountData.sumBalance.total_reward_amount | amountToPretty : 5}} {{validatorData.coin}}</span>
                <span class="text-black font-size-12 text-dark">${{stakeAmountData.sumBalance.total_usd_reward_amount | amountToPretty : 2}}</span>
            </div>
        </div>
    </div>
</div> -->