<div ngbTooltip="{{walletRole != 'admin' ?'Only admins can add users as viewers':'' || addMemberTooltip}}" placement="bottom" tooltipClass="viewer-tooltip">
  <button appDisableClick class="btn btn-primary-light btn-md" [ngClass]="{ disabled: walletRole != 'admin' || isWalletArchived }" [disabled]="walletRole != 'admin' || isWalletArchived" *ngIf="component_for === 'add-member'" (click)="open(addMember)">
      <i class="icon-add-alt"></i>
      <span class="d-inline ml-1">Add</span>
  </button>
</div>
<ng-template #addMember let-modal>
    <div class="modal-custom" *ngIf="step == 'step-1'">
      <div class="modal-header">
        <h2 class="modal-title">
          <i class="icon-users text-dark mr-2"></i>
          <ng-container>Add Members</ng-container>
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
          <i class="icon-close"></i>
        </button>
      </div>
      <div class="modal-body custom-height-body">
        <div class="row">
         <div class="col-12 mb-4">
          <p class="text-dark">Selected users will be added to the wallet as Viewers only</p>
            <label class="text-dark">Select Member</label>
            <ng-multiselect-dropdown [settings]="dropdownSettings" name="member"
              placeholder="Select Members" [disabled]="disabled" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"  (onSelectAll)="onSelectAll($event)"
              (onDeSelectAll)="onDeSelectAll($event)" [data]="org_member">
              <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected"
                class="d-flex">
                <div class="d-flex align-items-center p-0" [ngClass]="isSelected?'selected':''">
                  <div class="d-flex align-items-center box_Circle_Qr p-2">
                    <img class="img-fluid img-round-border p-1" [src]="getItems[id].phptoURL" alt="">
                  </div>
                  <div class="details d-flex flex-column">
                    <div class="text-left p-0">
                      <span #name class="m-0 truncate_Name">{{getItems[id].displayName}}</span>
                    </div>
                    <!-- <div class="truncate text-left p-0 t_Id">
                      <span class="text-muted">Policy Manager, Owner</span>
                    </div> -->
                  </div>
                </div>
              </ng-template>
              <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                <div class="d-flex">
                  <div class="d-flex align-items-center box_Circle_Qr">
                    <img class="img-fluid img-round-border img-round-border-small  p-1 mr-2"
                    [src]="getItems[id].phptoURL" alt="">
                  </div>
                  <div class="details d-flex align-items-center">
                    <div class="col text-left p-0">
                      <span #name class="m-0 truncate_Name ">{{ option }}</span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" (click)="addMemberAsViewer()" [ngClass]="{ disabled: !selectedMember.length }" [disabled]="!selectedMember.length" class="btn btn-outline-primary w-100">
          Add Member
        </button>
      </div>
    </div>
    
    <div class="modal-custom" *ngIf="step == 'success'">
      <div class="modal-header border-0">
          <h2 class="modal-title"><i class="icon-users text-dark mr-2"></i>Add Members</h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
                  class="icon-close"></i> </button>
      </div>
      <div class="modal-body d-flex align-items-center">
          <div class="row">
              <div class="col-12">
                  <div class="mb-3 position-relative">
                      <div class="background_success text-center"> <img class="w-50"
                              src="../../../../assets/images/check-outline-success.gif"> </div>
                  </div>
                  <div class="mb-5 text-center">
                      <div class="h5">Success</div>
                      <div class="text-muted">'Awesome! You have successfully added <br>member as viewer 🎉'
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
          <button type="submit" class="btn btn-outline-primary w-100"
              (click)="modal.dismiss('Cross click');"> Done
          </button>
      </div>
  </div>
</ng-template>