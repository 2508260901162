import { Psbt as BCH_Psbt } from '../../bchpsbt';
import { environment } from '../../../../../environments/environment';
import { Output } from '../../../entities';
import * as bitcoin from 'bitcoinjs-lib';
import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import { LedgerService } from '../../ledger/ledger';
import Btc from "@ledgerhq/hw-app-btc";
import { getSigningPubkey, getTrezorTx } from '../trezorTxUtis'
import TrezorConnect from 'trezor-connect';
import { Guid } from "guid-typescript";
import { validateSignature } from '../../../utils/validate-signature';

export async function signPsbt(psbT: string, output: Output, scriptSig: string, txReq) {
    let signer1;
    let Coinname;
    if (this.wallet.chain.toLowerCase() == "bch") {
        signer1 = BCH_Psbt.fromHex(psbT, { network: environment.btcNetwork });
        Coinname = "Bitcoin Cash";
    } else {
        signer1 = bitcoin.Psbt.fromHex(psbT, { network: environment.btcNetwork });
        Coinname = "Bitcoin";
    }
    let trezorTx = getTrezorTx(psbT, this.wallet, scriptSig) as any;
    let signerTx;
    var walletkey = this.wallet.walletKeys;
    var objwalletkey = walletkey.find(x => x.ismine === true);
    let isLedger = (objwalletkey.provider == 'ledger');
    // let isLedger = true;
    if (isLedger) {
        /////////Ledger Start
        let transport = await Transportwebusb.create();
        await LedgerService.openApp(Coinname, transport);
        transport = await LedgerService.getNewTransport();
        const btc = new Btc({transport});
        // Find it is segwit or not
        const isSegwit = true;
        // Make __CACHE method to public in psbt.d.ts.
        const newTx = signer1.__CACHE.__TX;
        const outtx = btc.splitTransaction(newTx.toHex(), isSegwit);
        const outputScript = btc.serializeTransactionOutputs(outtx).toString('hex');
        function buf2hex(buffer) {
            return [...new Uint8Array(buffer)]
                .map(x => x.toString(16).padStart(2, '0'))
                .join('');
        };
        
        console.log(signer1);
        console.log(trezorTx);

        const inputs = new Array<[any, number, string | null | undefined, number | null | undefined]>();
        const associatedKeysets = new Array<string>();

        for (let i = 0; i < trezorTx.inputs.length; i++) {
            let reedemscreept;
            if (isSegwit)
                reedemscreept = signer1.data.inputs[i].witnessScript;
            else
                reedemscreept = signer1.data.inputs[i].redeemScript;
            const txbuffer = signer1.data.inputs[i].nonWitnessUtxo;
            const vinUTXO = buf2hex(txbuffer);
            var vintx = btc.splitTransaction(vinUTXO, isSegwit);
            inputs.push([vintx, trezorTx.inputs[i].prev_index, buf2hex(reedemscreept), trezorTx.inputs[i].sequence]);
            let walletPath = (objwalletkey.path + "/" + trezorTx.inputs[0].multisig.pubkeys[0].address_n[0] + "/" + trezorTx.inputs[0].multisig.pubkeys[0].address_n[1]);
            associatedKeysets.push(walletPath.toString());
        }
        const btcout = await btc.signP2SHTransaction({
            inputs: inputs,
            associatedKeysets: associatedKeysets,
            outputScriptHex: outputScript,
            lockTime: signer1.locktime,
            segwit: isSegwit,
            transactionVersion: signer1.version,
        });
        for (let i = 0; i < btcout.length; i++) {
            let sig;
            if (isSegwit)
                sig = Buffer.from(btcout[i], 'hex')
            else
                sig = Buffer.concat([Buffer.from(btcout[i], 'hex'), Buffer.from('01', 'hex')]);
            const partialSig = [
                {
                    pubkey: getSigningPubkey(this.wallet, trezorTx.inputs[i].multisig.pubkeys[0].address_n),
                    signature: sig,
                }
            ];
            signer1.data.updateInput(i, { partialSig: partialSig });
           
            const isSignatureValid = validateSignature(
                signer1,
                this.wallet.chain,
                i,
                partialSig
            );

            // Log the validation result.
            this.logger.info(`Signature verification result: ${isSignatureValid}`);

            // Return an error if validation fails.
            if (!isSignatureValid) {
                return [{ error: 'You might be signing with an incorrect Ledger device!' }];
            }

        }
        signerTx = { success: true };
        /////////Ledger End
    }
    else {
        signerTx = await TrezorConnect.signTransaction(trezorTx);
        this.logger.info("Signing result " + JSON.stringify(signerTx));
        if (!signerTx.success) {
            if (signerTx.payload && signerTx.payload.error && signerTx.payload.error == 'Pubkey not found in multisig script')
                return [{ "error": "You might be signing with an incorrect trezor device!" }]
            return [{ "error": signerTx.payload.code }]
        }

        this.logger.info(psbT + " " + signerTx)
        //let signer1 = bitcoin.Psbt.fromHex(psbT, { network: environment.btcNetwork });
        for (let i = 0; i < signerTx.payload.signatures.length; i++) {
            let sighash = this.wallet.chain.toLowerCase() == "bch" ? "41" : "01";
            const partialSig = [
                {
                    pubkey: getSigningPubkey(this.wallet, trezorTx.inputs[i].multisig.pubkeys[0].address_n),
                    signature: Buffer.from(signerTx.payload.signatures[i] + sighash, "hex"),
                }
            ];
            this.logger.info(signerTx, signerTx.payload.signatures[i] + sighash);
            signer1.data.updateInput(i, { partialSig: partialSig });
            
            const isSignatureValid = validateSignature(
                signer1,
                this.wallet.chain,
                i,
                partialSig
            );

            // Log the validation result.
            this.logger.info(`Signature verification result: ${isSignatureValid}`);

            // Return an error if validation fails.
            if (!isSignatureValid) {
                return [{ error: 'You might be signing with an incorrect Trezor device!' }];
            }
        }
    }

    let sequenceId = Guid.create().toString();
    if (txReq) {
        if ('sequenceId' in txReq) {
            sequenceId = txReq['sequenceId'] as string;
        }
    }

    let apiResult = await this.httpService.addNewMultisigTransaction(this.wallet.id, {
        sequenceId: sequenceId,
        walletid: this.wallet.id,
        raw: { "txHex": psbT, signature: signer1.toBase64(), trezorTx: trezorTx },
        pubkey: "",
        destinationAddress: output.address,
        fromAddress: this.wallet.address,
        amount: output.value,
        chain: this.wallet.chain,
        asset: this.wallet.coin,
        type: 1
    }).toPromise()
    return [signerTx, apiResult]
}