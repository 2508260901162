<div class="container-fluid center">
    <div class="row">
        <div class="col-xl-12 p-0">
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item">
                    <a class="text-muted font-weight-bolder" routerLink='/settings'>Settings</a>
                </li>
                <li class="breadcrumb-item active">
                    <span class="text-muted font-weight-bolder">Teams</span>
                </li>
            </ol>
        </div>
    </div>
    <div class="row px-2 py-4" style="border-radius: 14px;" *ngIf="teamsListData && !loading">
        <div class="col-sm-12">
            <div class="d-flex justify-content-between align-items-center header-title-margin pb-3">
                <h6 class="d-inline mb-0 mr-5">Teams</h6>
                <div class="d-flex align-items-center">
                    <div class="search-round mr-3" *ngIf="teamsListData">
                        <i class="icon-search" aria-hidden="true"></i>
                        <input class="form-control" [(ngModel)]="searchTerm" (ngModelChange)="searchTermChanged($event)"
                            placeholder="Search by team name" type="text">

                    </div>
                    <ul class="d-flex flex-wrap" *ngIf="teamsListData" appHideIfCustodyOrg>
                        <li>
                            <span class="tag cursor-pointer mr-2"
                                [ngClass]="{ 'active': filterTeamType === FilterType.ALL}"
                                (click)="teamTypeFilter(FilterType.ALL)">All</span>
                        </li>
                        <li>
                            <span class="tag cursor-pointer mr-2"
                                [ngClass]="{ 'active': filterTeamType === FilterType.SIGNED}"
                                (click)="teamTypeFilter(FilterType.SIGNED)">Signing Team</span>
                        </li>
                        <li>
                            <span class="tag cursor-pointer mr-2"
                                [ngClass]="{ 'active': filterTeamType === FilterType.NON_SIGNED}"
                                (click)="teamTypeFilter(FilterType.NON_SIGNED)">Non Signing Team</span>
                        </li>
                    </ul>

                     <!-- Create team Button  -->
                    <button appDisableClick ngbTooltip="Add Team" class="btn btn-primary btn-round btn-md"
                        (click)="openAddTeamModal()">
                        <i class="icon-add-alt"></i>
                        <span class="d-inline ml-1">Add</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card m-t-10">
                <div class="table-responsive styled-table">
                    <table class="table policies-list-table table-only-header">
                        <thead>
                            <tr>
                                <th class="pl-4" style="white-space: nowrap;">Team</th>
                                <th style="white-space: nowrap;" class="text-center">Wallets</th>
                                <th style="white-space: nowrap;">Members</th>
                                <th style="white-space: nowrap;" class="text-center" appHideIfCustodyOrg>Team Type</th>
                                <th style="white-space: nowrap;width: 10px"></th>
                                <th *ngIf="(teamsListData | teamListSearch: teamSearch ).length === 0"></th>
                            </tr>
                            
                        </thead>
                        <tbody id="policies-list-table">
                            <!-- Display skeleton loader only during content loading -->
                            <ng-container *ngIf="isContentLoading; else contentData">
                                <tr *ngFor="let item of [].constructor(PAGE_SIZE); let i = index">
                                    <td>
                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                    </td>
                                    <td>
                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                    </td>
                                    <td>
                                        <ngx-skeleton-loader count="4" appearance="circle"></ngx-skeleton-loader>
                                    </td>
                                    <td>
                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-template #contentData>
                                <tr *ngFor="let teamsData of teamsListData ;let i=index">
                                    <td class="pl-4" style="vertical-align: middle;">
                                        <div>
                                            <span class="d-block" placement="top">{{teamsData.name}}</span>
                                        </div>
                                    </td>
                                    <td style="vertical-align: middle;" class="text-center">
                                        <span
                                            *ngIf="teamsData?.walletCount > 0 ;else showDash">{{teamsData.walletCount}}</span>
                                        <ng-template #showDash>
                                            <span>-</span>
                                        </ng-template>
                                    </td>
                                    <td class="light-grey-text" style="vertical-align: middle;">
                                        <!-- TODO: This will change based on changes in the API -->
                                        <app-members-list [members]="teamsData.teamMembers">
                                        </app-members-list>
                                    </td>
                                    <td style="vertical-align: middle;" class="text-center" appHideIfCustodyOrg>
                                        <p class="mb-0">{{ getSigningTypeLabel(teamsData.type) }}
                                        </p>
                                        <span *ngIf="teamsData.subType" class="text-muted">
                                            {{ getSigningSubTypeLabel(teamsData.subType) }}
                                        </span>
                                    </td>
                                    <td>
                                        <div ngbDropdown container="body" *ngIf="isUserOwner">
                                            <button class="dropDown-btn" 
                                                    ngbDropdownToggle 
                                                    placement="left"
                                                    [ngClass]="{disabled: isMachineUserTeam(teamsData)}"
                                                    tooltipClass="member-tooltip"
                                                    [disabled]="isMachineUserTeam(teamsData)"
                                                    [ngbTooltip]="isMachineUserTeam(teamsData) ? 'Cannot edit teams with Machine Users' : ''">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                </svg>
                                                <div ngbDropdownMenu style="border-radius: 12px;">
                                                    <button ngbDropdownItem class="px-3 d-flex align-items-center" (click)="openEditTeamModal(teamsData)">
                                                      <i class="icon-edit icon-squre-custon mr-2 fs-14"></i>
                                                      Edit Team
                                                    </button>
                                                </div>
                                            </button>
                                       </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <tr *ngIf="showNoResultsFound">
                                <td colspan="8" class="p-2 text-center">No results found.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="teamsListData.length > 0" class="mt-3 mb-3">
                <div class="d-flex justify-content-between">
                    <span>Showing <span>{{paginationMetaData?.totalCount | paginationFromTo :
                            paginationMetaData?.currentPage : paginationMetaData?.pageSize}}</span> of
                        {{paginationMetaData?.totalCount}}
                        entries</span>
                    <div class="pagination">
                        <ngb-pagination [collectionSize]="paginationMetaData?.totalCount"
                            [(page)]="paginationMetaData.currentPage" [pageSize]="paginationMetaData.pageSize"
                            [maxSize]="3" [boundaryLinks]="true" [ellipses]="false" (pageChange)="onPageChange($event)">
                            <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left"
                                    placement="top" ngbTooltip="First"></i><i class="icon-arrow-left" placement="top"
                                    ngbTooltip="First"></i></ng-template>
                            <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left"
                                    placement="top" ngbTooltip="Previous"></i></ng-template>
                            <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right"
                                    placement="top" ngbTooltip="Next"></i></ng-template>
                            <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right"
                                    placement="top" ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card card-margin mt-3" *ngIf="loading">
    <div class="table-responsive styled-table">
        <table class="table">
            <thead>
                <tr>
                    <th class="pl-4" scope="col" style="width: 400px;"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                    <th scope="col" style="width: 250px;"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                    <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                    <th scope="col" style="width: 250px;" appHideIfCustodyOrg><ngx-skeleton-loader></ngx-skeleton-loader></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of [].constructor(PAGE_SIZE + 3); let i = index">
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                    <td>
                        <ngx-skeleton-loader count="4" appearance="circle"></ngx-skeleton-loader>
                    </td>
                    <td appHideIfCustodyOrg>
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="!loading && !teamsListData.length" && !showNoResultsFound
    class="card d-flex align-items-center p-4 card-shadow-border">
    <img src="../../../assets/images/placeholder/policy.png" width="125" class="mb-4">
    <h4>No teams yet</h4>
    <p class="mb-0">No teams yet, create teams now.</p>
</div>