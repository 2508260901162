import { Component, Input, OnInit } from '@angular/core';
import { orgType, CUSTODY_TYPE } from '../../../shared/entities/members';
import { getParentChain } from '../../../shared/helpers/WalletUtils';
import { getName, subName, getSupportedCoins, sortCoinsAlphabetically, getSupportedCoinGroupText } from '../../../shared/helpers/coins';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SegmentService } from 'ngx-segment-analytics';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConstantService } from '../../../shared/services/constant.service';
import { DataService } from '../../../shared/services/data.service';
import { HttpService } from '../../../shared/services/http.service';
import { MembersService } from '../../../shared/services/members.service';
import { OrgFeatureType } from '../../../shared/entities/OrganizationFeatures';
import { isMultiSigHybridEnabled, multisigCustodyType } from '../../../shared/helpers/org.utils';
import { MPCTeamConfig, MPCWalletData, TeamsV2, WalletRequest } from '../../../shared/entities';
import { MembersType } from '../../../shared/components/new-group-wallet/mobile-teams/mobile-teams.component';
import { TeamsService } from '../../../shared/services/teams.service';
import { ErrorPrompt } from '../../../shared/components/custom-prompt/custom-prompt.service';
import { WalletService } from '../../../shared/services/wallet.service';
import { NoShowError } from '../../../shared/entities/Error';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'app-create-wallet-custody',
  templateUrl: './create-wallet-custody.component.html',
  styleUrls: ['./create-wallet-custody.component.scss']
})
export class CreateWalletCustodyComponent implements OnInit {
  selected_Asset = false;
  step_add_member = false;
  step_link_device = false;
  stepNumber = 1;
  fltr: any;
  _members: any;
  members: any;
  minimalApprovalReq = [1];
  member_Value_drop = 2;
  minApprove_val = 1;
  custom = false;
  selectedItem: any;
  selected = true;
  selectedItem_custom: string;
  shield: boolean = true;
  total_members_limit = false;
  custom_val = false;
  total_members: number;
  min_members: number;
  ttl_member_val: number;
  min_member_val: number;
  enable_preivew = false;
  getmem: any;
  added_member = false;
  type: string;
  final_members: string;
  gas_station: boolean = true;
  user: any;
  selected_asset_value: string;
  walletName: string = "";
  disable_New_Wallet = false;
  get_user_link_details: any;
  verifyWalletName;
  createWalletOption = false;
  createSameAddress: boolean;
  loading: boolean = false;

  checkSignerSelection: boolean = false;
  selected_type: any = {};
  selected_team: any = {};
  notLinkedMemberSigner = [];
  notLinkedMemberInitiator = [];
  isChainLinkeds: any = true;

  @Input() component_for: string;
  link_component_for = "link_new";
  dropdownSettings: IDropdownSettings = {};
  signersDropdownSettings: IDropdownSettings = {};
  coin: any;
  teamsListData: TeamsV2[];
  teamsList: TeamsV2[];
  onDestroy$: Subject<void> = new Subject();
  orgProfile: any = {};

  orgType = orgType;
  custodyType = CUSTODY_TYPE;
  userKeys: any;

  orgFeatureType = OrgFeatureType;

  multisigCustodyType: OrgFeatureType;
  multisigSigners: any[] = [];
  filteredMultisigSigners: any[] = [];
  selectedMultisigSigners: any[] = [];

  // Specific to the MPC flow
  MembersType = MembersType;
  walletCreateStep: number;
  mpcWalletData: MPCWalletData;

  currentUserDetails: any;
  displayFirewallWarning: boolean = false;

  MULTISIG_TEAMS_SUPPORTED_CHAINS = ["EVM", "UTXO", "TRON"]

  constructor(
    private org: ConstantService,
    data: DataService,
    private modalService: NgbModal,
    private httpservice: HttpService,
    private memberservice: MembersService,
    private segment: SegmentService,
    config: NgbModalConfig,
    private teamsService: TeamsService,
    private walletService: WalletService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.total_members = 2;
    this.min_members = 1;
    this.user = JSON.parse(localStorage.getItem("user"));

    data.getUserProfile
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user_detail) => {
        this.org.changeOrg(user_detail["organizations"]);
        this.orgProfile = user_detail["organizations"][0];
        if (user_detail["organizations"][0].type === 2) {
          let data = user_detail;
          // Get the keys that exist in obj2.MPC_Protocol_Support
          let validKeys = Object.keys(environment.MPC_Protocol_Support);

          // For HYBRID custody, only show the keys that are supported by the multisig teams
          if (isMultiSigHybridEnabled(this.orgProfile)) {
            validKeys = validKeys.filter(
              (key) => {
                const parentchain = getParentChain(key);
                return this.MULTISIG_TEAMS_SUPPORTED_CHAINS.includes(parentchain);
              }
            );
          }

          // Remove the keys in obj1.keys that are not in validKeys
          for (const key of Object.keys(data.keys)) {
            if (!validKeys.includes(key)) {
              delete data.keys[key];
            }
          }
          this.fltr = data;
          this.disable_New_Wallet = true;
        }
        if (environment.disableTennetBuildFts) {
          this.disable_New_Wallet = false;
        }
        // this.fltr = user_detail;
        this.get_user_link_details = user_detail["keys"];
        // Fetch the type of multisig custody
        this.multisigCustodyType = multisigCustodyType(this.orgProfile);
      });

    this.getTeamList();
  }

  ngOndestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async getProfile() {
    this.userKeys = await this.httpservice.getprofile().toPromise().then();
  }

  getTeamList() {
    this.teamsService.getAllTeams()
      .subscribe((allTeams) => {
        this.teamsList = allTeams.teamListData;
        this.teamsListData = allTeams.teamListData;
      });
  }

  async getMember(orgId) {
    let _members = await this.httpservice.getOrgMembers(orgId);
    console.info("org id are --------", orgId, _members.members);
    // this.members = _members.member
  }

  ngOnInit() {
    this.stepNumber = 1;
    this.getProfile();
    this.getmem = [];
    this.memberservice.resetMember();
    this.members = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      enableCheckAll: false,
      allowSearchFilter: true,
      limitSelection: this.total_members,
      noDataAvailablePlaceholderText: "",
    };
    this.signersDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      enableCheckAll: false,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: "No signers available, please link device",
    };
  }

  getName = (id) => {
    return getName(id);
  };

  getSubName = (id) => {
    return subName(id);
  };

  getSupportedCoins = (key) => {
    return getSupportedCoins(key);
  };

  isAssetDisabled(asset) {
    if (getParentChain(asset.key.toUpperCase()) == "EVM") {
      return false;
    } else if (getParentChain(asset.key.toUpperCase()) == "MISC") {
      return true;
    }
    return !asset.value;
  }

  select_Asset(asset, t) {
    if (getSupportedCoins(asset)?.length > 0) {
      this.createWalletOption = true;
    } else {
      this.createWalletOption = false;
    }
    this.selected_Asset = true;
    this.selected_asset_value = asset;

    this.members.map(
      (member) => (member.isDisabled = !member.keys[this.selected_asset_value])
    );
    this._members = this.members;
  }

  change_Asset() {
    this.selected_Asset = false;
    this.selected_asset_value = "";
    this.coin = "";
    this.selectedItem = null;
    this.selected = true;
    this.custom = false;
    this.selectedItem_custom = "";
    this.createWalletOption = false;
    this.selected_type.name = "";
  }

  onNextBtnClick() {
    if (this.stepNumber === 1) {
      this.walletCreateStep = MembersType.INITIATORS;
    }
    this.stepNumber = this.stepNumber + 1;
  }

  onBackButtonClick() {
    if (this.stepNumber === 2 && this.walletCreateStep === MembersType.INITIATORS) {
      // Reset data
      this.mpcWalletData = new MPCWalletData();
    }

    this.stepNumber--;
  }

  showFinalPopUp() {
    this.stepNumber = 4;
  }

  closeModal() {
    this.minimalApprovalReq = [1];
    this.member_Value_drop = 2;
    this.minApprove_val = 1;
    this.stepNumber = 1;
    this.members = [];
    this.selected_type = {};
    this.mpcWalletData = null;
    this.resetSendModel();
    this.memberservice.resetMember();
    this.modalService.dismissAll();
    this.notLinkedMemberSigner = [];
    this.notLinkedMemberInitiator = [];
  }

  async createWalletRequest() {
    let walletRequest = new WalletRequest(this.walletName, undefined, this.selected_asset_value,
      this.mpcWalletData.signers?.team?.id, this.mpcWalletData.initiators?.team?.id,
      this.mpcWalletData.initiators?.minMembersRequired, this.mpcWalletData.signers?.minMembersRequired)

    if (this.isMultisigHybridCustody) walletRequest.subOrgSigners = this.selectedMultisigSigners.map(signer => signer.id)

    try {
      await firstValueFrom(this.walletService.createWalletWith2FA(walletRequest, "CUSTODY"));
      this.segment.track("team-added-wallet", walletRequest).catch((err) => { });
      this.onNextBtnClick();
      this.members = [];
      this.memberservice.resetMember();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.modalService.dismissAll();
      if (err instanceof NoShowError) {
        return;
      }
      ErrorPrompt.fire({
        icon: "error",
        title: "Oops...",
        text: `${err?.error?.message || err?.message}`,
      });
    }
  }

  open(content) {
    this.getTeamList();
    this.minimalApprovalReq = [1];
    this.member_Value_drop = 2;
    this.minApprove_val = 1;
    this.selected_team = {};
    this.selected_type = {};
    this.notLinkedMemberInitiator = [];
    this.notLinkedMemberSigner = [];
    this.createSameAddress = false;
    this.coin = "";
    this.checkSignerSelection = false;

    this.mpcWalletData = new MPCWalletData();

    this.modalService
      .open(content, {
        windowClass: "new-group-wallet modal-custom-background",
        centered: true,
      })
      .result.then(
        (result) => {
          this.resetSendModel();
          // this.resetAmmountValidationErrormessages();
        },
        (reason) => {
          this.resetSendModel();
          // this.resetAmmountValidationErrormessages();
        }
      );
  }

  populateAllMultiSigSigners() {
    if (this.isMultisigHybridCustody) {
      this.httpservice
        .getAllMembers()
        .pipe()
        .subscribe((allmember) => {
          this.multisigSigners = allmember[0].members.filter((member) => member.providers && member.providers.includes("ledger"))
          this.multisigSigners.map(
            (member) => {
              const isKeyExists = member.keys[this.selected_asset_value];
              member.isDisabled = !isKeyExists;
              return member;
            }
          );
        });
    }

  }

  resetSendModel() {
    this.notLinkedMemberInitiator = [];
    this.notLinkedMemberSigner = [];
    this.custom = false;
    this.selected = true;
    this.set_members("1-2");
    this.shield = true;
    this.total_members_limit = false;
    this.stepNumber = 1;
    this.walletName = "";
    this.selected_Asset = false;
    this.memberservice.resetMember();
    this.multisigSigners = [];
    this.selectedMultisigSigners = [];

    this.httpservice
      .getAllMembers()
      .pipe()
      .subscribe((allmember) => {
        allmember.filter((t) => {
          this.members = t.members.filter((x) => {
            x.email === this.user.email;
            return x;
          });
          this.members = this.members.filter((e) => e.userType == 1);
        });
      });
  }

  custom_range() {
    this.stepNumber = 0;
    this.custom = true;
    this.custom_val = true;
    this.total_members = this.member_Value_drop;
    this.min_members = this.minApprove_val;
    this.min_member_val = this.min_members;
    this.ttl_member_val = this.total_members;
  }

  set_members(newValue) {
    this.total_members = parseInt(newValue.split(["-"])[1]);
    this.dropdownSettings.limitSelection = this.total_members;
    this.min_members = newValue.split(["-"])[0];
    this.selectedItem = newValue; // don't forget to update the model here
    this.selected = false;
    this.selectedItem_custom = "";
    this.custom_val = false;
    this.memberservice.resetMember();
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      limitSelection: this.total_members,
    });
    this.httpservice
      .getAllMembers()
      .pipe()
      .subscribe((allmember) => {
        allmember.filter((t) => {
          this.members = t.members.filter((x) => {
            x.email === this.user.email;
            return x;
          });
          this.members = this.members.filter((e) => e.userType == 1);
        });
      });
    this.total_members_limit = false;
    this.enable_preivew = false;
  }

  get getMembers() {
    this.members.map(
      (member) => (member.isDisabled = !member.keys[this.selected_asset_value])
    );
    return this.members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  get getMultisigSigners() {
    this.multisigSigners.map(
      (member) => {
        const isKeyExists = member.keys[this.selected_asset_value];
        member.isDisabled = !isKeyExists;
        return member;
      }
    );
    return this.multisigSigners.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  onMemberSelect(memberOption: any) {
    const signer = this.filteredMultisigSigners.find(member => member.id === memberOption.id)
    this.selectedMultisigSigners.push(signer);
  }
  onMemberDeSelect(memberOption: any) {
    this.selectedMultisigSigners = this.selectedMultisigSigners.filter(member => member.id !== memberOption.id);
  }

  get isMultisigHybridCustody() {
    return this.multisigCustodyType === OrgFeatureType.MULTISIG_HYBRID;
  }

  onInitiatorsSelect(initiators: MPCTeamConfig) {
    this.mpcWalletData.initiators = initiators;

    // Filter multisig signers for hybrid multisig custody
    if (this.isMultisigHybridCustody) {     
      this.filteredMultisigSigners = this.multisigSigners.filter((member) => !initiators.team?.teamMembers?.some((initiator) => initiator.userid === member.id));
    }
  }

  get isValidInitiators(): boolean {
    return this.mpcWalletData.initiators?.isValidTeam
  }

  isValidAsset(): boolean {
    let key = Object.keys(this.fltr.keys);
    if (key.includes(this.selected_asset_value)) {
      return true;
    }
    return false;
  }

  sortKeysAlphabetically() {
    this.fltr.keys = sortCoinsAlphabetically(this.fltr.keys);
  }

  returnZero() {
    return 0
  }

  transformMultisigSigners() {
    return this.selectedMultisigSigners.map((member) => ({
      user: {
        displayName: member.displayName,
        email: member.email,
        photoURL: member.phptoURL,
      }
    }));
  }

  /**
   * Determines whether the initiator button should be disabled for the hybrid mode.
   * The button will be disabled if the initiators are not valid or if no multisig signers are selected.
   * 
   * @returns A boolean value indicating whether the initiator button should be disabled.
   */
  disableInitiatorBtnForHybrid(): boolean {
    return !this.isValidInitiators || !this.selectedMultisigSigners.length;
  }


  getSupportedCoinGroupText = (key) => {
    return getSupportedCoinGroupText(key);
  };


}
