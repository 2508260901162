import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validator-card',
  templateUrl: './validator-card.component.html',
  styleUrls: ['./validator-card.component.scss']
})
export class ValidatorCardComponent implements OnInit {
  @Input() validatorData: any;
  @Input() dropdown_card: string;
  @Input() card_padding: any;
  @Input() card: any;
  @Input() stakeAmountData: any;
  
  constructor() { }

  ngOnInit(): void {
    console.log('data val',this.stakeAmountData);
    
  }

}
