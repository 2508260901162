<ng-template #exchangeModal let-modal>
    <div class="modal-header px-4">
        <div class="d-inline">
            <h2 class="modal-title">
                <span *ngIf="currentStep !== 1" (click)="prevStep()" class="cursor-pointer mt-1 mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                        <path
                            d="M6.77829 12.0464C6.93527 11.8885 7.02339 11.6749 7.02339 11.4522C7.02339 11.2295 6.93527 11.0159 6.77829 10.858L2.90123 6.98936H16.7501C16.9736 6.98936 17.188 6.90056 17.3461 6.7425C17.5041 6.58443 17.5929 6.37005 17.5929 6.14652C17.5929 5.92298 17.5041 5.70861 17.3461 5.55054C17.188 5.39248 16.9736 5.30368 16.7501 5.30368H2.92652L6.77829 1.4519C6.93812 1.29319 7.02836 1.07749 7.02915 0.852253C7.02994 0.627013 6.94122 0.410685 6.78251 0.250857C6.6238 0.0910299 6.4081 0.000795554 6.18286 5.23528e-06C5.95762 -0.000785083 5.74129 0.0879333 5.58146 0.246643L0.221002 5.61553C0.0794633 5.75769 0 5.95013 0 6.15073C0 6.35134 0.0794633 6.54378 0.221002 6.68594L5.58146 12.0464C5.65981 12.1254 5.75303 12.1881 5.85574 12.2309C5.95845 12.2737 6.06861 12.2957 6.17988 12.2957C6.29114 12.2957 6.40131 12.2737 6.50401 12.2309C6.60672 12.1881 6.69994 12.1254 6.77829 12.0464Z"
                            fill="#8D96B8" />
                    </svg>
                </span>
                New Exchange Connection
            </h2>
        </div>
        <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss('cross_click');">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="currentStep === 0" class="container-fluid step0">
            <table *ngFor="let item of [].constructor(4); let i = index">
                <tr>
                    <td style="width: 470px;height:50px">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                </tr>
                <tr>
                    <td style="width: 470px;height:50px">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </td>
                </tr>
            </table>
        </div>
        <div class="container-fluid" *ngIf="currentStep === 1">
            <div class="search-round" id="policies-search-id">
                <i class="icon-search" aria-hidden="true"></i>
                <input class="form-control" [(ngModel)]="exchangeSearchText" (ngModelChange)="filterExchanges()"
                    placeholder="Search Exchange" type="text" />
            </div>
            <div class="select-exchange">Select Exchange</div>
            <div class="container-fluid exchange-list-items">
                <ng-container *ngIf="isAppsLoading">
                    <table>
                        <tr *ngFor="let item of [].constructor(5); let i = index">
                            <td style="width: 470px;padding-left: 30px;height:50px">
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </td>
                        </tr>
                    </table>
                </ng-container>
                <ng-container *ngIf="!isAppsLoading">
                    <div *ngFor="let exchange1 of filteredExchangeList" class="row">
                        <div class="col-12 col-md-12 ex-item" (click)="exchangeSelected(exchange1)">
                            <img class="img-fluid for-light img-round-border" [src]="exchange1.exchangeIcon" alt="">
                            <div>
                                <span class="ex-ti">{{exchange1.name}}</span>
                                <span class="multiple-acc-support color-gry" *ngIf="exchange1?.has?.hasSubAccSupport">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="6.5819" stroke="#757E9D" stroke-width="0.836207" />
                                        <path d="M4.06836 6.9311L6.06836 8.9311L9.89595 5.10352" stroke="#757E9D"
                                            stroke-width="0.836207" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Sub Account Supported</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!filteredExchangeList.length" class="row">
                        <div class="col-12 col-md-12 no-exc-found">
                            No Exchanges Found.
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="container-fluid step2" *ngIf="currentStep === 2">
            <ng-container *ngIf="!(dynamicFields.length > 0) || loading">
                <table *ngFor="let item of [].constructor(4); let i = index">
                    <tr>
                        <td style="width: 470px;height:50px">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 470px;height:50px">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                        </td>
                    </tr>
                </table>
            </ng-container>

            <ng-container *ngIf="dynamicFields.length > 0 && !loading">
                <form [formGroup]="addExchangeForm">
                    <div class="info-container cursor-pointer" (click)="openHelp()">
                        <span class="learn-to-connect">Learn how to connect to
                            {{addExchangeForm.get('exchangeName').value}}</span>
                        <img class="link-icon" src="../../../../../assets/images/link.svg" alt="link" />
                    </div>
                    <!-- Disable MIAN account and enable SUB account -->
                    <div class="mt-2 pb-2 ex-name" *ngIf="mainAccountDetails.length > 0 && hasSubAccSupport">
                        <div class="form-check form-check-inline" [ngbTooltip]="mainAccountTooltip" placement="right">
                            <input class="form-check-input radio liminal-radio" type="radio"
                                formControlName="accountType" value="main" id="mainAccountRadio" disabled="true">
                            <label class="radio-label cursor-pointer" for="mainAccountRadio">Main Account</label>
                        </div>

                        <div class="form-check form-check-inline ml-4">
                            <input class="form-check-input radio liminal-radio" type="radio"
                                formControlName="accountType" value="sub" id="subAccountRadio">
                            <label class="radio-label cursor-pointer" for="subAccountRadio">
                                Sub Account
                            </label>
                        </div>
                    </div>
                    <!-- Disable SUB account and enable MIAN account -->
                    <div class="mt-2 pb-2 ex-name" *ngIf="!(mainAccountDetails.length > 0) || !hasSubAccSupport">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input radio liminal-radio" type="radio"
                                formControlName="accountType" value="main" id="mainAccountRadio2">
                            <label class="radio-label cursor-pointer" for="mainAccountRadio2">Main Account</label>
                        </div>

                        <div class="form-check form-check-inline ml-4" [ngbTooltip]="tooltipText" placement="right">
                            <input class="form-check-input radio liminal-radio" type="radio"
                                formControlName="accountType" value="sub" id="subAccountRadio2" disabled="true">
                            <label class="radio-label cursor-pointer" for="subAccountRadio2">
                                Sub Account
                            </label>
                        </div>
                    </div>
                    <div class="mt-2 pb-2 ex-name">
                        <div class="d-inline">
                            <label class="mb-2 text-muted font-weight-500">
                                Select Exchange
                            </label>
                        </div>
                        <div class="relative-position-container">
                            <img [src]="selectedExchange.exchangeIcon" class="icon-inside-input">
                            <input autocomplete="off" class="form-control" formControlName="exchangeName"
                                placeholder="Enter connection name" type="text" readonly>
                        </div>
                    </div>

                    <div class="mt-2 pb-2" *ngIf="isSubAccountSelected()">
                        <div class="d-inline">
                            <label class="mb-2 text-muted font-weight-500">
                                Main Account
                            </label>
                        </div>
                        <div class="mt-1" style="margin-left: auto !important;" ngbDropdown
                            #mainAccountInit="ngbDropdown" container="body">
                            <div class="relative-position-container">
                                <input autocomplete="off" class="form-control search-custom cursor-pointer"
                                    id="dropdownManual" ngbDropdownAnchor
                                    (focus)="mainAccountInit.open(); focusTotInit=true" (blur)="focusTotInit=false"
                                    type="text" placeholder="Select Main Account" formControlName="mainAccount"
                                    [value]="selectedMainAccount?.name" />
                                <img class="accordion-panel-icon exch-acc-icon"
                                    (click)="focusTotInit ? mainAccountInit.close() : mainAccountInit.open()"
                                    [class.active-icon]="focusTotInit" src="../../../../../assets/images/down_arrow.svg"
                                    alt="arrow" [style.transform]="focusTotInit ? 'rotate(180deg)' : 'rotate(0deg)'" />
                            </div>
                            <ul ngbDropdownMenu class="digital_asset py-0">
                                <div class="d-flex"
                                    *ngFor="let data of mainAccountDetails | teamListSearch : addExchangeForm.get('mainAccount').value"
                                    style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                    <button class="dropdown-content card brd_Box p-0 m-0"
                                        style="width: 100%; border: none !important;"
                                        (click)="OnMainAccountSelect(data)">
                                        <li ngbDropdownItem class="p-3 text-wrap">
                                            <div class="d-flex  flex-column">
                                                <div class="text-left text-margin">
                                                    {{data.name}}
                                                </div>
                                                <div class="ml-auto"></div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                            </ul>
                        </div>
                    </div>


                    <div class="mt-2 pb-3">
                        <div class="d-inline">
                            <label class="mb-2 text-muted font-weight-500">
                                Connection Name
                            </label>
                        </div>
                        <input autocomplete="off" class="form-control" formControlName="connectionName"
                            placeholder="Enter connection name" type="text" maxlength="50" pattern="[a-zA-Z0-9 ]*">
                    </div>
                    <div *ngFor="let field of dynamicFields" style="margin-bottom: 20px">
                        <span class="mb-2 text-muted font-weight-500">{{ field.label }}</span>
                        <div class="custom-search text-dark" style="margin-top: 4px">
                            <div class="position-relative">
                                <input class="form-control" [type]="field.encrypted ? 'password' : 'text'"
                                    [placeholder]="'Enter ' + field.label" [formControlName]="field.keyName"
                                    style="padding: 0px; padding-left: 10px; padding-right: 10px" />
                                <button class="btn btn-link secret-field" *ngIf="field.visible" 
                                    (click)="toggleVisibility(field)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-eye eye-icon" viewBox="0 0 16 16">
                                        <path
                                            d="M1.772 1.772a10.5 10.5 0 0 1 12.456 12.456l-1.5-1.5a8.5 8.5 0 0 0-9.456-9.456l-1.5-1.5zM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm-.5 2a.5.5 0 0 0 1 0V8a.5.5 0 0 0-1 0v.5zm7.865 1.904a6.5 6.5 0 0 0-9.77-.002l-.707.707a7.498 7.498 0 0 1 11.183 0l-.707-.705zM15 8a7.5 7.5 0 0 0-11-6.633 7.5 7.5 0 0 0-4.5 13.766.498.498 0 0 0 .201.142l1.356.678a2 2 0 0 0 2.682-2.682l-.678-1.356a.498.498 0 0 0-.142-.201A7.5 7.5 0 0 0 8 15a7.5 7.5 0 0 0 6.633-11zM8 13a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="add-con-btn d-flex align-items-end">
                        <button class="btn btn-primary active w-100" (click)="submitExchangeForm()"
                            [disabled]="!addExchangeForm.valid">Next</button>
                    </div>
                </form>
            </ng-container>
        </div>
        <div class="container-fluid step3" [ngClass]="{'step3-height': !mpcWalletData.initiators?.team}"
            *ngIf="currentStep === 3">
            <div class="modal-body p-4">
                <app-mobile-teams [type]="MembersType.INITIATORS" [teamsListData]="teamsListData"
                    [orgProfile]="orgProfile" (onTeamConfigUpdated)="onInitiatorsSelect($event)"
                    [teamConfig]="mpcWalletData?.initiators" [component]="'exchange'"></app-mobile-teams>
            </div>
            <div class="modal-footer border-0 "
                [ngClass]="{'step3-next-btn': !mpcWalletData.initiators?.team, 'step3-next-btn-reset': mpcWalletData.initiators?.team }">
                <button [disabled]="!isValidInitiators" class="btn btn-primary w-100 " data-original-title="" title=""
                    (click)="onNextOverview()" type="submit">
                    Next
                </button>
            </div>
        </div>

        <div class="container-fluid step3" [ngClass]="{'step3-height': !mpcWalletData.signers?.team}"
            *ngIf="currentStep === 4">
            <div class="modal-body p-4">
                <app-mobile-teams [type]="MembersType.SIGNERS" [teamsListData]="teamsListData" [orgProfile]="orgProfile"
                    (onTeamConfigUpdated)="onSignersSelect($event)" [teamConfig]="mpcWalletData?.signers"
                    [component]="'exchange'"></app-mobile-teams>
            </div>
            <div class="modal-footer border-0"
                [ngClass]="{'step3-next-btn': !mpcWalletData.signers?.team, 'step3-next-btn-reset': mpcWalletData.signers?.team }">
                <button [disabled]="!isValidSigners" class="btn btn-primary w-100" data-original-title="" title=""
                    (click)="onNextOverview()" type="submit">
                    Next
                </button>
            </div>
        </div>

        <ng-container *ngIf="currentStep === 5">
            <div class="step4">
                <span class="text-muted p-2">Exchange</span>
                <div class="exchange-details card-border mt-2">
                    <img [src]="selectedExchange.exchangeIcon"
                        class="exch-detail-icon img-fluid for-light img-round-border">
                    <div class="exch-info">
                        <span class="exch-conn-name">{{addExchangeForm.get('connectionName').value}}</span>
                        <span class="exch-name text-muted">{{addExchangeForm.get('exchangeName').value}}</span>
                    </div>
                </div>

                <div class="card card-border py-3 px-2">
                    <ng-container *ngIf="mpcWalletData.initiators?.team">
                        <div class="d-flex">
                            <div class="d-flex flex-column p-2">
                                <span class="font-weight-500 mb-1">Initiators</span>
                                <span class="font-weight-500 text-muted">{{mpcWalletData.initiators?.team.name}}</span>
                            </div>
                            <span
                                class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{mpcWalletData.initiators?.selectedMembersCountText}}</span>
                        </div>
                        <app-member-list [members]="mpcWalletData.initiators?.team?.teamMembers"
                            [removeOuterBorder]="true" [commonMembers]="getMpcCommonMembers()"></app-member-list>
                    </ng-container>

                    <ng-container *ngIf="mpcWalletData.signers?.team">
                        <div class="d-flex mt-4">
                            <div class="d-flex flex-column p-2">
                                <span class="font-weight-500 mb-1">Approvers</span>
                                <span class="font-weight-500 text-muted">{{mpcWalletData.signers?.team.name}}</span>
                            </div>
                            <span
                                class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{mpcWalletData.signers?.selectedMembersCountText}}</span>
                        </div>
                        <app-member-list [members]="mpcWalletData.signers?.team?.teamMembers" [removeOuterBorder]="true"
                            [commonMembers]="getMpcCommonMembers()"></app-member-list>
                    </ng-container>
                </div>
                <div *ngIf="getMpcCommonMembers().length" class="d-flex info-box align-items-center mt-3">
                    <i class="icon-info mr-2"></i>
                    <span>Highlighted member(s) are part of both teams</span>
                </div>
                <div *ngIf="getMpcCommonMembers().length" class="d-flex align-items-center mt-3 ">
                    <input class="checkbox_animated" type="checkbox" [(ngModel)]="isCommonSignersInitiators">
                    <span class="mt-1">Allow initiators to approve their own transactions</span>
                </div>
            </div>
            <div class="modal-footer border-0">
                <button class="btn btn-primary  w-100" data-original-title="" title=""
                    [disabled]="(getMpcCommonMembers().length  && !isCommonSignersInitiators)" type="submit"
                    (click)="addExchangeConnection()" style="height:40px">
                    <div class="loader-box align-loader" *ngIf="submitAccountInfo">
                        <div class="loader-15 bg-white"></div>
                    </div>
                    <div *ngIf="!submitAccountInfo">
                        Add Connection</div>
                </button>
            </div>
        </ng-container>

    </div>
</ng-template>

<ng-template #failureExchangeModal let-modal>
    <div class="modal-header border-0 px-4 mb-5">
        <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss('cross_click');">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body txt-align mt-5">
        <div class="bg-img">
            <img src="../../../../../assets/images/error-face.svg" alt="error-icon" height="120">
        </div>
        <div class="success mt-4">{{messageConstants?.title}}</div>
        <div class="suc-msg mt-2">{{messageConstants?.subTitle}}</div>

        <div class="mt-4 mb-5 txt-align support-txt">
            <div>{{messageConstants?.text1}} <span class="txt-style"
                    (click)="modal.dismiss('try_again')">{{messageConstants?.tryAgain}}.</span>
            </div>
            <div class="mt-2 mb-5">{{messageConstants?.text2}} <span class="txt-style">{{messageConstants?.supportTeam}}
                    .</span>
            </div>
        </div>
    </div>
</ng-template>