import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthServiceJWT } from '../../services/auth.service';
import { Wallet } from '@entities/wallet';
import TrezorConnect from 'trezor-connect';
import { WalletserviceService } from '../../services/walletservice.service';
import { Router } from '@angular/router';
import * as bip32path from 'bip32-path';
import { getWalletType } from 'src/app/shared/helpers/pathUtils';
import { HttpService } from 'src/app/shared/services/http.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import Swal from 'sweetalert2';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SegmentService } from 'ngx-segment-analytics';
import { PubKeys } from '@entities/Pubkey';
import Eth from '@ledgerhq/hw-app-eth';
import Trx from '@ledgerhq/hw-app-trx';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { newPolkadotApp } from '@zondax/ledger-substrate';
import Str from '@ledgerhq/hw-app-str';
import getPublicKey, { Coin, Coins } from '../../helpers/trezor/getPubkey';
import {
  getNewTransportTRON,
  getNewWebUSBTransport as getNewTransport,
} from '../../helpers/ledger/getTransports';
import {
  getCurrentApp,
  getInstalledApp,
  linkBCHLedger,
  linkBTCLedger,
  linkCosmosLedger,
  linkDOGELedger,
  linkETHLedger,
  linkLTCLedger,
  linkSolanaLedger,
  linkXRPLedger,
} from '@helpers/ledger';
import { checkDeviceCompatibleWithChain } from '@helpers/HWdevices';
import { orgType } from '@entities/members';
import { DataService } from '../../services/data.service';
import { showLinkDevice } from '@helpers/org.utils';
import { UserService } from '../../services/user.service';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { ChooseOrganizationComponent } from 'src/app/components/choose-organization/choose-organization.component';
import { OrganizationService } from '../../services/organization.service';

require('bs58check');

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  public UserName: any = '';
  public UserProfilePic: any = '';
  connectionStatus: string = 'Please connect and unlock your trezor device.';
  closeResult = '';
  messagelog: string;
  trezorLabel: string = '';
  trezorType: string = '';
  isLinking = false;
  deviceName: string;
  deviceImg: string;
  IsMobile = true;
  Iswebusb = true;
  linking_btc = false;
  linking_eth = false;
  linking_doge = false;
  linking_ltc = false;
  linking_bch = false;
  linking_trx = false;
  linking_xrp = false;
  linking_cosmos = false;
  linking_dot = false;
  linking_sol = false;
  linking_xlm = false;
  ledger_linking_trx = false;
  ledger_linking_xrp = false;
  ledger_linking_eth = false;
  ledger_linking_bch = false;
  ledger_linking_ltc = false;
  ledger_linking_cosmos = false;
  ledger_linking_dot = false;
  ledger_linking_sol = false;
  ledger_linking_xlm = false;
  ledger_linking_doge = false;
  ng_xrp = false;
  ng_trx = false;
  ng_eth = false;
  ng_bch = false;
  ng_ltc = false;
  ng_cosmos = false;
  ng_dot = false;
  ng_sol = false;
  ng_xlm = false;
  ng_doge = false;
  linking_Ledger_Error = false;
  linking_Ledger_Message = '';
  isFindTrezor = false;
  coin_name = '';
  step1 = true;
  step2 = false;
  finish_btc = false;
  deviceselected = false;
  trezorImg: string = '../../../assets/images/other-images/t1andt2.png';
  wallets: Array<Wallet>;
  readyToUse: boolean;
  backUp: boolean;
  liminalUnderstand: boolean;
  component_for = 'menu';
  @Input() link_component_for: string;
  webview = false;
  mobileview = true;
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  selectedItems = [];
  linked_coin: any = [];
  state = 'initial';
  isOwner: any;
  userType: any;
  userProfile: any = {};
  orgType = orgType;
  userId: number;
  showLinkDevice = showLinkDevice;

  @ViewChild('successModal') successModal: ElementRef;
  @ViewChild('errorModal') errorModal: ElementRef;

  constructor(
    public authService: AuthServiceJWT,
    public httpService: HttpService,
    public walletService: WalletserviceService,
    private dataService: DataService,
    public ngZone: NgZone,
    public router: Router,
    private logger: LoggerService,
    private modalService: NgbModal,
    private segment: SegmentService,
    private userService: UserService,
    public orgSvc: OrganizationService
  ) {
    this.init();
    try {
      let user = this.authService.getUser;
      this.UserProfilePic = user.photoURL;
    } catch (error) {
      console.error(error);
    }
    this.wallets = walletService.getAllWallets();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'coin_id',
      textField: 'coin',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
    };
  }

  async init() {
    const profile = this.dataService.getUserProfile.getValue();
    this.userId = profile.id;
    this.isOwner = profile.organizations[0].isowner;
    this.userType = profile.organizations[0].type;
    this.userProfile = profile.organizations[0];

    this.httpService.getUserById(this.userId).subscribe((userData) => {
      this.UserName = userData.user.displayName;
    });

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.UserName = userData.user.displayName;
      }
    });
  }

  onItemSelect(item: any) {
    checkDeviceCompatibleWithChain(item.coin, this.deviceName);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  getNewTransport() {
    getNewTransport();
  }

  logout() {
    this.authService.logout();
  }

  getAddress() {
    let coinPath = 0;
    let coin: Coins = 'btc';
    //@ts-ignore
    if (environment.env == 'dev') {
      coinPath = 1;
      coin = 'test';
    }
    getPublicKey(coinPath, coin).then(async (response) => {
      if (response.success) {
        for (let i = 0; i < response.payload.length; i++) {
          try {
            let payLoad = response.payload[i];
            if (payLoad.serializedPath.includes("m/44'/" + coinPath + "'") || !payLoad.xpubSegwit) {
              let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
              wallet.xpub = payLoad.xpub;
              wallet.path = payLoad.serializedPath;
              wallet.config = '1of1';
              wallet.type = getWalletType(
                bip32path.fromString(payLoad.serializedPath).toPathArray()
              );
              wallet.id = wallet.xpub;
              wallet.coin = 'BTC';
              await this.httpService.saveKey(wallet, 'trezor').toPromise();
            } else {
              let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
              wallet.xpub = payLoad.xpubSegwit;
              wallet.path = payLoad.serializedPath;
              wallet.id = wallet.xpub;
              wallet.type = getWalletType(
                bip32path.fromString(payLoad.serializedPath).toPathArray()
              );
              wallet.config = '1of1';
              wallet.coin = 'BTC';
              await this.httpService.saveKey(wallet, 'trezor').toPromise();
            }
          } catch (error) {
            this.logger.error(error, error);
            console.error(error);
          }
        }
        Swal.fire({
          title: 'Success!',
          text: 'Pubkeys have been exported!!',
          icon: 'success',
          confirmButtonText: 'Cool',
        });
        let allWallets = this.walletService.getAllWallets();
        let shouldNavigate = true;
        for (let i = 0; i < allWallets.length; i++) {
          if (allWallets[i].config != '1of1') shouldNavigate = false;
        }
        if (shouldNavigate) {
          this.ngZone.run(() => {});
        } else {
          this.ngZone.run(() => {});
        }
      }
    });
  }

  open(content) {
    this.modalService.dismissAll();
    try {
      this.segment
        .track('link-device-initiated', this.authService.getUser)
        .then(() => console.log('Link Device Initiated'))
        .catch((_err) => {});
    } catch (err) {
      console.error(err);
    }
    this.modalService
      .open(content, {
        windowClass: 'linkDevice  modal-custom-background',
        centered: true,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.resetLinkDeviceModel();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.resetLinkDeviceModel();
        }
      );
  } //end
  private getDismissReason(reason: any): string {
    this.resetLinkDeviceModel();

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  resetLinkDeviceModel() {
    this.step1 = true;
    this.step2 = false;
    this.readyToUse = false;
    this.backUp = false;
    this.isLinking = false;
    this.liminalUnderstand = false;
    this.linking_btc = false;
    this.linking_eth = false;
    this.linking_doge = false;
    this.linking_trx = false;
    this.linking_xrp = false;
    this.linking_cosmos = false;
    this.linking_dot = false;
    this.linking_Ledger_Error = false;
    this.linking_Ledger_Message = '';
    this.deviceName = '';
    this.deviceImg = '';
    this.deviceselected = false;
    this.linking_bch = false;
    this.selectedItems = [];
    this.linked_coin = [];
  }

  async linkTrezorDevice(coinNames: Coin) {
    this.isLinking = true;
    try {
      this.segment
        .track(`link-device-${coinNames.coinName}-attempted`, this.authService.getUser)
        .then(() => console.log(`Link Device ${coinNames.coinName} Success`))
        .catch((_err) => {});
    } catch (err) {
      this.logger.error(err, err);
    }
    let coinPath = coinNames.coinPath;
    let coin = coinNames.coinName;

    let response = await getPublicKey(coinPath, coin);
    if (response.success) {
      console.log(response);
      for (let i = 0; i < response.payload.length; i++) {
        try {
          let payLoad = response.payload[i];
          if (payLoad.serializedPath.includes("m/44'/" + coinPath + "'") || !payLoad.xpubSegwit) {
            let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
            wallet.xpub = payLoad.xpub;
            wallet.path = payLoad.serializedPath;
            wallet.id = wallet.xpub;
            wallet.type = getWalletType(bip32path.fromString(payLoad.serializedPath).toPathArray());
            wallet.config = '1of1';
            wallet.coin = coinNames.coinName.toUpperCase();
            await this.httpService.saveKey(wallet, 'trezor').toPromise();
          } else {
            let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
            wallet.xpub = payLoad.xpubSegwit;
            wallet.path = payLoad.serializedPath;
            wallet.id = wallet.xpub;
            wallet.type = getWalletType(bip32path.fromString(payLoad.serializedPath).toPathArray());
            wallet.config = '1of1';
            wallet.coin = coinNames.coinName.toUpperCase();
            await this.httpService.saveKey(wallet, 'trezor').toPromise();
          }
        } catch (error) {
          this.step1 = true;
          this.step2 = false;
          this.logger.error(error, error);
          this.segment
            .track(`link-device-${coinNames.coinName}-failed`, this.authService.getUser)
            .then(() => console.log(`Link Device ${coinNames.coinName} Failed`))
            .catch((err) => {
              this.logger.error(err, err);
              console.error(err);
            });
          console.error(error);
        }
      }
      this.linked_coin.push(coinNames.coinName);
      this.segment
        .track(`link-device-${coinNames.coinName}-success`, this.authService.getUser)
        .then(() => console.log(`Link Device ${coinNames.coinName} Success`))
        .catch((err) => {
          this.logger.error(err, err);
          console.log(err);
        });
      this.segment
        .track('link-device-success', this.authService.getUser)
        .then(() => console.log('Link Device Success'))
        .catch((err) => {
          this.logger.error(err, err);
          console.error(err);
        });

      let allWallets = this.walletService.getAllWallets();
      let shouldNavigate = true;
      for (let i = 0; i < allWallets.length; i++) {
        if (allWallets[i].config != '1of1') shouldNavigate = false;
      }
      if (shouldNavigate) {
        this.ngZone.run(() => {});
      } else {
        this.ngZone.run(() => {});
      }
    } else {
      this.logger.error('failed to link coin', response);
      console.log('failed to log coin', this.coin_name, coinNames);
      this.coin_name = coinNames.capitalize();
      this.error_message(this.coin_name);
    }
    return response;
  }

  async linkbtcDevice(): Promise<any> {
    this.linking_btc = true;
    const coinNames = new Coin('btc');
    const response = await this.linkTrezorDevice(coinNames);
    if (response.success) {
      this.linking_btc = false;
    }
    this.finish_btc = true;
    return response;
  }

  error_message(coin_name) {
    this.coin_name = coin_name;
    let title = 'Oops, we could not link your device for ' + coin_name + ' accounts.';
    let message = 'You can try again later to link the same.';
    ErrorPrompt.fire({
      title: title,
      text: message,
      icon: 'error',
      timer: 5000,
    });
  }

  /****************************
   * Link ETH Device
   */
  async linkethDevice(): Promise<any> {
    this.linking_btc = false;
    this.linking_eth = true;
    this.isLinking = true;
    try {
      this.segment
        .track('link-device-eth-attempted', this.authService.getUser)
        .then(() => console.log('Link Device ETH Success'))
        .catch((_err) => {});
    } catch (err) {
      this.logger.error(err, err);
    }
    let coinPath = 0;
    if (environment.env == 'dev') {
      coinPath = 1;
    }
    let response = await TrezorConnect.ethereumGetAddress({
      path: "m/44'/60'/0'/0/0",
      showOnTrezor: false,
    });
    console.log('EE' + JSON.stringify(response));
    if (response.success) {
      this.linking_eth = false;
      try {
        let payLoad;
        payLoad = response.payload;
        this.linked_coin.push('eth');
        if (payLoad.serializedPath.includes("m/44'/" + coinPath + "'") || !payLoad.xpubSegwit) {
          let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
          wallet.xpub = payLoad.address;
          wallet.path = payLoad.serializedPath;
          wallet.id = wallet.xpub;
          wallet.coin = 'ETH';
          wallet.type = 'address';
          wallet.provider = 'trezor';
          await this.httpService.saveKey(wallet, 'trezor').toPromise();
        } else {
          let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
          wallet.xpub = payLoad.xpubSegwit;
          wallet.path = payLoad.serializedPath;
          wallet.id = wallet.xpub;
          wallet.coin = 'ETH';
          wallet.type = 'address';
          wallet.address = payLoad.address;
          await this.httpService.saveKey(wallet, 'trezor').toPromise();
        }
      } catch (error) {
        this.logger.error(error, error);
        this.segment
          .track('link-device-eth-failed', this.authService.getUser)
          .then(() => console.log('Link Device ETH Failed'))
          .catch((_err) => {});
        console.error(error);
      }

      // Swal.fire({
      //   title: 'Success!',
      //   text: 'Pubkeys have been exported!!',
      //   icon: 'success',
      //   confirmButtonText: 'Cool'
      // })

      this.segment
        .track('link-device-eth-success', this.authService.getUser)
        .then(() => console.log('Link Device ETH Success'))
        .catch((_err) => {});

      let allWallets = this.walletService.getAllWallets();
      let shouldNavigate = true;
      for (let i = 0; i < allWallets.length; i++) {
        if (allWallets[i].config != '1of1') shouldNavigate = false;
      }
      if (shouldNavigate) {
        this.ngZone.run(() => {});
      } else {
        this.ngZone.run(() => {});
      }
    } else {
      this.coin_name = 'Ether';
      this.error_message(this.coin_name);
    }
    return response;
  }

  async linkDogeDevice(): Promise<any> {
    this.linking_eth = false;
    this.linking_ltc = false;
    this.linking_doge = true;
    const coinNames = new Coin('doge');
    const response = await this.linkTrezorDevice(coinNames);
    if (response.success) {
      this.linking_doge = false;
    }
    return response;
  }

  async linkLTCDevice(): Promise<any> {
    const coinNames = new Coin('ltc');
    return await this.linkTrezorDevice(coinNames);
  }

  /**************************************************************
   This is for ETH link device with **Ledger**
   ****************************************************************/
  linkETHLedgerDevice = async () => {
    await linkETHLedger.call(this);
  };

  async getethledger(transport) {
    try {
      const eth = new Eth(transport);
      const result = await eth.getAddress("44'/60'/0'/0/0");
      console.log(result, 'eth.getAddress');
      return result;
    } catch (e) {
      await this.delay(5000);
      transport = await getNewTransport();
      const eth = new Eth(transport);
      const result = await eth.getAddress("44'/60'/0'/0/0");
      console.log(result, 'xrp.getAddress');
      return result;
    }
  }

  /**************************************************************
   This is for XRP link device with **Ledger**
   ****************************************************************/
  linkXRPLedgerDevice = async () => {
    await linkXRPLedger.call(this);
  }; //end

  /**************************************************************
   This is for BCH link device with **Ledger**
   ****************************************************************/
  linkBCHLedgerDevice = async () => {
    await linkBCHLedger.call(this);
  }; //end

  /**************************************************************
   This is for LTC link device with **Ledger**
   ****************************************************************/
  linkLTCLedgerDevice = async () => {
    await linkLTCLedger.call(this);
  }; //end

  /**************************************************************
   This is for DOGE link device with **Ledger**
   ****************************************************************/
  linkDOGELedgerDevice = async () => {
    await linkDOGELedger.call(this);
  }; //end

  /**************************************************************
   This is for Cosmos link device with **Ledger**
   ****************************************************************/
  linkCosmosLedgerDevice = async () => {
    await linkCosmosLedger.call(this);
  }; //end

  /**************************************************************
   This is for DOT link device with **Ledger**
   ****************************************************************/
  linkDotLedgerDevice = async () => {
    this.ng_dot = false;

    this.linking_Ledger_Error = true;
    this.linking_Ledger_Message = 'Device link in progress..Please wait';
    let transport = await getNewTransport();
    try {
      const polkaapp = newPolkadotApp(transport);
      const result = await polkaapp.getAddress(
        environment.dot_config.DOT.LEDGER_DEFAULT_ACCOUNT,
        environment.dot_config.DOT.LEDGER_DEFAULT_CHANGE,
        environment.dot_config.DOT.LEDGER_DEFAULT_INDEX,
        false
      );
      this.logger.info('polkadot pubkey & address ' + result);

      if (result) {
        this.linking_dot = false;
        this.linked_coin.push('dot');
        let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
        wallet.xpub = result.address;
        wallet.path = environment.dot_config.DOT.path;
        wallet.id = result.address;
        wallet.coin = environment.dot_config.DOT.nativecoin;
        wallet.type = 'address';
        wallet.provider = 'ledger';
        await this.httpService.saveKey(wallet, 'ledger').toPromise();
        wallet.xpub = result.pubKey;
        wallet.id = result.pubKey;
        wallet.type = 'pubkey';
        wallet.coin = environment.dot_config.DOT.nativecoin;
        await this.httpService.saveKey(wallet, 'ledger').toPromise();
      } else {
        this.coin_name = 'DOT';
        this.error_message(this.coin_name);
      }

      this.segment
        .track('link-device-xrp-success', this.authService.getUser)
        .then(() => console.log('Link Device DOT Success'))
        .catch((_err) => {});
    } catch (err) {
      this.step1 = true;
      this.step2 = false;
      this.logger.error(err);
      this.segment
        .track('link-device-dot-fail', this.authService.getUser)
        .then(() => console.log('Link Device DOT Fail'))
        .catch((_err) => {});

      if (err.name == 'TransportStatusError') {
        ErrorPrompt.fire({
          title: 'Fail!',
          text: 'Error while exporting Pubkeys!!',
        });
      } else {
        // alert(err.message);
      }
    }
    let currentapp = await getCurrentApp(transport);
    if (currentapp.name != 'BOLOS') {
      //Exit app
      await transport.send(0xb0, 0xa7, 0x00, 0x00);
      await this.delay(2000);
    }
    this.ledger_linking_dot = false;
    this.linking_dot = false;
    this.linking_Ledger_Error = false;

    //this needs to be changed for the last coin to be linked
  }; //end

  /**************************************************************
   This is for trx link device with **Ledger**
   ****************************************************************/
  linkTRXLedgerDevice = async () => {
    this.linking_Ledger_Message = 'Device link in progress..Please wait';
    var transport = await getNewTransportTRON();
    this.ng_trx = false;
    this.linking_eth = false;
    this.linking_doge = false;
    this.linking_btc = false;
    this.linking_xrp = false;
    this.linking_cosmos = false;
    this.linking_trx = true;
    this.isLinking = true;
    try {
      this.segment
        .track('link-device-trx-attempted', this.authService.getUser)
        .then(() => console.log('Link Device TRX attempted'))
        .catch((_err) => {});

      this.linking_Ledger_Error = true;
      const trx = new Trx(transport);
      var trxpath = "44'/195'/0'/0/0";
      let Address = await trx.getAddress(trxpath);
      let address = Address.address;
      if (Address) {
        this.linking_trx = false;
        this.linked_coin.push('trx');
        let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
        wallet.xpub = address;
        wallet.path = 'm/' + trxpath;
        wallet.id = address;
        wallet.coin = 'TRX';
        wallet.type = 'address';
        wallet.provider = 'ledger';
        // console.log("waller========== ", wallet)
        await this.httpService.saveKey(wallet, 'ledger').toPromise();
      } else {
        this.coin_name = 'TRX';
        this.error_message(this.coin_name);
      }

      try {
        this.segment
          .track('link-device-trx-success', this.authService.getUser)
          .then(() => console.log('Link Device TRX Success'))
          .catch((_err) => {});
        this.linked_coin.push('trx');
      } catch (err) {
        this.logger.error(err, err);
        console.log(err);
      }
    } catch (err) {
      this.step1 = true;
      this.step2 = false;
      console.log(err);
      this.logger.error(err);
      this.segment
        .track('link-device-trx-fail', this.authService.getUser)
        .then(() => console.log('Link Device TRX Fail'))
        .catch((_err) => {});
      if (err.name == 'TransportStatusError') {
        ErrorPrompt.fire({
          title: 'Fail!',
          text: 'Error while exporting Pubkeys!!',
        });
      } else {
        // alert(err.message);
      }
    }
    this.ledger_linking_trx = false;
    //let currentapp = await this.getCurrentApp(transport);
    //if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
    await transport.close();
    //}
    //this needs to be changed for the last coin to be linked
  }; //end

  /**************************************************************
   This is for Solana link device with **Ledger**
   ****************************************************************/
  linkSolanaLedgerDevice = async () => {
    await linkSolanaLedger.call(this);
  }; //end

  /**************************************************************
   This is for XLM link device with **Ledger**
   ****************************************************************/
  linkXLMLedgerDevice = async () => {
    this.ng_xlm = false;
    this.linking_eth = false;
    this.linking_doge = false;
    this.linking_btc = false;
    this.linking_xrp = false;
    this.linking_cosmos = false;
    this.linking_trx = false;
    this.linking_sol = false;
    this.linking_xlm = true;
    this.isLinking = true;

    this.linking_Ledger_Error = true;
    this.linking_Ledger_Message = 'Device link in progress..Please wait';
    let transport = await getNewTransportTRON();
    try {
      //  let transport = await Transportwebusb.create();
      const str = new Str(transport);
      let result = await str.getPublicKey("44'/148'/0'");
      let pubkey = result.publicKey;

      //  let address = result.;
      if (result) {
        this.linking_xlm = false;
        this.linked_coin.push('xlm');
        let wallet: PubKeys = { xpub: '', path: '', id: '', type: '', name: '', config: '' };
        wallet.xpub = pubkey;
        wallet.path = environment.xlm_config.XLM.path || "44'/148'/0'";
        wallet.id = pubkey;
        wallet.coin = 'XLM';
        wallet.type = 'address';
        wallet.provider = 'ledger';

        await this.httpService.saveKey(wallet, 'ledger').toPromise();
      } else {
        this.coin_name = 'XLM';
        this.error_message(this.coin_name);
      }

      this.segment
        .track('link-device-xlm-success', this.authService.getUser)
        .then(() => console.log('Link Device XLM Success'))
        .catch((_err) => {});
    } catch (err) {
      this.step1 = true;
      this.step2 = false;
      this.logger.error(err);
      this.segment
        .track('link-device-xlm-fail', this.authService.getUser)
        .then(() => console.log('Link Device XLM Fail'))
        .catch((_err) => {});
      if (err.name == 'TransportStatusError') {
        ErrorPrompt.fire({
          title: 'Fail!',
          text: 'Error while exporting Pubkeys!!',
        });
      } else {
        // alert(err.message);
      }
    }
    // let currentapp = await this.getCurrentApp(transport);
    // if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
    //}
    this.ledger_linking_xlm = false;
    this.linking_xlm = false;
    this.ng_xlm = false;
    this.linking_Ledger_Error = false;
  }; //end

  /**************************************************************
   This is for BTC link device with **Ledger**
   ****************************************************************/

  linkBTCLedgerDevice = async (transport, coinname) => {
    await linkBTCLedger.call(this, transport, coinname);
  };

  async linkBCHDevice(): Promise<any> {
    const coinNames = new Coin('bch');
    const response = await this.linkTrezorDevice(coinNames);
    this.isLinking = false;
    return response;
  }

  async linkDevice() {
    if (this.deviceName == 'Ledger') {
      this.deviceselected = true;
      var transport = await getNewTransport();
      this.isLinking = true;
      //const res = await transport.send(0xe0, 0x01, 0x00, 0x00);
      // transport.setAllowAutoDisconnect(false)
      const apps = await getInstalledApp.call(this, transport);
      console.log('apps', apps);

      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].coin == 'BTC') {
          var objAppBTC;
          if (environment.env == 'dev') {
            objAppBTC = apps.find((o) => o.name === 'Bitcoin Test Legacy');
            if (!objAppBTC) objAppBTC = apps.find((o) => o.name === 'Bitcoin Test');
          } else {
            objAppBTC = apps.find((o) => o.name === 'Bitcoin Legacy');
            if (!objAppBTC) objAppBTC = apps.find((o) => o.name === 'Bitcoin');
          }
        }

        if (this.selectedItems[i].coin == 'ETH') {
          var objAppETH = apps.find((o) => o.name === 'Ethereum');
        }
        if (this.selectedItems[i].coin == 'XRP') {
          var objAppXRP = apps.find((o) => o.name === 'XRP');
        }
        //@ts-ignore
        if (this.selectedItems[i].coin == 'ATOM') {
          var objAppCosmos = apps.find((o) => o.name === 'Cosmos');
        }
        if (this.selectedItems[i].coin == 'TRX') {
          var objAppTRX = apps.find((o) => o.name === 'Tron');
        }
        if (this.selectedItems[i].coin == 'BCH') {
          var objAppBCH = apps.find((o) => o.name === 'Bitcoin Cash');
        }
        if (this.selectedItems[i].coin == 'LTC') {
          var objAppLTC = apps.find((o) => o.name === 'Litecoin');
        }
        if (this.selectedItems[i].coin == 'DOGE') {
          var objAppDOGE = apps.find((o) => o.name === 'Dogecoin');
        }
        if (this.selectedItems[i].coin == 'DOT') {
          var objAppDOT = apps.find((o) => o.name === 'Polkadot');
        }
        if (this.selectedItems[i].coin == 'SOL') {
          var objAppSOL = apps.find((o) => o.name === 'Solana');
        }
        if (this.selectedItems[i].coin == 'XLM') {
          var objAppXLM = apps.find((o) => o.name === 'Stellar');
        }
      }

      if (objAppBTC) {
        await this.linkBTCLedgerDevice(transport, objAppBTC.name);
      }
      if (objAppETH) {
        await this.delay(2000);
        let CoinName = 'Ethereum';
        this.linking_eth = true;
        this.linking_doge = false;
        this.linking_btc = false;
        this.linking_xrp = false;
        this.linking_cosmos = false;
        this.isLinking = true;
        this.segment
          .track('link-device-eth-attempted', this.authService.getUser)
          .then(() => console.log('Link Device ETH attempted'))
          .catch((_err) => {});

        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';

        this.linking_eth = true;
        this.ledger_linking_eth = true;
        this.ng_eth = true;
        while (this.ledger_linking_eth) {
          await this.delay(2000);
        }
      }
      if (objAppXRP) {
        await this.delay(2000);

        let CoinName = 'XRP';
        this.linking_eth = false;
        this.linking_doge = false;
        this.linking_btc = false;
        this.linking_trx = false;
        this.isLinking = true;
        this.segment
          .track('link-device-xrp-attempted', this.authService.getUser)
          .then(() => console.log('Link Device XRP attempted'))
          .catch((_err) => {});
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_xrp = true;
        this.ledger_linking_xrp = true;
        this.linking_xrp = true;
        while (this.ledger_linking_xrp) {
          await this.delay(2000);
        }
      }

      if (objAppBCH) {
        await this.delay(2000);
        let CoinName = 'Bitcoin Cash';
        this.linking_eth = false;
        this.linking_doge = false;
        this.linking_btc = false;
        this.linking_trx = false;
        this.linking_cosmos = false;
        this.isLinking = true;
        this.segment
          .track('link-device-bch-attempted', this.authService.getUser)
          .then(() => console.log('Link Device BCH attempted'))
          .catch((_err) => {});

        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_bch = true;
        this.ledger_linking_bch = true;
        this.linking_bch = true;
        while (this.ledger_linking_bch) {
          await this.delay(2000);
        }
      }
      if (objAppLTC) {
        await this.delay(2000);
        let CoinName = 'Litecoin';
        this.linking_eth = false;
        this.linking_doge = false;
        this.linking_btc = false;
        this.linking_trx = false;
        this.linking_cosmos = false;
        this.linking_bch = false;
        this.isLinking = true;

        this.segment
          .track('link-device-ltc-attempted', this.authService.getUser)
          .then(() => console.log('Link Device LTC attempted'))
          .catch((_err) => {});

        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_ltc = true;
        this.ledger_linking_ltc = true;
        this.linking_ltc = true;
        while (this.ledger_linking_ltc) {
          await this.delay(2000);
        }
      }

      if (objAppDOGE) {
        await this.delay(2000);
        let CoinName = 'Dogecoin';
        this.linking_eth = false;
        this.linking_ltc = false;
        this.linking_btc = false;
        this.linking_trx = false;
        this.linking_cosmos = false;
        this.linking_bch = false;
        this.isLinking = true;

        this.segment
          .track('link-device-doge-attempted', this.authService.getUser)
          .then(() => console.log('Link Device DOGE attempted'))
          .catch((_err) => {});

        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_doge = true;
        this.ledger_linking_doge = true;
        this.linking_doge = true;
        while (this.ledger_linking_doge) {
          await this.delay(2000);
        }
      }

      if (objAppCosmos) {
        await this.delay(2000);

        let CoinName = 'Cosmos';
        this.linking_eth = false;
        this.linking_doge = false;
        this.linking_btc = false;
        this.linking_trx = false;
        this.isLinking = true;

        this.segment
          .track('link-device-cosmos-attempted', this.authService.getUser)
          .then(() => console.log('Link Device Cosmos attempted'))
          .catch((_err) => {});

        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Cosmos Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_cosmos = true;
        this.ledger_linking_cosmos = true;
        this.linking_cosmos = true;
        while (this.ledger_linking_cosmos) {
          await this.delay(2000);
        }
      }

      if (objAppDOT) {
        await this.delay(2000);

        let CoinName = 'Polkadot';
        this.linking_eth = false;
        this.linking_doge = false;
        this.linking_btc = false;
        this.linking_trx = false;
        this.linking_bch = false;
        this.linking_cosmos = false;
        this.isLinking = true;
        this.segment
          .track('link-device-dot-attempted', this.authService.getUser)
          .then(() => console.log('Link Device DOT attempted'))
          .catch((_err) => {});

        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Polkadot Open App On Ledger';
        const transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_dot = true;
        this.ledger_linking_dot = true;
        this.linking_dot = true;
        while (this.ledger_linking_dot) {
          await this.delay(2000);
        }
      }

      if (objAppTRX) {
        transport = await getNewTransport();
        let CoinName = 'Tron';
        //Exit app
        await transport.send(0xb0, 0xa7, 0x00, 0x00);
        await this.delay(5000);
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        try {
          await transport.close();
        } catch {}
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_trx = true;
        this.ledger_linking_trx = true;
        while (this.ledger_linking_trx) {
          await this.delay(2000);
        }
      }

      if (objAppSOL) {
        transport = await getNewTransport();
        let CoinName = 'Solana';
        //Exit app
        await transport.send(0xb0, 0xa7, 0x00, 0x00);
        await this.delay(5000);
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        try {
          await transport.close();
        } catch {}
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_sol = true;
        this.ledger_linking_sol = true;

        while (this.ledger_linking_sol) {
          await this.delay(2000);
        }
      }

      if (objAppXLM) {
        transport = await getNewTransport();
        let CoinName = 'Stellar';
        //Exit app
        await transport.send(0xb0, 0xa7, 0x00, 0x00);
        await this.delay(5000);
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Allow Open App On Ledger';
        transport = await getNewTransport();
        await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, 'ascii'));
        await this.delay(2000);
        try {
          await transport.close();
        } catch {}
        this.linking_Ledger_Error = true;
        this.linking_Ledger_Message = 'Click below button to continue';
        this.ng_xlm = true;
        this.ledger_linking_xlm = true;

        while (this.ledger_linking_xlm) {
          await this.delay(2000);
        }
      }
      //this needs to be changed for the last coin to be linked
      this.step1 = false;
      this.step2 = true;
      this.segment
        .track('link-device-xrp-success', this.authService.getUser)
        .then(() => console.log('Link Device XRP Success'))
        .catch((_err) => {});

      this.segment
        .track('link-device-success', this.authService.getUser)
        .then(() => console.log('Link Device Success'))
        .catch((_err) => {});

      let allWallets = this.walletService.getAllWallets();
      let shouldNavigate = true;
      for (let i = 0; i < allWallets.length; i++) {
        if (allWallets[i].config != '1of1') shouldNavigate = false;
      }
      if (shouldNavigate) {
        this.ngZone.run(() => {});
      } else {
        this.ngZone.run(() => {});
      }
    } else if (this.deviceName == 'Trezor') {
      this.deviceselected = true;
      this.Trazor_Connects();
    } else {
      this.deviceselected = false;
    }
  }

  async Trazor_Connects() {
    let result = [];
    let response;
    console.log('Selected', this.selectedItems);
    for (var i = 0; i < this.selectedItems.length; i++) {
      console.log('Selected Attempt', this.selectedItems[i].coin);
      if (this.selectedItems[i].coin == 'DOGE') {
        response = await this.linkDogeDevice();
        console.log('Selected Response', response);
        result.push(response.success);
      }
      if (this.selectedItems[i].coin == 'ETH') {
        response = await this.linkethDevice();
        console.log('Selected Response', response);
        result.push(response.success);
      }
      if (this.selectedItems[i].coin == 'BTC') {
        response = await this.linkbtcDevice();
        console.log('Selected Response', response);
        result.push(response.success);
      }
      if (this.selectedItems[i].coin == 'LTC') {
        response = await this.linkLTCDevice();
        console.log('Selected Response', response);
        result.push(response.success);
      }
      if (this.selectedItems[i].coin == 'BCH') {
        response = await this.linkBCHDevice();
        console.log('Selected Response', response);
        result.push(response.success);
      }
    }
    console.log('Result', result);

    this.modalService.dismissAll();

    if (result.every(Boolean)) {
      // Swal.fire({
      //   title: 'Success!',
      //   text: 'Awesome! You have successfully linked your digital assets accounts 🎉',
      //   icon: 'success',
      //   confirmButtonText: 'Cool'
      // });
      this.modalService.open(this.successModal, {
        windowClass: 'modal-custom-background',
        centered: true,
      });
    } else {
      // let title = "Oops, we could not link your device.";
      // let message = "You can try again later to link the same. In case it does not work, please get in touch with us at <a class='support_email' href='mailto:support@lmnl.app'>support@lmnl.app </a>";
      // Swal.fire({
      //   title: title,
      //   html: message,
      //   icon: "error"
      // });
      this.modalService.open(this.errorModal, {
        windowClass: 'modal-custom-background',
        centered: true,
      });
    }
  }

  selectDevice(deviceName, deviceImg) {
    this.deviceselected = true;
    this.deviceName = deviceName;
    this.deviceImg = deviceImg;
    if (this.deviceName == 'Trezor') {
      this.dropdownList = [
        { coin_id: 1, coin: 'BCH' },
        { coin_id: 2, coin: 'BTC' },
        { coin_id: 3, coin: 'DOGE' },
        { coin_id: 4, coin: 'ETH' },
        { coin_id: 5, coin: 'LTC' },
      ];
    }
    if (this.deviceName == 'Ledger') {
      this.dropdownList = [
        { coin_id: 1, coin: 'BTC' },
        { coin_id: 2, coin: 'ETH' },
        { coin_id: 3, coin: 'XRP' },
        //@ts-ignore
        { coin_id: 4, coin: 'ATOM' },
        { coin_id: 5, coin: 'TRX' },
        { coin_id: 6, coin: 'BCH' },
        { coin_id: 7, coin: 'LTC' },
        { coin_id: 11, coin: 'DOGE' },
        { coin_id: 8, coin: 'DOT' },
        { coin_id: 9, coin: 'SOL' },
        { coin_id: 10, coin: 'XLM' },
      ];
    }
  }

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async connectTrezor() {
    TrezorConnect.renderWebUSBButton();
    if (!this.isFindTrezor) {
      this.isFindTrezor = true;
    }
  }

  switchOrg() {
    const modalRef = this.modalService.open(ChooseOrganizationComponent, { centered: true });
    modalRef.componentInstance.component_for = 'switch-org-modal';
  }
}
