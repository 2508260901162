import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ApiKeyService } from '../../../../../../src/app/shared/services/api-key.service';
import { csvGeneration } from '@helpers/general';
import { StepsEnum } from 'src/app/shared/constants/apiKeys.const';
import { AuthServiceJWT } from 'src/app/shared/services/auth.service';
import { scopes } from 'src/app/shared/constants/authScopes';

@Component({
  selector: 'app-rotate-keys',
  templateUrl: './rotate-keys.component.html',
  styleUrls: ['./rotate-keys.component.scss']
})
export class RotateKeysComponent implements OnInit, AfterViewInit {
  @ViewChild('datepicker', { static: false }) datepicker: NgbDatepicker;
  @Output() close = new EventEmitter();
  @Input() userDetails: any;
  steps = StepsEnum;

  step = this.steps.STEP1;
  keyExpiration = '';
  date;
  isLoading = false
  today: NgbDate;
  maxRange: { month: number; year: number; day: number; };
  apiData;
  openIpAddressRestrictModal = 0;
  currentIpDetails;

  constructor(
    private calendar: NgbCalendar,
    private apiKeyService: ApiKeyService,
    private authService: AuthServiceJWT
  ) { 
    this.date = this.calendar.getToday();
  }

  ngOnInit(): void {
    const {minRange, maxRange } = this.apiKeyService.setMaxDateRange();
    this.today = minRange;
    this.maxRange = maxRange;
  }


  ngAfterViewInit(): void {
    this.datepicker.focus()
  }
  closeModal() {
    this.close.emit(this.step);
  }

  async rotateKey(keyExpiration) {
    this.authService.writeAccess(scopes.ApiKeyEnableDisable).subscribe(async (token) => {
      let payload = {
        "keyExpiration": `${keyExpiration.year}/${keyExpiration.month}/${keyExpiration.day}`
      }
      this.isLoading = true;
      this.apiData = await this.apiKeyService.rotateAPIKey(this.userDetails.id, payload, token).toPromise();
      if(this.apiData) {
        this.isLoading = false;
        this.step = this.steps.STEP2;
      }
    });
  }

  downloadAPI() {
    csvGeneration([
      {
        Name:this.apiData.data.name,
        Email:this.apiData.data.userEmail,
        Client_ID: this.apiData.data.client_id,
        Client_Secret: this.apiData.data.client_secret,
        Role: 'Signer',
      }
    ], ["Name","Email","Client ID", "Client Secret", "Role"], "Liminal_API_Key");
  } 

  restrictIpAddress(details) {
    this.currentIpDetails = details
    this.openIpAddressRestrictModal = Date.now();
  }
}
