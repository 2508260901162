"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Vector256 = exports.UInt64 = exports.UInt32 = exports.UInt16 = exports.UInt8 = exports.STObject = exports.STArray = exports.PathSet = exports.Hash256 = exports.Hash160 = exports.Hash128 = exports.Currency = exports.Blob = exports.Amount = exports.AccountID = exports.coreTypes = void 0;
const account_id_1 = require("./account-id");
Object.defineProperty(exports, "AccountID", {
  enumerable: true,
  get: function () {
    return account_id_1.AccountID;
  }
});
const amount_1 = require("./amount");
Object.defineProperty(exports, "Amount", {
  enumerable: true,
  get: function () {
    return amount_1.Amount;
  }
});
const blob_1 = require("./blob");
Object.defineProperty(exports, "Blob", {
  enumerable: true,
  get: function () {
    return blob_1.Blob;
  }
});
const currency_1 = require("./currency");
Object.defineProperty(exports, "Currency", {
  enumerable: true,
  get: function () {
    return currency_1.Currency;
  }
});
const hash_128_1 = require("./hash-128");
Object.defineProperty(exports, "Hash128", {
  enumerable: true,
  get: function () {
    return hash_128_1.Hash128;
  }
});
const hash_160_1 = require("./hash-160");
Object.defineProperty(exports, "Hash160", {
  enumerable: true,
  get: function () {
    return hash_160_1.Hash160;
  }
});
const hash_256_1 = require("./hash-256");
Object.defineProperty(exports, "Hash256", {
  enumerable: true,
  get: function () {
    return hash_256_1.Hash256;
  }
});
const issue_1 = require("./issue");
const path_set_1 = require("./path-set");
Object.defineProperty(exports, "PathSet", {
  enumerable: true,
  get: function () {
    return path_set_1.PathSet;
  }
});
const st_array_1 = require("./st-array");
Object.defineProperty(exports, "STArray", {
  enumerable: true,
  get: function () {
    return st_array_1.STArray;
  }
});
const st_object_1 = require("./st-object");
Object.defineProperty(exports, "STObject", {
  enumerable: true,
  get: function () {
    return st_object_1.STObject;
  }
});
const uint_16_1 = require("./uint-16");
Object.defineProperty(exports, "UInt16", {
  enumerable: true,
  get: function () {
    return uint_16_1.UInt16;
  }
});
const uint_32_1 = require("./uint-32");
Object.defineProperty(exports, "UInt32", {
  enumerable: true,
  get: function () {
    return uint_32_1.UInt32;
  }
});
const uint_64_1 = require("./uint-64");
Object.defineProperty(exports, "UInt64", {
  enumerable: true,
  get: function () {
    return uint_64_1.UInt64;
  }
});
const uint_8_1 = require("./uint-8");
Object.defineProperty(exports, "UInt8", {
  enumerable: true,
  get: function () {
    return uint_8_1.UInt8;
  }
});
const vector_256_1 = require("./vector-256");
Object.defineProperty(exports, "Vector256", {
  enumerable: true,
  get: function () {
    return vector_256_1.Vector256;
  }
});
const xchain_bridge_1 = require("./xchain-bridge");
const enums_1 = require("../enums");
const coreTypes = {
  AccountID: account_id_1.AccountID,
  Amount: amount_1.Amount,
  Blob: blob_1.Blob,
  Currency: currency_1.Currency,
  Hash128: hash_128_1.Hash128,
  Hash160: hash_160_1.Hash160,
  Hash256: hash_256_1.Hash256,
  Issue: issue_1.Issue,
  PathSet: path_set_1.PathSet,
  STArray: st_array_1.STArray,
  STObject: st_object_1.STObject,
  UInt8: uint_8_1.UInt8,
  UInt16: uint_16_1.UInt16,
  UInt32: uint_32_1.UInt32,
  UInt64: uint_64_1.UInt64,
  Vector256: vector_256_1.Vector256,
  XChainBridge: xchain_bridge_1.XChainBridge
};
exports.coreTypes = coreTypes;
// Ensures that the DEFAULT_DEFINITIONS object connects these types to fields for serializing/deserializing
// This is done here instead of in enums/index.ts to avoid a circular dependency
// because some of the above types depend on BinarySerializer which depends on enums/index.ts.
enums_1.DEFAULT_DEFINITIONS.associateTypes(coreTypes);
