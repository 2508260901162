<div *ngIf="component_for == 'refill_wallet'">
  <div class="center card card-margin" *ngIf="_wallets.length && !loading">
    <div class="d-flex justify-content-between align-items-center p-4">
        <h2 class="mr-2 mb-0 font-weight-500">Refill Wallets</h2>
        <div class="d-flex">
          <div class="mr-3" *ngIf="pendingtx_component_for != 'dashboared-pending'">
            <div class="search-round">
              <i class="icon-search" aria-hidden="true"></i>
              <input class="form-control" [(ngModel)]="refillSearch" placeholder="Search" type="text" />
            </div>
        </div>
        <ul class="d-flex flex-wrap">
          <li>
            <span class="tag cursor-pointer ml-2" [ngClass]="{'active' : filterType == 'all'}" (click)="refillFilter('all')">All</span>
        </li>
            <li>
                <span class="tag cursor-pointer ml-2" [ngClass]="{'active' : filterType == 'low-refill'}" (click)="refillFilter('low-refill')">Low Refills <b class="ml-2">{{(_wallets | refillFilters:'low-refill').length}}</b></span>
            </li>
            <li>
              <span class="tag cursor-pointer ml-2" [ngClass]="{'active' : filterType == 'low-balance'}" (click)="refillFilter('low-balance')">Low Balance <b class="ml-2">{{(_wallets | refillFilters:'low-balance').length}}</b></span>
          </li>
        </ul>
        <button class="btn btn-primary btn-round font-size-12 ml-3" (click)="open(Create_refill)"><i class="icon-add-alt mr-1 font-size-12"></i>Add</button>
        </div>
        
    </div>
    <div class="row">
        <div class="col-sm-12">
            
                <div class="table-responsive table-new">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="text-left pl-4" style="width: 12%;">Wallet</th>
                                <th scope="col" class="text-left" style="width: 8%;">Asset</th>
                                <th scope="col" class="text-left" style="width: 10%;">Balance</th>
                                <th scope="col" class="text-left" style="width: 10%;">Refill with</th>
                                <th scope="col" class="text-left" style="width: 12%;">To address</th>
                                <th scope="col" class="text-left" style="width: 8%;">If less than</th>
                                <th scope="col" class="text-left" style="width: 10%;">Interval</th>
                                <th scope="col" class="text-left" style="width: 9%;"></th>
                                <th scope="col" style="width: 18%;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let refillWallets of _wallets | refillFilters:filterType | refillSearch:refillSearch |  slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index" 
                            [ngClass]="{'bg-light-red outline-border-tr': refillWallets.scheduledBatch.remainingTxs <= 1 || refillWallets.scheduledBatch.currentbalance <= refillWallets.scheduledBatch.balancetrigger}">
                              <td class="pl-4 cursor-pointer" (click)="walletDetailClick(refillWallets.id)">
                              <span class="d-block dottedtext dottedtext-wallet-name" ngbTooltip="{{refillWallets.name}}">{{refillWallets.name}}</span>
                              <span class="network text-dark {{ refillWallets.chain.toLowerCase() }} d-block">{{ refillWallets.chain | getName }}</span>
                              </td>
                                    <td>
                                      <div class="d-flex align-items-center">
                                        <img class="align-self-center mr-1 img-fluid img-round-border mr-2"
                                            [src]="refillWallets.coin | getIcon">{{ refillWallets.coin }}
                                    </div>   
                                        </td>
                                    
                                    <td>
                                        <div>
                                        <span class="d-block">${{ refillWallets.balanceUSD | number : '1.2-2'}}</span>
                                        <span class="d-block text-dark">{{ refillWallets.balance | number : '1.5-5'}} {{refillWallets.coin}}</span>
                                        </div>
                                    </td>
                                    <td> 
                                       <span class="d-block">{{refillWallets.scheduledBatch.amount | number : '1.5-5'}} {{refillWallets.coin}}</span> 
                                       <span class="d-block text-dark font-size-12 font"><span class="text-red font-size-12 font-weight-600" [ngClass]="{'text-red':refillWallets.scheduledBatch.remainingTxs <= 1}">{{refillWallets.scheduledBatch.remainingTxs}} of {{refillWallets.scheduledBatch.transactioncount}}</span> Remaining</span>
                                    </td>
                                    <td>
                                        <span class="d-flex"><span class="dottedtext dottedtext-add-title" ngbTooltip="{{refillWallets.scheduledBatch.walletNameInfo}}">{{refillWallets.scheduledBatch.walletNameInfo }}</span> &nbsp;|&nbsp; <span [ngClass]="{'text-red':refillWallets.scheduledBatch.currentbalance <= refillWallets.scheduledBatch.balancetrigger}">{{ refillWallets.scheduledBatch.currentbalance |
                                          number : '1.5-5' }} {{refillWallets.coin}}</span></span>
                                        <span class="d-block text-dark" ngbTooltip="{{refillWallets.scheduledBatch.address}}">{{refillWallets.scheduledBatch.address.substring(0,8)}}...{{refillWallets.scheduledBatch.address.substring(refillWallets.scheduledBatch.address.length - 8)}}</span>
                                    </td>
                                    <td class="text-muted m-t-10" >
                                        <span>{{ refillWallets.scheduledBatch.balancetrigger | number
                                          : '1.5-5' }} {{refillWallets.coin}}</span>
                                    </td>
                                    <td>
                                       <span class="d-block">Every {{refillWallets.scheduledBatch.recurringtime | amountToPretty : 0}} hrs</span>
                                       <span class="d-block text-dark font-size-12">last {{ refillWallets.scheduledBatch.lasttransaction | dateAgo }}</span>
                                    </td>
                                    <td>
                                      <app-signer-user-list [signerUserData]="refillWallets.walletKeys" [uniqId]="i" [component_for]="'refill'">
                                      </app-signer-user-list>
                                    </td>
                                    <td class="m-t-10 text-right pr-4">
                                      <div class="d-flex font-weight-bold justify-content-end text-right float-right media-body align-items-center">
                                        <button class="btn btn-view-only btn-round btn-sm"
                                          *ngIf="refillWallets.access == 'READ' || refillWallets.access == 'VIEWER'">View only</button>
                                        <ng-container *ngIf="refillWallets.access == 'SIGNER'">
                                          <div [ngbTooltip]="!refillWallets?.ScheduleTransactions?.length && 'No refills to approve'">
                                          <button 
                                          [ngClass]="refillWallets.ScheduleTransactions?.length ? '' : 'disabled'"
                                          [disabled]="!refillWallets.ScheduleTransactions?.length" 
                                          (click)="onApproveClick(refillWallets.scheduledBatch?.walletid)"
                                          class="btn btn-round btn-sm mr-2 btn-primary-light">Approve</button>
                                          </div>
                                          <div [ngbTooltip]="!refillWallets.scheduledBatch.remainingTxs && 'No refills available to push'">
                                          <button (click)="pushOpen(pushrefillwallets);pushData(refillWallets.id,refillWallets.coin)" [ngClass]="refillWallets.scheduledBatch.remainingTxs > 0 ? '' : 'disabled'" 
                                            [disabled]="!refillWallets.scheduledBatch.remainingTxs" class="btn btn-round btn-sm btn-blue-light">Push</button>
                                          </div>
                                        </ng-container>
                                        
                                      </div>
                                    </td>
                                    
                            </tr>
                        </tbody>
                    </table>
                </div>
            <div class="col-12 my-3" *ngIf="_wallets.length > 6">
              <div class="d-flex justify-content-between">
                <span class="table-footer-text">Showing <span>{{(_wallets | refillFilters:filterType | refillSearch:refillSearch).length | paginationFromTo : page : pageSize}}</span> of {{(_wallets | refillFilters:filterType | refillSearch:refillSearch).length}} entries</span>
                <div class="pagination">
                  <ngb-pagination [collectionSize]="(_wallets | refillFilters:filterType | refillSearch:refillSearch).length" [(page)]="page" [pageSize]="pageSize" [maxSize]="3" [boundaryLinks]="true" [ellipses]="false">
                    <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i></ng-template>
                    <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left" placement="top" ngbTooltip="Previous"></i></ng-template>
                    <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right" placement="top" ngbTooltip="Next"></i></ng-template> 
                    <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right" placement="top" ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>  
                  </ngb-pagination>
                </div>
              </div>
            </div>
        </div>
    </div>
    <!-- Schedule transactions -->
    <ng-template #Scheduled_refill let-modal>
      <div class="modal-header">
        <h2 class="modal-title">
          <i class="icon-refill mr-2"></i>
          Refill Transactions
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
          <i class="icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <div class="padding-21">
                  <label class="mb-1">From</label>
                  <div class="card refill_card">
                    <div class="card-body refill_card p-3">
                      <div class="media border-after-xs">
                        <img class="align-self-center mr-2 img-fluid img-round-border" [src]="coin | getIcon">
                        <div class="media-body details align-self-center">
                          {{ Wallet_Name }}</div>
                        <div class="media-body text-right">
                          <h6 class="card-balance">${{ balanceUSD | number : '1.2-2'}}</h6>
                          <span class="card-balance-coin text-muted">{{ balance | number : '1.5-5'}} {{coin}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label class="mb-1">To</label>
              <div class="card refill_card">
                <div class="card-body refill_card p-3">
                  <div class="d-flex">
                    <i class="icon-address text-secondary" style="border-radius: 50%;padding: 12px;border: 2px solid #f7c325;display: flex;justify-content: center;font-size: 22px!important;align-items: center;width: 50px;height: 50px;"></i>
                    <div class="details d-flex flex-column align-items-center pl-2">
                      <div class="col text-left p-0">
                        <p class="m-0 truncate_Name">{{ to_Wallet_Name }}</p>
                      </div>
                      <div class="col truncate text-left p-0 t_Id text-muted">
                        <span>{{ condition }}</span>
                      </div>
                    </div>
                    <div class="d-flex  flex-column  justify-content-end float-right media-body">
                      <div class="col text-right p-0">
                        <p class="m-0 font-10 pt-1">Current Balance</p>
                      </div>
                      <div class="col text-right p-0">
                        <span class="card-balance-coin text-muted">{{ currentBalance | number : '1.5-5'}} {{coin}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label class="mb-1">Schedule</label>
                <div class="card refill_card">
                  <div class="card-body refill_card p-3">
                    <div class="d-flex justify-content-between">
                      <div class="px-1">
                        <div> <label class="text-dark mb-0 font-size-13">Refill</label>
                        </div>
                        <div>
                            <span class="capsule_violet">{{ frequency }} Times</span>
                          </div>
                      </div>
                      <div class="px-1">
                        <div> <label class="text-dark mb-0 font-size-13">Every</label>
                        </div>
                        <div>
                            <span class="capsule_blue">{{ recurringTime | number : '1.1'
                              }}h</span>
                          </div>
                      </div>
                      <div class="px-1">
                        <div> <label class="text-dark mb-0 font-size-13">With</label>
                        </div>
                        <div class="align-items-center  m-auto ">
                            <span class="capsule_green">{{ amount | number : '1.2-2'}}
                              {{coin}}</span>
                          </div>
                      </div>
                      <div class="px-1">
                        <div>
                          <label class="text-dark mb-0 font-size-13">If Balance Below</label>
                        </div>
                        <div>
                          <span class="capsule_orng">{{ balanceTrigger | number : '1.0'}} {{coin}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label class="mb-1">Progress</label>
                <div class="card refill_card last-card">
                  <div class="card-body refill_card">
                    <div class="d-flex">
                      <div class="d-flex  flex-column ">
                        <div class="col pl-0">
                          <div class="userMainbox">
                            <img
                              class="align-self-center img-fluid img-30 rounded-circle user-image refill_wallet text-left "
                              [attr.src]="signers_PhotoURL">
                          </div>
                        </div>
                      </div>
                      <div class="d-flex w-75  flex-column">
                        <div class="col text-left p-0">
                          <p class="m-0 truncate_Name">Transactions</p>
                        </div>
                        <div class="col text-left p-0">
                          <div class="progress w-90">
                            <div class="progress-bar bg-success" id="progressbar" role="progressbar"
                              [ngStyle]="{'width': percentage_of_progressbar}" aria-valuenow="75" aria-valuemin="0"
                              aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-end">
                        <div class="col text-right p-0 ">
                          <span class="m-0 prog_ratio">{{current_transaction_count}}/{{total_transactions}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center" *ngIf="device == 'ledger'">
                  <i class="icon-ledger mr-2"></i>
                  <span>Follow the instructions on your Ledger device to Sign</span>
              </div>
              <div class="d-flex align-items-center justify-content-center" *ngIf="device == 'trezor'">
                <i class="icon-trezor mr-2"></i>
                <span>Follow the instructions on your Trezor device to Sign</span>
            </div>
            </div>
          </div>
        </div>
      </div>
    <div class="modal-footer border-0" *ngIf="browser == 'Firefox' && device == 'ledger'; else not_fox" >
        <div class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use Google Chrome or other supported browser.</div>
    </div>
    <ng-template #not_fox>
      <div class="modal-footer border-0">
        <button (click)="Sign(wallet_id)" [ngClass]="{'disabled': isLoader }" [disabled]="isLoader"
          class="btn btn-primary w-100" type="submit">
          <i class="icon-check mr-1" *ngIf="!isLoader"></i>
          <div class="loader-box" *ngIf="isLoader">
            <div class="loader-15 bg-white"></div>
          </div>
          <span *ngIf="!isLoader">Sign</span>
        </button>
      </div>
    </ng-template>
    </ng-template>
    <!-- Schedule transactions Ends-->
    <ng-template #pushrefillwallets let-modal>
      <div class="modal-header">
        <h2 class="modal-title">Push Refill Transaction</h2>
        <button type="button" class="close" aria-label="Close" id="canceltransaction"
          (click)="modal.dismiss('Cross click')">
          <i class="icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        
          <div class="m-t-20  m-b-20">
            <div class="text-left">
              <p class="text-left m-0">Are you sure you want to push refill transaction before it's cool down
                  period ends?</p>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary"
          (click)="refillPushBtn(pushId,pushCoin)" type="button" 
          title=""> <i class="icon-check mr-1"></i> Yes</button>
      </div>
    </ng-template>
</div>
<div *ngIf="!_wallets.length && !loading" class="card d-flex align-items-center p-4 card-shadow-border">
  <img src="../../../assets/images/placeholder/wallet.png" width="125" class="mb-4">
  <h6>No refill wallets yet</h6>
  <p class="mb-0 text-dark">You currently do not have any refill wallets.
    <span *ngIf="userType == 2 && keys > 0"><app-new-group-wallet [component_for]="'No Wallet'"></app-new-group-wallet></span></p>
</div>
<div class="card card-margin" *ngIf="loading">
  <div class="row w-100 p-4">
      <div class="col-2">
          <ngx-skeleton-loader></ngx-skeleton-loader>
      </div>
      <div class="col-6 ml-auto">
        <div class="row">
          <div class="col-4">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </div>
          <div class="col-4">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </div>
          <div class="col-4">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </div>
        </div>
    </div>
  </div>
  <div class="table-responsive table-new">
      <table class="table">
          <thead>
              <tr>
                  <th scope="col" style="width: 160px;">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  <th scope="col">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </th>
                  
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of [].constructor(6); let i = index">
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
                  <td>
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
  <!-- <div class="w-100" *ngIf="refillCard === 'refill_card'?false:true">
    <div *ngIf="wallets && wallets.length>0 && state" class="header-title-margin text-muted py-3">
      <h2>Refill Wallets <a *ngIf="wallets.length > 6" routerLink="/wallets" fragment="refill"
        class="btn btn-link text-primary font-weight-bold p-0 float-right">View All</a>
      </h2>
    </div>
  </div>
  <div class="w-100" *ngIf="state">
      <div class="row" [ngClass]="{'m-0' : refillCard === 'refill_card'}">
      <div class="col-12" [ngClass]="refillCard === 'refill_card'?'col-xl-12 p-0':'col-xl-4'" *ngFor="let refillWallets of _wallets | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ;let i=index">
        <div class="card card-shadow">
          <div class="card-body p-4">
            <div class="media border-after-xs">
              <img class="align-self-center mr-2 img-fluid img-round-border" [src]="refillWallets.coin | getIcon">
              <div class="media-body details align-self-center">
                {{ refillWallets.name }}</div>
              <div class="media-body text-right">
                <h6 class="card-balance">${{ refillWallets.balanceUSD | number : '1.2-2'}}</h6>
                <span class="card-balance-coin">{{ refillWallets.balance | number : '1.5-5'}} {{refillWallets.coin}}
                </span>
              </div>
            </div>
            <div class="d-flex mt-3 font-13">
              <div class="d-flex  flex-column px-1">
                <div class="col p-0 text-center"> <label class="text-muted schedule_Label">Remaining</label></div>
                <div class="col p-0 text-center">
                  <span class="font_Line_Height capsule_violet">{{
                    refillWallets['scheduledBatch'].remainingTxs }} Refills</span>
                </div>
                <div
                  ngbTooltip="{{ refillWallets['scheduledBatch'].amount | number : '1.2-2'  }} {{refillWallets.coin}} "
                  class="p-0 mt-2 text-center " *ngIf="refillWallets['scheduledBatch'].amount < 1000"> <label
                    class="mb-0 schedule_Label card-balance-coin">{{ refillWallets['scheduledBatch'].amount | number :
                    '1.2-2'}} {{refillWallets.coin}}</label></div>
                <div
                  ngbTooltip="{{ refillWallets['scheduledBatch'].amount | number : '1.2-2'  }} {{refillWallets.coin}} "
                  class="p-0 mt-2 text-center " *ngIf="refillWallets['scheduledBatch'].amount >= 1000"> <label
                    class="mb-0 schedule_Label card-balance-coin">{{ refillWallets['scheduledBatch'].amount |
                    amountrounded : 2}} {{refillWallets.coin}}</label></div>
              </div>
              <div class="d-flex  flex-column px-1">
                <div class="col p-0 text-center"> <label class="text-muted schedule_Label">Last</label></div>
                <div class="col p-0 text-center">
                  <div class="align-items-center box_Circle_Schedule green_Ring m-auto ">
                    <span class="font_Line_Height capsule_blue">{{
                      refillWallets['scheduledBatch'].lasttransaction | dateAgo }}</span>
                  </div>
                </div>
                <div class="col p-0 text-center"></div>
              </div>
              <div class="d-flex  flex-column px-1">
                <div class="col p-0 text-center"> <label class="text-muted schedule_Label">Cooldown</label></div>
                <div class="col p-0">
                  <div class="align-items-center  text-center box_Circle_Schedule orng_Ring m-auto ">
                    <span class="font_Line_Height capsule_green">{{refillWallets.scheduledBatch.coolDownInfo}}<span>h</span></span>
                  </div>
                </div>
                <div class="col p-0 text-center"></div>
              </div>
              <div class="d-flex  flex-column  justify-content-end float-right media-body">
                <div class="col text-right pr-0">
                  <label ngbTooltip="{{refillWallets.scheduledBatch.walletNameInfo}}"
                    class="text-muted schedule_Label">{{refillWallets.scheduledBatch.walletNameInfo|
                    slice:
                    0:4 }} {{ refillWallets['scheduledBatch'].address | slice:0:6 }}</label>
                </div>
                <div class="col text-right p-0" *ngIf="refillWallets['scheduledBatch'].currentbalance < 1000">
                  <span
                    ngbTooltip="{{ refillWallets['scheduledBatch'].currentbalance | number : '1.2-2'  }} {{refillWallets.coin}} "
                    class="m-0 capsule_yellow">{{ refillWallets['scheduledBatch'].currentbalance |
                    number : '1.2-2' }} {{refillWallets.coin}}</span>
                </div>
                <div class="col text-right p-0" *ngIf=" refillWallets['scheduledBatch'].currentbalance >= 1000 ">
                  <span
                    ngbTooltip="{{ refillWallets['scheduledBatch'].currentbalance | number : '1.2-2'  }} {{refillWallets.coin}} "
                    class="m-0 capsule_yellow ">{{ refillWallets['scheduledBatch'].currentbalance |
                    amountrounded : 2 }} {{refillWallets.coin}}</span>
                </div>
                <div class="text-right p-0" *ngIf="refillWallets['scheduledBatch'].balancetrigger < 1000">
                  <span
                    ngbTooltip="{{ refillWallets['scheduledBatch'].balancetrigger | number : '1.2-2'  }} {{refillWallets.coin}} "
                    class="m-0 card-balance-coin">{{ '<' }}{{ refillWallets['scheduledBatch'].balancetrigger | number
                      : '1.2-2' }} {{refillWallets.coin}}</span>
                </div>
                <div class="text-right p-0" *ngIf="refillWallets['scheduledBatch'].balancetrigger >= 1000">
                  <span
                    ngbTooltip="{{ refillWallets['scheduledBatch'].balancetrigger | number : '1.2-2'  }} {{refillWallets.coin}}"
                    class="m-0 card-balance-coin">{{ '<' }}{{ refillWallets['scheduledBatch'].balancetrigger |
                      amountrounded : 2 }} {{refillWallets.coin}}</span>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column  border-after-xs pt-3">
              <div class="d-flex details">
                <app-signer-user-list [signerUserData]="refillWallets.walletKeys" [uniqId]="i" [component_for]="'refill'">
                </app-signer-user-list>
                <div class="d-flex font-weight-bold justify-content-end text-right float-right media-body align-items-center">
                  <ng-container *ngIf="refillWallets.access == 'SIGNER'">
                    <span (click)="onApproveClick(refillWallets['scheduledBatch'].walletid)"
                    *ngIf="refillWallets['ScheduleTransactions'].length"
                    class="approve_btn border-bottom-green">Approve All ({{ refillWallets['ScheduleTransactions'].length }}) </span>
                  <span class="approve_btn text-muted d-inline-flex align-items-center"
                    *ngIf="!refillWallets['ScheduleTransactions'].length || refillWallets.access == 'READ' || refillWallets.access == 'VIEWER'"> <i
                      class="icon-check mr-1"></i>All Approved </span>
                  </ng-container>
                  <span class="approve_btn text-muted d-inline-flex align-items-center"
                    *ngIf="refillWallets.access == 'READ' || refillWallets.access == 'VIEWER'">View only</span>
                  <span class="text-primary" *ngIf="refillWallets['scheduledBatch'].remainingTxs > 0">&nbsp;| &nbsp;</span>
                  <span (click)="pushOpen(pushrefillwallets)" *ngIf="refillWallets['scheduledBatch'].remainingTxs > 0"
                    class="approve_btn border-bottom-green">Push</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="col-12 m-b-20" style="margin-top: -20px;" *ngIf="_wallets.length > 6">
    <div class="d-flex justify-content-between">
      <span>Showing <span>{{_wallets.length | paginationFromTo : page : pageSize}}</span> of {{_wallets.length}} entries</span>
      <div class="pagination">
        <ngb-pagination [collectionSize]="_wallets.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="3" [boundaryLinks]="true" [ellipses]="false">
          <ng-template class="left_arrow" ngbPaginationFirst><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i><i class="icon-arrow-left" placement="top" ngbTooltip="First"></i></ng-template>
          <ng-template class="left_arrow" ngbPaginationPrevious><i class="icon-arrow-left" placement="top" ngbTooltip="Previous"></i></ng-template>
          <ng-template class="right_arrow" ngbPaginationNext><i class="icon-arrow-right" placement="top" ngbTooltip="Next"></i></ng-template> 
          <ng-template class="right_arrow" ngbPaginationLast><i class="icon-arrow-right" placement="top" ngbTooltip="Last"></i><i class="icon-arrow-right"></i></ng-template>  
        </ngb-pagination>
      </div>
    </div>
  </div>
  </div> -->
</div>

<div *ngIf="component_for == 'create_refill'">
  <button class="btn px-4 py-2 text-left" style="padding: 0.25rem 1.5rem;" (click)="!walletData.isArchived && checkPending(Create_refill)"
  [ngClass]="{'disabled': walletData?.isArchived }"
[disabled]="walletData?.isArchived ">
    <i class="icon-refill mt-1 mr-2" ></i>
    <span class="body-font" style="margin-left: 2px;">Create Refill <br>Schedule</span>
  </button>
</div>
<ng-template #Create_refill let-modal>
  <div *ngIf="status === 'step-1'">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-refill mr-2"></i>
        Refill Schedule
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="mb-4" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
            <label class="text-dark">Wallet</label>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search wallet" id="dropdownManual"
              [(ngModel)]="searchWalletInput" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
            <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
              <div class="d-flex position-relative" *ngFor="let walletdata of checkForRefillWallet(walletAllData) | wallet_search: searchWalletInput" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                <div class="w-100">
                  <button class="dropdown-content w-100 p-0 m-0 border-0" (click)="selectWallet(walletdata)">
                  <app-wallet-card [option]=walletdata [AllWallets]="walletAllData" [dropdown_card]="'dropdown-card'"
                    [card_padding]="'p-3'"></app-wallet-card>
                </button>
                </div>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedWallet">
            <label class="text-dark">Wallet</label>
            <span class="cursor-pointer text-muted text-underline float-right"  *ngIf="component_for != 'create_refill'" (click)="selectedWallet = !selectedWallet">
              Clear
            </span>
            <app-wallet-card class="selected-card" [option]="selectedWallet" [AllWallets]="walletAllData" [card_padding]="'p-3'"></app-wallet-card>
          </div>
          <!-- <div class="mb-4">
            <label class="text-dark">Wallet</label>
            <app-wallet-card [option]="walletData" [AllWallets]="walletAllData" [card_padding]="'p-3'"></app-wallet-card>
          </div> -->
          <div class="mb-4" ngbDropdown #myDropAssets="ngbDropdown" *ngIf="!selectedAsset">
            <label class="text-dark">Asset</label>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search asset" id="dropdownManual1" 
            [ngClass]="{'disabled': !selectedWallet}" [disabled]="!selectedWallet"
              [(ngModel)]="searchAssetInput" ngbDropdownAnchor (focus)="myDropAssets.open()" type="text">
            <ul ngbDropdownMenu aria-labelledby="dropdownManual1" class="whitelistscroll w-100 py-0">
              <div class="d-flex" *ngFor="let assetsdata of assets | wallet_search: searchAssetInput"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
                <button class="dropdown-content w-100 p-0 m-0 border-0">
                  <app-wallet-card [option]=assetsdata [dropdown_card]="'dropdown-card'" [card_padding]="'p-3'"
                    [assetsCard]="'assets_card'" (click)="selectAssets(assetsdata)"></app-wallet-card>
                </button>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedAsset">
            <label class="text-dark">Asset</label>
            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedAsset"
              (click)="selectedAsset = !selectedAsset;selectedAddress? selectedAddress = !selectedAddress:''">Clear</span>
            <app-wallet-card class="selected-card" [option]=selectedAsset [assetsCard]="'assets_card'" [card_padding]="'p-3'">
            </app-wallet-card>
          </div>
          <div ngbDropdown #myDropAddress="ngbDropdown" *ngIf="!selectedAddress" class="mb-4">
            <label class="text-dark">Destination Address</label>
            <input autocomplete="off" class="form-control search-custom" placeholder="Search address"
              id="dropdownManual2" ngbDropdownAnchor (focus)="myDropAddress.open()" [(ngModel)]="searchAssetAddress" type="text"
              [ngClass]="{'disabled': !selectedAsset}" [disabled]="!selectedAsset">
            <ul ngbDropdownMenu aria-labelledby="dropdownManual2" class="whitelistscroll w-100 py-0"
              *ngIf="selectedAsset">
              <div class="d-flex" *ngFor="let policy of selectedAsset.policy | walletAddressSearch: searchAssetAddress">
                <button *ngIf="policy.subtype.toLowerCase() === 'address'"
                  class="dropdown-content card w-100 p-0 brd_Box" (click)="selectAddress(policy)">
                  <li ngbDropdownItem class="d-flex p-3">
                    <i class="icon-address text-secondary" style="border-radius: 50%;padding: 12px;border: 2px solid #f7c325;display: flex;justify-content: center;font-size: 22px!important;align-items: center;width: 50px;height: 50px;"></i>
                    <div class="details d-flex flex-column align-items-center">
                      <div class="col text-left p-0">
                        <p class="m-0 truncate_Name">{{ policy.name }}</p>
                      </div>
                      <div class="col text-left p-0 t_Id">
                        <span>{{ policy.condition.substring(0, 8) }}...</span>
                        <span>{{ policy.condition.substring(policy.condition.length - 4) }}</span>
                      </div>
                    </div>
                  </li>
                </button>
              </div>
            </ul>
          </div>
          <div *ngIf="selectedAddress" class="mb-4">
            <label class="text-dark">Destination Address</label>
            <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedAddress"
              (click)="selectedAddress = !selectedAddress">Clear</span>
            <app-address-card class="selected-card" [option2]=selectedAddress [card_padding]="'p-3'">
            </app-address-card>
          </div>
          <div *ngIf="createRefillStep === 1">
            <div>
              <p class="border-bottom body-font pb-2">Schedule</p>
            </div>
            <div class="mb-4">
              <label class="text-dark">Number of Refills</label>
              <div class="input-group">
                <input autocomplete="off" min="1" onwheel="return false;" (keypress)="allowNumber($event)" class="form-control body-font"
                    placeholder="Select number of refills" type="number" [(ngModel)]="setNumberRefill" value="">
              </div>
            </div>
            <div class="mb-4">
              <label class="text-dark">Interval Between Refills</label>
            <div class="input-group">
              <input type="number" class="form-control body-font border-radius-left" min="1" onwheel="return false;" (keypress)="allowNumber($event)" [(ngModel)]="setRefillInterval" placeholder="Enter refill interval" value="">
              <div class="input-group-append">
                <div ngbDropdown class="d-inline-flex align-items-center input-group-text refill-dp">
                  <button class="btn p-0 body-font text-capitalize" id="dropdownBasicTime" ngbDropdownToggle>
                    {{interval_unit}}
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 8 5" fill="none"
                      class="ml-2">
                      <path
                        d="M7.52555 0.380031C7.39796 0.285101 7.23385 0.239397 7.06898 0.252876C6.90411 0.266356 6.75184 0.337925 6.64534 0.451994L3.99996 3.3086L1.35454 0.452041C1.24834 0.337525 1.09595 0.265666 0.930887 0.252273C0.765823 0.23888 0.601608 0.285051 0.474368 0.380627C0.347127 0.476203 0.267284 0.613356 0.252404 0.761914C0.237523 0.910471 0.288823 1.05827 0.395019 1.17278L3.5202 4.54793C3.57882 4.61121 3.65218 4.66211 3.73509 4.69704C3.81799 4.73198 3.90842 4.75008 3.99996 4.75008C4.0915 4.75008 4.18192 4.73198 4.26482 4.69704C4.34773 4.66211 4.42109 4.61121 4.47972 4.54793L7.60489 1.17273C7.71105 1.0581 7.76232 0.910236 7.74745 0.761609C7.73257 0.612982 7.65276 0.475744 7.52555 0.380031Z"
                        fill="#6F757A" />
                    </svg>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasicTime" class="p-0 custom-dropdown-menu">
                    <button class="p-2 justify-content-start border-0 rounded-0" ngbDropdownItem
                      (click)="selectRole('hours')">Hours</button>
                    <button class="p-2 justify-content-start border-0 rounded-0" ngbDropdownItem
                      (click)="selectRole('days')">Days</button>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="mb-4">
              <label class="text-dark">Refill Amount
              </label>
              <div class="input-group">
                <input autocomplete="off" min="1" onwheel="return false;" (keypress)="allowNumberDecimal($event)" class="form-control border-radius-left body-font"
                    placeholder="Enter refill amount" type="number" [(ngModel)]="setRefillAmount" value="">
                <div *ngIf="selectedAsset" class="input-group-append"><span class="input-group-text font-size-14 border-radius-right">{{selectedAsset?.coin}}</span>
                </div>
            </div>
            </div>
            <div>
              <label class="text-dark">If Balance Lower than
              </label>
              <div class="input-group">
                <input autocomplete="off" min="1" onwheel="return false;" (keypress)="allowNumberDecimal($event)" class="form-control border-radius-left body-font"
                    placeholder="Enter trigger balance" type="number" [(ngModel)]="setBalanceLower" value="">
                <div *ngIf="selectedAsset" class="input-group-append"><span class="input-group-text font-size-14 border-radius-right">{{selectedAsset?.coin}}</span>
                </div>
            </div>
            </div>
            <!-- <div class="d-flex align-items-center mt-3">
              <span class="mb-0 mr-1 line-height-12">
                  <i class="icon-ledger text-dark"></i>
              </span>
              <div class="ml-2">
                  <span class="d-block">
                      <a href="javascript:void(0);" class="font-size-12">
                          Follow the instructions on your Ledger device to Sign
                      </a>
                  </span>
              </div>
          </div> -->
          </div>
          <div *ngIf="createRefillStep === 2">
            <div class="d-flex justify-content-between">
              <label class="text-dark">Schedule</label>
              <span class="cursor-pointer text-muted text-underline float-right" 
               (click)="setNumberRefill = null;setRefillInterval = null;setRefillAmount = null;setBalanceLower = null;this.createRefillStep = 1;">
               Clear</span>
            </div>
            <div class="card refill_card mb-4">
              <div class="card-body refill_card p-3">
                <div class="d-flex justify-content-between">
                  <div class="px-1">
                    <div> <label class="text-dark mb-0 font-size-13">Refill</label>
                    </div>
                    <div>
                      <span class="capsule_violet">{{ setNumberRefill }} Times</span>
                    </div>
                  </div>
                  <div class="px-1">
                    <div> <label class="text-dark mb-0 font-size-13">Every</label>
                    </div>
                    <div>
                      <span class="capsule_blue">{{ setRefillInterval }}{{interval_unit.charAt(0)}}</span>
                    </div>
                  </div>
                  <div class="px-1">
                    <div> <label class="text-dark mb-0 font-size-13">With</label>
                    </div>
                    <div>
                      <span class="capsule_green">{{ setRefillAmount | number : '1.2-2'}}
                          {{selectedAsset?.coin}}</span>
                    </div>
                  </div>
                  <div class="px-1">
                    <div>
                      <label class="text-dark mb-0 font-size-13">If Balance Below</label>
                    </div>
                    <div>
                      <span class="m-0 capsule_orng">{{ setBalanceLower | number : '1.2-2'}} {{selectedAsset?.coin}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="modal-footer border-0" *ngIf="createRefillStep === 1">
      <button (click)="nextCreateRefill()" 
      [ngClass]="{'disabled': !selectedWallet ||  !selectedAsset || !selectedAddress || !setNumberRefill || !setRefillInterval || !setRefillAmount || !setBalanceLower }" 
      [disabled]="!selectedWallet || !selectedAsset || !selectedAddress || !setNumberRefill || !setRefillInterval || !setRefillAmount || !setBalanceLower"
        class="btn btn-outline-primary w-100" type="submit">
        <span>Next</span>
      </button>
    </div>
    <div class="modal-footer border-0" *ngIf="createRefillStep === 2">
      <button (click)="createRefillSchedule(selectedAddress.condition,selectedWallet.id,setNumberRefill,setRefillInterval,setRefillAmount,setBalanceLower,selectedAsset?.coin,interval_unit)" class="btn btn-outline-primary w-100" type="submit">
        <span *ngIf="!isLoading">Create Refill Schedule</span>
        <div *ngIf="isLoading" class="loader-box">
          <div class="loader-15"></div>
        </div>
      </button>
    </div>
  
  </div>
  <div *ngIf="status === 'success'">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-refill mr-2"></i>
        Refill Schedule
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body d-flex align-items-center">
        <div class="row">
            <div class="col-12">
                <div class="mb-3 position-relative">
                    <div class="background_success text-center"> <img class="w-50"
                            src=".../../../assets/images/check-outline-success.gif"> </div>
                </div>
                <div class="mb-5 text-center">
                    <div class="h5">Success</div>
                    <div class="text-muted w-75 m-auto">
                        Awesome! You have successfully created <br>refill schedule transaction. 🎉
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');"> Done
        </button>
    </div>
</div>
</ng-template>
