var t = function () {
  return t = Object.assign || function (t) {
    for (var i, e = 1, n = arguments.length; e < n; e++) for (var o in i = arguments[e]) Object.prototype.hasOwnProperty.call(i, o) && (t[o] = i[o]);
    return t;
  }, t.apply(this, arguments);
};
function i(t) {
  return t.endsWith("-v2") ? 2 : 1;
}
"function" == typeof SuppressedError && SuppressedError;
var e = function () {
    function e(t, i, e, n) {
      var o = this;
      this.iframe = null, this.iframeId = null, this.sessionId = "", this.$container = null, this.initialized = !1, this.onMessage = function (t) {
        o.onSdkMessage(t);
      }, this.baseUrl = t || this.getCurrentBaseUrl("https://api.sumsub.com", i.accessToken), this.config = i, this.config.packageVersion = this.getVersion(), this.callbacks = e, this.options = n;
    }
    return e.prototype.getVersion = function () {
      return "2.3.4";
    }, e.prototype.getCurrentBaseUrl = function (t, i) {
      var e = ["_act-sbx-jwt-", "_act-jwt-"].find(function (t) {
        return i.startsWith(t);
      });
      if (e) for (var n = 0, o = i.replace(e, "").replace("-v2", "").split(".").map(function (t) {
          try {
            return atob(t);
          } catch (i) {
            return t;
          }
        }).map(function (t) {
          try {
            return JSON.parse(t);
          } catch (i) {
            return t;
          }
        }); n < o.length; n++) {
        var s = o[n];
        if (null == s ? void 0 : s.url) return s.url;
      }
      return t;
    }, e.prototype.getIframeId = function () {
      if (2 === this.config.version) try {
        return btoa(this.config.accessToken);
      } catch (t) {
        return this.config.accessToken;
      }
      return String(Math.floor(1e8 * Math.random()));
    }, e.prototype.launch = function (t) {
      this.options.addViewportTag && this.addViewportTag(), this.iframe = this.createIframe(t), this.sessionId = "", this.iframeId = "id_" + this.getIframeId(), this.iframe && this.config ? (this.registerEventListener(), this.iframe.src = this.getIframeSrc()) : console.error("Idensic was not initialized since either provided element was not found in the DOM or invalid config");
    }, e.prototype.addViewportTag = function () {
      var t = document.getElementsByName("viewport");
      if (!t || 0 === t.length) {
        var i = document.createElement("meta");
        i.setAttribute("name", "viewport"), i.setAttribute("content", "width=device-width,user-scalable=yes"), document.head.appendChild(i);
      }
    }, e.prototype.createIframe = function (t) {
      var i = "string" == typeof t ? document.querySelector(t) : t;
      if (!i) return console.error("Provide a valid selector for the iframe container"), null;
      this.$container = i;
      var e = document.createElement("iframe");
      for (e.width = "100%", e.scrolling = "no", e.allow = "camera; microphone; geolocation; clipboard-write", e.setAttribute("frameborder", "0"); i.firstChild;) i.removeChild(i.firstChild);
      return i.appendChild(e), e;
    }, e.prototype.getIframeSrc = function () {
      var t = "?_=" + this.iframeId,
        i = 2 === this.config.version ? "/websdk" : "/idensic",
        e = this.baseUrl + i + "/websdk.html";
      return 2 === this.config.version && this.config.theme && (t += "&theme=" + this.config.theme), 2 === this.config.version && this.config.customizationName && (t += "&customizationName=" + encodeURIComponent(this.config.customizationName)), e + t;
    }, e.prototype.registerEventListener = function () {
      window.addEventListener("message", this.onMessage);
    }, e.prototype.onSdkMessage = function (i) {
      var e;
      if (this.baseUrl === i.origin) {
        var n = i.data;
        if (n.method && ~n.method.indexOf("idCheck") && (!this.sessionId || this.sessionId === n.sessionId) && (null === (e = this.iframe) || void 0 === e ? void 0 : e.contentWindow)) {
          if ("idCheck.onReady" == n.method && n.frameId === this.iframeId) {
            this.sessionId = n.sessionId;
            var o = {
              options: {
                adaptIframeHeight: this.options.adaptIframeHeight
              }
            };
            this.iframe.contentWindow.postMessage(t(t({
              method: "idCheck.init"
            }, this.config), o), "*");
          }
          if ("idCheck.onInitialized" == n.method && (this.initialized = !0), "idCheck.onResize" == n.method && this.options.adaptIframeHeight && (this.iframe.style.height = n.height + "px"), "idCheck.scrollTo" == n.method && this.options.adaptIframeHeight) this.scrollTo(n.top);else {
            var s = n.method;
            delete n.method, delete n.frameId, delete n.sessionId, "idCheck.onError" === s && "invalid-token" === n.code ? this.callExpirationHandler() : "idCheck.onError" === s && "function" == typeof this.callbacks.onError ? this.callbacks.onError(n) : "function" == typeof this.callbacks.onMessage && this.callbacks.onMessage(s, n);
          }
        }
      }
    }, e.prototype.callExpirationHandler = function () {
      var t = this,
        i = this.callbacks.expirationHandler;
      i.legacy ? i.handler(function (i) {
        return t.updateAccessTokenOrReinitialize(i);
      }) : i.handler().then(function (i) {
        return t.updateAccessTokenOrReinitialize(i);
      }, function (i) {
        null != i || (i = "Failed to update access token"), i.message && (i = i.message), "string" != typeof i && (i = String(i)), t.updateAccessToken(null, i);
      });
    }, e.prototype.updateAccessTokenOrReinitialize = function (t) {
      var e = i(t);
      if (this.iframe && !this.initialized && this.config.version != e) return this.sessionId = "", this.config.accessToken = t, this.config.version = e, this.baseUrl = this.getCurrentBaseUrl(this.baseUrl, t), this.iframeId = "id_" + this.getIframeId(), void (this.iframe.src = this.getIframeSrc());
      this.updateAccessToken(t);
    }, e.prototype.scrollTo = function (t) {
      for (var i, e, n = null === (i = this.iframe) || void 0 === i ? void 0 : i.parentElement; 0 === (null == n ? void 0 : n.scrollTop) && "BODY" !== (null == n ? void 0 : n.tagName);) n = null == n ? void 0 : n.parentElement;
      if (0 === (null == n ? void 0 : n.scrollTop) && "BODY" === (null == n ? void 0 : n.tagName)) {
        var o = (null === (e = this.iframe) || void 0 === e ? void 0 : e.getBoundingClientRect().top) || 0;
        window.scrollTo({
          top: o + t,
          behavior: "smooth"
        });
      } else null == n || n.scrollTo({
        top: t,
        behavior: "smooth"
      });
    }, e.prototype.updateAccessToken = function (t, i) {
      var e, n;
      null === (n = null === (e = this.iframe) || void 0 === e ? void 0 : e.contentWindow) || void 0 === n || n.postMessage({
        method: "idCheck.updateAccessToken",
        accessToken: t,
        error: i
      }, "*");
    }, e.prototype.destroy = function () {
      for (window.removeEventListener("message", this.onMessage); this.$container && this.$container.firstChild;) this.$container.removeChild(this.$container.firstChild);
      this.$container = null;
    }, e.prototype.navigateBack = function () {
      var t, i;
      null === (i = null === (t = this.iframe) || void 0 === t ? void 0 : t.contentWindow) || void 0 === i || i.postMessage({
        method: "idCheck.callNavigationBack"
      }, "*");
    }, e;
  }(),
  n = function () {
    function t(t, i) {
      if (this.config = null, this.reusableConfig = null, this.eventHandlers = {}, this.anyEventHandler = null, this.options = {
        adaptIframeHeight: !0,
        addViewportTag: !0
      }, "string" != typeof t) throw new Error("Access token must be a string");
      if ("function" != typeof i) throw new Error("updateAccessToken callback is required");
      this.accessToken = t, this.updateAccessToken = i;
    }
    return t.prototype.onTestEnv = function () {
      return this;
    }, t.prototype.withBaseUrl = function (t) {
      return this.baseUrl = t, this;
    }, t.prototype.withConf = function (t) {
      return this.config = t, this;
    }, t.prototype.withReusableKycConf = function (t) {
      return this.reusableConfig = t, this;
    }, t.prototype.withOptions = function (t) {
      return t.hasOwnProperty("adaptIframeHeight") && (this.options.adaptIframeHeight = t.adaptIframeHeight), t.hasOwnProperty("addViewportTag") && (this.options.addViewportTag = t.addViewportTag), this;
    }, t.prototype.on = function (t, i) {
      return this.eventHandlers[t] = i, this;
    }, t.prototype.onMessage = function (t) {
      return this.anyEventHandler = t, this;
    }, t.prototype.onNavigationUiControlsStateChanged = function (t) {
      return this.eventHandlers["idCheck.onNavigationUiControlsStateChanged"] = t, this;
    }, t.prototype.build = function () {
      var t,
        n,
        o,
        s,
        r,
        a,
        c,
        h,
        l,
        d,
        u,
        f,
        p = this,
        g = i(this.accessToken);
      return new e(this.baseUrl, {
        version: g,
        theme: null === (t = this.config) || void 0 === t ? void 0 : t.theme,
        customizationName: null === (n = this.config) || void 0 === n ? void 0 : n.customizationName,
        accessToken: this.accessToken,
        lang: null === (o = this.config) || void 0 === o ? void 0 : o.lang,
        email: null === (s = this.config) || void 0 === s ? void 0 : s.email,
        phone: null === (r = this.config) || void 0 === r ? void 0 : r.phone,
        country: null === (a = this.config) || void 0 === a ? void 0 : a.country,
        uiConf: null === (c = this.config) || void 0 === c ? void 0 : c.uiConf,
        i18n: null === (h = this.config) || void 0 === h ? void 0 : h.i18n,
        documentsByCountries: null === (l = this.config) || void 0 === l ? void 0 : l.documentsByCountries,
        documentDefinitions: null === (d = this.config) || void 0 === d ? void 0 : d.documentDefinitions,
        autoSelectDocumentDefinitions: null === (u = this.config) || void 0 === u ? void 0 : u.autoSelectDocumentDefinitions,
        controlledNavigationBack: null === (f = this.config) || void 0 === f ? void 0 : f.controlledNavigationBack,
        reusableConfig: this.reusableConfig
      }, {
        expirationHandler: {
          legacy: !1,
          handler: this.updateAccessToken
        },
        onMessage: function (t, i) {
          var e,
            n = p.eventHandlers[t];
          n ? n(i) : null === (e = p.anyEventHandler) || void 0 === e || e.call(p, t, i);
        }
      }, this.options);
    }, t;
  }(),
  o = function () {
    function t(t, i) {
      this.debugEnabled = !1, this.options = {
        adaptIframeHeight: !0,
        addViewportTag: !0
      }, this.config = null, this.reusableConfig = null, this.accessToken = null, this.expirationHandler = null, this.baseUrl = t, this.flowName = i;
    }
    return t.prototype.withAccessToken = function (t, i) {
      if (this.accessToken = t, !i || "function" != typeof i) throw new Error('Invalid parameter, "expirationHandler" must be a function');
      return this.expirationHandler = i, this;
    }, t.prototype.debug = function (t) {
      return this.debugEnabled = t, this;
    }, t.prototype.withOptions = function (t) {
      return t.hasOwnProperty("adaptIframeHeight") && (this.options.adaptIframeHeight = t.adaptIframeHeight), t.hasOwnProperty("addViewportTag") && (this.options.addViewportTag = t.addViewportTag), this;
    }, t.prototype.withConf = function (t) {
      return this.config = t, this;
    }, t.prototype.withReusableKycConf = function (t) {
      return this.reusableConfig = t, this;
    }, t.prototype.build = function () {
      var t, n, o, s, r, a, c, h, l, d, u, f, p;
      if (!this.accessToken || !this.expirationHandler) throw new Error("Configure access token end the expiration handler before");
      var g = i(this.accessToken);
      return new e(this.baseUrl, {
        version: g,
        theme: null === (t = this.config) || void 0 === t ? void 0 : t.theme,
        customizationName: null === (n = this.config) || void 0 === n ? void 0 : n.customizationName,
        accessToken: this.accessToken,
        flowName: this.flowName,
        lang: null === (o = this.config) || void 0 === o ? void 0 : o.lang,
        email: null === (s = this.config) || void 0 === s ? void 0 : s.email,
        phone: null === (r = this.config) || void 0 === r ? void 0 : r.phone,
        country: null === (a = this.config) || void 0 === a ? void 0 : a.country,
        uiConf: null === (c = this.config) || void 0 === c ? void 0 : c.uiConf,
        i18n: null === (h = this.config) || void 0 === h ? void 0 : h.i18n,
        documentsByCountries: null === (l = this.config) || void 0 === l ? void 0 : l.documentsByCountries,
        documentDefinitions: null === (d = this.config) || void 0 === d ? void 0 : d.documentDefinitions,
        autoSelectDocumentDefinitions: null === (u = this.config) || void 0 === u ? void 0 : u.autoSelectDocumentDefinitions,
        reusableConfig: this.reusableConfig
      }, {
        expirationHandler: {
          legacy: !0,
          handler: this.expirationHandler
        },
        onMessage: null === (f = this.config) || void 0 === f ? void 0 : f.onMessage,
        onError: null === (p = this.config) || void 0 === p ? void 0 : p.onError
      }, {
        adaptIframeHeight: this.options.adaptIframeHeight,
        addViewportTag: this.options.addViewportTag,
        debug: this.debugEnabled
      });
    }, t;
  }(),
  s = {
    Builder: function (t, i) {
      return new o(t, i);
    },
    init: function (t, i) {
      return new n(t, i);
    }
  };
export { s as default };