"use strict";

/**
 * Codec class
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidClassicAddress = exports.decodeAccountPublic = exports.encodeAccountPublic = exports.encodeNodePublic = exports.decodeNodePublic = exports.decodeAddress = exports.decodeAccountID = exports.encodeAddress = exports.encodeAccountID = exports.decodeSeed = exports.encodeSeed = exports.codec = void 0;
const base_1 = require("@scure/base");
const sha256_1 = require("@xrplf/isomorphic/sha256");
const utils_1 = require("./utils");
class Codec {
  constructor(options) {
    this._sha256 = options.sha256;
    this._codec = base_1.base58xrp;
  }
  /**
   * Encoder.
   *
   * @param bytes - Uint8Array of data to encode.
   * @param opts - Options object including the version bytes and the expected length of the data to encode.
   */
  encode(bytes, opts) {
    const versions = opts.versions;
    return this._encodeVersioned(bytes, versions, opts.expectedLength);
  }
  /**
   * Decoder.
   *
   * @param base58string - Base58Check-encoded string to decode.
   * @param opts - Options object including the version byte(s) and the expected length of the data after decoding.
   */
  /* eslint-disable max-lines-per-function --
   * TODO refactor */
  decode(base58string, opts) {
    var _a;
    const versions = opts.versions;
    const types = opts.versionTypes;
    const withoutSum = this.decodeChecked(base58string);
    if (versions.length > 1 && !opts.expectedLength) {
      throw new Error('expectedLength is required because there are >= 2 possible versions');
    }
    const versionLengthGuess = typeof versions[0] === 'number' ? 1 : versions[0].length;
    const payloadLength = (_a = opts.expectedLength) !== null && _a !== void 0 ? _a : withoutSum.length - versionLengthGuess;
    const versionBytes = withoutSum.slice(0, -payloadLength);
    const payload = withoutSum.slice(-payloadLength);
    for (let i = 0; i < versions.length; i++) {
      /* eslint-disable @typescript-eslint/consistent-type-assertions --
       * TODO refactor */
      const version = Array.isArray(versions[i]) ? versions[i] : [versions[i]];
      if ((0, utils_1.arrayEqual)(versionBytes, version)) {
        return {
          version,
          bytes: payload,
          type: types ? types[i] : null
        };
      }
      /* eslint-enable @typescript-eslint/consistent-type-assertions */
    }
    throw new Error('version_invalid: version bytes do not match any of the provided version(s)');
  }
  encodeChecked(bytes) {
    const check = this._sha256(this._sha256(bytes)).slice(0, 4);
    return this._encodeRaw(Uint8Array.from((0, utils_1.concatArgs)(bytes, check)));
  }
  decodeChecked(base58string) {
    const intArray = this._decodeRaw(base58string);
    if (intArray.byteLength < 5) {
      throw new Error('invalid_input_size: decoded data must have length >= 5');
    }
    if (!this._verifyCheckSum(intArray)) {
      throw new Error('checksum_invalid');
    }
    return intArray.slice(0, -4);
  }
  _encodeVersioned(bytes, versions, expectedLength) {
    if (!checkByteLength(bytes, expectedLength)) {
      throw new Error('unexpected_payload_length: bytes.length does not match expectedLength.' + ' Ensure that the bytes are a Uint8Array.');
    }
    return this.encodeChecked((0, utils_1.concatArgs)(versions, bytes));
  }
  _encodeRaw(bytes) {
    return this._codec.encode(Uint8Array.from(bytes));
  }
  /* eslint-enable max-lines-per-function */
  _decodeRaw(base58string) {
    return this._codec.decode(base58string);
  }
  _verifyCheckSum(bytes) {
    const computed = this._sha256(this._sha256(bytes.slice(0, -4))).slice(0, 4);
    const checksum = bytes.slice(-4);
    return (0, utils_1.arrayEqual)(computed, checksum);
  }
}
/**
 * XRP codec
 */
// base58 encodings: https://xrpl.org/base58-encodings.html
// Account address (20 bytes)
const ACCOUNT_ID = 0;
// Account public key (33 bytes)
const ACCOUNT_PUBLIC_KEY = 0x23;
// 33; Seed value (for secret keys) (16 bytes)
const FAMILY_SEED = 0x21;
// 28; Validation public key (33 bytes)
const NODE_PUBLIC = 0x1c;
// [1, 225, 75]
const ED25519_SEED = [0x01, 0xe1, 0x4b];
const codecOptions = {
  sha256: sha256_1.sha256
};
const codecWithXrpAlphabet = new Codec(codecOptions);
exports.codec = codecWithXrpAlphabet;
// entropy is a Uint8Array of size 16
// type is 'ed25519' or 'secp256k1'
function encodeSeed(entropy, type) {
  if (!checkByteLength(entropy, 16)) {
    throw new Error('entropy must have length 16');
  }
  const opts = {
    expectedLength: 16,
    // for secp256k1, use `FAMILY_SEED`
    versions: type === 'ed25519' ? ED25519_SEED : [FAMILY_SEED]
  };
  // prefixes entropy with version bytes
  return codecWithXrpAlphabet.encode(entropy, opts);
}
exports.encodeSeed = encodeSeed;
function decodeSeed(seed, opts = {
  versionTypes: ['ed25519', 'secp256k1'],
  versions: [ED25519_SEED, FAMILY_SEED],
  expectedLength: 16
}) {
  return codecWithXrpAlphabet.decode(seed, opts);
}
exports.decodeSeed = decodeSeed;
function encodeAccountID(bytes) {
  const opts = {
    versions: [ACCOUNT_ID],
    expectedLength: 20
  };
  return codecWithXrpAlphabet.encode(bytes, opts);
}
exports.encodeAccountID = encodeAccountID;
/* eslint-disable import/no-unused-modules ---
 * unclear why this is aliased but we should keep it in case someone else is
 * importing it with the aliased name */
exports.encodeAddress = encodeAccountID;
/* eslint-enable import/no-unused-modules */
function decodeAccountID(accountId) {
  const opts = {
    versions: [ACCOUNT_ID],
    expectedLength: 20
  };
  return codecWithXrpAlphabet.decode(accountId, opts).bytes;
}
exports.decodeAccountID = decodeAccountID;
/* eslint-disable import/no-unused-modules ---
 * unclear why this is aliased but we should keep it in case someone else is
 * importing it with the aliased name */
exports.decodeAddress = decodeAccountID;
/* eslint-enable import/no-unused-modules */
function decodeNodePublic(base58string) {
  const opts = {
    versions: [NODE_PUBLIC],
    expectedLength: 33
  };
  return codecWithXrpAlphabet.decode(base58string, opts).bytes;
}
exports.decodeNodePublic = decodeNodePublic;
function encodeNodePublic(bytes) {
  const opts = {
    versions: [NODE_PUBLIC],
    expectedLength: 33
  };
  return codecWithXrpAlphabet.encode(bytes, opts);
}
exports.encodeNodePublic = encodeNodePublic;
function encodeAccountPublic(bytes) {
  const opts = {
    versions: [ACCOUNT_PUBLIC_KEY],
    expectedLength: 33
  };
  return codecWithXrpAlphabet.encode(bytes, opts);
}
exports.encodeAccountPublic = encodeAccountPublic;
function decodeAccountPublic(base58string) {
  const opts = {
    versions: [ACCOUNT_PUBLIC_KEY],
    expectedLength: 33
  };
  return codecWithXrpAlphabet.decode(base58string, opts).bytes;
}
exports.decodeAccountPublic = decodeAccountPublic;
function isValidClassicAddress(address) {
  try {
    decodeAccountID(address);
  } catch (_error) {
    return false;
  }
  return true;
}
exports.isValidClassicAddress = isValidClassicAddress;
function checkByteLength(bytes, expectedLength) {
  return 'byteLength' in bytes ? bytes.byteLength === expectedLength : bytes.length === expectedLength;
}
