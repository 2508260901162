"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.randomBytes = exports.stringToHex = exports.hexToString = exports.hexToBytes = exports.bytesToHex = void 0;
const utils_1 = require("@noble/hashes/utils");
const shared_1 = require("./shared");
/* eslint-disable func-style -- Typed to ensure uniformity between node and browser implementations and docs */
const bytesToHex = bytes => {
  const hex = (0, utils_1.bytesToHex)(bytes instanceof Uint8Array ? bytes : Uint8Array.from(bytes));
  return hex.toUpperCase();
};
exports.bytesToHex = bytesToHex;
// A clone of hexToBytes from @noble/hashes without the length checks. This allows us to do our own checks.
const hexToBytes = hex => {
  const len = hex.length;
  const array = new Uint8Array(len / 2);
  if (!shared_1.HEX_REGEX.test(hex)) {
    throw new Error('Invalid hex string');
  }
  for (let i = 0; i < array.length; i++) {
    const j = i * 2;
    const hexByte = hex.slice(j, j + 2);
    const byte = Number.parseInt(hexByte, 16);
    if (Number.isNaN(byte) || byte < 0) {
      throw new Error('Invalid byte sequence');
    }
    array[i] = byte;
  }
  return array;
};
exports.hexToBytes = hexToBytes;
const hexToString = (hex, encoding = 'utf8') => {
  return new TextDecoder(encoding).decode((0, exports.hexToBytes)(hex));
};
exports.hexToString = hexToString;
const stringToHex = string => {
  return (0, exports.bytesToHex)(new TextEncoder().encode(string));
};
exports.stringToHex = stringToHex;
/* eslint-enable func-style */
exports.randomBytes = utils_1.randomBytes;
__exportStar(require("./shared"), exports);
