"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IJsonRpcRouter = void 0;
class IJsonRpcRouter {
  constructor(routes) {
    this.routes = routes;
  }
}
exports.IJsonRpcRouter = IJsonRpcRouter;
