import { SegmentService } from "ngx-segment-analytics";
import { LoggerService } from "../services/logger.service";

export class SegmentEventsUtil {
    constructor( 
        private logger: LoggerService,
        private segment: SegmentService,
        ) {}

   triggerEvent(event, payload) {
        switch (event) {
          case "tx-attempted":
            this.segment.track("send-asset-transaction-sign-attempted", payload)
              .then(() => this.logger.info("Send asset transaction sign attempted"))
              .catch((err) => { });
            break;
          case "tx-success":
            this.segment.track("send-asset-transaction-sign-success", payload)
              .then(() => this.logger.info("Send asset transaction sign success"))
              .catch((err) => { });
            break;
          case "tx-failed":
            this.segment.track("send-asset-transaction-sign-failed", payload)
              .then(() => this.logger.info("Send asset transaction sign failed"))
              .catch((err) => { });
            break;
          default: console.log("undefined event");
        }
      }
}