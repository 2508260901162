<div class="container-fluid" *ngIf="PDFDownload_component_for === 'wallet_key_card_pdf_download'?false:true">
<div class="row">
  <div class="col-xl-12 p-0">
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item">
        <a class="text-muted font-weight-bolder" routerLink='/settings'>Settings</a>
      </li>
      <li class="breadcrumb-item active">
        <span class="text-muted font-weight-bolder">Key Card</span>
      </li>
    </ol>
  </div>
  <ng-container *ngIf="res">
    <div class="col-md-4 col-12" *ngFor="let wallet of res">
      <div class="card">
        <div class="card-body wallet-details-box p-4">
          <div class="media border-after-xs pb-3">
            <div class="mr-3">
              <i class="icon-wallet icon-round-border text-primary"></i>
            </div>
            <div class="media-body details align-self-center">
              <div class="text-dot" [ngbTooltip]="wallet?.name" >{{ wallet.name }}</div>
              <div *ngIf="wallet?.chain" class="network text-dark {{ wallet.chain.toLowerCase() }}">
                <div class="text-dot" [ngbTooltip]="wallet?.chain | getName" [placement]="['top-left', 'auto']">
                  <span>{{ wallet.chain | getName }} </span>
                </div>
              </div>
            </div>
            <div class="media-body text-right align-self-center">
              <h6 class="card-balance">
                ${{ wallet.balanceUSD | number: "1.2-2" }}
              </h6>
            </div>
          </div>
          <div class="media border-after-xs">
            <div class="customers d-inline-block rounded-pill avatar-group col-8 px-0">
              <ul class="d-flex p-1">
                <li
                  class="d-inline-block"
                  *ngFor="let members of loadSigners(wallet)"
                >
                  <img
                    referrerpolicy="no-referrer"
                    class="align-self-center img-30 rounded-circle user-image text-left"
                    src="{{ members.photoURL }}"
                    title="{{ members.displayName }}"
                  />
                </li>
              </ul>
            </div>
            <div class="media-body text-right align-self-center col-4 px-0">
              <span
                role="button"
                (click)="view_card(wallet)"
                class="view-card text-muted pointer"
                >view card</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="!res">
    <div class="col-12 col-xl-4" *ngFor="let item of [].constructor(12); let i = index">
      <div class="card">
          <div class="p-4">
              <div class="d-block">
                <ul>
                    <li><ngx-skeleton-loader></ngx-skeleton-loader></li>
                    <li><ngx-skeleton-loader></ngx-skeleton-loader></li>
                    <li><ngx-skeleton-loader></ngx-skeleton-loader></li>
                    <li><ngx-skeleton-loader></ngx-skeleton-loader></li>
                  </ul>
              </div>
          </div>
        </div>
  </div>
  </ng-container>
</div>
</div>
<div *ngIf="PDFDownload_component_for === 'wallet_key_card_pdf_download'?true:false">
  <!-- <button role="button" (click)="view_card(_res)" class="btn btn-outline-primary btn-md"><i class="icon-wallet-keycard mr-2"></i> Download</button> -->
  <button class="btn-outline text-dark btn p-0 btn-unerline" (click)="view_card(_res)" [disabled]="!_res"><i class="icon-download mr-1"></i>Download</button>
</div>