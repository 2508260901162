import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginationFromTo'
  })
  export class paginationTOFrom implements PipeTransform {
    transform(length: any, page: any, pageSize: any): any {
      let from = ((page - 1) * pageSize) + 1;
    let to = page * pageSize < length ? page * pageSize : length;
    return `${from} to ${to}`;
    }
  }