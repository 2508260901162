<!-- TYPE: MPC -->
<ng-container>
    <!-- Column: Type -->
    <td class="m-t-10 pl-4">
        <div class="d-flex align-items-center">
            <img *ngIf="txdata?.isRbf && txdata?.speedUpTransactionList && txdata?.speedUpTransactionList.length"
                class="cursor-pointer mr-2 open-state" [ngClass]="{'open-state' :  txdata?.tx_state == 'open'}"
                (click)="onChangeTxState(txdata)"
                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBnc/RCcIwEAbg/z/wvSM4gitkAjeRggjSAUTwJW7gJu0IjtARfBc8c3ko9JqW0ntJ+C9fkgM2Fk/nJlJw5PcXYrz3S4fr+rrXnbRp24kIXlBWFlhjBaoIPJnDS3NQZQvqp/SyQyE+bm8OzRlcQnnG0c0OW1ZCE+ixzZ5WeGQlHub/U0NCfUJdCVn9AYaBV93eNb1AAAAAAElFTkSuQmCC' />
            <div class="d-flex flex-column" *ngIf="txdata?._mpcTxType?.tx_category_type === 'staking'">
                <span class="text-capitalize">{{txdata?._mpcTxType?.tx_category_type}} </span>
                <span class="text-capitalize">{{txdata?._mpcTxType?.tx_sub_category_type}} </span>
            </div>

            <div class="d-flex flex-column" *ngIf="txdata?._mpcTxType?.tx_category_type !== 'staking'">
                <span class="text-capitalize">Send </span>
            </div>
        </div>

        <div class="speed-up-pill" *ngIf="txdata?.isRbf" title="Most recent transaction">
            <img src="../../../../../assets/images/speed-transaction.svg" alt="speed-transaction.svg">
            <span>Speed Up</span>
        </div>
    </td>
    <!-- Column: Asset -->
    <td class="m-t-10" [ngClass]="{'pl-4':pendingtransactions_component_for === 'pentrs_wallet_details'}">
        <div class="d-flex">
            <img class="align-self-center mr-1 img-fluid img-round-border mr-2" [src]="txdata?.asset | getIcon">
            <div class="d-flex flex-column">
                <span>{{ txdata?.asset | getName }}</span>
                <span class="text-dark mr-6" *ngIf="txdata?.walletinfor?.type !== walletType.Exchange">{{ txdata?.walletinfor?.name }}</span>
                <span class="text-dark mr-6" *ngIf="txdata?.walletinfor?.type === walletType.Exchange">{{ txdata?.wallet?.account?.name }}</span>
            </div>
        </div>
    </td>
    <!-- Column: Value -->
    <td>
        <div class="d-flex flex-column">
            <span class="d-block" placement="top">{{txdata?.amount | amountToPretty : 5}} {{ txdata?.asset }}</span>
            <span class="d-block text-dark">${{txdata?.usdAmount | amountToPretty : 2}}</span>
        </div>
    </td>
    <!-- Column: WalletType -->
    <td appHideIfCustodyOrg>
        <span *ngIf="txdata?.walletinfor?.type === 'exchange'">
            Exchange
        </span>
        <span *ngIf="txdata?.walletinfor?.type !== 'exchange'">
            {{ getWalletType(txdata?.walletinfor?.type, txdata?.walletinfor?.subtype) }}
        </span>
        
    </td>
    <!-- Column: Details -->
    <td class="span-overflow m-t-2 whitespace-wrap">
        <div class="float-container">
            <div class="float-child second" *ngIf="txdata?.addressLabel">
                <a>
                    <span class="dottedtext" placement="top" ngbTooltip="{{txdata?.addressLabel}}">
                        {{txdata?.addressLabel}}</span>
                </a>
            </div>
            <div class="float-child second">
                <a>
                    <span class="dottedtext text-dark" ngbTooltip="{{txdata?.destinationAddress}}">
                        {{txdata?.destinationAddress.substring(0,8)}}...{{txdata?.destinationAddress.substring(txdata?.destinationAddress.length
                        - 5)}}
                    </span>
                </a>
            </div>
            <div *ngIf="txdata.memoData?.value">
                <span>Memo/Tag - {{txdata.memoData.value}}</span>
            </div>
        </div>
    </td>
    <!-- Column: Status -->
    <td class="text-muted m-t-10 body-font">
        <div class="status word-breakdiv">
            <div *ngIf="!txdata.txn_req_id && txdata.status === 1">
                <span class="">Initiating</span>
                <ngb-progressbar class="my-1 int-sign-progress" [value]="txdata.team.initiatorsSigns"
                    [max]="txdata.team.initiators" height="5px"></ngb-progressbar>
                <span class="d-flex text-dark"><b class="font-size-12">{{txdata.team.initiators -
                        txdata.team.initiatorsSigns}}</b> <span class="font-size-12 ml-1">Remaining</span></span>
            </div>
            <div *ngIf="txdata.txn_req_id && (txdata.status === ItransactionStatus.Pending || txdata.status === ItransactionStatus.ExchangeApprovalPending)">
                <span class="">{{txdata.status === ItransactionStatus.ExchangeApprovalPending ? teamActionType.APPROVAL : teamActionType.SIGNING}}</span>
                <ngb-progressbar class="my-1 int-sign-progress" [value]="txdata.team?.signersSigns"
                    [max]="txdata.team?.signers" height="5px"></ngb-progressbar>
                <span class="d-flex text-dark" *ngIf="txdata.team?.signers"><b class="font-size-12">{{txdata.team?.signers -
                        txdata.team?.signersSigns}}</b> <span class="font-size-12 ml-1">Remaining</span></span>
            </div>
            <div *ngIf="txdata.status === 2">
                <span class="">Pending</span>
                <ngb-progressbar class="my-1 pending-progress" [value]="100" [max]="100" height="5px"></ngb-progressbar>
                <span class="text-dark font-size-12">All Signed</span>
            </div>
            <div *ngIf="!txdata.txn_req_id && FIREWALL_PENDING_STATUS === txdata.status">
                <span>Pending</span>
                <ngb-progressbar class="my-1 int-sign-progress" max="100" value="50" height="5px"></ngb-progressbar>
                <span  *ngIf="txdata.livenessPending">Liveness Check</span>
                <span *ngIf="!txdata.livenessPending" class="d-flex text-dark font-size-12">1 Remaining</span>
            </div>
        </div>
    </td>
    <!-- Column: Members -->
    <td>
        <ng-container *ngIf="!(!txdata.txn_req_id && txdata.status === FIREWALL_PENDING_STATUS); else firewallMembers">
            <ng-container *ngIf="!(orgProfile.orgType == orgType.sub && orgProfile.custodyType == custodyType.CUSTODY && txdata.txn_req_id);else orgIcon">
                <app-signer-user-list [signerUserData]="txdata.txn_req_id?txdata.signersList:txdata.initiatorsList" [uniqId]="uniqId" [component_for]="'mpcTx'"></app-signer-user-list>
            </ng-container>
            <ng-template #orgIcon>
                <img src="../../../../assets/images/signer-logo.png" width="44" class="img-fluid" alt="logo">
            </ng-template>
        </ng-container>
        <ng-template #firewallMembers>
            <app-signer-user-list [signerUserData]="txdata.firewallTeamMembers?.length ? txdata.firewallTeamMembers[0].members : []" [uniqId]="uniqId" [component_for]="'mpcTx'"></app-signer-user-list>
        </ng-template>
    </td>
    <!-- Column: Notes -->
    <td>
        <div *ngIf="txdata?.comment">
            <img placement="top" [ngbTooltip]="list" width="25"
                src='../../../../assets/images/notes.png' />
            <ng-template #list>
                <div class="d-flex flex-column text-left text-dark">
                    <span>{{txdata?.comment}}</span>
                </div>
            </ng-template>
        </div>
        <div class="ml-2 text-dark" *ngIf="!txdata?.comment">
            -
        </div>
    </td>
    <!-- Column: Time -->
    <td class="time">
        <div>
            {{ txdata.createdAt | dateWithZone }}
        </div>
    </td>

    <!-- Column: Action -->
    <td *ngIf="txdata.walletinfor || txdata.status === ItransactionStatus.ExchangeApprovalPending" class="m-t-10 text-right pr-4">
        <div class="d-flex justify-content-end">
            <div class="d-flex justify-content-end mr-1" *ngIf="isChainSupported(txdata?.chain) && isInitiateRbfTxn(txdata)">
                <a href="javascript:void(0);" (click)="openSpeedUpTxnModal(txdata);" placement="top"
                    ngbTooltip="Speed up transaction" class="speedup-btn btn btn-round btn-sm">
                    Speed Up
                </a>
            </div>
            <button class="btn btn-primary-light btn-round btn-sm" title="View" (click)="onViewFirewallTransactionClick(txdata)">View</button>
        </div>
    </td>

</ng-container>