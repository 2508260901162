import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ComplianceTeamData, EditTeamRequest, TeamsListResponse, TeamsV2Request, UpdateComplianceTeamRequest, Wallet } from '../entities';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private apiService: ApiService) { }

  createTeam(team: TeamsV2Request): Observable<any> {
    return this.apiService.post(`/teams/v2/create`, team).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  editTeam(team: EditTeamRequest, token: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.apiService.put(`/teams/v2/update`, team, {headers}).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateComplianceTeam(data: UpdateComplianceTeamRequest, token: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.apiService.put(`/teams/v2/compliance/update`, data, {headers}).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getComplianceTeam(): Observable<ComplianceTeamData | null> {
    return this.apiService.get<ComplianceTeamData>(`/teams/v2/compliance`).pipe(
      map((response: any) => response.data[0])
    );
  }

  getTeamsByPage(pageNumber: number = 1, pageSize: number = 10, type?: number, searchString?: string): Observable<TeamsListResponse> {
    let url = this.generateTeamsListUrl(pageNumber, pageSize, type, searchString);
    url = url + '&activeUsers=true';
    return this.apiService.get<TeamsListResponse>(url)
      .pipe(map((response: any) => response.data));
  }

  getAllTeams(): Observable<TeamsListResponse> {
    return this.apiService.get<TeamsListResponse>(`/teams/v2/teamList?skipPagination=true&activeUsers=true`)
      .pipe(map((response: any) => response.data));
  }

  private generateTeamsListUrl(pageNumber: number, pageSize: number, type?: number, searchString?: string): string {
    let queryParams = `?page=${pageNumber}&pageSize=${pageSize}`;

    if (type !== undefined) {
      queryParams += `&type=${type}`;
    }

    if (searchString) {
      queryParams += `&searchTerm=${searchString}`;
    }

    return `/teams/v2/teamList${queryParams}`;
  }

  getWalletAssociatedWithTeam(teamId: number): Observable<(Wallet  & {approvalsNeeded: number})[]> {
    const walletTeamsUrl = `/wallet/ext/team/${teamId}`
    return this.apiService.get<{data: (Wallet  & {approvalsNeeded: number})[]}>(walletTeamsUrl).pipe(
      map(response => response.data)
    )
  }

}
