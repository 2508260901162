"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TxProof = exports.BlockMeta = exports.LightBlock = exports.SignedHeader = exports.Proposal = exports.CommitSig = exports.Commit = exports.Vote = exports.Data = exports.Header = exports.BlockID = exports.Part = exports.PartSetHeader = exports.signedMsgTypeToJSON = exports.signedMsgTypeFromJSON = exports.SignedMsgType = exports.blockIDFlagToJSON = exports.blockIDFlagFromJSON = exports.BlockIDFlag = exports.protobufPackage = void 0;
/* eslint-disable */
const proof_1 = require("../crypto/proof");
const types_1 = require("../version/types");
const timestamp_1 = require("../../google/protobuf/timestamp");
const validator_1 = require("./validator");
const binary_1 = require("../../binary");
const helpers_1 = require("../../helpers");
exports.protobufPackage = "tendermint.types";
/** BlockIdFlag indicates which BlcokID the signature is for */
var BlockIDFlag;
(function (BlockIDFlag) {
  BlockIDFlag[BlockIDFlag["BLOCK_ID_FLAG_UNKNOWN"] = 0] = "BLOCK_ID_FLAG_UNKNOWN";
  BlockIDFlag[BlockIDFlag["BLOCK_ID_FLAG_ABSENT"] = 1] = "BLOCK_ID_FLAG_ABSENT";
  BlockIDFlag[BlockIDFlag["BLOCK_ID_FLAG_COMMIT"] = 2] = "BLOCK_ID_FLAG_COMMIT";
  BlockIDFlag[BlockIDFlag["BLOCK_ID_FLAG_NIL"] = 3] = "BLOCK_ID_FLAG_NIL";
  BlockIDFlag[BlockIDFlag["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(BlockIDFlag || (exports.BlockIDFlag = BlockIDFlag = {}));
function blockIDFlagFromJSON(object) {
  switch (object) {
    case 0:
    case "BLOCK_ID_FLAG_UNKNOWN":
      return BlockIDFlag.BLOCK_ID_FLAG_UNKNOWN;
    case 1:
    case "BLOCK_ID_FLAG_ABSENT":
      return BlockIDFlag.BLOCK_ID_FLAG_ABSENT;
    case 2:
    case "BLOCK_ID_FLAG_COMMIT":
      return BlockIDFlag.BLOCK_ID_FLAG_COMMIT;
    case 3:
    case "BLOCK_ID_FLAG_NIL":
      return BlockIDFlag.BLOCK_ID_FLAG_NIL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BlockIDFlag.UNRECOGNIZED;
  }
}
exports.blockIDFlagFromJSON = blockIDFlagFromJSON;
function blockIDFlagToJSON(object) {
  switch (object) {
    case BlockIDFlag.BLOCK_ID_FLAG_UNKNOWN:
      return "BLOCK_ID_FLAG_UNKNOWN";
    case BlockIDFlag.BLOCK_ID_FLAG_ABSENT:
      return "BLOCK_ID_FLAG_ABSENT";
    case BlockIDFlag.BLOCK_ID_FLAG_COMMIT:
      return "BLOCK_ID_FLAG_COMMIT";
    case BlockIDFlag.BLOCK_ID_FLAG_NIL:
      return "BLOCK_ID_FLAG_NIL";
    case BlockIDFlag.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
exports.blockIDFlagToJSON = blockIDFlagToJSON;
/** SignedMsgType is a type of signed message in the consensus. */
var SignedMsgType;
(function (SignedMsgType) {
  SignedMsgType[SignedMsgType["SIGNED_MSG_TYPE_UNKNOWN"] = 0] = "SIGNED_MSG_TYPE_UNKNOWN";
  /** SIGNED_MSG_TYPE_PREVOTE - Votes */
  SignedMsgType[SignedMsgType["SIGNED_MSG_TYPE_PREVOTE"] = 1] = "SIGNED_MSG_TYPE_PREVOTE";
  SignedMsgType[SignedMsgType["SIGNED_MSG_TYPE_PRECOMMIT"] = 2] = "SIGNED_MSG_TYPE_PRECOMMIT";
  /** SIGNED_MSG_TYPE_PROPOSAL - Proposals */
  SignedMsgType[SignedMsgType["SIGNED_MSG_TYPE_PROPOSAL"] = 32] = "SIGNED_MSG_TYPE_PROPOSAL";
  SignedMsgType[SignedMsgType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SignedMsgType || (exports.SignedMsgType = SignedMsgType = {}));
function signedMsgTypeFromJSON(object) {
  switch (object) {
    case 0:
    case "SIGNED_MSG_TYPE_UNKNOWN":
      return SignedMsgType.SIGNED_MSG_TYPE_UNKNOWN;
    case 1:
    case "SIGNED_MSG_TYPE_PREVOTE":
      return SignedMsgType.SIGNED_MSG_TYPE_PREVOTE;
    case 2:
    case "SIGNED_MSG_TYPE_PRECOMMIT":
      return SignedMsgType.SIGNED_MSG_TYPE_PRECOMMIT;
    case 32:
    case "SIGNED_MSG_TYPE_PROPOSAL":
      return SignedMsgType.SIGNED_MSG_TYPE_PROPOSAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SignedMsgType.UNRECOGNIZED;
  }
}
exports.signedMsgTypeFromJSON = signedMsgTypeFromJSON;
function signedMsgTypeToJSON(object) {
  switch (object) {
    case SignedMsgType.SIGNED_MSG_TYPE_UNKNOWN:
      return "SIGNED_MSG_TYPE_UNKNOWN";
    case SignedMsgType.SIGNED_MSG_TYPE_PREVOTE:
      return "SIGNED_MSG_TYPE_PREVOTE";
    case SignedMsgType.SIGNED_MSG_TYPE_PRECOMMIT:
      return "SIGNED_MSG_TYPE_PRECOMMIT";
    case SignedMsgType.SIGNED_MSG_TYPE_PROPOSAL:
      return "SIGNED_MSG_TYPE_PROPOSAL";
    case SignedMsgType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
exports.signedMsgTypeToJSON = signedMsgTypeToJSON;
function createBasePartSetHeader() {
  return {
    total: 0,
    hash: new Uint8Array()
  };
}
exports.PartSetHeader = {
  typeUrl: "/tendermint.types.PartSetHeader",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.total !== 0) {
      writer.uint32(8).uint32(message.total);
    }
    if (message.hash.length !== 0) {
      writer.uint32(18).bytes(message.hash);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePartSetHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.total = reader.uint32();
          break;
        case 2:
          message.hash = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBasePartSetHeader();
    if ((0, helpers_1.isSet)(object.total)) obj.total = Number(object.total);
    if ((0, helpers_1.isSet)(object.hash)) obj.hash = (0, helpers_1.bytesFromBase64)(object.hash);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.total !== undefined && (obj.total = Math.round(message.total));
    message.hash !== undefined && (obj.hash = (0, helpers_1.base64FromBytes)(message.hash !== undefined ? message.hash : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBasePartSetHeader();
    message.total = object.total ?? 0;
    message.hash = object.hash ?? new Uint8Array();
    return message;
  }
};
function createBasePart() {
  return {
    index: 0,
    bytes: new Uint8Array(),
    proof: proof_1.Proof.fromPartial({})
  };
}
exports.Part = {
  typeUrl: "/tendermint.types.Part",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.index !== 0) {
      writer.uint32(8).uint32(message.index);
    }
    if (message.bytes.length !== 0) {
      writer.uint32(18).bytes(message.bytes);
    }
    if (message.proof !== undefined) {
      proof_1.Proof.encode(message.proof, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePart();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.index = reader.uint32();
          break;
        case 2:
          message.bytes = reader.bytes();
          break;
        case 3:
          message.proof = proof_1.Proof.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBasePart();
    if ((0, helpers_1.isSet)(object.index)) obj.index = Number(object.index);
    if ((0, helpers_1.isSet)(object.bytes)) obj.bytes = (0, helpers_1.bytesFromBase64)(object.bytes);
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = proof_1.Proof.fromJSON(object.proof);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.bytes !== undefined && (obj.bytes = (0, helpers_1.base64FromBytes)(message.bytes !== undefined ? message.bytes : new Uint8Array()));
    message.proof !== undefined && (obj.proof = message.proof ? proof_1.Proof.toJSON(message.proof) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBasePart();
    message.index = object.index ?? 0;
    message.bytes = object.bytes ?? new Uint8Array();
    if (object.proof !== undefined && object.proof !== null) {
      message.proof = proof_1.Proof.fromPartial(object.proof);
    }
    return message;
  }
};
function createBaseBlockID() {
  return {
    hash: new Uint8Array(),
    partSetHeader: exports.PartSetHeader.fromPartial({})
  };
}
exports.BlockID = {
  typeUrl: "/tendermint.types.BlockID",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.hash.length !== 0) {
      writer.uint32(10).bytes(message.hash);
    }
    if (message.partSetHeader !== undefined) {
      exports.PartSetHeader.encode(message.partSetHeader, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlockID();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hash = reader.bytes();
          break;
        case 2:
          message.partSetHeader = exports.PartSetHeader.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseBlockID();
    if ((0, helpers_1.isSet)(object.hash)) obj.hash = (0, helpers_1.bytesFromBase64)(object.hash);
    if ((0, helpers_1.isSet)(object.partSetHeader)) obj.partSetHeader = exports.PartSetHeader.fromJSON(object.partSetHeader);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.hash !== undefined && (obj.hash = (0, helpers_1.base64FromBytes)(message.hash !== undefined ? message.hash : new Uint8Array()));
    message.partSetHeader !== undefined && (obj.partSetHeader = message.partSetHeader ? exports.PartSetHeader.toJSON(message.partSetHeader) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseBlockID();
    message.hash = object.hash ?? new Uint8Array();
    if (object.partSetHeader !== undefined && object.partSetHeader !== null) {
      message.partSetHeader = exports.PartSetHeader.fromPartial(object.partSetHeader);
    }
    return message;
  }
};
function createBaseHeader() {
  return {
    version: types_1.Consensus.fromPartial({}),
    chainId: "",
    height: BigInt(0),
    time: timestamp_1.Timestamp.fromPartial({}),
    lastBlockId: exports.BlockID.fromPartial({}),
    lastCommitHash: new Uint8Array(),
    dataHash: new Uint8Array(),
    validatorsHash: new Uint8Array(),
    nextValidatorsHash: new Uint8Array(),
    consensusHash: new Uint8Array(),
    appHash: new Uint8Array(),
    lastResultsHash: new Uint8Array(),
    evidenceHash: new Uint8Array(),
    proposerAddress: new Uint8Array()
  };
}
exports.Header = {
  typeUrl: "/tendermint.types.Header",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.version !== undefined) {
      types_1.Consensus.encode(message.version, writer.uint32(10).fork()).ldelim();
    }
    if (message.chainId !== "") {
      writer.uint32(18).string(message.chainId);
    }
    if (message.height !== BigInt(0)) {
      writer.uint32(24).int64(message.height);
    }
    if (message.time !== undefined) {
      timestamp_1.Timestamp.encode(message.time, writer.uint32(34).fork()).ldelim();
    }
    if (message.lastBlockId !== undefined) {
      exports.BlockID.encode(message.lastBlockId, writer.uint32(42).fork()).ldelim();
    }
    if (message.lastCommitHash.length !== 0) {
      writer.uint32(50).bytes(message.lastCommitHash);
    }
    if (message.dataHash.length !== 0) {
      writer.uint32(58).bytes(message.dataHash);
    }
    if (message.validatorsHash.length !== 0) {
      writer.uint32(66).bytes(message.validatorsHash);
    }
    if (message.nextValidatorsHash.length !== 0) {
      writer.uint32(74).bytes(message.nextValidatorsHash);
    }
    if (message.consensusHash.length !== 0) {
      writer.uint32(82).bytes(message.consensusHash);
    }
    if (message.appHash.length !== 0) {
      writer.uint32(90).bytes(message.appHash);
    }
    if (message.lastResultsHash.length !== 0) {
      writer.uint32(98).bytes(message.lastResultsHash);
    }
    if (message.evidenceHash.length !== 0) {
      writer.uint32(106).bytes(message.evidenceHash);
    }
    if (message.proposerAddress.length !== 0) {
      writer.uint32(114).bytes(message.proposerAddress);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.version = types_1.Consensus.decode(reader, reader.uint32());
          break;
        case 2:
          message.chainId = reader.string();
          break;
        case 3:
          message.height = reader.int64();
          break;
        case 4:
          message.time = timestamp_1.Timestamp.decode(reader, reader.uint32());
          break;
        case 5:
          message.lastBlockId = exports.BlockID.decode(reader, reader.uint32());
          break;
        case 6:
          message.lastCommitHash = reader.bytes();
          break;
        case 7:
          message.dataHash = reader.bytes();
          break;
        case 8:
          message.validatorsHash = reader.bytes();
          break;
        case 9:
          message.nextValidatorsHash = reader.bytes();
          break;
        case 10:
          message.consensusHash = reader.bytes();
          break;
        case 11:
          message.appHash = reader.bytes();
          break;
        case 12:
          message.lastResultsHash = reader.bytes();
          break;
        case 13:
          message.evidenceHash = reader.bytes();
          break;
        case 14:
          message.proposerAddress = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseHeader();
    if ((0, helpers_1.isSet)(object.version)) obj.version = types_1.Consensus.fromJSON(object.version);
    if ((0, helpers_1.isSet)(object.chainId)) obj.chainId = String(object.chainId);
    if ((0, helpers_1.isSet)(object.height)) obj.height = BigInt(object.height.toString());
    if ((0, helpers_1.isSet)(object.time)) obj.time = (0, helpers_1.fromJsonTimestamp)(object.time);
    if ((0, helpers_1.isSet)(object.lastBlockId)) obj.lastBlockId = exports.BlockID.fromJSON(object.lastBlockId);
    if ((0, helpers_1.isSet)(object.lastCommitHash)) obj.lastCommitHash = (0, helpers_1.bytesFromBase64)(object.lastCommitHash);
    if ((0, helpers_1.isSet)(object.dataHash)) obj.dataHash = (0, helpers_1.bytesFromBase64)(object.dataHash);
    if ((0, helpers_1.isSet)(object.validatorsHash)) obj.validatorsHash = (0, helpers_1.bytesFromBase64)(object.validatorsHash);
    if ((0, helpers_1.isSet)(object.nextValidatorsHash)) obj.nextValidatorsHash = (0, helpers_1.bytesFromBase64)(object.nextValidatorsHash);
    if ((0, helpers_1.isSet)(object.consensusHash)) obj.consensusHash = (0, helpers_1.bytesFromBase64)(object.consensusHash);
    if ((0, helpers_1.isSet)(object.appHash)) obj.appHash = (0, helpers_1.bytesFromBase64)(object.appHash);
    if ((0, helpers_1.isSet)(object.lastResultsHash)) obj.lastResultsHash = (0, helpers_1.bytesFromBase64)(object.lastResultsHash);
    if ((0, helpers_1.isSet)(object.evidenceHash)) obj.evidenceHash = (0, helpers_1.bytesFromBase64)(object.evidenceHash);
    if ((0, helpers_1.isSet)(object.proposerAddress)) obj.proposerAddress = (0, helpers_1.bytesFromBase64)(object.proposerAddress);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.version !== undefined && (obj.version = message.version ? types_1.Consensus.toJSON(message.version) : undefined);
    message.chainId !== undefined && (obj.chainId = message.chainId);
    message.height !== undefined && (obj.height = (message.height || BigInt(0)).toString());
    message.time !== undefined && (obj.time = (0, helpers_1.fromTimestamp)(message.time).toISOString());
    message.lastBlockId !== undefined && (obj.lastBlockId = message.lastBlockId ? exports.BlockID.toJSON(message.lastBlockId) : undefined);
    message.lastCommitHash !== undefined && (obj.lastCommitHash = (0, helpers_1.base64FromBytes)(message.lastCommitHash !== undefined ? message.lastCommitHash : new Uint8Array()));
    message.dataHash !== undefined && (obj.dataHash = (0, helpers_1.base64FromBytes)(message.dataHash !== undefined ? message.dataHash : new Uint8Array()));
    message.validatorsHash !== undefined && (obj.validatorsHash = (0, helpers_1.base64FromBytes)(message.validatorsHash !== undefined ? message.validatorsHash : new Uint8Array()));
    message.nextValidatorsHash !== undefined && (obj.nextValidatorsHash = (0, helpers_1.base64FromBytes)(message.nextValidatorsHash !== undefined ? message.nextValidatorsHash : new Uint8Array()));
    message.consensusHash !== undefined && (obj.consensusHash = (0, helpers_1.base64FromBytes)(message.consensusHash !== undefined ? message.consensusHash : new Uint8Array()));
    message.appHash !== undefined && (obj.appHash = (0, helpers_1.base64FromBytes)(message.appHash !== undefined ? message.appHash : new Uint8Array()));
    message.lastResultsHash !== undefined && (obj.lastResultsHash = (0, helpers_1.base64FromBytes)(message.lastResultsHash !== undefined ? message.lastResultsHash : new Uint8Array()));
    message.evidenceHash !== undefined && (obj.evidenceHash = (0, helpers_1.base64FromBytes)(message.evidenceHash !== undefined ? message.evidenceHash : new Uint8Array()));
    message.proposerAddress !== undefined && (obj.proposerAddress = (0, helpers_1.base64FromBytes)(message.proposerAddress !== undefined ? message.proposerAddress : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBaseHeader();
    if (object.version !== undefined && object.version !== null) {
      message.version = types_1.Consensus.fromPartial(object.version);
    }
    message.chainId = object.chainId ?? "";
    if (object.height !== undefined && object.height !== null) {
      message.height = BigInt(object.height.toString());
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = timestamp_1.Timestamp.fromPartial(object.time);
    }
    if (object.lastBlockId !== undefined && object.lastBlockId !== null) {
      message.lastBlockId = exports.BlockID.fromPartial(object.lastBlockId);
    }
    message.lastCommitHash = object.lastCommitHash ?? new Uint8Array();
    message.dataHash = object.dataHash ?? new Uint8Array();
    message.validatorsHash = object.validatorsHash ?? new Uint8Array();
    message.nextValidatorsHash = object.nextValidatorsHash ?? new Uint8Array();
    message.consensusHash = object.consensusHash ?? new Uint8Array();
    message.appHash = object.appHash ?? new Uint8Array();
    message.lastResultsHash = object.lastResultsHash ?? new Uint8Array();
    message.evidenceHash = object.evidenceHash ?? new Uint8Array();
    message.proposerAddress = object.proposerAddress ?? new Uint8Array();
    return message;
  }
};
function createBaseData() {
  return {
    txs: []
  };
}
exports.Data = {
  typeUrl: "/tendermint.types.Data",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.txs) {
      writer.uint32(10).bytes(v);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.txs.push(reader.bytes());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseData();
    if (Array.isArray(object?.txs)) obj.txs = object.txs.map(e => (0, helpers_1.bytesFromBase64)(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.txs) {
      obj.txs = message.txs.map(e => (0, helpers_1.base64FromBytes)(e !== undefined ? e : new Uint8Array()));
    } else {
      obj.txs = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBaseData();
    message.txs = object.txs?.map(e => e) || [];
    return message;
  }
};
function createBaseVote() {
  return {
    type: 0,
    height: BigInt(0),
    round: 0,
    blockId: exports.BlockID.fromPartial({}),
    timestamp: timestamp_1.Timestamp.fromPartial({}),
    validatorAddress: new Uint8Array(),
    validatorIndex: 0,
    signature: new Uint8Array()
  };
}
exports.Vote = {
  typeUrl: "/tendermint.types.Vote",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.height !== BigInt(0)) {
      writer.uint32(16).int64(message.height);
    }
    if (message.round !== 0) {
      writer.uint32(24).int32(message.round);
    }
    if (message.blockId !== undefined) {
      exports.BlockID.encode(message.blockId, writer.uint32(34).fork()).ldelim();
    }
    if (message.timestamp !== undefined) {
      timestamp_1.Timestamp.encode(message.timestamp, writer.uint32(42).fork()).ldelim();
    }
    if (message.validatorAddress.length !== 0) {
      writer.uint32(50).bytes(message.validatorAddress);
    }
    if (message.validatorIndex !== 0) {
      writer.uint32(56).int32(message.validatorIndex);
    }
    if (message.signature.length !== 0) {
      writer.uint32(66).bytes(message.signature);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.int32();
          break;
        case 2:
          message.height = reader.int64();
          break;
        case 3:
          message.round = reader.int32();
          break;
        case 4:
          message.blockId = exports.BlockID.decode(reader, reader.uint32());
          break;
        case 5:
          message.timestamp = timestamp_1.Timestamp.decode(reader, reader.uint32());
          break;
        case 6:
          message.validatorAddress = reader.bytes();
          break;
        case 7:
          message.validatorIndex = reader.int32();
          break;
        case 8:
          message.signature = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseVote();
    if ((0, helpers_1.isSet)(object.type)) obj.type = signedMsgTypeFromJSON(object.type);
    if ((0, helpers_1.isSet)(object.height)) obj.height = BigInt(object.height.toString());
    if ((0, helpers_1.isSet)(object.round)) obj.round = Number(object.round);
    if ((0, helpers_1.isSet)(object.blockId)) obj.blockId = exports.BlockID.fromJSON(object.blockId);
    if ((0, helpers_1.isSet)(object.timestamp)) obj.timestamp = (0, helpers_1.fromJsonTimestamp)(object.timestamp);
    if ((0, helpers_1.isSet)(object.validatorAddress)) obj.validatorAddress = (0, helpers_1.bytesFromBase64)(object.validatorAddress);
    if ((0, helpers_1.isSet)(object.validatorIndex)) obj.validatorIndex = Number(object.validatorIndex);
    if ((0, helpers_1.isSet)(object.signature)) obj.signature = (0, helpers_1.bytesFromBase64)(object.signature);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.type !== undefined && (obj.type = signedMsgTypeToJSON(message.type));
    message.height !== undefined && (obj.height = (message.height || BigInt(0)).toString());
    message.round !== undefined && (obj.round = Math.round(message.round));
    message.blockId !== undefined && (obj.blockId = message.blockId ? exports.BlockID.toJSON(message.blockId) : undefined);
    message.timestamp !== undefined && (obj.timestamp = (0, helpers_1.fromTimestamp)(message.timestamp).toISOString());
    message.validatorAddress !== undefined && (obj.validatorAddress = (0, helpers_1.base64FromBytes)(message.validatorAddress !== undefined ? message.validatorAddress : new Uint8Array()));
    message.validatorIndex !== undefined && (obj.validatorIndex = Math.round(message.validatorIndex));
    message.signature !== undefined && (obj.signature = (0, helpers_1.base64FromBytes)(message.signature !== undefined ? message.signature : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBaseVote();
    message.type = object.type ?? 0;
    if (object.height !== undefined && object.height !== null) {
      message.height = BigInt(object.height.toString());
    }
    message.round = object.round ?? 0;
    if (object.blockId !== undefined && object.blockId !== null) {
      message.blockId = exports.BlockID.fromPartial(object.blockId);
    }
    if (object.timestamp !== undefined && object.timestamp !== null) {
      message.timestamp = timestamp_1.Timestamp.fromPartial(object.timestamp);
    }
    message.validatorAddress = object.validatorAddress ?? new Uint8Array();
    message.validatorIndex = object.validatorIndex ?? 0;
    message.signature = object.signature ?? new Uint8Array();
    return message;
  }
};
function createBaseCommit() {
  return {
    height: BigInt(0),
    round: 0,
    blockId: exports.BlockID.fromPartial({}),
    signatures: []
  };
}
exports.Commit = {
  typeUrl: "/tendermint.types.Commit",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.height !== BigInt(0)) {
      writer.uint32(8).int64(message.height);
    }
    if (message.round !== 0) {
      writer.uint32(16).int32(message.round);
    }
    if (message.blockId !== undefined) {
      exports.BlockID.encode(message.blockId, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.signatures) {
      exports.CommitSig.encode(v, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.height = reader.int64();
          break;
        case 2:
          message.round = reader.int32();
          break;
        case 3:
          message.blockId = exports.BlockID.decode(reader, reader.uint32());
          break;
        case 4:
          message.signatures.push(exports.CommitSig.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseCommit();
    if ((0, helpers_1.isSet)(object.height)) obj.height = BigInt(object.height.toString());
    if ((0, helpers_1.isSet)(object.round)) obj.round = Number(object.round);
    if ((0, helpers_1.isSet)(object.blockId)) obj.blockId = exports.BlockID.fromJSON(object.blockId);
    if (Array.isArray(object?.signatures)) obj.signatures = object.signatures.map(e => exports.CommitSig.fromJSON(e));
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.height !== undefined && (obj.height = (message.height || BigInt(0)).toString());
    message.round !== undefined && (obj.round = Math.round(message.round));
    message.blockId !== undefined && (obj.blockId = message.blockId ? exports.BlockID.toJSON(message.blockId) : undefined);
    if (message.signatures) {
      obj.signatures = message.signatures.map(e => e ? exports.CommitSig.toJSON(e) : undefined);
    } else {
      obj.signatures = [];
    }
    return obj;
  },
  fromPartial(object) {
    const message = createBaseCommit();
    if (object.height !== undefined && object.height !== null) {
      message.height = BigInt(object.height.toString());
    }
    message.round = object.round ?? 0;
    if (object.blockId !== undefined && object.blockId !== null) {
      message.blockId = exports.BlockID.fromPartial(object.blockId);
    }
    message.signatures = object.signatures?.map(e => exports.CommitSig.fromPartial(e)) || [];
    return message;
  }
};
function createBaseCommitSig() {
  return {
    blockIdFlag: 0,
    validatorAddress: new Uint8Array(),
    timestamp: timestamp_1.Timestamp.fromPartial({}),
    signature: new Uint8Array()
  };
}
exports.CommitSig = {
  typeUrl: "/tendermint.types.CommitSig",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.blockIdFlag !== 0) {
      writer.uint32(8).int32(message.blockIdFlag);
    }
    if (message.validatorAddress.length !== 0) {
      writer.uint32(18).bytes(message.validatorAddress);
    }
    if (message.timestamp !== undefined) {
      timestamp_1.Timestamp.encode(message.timestamp, writer.uint32(26).fork()).ldelim();
    }
    if (message.signature.length !== 0) {
      writer.uint32(34).bytes(message.signature);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommitSig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.blockIdFlag = reader.int32();
          break;
        case 2:
          message.validatorAddress = reader.bytes();
          break;
        case 3:
          message.timestamp = timestamp_1.Timestamp.decode(reader, reader.uint32());
          break;
        case 4:
          message.signature = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseCommitSig();
    if ((0, helpers_1.isSet)(object.blockIdFlag)) obj.blockIdFlag = blockIDFlagFromJSON(object.blockIdFlag);
    if ((0, helpers_1.isSet)(object.validatorAddress)) obj.validatorAddress = (0, helpers_1.bytesFromBase64)(object.validatorAddress);
    if ((0, helpers_1.isSet)(object.timestamp)) obj.timestamp = (0, helpers_1.fromJsonTimestamp)(object.timestamp);
    if ((0, helpers_1.isSet)(object.signature)) obj.signature = (0, helpers_1.bytesFromBase64)(object.signature);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.blockIdFlag !== undefined && (obj.blockIdFlag = blockIDFlagToJSON(message.blockIdFlag));
    message.validatorAddress !== undefined && (obj.validatorAddress = (0, helpers_1.base64FromBytes)(message.validatorAddress !== undefined ? message.validatorAddress : new Uint8Array()));
    message.timestamp !== undefined && (obj.timestamp = (0, helpers_1.fromTimestamp)(message.timestamp).toISOString());
    message.signature !== undefined && (obj.signature = (0, helpers_1.base64FromBytes)(message.signature !== undefined ? message.signature : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBaseCommitSig();
    message.blockIdFlag = object.blockIdFlag ?? 0;
    message.validatorAddress = object.validatorAddress ?? new Uint8Array();
    if (object.timestamp !== undefined && object.timestamp !== null) {
      message.timestamp = timestamp_1.Timestamp.fromPartial(object.timestamp);
    }
    message.signature = object.signature ?? new Uint8Array();
    return message;
  }
};
function createBaseProposal() {
  return {
    type: 0,
    height: BigInt(0),
    round: 0,
    polRound: 0,
    blockId: exports.BlockID.fromPartial({}),
    timestamp: timestamp_1.Timestamp.fromPartial({}),
    signature: new Uint8Array()
  };
}
exports.Proposal = {
  typeUrl: "/tendermint.types.Proposal",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.height !== BigInt(0)) {
      writer.uint32(16).int64(message.height);
    }
    if (message.round !== 0) {
      writer.uint32(24).int32(message.round);
    }
    if (message.polRound !== 0) {
      writer.uint32(32).int32(message.polRound);
    }
    if (message.blockId !== undefined) {
      exports.BlockID.encode(message.blockId, writer.uint32(42).fork()).ldelim();
    }
    if (message.timestamp !== undefined) {
      timestamp_1.Timestamp.encode(message.timestamp, writer.uint32(50).fork()).ldelim();
    }
    if (message.signature.length !== 0) {
      writer.uint32(58).bytes(message.signature);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.int32();
          break;
        case 2:
          message.height = reader.int64();
          break;
        case 3:
          message.round = reader.int32();
          break;
        case 4:
          message.polRound = reader.int32();
          break;
        case 5:
          message.blockId = exports.BlockID.decode(reader, reader.uint32());
          break;
        case 6:
          message.timestamp = timestamp_1.Timestamp.decode(reader, reader.uint32());
          break;
        case 7:
          message.signature = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseProposal();
    if ((0, helpers_1.isSet)(object.type)) obj.type = signedMsgTypeFromJSON(object.type);
    if ((0, helpers_1.isSet)(object.height)) obj.height = BigInt(object.height.toString());
    if ((0, helpers_1.isSet)(object.round)) obj.round = Number(object.round);
    if ((0, helpers_1.isSet)(object.polRound)) obj.polRound = Number(object.polRound);
    if ((0, helpers_1.isSet)(object.blockId)) obj.blockId = exports.BlockID.fromJSON(object.blockId);
    if ((0, helpers_1.isSet)(object.timestamp)) obj.timestamp = (0, helpers_1.fromJsonTimestamp)(object.timestamp);
    if ((0, helpers_1.isSet)(object.signature)) obj.signature = (0, helpers_1.bytesFromBase64)(object.signature);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.type !== undefined && (obj.type = signedMsgTypeToJSON(message.type));
    message.height !== undefined && (obj.height = (message.height || BigInt(0)).toString());
    message.round !== undefined && (obj.round = Math.round(message.round));
    message.polRound !== undefined && (obj.polRound = Math.round(message.polRound));
    message.blockId !== undefined && (obj.blockId = message.blockId ? exports.BlockID.toJSON(message.blockId) : undefined);
    message.timestamp !== undefined && (obj.timestamp = (0, helpers_1.fromTimestamp)(message.timestamp).toISOString());
    message.signature !== undefined && (obj.signature = (0, helpers_1.base64FromBytes)(message.signature !== undefined ? message.signature : new Uint8Array()));
    return obj;
  },
  fromPartial(object) {
    const message = createBaseProposal();
    message.type = object.type ?? 0;
    if (object.height !== undefined && object.height !== null) {
      message.height = BigInt(object.height.toString());
    }
    message.round = object.round ?? 0;
    message.polRound = object.polRound ?? 0;
    if (object.blockId !== undefined && object.blockId !== null) {
      message.blockId = exports.BlockID.fromPartial(object.blockId);
    }
    if (object.timestamp !== undefined && object.timestamp !== null) {
      message.timestamp = timestamp_1.Timestamp.fromPartial(object.timestamp);
    }
    message.signature = object.signature ?? new Uint8Array();
    return message;
  }
};
function createBaseSignedHeader() {
  return {
    header: undefined,
    commit: undefined
  };
}
exports.SignedHeader = {
  typeUrl: "/tendermint.types.SignedHeader",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.header !== undefined) {
      exports.Header.encode(message.header, writer.uint32(10).fork()).ldelim();
    }
    if (message.commit !== undefined) {
      exports.Commit.encode(message.commit, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignedHeader();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.header = exports.Header.decode(reader, reader.uint32());
          break;
        case 2:
          message.commit = exports.Commit.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseSignedHeader();
    if ((0, helpers_1.isSet)(object.header)) obj.header = exports.Header.fromJSON(object.header);
    if ((0, helpers_1.isSet)(object.commit)) obj.commit = exports.Commit.fromJSON(object.commit);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.header !== undefined && (obj.header = message.header ? exports.Header.toJSON(message.header) : undefined);
    message.commit !== undefined && (obj.commit = message.commit ? exports.Commit.toJSON(message.commit) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseSignedHeader();
    if (object.header !== undefined && object.header !== null) {
      message.header = exports.Header.fromPartial(object.header);
    }
    if (object.commit !== undefined && object.commit !== null) {
      message.commit = exports.Commit.fromPartial(object.commit);
    }
    return message;
  }
};
function createBaseLightBlock() {
  return {
    signedHeader: undefined,
    validatorSet: undefined
  };
}
exports.LightBlock = {
  typeUrl: "/tendermint.types.LightBlock",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.signedHeader !== undefined) {
      exports.SignedHeader.encode(message.signedHeader, writer.uint32(10).fork()).ldelim();
    }
    if (message.validatorSet !== undefined) {
      validator_1.ValidatorSet.encode(message.validatorSet, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLightBlock();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.signedHeader = exports.SignedHeader.decode(reader, reader.uint32());
          break;
        case 2:
          message.validatorSet = validator_1.ValidatorSet.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseLightBlock();
    if ((0, helpers_1.isSet)(object.signedHeader)) obj.signedHeader = exports.SignedHeader.fromJSON(object.signedHeader);
    if ((0, helpers_1.isSet)(object.validatorSet)) obj.validatorSet = validator_1.ValidatorSet.fromJSON(object.validatorSet);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.signedHeader !== undefined && (obj.signedHeader = message.signedHeader ? exports.SignedHeader.toJSON(message.signedHeader) : undefined);
    message.validatorSet !== undefined && (obj.validatorSet = message.validatorSet ? validator_1.ValidatorSet.toJSON(message.validatorSet) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseLightBlock();
    if (object.signedHeader !== undefined && object.signedHeader !== null) {
      message.signedHeader = exports.SignedHeader.fromPartial(object.signedHeader);
    }
    if (object.validatorSet !== undefined && object.validatorSet !== null) {
      message.validatorSet = validator_1.ValidatorSet.fromPartial(object.validatorSet);
    }
    return message;
  }
};
function createBaseBlockMeta() {
  return {
    blockId: exports.BlockID.fromPartial({}),
    blockSize: BigInt(0),
    header: exports.Header.fromPartial({}),
    numTxs: BigInt(0)
  };
}
exports.BlockMeta = {
  typeUrl: "/tendermint.types.BlockMeta",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.blockId !== undefined) {
      exports.BlockID.encode(message.blockId, writer.uint32(10).fork()).ldelim();
    }
    if (message.blockSize !== BigInt(0)) {
      writer.uint32(16).int64(message.blockSize);
    }
    if (message.header !== undefined) {
      exports.Header.encode(message.header, writer.uint32(26).fork()).ldelim();
    }
    if (message.numTxs !== BigInt(0)) {
      writer.uint32(32).int64(message.numTxs);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBlockMeta();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.blockId = exports.BlockID.decode(reader, reader.uint32());
          break;
        case 2:
          message.blockSize = reader.int64();
          break;
        case 3:
          message.header = exports.Header.decode(reader, reader.uint32());
          break;
        case 4:
          message.numTxs = reader.int64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseBlockMeta();
    if ((0, helpers_1.isSet)(object.blockId)) obj.blockId = exports.BlockID.fromJSON(object.blockId);
    if ((0, helpers_1.isSet)(object.blockSize)) obj.blockSize = BigInt(object.blockSize.toString());
    if ((0, helpers_1.isSet)(object.header)) obj.header = exports.Header.fromJSON(object.header);
    if ((0, helpers_1.isSet)(object.numTxs)) obj.numTxs = BigInt(object.numTxs.toString());
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.blockId !== undefined && (obj.blockId = message.blockId ? exports.BlockID.toJSON(message.blockId) : undefined);
    message.blockSize !== undefined && (obj.blockSize = (message.blockSize || BigInt(0)).toString());
    message.header !== undefined && (obj.header = message.header ? exports.Header.toJSON(message.header) : undefined);
    message.numTxs !== undefined && (obj.numTxs = (message.numTxs || BigInt(0)).toString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseBlockMeta();
    if (object.blockId !== undefined && object.blockId !== null) {
      message.blockId = exports.BlockID.fromPartial(object.blockId);
    }
    if (object.blockSize !== undefined && object.blockSize !== null) {
      message.blockSize = BigInt(object.blockSize.toString());
    }
    if (object.header !== undefined && object.header !== null) {
      message.header = exports.Header.fromPartial(object.header);
    }
    if (object.numTxs !== undefined && object.numTxs !== null) {
      message.numTxs = BigInt(object.numTxs.toString());
    }
    return message;
  }
};
function createBaseTxProof() {
  return {
    rootHash: new Uint8Array(),
    data: new Uint8Array(),
    proof: undefined
  };
}
exports.TxProof = {
  typeUrl: "/tendermint.types.TxProof",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.rootHash.length !== 0) {
      writer.uint32(10).bytes(message.rootHash);
    }
    if (message.data.length !== 0) {
      writer.uint32(18).bytes(message.data);
    }
    if (message.proof !== undefined) {
      proof_1.Proof.encode(message.proof, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTxProof();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rootHash = reader.bytes();
          break;
        case 2:
          message.data = reader.bytes();
          break;
        case 3:
          message.proof = proof_1.Proof.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseTxProof();
    if ((0, helpers_1.isSet)(object.rootHash)) obj.rootHash = (0, helpers_1.bytesFromBase64)(object.rootHash);
    if ((0, helpers_1.isSet)(object.data)) obj.data = (0, helpers_1.bytesFromBase64)(object.data);
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = proof_1.Proof.fromJSON(object.proof);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.rootHash !== undefined && (obj.rootHash = (0, helpers_1.base64FromBytes)(message.rootHash !== undefined ? message.rootHash : new Uint8Array()));
    message.data !== undefined && (obj.data = (0, helpers_1.base64FromBytes)(message.data !== undefined ? message.data : new Uint8Array()));
    message.proof !== undefined && (obj.proof = message.proof ? proof_1.Proof.toJSON(message.proof) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseTxProof();
    message.rootHash = object.rootHash ?? new Uint8Array();
    message.data = object.data ?? new Uint8Array();
    if (object.proof !== undefined && object.proof !== null) {
      message.proof = proof_1.Proof.fromPartial(object.proof);
    }
    return message;
  }
};
