import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { CUSTODY_TYPE, orgType } from '@entities/members';
import { ConstantService } from '../../services/constant.service';

@Component({
  selector: 'app-notification-email',
  templateUrl: './notification-email.component.html',
  styleUrls: ['./notification-email.component.scss']
})
export class NotificationEmailComponent implements OnInit {
  stage = 'email_edit';
  complianceEmail = '';
  billingEmail = '';
  securityEmail = '';
  transactionEmail = ''
  registerForm: FormGroup;
  submitted = false;
  orgProfile: any;
  isCustody:boolean;
  isFormValid:boolean =false;
  constructor(private modalService: NgbModal, private httpService: HttpService, private formBuilder: FormBuilder,private data: DataService ,private constService:ConstantService) { 
    this.initializeAndUpdateFormValidations();
  }
  get f() { return this.registerForm.controls; }
  ngOnInit(): void {
  this.subscribeToFormChanges();
  this.isCustody = this.constService.org.value[0]['isCustody'];
  }
  open(edit_notification_dtls: any) {
    this.httpService.view_Notification_Details().subscribe((emailDetails)=>
    {
      Object.values(emailDetails.data).forEach((data) => {
        if (data["type"] === "compliance")
        this.complianceEmail = data["email"];
        // if (data["type"] === "accounts")
        // this.billingEmail = data["email"];
        // if (data["type"] === "security")
        // this.securityEmail = data["email"];
        if(data['type'] === "transaction")
        this.transactionEmail = data["email"]
      });
    });
    this.stage = 'email_edit';
    this.modalService.open(edit_notification_dtls, { windowClass: 'modal-custom-background', centered: true });
  }
  close(){
    this.modalService.dismissAll(); 
    this.complianceEmail = '';
    // this.billingEmail = '';
    // this.securityEmail = ''; 
    this.transactionEmail = '';
  }
  save_Emails()
  {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.httpService.update_Notifications_Details(this.complianceEmail,"compliance").toPromise();
    // this.httpService.update_Notifications_Details(this.billingEmail,"accounts").toPromise();
    // this.httpService.update_Notifications_Details(this.securityEmail,"security").toPromise();
    this.httpService.update_Notifications_Details(this.transactionEmail,"transaction").toPromise();
    this.stage = 'success';
  }

  initializeAndUpdateFormValidations(){
    this.registerForm = this.formBuilder.group({
      compEmail: ['', [ Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      billEmail: ['', [ Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      secEmail: ['', [ Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      tranEmail: ['', [ Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
  });

    if(this.isCustody){
      this.registerForm.get('tranEmail')?.setValidators([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
      this.registerForm.updateValueAndValidity();
          }
  }

  updateButtonState() {
    const isFormValid = this.registerForm.valid;
    const isAnyFieldTouched = Object.keys(this.registerForm.controls).some(controlName => this.registerForm.get(controlName)?.touched);

    if (isFormValid && isAnyFieldTouched) {
     this.isFormValid =true;
    } else {
      this.isFormValid =false;
  }
  }

  onInput(event: Event) {
    const control = this.registerForm.get('compEmail');
    if (control) {
      control.markAsTouched(); 
    }
  }

  subscribeToFormChanges(){
    this.registerForm.valueChanges.subscribe(() => {
        this.updateButtonState();
      });
  }
}
