<ul class="nav nav-tab mb-4">
  <li class="nav-item">
    <a class="nav-link" [class]="{ active: _tab == 'history' }" (click)="changeTab('history')">History</a>
  </li>
  <li noDisplay class="nav-item">
    <a class="nav-link" [class]="{ active: _tab == 'reports' }" (click)="changeTab('reports')">Reports</a>
  </li>

</ul>

<div *ngIf="_tab === 'history'">
  <app-transactions></app-transactions>
</div>
<div *ngIf="_tab === 'reports'">
  <app-reports></app-reports>
</div>
