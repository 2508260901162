"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUint8Array = exports.isNonNullObject = exports.isDefined = exports.sleep = exports.assertDefinedAndNotNull = exports.assertDefined = exports.assert = exports.arrayContentStartsWith = exports.arrayContentEquals = void 0;
var arrays_1 = require("./arrays");
Object.defineProperty(exports, "arrayContentEquals", {
  enumerable: true,
  get: function () {
    return arrays_1.arrayContentEquals;
  }
});
Object.defineProperty(exports, "arrayContentStartsWith", {
  enumerable: true,
  get: function () {
    return arrays_1.arrayContentStartsWith;
  }
});
var assert_1 = require("./assert");
Object.defineProperty(exports, "assert", {
  enumerable: true,
  get: function () {
    return assert_1.assert;
  }
});
Object.defineProperty(exports, "assertDefined", {
  enumerable: true,
  get: function () {
    return assert_1.assertDefined;
  }
});
Object.defineProperty(exports, "assertDefinedAndNotNull", {
  enumerable: true,
  get: function () {
    return assert_1.assertDefinedAndNotNull;
  }
});
var sleep_1 = require("./sleep");
Object.defineProperty(exports, "sleep", {
  enumerable: true,
  get: function () {
    return sleep_1.sleep;
  }
});
var typechecks_1 = require("./typechecks");
Object.defineProperty(exports, "isDefined", {
  enumerable: true,
  get: function () {
    return typechecks_1.isDefined;
  }
});
Object.defineProperty(exports, "isNonNullObject", {
  enumerable: true,
  get: function () {
    return typechecks_1.isNonNullObject;
  }
});
Object.defineProperty(exports, "isUint8Array", {
  enumerable: true,
  get: function () {
    return typechecks_1.isUint8Array;
  }
});
