<div class="card d-flex p-4 card-shadow-border">
  <div class="mb-3 loader w-75">
    <ngx-skeleton-loader></ngx-skeleton-loader>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of [].constructor(10); let i = index">
        <td>
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </td>
        <td>
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </td>
        <td>
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </td>
        <td>
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </td>
        <td>
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </td>
      </tr>
    </tbody>
  </table>
</div>
