"use strict";

/******************************************************************************
 *  (c) 2018 - 2024 Zondax AG
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 *****************************************************************************/
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ERROR_DESCRIPTION_OVERRIDE = exports.LedgerError = exports.PAYLOAD_TYPE = exports.LEDGER_DASHBOARD_CLA = exports.HARDENED = void 0;
exports.HARDENED = 0x80000000;
exports.LEDGER_DASHBOARD_CLA = 0xb0;
// Payload types for transactions
exports.PAYLOAD_TYPE = {
  INIT: 0x00,
  ADD: 0x01,
  LAST: 0x02
};
// Ledger error codes and descriptions sorted by value
var LedgerError;
(function (LedgerError) {
  LedgerError[LedgerError["U2FUnknown"] = 1] = "U2FUnknown";
  LedgerError[LedgerError["U2FBadRequest"] = 2] = "U2FBadRequest";
  LedgerError[LedgerError["U2FConfigurationUnsupported"] = 3] = "U2FConfigurationUnsupported";
  LedgerError[LedgerError["U2FDeviceIneligible"] = 4] = "U2FDeviceIneligible";
  LedgerError[LedgerError["U2FTimeout"] = 5] = "U2FTimeout";
  LedgerError[LedgerError["Timeout"] = 14] = "Timeout";
  LedgerError[LedgerError["GpAuthFailed"] = 25344] = "GpAuthFailed";
  LedgerError[LedgerError["PinRemainingAttempts"] = 25536] = "PinRemainingAttempts";
  LedgerError[LedgerError["ExecutionError"] = 25600] = "ExecutionError";
  LedgerError[LedgerError["WrongLength"] = 26368] = "WrongLength";
  LedgerError[LedgerError["IncorrectLength"] = 26368] = "IncorrectLength";
  LedgerError[LedgerError["MissingCriticalParameter"] = 26624] = "MissingCriticalParameter";
  LedgerError[LedgerError["ErrorDerivingKeys"] = 26626] = "ErrorDerivingKeys";
  LedgerError[LedgerError["EmptyBuffer"] = 27010] = "EmptyBuffer";
  LedgerError[LedgerError["SecurityStatusNotSatisfied"] = 27010] = "SecurityStatusNotSatisfied";
  LedgerError[LedgerError["OutputBufferTooSmall"] = 27011] = "OutputBufferTooSmall";
  LedgerError[LedgerError["DataIsInvalid"] = 27012] = "DataIsInvalid";
  LedgerError[LedgerError["ConditionsOfUseNotSatisfied"] = 27013] = "ConditionsOfUseNotSatisfied";
  LedgerError[LedgerError["CommandIncompatibleFileStructure"] = 27009] = "CommandIncompatibleFileStructure";
  LedgerError[LedgerError["TransactionRejected"] = 27014] = "TransactionRejected";
  LedgerError[LedgerError["BadKeyHandle"] = 27264] = "BadKeyHandle";
  LedgerError[LedgerError["IncorrectData"] = 27264] = "IncorrectData";
  LedgerError[LedgerError["ReferencedDataNotFound"] = 27272] = "ReferencedDataNotFound";
  LedgerError[LedgerError["NotEnoughMemorySpace"] = 27268] = "NotEnoughMemorySpace";
  LedgerError[LedgerError["FileAlreadyExists"] = 27273] = "FileAlreadyExists";
  LedgerError[LedgerError["InvalidP1P2"] = 27392] = "InvalidP1P2";
  LedgerError[LedgerError["IncorrectP1P2"] = 27392] = "IncorrectP1P2";
  LedgerError[LedgerError["InstructionNotSupported"] = 27904] = "InstructionNotSupported";
  LedgerError[LedgerError["InsNotSupported"] = 27904] = "InsNotSupported";
  LedgerError[LedgerError["UnknownApdu"] = 27906] = "UnknownApdu";
  LedgerError[LedgerError["DeviceNotOnboarded"] = 27911] = "DeviceNotOnboarded";
  LedgerError[LedgerError["DeviceNotOnboarded2"] = 26129] = "DeviceNotOnboarded2";
  LedgerError[LedgerError["CustomImageBootloader"] = 26159] = "CustomImageBootloader";
  LedgerError[LedgerError["CustomImageEmpty"] = 26158] = "CustomImageEmpty";
  LedgerError[LedgerError["AppDoesNotSeemToBeOpen"] = 28161] = "AppDoesNotSeemToBeOpen";
  LedgerError[LedgerError["ClaNotSupported"] = 28160] = "ClaNotSupported";
  LedgerError[LedgerError["Licensing"] = 28482] = "Licensing";
  LedgerError[LedgerError["UnknownError"] = 28416] = "UnknownError";
  LedgerError[LedgerError["TechnicalProblem"] = 28416] = "TechnicalProblem";
  LedgerError[LedgerError["SignVerifyError"] = 28417] = "SignVerifyError";
  LedgerError[LedgerError["Halted"] = 28586] = "Halted";
  LedgerError[LedgerError["NoErrors"] = 36864] = "NoErrors";
  LedgerError[LedgerError["DeviceIsBusy"] = 36865] = "DeviceIsBusy";
  LedgerError[LedgerError["UnknownTransportError"] = 65535] = "UnknownTransportError";
  LedgerError[LedgerError["AccessConditionNotFulfilled"] = 38916] = "AccessConditionNotFulfilled";
  LedgerError[LedgerError["AlgorithmNotSupported"] = 38020] = "AlgorithmNotSupported";
  LedgerError[LedgerError["CodeBlocked"] = 38976] = "CodeBlocked";
  LedgerError[LedgerError["CodeNotInitialized"] = 38914] = "CodeNotInitialized";
  LedgerError[LedgerError["ContradictionInvalidation"] = 38928] = "ContradictionInvalidation";
  LedgerError[LedgerError["ContradictionSecretCodeStatus"] = 38920] = "ContradictionSecretCodeStatus";
  LedgerError[LedgerError["InvalidKcv"] = 38021] = "InvalidKcv";
  LedgerError[LedgerError["InvalidOffset"] = 37890] = "InvalidOffset";
  LedgerError[LedgerError["LockedDevice"] = 21781] = "LockedDevice";
  LedgerError[LedgerError["MaxValueReached"] = 38992] = "MaxValueReached";
  LedgerError[LedgerError["MemoryProblem"] = 37440] = "MemoryProblem";
  LedgerError[LedgerError["NoEfSelected"] = 37888] = "NoEfSelected";
  LedgerError[LedgerError["InconsistentFile"] = 37896] = "InconsistentFile";
  LedgerError[LedgerError["FileNotFound"] = 37892] = "FileNotFound";
  LedgerError[LedgerError["UserRefusedOnDevice"] = 21761] = "UserRefusedOnDevice";
  LedgerError[LedgerError["NotEnoughSpace"] = 20738] = "NotEnoughSpace";
  LedgerError[LedgerError["GenericError"] = 4294967295] = "GenericError";
})(LedgerError || (exports.LedgerError = LedgerError = {}));
exports.ERROR_DESCRIPTION_OVERRIDE = {
  [LedgerError.U2FUnknown]: 'U2F: Unknown',
  [LedgerError.U2FBadRequest]: 'U2F: Bad request',
  [LedgerError.U2FConfigurationUnsupported]: 'U2F: Configuration unsupported',
  [LedgerError.U2FDeviceIneligible]: 'U2F: Device Ineligible',
  [LedgerError.U2FTimeout]: 'U2F: Timeout',
  [LedgerError.Timeout]: 'Timeout',
  [LedgerError.NoErrors]: 'No errors',
  [LedgerError.DeviceIsBusy]: 'Device is busy',
  [LedgerError.ErrorDerivingKeys]: 'Error deriving keys',
  [LedgerError.ExecutionError]: 'Execution Error',
  [LedgerError.WrongLength]: 'Wrong Length',
  [LedgerError.EmptyBuffer]: 'Empty Buffer',
  [LedgerError.OutputBufferTooSmall]: 'Output buffer too small',
  [LedgerError.DataIsInvalid]: 'Data is invalid',
  [LedgerError.TransactionRejected]: 'Transaction rejected',
  [LedgerError.BadKeyHandle]: 'Bad key handle',
  [LedgerError.InvalidP1P2]: 'Invalid P1/P2',
  [LedgerError.InstructionNotSupported]: 'Instruction not supported',
  [LedgerError.AppDoesNotSeemToBeOpen]: 'App does not seem to be open',
  [LedgerError.UnknownError]: 'Unknown error',
  [LedgerError.SignVerifyError]: 'Sign/verify error',
  [LedgerError.UnknownTransportError]: 'Unknown transport error',
  [LedgerError.GpAuthFailed]: 'GP Authentication Failed',
  [LedgerError.PinRemainingAttempts]: 'PIN Remaining Attempts',
  [LedgerError.MissingCriticalParameter]: 'Missing Critical Parameter',
  [LedgerError.ConditionsOfUseNotSatisfied]: 'Conditions of Use Not Satisfied',
  [LedgerError.CommandIncompatibleFileStructure]: 'Command Incompatible with File Structure',
  [LedgerError.ReferencedDataNotFound]: 'Referenced Data Not Found',
  [LedgerError.NotEnoughMemorySpace]: 'Not Enough Memory Space',
  [LedgerError.FileAlreadyExists]: 'File Already Exists',
  [LedgerError.UnknownApdu]: 'Unknown APDU',
  [LedgerError.DeviceNotOnboarded]: 'Device Not Onboarded',
  [LedgerError.DeviceNotOnboarded2]: 'Device Not Onboarded (Secondary)',
  [LedgerError.CustomImageBootloader]: 'Custom Image Bootloader Error',
  [LedgerError.CustomImageEmpty]: 'Custom Image Empty',
  [LedgerError.ClaNotSupported]: 'CLA Not Supported',
  [LedgerError.Licensing]: 'Licensing Error',
  [LedgerError.Halted]: 'Device Halted',
  [LedgerError.AccessConditionNotFulfilled]: 'Access Condition Not Fulfilled',
  [LedgerError.AlgorithmNotSupported]: 'Algorithm Not Supported',
  [LedgerError.CodeBlocked]: 'Code Blocked',
  [LedgerError.CodeNotInitialized]: 'Code Not Initialized',
  [LedgerError.ContradictionInvalidation]: 'Contradiction Invalidation',
  [LedgerError.ContradictionSecretCodeStatus]: 'Contradiction with Secret Code Status',
  [LedgerError.InvalidKcv]: 'Invalid KCV',
  [LedgerError.InvalidOffset]: 'Invalid Offset',
  [LedgerError.LockedDevice]: 'Device Locked',
  [LedgerError.MaxValueReached]: 'Maximum Value Reached',
  [LedgerError.MemoryProblem]: 'Memory Problem',
  [LedgerError.NoEfSelected]: 'No EF Selected',
  [LedgerError.InconsistentFile]: 'Inconsistent File',
  [LedgerError.FileNotFound]: 'File Not Found',
  [LedgerError.UserRefusedOnDevice]: 'User Refused on Device',
  [LedgerError.NotEnoughSpace]: 'Not Enough Space',
  [LedgerError.GenericError]: 'Generic Error'
};
