import { get } from "http";

function getPolicyListData(wallet, policy) {
  let policytypecustom;
  if (policy.subtype.toLowerCase() === "address") {
    policytypecustom = "Whitelist";
  } else if (policy.subtype.toLowerCase() === "velocity") {
    policytypecustom = "Spending Limit";
  } else if (policy.subtype.toLowerCase() === "threshold") {
    policytypecustom = "Transaction Limit";
  }

  return {
    walletid: wallet.id,
    access: wallet.access,
    walletname: wallet.name,
    chain: wallet.chain,
    coin: wallet.coin,
    imageurl: wallet.imageurl,
    orgid: wallet.orgid,
    name: policy.name,
    subtype: policy.subtype,
    condition: policy.condition,
    amount: policy.amount,
    policyid: policy.id,
    walletKeys: wallet.walletKeys,
    signers: policy.signers,
    approvers: policy.approvers || [],
    created_at: policy.created_at,
    updated_at: policy.updated_at,
    coinfullname: wallet.coinfullname,
    policytypecustom: policytypecustom,
    isArchived: wallet.isArchived,
    memo: policy.memo,
  };
}

export { getPolicyListData };
