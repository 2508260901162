"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Hash256 = void 0;
const hash_1 = require("./hash");
/**
 * Hash with a width of 256 bits
 */
class Hash256 extends hash_1.Hash {
  constructor(bytes) {
    super(bytes !== null && bytes !== void 0 ? bytes : Hash256.ZERO_256.bytes);
  }
}
exports.Hash256 = Hash256;
Hash256.width = 32;
Hash256.ZERO_256 = new Hash256(new Uint8Array(Hash256.width));
