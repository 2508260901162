var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import invariant from "invariant";
export const getAppAndVersion = transport => __awaiter(void 0, void 0, void 0, function* () {
  const r = yield transport.send(0xb0, 0x01, 0x00, 0x00);
  let i = 0;
  const format = r[i++];
  invariant(format === 1, "getAppAndVersion: format not supported");
  const nameLength = r[i++];
  const name = r.slice(i, i += nameLength).toString("ascii");
  const versionLength = r[i++];
  const version = r.slice(i, i += versionLength).toString("ascii");
  const flagLength = r[i++];
  const flags = r.slice(i, i += flagLength);
  return {
    name,
    version,
    flags
  };
});
export const checkIsBtcLegacy = transport => __awaiter(void 0, void 0, void 0, function* () {
  try {
    // Call old btc API, it will throw an exception with new btc app. It is a workaround to differentiate new/old btc nano app
    yield transport.send(0xe0, 0xc4, 0, 0);
  } catch (e) {
    return false;
  }
  return true;
});
