import { Component, Input, OnInit } from '@angular/core';
import { UserStatusEnum } from '@entities/User';

@Component({
  selector: 'app-api-key-status',
  templateUrl: './api-key-status.component.html',
  styleUrls: ['./api-key-status.component.scss']
})
export class ApiKeyStatusComponent implements OnInit {

  @Input() details: any;
  UserStatus = UserStatusEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
