import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
@Pipe({
    name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {
    transform(value: any): any {
        let resp = value;
        // const formattedDate = moment.utc(value).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z');
        // console.log("*********************************");
        // console.log(formattedDate);
        // resp = `${formattedDate} `;
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29 && seconds >= 0) // less than 30 seconds ago will show as 'Just now'
                return 'now';
            // return 'Just now';
            const intervals = {
                'y': 31536000,
                'mo': 2592000,
                'w': 604800,
                'd': 86400,
                'h': 3600,
                'm': 60,
                's': 1
            };
            let counter;
            if (seconds < 0) {
                let second = seconds * -1;
                for (const i in intervals) {
                    counter = Math.floor(second / intervals[i]);
                    if (counter > 0)
                        if (counter === 1) {
                            resp = "in " + counter + i; // singular (1 day ago)
                            break;
                        } else {
                            resp = "in " + counter + i; // plural (2 days ago)
                            break;
                        }
                }
            }
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        resp = counter + i + ' ago';; // singular (1 day ago)
                        break;
                    } else {
                        resp = counter + i + ' ago';; // plural (2 days ago)
                        break;
                    }
            }
        }
        return resp;
    }
}


@Pipe({
    name: 'dateWithZone'
})
export class DateWithZonePipe implements PipeTransform {
    transform(value: any, format: string = 'DD-MM-YYYY HH:mm z'): any {
        return moment.utc(value).tz(moment.tz.guess()).format(format);
    }
}
