"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Responses = exports.Params = void 0;
var requests_1 = require("./requests");
Object.defineProperty(exports, "Params", {
  enumerable: true,
  get: function () {
    return requests_1.Params;
  }
});
var responses_1 = require("./responses");
Object.defineProperty(exports, "Responses", {
  enumerable: true,
  get: function () {
    return responses_1.Responses;
  }
});
