import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-tx-history",
  templateUrl: "./tx-history.component.html",
  styleUrls: ["./tx-history.component.scss"],
})
export class TxHistoryComponent implements OnInit {
  public _tab = "history";

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {   
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe(url => {
      const childRoute = this.activatedRoute.firstChild;
      if (childRoute) {
        childRoute.url.subscribe(childUrl => {
          const childPath = childUrl.map(segment => segment.path).join('/');
          this._tab = childPath;
          this.router.navigate([`tx/${childPath}`]);
        });
      }
    });
  }

  changeTab(tab) {
    this._tab = tab;
    this.router.navigate([`tx/${this._tab}`]);
  }
}
