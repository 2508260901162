import { Component, ElementRef, NgZone, OnInit, ViewChild, HostListener, Input, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { WalletserviceService } from '../../services/walletservice.service';
import TrezorConnect, { UI, UI_EVENT, DEVICE_EVENT, Device, RESPONSE_EVENT } from 'trezor-connect';
import BN from "bignumber.js";
import { Wallet, WalletGrouped, WalletUserType } from '../../entities/wallet';
import { LoggerService } from '../../services/logger.service';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { tokenType, TransactionRequest, txType } from '../../entities/TransactionRequest';
import { ComposeTransaction } from '../../helpers/trezor/transactionComposer';
import Swal from 'sweetalert2';
import { Policy } from 'src/app/shared/entities/Policy';
import { SegmentService } from 'ngx-segment-analytics';
import { AuthServiceJWT } from '../../services/auth.service';
import { getIcon, getName } from '../../helpers/coins';
import { SendTeamTxService } from '../../services/sendteamtxn.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UAParser } from 'ua-parser-js';
import { toPrettyAmount } from '../../components/pipes/amountrounded.pipe';
import { ConstantService } from '../../services/constant.service';
import { MpcTxData } from '../../entities/mpcTxRequest';
import { environment } from 'src/environments/environment';
import { allowNumberDecimal } from '../../helpers/inputNumber';
import { orgType } from '../../entities/members';
import { isV2MultisigWallet } from '@helpers/WalletUtils';
import { SegmentEventsUtil } from '../../utils/segment-events.util';

interface addressAmountPair {
  address: string;
  amount: number;
  policy: Policy;
  usdamount: number;
}
@Component({
  selector: 'app-send-assets',
  templateUrl: './send-assets.component.html',
  styleUrls: ['./send-assets.component.scss']
})
export class SendAssetsComponent implements OnInit {
  readonly IWalletUserType = WalletUserType;
  closeResult = '';
  wallets: Wallet[];
  _wallet: any;
  walletGrouped: WalletGrouped[];
  filter = '';
  searchWalletInput: string;
  searchAssetInput: string;
  searchAssetAddress: string;
  filteredOptions: Observable<Wallet[]> = EMPTY;
  filteredOptionsreceiver: Observable<Wallet[]> = EMPTY
  SendControl = new FormControl();
  ReceiveControl = new FormControl();
  txRequest: TransactionRequest
  composeTransaction: ComposeTransaction;
  invalidAmmount = false;
  isSigning = false;
  allowedDecimals = false;
  ledgerDeviceSignText: string;
  usdAmount: number = null;
  userDetail: any;
  sender_details = false;
  selectedWallet: Wallet;
  selectedAsset: Wallet;
  selectedAddress: any;
  _wallets: any;
  assets: any = [];
  inputWidth: string = '';
  getMemoInputValue: string;
  selectedAddressAmount: addressAmountPair = { policy: null, address: null, amount: null, usdamount: null }
  active = 1;
  sendTxType: string = 'send_assets';
  selectedNFT: any;
  deviceName: string;
  walletNFT: any = [];
  approveTxStatus: boolean = false;
  mpcTxRequest: MpcTxData;
  fltr: any;
  getNoteInputValue: string = ""
  walletVersionType = 0
  mpc_asset_changes = []
  orgProfile: any = {};
  orgType = orgType
  isV2MultisigWallet = isV2MultisigWallet;
  getMemoValue:string | number;
  trmStatus: boolean = false;
  successMessage: string = '';
  addressLabel: string = '';
  @ViewChild("content3") sendViewDiv: ElementRef;
  @ViewChild("successModal") successModal: ElementRef;
  @ViewChild("errorModal") errorModal: ElementRef;
  @ViewChild('banDialog') banDialog: ElementRef;
  @Input() sendassets_component_for: string;
  @Input() walletDetailWalletId: any;
  @Input() singleWallet: any;
  @Input() walletType: any;
  @Input() walletIdFromUnstake: any;

  browser: string;
  device: string;
  errorMsg: any;
  // device: string;

  private onDestroy$: Subject<void> = new Subject<void>();
  public eventsUtil: SegmentEventsUtil;
  constructor(private data: DataService,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private date: DataService,
    private logger: LoggerService,
    private modalService: NgbModal,
    public authService: AuthServiceJWT,
    private segment: SegmentService,
    public sendTeamTxService: SendTeamTxService,
    private org: ConstantService,
    config: NgbModalConfig,) {
    this.eventsUtil = new SegmentEventsUtil(this.logger, this.segment);
    config.backdrop = 'static';
    config.keyboard = false;
    this.browser = new UAParser().getBrowser().name;

    this.wallets = walletService.getAllWallets();
    let result = this.groupBy(this.wallets, (obj) => obj.chain);
    this.walletGrouped = Object.entries(result).map(([title, data]) => ({ title, data }));
    this.ledgerDeviceSignText = this.deviceLedgerSignText();
  }

  ngOnInit(): void {
    this.filteredOptions = this.SendControl.valueChanges
      .pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.wallets.slice())
      );

    this.filteredOptionsreceiver = this.ReceiveControl.valueChanges
      .pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.wallets.slice())
      );

    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallets => {
      if (wallets) {
        this.wallets = wallets.filter(ele => ele.isArchived !== 1);
        this._wallets = [...new Map(wallets.map(item => [item.id, item])).values()];

        this._wallets.forEach(element => {
          element["coinfullname"] = getName(element.chain);
        });
        this._wallets = this._wallets?.filter((ele) => (ele?.subtype != 'hot_pipeline' && ele?.subtype != 'hot' && ele?.subtype != 'deposit'))
        if (this.sendassets_component_for !== 'gas-station') {
          this._wallets = this._wallets.filter((ele) => ele?.type != 'gasStation' && ele?.subtype != 'gasStation');
        }
        this._wallets = this._wallets.sort((a, b) => a.name.localeCompare(b.name));
        let result = this.groupBy(this.wallets, (obj) => obj.chain);
        this.walletGrouped = Object.entries(result).map(([title, data]) => ({ title, data }));
        // this.singleWalletSendFilter();
      }
    });

    this.data.txRequest.pipe(takeUntil(this.onDestroy$)).subscribe(tx => {
      if (tx) {
        this.txRequest = tx
        this.usdAmount = null;
        if (this.sendViewDiv) {
          this.open(this.sendViewDiv);
          if (this.txRequest.tokenType == tokenType.nftToken.toString()) {
            this.sendTxType = 'send_nft';
          }
        }
        this.getMemoValue = this.txRequest.memoData?.value || "";
        this.getNoteInputValue = this.txRequest.comment
        this.selectedAddressAmount.amount = this.txRequest.amount;
        this.addressLabel = this.txRequest.addressLabel;
        if ('actionType' in this.txRequest) {
          this.selectedAddressAmount.address = this.txRequest['destination'];
          this.selectedAddressAmount.usdamount = this.txRequest['usdAmount'];
          this.selectedAddressAmount.policy = this.txRequest['destination'];
          this.selectedAsset = this.walletService.getWallet(tx['walletId'] + "_" + tx.asset.toLowerCase())
        } else {
          this.selectedAddressAmount.address = this.txRequest.destinationAddress;
          this.selectedAddressAmount.usdamount = this.txRequest.usdamount;
          // this.logger.info(this.txRequest.walletinfor?.policy, this.selectedAddressAmount.address);
          // this.logger.info(this.txRequest.walletinfor?.policy.filter(p => p.condition == this.selectedAddressAmount.address));
          this.selectedAddressAmount.policy = (this.txRequest.walletinfor?.policy?.filter(p => p.condition == this.selectedAddressAmount.address) || [])[0];
          this.selectedAsset = this.walletService.getWallet(tx.walletid + "_" + tx.asset.toLowerCase())
        }
        if (this.txRequest.tokenType == tokenType.nftToken.toString()) {
          this.selectedAddressAmount.amount = this.txRequest.walletinfor.nativePrice;
          this.selectedNFT = this.walletService.getAllWallets();
          this.selectedNFT = this.selectedNFT.filter(ele => ele.id == this.txRequest.walletid && ele.coin.toLowerCase() == this.txRequest.asset.toLowerCase() && ele.identifier.toLowerCase() == JSON.parse(this.txRequest.raw).to.toLowerCase())[0];
        }
        this.inputWidth = (toPrettyAmount.prototype.transform(this.txRequest.amount, 5).toString().length * 15) + 'px';
        this.deviceName = this.selectedAsset?.walletKeys?.filter(item => item.ismine === true)[0]?.provider;
        if ('actionType' in this.txRequest) {
          let wallet = this._wallets.filter(ele => ele.id == tx['walletId']);
          this.selectedWallet = wallet[0];
        } else {
          let wallet = this._wallets.filter(ele => ele.id == tx.walletid);
          this.selectedWallet = wallet[0];
        }
        this.walletVersionType = this.selectedWallet.version;
        this.onSubmit();
      } else {
        this.txRequest = null;
      }
    })

    this.data.txMpcRequest.pipe(takeUntil(this.onDestroy$)).subscribe((tx) => {
      if (tx) {
        this.mpc_asset_changes = tx.explainTransactionMpcMsg
        this.mpcTxRequest = tx;
        if (this.sendViewDiv) {
          this.open(this.sendViewDiv);
          this.sender_details = true;
        }
        this.getMemoValue = this.mpcTxRequest.memoData?.value || "";;
        this.selectedAddressAmount.address = this.mpcTxRequest.destinationAddress;
        this.getNoteInputValue = this.mpcTxRequest.comment
        this.selectedAddressAmount.amount = parseFloat(this.mpcTxRequest.amount);
        this.selectedAddressAmount.usdamount = this.mpcTxRequest.usdAmount;
        this.selectedAsset = this.walletService.getWallet(tx.walletId + "_" + tx.asset.toLowerCase());
        let wallet = this._wallets.filter(ele => ele.id == tx.walletId);
        this.selectedWallet = wallet[0] ?? tx.wallet;
        this.walletVersionType = this.selectedWallet.version
        this.approveTxStatus = true;
        this.addressLabel = this.mpcTxRequest.addressLabel;
        this.inputWidth = (toPrettyAmount.prototype.transform(parseFloat(this.mpcTxRequest.amount), 5).toString().length * 15) + 'px';
        // this.selectedAddressAmount.policy = this.mpcTxRequest.walletinfor.policy.filter(p => p.condition == this.selectedAddressAmount.address)[0]

        // Execute this logic for Multisig V2 wallets only. 
        if (isV2MultisigWallet(this.selectedWallet) && this.mpcTxRequest.status === 1 && this.mpcTxRequest.txn_req_id) {
          this.deviceName = 'ledger'
          this.txRequest = this.mpcTxRequest.txReq;
          this.txRequest.trpId = this.mpcTxRequest.id;
        }

      } else {
        this.mpcTxRequest = null;
      }
    })

    this.data.getUserProfile.pipe(takeUntil(this.onDestroy$)).subscribe(user_detail => {
      this.org.changeOrg(user_detail["organizations"]);
      this.fltr = user_detail;
      this.orgProfile = user_detail["organizations"][0];
      this.trmStatus = user_detail['organizations'][0].trmStatus;
    });
  }

  public ngOnDestroy(): void {
    this.sendViewDiv = null;
    this.selectedAsset = null;
    this.selectedAddress = null;
    this.sender_details = false;
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.searchAssetAddress = "";
    this.inputWidth = '';
    this.onDestroy$.next();
  }

  private _filter(name: string): Wallet[] {
    const filterValue = name.toLowerCase();
    return this.wallets.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  groupBy<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    key: K | { (obj: T): string }
  ): Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
      const value = keyFn(obj);
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);
  }

  sumofwallets(walletId) {
    let total = new BN(0);
    let walletAssets;
    walletAssets = this.wallets.filter(obj => obj.id == walletId);
    if (walletAssets && walletAssets.length > 0)
      for (let i = 0; i < walletAssets.length; i++) {
        total = total.plus(new BN(walletAssets[i].balanceUSD));
      }
    return total
  }

  sumByGroup(title) {
    let total = new BN(0);
    if (this.walletGrouped && this.walletGrouped.length > 0)
      for (let i = 0; i < this.walletGrouped.length; i++) {
        if (this.walletGrouped[i].title == title)
          for (let j = 0; j < this.walletGrouped[i].data.length; j++)
            total = total.plus(new BN(this.walletGrouped[i].data[j].balanceUSD));
      }
    return total
  }

  autoSelectedWalletFromUnstake() {
    this.selectedWallet = this.wallets.filter((el) => el.id == this.walletIdFromUnstake)[0]
    this.assets = this.wallets.filter(obj => obj.id == this.selectedWallet.id && obj.tokenType != tokenType.nftToken);
    this.assets = this.assets.sort((a, b) => a.coin.localeCompare(b.coin));
    console.log("this.selectedWallet", this.selectedWallet, this.selectedAsset);
  }
  openD(content) {
    if (this.sendassets_component_for === 'normal_send') {
      this._wallets = this._wallets.filter((ele) => ele.isArchived !== 1)
    }
    this.resetSendModel();
    this.singleWalletSendFilter();
    if (this.walletIdFromUnstake) {
      this.autoSelectedWalletFromUnstake();
    }
    this.open(content);
  }

  open(content) {
    this.isSigning = false;
    this.filter = '';
    this.modalService.dismissAll()
    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.resetSendModel();
      this.singleWalletSendFilter();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.resetSendModel();
    });
  }

  private getDismissReason(reason: any): string {
    this.resetSendModel();
    this.resetAmmountValidationErrormessages();

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  resetSendModel() {
    this.selectedAddressAmount = { policy: null, address: null, amount: null, usdamount: null }
    this.txRequest = null;
    this.mpcTxRequest = null;
    if (this.sendassets_component_for !== 'wallet_details_sendassets') {
      this.selectedWallet = null;
    }
    this.selectedAsset = null;
    this.selectedAddress = null;
    this.sender_details = false;
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.searchAssetAddress = "";
    this.inputWidth = '';
    this.getMemoInputValue = '';
    this.selectedNFT = null;
    this.sendTxType = 'send_assets';
    this.getNoteInputValue = ''
    this.approveTxStatus = false;
  }

  transactionType(amount) {
    if (this.sendassets_component_for == 'gas-station') {
      this.signGasStationTx(amount);
    } else {
      if (isV2MultisigWallet(this.selectedWallet) &&  this.mpcTxRequest?.status === 1 && this.mpcTxRequest?.txn_req_id) {
        (this.txRequest) ? this.onSubmit(this.txRequest.amount) : this.onSubmit(amount)
        this.onSign()
      }
      else if (this.selectedWallet.version == 2) {
        if (this.approveTxStatus) {
          this.approveInitiatorTx();
        } else {
          this.initiatorsTx(amount);
        }
      } else {
        (this.txRequest) ? this.onSubmit(this.txRequest.amount) : this.onSubmit(amount)
        this.onSign()
      }
    }
  }

  async getDeviceName(): Promise<string> {
    let deviceList = [];
    if (this.fltr.keys['ETH'].length) {
      this.fltr.keys['ETH'].forEach(element => {
        deviceList.push(element.provider.toLowerCase())
      });
      if (deviceList.includes('trezor')) {
        return 'trezor';
      } else if (deviceList.includes('ledger')) {
        return 'ledger';
      } else if (deviceList.includes('ledger') && deviceList.includes('trezor')) {
        return 'trezor';
      }
    } else {
      return;
    }
  }

  async signGasStationTx(amount) {
    this.isSigning = true;
    try {
      this.eventsUtil.triggerEvent("tx-attempted", this.authService.getUser);
      const response = await this.sendTeamTxService.gasStationSign(this.selectedAsset, this.selectedAddressAmount.address, amount, this.deviceName, this.selectedWallet, this.getNoteInputValue);
      this.isSigning = false;
      this.modalService.dismissAll();
      if (response.success === true) {
        this.eventsUtil.triggerEvent("tx-success", this.authService.getUser);
        this.modalService.open(this.successModal, { windowClass: 'modal-custom-background', centered: true });
      } else {
        this.errorMsg = response.error.message.charAt(0).toUpperCase() + response.error.message.slice(1);
        this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: response.error });
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      }
    } catch (err) {
      this.modalService.dismissAll();
      if (!err?.error?.success) {
        this.errorMsg = err?.error?.message.charAt(0).toUpperCase() + err?.error?.message.slice(1);
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      } else {
        this.errorMsg = err.message.charAt(0).toUpperCase() + err.message.slice(1);
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      }
      this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: err });
    }
  }

  async initiatorsTx(amount) {
    this.isSigning = true;
    try {
      this.eventsUtil.triggerEvent("tx-attempted", this.authService.getUser);
      const response = await this.sendTeamTxService.mpcTransactionSign(
        this.selectedAsset,
        this.selectedAddressAmount.address,
        amount,
        null,
        this.selectedWallet,
        this.getNoteInputValue,
        this.txRequest.transferType,
        null,
        this.txRequest,
        this.txRequest.accountId
      );
      this.isSigning = false;
      this.modalService.dismissAll();
      if (response.success === true) {
        this.eventsUtil.triggerEvent("tx-success", this.authService.getUser);
        this.modalService.open(this.successModal, { windowClass: 'modal-custom-background', centered: true });
        // Fetch pending transactions
        const [txRequests, travelRuleRequests] = await Promise.all([
          this.httpService.getTeamsPendingTxReq().toPromise(),
          this.httpService.getTravelRulePending().toPromise()
        ]);

        this.data.changeMpcPendingTxRequest(txRequests.data);
        this.data.changePendingTravelRuleTxRequest(travelRuleRequests);

      } else {
        if(response.error.message === 'This address is blocklisted.'){
          this.modalService.open(this.banDialog, { centered: true });
        }
        else{
        this.errorMsg = response.error.message.charAt(0).toUpperCase() + response.error.message.slice(1);
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
        }
        this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: response.error });
      }
    } catch (err) {
      this.modalService.dismissAll();
      if (!err?.error?.success) {
        this.errorMsg = err?.error?.message.charAt(0).toUpperCase() + err?.error?.message.slice(1);
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      } else {
        this.errorMsg = err.message.charAt(0).toUpperCase() + err.message.slice(1);
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      }
      this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: err });
    }
  }

  //On Press of Confirm button before signing
  async onSubmit(amount?) {
    if (amount)
      this.selectedAddressAmount.amount = amount;
    this.inputWidth = (toPrettyAmount.prototype.transform(this.selectedAddressAmount.amount, 5).toString().length * 15) + 'px';
    if (this.selectedAddressAmount.address && this.selectedAddressAmount.amount && this.selectedAddressAmount.amount >= 0) {
      this.sender_details = true;
      console.log('send-asset-amount-and-address-confirmed');
      this.segment.track("send-asset-amount-and-address-confirmed", this.authService.getUser)
        .then(() => this.logger.info("Send asset amount and address confirmed")).catch((err) => { });
      if (this.sendTxType == 'send_assets') {
        this.composeTransaction = new ComposeTransaction(this.selectedAsset, this.data, this.httpService, this.logger,this.segment,this.authService, this.walletService)
      }
      if (this.sendTxType == 'send_nft') {
        this.composeTransaction = new ComposeTransaction(this.selectedNFT, this.data, this.httpService, this.logger,this.segment,this.authService, this.walletService)
      }
    } else {
      this.resetSendModel()
      this.selectedAddressAmount = { policy: null, address: null, amount: null, usdamount: null }
      try {
        this.segment.track("send-asset-amount-and-address-confirm-failed", this.authService.getUser)
          .then(() => this.logger.info("Send asset amount and address confirmation failed")).catch((err) => { });
      } catch (err) {
        this.logger.error(err, err);
      }
      return;
    }
  }

  deviceLedgerSignText() {
    return "Open Ether app in your Ledger device and click Sign"
  }

  // After on submit we press Sign button 
  async onSign(type?: string, amount?) {
    console.log("onsign----------", amount, type);

    if (type == "mint" || type == "minterc20") {
      this.onSubmit(amount);
    }
    else {
      type = 'normal';
    }
    this.isSigning = true;
    this.eventsUtil.triggerEvent("tx-attempted", this.authService.getUser);
    
    if (!this.getMemoInputValue) {
      this.getMemoInputValue = undefined;
    }
    TrezorConnect.cancel();
    console.log("Data", this.txRequest);
    var signResponse = await this.composeTransaction.sendTransaction({ address: this.selectedAddressAmount.address, value: this.selectedAddressAmount.amount, data: this.getMemoInputValue, comment: this.getNoteInputValue }, [], this.txRequest, type, this.getNoteInputValue)
    this.isSigning = false;
    for (let i = 0; i < signResponse.length; i++) {
      if (signResponse[i].error) {
        if (signResponse[i].error == 'Method_Interrupted') {
          return;
        } else {
          this.modalService.dismissAll();
          this.errorMsg = signResponse[i].error.charAt(0).toUpperCase() + signResponse[i].error.slice(1);
          this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
         

            this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: signResponse[i] });
          this.resetSendModel();
          return;
        }
      }
    }

    this.modalService.dismissAll();
    this.modalService.open(this.successModal, { windowClass: 'modal-custom-background', centered: true });
    
    this.eventsUtil.triggerEvent("tx-success", this.authService.getUser);
    this.resetSendModel()

    this.refreshPendingTx();
    await this.refreshMpcPendingTransactions();
  }

  async refreshMpcPendingTransactions() {
    // Update pending transactions
    let pendingTxs = await this.httpService.getTeamsPendingTxReq().toPromise();
    if (pendingTxs.data) this.data.changeMpcPendingTxRequest(pendingTxs.data);
  }

  async refreshPendingTx() {
    let txRequests = await this.httpService.getAllPendingWalletTransactions().toPromise();
    this.data.changePendingTxRequest(txRequests);

    // let wallets = this.data.wallets.value;
    // this.data.changeWallets(wallets);
  }

  selectPolicy(policy: Policy) {
    this.selectedAddressAmount.address = policy.condition
    this.selectedAddressAmount.policy = policy
  }

  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }//end

  resetAmmountValidationErrormessages() {
    this.invalidAmmount = false;
    this.allowedDecimals = false;
  }//end

  async transactionAmmountValidations(val: number) {
    this.inputWidth = (val.toString().length * 15) + 'px';
    if (val != undefined) {
      if (Number(val) > Number(this.selectedAsset.balance)) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else if (Number(val) < 0.00001) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      }
      else {
        if (this.decimalPlaces(val) > 5) {
          this.allowedDecimals = true;
          this.invalidAmmount = false;
        } else {
          this.allowedDecimals = false;
          this.invalidAmmount = false;
        }
      }
    } else {
      this.invalidAmmount = true;
      this.allowedDecimals = false;
    }
  }//end

  async selectWallet(wallet) {
    this.assets = this.wallets.filter(obj => obj.id == wallet.id && obj.tokenType != tokenType.nftToken);
    this.assets = this.assets.sort((a, b) => a.coin.localeCompare(b.coin));
    this.selectedWallet = wallet;
    this.walletVersionType = this.selectedWallet.version
    this.setPolicies();
    this.userDetail = this.data.getUserProfile.getValue();
    let device = wallet.walletKeys.filter(ele => ele.userid == this.userDetail.id);
    if (wallet.version == 2) {
      this.deviceName = await this.getDeviceName();
    } else {
      this.deviceName = device[0].provider;
    }
    this.walletNFT = this.wallets.filter(obj => obj.id == wallet.id && obj.type.toLowerCase() == 'gnosis' && obj.tokenType == tokenType.nftToken && (obj.chain.toLowerCase() == 'eth' || obj.chain.toLowerCase() == 'polygon'));
  }

  selectAssets(assets) {
    this.selectedAsset = assets;
  }

  selectAddress(policy: Policy) {
    this.selectedAddress = policy;
  }

  setPolicies() {
    if (this.selectedWallet?.policy?.length) {
      this.selectedWallet['policy'] = this.selectedWallet?.policy?.filter(policy => !environment.appRestrictedaddresses.some((ele) => ele.address == policy.condition))
    }
  }

  async senderAssetsConfirm() {
    this.sender_details = true;
    this.selectedAddressAmount.address = this.selectedAddress.condition;
    this.selectedAddressAmount.policy = this.selectedAddress;
    let res = await this.httpService.getRate(this.selectedAsset.coin.toUpperCase()).toPromise();
    this.usdAmount = parseFloat(JSON.parse(res)['usd']);

  }

  async senderNFTConfirm() {
    this.sender_details = true;
    this.selectedAddressAmount.address = this.selectedAddress.condition;
    this.selectedAddressAmount.policy = this.selectedAddress;
    this.selectedAddressAmount.amount = this.selectedNFT.nativePrice;
    this.selectedAddressAmount.usdamount = this.selectedNFT.balanceUSD;
  }

  getImageUrl(wallet) {
    if (!wallet.coin.toString().includes(" "))
      return getIcon(wallet.coin);
    else
      return wallet.imageurl
  }

  async singleWalletSendFilter() {
    if (this.sendassets_component_for === 'wallet_details_sendassets' || this.sendassets_component_for === 'wallet_dropdown_sendassets' || this.sendassets_component_for === 'gas-station') {
      let singleWallet;
      singleWallet = this._wallets.filter(ele => ele.id == this.walletDetailWalletId);
      this.selectedWallet = singleWallet[0];
      this.walletVersionType = this.selectedWallet.version
      this.setPolicies();
      this.assets = this.wallets.filter(obj => obj.id == this.walletDetailWalletId && obj.tokenType != 2);
      this.assets = this.assets.sort((a, b) => a.coin.localeCompare(b.coin));
      this.walletNFT = this.wallets.filter(obj => obj.id == this.walletDetailWalletId && obj.type.toLowerCase() == 'gnosis' && obj.tokenType == tokenType.nftToken && (obj.chain.toLowerCase() == 'eth' || obj.chain.toLowerCase() == 'polygon'));
      this.userDetail = this.data.getUserProfile.getValue();
      let device = this.selectedWallet.walletKeys.filter(ele => ele.userid == this.userDetail?.id);
      this.deviceName = device[0]?.provider;
      if (this.sendassets_component_for === 'gas-station') {
        this.selectAssets(this.assets[0]);
      }
    }
  }

  selectTxType(type) {

    if (!(this.sendTxType == type)) {
      this.selectedNFT = null;
      this.selectedAddress = null;
      this.selectedAsset = null;
    }
    this.sendTxType = type;
  }
  selectNFT(nft) {
    this.selectedNFT = nft;

  }

  filterArchive(wallet) {
    return wallet.filter(ele => ele.isArchived != 1)
  }

  async approveInitiatorTx() {
    this.isSigning = true;

    try {
      this.eventsUtil.triggerEvent("tx-attempted", this.authService.getUser);
      const response = await this.sendTeamTxService.initiatorApproveSign(this.mpcTxRequest.id, this.mpcTxRequest.msgToSign, this.deviceName);
      this.isSigning = false;
      this.modalService.dismissAll();
      if (response.success === true) {
        this.successMessage = response.data;
        this.eventsUtil.triggerEvent("tx-success", this.authService.getUser);
        this.modalService.open(this.successModal, { windowClass: 'modal-custom-background', centered: true });
        const [txRequests, travelRuleRequests] = await Promise.all([
          this.httpService.getTeamsPendingTxReq().toPromise(),
          this.httpService.getTravelRulePending().toPromise()
        ]);

        this.data.changeMpcPendingTxRequest(txRequests.data);
        this.data.changePendingTravelRuleTxRequest(travelRuleRequests);

      } else {
        this.errorMsg = response?.error?.message ? response?.error?.message?.charAt(0)?.toUpperCase() + response?.error?.message?.slice(1) : 'Something went wrong. Please try again later.';
        this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: response?.error });
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      }
    } catch (err) {
      this.modalService.dismissAll();
      if (!err?.error?.success) {
        this.errorMsg = err?.error?.message ? err?.error?.message?.charAt(0).toUpperCase() + err?.error?.message.slice(1) : 'Something went wrong. Please try again later.';
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      } else {
        this.errorMsg = err?.message ? err?.message.charAt(0).toUpperCase() + err?.message.slice(1) : 'Something went wrong. Please try again later.';
        this.modalService.open(this.errorModal, { windowClass: 'modal-custom-background', centered: true });
      }
      this.eventsUtil.triggerEvent("tx-failed", {user: this.authService.getUser, error: err });
    }
  }

  allowNumberDecimal(event) {
    return allowNumberDecimal(event)
  }

}