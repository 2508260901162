"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGroupAminoConverters = void 0;
function createGroupAminoConverters() {
  // Missing, see https://github.com/cosmos/cosmjs/issues/1441
  return {};
}
exports.createGroupAminoConverters = createGroupAminoConverters;
