import { Component, Input, OnInit } from '@angular/core';
import { Content } from '../../../../entities';
import { ContentTitlePipe } from '../../../pipes/title-case-after-comma.pipe';

@Component({
  selector: 'app-txn-content',
  templateUrl: './txn-content.component.html',
  styleUrls: ['./txn-content.component.scss'],
  providers: [ContentTitlePipe]
})
export class TxnContentComponent implements OnInit {

  @Input() content: Content[] = [];
  @Input() columns: number = 2;
  @Input() addSeparator: boolean = false;
  @Input() startIdx: number = 0;
  @Input() endIdx: number;
  @Input() classnames: string = 'row-3' ;

  COMPLIANCE_PROVIDER = 'Provider'
  TRANSACTION_HASH = 'Transaction Hash'

  constructor() { }

  ngOnInit(): void {
    if (this.endIdx === undefined) {
      this.endIdx = this.content.length;
    }
  }
  columnCount: number = 2;

  getColumnClasses(count: number): string[] {
    return [`col-md-${12 / count}`];
  }

  setColumnCount(count: number): void {
    this.columnCount = count;
  }

  extractURL(value: string): string | null {
    const urlRegex = /\(([^)]+)\)/; // Regular expression to match text within parentheses
    const match = value.match(urlRegex);

    if (match && match.length > 1) {
      const url = match[1]; // Extract the URL
      return url;
    }

    return null; // Return null if no URL is found
  }

  extractTextBeforeParentheses(value: string): string {
    const urlRegex = /(.*)\s\(([^)]+)\)/; // Regular expression to match text before and within parentheses
    const match = value.match(urlRegex);

    if (match && match.length > 1) {
      const textBeforeParentheses = match[1]; // Extract the text before parentheses
      return textBeforeParentheses;
    }

    return value;
  }

  getProviderToolTip(value: string) {
    return `See transaction in ${this.extractTextBeforeParentheses(value)}`;
  }
}
