import ECPairFactory from 'ecpair';
import ecc from '@bitcoinerlab/secp256k1';

const ECPair = ECPairFactory(ecc);

/**
 * Verifies the authenticity of a digital signature for a given public key and message hash.
 * This function uses elliptic curve cryptography (secp256k1) for signature verification.
 *
 * @param {Uint8Array} pubkey - The public key (in `Uint8Array` format) to validate the signature against.
 * @param {Uint8Array} msghash - The message hash (in `Uint8Array` format) that was signed.
 * @param {Uint8Array} signature - The signature (in `Uint8Array` format) to verify.
 * https://github.com/bitcoinjs/bitcoinjs-lib/blob/master/test/integration/transactions.spec.ts
 * @returns {boolean} - Returns `true` if the signature is valid for the given public key and message hash, otherwise `false`.
 * 
 */
const signatureValidator = (
  pubkey: Uint8Array,
  msghash: Uint8Array,
  signature: Uint8Array,
): boolean => ECPair.fromPublicKey(pubkey).verify(msghash, signature);

/**
 * Validates the signature for a given wallet chain.
 * 
 * @param {string} walletChain - The blockchain of the wallet (e.g., "bch", "doge").
 * @param {number} inputIndex - The input index to validate.
 * @param {any} signer - The signer instance used for validation.
 * @param {any} partialSig - Partial signature object for specific chains.
 * @returns {boolean} - Returns true if the signature is valid, false otherwise.
 */
export function validateSignature(signer: any, walletChain: string, inputIndex: number, partialSig: any): boolean {
	// Normalize the chain name for comparison.
	const normalizedChain = walletChain.toLowerCase();
  
	// Determine the validation method based on the wallet chain.
	if (normalizedChain === "bch" || normalizedChain === "doge") {
	  return signer.validateSignaturesOfInput(inputIndex, partialSig[0].pubkey);
	}
  
	return signer.validateSignaturesOfInput(inputIndex, signatureValidator);
  }
  