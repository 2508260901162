import { Wallet } from '../../entities/wallet'
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { LoggerService } from '../../services/logger.service';
import { getParentChain } from '../WalletUtils'
import { SegmentService } from 'ngx-segment-analytics';
import { AuthServiceJWT } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Guid } from "guid-typescript";
import { cosmosSignature, error } from '../../entities/cosmosTransaction';
import { ComposeTransaction } from '../../helpers/trezor/transactionComposer';
import { signETHStakingTransaction ,signSolanaStakingTransaction} from './signers';
import { WalletserviceService } from '../../services/walletservice.service';
import { ErrorPrompt } from '../../components/custom-prompt/custom-prompt.service';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { scopes } from '../../constants/authScopes';


export class stakingSign {
    // public authService: AuthServiceJWT
    // private segment: SegmentService
    txPsbt: string = ''
    chainName: string = '';
    composeTransaction: ComposeTransaction;

    constructor(private wallet: Wallet, private data: DataService, private httpService: HttpService, private logger: LoggerService, private auth: AuthServiceJWT,
        private walletService: WalletserviceService, private segment: SegmentService, private apiService: ApiService) {

    }

    async stakingTransaction(amount, comment, wallet, validator, type, txReq?): Promise<any> {

        console.log('txReq', txReq);
        this.composeTransaction = new ComposeTransaction(this.wallet, this.data, this.httpService, this.logger,this.segment,this.auth, this.walletService);
        if (getParentChain(this.wallet.chain) == "COSMOS") {
            return await this.signCosmosStakingTransaction(amount, comment, wallet, validator, type,)
        }
        if (getParentChain(this.wallet.chain) == "CARDANO") {
            return await this.signCardanoStakingTransaction(amount, comment, wallet, validator, type)
        }
        if (getParentChain(this.wallet.chain).toLowerCase() == "evm") {
            return await signETHStakingTransaction.call(this, amount, comment, wallet, validator, type, txReq)
        }
        if(getParentChain(this.wallet.chain).toLowerCase() == "sol"){
            return await signSolanaStakingTransaction.call(this, amount,comment,wallet,validator,type)
        }
        return null;
    }
    async signCosmosStakingTransaction(amount, comment, wallet, validator, type) {
        console.info('cosmos sign');
        try {
            let dataObj = {
                "amount": (type == 'claimrewards') ? amount : amount.toString(),
                "comment": comment,

            }
            console.info('dataObj', dataObj);

            try {
                var respData = await this.httpService.stakingPrebuild(wallet.id, comment, validator.id, type, dataObj).toPromise();
            } catch (error) {
                return [{ "error": error.error.message }];
            }

            console.log('respData', respData);

            let tx_obj = JSON.parse(respData.data.prebuildTransactionResult.txJson)
            console.info('xy', tx_obj);
            const signData: cosmosSignature | error[] = await this.composeTransaction.signCosmos(tx_obj, wallet);
            if (signData[0]?.error) {
                return signData;
            }
            console.info('signData', signData);

            let sequenceId = Guid.create().toString();

            const signTransactionResponse: signedStakingTransaction = {
                partialSignedTx: {
                    halfSigned: {
                        payload: JSON.stringify(respData.data.prebuildTransactionResult)
                    },
                    signature: signData['signatures'],
                    sequenceId: sequenceId

                }

            };
            console.log('signTransactionResponse', signTransactionResponse);

            var sendresult = await this.httpService.sendStakingTransaction(wallet.id, validator.id, type, signTransactionResponse);
            sendresult = (sendresult)
            console.log(sendresult);
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";

        }
        catch (err) {
            if (err.message == "Ledger device: UNKNOWN_ERROR (0x6a8d)") {
                ErrorPrompt.fire({
                    // position: 'top-end',
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: "Allow unverified contracts from Ledger app setting.",
                    showConfirmButton: false,
                    // timer: 1500
                })
            }
            else if (err.name == "TransportOpenUserCancelled") {
                ErrorPrompt.fire({
                    // position: 'top-end',
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: "It seems you have not connected your ledger device or please recheck the connection and try again.",
                    showConfirmButton: false,
                    // timer: 1500
                })
              
            } else {
                ErrorPrompt.fire({
                    // position: 'top-end',
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: this.walletService.setCustomErrorMsg(err.message),
                    showConfirmButton: false,
                    // timer: 1500
                })
              
            }
            this.segment.track("send-asset-transaction-sign-failed", {user: this.auth.getUser, error: err})
                    .then(() => console.log("Send asset transaction sign failed")).catch((err)=>{});
        }
    }
    // ------------------------

    async signCardanoStakingTransaction(amount, comment, wallet, validator, type) {

        try {

            let dataObj = {
                "amount": (type == 'claimrewards') ? amount : amount.toString(),
                "comment": comment,
                "timestamp": new Date().getTime()
            }

            try {
                return await this.apiService.executeApiWithAuth2Fa(scopes.WalletTxCreate, (accessToken) =>
                  this.httpService.stakingPrebuild(
                    wallet.id,
                    comment,
                    validator.id,
                    type,
                    dataObj,
                    accessToken
                  )
                );
              } catch (error) {
                return Promise.reject(error);
              }

        } catch (e) {
            console.log("errorr are----", e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }
    }
}

export interface HalfSignedStakeingTransaction {
    partialSignedTx: {
        halfSigned?: {
            payload?: string;
        },
        signature?: string;
        sequenceId?: string;
    };
}




export type signedStakingTransaction = HalfSignedStakeingTransaction;