import { Component, Input, OnInit } from '@angular/core';
import { AddressService } from '../../services/address.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {

  constructor( 
    private adresssService : AddressService, 
  ) {}

  loading = false;
  addressSearch: string = "";
  _tab: string = 'native';
  page: number = 1;
  pageSize: number = 10;
  addressesArray: any[] = [];
  assetType: string = "native";
  addressesFound: boolean = true;
  totalAddresses = 0;
  addressWithBalance = 0;
  searchAssetsArray= [];
  noNonNativeAssets : boolean = false;
  noNativeAssets ; boolean = false;
  isSearchLoader : boolean = false;
  totalPages: number;
  addressesObtained: number = 100;
  totalNativeAddresses: number;
  skipVariable: number = 1;

  @Input() walletId: any;
  
  ngOnInit(): void {
    this.loading = true;
    this.getWalletAddresses();
  }

  changeTab(tab) {

    this._tab = tab;
    this.addressesArray = [];
    this.searchAssetsArray = [];
    this.addressSearch = "";

    if(tab === 'native') 
    {
      this.assetType = 'native';
      this.loading = true;
      this.addressesFound = true;
      this.skipVariable = 1;
      this.getWalletAddresses();
    }
     else if(tab === 'others') 
    {
      this.addressesArray = [];
      this.assetType = 'non-native';
      this.loading = true;
      this.addressesFound = true;
      this.skipVariable = 1;
      this.page = 1;
      this.getWalletAddresses();
    } 
    else if(tab === 'search') 
    {
      this.addressesArray = [];
      this.noNativeAssets = false;
      this.noNonNativeAssets = false;
      this.addressesFound = true;
    }
  }

  getWalletAddresses() {
    this.adresssService.getWalletAddresses(this.walletId, this.assetType, this.skipVariable, this.addressesObtained).subscribe((res) => {

      if (this.page === 1) {
        this.addressesArray = res.data.addressesResponse;
      } else {
        this.addressesArray = this.addressesArray.concat(res.data.addressesResponse);
      }
      this.totalPages = Math.ceil(this.addressesArray.length / this.pageSize);

      this.noNativeAssets = this.assetType === 'native' && this.addressesArray.length === 0;
      this.noNonNativeAssets = this.assetType === 'non-native' && this.addressesArray.length === 0;

      this.addressesArray.sort((a, b) => b.balanceUSD - a.balanceUSD);

        if (this.assetType === 'non-native') {
          const filteredAndSortedAddressesArray = 
                    this.addressesArray
                        .map(address => { address.assets = address.assets
                                  .filter(asset => asset.balanceUSD > 0 && asset.balance > 0)
                                  .sort((a, b) => b.balanceUSD - a.balanceUSD);
                              return address;
                        });

          this.addressesArray = filteredAndSortedAddressesArray;
        }
        else if (this.assetType === 'native') {
          this.totalAddresses = res.data.totalAddresses;
          this.addressWithBalance = res.data.totalAddressesWithBalance;
        }

        this.loading = false;
      });
  }


  accordion(address: any) {
    this.addressesArray.forEach(addrEle => {
      if (addrEle.address === address) {
        if (addrEle.state == "open") {
          addrEle.state = "close";
        }
        else {
          addrEle.state = "open";
        }
      };
    });
  }

  getTotalCryptoBalance(assets: any) {
    if(!assets || assets.length === 0) {
      return 0;
    }else if(assets && assets?.length === 1) {
      return assets[0].balance;
    } else {
      return assets.reduce((a, b) => Number(a.balance) + Number(b.balance), 0);
    }
  }

  searchAddressDetails() {
    this.addressesArray = [];
    this.searchAssetsArray = [];
    this.addressesFound = true;
    this.isSearchLoader = true;

    const searchPage = Math.ceil(this.page/this.pageSize);

    this.adresssService.searchAddressDetailsByWalletIdAndAddress(this.walletId, this.addressSearch, searchPage, this.addressesObtained)
      .subscribe((res) => {
        
        this.addressesArray = res.data.addressesResponse.sort((a, b) => {
          if (a.balanceUSD < b.balanceUSD) return 1;
          if (a.balanceUSD > b.balanceUSD) return -1;
          return 0;
        });

      this.isSearchLoader = false;  
      if (this.addressesArray.length === 0) this.addressesFound = false
      if (this.addressesFound) this.setAdressAssetsData();

    });
  }

  setAdressAssetsData(){
    this.addressesArray.forEach((address) => {
      if (address.assets.length !== 0) {
        address.assets = address.assets
        .map(asset => ({
            ...asset,
            lastTxnTimestamp: address.lastTxnTimestamp,
            balanceUSD: asset.balanceUSD
          }));
    
        this.searchAssetsArray.push(...address.assets);
      }
    });
  }

  onPageChange(page: number): void {
    if (page === this.totalPages) {
      this.skipVariable ++;
      this.getWalletAddresses();
    }
  }
}