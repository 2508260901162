/**
 * download tpye enum for pdf and csv
 */
export enum DownloadTypes {
    HOLDING_STATEMENT = "hs",
}

/**
 * download module enum
 */
export enum DownloadModule {
    CSV = "csv",
    PDF = "pdf"
}

/**
 * download type definition
 */
export type downloadsType = "hs" | "as";

/**
 * download module type definition
 */
export type moduleType = "csv" | "pdf";