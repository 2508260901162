<div class="card card-border" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''" *ngIf="card == 'wallets'">
    <div class="card-body" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <!-- <img  class="align-self-center mr-2 img-fluid img-round-border" src="../../../assets/images/wallet.svg"> -->
            <i class="icon-wallet mr-2 img-fluid icon-round-border text-primary"></i>
            <div class="d-flex flex-column text-left">
                <span class="">{{option.name}}</span>
                <span class="network" [ngClass]="option.chain.toLowerCase()">
                    {{ option.chain | getName }}
                </span>
            </div>
            <div class="d-flex flex-column ml-auto text-right">
                <span class="font-weight-500 ">{{ option.balance | amountToPretty : 5}} {{option.coin}} </span>
                <span class=" font-weight-500 font-size-12">${{ option.balanceUSD | amountToPretty : 2}}</span>
            </div>
        </div>
    </div>
</div>
<div class="card card-border" [ngClass]="dropdown_card === 'dropdown-card'?'card-dropdown':''" *ngIf="card == 'assets'">
    <div class="card-body" [ngClass]="card_padding">
        <div class="media border-after-xs align-items-center">
            <img  class="align-self-center mr-2 img-fluid img-round-border" [src]="option.chain | getIcon">
            <div class="d-flex">
                <span class="">{{option.assets}}</span>
            </div>
            <div class="d-flex flex-column ml-auto text-right">
                <span class="font-weight-500 ">{{ option.amount | amountToPretty : 5}} {{option.assets}} </span>
                <span class=" font-weight-500 font-size-12">${{ option.amountUSD | amountToPretty : 2}}</span>
            </div>
        </div>
    </div>
</div>