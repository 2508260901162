import { Title } from '@angular/platform-browser';
import { Component, OnInit, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Wallet } from '../../entities/wallet';
import { WalletserviceService } from '../../services/walletservice.service';
import { ConstantService } from '../../services/constant.service';
import { jsPDF } from "jspdf";
import { parse } from 'path';
import { DatePipe } from '@angular/common';
import { getName } from '../../helpers/coins';
import Swal from "sweetalert2";
import { HttpService } from '../../services/http.service';
import { environment } from 'src/environments/environment';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { DataService } from '../../services/data.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-wallet-key-card-page',
  templateUrl: './wallet-key-card-page.component.html',
  styleUrls: ['./wallet-key-card-page.component.scss']
})
export class WalletKeyCardPageComponent implements OnInit {
  pageTitle = "";

  wallets: Wallet[];
  orgSubscription: Subscription;
  organization: any;
  res: any;
  _res: any;
  today: number = Date.now();
  latest_date = this.datepipe.transform(this.today, 'd MMM y');
  @Input() PDFDownload_component_for: string;
  @Input() walletDetailWalletId: any;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private walletService: WalletserviceService,
    private org: ConstantService,
    public datepipe: DatePipe,
    private httpService: HttpService,
    private titleService:Title,
    private data: DataService,
  ) {
  
  // this.wallets = walletService.getAllWallets();
  
  this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((wallets) => {
    wallets = wallets.filter(ele=>ele.version == 1)
    this.wallets = wallets;
    
    const ids = this.wallets.map(item => item.id);

    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

  const uniqIds = ids.filter(distinct);
   this.res = uniqIds.map(id => {
    let tmp = []
    const arr = this.wallets.filter(item => item.id === id)
    var name:string;
    var chain:string;
    var parentchain:string;
    var sum: number = 0;
    var walletKeys: any;
    var address: any;
    var config: any;
    var orgid: any;

    arr.forEach((item:any) => {
      sum = sum + parseFloat(item.balanceUSD);
      chain = item.chain;
      name = item.name;
      parentchain = item.parentchain;
      walletKeys = item.walletKeys;
      address = item.address;
      config = item.config;
      orgid = item.orgid;
    });

    tmp.push(sum);

    return { name: name, id: id, balanceUSD: sum, chain: chain, parentchain: parentchain, walletKeys: walletKeys, address: address, config: config, orgid: orgid }
    });
    if(this.PDFDownload_component_for === 'wallet_key_card_pdf_download')
    {
      this.res = this.res.find(obj => obj.id == this.walletDetailWalletId)
      this._res = this.res;  
    this.pageTitle = `${environment.buildName} Vaults - Wallets`;
    this.titleService.setTitle(this.pageTitle);
     }else{
      this.pageTitle = `${environment.buildName} Vaults -  Key Cards`;
      this.titleService.setTitle(this.pageTitle);
    }
  })
}

  ngOnInit(): void {
    this.orgSubscription = this.org.currentOrg.subscribe(o => {
      this.organization = o;
    })
   
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  loadSigners(txdata) {
    let walletSigners = txdata.walletKeys;
    let signersWithStatus = [];
    for (let i = 0; i < walletSigners.length; i++) {
      signersWithStatus.push(walletSigners[i]);
      // if (walletSigners[i].displayName.toLowerCase() !== "liminal") {
      //   signersWithStatus.push(walletSigners[i]);
      // }
    }
    return signersWithStatus;
  }

  urlContentToDataUri(url){
    return fetch(url).then(response => response.blob())
           .then(blob => new Promise(callback=>{
                let reader = new FileReader();
                reader.onload = function(){callback(String(this.result).substr(String(this.result).indexOf('base64,') + 7))};
                reader.readAsDataURL(blob);
            }));
  }

  async view_card(wallet) {
    const doc = new jsPDF({
        orientation: 'l',
        format: 'legal'
    });
    try {
      let page = 1;

    // Add the font to jsPDF
    doc.addFileToVFS("Poppins.ttf", String(await this.urlContentToDataUri('/assets/fonts/Poppins/Poppins-SemiBold.ttf')));
    doc.addFont("Poppins.ttf", "Poppins", "normal");
    doc.addFileToVFS("Poppins_Regular.ttf", String(await this.urlContentToDataUri('/assets/fonts/Poppins/Poppins-Regular.ttf')));
    doc.addFont("Poppins_Regular.ttf", "Poppins_Regular", "normal");

    function pdfFont(size, face, color) {
      doc.setFontSize(size);
      doc.setFont(face);
      doc.setTextColor(color);
    }

    // First Page
    var img_logo = new Image();
    img_logo.src = '/assets/images/logo/large.png';
    doc.addImage(img_logo, 'svg', 10, 20, 50, 9);

    var img_circle = new Image();
    img_circle.src = '/assets/images/wallet-key-card/circle.png';
    doc.addImage(img_circle, 'svg', 200, 50, 130, 120);

    pdfFont(40, 'Poppins', '#000000');
    doc.text(`${wallet.name} (${wallet.chain})`, 10, 80);
 
    pdfFont(14, 'Poppins_Regular', '#000000');
    doc.text(`Wallet Key Card • ${this.latest_date}`, 10, 90);

    pdfFont(16, 'Poppins_Regular', "#6c757d");
    doc.text("This key card will help you to recreate the wallet even when Liminal does not exist. Please note that this card does not contain any private keys and hence the information mentioned in this card cannot help you to spend any funds from this wallet.", 10, 100, { maxWidth: 180 });

    if (wallet.orgid) {
      let og = this.organization.filter((o) => {
        return o.id == wallet.orgid;
      });
      if (og.length) {
        if(og[0].name)
        {
          pdfFont(20, 'Poppins', "#2BB673");
          doc.text(og[0].name, 10, 180);
        }
        if(og[0].address)
        {
          pdfFont(16, 'Poppins_Regular', "#2b2b2b");
          doc.text(og[0].address, 10, 190);
        }
        // Rectangle
        // doc.setFillColor("#2BB673");
        // doc.rect(100, 190, 100, 1, 'F');
      }
    }

    // Second Page
    page = page + 1;
    doc.insertPage(page);

    pdfFont(40, 'Poppins', "#000000");
    doc.text("Group wallet", 10, 20);

    pdfFont(16, 'Poppins_Regular', "#6c757d");
    doc.text("Group wallets require more than one person to sign transactions.", 10, 30);

    var img_rings = new Image();
    img_rings.src = '/assets/images/logo/logo-icon.png';
    doc.addImage(img_rings, 'png', 322, 10, 15, 10);

    doc.setLineWidth(1);
    doc.setDrawColor("#f5f8fe");
    doc.roundedRect(10, 50, 150, 40, 5, 5);

    pdfFont(14, 'Poppins', "#2BB673");
    doc.text(`${wallet.config.split("of").join(" of ")} Config.`, 16, 65);

    pdfFont(12, 'Poppins_Regular', "#2b2b2b");
    doc.text(`Minimum ${wallet.config.split("of")[0]} people out of ${wallet.config.split("of")[1]} signers required to sign transactions.`, 16, 72, { maxWidth: 130 });

    doc.setLineWidth(1);
    doc.setDrawColor("#f5f8fe");
    doc.roundedRect(10, 100, 150, 40, 5, 5);

    pdfFont(14, 'Poppins', '#2BB673');
    doc.text("Policy Shield ", 16, 112,{maxWidth: 150});

    if (wallet.parentchain == "EVM") {
      pdfFont(14, 'Poppins', '#d1baba');
      doc.text("enabled", 50 , 112);
    } else {
      pdfFont(14, 'Poppins', '#d1baba');
      doc.text("disabled", 50 , 112);
    }

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("No key is managed by Liminal and hence policies can not be enforced at signing by Liminal.", 16, 120,{maxWidth: 130});

    doc.setLineWidth(1);
    doc.setDrawColor("#f5f8fe");
    doc.roundedRect(10, 150, 150, 40, 5, 5);

    pdfFont(14, 'Poppins', '#2BB673');
    doc.text("Address", 16, 162);

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text(wallet.address, 16, 170);

    pdfFont(14, 'Poppins', '#2b2b2b');
    doc.text(`${wallet.chain} Blockchain`, 201, 62);

    doc.setLineWidth(1);
    doc.setFillColor("#f5f8fe");
    doc.roundedRect(195, 100, 150, 40, 5, 5, 'F');

    pdfFont(14, 'Poppins', '#2b2b2b');
    doc.text(wallet.chain, 201, 112);

    doc.setLineWidth(1);
    doc.setFillColor("#f5f8fe");
    doc.roundedRect(195, 150, 150, 40, 5, 5, 'F');

    if (wallet.parentchain == "EVM") {
      pdfFont(14, 'Poppins', '#2b2b2b');
      doc.text("Gnosis", 201, 162);
    }
    
    // Members Page
    let y = 10;

    wallet.walletKeys.forEach(function (w, i) {
      if (i%6 == 0) {
        y = 10;
        page = page + 1;
        doc.insertPage(page);
        pdfFont(40, 'Poppins', '#2b2b2b');
        doc.text("Members", 10, 20);
        doc.addImage(img_rings, 'png', 322, 10, 15, 10);
      } 
      if (i % 2 == 0) {
        y += 55;
        var x =  '0' + (i+1) + '.';
        if (i >= 9) x = (i+1) + '.';
        pdfFont(34, 'Poppins', '#2BB673');
        doc.text(x, 10, y); // +40

        pdfFont(20, 'Poppins', '#2b2b2b');
        doc.text(`${w.displayName}`, 30, (y - 10));// -10
        pdfFont(18, 'Poppins_Regular', '#096cb8');
        doc.text(`${w.email}`, 30, (y - 1));// -10
        pdfFont(14, 'Poppins_Regular', '#6c757d');
        doc.text("Address -", 30, (y + 7));  // -2
        doc.text(w.xpub, 30, (y + 13),{maxWidth: 150});  // +6 
        pdfFont(14, 'Poppins_Regular', '#6c757d');
        doc.text("Path:", 30,  (y + 25)); // +15 
        pdfFont(14, 'Poppins_Regular', '#2b2b2b');
        doc.text(w.path, 43, (y + 25)); // +15
        pdfFont(14, 'Poppins_Regular', '#6c757d');
        doc.text("Device:", 90, (y + 25));  // +15
        pdfFont(14, 'Poppins', '#2b2b2b');
        doc.text(w.provider, 110, (y + 25));   // +15 
      } else {
        var x =  '0' + (i+1) + '.';
        if (i >= 9) x = (i+1) + '.';
        
        pdfFont(34, 'Poppins', '#2BB673');
        doc.text(x, 165, y); // +40

        pdfFont(20, 'Poppins', '#2b2b2b');
        doc.text(`${w.displayName}`, 185, (y - 10));// -10
        pdfFont(18, 'Poppins_Regular', '#096cb8');
        doc.text(`${w.email}`, 185, (y - 1));// -10
        pdfFont(14, 'Poppins_Regular', '#6c757d');
        doc.text("Address -", 185, (y + 7));  // -2
        doc.text(w.xpub, 185, (y + 13),{maxWidth: 150});  // +6 
        pdfFont(14, 'Poppins_Regular', '#6c757d');
        doc.text("Path:", 185,  (y + 25)); // +13 
        pdfFont(14, 'Poppins_Regular', '#2b2b2b');
        doc.text(w.path, 198, (y + 25)); // +13
        pdfFont(14, 'Poppins_Regular', '#6c757d');
        doc.text("Device:", 243, (y + 25));  // +13
        pdfFont(14, 'Poppins', '#2b2b2b');
        doc.text(w.provider, 263, (y + 25));   // +13 
      }
    });

    // Fourth Page
    page = page + 1;
    doc.insertPage(page);

    pdfFont(40, 'Poppins', '#2b2b2b');
    doc.text("Notes", 10, 20);
    doc.addImage(img_rings, 'png', 322, 10, 15, 10);
    pdfFont(12, 'Poppins_Regular', '#2BB673');
    doc.text("1.", 10, 30);

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("It is your responsibility to ensure that the information mentioned in this wallet recovery card is complete and accurate. Liminal holds no liability for inability to recover funds due to incorrect information.", 17, 30, {maxWidth: 200});

    pdfFont(12, 'Poppins_Regular', '#2BB673');
    doc.text("2.", 10, 50);

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("This is confidential information and hence you should save this card in a secure location and share it only with the relevant people.", 17, 50, {maxWidth: 200});

    pdfFont(12, 'Poppins_Regular', '#2BB673');
    doc.text("3.", 10, 65);

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("This wallet key card can be used to recreate this group wallet outside of the Liminal platform. Anyone who has access to this card can recreate this wallet and view all transactions that have been done using this wallet.", 17, 65, {maxWidth: 200});

    pdfFont(12, 'Poppins_Regular', '#2BB673');
    doc.text("4.", 10, 85);

    pdfFont(12, 'Poppins', '#2b2b2b');
    doc.text("This wallet does not contain any private keys. The information mentioned in this card cannot be used to access and spend funds from this wallet. Funds can be only be spent as per the defined policy by people who have access to the private keys.", 17, 85, {maxWidth: 200});

    pdfFont(12, 'Poppins_Regular', '#2BB673');
    doc.text("5.", 10, 105);

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("You should ensure that the private keys of the wallet members are appropriately backed up.", 17, 105, {maxWidth: 200});

    doc.setLineWidth(1);
    doc.setFillColor("#f5f8fe");
    doc.roundedRect(240, 30, 90, 90, 5, 5, 'F');

    pdfFont(16, 'Poppins', '#2b2b2b');
    doc.text("Support", 250, 45, {maxWidth: 60});

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("Read         guide to restore your group wallet using the information mentioned in this card.", 250, 55, {maxWidth: 60});
    
    if (wallet.chain == "ETH") {
      const textWidth_this = doc.getTextWidth("this");
      doc.setTextColor("#007bff");
      doc.textWithLink('this', 262, 55, { url: 'https://support.liminalcustody.com/support/solutions/articles/1060000078984-how-to-recover-evm-based-multi-sig-disaster' });
      doc.setDrawColor("#007bff");
      doc.line(262, 55.5, 262 + textWidth_this, 55.5, 'F');
    } else {
      const textWidth_this = doc.getTextWidth("this");
      doc.setTextColor("#007bff");
      doc.textWithLink('this', 262, 55, { url: 'https://support.lmnl.app/en/articles/5484217-how-to-restore-your-group-wallet-without-using-liminal-bitcoin' });
      doc.setDrawColor("#007bff");
      doc.line(262, 55.5, 262 + textWidth_this, 55.5, 'F');
    }

    pdfFont(12, 'Poppins_Regular', '#2b2b2b');
    doc.text("If you need any help with restoring your wallets, please get in touch with us at support@lmnl.app ", 250, 80, {maxWidth: 60});

    doc.setTextColor("#007bff");
    doc.textWithLink('Help Desk', 250, 105, { url: 'https://support.lmnl.app/' });
    const textWidth = doc.getTextWidth("Help Desk");
    doc.setDrawColor("#007bff");
    doc.line(250, 105.5, 250 + textWidth, 105.5, 'F');

    doc.save(`${wallet.name}_${wallet.chain}.pdf`);
    }
    catch(err) {
      // console.log('dhruv',err);
      ErrorPrompt.fire({
        icon: "error",
        title: "Error",
        text: err,
        showConfirmButton: false,
      })
    } 
  }
}