<!-- <button style="display: flex;justify-content: center;align-items: center;" (click)="buttonClicked()">Click me</button> -->

<ng-template #ipAddressRestrictionc let-modal>
    <div class="modal-header border-0 px-4">
        <div class="d-inline">
            <h2 class="modal-title">
                IP Whitelist
            </h2>
        </div>
        <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss('cross_click');">
            <i class="icon-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <form #ipForm="ngForm" (ngSubmit)="onIpFormSubmit(ipForm)">
                <div class="row align-items-center form">
                    <div class="col-md-5 mt-2 pb-2" style="padding: 0 5px;">
                        <div class="d-inline">
                            <label class="mb-2 text-muted font-weight-500">IP Address</label>
                        </div>
                        <input autocomplete="off" class="form-control" placeholder="Enter IP Addresses" type="text"
                            name="ipAddress" [(ngModel)]="ipAddress" required>
                    </div>
                    <div class="col-md-5 mt-2 pb-2" style="padding: 0 15px;">
                        <div class="d-inline">
                            <label class="mb-2 text-muted font-weight-500">Subnet Mask</label>
                        </div>
                        <input autocomplete="off" class="form-control" placeholder="Enter Subnet Masks" type="text"
                            name="subnetMask" [(ngModel)]="subnetMask" required>
                    </div>
                    <div class="col-md-2 add-btn pb-2" style="padding: 0 5px">
                        <button class="btn btn-primary active w-100" type="submit"
                            [disabled]="ipForm.invalid">Add</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="row">
            <div class="col-12">
              <div class="card card-margin">
                <div class="table-responsive table-new">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" class="text-left" style="padding-left: 50px;">IP Address</th>
                        <th scope="col" class="text-left" style="padding-left: 50px;">Subnet Mask</th>
                        <th scope="col" class="text-center" style="padding-left: 30px;">Action</th>
                      </tr>
                    </thead>
                    <ng-container *ngIf="!(ipAddressList.length === 0 && !isLoading)">
                      <tbody>
                        <ng-container *ngIf="!isLoading; else skeletonBlock">
                          <tr class="card-row-wallet" *ngFor="let address of ipAddressList">
                            <td style="padding-left: 50px;" [ngClass]="{ 'disabled': address?.status === 0 }">
                              <div class="justify-content-start">
                                <div class="d-flex flex-column">
                                  <span>{{address.ipAddress}}</span>
                                </div>
                              </div>
                            </td>
                            <td style="padding-left: 50px;" [ngClass]="{ 'disabled': address?.status === 0 }">
                              <div class="justify-content-start">
                                <div class="d-flex flex-column ">
                                  <span>{{address.subnetMask}}</span>
                                </div>
                              </div>
                            </td>
                            <td style="padding-left: 50px;">
                              <div class="justify-content-start" *ngIf="address?.status === 0 || address?.status === 1">
                                <div class="d-flex flex-column text-center">
                                  <span class="cursor-pointer action enable-connection"
                                    *ngIf="address?.status === 0; else disableAction"
                                    style="margin-right: 10px;" (click)="enableDisableIpList(address, 'enable')">Enable</span>
                                  <ng-template #disableAction>
                                    <span class="cursor-pointer action disable-connection" style="margin-right: 10px;"
                                      (click)="enableDisableIpList(address, 'disable')">Disable</span>
                                  </ng-template>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-template #skeletonBlock>
                          <tr *ngFor="let item of [].constructor(3); let i = index">
                            <td style="padding-left: 35px;">
                              <ngx-skeleton-loader></ngx-skeleton-loader>
                            </td>
                            <td style="padding-left: 35px;">
                              <ngx-skeleton-loader></ngx-skeleton-loader>
                            </td>
                            <td style="padding-left: 35px;">
                              <ngx-skeleton-loader></ngx-skeleton-loader>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </ng-container>
                  </table>
                </div>
                <span *ngIf="ipAddressList.length === 0 && !isLoading">
                  <div class="empty">
                    <img src="../../../../assets/images/lock-icon.svg" alt="icon">
                  </div>
                  <div class="txt text-dark">
                    <span>Currently, any IP can access your wallets by using this API key. To enhance security, you should restrict access to specific IP addresses.</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
    </div>


</ng-template>