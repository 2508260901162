"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STANDARD_ERROR_MAP = exports.SERVER_ERROR_CODE_RANGE = exports.RESERVED_ERROR_CODES = exports.SERVER_ERROR = exports.INTERNAL_ERROR = exports.INVALID_PARAMS = exports.METHOD_NOT_FOUND = exports.INVALID_REQUEST = exports.PARSE_ERROR = void 0;
exports.PARSE_ERROR = "PARSE_ERROR";
exports.INVALID_REQUEST = "INVALID_REQUEST";
exports.METHOD_NOT_FOUND = "METHOD_NOT_FOUND";
exports.INVALID_PARAMS = "INVALID_PARAMS";
exports.INTERNAL_ERROR = "INTERNAL_ERROR";
exports.SERVER_ERROR = "SERVER_ERROR";
exports.RESERVED_ERROR_CODES = [-32700, -32600, -32601, -32602, -32603];
exports.SERVER_ERROR_CODE_RANGE = [-32000, -32099];
exports.STANDARD_ERROR_MAP = {
  [exports.PARSE_ERROR]: {
    code: -32700,
    message: "Parse error"
  },
  [exports.INVALID_REQUEST]: {
    code: -32600,
    message: "Invalid Request"
  },
  [exports.METHOD_NOT_FOUND]: {
    code: -32601,
    message: "Method not found"
  },
  [exports.INVALID_PARAMS]: {
    code: -32602,
    message: "Invalid params"
  },
  [exports.INTERNAL_ERROR]: {
    code: -32603,
    message: "Internal error"
  },
  [exports.SERVER_ERROR]: {
    code: -32000,
    message: "Server error"
  }
};
