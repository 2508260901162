import { Rule } from "./firewall.model";
import { TeamsV2 } from "./teams.model";

export interface QuorumApproveRejectRequest {
    requestIdentifier: string;
    quorumAppConfigId: number[];
    comment?: string;
}


export enum QuorumReqApprovalStatus {
    APPROVED = 1,
    REJECTED = 2
}

export interface QuorumApproveRejectResponse {
    requestIdentifier: string;
    quorumAppReqConfigId: number;
    userId: number;
    comment: string;
    status: QuorumReqApprovalStatus;
}

export interface Quorum {
    id: number;
    approvalsNeeded: number;
    teamId: number;
    status: number;
    team: TeamsV2;
    quorumAppConfig: QuorumAppConfig;
  }

export interface QuorumAppConfig {
    id: number;
    quorumId: number;
    referenceTableId: number;
    position: number;
    status: number;
    quorum: Quorum;
    rule: Rule;
  }