<div class="mb-2 ml-2">
    <div class="font-weight-500 font-size-14 mb-3" *ngIf="teamName && requiredApprovals">{{ requiredApprovals }} of {{
      teamName}}</div>
    <div class="row">
      <div class="col-md-4" *ngFor="let member of listOfMembers">
        <div class="d-flex details-container rounded mb-4">
          <div class="d-inline user-image-container">
            <img class="align-self-center img-40 rounded-circle-white-chip text-left avatar mr-2"
              src="{{member.photoURL}}">
            <div *ngIf="isMemberApproved(member)" class="user-approved-image"></div>
            <div *ngIf="isMemberRejected(member)" class="user-rejected-image"></div>
          </div>
  
          <div class="overflow-ellipsis">
            <span class="font-size-14 allow-line-breaks" ngbTooltip="{{member.displayName}}">{{member.displayName}}</span>
            <div class="font-size-14 text-muted">{{getApprovalStatus(member)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>