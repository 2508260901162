import { Pipe, PipeTransform } from '@angular/core';
import { eventsArray } from 'src/app/components/settings/audit-util';

@Pipe({
  name: 'logevent'
})
export class LogeventPipe implements PipeTransform {

  transform(eventKey: string): string {
    const event = eventsArray.find(item => item.event === eventKey);
    return event ? event.userEvent : eventKey;
  }

}
