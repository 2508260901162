"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSearchTxQueryArray = void 0;
function isSearchTxQueryArray(query) {
  return Array.isArray(query);
}
exports.isSearchTxQueryArray = isSearchTxQueryArray;
