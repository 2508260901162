import {Title} from "@angular/platform-browser";
import {Component, OnInit} from "@angular/core";
import "jspdf-autotable";
import {DataService} from "src/app/shared/services/data.service";
import {HttpService} from "src/app/shared/services/http.service";
import {environment} from "src/environments/environment";
import {ReportDownloadType, ReportType} from "@entities/members";
import {ConstantService} from "src/app/shared/services/constant.service";
import {ReportsService} from "src/app/shared/services/reports.service";
import {DownloadModule, DownloadTypes} from "src/app/shared/constants/report.const";

import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
    pageTitle = `${environment.buildName} Vaults - Reports`;
    subOrgDetails: any;
    wallets = null;
    _wallets = {};
    balance = 0;
    userDetails: any;
    today: number;
    orgDetails: any = {
        name: null,
        businessName: null,
        address: null,
        billingAmount: null,
    };
    customCalenderAccount = [];
    customCalenderCompliance = [];
    focusCompliance = false;
    loading: boolean[] = [false];
    loadingAcc: boolean[] = [false];
    newOrg: boolean = true;
    morgDetails: any = {};
    primarycolor: any;
    isCustody: boolean;
    accountFilterDates: any[] = [];
    complianceFilterDates: any[] = [];
    ReportTypeEnum = ReportType;
    ReportDownloadTypeEnum = ReportDownloadType;
    disableCSV = false;
    focusTotInitDownload: boolean;
    focusTotInit: boolean;
    focusTotInitDownloadAcc: boolean;

    constructor(
        private data: DataService,
        private httpService: HttpService,
        private titleService: Title,
        private constService: ConstantService,
        private reportService: ReportsService,
        private modalService: NgbModal,
    ) {
        this.titleService.setTitle(this.pageTitle);

        this.data.wallets.subscribe((wallets) => {
            if (wallets) {
                let balance = 0;
                this._wallets = wallets.reduce(function (r, a) {
                    if (!r[a.id]) {
                        r[a.id] = {
                            id: a.id,
                            name: a.name,
                            network: a.chain,
                            balance: 0,
                            address: a.address,
                            type: a.type,
                            coins: [],
                        };
                    }
                    balance += parseFloat(String(a.balanceUSD));
                    r[a.id].balance += parseFloat(String(a.balanceUSD));
                    if (a.tokenType == 2) {
                        r[a.id].coins.push({
                            coin: a["chain"],
                            balance: a["nativePrice"],
                            balanceUSD: a.balanceUSD,
                            nft: a["comman_name"] ? a["comman_name"] : "NFT",
                        });
                    } else {
                        r[a.id].coins.push({
                            coin: a.coin,
                            balance: a.balance,
                            balanceUSD: a.balanceUSD,
                        });
                    }
                    return r;
                }, {});
                this.balance = balance;
                const sortable = Object.entries(this._wallets).sort(
                    ([, a]: any, [, b]: any) => b.balance - a.balance
                );
                const maps = new Map();
                sortable.map((e) => maps.set(e[0], e[1]));
                this.wallets = Array.from(maps, ([key, value]) => {
                    return {
                        [key]: value,
                    };
                });
            }
        });
        this.data.getUserProfile.subscribe(async (user_detail: any) => {
            if (!user_detail) return;
            this.userDetails = user_detail;
            const OrgId = this.userDetails.organizations[0].id;
            this.orgDetails = await httpService
                .getOrganization(OrgId)
                .toPromise();
            this.subOrgDetails = this.userDetails.organizations[0]
            this.isCustody = this.constService.org.value[0]['isCustody'];
            this.getCalendarDates();

            // to do: need morg details
            const mngOrgId = this.userDetails.organizations[0]?.managementOrgId;
            if (mngOrgId) {
                this.httpService
                    .getOrganization(mngOrgId).subscribe((res: any) => {
                    this.morgDetails = res;
                });
            }

        });
    }

    ngOnInit(): void {
        this.accountFilterDates = [];
        this.complianceFilterDates = [];
        this.primarycolor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--primary");
    }

    getMonthList(fromDate, toDate, type) {
        const fromYear = fromDate.getFullYear();
        const fromMonth = fromDate.getMonth();
        const toYear = toDate.getFullYear();
        const toMonth = toDate.getMonth();
        const months = [];

        for (let year = fromYear; year <= toYear; year++) {
            let monthNum = year === fromYear ? fromMonth : 0;
            const monthLimit = year === toYear ? toMonth : 11;
            if (type === "account") {
                if (fromYear === toYear && fromMonth === toMonth) {
                    let month = monthNum + 1;
                    let monthWord = this.getMonthName(month);
                    months.push({date: monthWord + " " + year, month, year});
                    break;
                }
                this.newOrg = false;
                for (; monthNum <= monthLimit; monthNum++) {
                    let month = monthNum + 1;
                    let monthWord = this.getMonthName(month);
                    // '2022-12-01T12:40:08Z'
                    months.push({date: monthWord + " " + year, month, year});
                }
            } else {
                for (; monthNum <= monthLimit; monthNum++) {
                    let month = monthNum + 1;
                    let monthWord = this.getMonthName(month);
                    months.push({date: monthWord + " " + year, month, year});
                }
            }
        }
        return months;
    }

    getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString("en-US", {month: "long"});
    }

    /**
     * Download holding statement CSV
     * @param template modal template reference
     */
    async holdingStatement(template) {
        this.disableCSV = true
        try {
            const data: any = await this.httpService.downloadHolding({
                type: DownloadTypes.HOLDING_STATEMENT,
                module: DownloadModule.CSV
            }).toPromise();
            if (data?.data?.key) {
                this.disableCSV = false;
                this.openModal(template);
                window.open(`${environment.apiUrl}/download/${data?.data?.key}`, '_blank');
            }
        } catch (error) {
            this.disableCSV = false;
        }
    }

    handleDateFilter(event, data, type) {
        let checked = event.target.checked;
        switch (type) {
            case this.ReportTypeEnum.ACCOUNT:
                this.accountFilterDates = this.reportService.checkboxFilter(checked, data, this.accountFilterDates);
                break;
            case this.ReportTypeEnum.COMPLIANCE:
                this.complianceFilterDates = this.reportService.checkboxFilter(checked, data, this.complianceFilterDates);
                break;
        }
    }


    async downloadMultipleReports(type, downloadType?: ReportDownloadType) {
        let paramObj = null;
        let segmentData = {
            userId: this.userDetails,
            timestamp: new Date(),
            event: ""
        };
        switch (type) {
            case this.ReportTypeEnum.ACCOUNT:
                segmentData.event = "account-statement-downloaded";
                this.loadingAcc[0] = true;
                paramObj = this.reportService.filterParamObjs(this.accountFilterDates);
                if (paramObj !== null) {
                    let result = await this.httpService.getAccountStatement(encodeURIComponent(JSON.stringify(paramObj)), downloadType).toPromise();
                    this.reportService.handleMultipleReportsResponse(result, type, this.loadingAcc, this.accountFilterDates, segmentData);
                }
                break;
            case this.ReportTypeEnum.COMPLIANCE:
                segmentData.event = "compliance-report-downloaded";
                this.loading[0] = true;
                paramObj = this.reportService.filterParamObjs(this.complianceFilterDates);
                if (paramObj !== null) {
                    let result = await this.httpService.getComplianceStatement(encodeURIComponent(JSON.stringify(paramObj))).toPromise();
                    this.reportService.handleMultipleReportsResponse(result, type, this.loading, this.complianceFilterDates, segmentData);
                }
                break;
            default:
                break;
        }
    }

    /**
     * generic method to open the modal through html native element
     * @param content
     */
    openModal(content) {
        this.modalService.open(content, {windowClass: ' modal-custom-background', centered: true});
    }

    /**
     * Download holding statement PDF
     * @param template modal template reference
     */
    async downloadHoldingPdf(template) {
        this.disableCSV = true
        const data: any = await this.httpService.downloadPDF(DownloadTypes.HOLDING_STATEMENT).toPromise();
        if (data) this.disableCSV = false;
        if (data?.success) {
            this.openModal(template);
        }
    }

    /**
     * Generate date list for dropdowns
     * */
    private getCalendarDates() {
        // Don't regenerate dates on loopRefresh
        if (this.customCalenderAccount.length && this.customCalenderCompliance.length) return;

        const orgJoiningDate = new Date(this.orgDetails?.createdAt);
        const previousMonth = new Date();
        previousMonth.setDate(1);
        previousMonth.setHours(-1);

        this.customCalenderAccount = this.getMonthList(
            orgJoiningDate,
            previousMonth,
            ReportType.ACCOUNT,
        );
        this.customCalenderCompliance = this.getMonthList(
            orgJoiningDate,
            previousMonth,
            ReportType.COMPLIANCE,
        );
    }
}
