import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { WalletMessgaes } from '../../constants/wallet.const';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {};
  org_member: any;
  selectedMember: any = [];
  step:string = 'step-1';
  @Input() component_for: string;
  @Input() walletMember: any;
  @Input() walletRole:string;
  @Input() walletId:any;
  @Input() isWalletArchived: boolean;
  public addMemberTooltip: string;
  constructor(private modalService: NgbModal, public httpService: HttpService,private data: DataService) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'displayName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true
    };
    this.addMemberTooltip = this.isWalletArchived ? WalletMessgaes.ARCHIVED_MEMBER : "";
  }

  open(content: any) {
    this.selectedMember = [];
    this.step = 'step-1';
    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true });
    this.getOrgMember();
  }

  async getOrgMember() {
    let orgAll = await this.httpService.getAllMembers().toPromise();
    this.org_member = orgAll[0].members;
    this.org_member = this.getDifference(this.org_member, this.walletMember);
    console.info('filtermember', this.org_member);
  }

  getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.userid === object2.userid;
      });
    });
  }

  onItemSelect(item: any) {
    this.selectedMember.push(item);
    console.info('dc',this.selectedMember);
    
  }
  onItemDeSelect(item: any) {
    this.selectedMember = this.selectedMember.filter(ele => ele.id != item.id);
  }
  onSelectAll(items: any) {
    this.selectedMember = items;
  }
  onDeSelectAll(items: any)
  {
    this.selectedMember = items;
  }
  get getItems() {
    return this.org_member.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }

  async addMemberAsViewer() {
    let memberId = [];
    this.selectedMember.forEach(element => {
      memberId.push(element.id);
    });
    let bodyObj;
    try {
      bodyObj = {
        "walletId": this.walletId,
        "viewerIds": memberId
      }
      console.info('bodyObj',bodyObj);
      const response = await this.httpService.addMemberAsViewer(bodyObj).toPromise();
      console.log('response',response);
      if (response.success === true) {
        this.step = 'success';
        // let wallets = await this.httpService.getAllMultisigWallets().toPromise();
        // this.data.changeWallets(wallets)
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to add Member",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to add Member",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.error(e);
    }
  }

}
