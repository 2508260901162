import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Transaction, TxStatusOptions } from "../entities/Transaction";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class TxHistoryService {
  currentSearchString: string = '';
  constructor(private apiService: ApiService) { }


  getTxHistory(pageNumber: number, pageSize: number,  status:TxStatusOptions , from?:NgbDate, to?:NgbDate ,walletId?:string, searchTerm?:string): Observable<any> {
    const url = this.getTxHistoryUrl(pageNumber, pageSize, status, from, to ,walletId, searchTerm);
    return this.apiService.get(url)
      .pipe(map((response: any) => response?.data?.data));
  }

  getTxCount(walletId?: string, from?: Date, to?: Date, searchTerm?: string): Observable<any> {
    const url = this.getTxCountUrl(walletId, from, to, searchTerm);
    return this.apiService.get(url)
      .pipe(map((response: any) => response?.data));
  }

  private getTxCountUrl(walletId?: string, from?: Date, to?: Date, searchTerm?: string): string {
    let queryParams = '';

    if (walletId) {
      queryParams = `walletId=${walletId}`;
    }
    if (from && to) {
      queryParams += `&from=${from}&to=${to}`;
    }
    if (searchTerm) {
      queryParams += `&searchTerm=${searchTerm}`;
    }
    return `/wallet/all/txs/count?${queryParams}`;
  }


  private getTxHistoryUrl(pageNumber: number, pageSize: number, status: TxStatusOptions, from?: NgbDate, to?: NgbDate, walletId?: string, searchTerm?:string): string {
    let queryParams = `?page=${pageNumber}&pageSize=${pageSize}&status=${status}`;

    if (from && to) {
      queryParams += `&from=${from}&to=${to}`;
    }

    if (walletId) {
      queryParams += `&walletId=${walletId}`;
    }
    if (searchTerm) {
      queryParams += `&searchTerm=${searchTerm}`;
    }

    return `/v2/wallets/all/txs${queryParams}`;
  }
}
