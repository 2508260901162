<div
  class="d-inline-block"
  *ngIf="!state && (isOwner || userType===userTypeEnum.member)"
  ngbTooltip="{{
    !_wallets.length
      ? 'You currently don\'t have any deposit wallets to apply consolidation rules'
      : ''
  }}"
>
  <button
    *ngIf="display == 'button'"
    style="width: 96px;height: 38px;" class="btn btn-outline-primary btn-md btn-round"
    [ngClass]="{ disabled: !_wallets.length }"
    [disabled]="!_wallets.length"
    (click)="open(content3)"
  >
    <i class="icon-add-alt"></i>
    <span class="d-inline ml-1">Add</span>
  </button>
  <span *ngIf="display == 'inline'">
    , 
    <a
      (click)="open(content3)"
      [ngClass]="{
        disabled: !_wallets.length,
        'cursor-pointer': _wallets.length
      }"
      class="link-text hover-primary"
    >
      Create
    </a>
    rule now.
  </span>
</div>

<div class="d-inline-block" *ngIf="state && isOwner">
  <button class="btn px-4 py-2" (click)="update(content3)">
    <span class="d-inline">Update</span>
  </button>
</div>

<ng-template #content3 let-modal>
  <div *ngIf="step == 'step-1'">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-add-alt text-dark mr-2"></i>
        <ng-container *ngIf="!state">Add Smart Consolidation Rule</ng-container>
        <ng-container *ngIf="state">Update Smart Consolidation Rule</ng-container>
      </h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <p>
        You can setup consolidation rules for supported protocols to automate
        fund transfers from deposit wallet to required destination address and
        if the balance of destination address is greater than spillover amount
        select the next destination address.
      </p>
      <div class="row">
        <div class="col-6">
          <div
            class="mb-4"
            ngbDropdown
            #myDrop="ngbDropdown"
            *ngIf="!selectedWallet"
          >
            <label class="text-dark">Wallet</label>
            <input
              autocomplete="off"
              class="form-control search-custom"
              placeholder="Select wallet"
              id="dropdownManual"
              [(ngModel)]="searchWalletInput"
              ngbDropdownAnchor
              (focus)="myDrop.open()"
              type="text"
            />
            <ul
              ngbDropdownMenu
              aria-labelledby="dropdownManual"
              class="whitelistscroll w-100 py-0"
            >
              <div
                class="d-flex"
                *ngFor="
                  let walletdata of _wallets | wallet_search : searchWalletInput
                "
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)"
              >
                <button class="dropdown-content w-100 p-0 m-0 border-0">
                  <app-wallet-card
                    [option]="walletdata"
                    [AllWallets]="wallets"
                    [dropdown_card]="'dropdown-card'"
                    [card_padding]="'p-3'"
                    (click)="selectWallet(walletdata)"
                  ></app-wallet-card>
                </button>
              </div>
            </ul>
          </div>
          <div class="mb-4" *ngIf="selectedWallet">
            <label class="text-dark">Wallet</label>
            <span
              class="cursor-pointer text-muted text-underline float-right"
              *ngIf="selectedWallet && !rule"
              (click)="
                selectedWallet = !selectedWallet;
                selectedAsset ? (selectedAsset = !selectedAsset) : '';
                type.value = 'Auto';
                maxFees = null;
                selectedAddress ? (selectedAddress = !selectedAddress) : '';
                spillover = null;
                setAmount = null
              "
            >
              Clear
            </span>
            <app-wallet-card
              [option]="selectedWallet"
              [AllWallets]="wallets"
              [card_padding]="'p-3'"
            ></app-wallet-card>
          </div>
          <div
            class="mb-4"
            ngbDropdown
            #myDropAssets="ngbDropdown"
            *ngIf="!selectedAsset"
          >
            <label class="text-dark">Asset</label>
            <input
              autocomplete="off"
              class="form-control search-custom"
              placeholder="Select Asset"
              id="dropdownManual1"
              [(ngModel)]="searchAssetInput"
              ngbDropdownAnchor
              (focus)="myDropAssets.open()"
              type="text"
              [ngClass]="{ disabled: !selectedWallet }"
              [disabled]="!selectedWallet"
            />
            <ul
              ngbDropdownMenu
              aria-labelledby="dropdownManual1"
              class="whitelistscroll w-100 py-0"
            >
              <div
                class="d-flex"
                *ngFor="
                  let assetsdata of assets | wallet_search : searchAssetInput
                "
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)"
              >
                <button
                  class="dropdown-content w-100 p-0 m-0 border-0"
                  *ngIf="assetsdata.coin == 'All Assets'"
                >
                  <div
                    class="card card-border card-dropdown mb-0 rounded-0"
                    (click)="selectAssets(assetsdata)"
                  >
                    <div class="card-body p-3">
                      <div class="media border-after-xs align-items-center body-font">
                        <img
                          class="align-self-center mr-2 img-fluid img-round-border"
                          src="{{ assetsdata.imageurl }}"
                        />
                        <div class="details align-self-center">All Assets</div>
                        <div class="media-body text-right">
                          <h2 class="card-balance mb-0">
                            ${{ assetsdata.balanceUSD | number : "1.2-2" }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
                <button
                  class="dropdown-content w-100 p-0 m-0 border-0"
                  *ngIf="assetsdata.coin != 'All Assets'"
                >
                  <app-wallet-card
                    [option]="assetsdata"
                    [dropdown_card]="'dropdown-card'"
                    [card_padding]="'p-3'"
                    [assetsCard]="'assets_card'"
                    (click)="selectAssets(assetsdata)"
                  ></app-wallet-card>
                </button>
              </div>
            </ul>
          </div>
          <div class="mb-4" *ngIf="selectedAsset">
            <label class="text-dark">Asset</label>
            <span
              class="cursor-pointer text-muted text-underline float-right"
              *ngIf="selectedAsset && !rule"
              (click)="
                selectedAsset = !selectedAsset;
                selectedAddress ? (selectedAddress = !selectedAddress) : '';
                spillover = null
              "
              >Clear</span
            >
            <app-wallet-card
              *ngIf="selectedAsset.coin != 'All Assets'"
              [option]="selectedAsset"
              [assetsCard]="'assets_card'"
              [card_padding]="'p-3'"
            >
            </app-wallet-card>
            <div
              class="card card-border"
              *ngIf="selectedAsset.coin == 'All Assets'"
            >
              <div class="card-body p-3">
                <div class="media border-after-xs align-items-center">
                  <img
                    class="align-self-center mr-2 img-fluid img-round-border"
                    src="{{ selectedAsset.imageurl }}"
                  />
                  <div class="details align-self-center">All Assets</div>
                  <div class="media-body text-right">
                    <h2 class="card-balance mb-0">
                      ${{ selectedAsset.balanceUSD | number : "1.2-2" }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label for="amountControlSelect" class="text-dark"
              >Minimum Amount (per address)
              <i
                class="icon-info info-i"
                placement="right"
                ngbTooltip="Set the minimum amount required per address for consolidation"
                tooltipClass="tooltip-left"
              ></i>
            </label>
            <div class="input-group">
              <input
                autocomplete="off"
                class="form-control border-radius-left text-dark"
                placeholder="Enter Amount"
                type="number"
                min="1"
                (keypress)="omit_special_char($event)"
                [ngModel]="setAmount"
                (ngModelChange)="setAmount = $event"
                [ngClass]="{ disabled: !selectedWallet }"
                [disabled]="!selectedWallet"
              />
              <div class="input-group-append">
                <span class="input-group-text font-size-14 border-radius-right">
                  <select
                    style="background: none; border: 0px"
                    [(ngModel)]="currency"
                    [ngClass]="{ disabled: !selectedWallet }"
                    [disabled]="!selectedWallet"
                  >
                    <option value="0">USD</option>
                    <option
                      value="1"
                      *ngIf="
                        selectedAsset && selectedAsset.coin != 'All Assets'
                      "
                    >
                      {{ selectedAsset.coin }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <label for="amountControlSelect1" class="text-dark"
              >Consolidation Type</label
            >
            <select
              class="form-control text-dark"
              #type
              [(ngModel)]="consolidationType"
              (change)="onSelected(type.value)"
              id="amountControlSelect1"
              [ngClass]="{ disabled: !selectedWallet }"
              [disabled]="!selectedWallet"
            >
              <option selected value="Auto">Auto</option>
              <option value="Custom">Custom</option>
            </select>
          </div>

          <div class="mb-4" *ngIf="type.value?.toLowerCase() == 'custom'">
            <div>
              <label class="text-dark"
                >Max Fees
                <i
                  class="icon-info info-i"
                  placement="right"
                  ngbTooltip="Maximum fees to pay per transaction to protect against unpredictable network fees changes"
                  tooltipClass="tooltip-left"
                ></i>
              </label>
              <div class="input-group">
                <input
                  autocomplete="off"
                  min="0"
                  [ngModel]="maxFees"
                  (ngModelChange)="maxFees = $event"
                  [ngClass]="{
                    disabled: !(type.value?.toLowerCase() == 'custom')
                  }"
                  [disabled]="!(type.value?.toLowerCase() == 'custom')"
                  (keypress)="omit_special_char($event)"
                  class="form-control text-dark border-radius-left"
                  placeholder="Enter Max fees"
                  type="number"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text font-size-14 border-radius-right text-capitalize"
                    >{{ selectedWallet?.chain | getFeesUnit }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <label class="text-dark">Destination
            <i
              class="icon-info info-i"
              placement="right"
              ngbTooltip="Destination address to which the funds will be transferred"
              tooltipClass="tooltip-left"
            ></i>
          </label>
          <div class="destination-box">
            <div
              ngbDropdown
              #myDropAddress="ngbDropdown"
              *ngIf="!selectedAddress"
            >
              <label class="text-dark">Address</label>
              <input
                autocomplete="off"
                class="form-control search-custom"
                placeholder="Search Whitelisted Address"
                ngbDropdownAnchor
                (focus)="myDropAddress.open()"
                [(ngModel)]="searchAssetAddress"
                type="text"
                [ngClass]="{ disabled: !selectedAsset }"
                [disabled]="!selectedAsset"
              />
              <ul
                ngbDropdownMenu
                class="whitelistscroll w-100 py-0"
                *ngIf="selectedAsset"
              >
                <div
                  class="d-flex"
                  *ngFor="
                    let policy of selectedAsset.policy
                      | walletAddressSearch : searchAssetAddress
                  "
                >
                  <button
                    *ngIf="
                      policy.subtype.toLowerCase() === 'address' &&
                      nextAddress != policy
                    "
                    class="dropdown-content card w-100 p-0 brd_Box"
                    (click)="selectAddress(policy)"
                  >
                    <li ngbDropdownItem class="d-flex p-3">
                      <i
                        class="icon-address text-secondary"
                        style="
                          border-radius: 50%;
                          padding: 12px;
                          border: 2px solid #f7c325;
                          display: flex;
                          justify-content: center;
                          font-size: 22px !important;
                          align-items: center;
                          width: 50px;
                          height: 50px;
                        "
                      ></i>
                      <div
                        class="details d-flex flex-column align-items-center"
                      >
                        <div class="col text-left p-0">
                          <p class="m-0 truncate_Name">{{ policy.name }}</p>
                        </div>
                        <div class="col text-left p-0 t_Id">
                          <span>{{ policy.condition.substring(0, 8) }}...</span>
                          <span>{{
                            policy.condition.substring(
                              policy.condition.length - 4
                            )
                          }}</span>
                        </div>
                      </div>
                    </li>
                  </button>
                </div>
              </ul>
            </div>
            <div *ngIf="selectedAddress">
              <label class="text-dark">Address</label>
              <span
                class="cursor-pointer text-muted text-underline float-right"
                *ngIf="selectedAddress"
                (click)="selectedAddress = !selectedAddress; spillover = null"
                >Clear</span
              >
              <app-address-card
                [option2]="selectedAddress"
                [card_padding]="'p-3'"
              >
              </app-address-card>
            </div>

            <label class="text-dark" [ngClass]="{ 'mt-3': !selectedAddress }"
              >Spillover if balance greater than (Optional)</label
            >
            <div class="input-group">
              <input
                autocomplete="off"
                class="form-control border-radius-left text-dark"
                placeholder="Enter Amount"
                type="number"
                min="1"
                (keypress)="omit_special_char($event)"
                [(ngModel)]="spillover"
                value="{{ spillover }}"
                [ngClass]="{ disabled: !selectedAddress }"
                [disabled]="!selectedAddress"
              />
              <div class="input-group-append">
                <span class="input-group-text font-size-14 border-radius-right">
                  <span *ngIf="currency == 0">USD</span>
                  <span *ngIf="currency == 1">{{ selectedAsset.coin }}</span>
                </span>
              </div>
            </div>
          </div>

          <label class="mt-4" *ngIf="spillover"
            >Next Destination
            <i
              class="icon-info info-i"
              placement="right"
              ngbTooltip="Next destination address if balance of destination address is greater than spillover amount"
              tooltipClass="tooltip-left"
            ></i
          ></label>
          <div
            *ngIf="spillover"
            class="destination-box"
          >
            <div ngbDropdown #nextAddress="ngbDropdown" *ngIf="!nextAddress">
              <label class="text-dark">Address</label>
              <input
                autocomplete="off"
                class="form-control search-custom"
                placeholder="Search Whitelisted Address"
                ngbDropdownAnchor
                (focus)="nextAddress.open()"
                [(ngModel)]="searchAssetAddress"
                type="text"
                [ngClass]="{ disabled: !selectedAsset }"
                [disabled]="!selectedAsset"
              />
              <ul
                ngbDropdownMenu
                class="whitelistscroll w-100 py-0"
                *ngIf="selectedAsset"
              >
                <div
                  class="d-flex"
                  *ngFor="
                    let policy of selectedAsset.policy
                      | walletAddressSearch : searchAssetAddress
                  "
                >
                  <button
                    *ngIf="
                      policy.subtype.toLowerCase() === 'address' &&
                      selectedAddress != policy
                    "
                    class="dropdown-content card w-100 p-0 brd_Box"
                    (click)="selectNextAddress(policy)"
                  >
                    <li ngbDropdownItem class="d-flex p-3">
                      <i
                        class="icon-address text-secondary"
                        style="
                          border-radius: 50%;
                          padding: 12px;
                          border: 2px solid #f7c325;
                          display: flex;
                          justify-content: center;
                          font-size: 22px !important;
                          align-items: center;
                          width: 50px;
                          height: 50px;
                        "
                      ></i>
                      <div
                        class="details d-flex flex-column align-items-center"
                      >
                        <div class="col text-left p-0">
                          <p class="m-0 truncate_Name">{{ policy.name }}</p>
                        </div>
                        <div class="col text-left p-0 t_Id">
                          <span>{{ policy.condition.substring(0, 8) }}...</span>
                          <span>{{
                            policy.condition.substring(
                              policy.condition.length - 4
                            )
                          }}</span>
                        </div>
                      </div>
                    </li>
                  </button>
                </div>
              </ul>
            </div>
            <div *ngIf="nextAddress">
              <label class="text-dark">Address</label>
              <span
                class="cursor-pointer text-muted text-underline float-right"
                *ngIf="nextAddress"
                (click)="nextAddress = !nextAddress"
                >Clear</span
              >
              <app-address-card [option2]="nextAddress" [card_padding]="'p-3'">
              </app-address-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!_update">
      <div class="modal-footer border-0" *ngIf="type.value == 'Custom'">
        <button
          type="submit"
          class="btn btn-outline-primary w-100"
          [ngClass]="{
            disabled:
              !selectedWallet ||
              !selectedAsset ||
              !setAmount ||
              !maxFees ||
              !selectedAddress ||
              (spillover && !nextAddress)
          }"
          [disabled]="
            !selectedWallet ||
            !selectedAsset ||
            !setAmount ||
            !maxFees ||
            !selectedAddress ||
            (spillover && !nextAddress)
          "
          (click)="
            addConsolidationRule(
              selectedWallet.id,
              selectedAsset.coin,
              setAmount,
              type.value,
              maxFees,
              selectedAddress.condition
            )
          "
        >
          <i *ngIf="!isSigning" class="icon-add-alt mr-1"></i>
          <div *ngIf="isSigning" class="loader-box">
            <div class="loader-15"></div>
          </div>
          <span *ngIf="!isSigning && !rule">Add Rule</span>
          <span *ngIf="!isSigning && rule">Update Rule</span>
        </button>
      </div>
      <div class="modal-footer border-0" *ngIf="type.value == 'Auto'">
        <button
          type="submit"
          *ngIf="type.value == 'Auto'"
          class="btn btn-outline-primary w-100"
          [ngClass]="{
            disabled:
              !selectedWallet ||
              !selectedAsset ||
              !setAmount ||
              !selectedAddress ||
              (spillover && !nextAddress)
          }"
          [disabled]="
            !selectedWallet ||
            !selectedAsset ||
            !setAmount ||
            !selectedAddress ||
            (spillover && !nextAddress)
          "
          (click)="
            addConsolidationRule(
              selectedWallet.id,
              selectedAsset.coin,
              setAmount,
              type.value,
              maxFees,
              selectedAddress.condition
            )
          "
        >
          <i *ngIf="!isSigning" class="icon-add-alt mr-1"></i>
          <div *ngIf="isSigning" class="loader-box">
            <div class="loader-15"></div>
          </div>
          <span *ngIf="!isSigning && !rule">Add Rule</span>
          <span *ngIf="!isSigning && rule">Update Rule</span>
        </button>
      </div>
    </div>
    <div *ngIf="_update">
      <div class="modal-footer border-0" *ngIf="type.value == 'Custom'">
        <button
          type="submit"
          class="btn btn-outline-primary w-100"
          [ngClass]="{
            disabled:
              !spillover ||
              !nextAddress ||
              !selectedWallet ||
              !selectedAsset ||
              !setAmount ||
              !maxFees ||
              !selectedAddress
          }"
          [disabled]="
            !spillover ||
            !nextAddress ||
            !selectedWallet ||
            !selectedAsset ||
            !setAmount ||
            !maxFees ||
            !selectedAddress
          "
          (click)="
            addConsolidationRule(
              selectedWallet.id,
              selectedAsset.coin,
              setAmount,
              type.value,
              maxFees,
              selectedAddress.condition
            )
          "
        >
          <i *ngIf="!isSigning" class="icon-add-alt mr-1"></i>
          <div *ngIf="isSigning" class="loader-box">
            <div class="loader-15"></div>
          </div>
          <span *ngIf="!isSigning && !rule">Add Rule</span>
          <span *ngIf="!isSigning && rule">Update Rule</span>
        </button>
      </div>
      <div class="modal-footer border-0" *ngIf="type.value == 'Auto'">
        <button
          type="submit"
          *ngIf="type.value == 'Auto'"
          class="btn btn-outline-primary w-100"
          [ngClass]="{
            disabled:
              !spillover ||
              !nextAddress ||
              !selectedWallet ||
              !selectedAsset ||
              !setAmount ||
              !selectedAddress
          }"
          [disabled]="
            !spillover ||
            !nextAddress ||
            !selectedWallet ||
            !selectedAsset ||
            !setAmount ||
            !selectedAddress
          "
          (click)="
            addConsolidationRule(
              selectedWallet.id,
              selectedAsset.coin,
              setAmount,
              type.value,
              maxFees,
              selectedAddress.condition
            )
          "
        >
          <i *ngIf="!isSigning" class="icon-add-alt mr-1"></i>
          <div *ngIf="isSigning" class="loader-box">
            <div class="loader-15"></div>
          </div>
          <span *ngIf="!isSigning && !rule">Add Rule</span>
          <span *ngIf="!isSigning && rule">Update Rule</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successModal let-modal>
  <div>
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-add mr-2"></i> Add Smart Consolidation Rule
      </h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <i class="icon-close"></i>
      </button>
    </div>
    <div
      class="modal-body d-flex align-items-center"
    >
      <div class="row">
        <div class="col-12">
          <div class="mb-3 position-relative">
            <div class="background_success text-center">
              <img
                class="w-50"
                src="../../../../assets/images/check-outline-success.gif"
              />
            </div>
          </div>
          <div class="mb-5 text-center">
            <div class="h5">Success</div>
            <div class="text-muted">
              Awesome! You have successfully added <br />
              the consolidation rule. 🎉
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button
        type="submit"
        class="btn btn-outline-primary w-100"
        (click)="modal.dismiss('Cross click')"
      >
        Done
      </button>
    </div>
  </div>
</ng-template>
