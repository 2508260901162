<div>
    <div class="mb-4">
        <div class="row">
            <div class="col-6 align-items">
                <div>
                    <h6 class="d-flex align-items-center text-dark m-0">
                        <!-- <i class="icon-wallet mr-2"></i> -->
                        NFT
                    </h6>
                </div>
            </div>
            <div class="col-6">
                <ul class="d-flex nav justify-content-end">
                    <li class="nav-item pl-2">
                        <app-receive-assets [receiveassets_component_for]="'receive_wallet'"></app-receive-assets>
                    </li>
                    <li class="nav-item pl-2">
                        <app-send-assets [sendassets_component_for]="'normal_send'"></app-send-assets>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="!nftWallet.length" class="card d-flex align-items-center p-4 card-shadow-border">
        <img src="../../../assets/images/placeholder/policy.png" width="125" class="mb-4">
        <h4>No NFT yet 😔</h4>
        <p class="mb-0">You do not have any NFTs in your wallet.</p>
    </div>
</div>