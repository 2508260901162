import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { WalletserviceService } from '../../services/walletservice.service';
import { toPretty } from '../../helpers/trezor/numberHelper'
import BN from "bignumber.js";
import { Wallet} from '../../entities/wallet';
import { LoggerService } from '../../services/logger.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Validators } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TransactionRequest, txType } from '../../entities/TransactionRequest';
import { ComposeTransaction } from '../../helpers/trezor/transactionComposer';
import Swal from 'sweetalert2';
import { Policy } from 'src/app/shared/entities/Policy';
import { SegmentService } from 'ngx-segment-analytics';
import { AuthServiceJWT } from '../../services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {UAParser} from 'ua-parser-js';
import { environment } from 'src/environments/environment';
import { stakingSign } from '../../helpers/trezor/stakingSign';
import { ErrorPrompt } from '../custom-prompt/custom-prompt.service';
import { ApiService } from '../../services/api.service';

interface addressAmountPair {
  address: string;
  amount: number;
  policy: Policy
}
@Component({
  selector: 'app-staking-approval-popup',
  templateUrl: './staking-approval-popup.component.html',
  styleUrls: ['./staking-approval-popup.component.scss'],
  inputs: ['coin'],
})
export class StakingApprovalPopupComponent implements OnInit {

  closeResult = '';
  wallets: Wallet[];
  _wallet: any;
  stakingData: any = [];
  searchWalletInput: string;
  searchAssetInput: string;
  searchValidatorInput: string;
  filteredOptions: Observable<Wallet[]> = EMPTY;
  SendControl = new FormControl();
  ReceiveControl = new FormControl();
  walletList: Wallet[] = [];
  txRequest: TransactionRequest
  composeTransaction: ComposeTransaction;
  invalidAmmount = false;
  isSigning = false;
  allowedDecimals = false;
  ledgerDeviceClickSign = false;
  ledgerDeviceSignText: string;
  recive_string: string;
  usdAmount: number = null;
  selectedWallet:Wallet;
  selectedAsset:Wallet;
  selectedValidator:any;
  _wallets:any;
  status:string = 'step-0';
  stakeAmount:number;
  comment:string = '';
  assets:any;
  validatorDetail:any;
  stakingWaletDetail:any;
  selectedAddressAmount: addressAmountPair = { policy: null, address: null, amount: null }
  stakingSign: stakingSign;
  public walletAutocompleteControl = new FormControl('', Validators.required);
  @Input() component_for: string;
  @Input() stakingApproveData:any;
  @Input() stakeWalletData:any;
  @Input() enableStakeWalletData:any;
  @Input() actionType: string = "text"
  browser: string;
  device: string;
  
  private onDestroy$: Subject<void> = new Subject<void>();
  validatorList: any[];
  selectedWalletValidator: any = "";
  
  constructor(private data: DataService,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private logger: LoggerService,
    private modalService: NgbModal,
    public authService: AuthServiceJWT,
    private segment: SegmentService,
    private apiService: ApiService
  ) {

      this.browser = new UAParser().getBrowser().name;

  this.ledgerDeviceSignText = this.deviceLedgerSignText();
    
  this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe(wallets => {
      if (wallets) {
        this.wallets = wallets;
      }
    });

  }//end constructor

  ngOnInit(): void {
    this.stakingData = this.stakingApproveData;
    if(this.stakingData?.walletid)
      this.getStakeValidator(this.stakingData.walletid);
  }

  async getStakeValidator(walletId)
  {
    let stakeAssets = await this.httpService.getStakeWalletInfo(walletId).toPromise();
    console.log('stakeAssets',stakeAssets);
    
    // let data = stakeAssets.data.walletData.filter(e => e.assets == this.stakingData.asset && e.chain == this.stakingData.chain)[0].stakingWalletList;
    // let data = stakeAssets.data.walletData.filter(e => e.assets == this.stakingData.asset)[0].stakingWalletList;
    // let walletDetail = data.filter(e => e.wallet.id == this.stakingData.walletid)[0];
    this.validatorDetail = stakeAssets.data.walletData[0].stakingWalletList[0].stakingEnabledValidatorDetails[0];
    this.stakingWaletDetail = stakeAssets.data.walletData[0].stakingWalletList[0];
    console.info('this.validatorDetail',this.validatorDetail);
  }

  async enableStakeWalletFilter()
  {
    console.log(this.enableStakeWalletData);
    console.log(this.wallets);
    this.enableStakeWalletData.assets = this.enableStakeWalletData.assets.map(element => {
      return element.toLowerCase();
    });
    this._wallets = await this.wallets.filter(ele => ele.chain.toLowerCase() == this.enableStakeWalletData.chain.toLowerCase() && this.enableStakeWalletData.assets.includes(ele.coin.toLowerCase()));
    this._wallets = [...new Map(this._wallets.map(item => [item.id, item])).values()];
  }

  async stakeAdd()
  {
    await this.getStakingValidatorsList(this.stakeWalletData?.id);
    // console.log('list',this.stakeWalletData);
    this.selectedWallet = this.stakeWalletData;
    this.selectedAsset = this.stakeWalletData;
    this.selectedWalletValidator = this.validatorList?.filter(validator => validator.id == this.stakeWalletData.validatorId)[0];
    // this.device = this.selectedAsset.walletKeys.filter(item => item.ismine === true)[0].provider;
  }

  
  pretty(number, decimal) {
    return toPretty(number, decimal)
  }

  groupBy<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    key: K | { (obj: T): string }
  ): Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
      const value = keyFn(obj);
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);
  }

  sumofwallets() {
    let total = new BN(0);
    if (this.wallets && this.wallets.length > 0)
      for (let i = 0; i < this.wallets.length; i++) {
        total = total.plus(new BN(this.wallets[i].balanceUSD));
      }

    return total

  }

  open(content) {
    
    if(this.component_for == 'staking-enable')
    {
      this.enableStakeWalletFilter();
      this.status = 'step-0';
    }else
    if(this.component_for == 'staking-add')
    {
      this.status = 'step-1';
    }else
    if(this.stakingData?.txType == 'approvestake' || this.stakingData?._metaData.tx_sub_category_type.toLowerCase() == 'enable')
    {
      this.status = 'step-2';
    }else
    {
      this.status = 'step-3';
    }
    
    
    this.isSigning = false;
    
    if(this.status == 'step-3' || this.status == 'step-2')
    {
      this.device = this.stakingData.walletinfor.walletKeys.filter(item => item.ismine === true)[0].provider;
    }

    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.resetSendModel()
    });
  }

  resetSendModel() {
    
    this.stakeAmount = null;
    this.comment = '';
    this.status = "step-0";
    this.selectedAddressAmount = { policy: null, address: null, amount: null }
    this.txRequest = null;
    this.recive_string = '';
    this.selectedWallet = null;
    this.selectedAsset = null;
    this.validatorDetail = null;
    this.selectedValidator = null;
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.searchValidatorInput = "";
  }

  public ngOnDestroy(): void {
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.searchValidatorInput = "";
    this.onDestroy$.next();
  }
  
  //On Press of Confirm button before signing
  async onSubmit(amount?) {
      this.selectedAddressAmount.address = this.stakingData.destinationAddress;
        this.logger.info(this.stakingData.walletinfor.policy, this.selectedAddressAmount.address);
        this.logger.info(this.stakingData.walletinfor.policy.filter(p => p.condition == this.selectedAddressAmount.address));
        this.selectedAsset = this.walletService.getWallet(this.stakingData.walletid + "_" + this.stakingData.asset?.toLowerCase());
        // this.device = this.selectedAsset.walletKeys.filter(item => item.ismine === true)[0].provider;
        this.selectedAddressAmount.policy = this.stakingData.walletinfor.policy.filter(p => p.condition == this.selectedAddressAmount.address)[0]
    if (amount)
      this.selectedAddressAmount.amount = amount

    if (this.selectedAddressAmount.address && this.selectedAddressAmount.amount && this.selectedAddressAmount.amount >= 0) {
      
      this.segment.track("send-asset-amount-and-address-confirmed", this.authService.getUser)
        .then(() => this.logger.info("Send asset amount and address confirmed")).catch((err)=>{});
     
      let signcheck = this.stakingData.signs.some(e => e.approved === true);
      if(this.stakingData.type == 3 && this.stakingData.walletinfor.parentchain.toLowerCase() == 'evm' && signcheck === false)
      {
        this.stakingSign = new stakingSign(this.stakingData.walletinfor,this.data,this.httpService,this.logger,this.authService, this.walletService, this.segment, this.apiService);
      } else {
        this.composeTransaction = new ComposeTransaction(this.selectedAsset, this.data, this.httpService, this.logger,this.segment,this.authService, this.walletService)
      }
      
    } else {
      this.resetSendModel()
      this.selectedAddressAmount = { policy: null, address: null, amount: null }
     
      this.segment.track("send-asset-amount-and-address-confirm-failed", this.authService.getUser)
        .then(() => this.logger.info("Send asset amount and address confirmation failed")).catch((err)=>{});

      return;
    }
  }

  deviceLedgerSignText() {
    return "Open Ether app in your Ledger device and click Sign"
  }

  // After on submit we press Sign button 
  async onSign(type?: string, amount?) {

    console.log('onsign');
    if (type == "mint" || type == "minterc20") {

      this.onSubmit(amount);

    }
    else {
      type = 'normal';
    }
    this.isSigning = true;
    
    this.segment.track("send-asset-transaction-sign-attempted", this.authService.getUser)
      .then(() => this.logger.info("Send asset transaction sign attempted")).catch((err)=>{});
   
    let signResponse;
    let signcheck = this.stakingData.signs.some(e => e.approved === true);
    if(this.stakingData.type == 3 && this.stakingData.walletinfor.parentchain.toLowerCase() == 'evm' && signcheck === false)
    {
      signResponse = await this.stakingSign.stakingTransaction(this.stakingData.amount,this.stakingData.comment,this.stakingData.walletinfor,this.validatorDetail,'staking',this.stakingData)
    } else {
      signResponse = await this.composeTransaction.sendTransaction({ address: this.selectedAddressAmount.address, value: this.selectedAddressAmount.amount }, []
        , this.stakingData, type)
    }
  
    this.isSigning = false;
    for (let i = 0; i < signResponse.length; i++) {
      if (signResponse[i].error) {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Failed to sign transaction!',
          // text: signResponse[i].error.charAt(0).toUpperCase() + signResponse[i].error.slice(1),
          text: this.walletService.setCustomErrorMsg(signResponse[i].error),
          showConfirmButton: false,
        })
        
        this.segment.track("send-asset-transaction-sign-failed", {user: this.authService.getUser, error: signResponse[i]})
          .then(() => this.logger.info("Send asset transaction sign failed")).catch((err)=>{});
        
        this.resetSendModel()
        return;
      }
    }
    this.modalService.dismissAll();
    Swal.fire({
      title: 'Success!',
      text: 'Transaction has been signed!',
      icon: 'success',
      confirmButtonText: 'Cool'
    });
    this.status = "success";
    this.segment.track("send-asset-transaction-sign-success", this.authService.getUser)
      .then(() => this.logger.info("Send asset transaction sign success")).catch((err)=>{});
    this.refreshPendingTx();
  }//end




  async refreshPendingTx() {
    let txRequests = await this.httpService.getAllPendingWalletTransactions().toPromise();
    this.data.changePendingTxRequest(txRequests);

    // let wallets = await this.httpService.getAllMultisigWallets().toPromise();
    // this.data.changeWallets(wallets);
  }

  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }//end

  resetAmmountValidationErrormessages() {
    this.invalidAmmount = false;
    this.allowedDecimals = false;
  }//end

  async transactionAmmountValidations(val: number) {
    console.log(this.stakeWalletData.assetBalance);
    
    if (val != undefined) {
      if (Number(val) > Number(this.stakeWalletData.assetBalance)) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else if (Number(val) < 0.00001) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      }else {
        if (this.decimalPlaces(val) > 5) {
          this.allowedDecimals = true;
          this.invalidAmmount = false;
        } else {
          this.allowedDecimals = false;
          this.invalidAmmount = false;
        }
      }
    } else {
      this.invalidAmmount = false;
      this.allowedDecimals = false;
    }
  }//end

  selectWallet(wallet)
  {
    this.enableStakeWalletData.assets = this.enableStakeWalletData.assets.map(element => {
      return element.toLowerCase();
    });
    console.log(this.enableStakeWalletData.assets);
    
    this.assets = this.wallets.filter(obj => obj.id == wallet.id && this.enableStakeWalletData.assets.includes(obj.coin.toLowerCase()));
    console.log('data',this.enableStakeWalletData);
    this.selectedWallet = wallet;
    console.log('assets',this.assets);
    console.log('wallet',wallet);
    this.getStakingValidatorsList(wallet?.id);
  }

  selectAssets(assets)
  {
    this.selectedAsset = assets;
    // this.device = this.selectedAsset.walletKeys.filter(item => item.ismine === true)[0].provider;
  }

  selectAddress(policy: Policy)
  {
    this.selectedValidator = policy;
  }

  async senderAssetsConfirm()
  {
    this.selectedAddressAmount.address = this.selectedValidator.condition;
    this.selectedAddressAmount.policy = this.selectedValidator;
    let res = await this.httpService.getRate(this.selectedAsset.coin.toUpperCase()).toPromise();
    this.usdAmount = parseFloat(JSON.parse(res)['usd']);
  }


  
  async addStaking(id,ass_address,amount,comment, validator)
  {
    
    this.isSigning = true;
    try {
      const response = await this.httpService.addStaking(id, amount, ass_address,comment, validator).toPromise();

      console.log(response);
      if (response.success === true) {
        this.status = "success";
        this.isSigning = false;
      } else {
        this.modalService.dismissAll();
        this.isSigning = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to add staking",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to add staking",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.log(e);
    }
    this.refreshPendingTx();
  }
  async enableStaking(id,address,validator,comment)
  {
    
    this.isSigning = true;
    try {
      const response = await this.httpService.enableStaking(id, address, validator, comment).toPromise();
      console.log(response);
      if (response.success === true) {
        this.status = "success";
        this.isSigning = false;
      } else {
        this.modalService.dismissAll();
        this.isSigning = false;
        ErrorPrompt.fire({
          icon: "error",
          title: "Failed to Enable staking",
          text: response.message,
          showConfirmButton: false,
        })
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Failed to Enable staking",
        text: e.error.message,
        showConfirmButton: false,
      })
      console.log(e);
    }
    this.refreshPendingTx();
  }
  
  async getStakingValidatorsList(walletId:number) {
    const data:any =  await this.httpService.getStakingValidators(walletId).toPromise();
    this.validatorList = data?.data || [];
  }

  async selectValidator(validator) {
    this.validatorDetail = validator;
    this.searchValidatorInput = "";
  }
}