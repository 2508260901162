"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateJsonRpcError = exports.getErrorByCode = exports.getError = exports.isValidErrorCode = exports.isReservedErrorCode = exports.isServerErrorCode = void 0;
const constants_1 = require("./constants");
function isServerErrorCode(code) {
  return code <= constants_1.SERVER_ERROR_CODE_RANGE[0] && code >= constants_1.SERVER_ERROR_CODE_RANGE[1];
}
exports.isServerErrorCode = isServerErrorCode;
function isReservedErrorCode(code) {
  return constants_1.RESERVED_ERROR_CODES.includes(code);
}
exports.isReservedErrorCode = isReservedErrorCode;
function isValidErrorCode(code) {
  return typeof code === "number";
}
exports.isValidErrorCode = isValidErrorCode;
function getError(type) {
  if (!Object.keys(constants_1.STANDARD_ERROR_MAP).includes(type)) {
    return constants_1.STANDARD_ERROR_MAP[constants_1.INTERNAL_ERROR];
  }
  return constants_1.STANDARD_ERROR_MAP[type];
}
exports.getError = getError;
function getErrorByCode(code) {
  const match = Object.values(constants_1.STANDARD_ERROR_MAP).find(e => e.code === code);
  if (!match) {
    return constants_1.STANDARD_ERROR_MAP[constants_1.INTERNAL_ERROR];
  }
  return match;
}
exports.getErrorByCode = getErrorByCode;
function validateJsonRpcError(response) {
  if (typeof response.error.code === "undefined") {
    return {
      valid: false,
      error: "Missing code for JSON-RPC error"
    };
  }
  if (typeof response.error.message === "undefined") {
    return {
      valid: false,
      error: "Missing message for JSON-RPC error"
    };
  }
  if (!isValidErrorCode(response.error.code)) {
    return {
      valid: false,
      error: `Invalid error code type for JSON-RPC: ${response.error.code}`
    };
  }
  if (isReservedErrorCode(response.error.code)) {
    const error = getErrorByCode(response.error.code);
    if (error.message !== constants_1.STANDARD_ERROR_MAP[constants_1.INTERNAL_ERROR].message && response.error.message === error.message) {
      return {
        valid: false,
        error: `Invalid error code message for JSON-RPC: ${response.error.code}`
      };
    }
  }
  return {
    valid: true
  };
}
exports.validateJsonRpcError = validateJsonRpcError;
