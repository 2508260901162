<form class="form-inline">
    <div class="form-group date-range-custom">
        <div class="input-group" id="dateRangePicker">
            <i class="icon-calendar text-primary"></i>
            <input class="btn w-100 text-left btn-range" id="dateRange" [placeholder]="placeholder" name="dp" [value]="formattedDateRange" ngbDatepicker #dp="ngbDatepicker" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" [autoClose]="false"
                [(ngModel)]="model" [footerTemplate]="footerTemplate" (click)="dp.toggle(); isOpen = !isOpen" [maxDate]="today" readonly />
        </div>
    </div>
</form>
<ng-template #t let-date="date" let-focused="focused">
    <span class="custom-day" style="color:#a2a8ad;" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = toDate(date)" (mouseleave)="hoveredDate = null">
        {{ date.day }}
    </span>
</ng-template>
<ng-template #footerTemplate>
    <hr class="my-0">
    <button class="btn btn-outline-primary btn-sm m-2 float-left" (click)="clear()">Clear</button>
    <button class="btn btn-outline-secondary btn-sm m-2 float-right" (click)="dp.close()">Close</button>
</ng-template>