"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UInt16 = void 0;
const uint_1 = require("./uint");
const utils_1 = require("../utils");
/**
 * Derived UInt class for serializing/deserializing 16 bit UInt
 */
class UInt16 extends uint_1.UInt {
  constructor(bytes) {
    super(bytes !== null && bytes !== void 0 ? bytes : UInt16.defaultUInt16.bytes);
  }
  static fromParser(parser) {
    return new UInt16(parser.read(UInt16.width));
  }
  /**
   * Construct a UInt16 object from a number
   *
   * @param val UInt16 object or number
   */
  static from(val) {
    if (val instanceof UInt16) {
      return val;
    }
    if (typeof val === 'number') {
      UInt16.checkUintRange(val, 0, 0xffff);
      const buf = new Uint8Array(UInt16.width);
      (0, utils_1.writeUInt16BE)(buf, val, 0);
      return new UInt16(buf);
    }
    throw new Error('Can not construct UInt16 with given value');
  }
  /**
   * get the value of a UInt16 object
   *
   * @returns the number represented by this.bytes
   */
  valueOf() {
    return parseInt((0, utils_1.readUInt16BE)(this.bytes, 0));
  }
}
exports.UInt16 = UInt16;
UInt16.width = 16 / 8; // 2
UInt16.defaultUInt16 = new UInt16(new Uint8Array(UInt16.width));
