"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryClientImpl = exports.QueryConnectionParamsResponse = exports.QueryConnectionParamsRequest = exports.QueryConnectionConsensusStateResponse = exports.QueryConnectionConsensusStateRequest = exports.QueryConnectionClientStateResponse = exports.QueryConnectionClientStateRequest = exports.QueryClientConnectionsResponse = exports.QueryClientConnectionsRequest = exports.QueryConnectionsResponse = exports.QueryConnectionsRequest = exports.QueryConnectionResponse = exports.QueryConnectionRequest = exports.protobufPackage = void 0;
/* eslint-disable */
const pagination_1 = require("../../../../cosmos/base/query/v1beta1/pagination");
const connection_1 = require("./connection");
const client_1 = require("../../client/v1/client");
const any_1 = require("../../../../google/protobuf/any");
const binary_1 = require("../../../../binary");
const helpers_1 = require("../../../../helpers");
exports.protobufPackage = "ibc.core.connection.v1";
function createBaseQueryConnectionRequest() {
  return {
    connectionId: ""
  };
}
exports.QueryConnectionRequest = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.connectionId !== "") {
      writer.uint32(10).string(message.connectionId);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.connectionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionRequest();
    if ((0, helpers_1.isSet)(object.connectionId)) obj.connectionId = String(object.connectionId);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.connectionId !== undefined && (obj.connectionId = message.connectionId);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionRequest();
    message.connectionId = object.connectionId ?? "";
    return message;
  }
};
function createBaseQueryConnectionResponse() {
  return {
    connection: undefined,
    proof: new Uint8Array(),
    proofHeight: client_1.Height.fromPartial({})
  };
}
exports.QueryConnectionResponse = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.connection !== undefined) {
      connection_1.ConnectionEnd.encode(message.connection, writer.uint32(10).fork()).ldelim();
    }
    if (message.proof.length !== 0) {
      writer.uint32(18).bytes(message.proof);
    }
    if (message.proofHeight !== undefined) {
      client_1.Height.encode(message.proofHeight, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.connection = connection_1.ConnectionEnd.decode(reader, reader.uint32());
          break;
        case 2:
          message.proof = reader.bytes();
          break;
        case 3:
          message.proofHeight = client_1.Height.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionResponse();
    if ((0, helpers_1.isSet)(object.connection)) obj.connection = connection_1.ConnectionEnd.fromJSON(object.connection);
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = (0, helpers_1.bytesFromBase64)(object.proof);
    if ((0, helpers_1.isSet)(object.proofHeight)) obj.proofHeight = client_1.Height.fromJSON(object.proofHeight);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.connection !== undefined && (obj.connection = message.connection ? connection_1.ConnectionEnd.toJSON(message.connection) : undefined);
    message.proof !== undefined && (obj.proof = (0, helpers_1.base64FromBytes)(message.proof !== undefined ? message.proof : new Uint8Array()));
    message.proofHeight !== undefined && (obj.proofHeight = message.proofHeight ? client_1.Height.toJSON(message.proofHeight) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionResponse();
    if (object.connection !== undefined && object.connection !== null) {
      message.connection = connection_1.ConnectionEnd.fromPartial(object.connection);
    }
    message.proof = object.proof ?? new Uint8Array();
    if (object.proofHeight !== undefined && object.proofHeight !== null) {
      message.proofHeight = client_1.Height.fromPartial(object.proofHeight);
    }
    return message;
  }
};
function createBaseQueryConnectionsRequest() {
  return {
    pagination: undefined
  };
}
exports.QueryConnectionsRequest = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionsRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.pagination !== undefined) {
      pagination_1.PageRequest.encode(message.pagination, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.pagination = pagination_1.PageRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionsRequest();
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageRequest.fromJSON(object.pagination);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageRequest.toJSON(message.pagination) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionsRequest();
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageRequest.fromPartial(object.pagination);
    }
    return message;
  }
};
function createBaseQueryConnectionsResponse() {
  return {
    connections: [],
    pagination: undefined,
    height: client_1.Height.fromPartial({})
  };
}
exports.QueryConnectionsResponse = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionsResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.connections) {
      connection_1.IdentifiedConnection.encode(v, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      pagination_1.PageResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    if (message.height !== undefined) {
      client_1.Height.encode(message.height, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.connections.push(connection_1.IdentifiedConnection.decode(reader, reader.uint32()));
          break;
        case 2:
          message.pagination = pagination_1.PageResponse.decode(reader, reader.uint32());
          break;
        case 3:
          message.height = client_1.Height.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionsResponse();
    if (Array.isArray(object?.connections)) obj.connections = object.connections.map(e => connection_1.IdentifiedConnection.fromJSON(e));
    if ((0, helpers_1.isSet)(object.pagination)) obj.pagination = pagination_1.PageResponse.fromJSON(object.pagination);
    if ((0, helpers_1.isSet)(object.height)) obj.height = client_1.Height.fromJSON(object.height);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.connections) {
      obj.connections = message.connections.map(e => e ? connection_1.IdentifiedConnection.toJSON(e) : undefined);
    } else {
      obj.connections = [];
    }
    message.pagination !== undefined && (obj.pagination = message.pagination ? pagination_1.PageResponse.toJSON(message.pagination) : undefined);
    message.height !== undefined && (obj.height = message.height ? client_1.Height.toJSON(message.height) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionsResponse();
    message.connections = object.connections?.map(e => connection_1.IdentifiedConnection.fromPartial(e)) || [];
    if (object.pagination !== undefined && object.pagination !== null) {
      message.pagination = pagination_1.PageResponse.fromPartial(object.pagination);
    }
    if (object.height !== undefined && object.height !== null) {
      message.height = client_1.Height.fromPartial(object.height);
    }
    return message;
  }
};
function createBaseQueryClientConnectionsRequest() {
  return {
    clientId: ""
  };
}
exports.QueryClientConnectionsRequest = {
  typeUrl: "/ibc.core.connection.v1.QueryClientConnectionsRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryClientConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryClientConnectionsRequest();
    if ((0, helpers_1.isSet)(object.clientId)) obj.clientId = String(object.clientId);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryClientConnectionsRequest();
    message.clientId = object.clientId ?? "";
    return message;
  }
};
function createBaseQueryClientConnectionsResponse() {
  return {
    connectionPaths: [],
    proof: new Uint8Array(),
    proofHeight: client_1.Height.fromPartial({})
  };
}
exports.QueryClientConnectionsResponse = {
  typeUrl: "/ibc.core.connection.v1.QueryClientConnectionsResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    for (const v of message.connectionPaths) {
      writer.uint32(10).string(v);
    }
    if (message.proof.length !== 0) {
      writer.uint32(18).bytes(message.proof);
    }
    if (message.proofHeight !== undefined) {
      client_1.Height.encode(message.proofHeight, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryClientConnectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.connectionPaths.push(reader.string());
          break;
        case 2:
          message.proof = reader.bytes();
          break;
        case 3:
          message.proofHeight = client_1.Height.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryClientConnectionsResponse();
    if (Array.isArray(object?.connectionPaths)) obj.connectionPaths = object.connectionPaths.map(e => String(e));
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = (0, helpers_1.bytesFromBase64)(object.proof);
    if ((0, helpers_1.isSet)(object.proofHeight)) obj.proofHeight = client_1.Height.fromJSON(object.proofHeight);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    if (message.connectionPaths) {
      obj.connectionPaths = message.connectionPaths.map(e => e);
    } else {
      obj.connectionPaths = [];
    }
    message.proof !== undefined && (obj.proof = (0, helpers_1.base64FromBytes)(message.proof !== undefined ? message.proof : new Uint8Array()));
    message.proofHeight !== undefined && (obj.proofHeight = message.proofHeight ? client_1.Height.toJSON(message.proofHeight) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryClientConnectionsResponse();
    message.connectionPaths = object.connectionPaths?.map(e => e) || [];
    message.proof = object.proof ?? new Uint8Array();
    if (object.proofHeight !== undefined && object.proofHeight !== null) {
      message.proofHeight = client_1.Height.fromPartial(object.proofHeight);
    }
    return message;
  }
};
function createBaseQueryConnectionClientStateRequest() {
  return {
    connectionId: ""
  };
}
exports.QueryConnectionClientStateRequest = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionClientStateRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.connectionId !== "") {
      writer.uint32(10).string(message.connectionId);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionClientStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.connectionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionClientStateRequest();
    if ((0, helpers_1.isSet)(object.connectionId)) obj.connectionId = String(object.connectionId);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.connectionId !== undefined && (obj.connectionId = message.connectionId);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionClientStateRequest();
    message.connectionId = object.connectionId ?? "";
    return message;
  }
};
function createBaseQueryConnectionClientStateResponse() {
  return {
    identifiedClientState: undefined,
    proof: new Uint8Array(),
    proofHeight: client_1.Height.fromPartial({})
  };
}
exports.QueryConnectionClientStateResponse = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionClientStateResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.identifiedClientState !== undefined) {
      client_1.IdentifiedClientState.encode(message.identifiedClientState, writer.uint32(10).fork()).ldelim();
    }
    if (message.proof.length !== 0) {
      writer.uint32(18).bytes(message.proof);
    }
    if (message.proofHeight !== undefined) {
      client_1.Height.encode(message.proofHeight, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionClientStateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.identifiedClientState = client_1.IdentifiedClientState.decode(reader, reader.uint32());
          break;
        case 2:
          message.proof = reader.bytes();
          break;
        case 3:
          message.proofHeight = client_1.Height.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionClientStateResponse();
    if ((0, helpers_1.isSet)(object.identifiedClientState)) obj.identifiedClientState = client_1.IdentifiedClientState.fromJSON(object.identifiedClientState);
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = (0, helpers_1.bytesFromBase64)(object.proof);
    if ((0, helpers_1.isSet)(object.proofHeight)) obj.proofHeight = client_1.Height.fromJSON(object.proofHeight);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.identifiedClientState !== undefined && (obj.identifiedClientState = message.identifiedClientState ? client_1.IdentifiedClientState.toJSON(message.identifiedClientState) : undefined);
    message.proof !== undefined && (obj.proof = (0, helpers_1.base64FromBytes)(message.proof !== undefined ? message.proof : new Uint8Array()));
    message.proofHeight !== undefined && (obj.proofHeight = message.proofHeight ? client_1.Height.toJSON(message.proofHeight) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionClientStateResponse();
    if (object.identifiedClientState !== undefined && object.identifiedClientState !== null) {
      message.identifiedClientState = client_1.IdentifiedClientState.fromPartial(object.identifiedClientState);
    }
    message.proof = object.proof ?? new Uint8Array();
    if (object.proofHeight !== undefined && object.proofHeight !== null) {
      message.proofHeight = client_1.Height.fromPartial(object.proofHeight);
    }
    return message;
  }
};
function createBaseQueryConnectionConsensusStateRequest() {
  return {
    connectionId: "",
    revisionNumber: BigInt(0),
    revisionHeight: BigInt(0)
  };
}
exports.QueryConnectionConsensusStateRequest = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionConsensusStateRequest",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.connectionId !== "") {
      writer.uint32(10).string(message.connectionId);
    }
    if (message.revisionNumber !== BigInt(0)) {
      writer.uint32(16).uint64(message.revisionNumber);
    }
    if (message.revisionHeight !== BigInt(0)) {
      writer.uint32(24).uint64(message.revisionHeight);
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionConsensusStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.connectionId = reader.string();
          break;
        case 2:
          message.revisionNumber = reader.uint64();
          break;
        case 3:
          message.revisionHeight = reader.uint64();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionConsensusStateRequest();
    if ((0, helpers_1.isSet)(object.connectionId)) obj.connectionId = String(object.connectionId);
    if ((0, helpers_1.isSet)(object.revisionNumber)) obj.revisionNumber = BigInt(object.revisionNumber.toString());
    if ((0, helpers_1.isSet)(object.revisionHeight)) obj.revisionHeight = BigInt(object.revisionHeight.toString());
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.connectionId !== undefined && (obj.connectionId = message.connectionId);
    message.revisionNumber !== undefined && (obj.revisionNumber = (message.revisionNumber || BigInt(0)).toString());
    message.revisionHeight !== undefined && (obj.revisionHeight = (message.revisionHeight || BigInt(0)).toString());
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionConsensusStateRequest();
    message.connectionId = object.connectionId ?? "";
    if (object.revisionNumber !== undefined && object.revisionNumber !== null) {
      message.revisionNumber = BigInt(object.revisionNumber.toString());
    }
    if (object.revisionHeight !== undefined && object.revisionHeight !== null) {
      message.revisionHeight = BigInt(object.revisionHeight.toString());
    }
    return message;
  }
};
function createBaseQueryConnectionConsensusStateResponse() {
  return {
    consensusState: undefined,
    clientId: "",
    proof: new Uint8Array(),
    proofHeight: client_1.Height.fromPartial({})
  };
}
exports.QueryConnectionConsensusStateResponse = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionConsensusStateResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.consensusState !== undefined) {
      any_1.Any.encode(message.consensusState, writer.uint32(10).fork()).ldelim();
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.proof.length !== 0) {
      writer.uint32(26).bytes(message.proof);
    }
    if (message.proofHeight !== undefined) {
      client_1.Height.encode(message.proofHeight, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionConsensusStateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.consensusState = any_1.Any.decode(reader, reader.uint32());
          break;
        case 2:
          message.clientId = reader.string();
          break;
        case 3:
          message.proof = reader.bytes();
          break;
        case 4:
          message.proofHeight = client_1.Height.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionConsensusStateResponse();
    if ((0, helpers_1.isSet)(object.consensusState)) obj.consensusState = any_1.Any.fromJSON(object.consensusState);
    if ((0, helpers_1.isSet)(object.clientId)) obj.clientId = String(object.clientId);
    if ((0, helpers_1.isSet)(object.proof)) obj.proof = (0, helpers_1.bytesFromBase64)(object.proof);
    if ((0, helpers_1.isSet)(object.proofHeight)) obj.proofHeight = client_1.Height.fromJSON(object.proofHeight);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.consensusState !== undefined && (obj.consensusState = message.consensusState ? any_1.Any.toJSON(message.consensusState) : undefined);
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.proof !== undefined && (obj.proof = (0, helpers_1.base64FromBytes)(message.proof !== undefined ? message.proof : new Uint8Array()));
    message.proofHeight !== undefined && (obj.proofHeight = message.proofHeight ? client_1.Height.toJSON(message.proofHeight) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionConsensusStateResponse();
    if (object.consensusState !== undefined && object.consensusState !== null) {
      message.consensusState = any_1.Any.fromPartial(object.consensusState);
    }
    message.clientId = object.clientId ?? "";
    message.proof = object.proof ?? new Uint8Array();
    if (object.proofHeight !== undefined && object.proofHeight !== null) {
      message.proofHeight = client_1.Height.fromPartial(object.proofHeight);
    }
    return message;
  }
};
function createBaseQueryConnectionParamsRequest() {
  return {};
}
exports.QueryConnectionParamsRequest = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionParamsRequest",
  encode(_, writer = binary_1.BinaryWriter.create()) {
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionParamsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(_) {
    const obj = createBaseQueryConnectionParamsRequest();
    return obj;
  },
  toJSON(_) {
    const obj = {};
    return obj;
  },
  fromPartial(_) {
    const message = createBaseQueryConnectionParamsRequest();
    return message;
  }
};
function createBaseQueryConnectionParamsResponse() {
  return {
    params: undefined
  };
}
exports.QueryConnectionParamsResponse = {
  typeUrl: "/ibc.core.connection.v1.QueryConnectionParamsResponse",
  encode(message, writer = binary_1.BinaryWriter.create()) {
    if (message.params !== undefined) {
      client_1.Params.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(input, length) {
    const reader = input instanceof binary_1.BinaryReader ? input : new binary_1.BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryConnectionParamsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.params = client_1.Params.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object) {
    const obj = createBaseQueryConnectionParamsResponse();
    if ((0, helpers_1.isSet)(object.params)) obj.params = client_1.Params.fromJSON(object.params);
    return obj;
  },
  toJSON(message) {
    const obj = {};
    message.params !== undefined && (obj.params = message.params ? client_1.Params.toJSON(message.params) : undefined);
    return obj;
  },
  fromPartial(object) {
    const message = createBaseQueryConnectionParamsResponse();
    if (object.params !== undefined && object.params !== null) {
      message.params = client_1.Params.fromPartial(object.params);
    }
    return message;
  }
};
class QueryClientImpl {
  constructor(rpc) {
    this.rpc = rpc;
    this.Connection = this.Connection.bind(this);
    this.Connections = this.Connections.bind(this);
    this.ClientConnections = this.ClientConnections.bind(this);
    this.ConnectionClientState = this.ConnectionClientState.bind(this);
    this.ConnectionConsensusState = this.ConnectionConsensusState.bind(this);
    this.ConnectionParams = this.ConnectionParams.bind(this);
  }
  Connection(request) {
    const data = exports.QueryConnectionRequest.encode(request).finish();
    const promise = this.rpc.request("ibc.core.connection.v1.Query", "Connection", data);
    return promise.then(data => exports.QueryConnectionResponse.decode(new binary_1.BinaryReader(data)));
  }
  Connections(request = {
    pagination: pagination_1.PageRequest.fromPartial({})
  }) {
    const data = exports.QueryConnectionsRequest.encode(request).finish();
    const promise = this.rpc.request("ibc.core.connection.v1.Query", "Connections", data);
    return promise.then(data => exports.QueryConnectionsResponse.decode(new binary_1.BinaryReader(data)));
  }
  ClientConnections(request) {
    const data = exports.QueryClientConnectionsRequest.encode(request).finish();
    const promise = this.rpc.request("ibc.core.connection.v1.Query", "ClientConnections", data);
    return promise.then(data => exports.QueryClientConnectionsResponse.decode(new binary_1.BinaryReader(data)));
  }
  ConnectionClientState(request) {
    const data = exports.QueryConnectionClientStateRequest.encode(request).finish();
    const promise = this.rpc.request("ibc.core.connection.v1.Query", "ConnectionClientState", data);
    return promise.then(data => exports.QueryConnectionClientStateResponse.decode(new binary_1.BinaryReader(data)));
  }
  ConnectionConsensusState(request) {
    const data = exports.QueryConnectionConsensusStateRequest.encode(request).finish();
    const promise = this.rpc.request("ibc.core.connection.v1.Query", "ConnectionConsensusState", data);
    return promise.then(data => exports.QueryConnectionConsensusStateResponse.decode(new binary_1.BinaryReader(data)));
  }
  ConnectionParams(request = {}) {
    const data = exports.QueryConnectionParamsRequest.encode(request).finish();
    const promise = this.rpc.request("ibc.core.connection.v1.Query", "ConnectionParams", data);
    return promise.then(data => exports.QueryConnectionParamsResponse.decode(new binary_1.BinaryReader(data)));
  }
}
exports.QueryClientImpl = QueryClientImpl;
